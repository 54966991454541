import React, { Fragment } from 'react';
import man from '../../../assets/images/user.png'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';

const UserPanel = (props) => {
    const user = JSON.parse(localStorage.getItem('data'));
    const url = user?.profile_picture;

    let role = '';

    switch (user.role.name) {
        case 'trainer' : role = '/trainer'; break;
        case 'ketua klien' : role = '/ketua'; break;
        case 'admin c' : role = '/admin'; break;
        case 'super admin' : role = '/superadmin'; break;
    }

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={url ? url : man} alt="#" style={{height: '60px', objectFit: 'cover'}} />
                    <div className="profile-edit">
                        <Link to={`${role}/profil-saya`}>
                            <Edit />
                        </Link>
                    </div>
                </div>
                <h6 className="mt-3 f-14">{user?.name}</h6>
                <p>{props.as === 'peserta' ? props.as : `${props.as === 'admin' ? `Admin C` : props.as} - ${user?.company_name}`}</p>
            </div>
        </Fragment>
    );
};

export default UserPanel;
