import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import DatePicker from "react-datepicker";
import { propTypes } from "pdf-viewer-reactjs";
import moment from "moment-timezone";
import { Link, useLocation } from "react-router-dom";
import { BreadcrumbComp, TableComp } from "../../../component";
import { TransaksiIcon } from "../../../component/common/constant/menu";
import voucher from "../../../assets/images/voucher-redeem.png";
import success from "../../../assets/images/success.png";
import API from "../../../api";
import {checkEmpty, numberWithDots} from "../../../helper";
import swal from "sweetalert2";

const ParsedDateInd = (date) =>
  date
    ? moment.utc(date).lang("id").tz("Asia/Jakarta").format("DD MMMM YYYY")
    : "";

const TambahVoucher = (props) => {
  const [company, setCompany] = useState([]);
  const [modalBerhasilDibuat, setmodalBerhasilDibuat] = useState(false);
  const [formData, setformData] = useState({});
  const [agreement, setAgreement] = useState(false);
  const [voucherData, setVoucherData] = useState({
    nominal: 0,
    jumlah_voucher: 0,
    kodeVoucher: [],
  });

  const [generate, setgenerate] = useState(false);

  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const labelForm = (text) => (
    <label className="text-work mt-4" style={{ fontSize: "16px" }}>
      {text}
    </label>
  );

  useEffect(() => {
    API.superadmin.company
      .list_perusahaan({per_page: 9999})
      .then((json) => {
        setCompany(json.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setVoucherData({
      ...voucherData,
      total:
        parseInt(voucherData.nominal) * parseInt(voucherData.jumlah_voucher),
    });
  }, [voucherData.nominal, voucherData.jumlah_voucher]);

  const handleSubmit = (status) => {
    // e.preventDefault();

    setformData({
      ...formData,
      status: status,
    });

    const formdata = {
      ...formData,
      status: status,
    };

    if(!agreement)
      swal.fire('Error', 'Anda harus menyetujui dulu', 'error');
    else{
      if(checkEmpty(formdata, [
        {
          name: 'nominal',
          alias: 'Nominal'
        },
        {
          name: 'quantity',
          alias: 'Jumlah voucher'
        },
        {
          name: 'start_voucher',
          alias: 'Tanggal mulai'
        },
        {
          name: 'end_voucher',
          alias: 'Tanggal akhir'
        },
        {
          name: 'description',
          alias: 'Deskripsi'
        },
        {
          name: 'company_id',
          alias: 'Perusahaan'
        },
        {
          name: 'status',
          alias: 'Status'
        },
      ])){
        if(moment(formData.end_voucher).diff(moment(formData.start_voucher)) < 1){
          swal.fire('Error', 'Tanggal mulai tidak boleh lebih dari tanggal selesai', 'error');
        }
        else{
          API.superadmin.vouchers
              .insert_voucher(formdata)
              .then((json) => {
                console.log(json.data);
                setVoucherData(json.data);
                setmodalBerhasilDibuat(!modalBerhasilDibuat);
              })
              .catch((err) => {
                console.log(err);
              });
        }
      }
    }

  };

  const handleCloseModal = () => {
    setmodalBerhasilDibuat(!modalBerhasilDibuat);
    setgenerate(true);
  };
  return (
    <>
      <div className="page-header d-flex">
        <BreadcrumbComp title={"List Voucher"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/list-voucher" className="text-warning">
              <TransaksiIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">List Voucher</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 text-center">
              <img
                className="img-fluid mb-4"
                src={voucher}
                style={{ width: "84.17px" }}
                alt="omind"
              />
              <h3
                className="text-work"
                style={{ fontSize: "24px", fontWeight: "600" }}
              >
                Generate Kode Voucher
              </h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="col-md-4 d-flex align-items-end justify-content-between">
                    <div className="form-group mb-0">
                      {labelForm("Nominal")}
                      {generate ? (
                        <h6 className="mt-2">Rp. {voucherData.nominal}</h6>
                      ) : (
                        <input
                          type="number"
                          name="nominal"
                          className="form-control"
                          placeholder="Isi nominal voucher"
                          style={{ width: "134px" }}
                          onChange={handleChange}
                          required
                        />
                      )}
                    </div>
                    <h3 className="mb-0">X</h3>
                    <div className="form-group mb-0">
                      {labelForm("Jumlah voucher")}
                      {generate ? (
                        <h6 className="mt-2">Rp. {voucherData.quantity}</h6>
                      ) : (
                        <input
                          type="number"
                          name="quantity"
                          className="form-control"
                          placeholder="Jumlah voucher"
                          style={{ width: "134px" }}
                          onChange={handleChange}
                          required
                        />
                      )}
                    </div>
                    <h3 className="mr-2">=</h3>
                  </div>
                  <div className="col-md-8">
                    {labelForm("Jumlah Total Nominal Voucher")}
                      <h6 className="mt-2">
                        Rp. {formData.nominal && formData.quantity ? numberWithDots(formData.nominal * formData.quantity) : 0}
                      </h6>
                  </div>
                </div>
                <div className="form-group mt-3">
                  {labelForm("Tanggal Berlaku")}
                  <div className="d-flex align-items-center">
                    {generate ? (
                      <h6 className="mt-2">
                        {voucherData.start}
                      </h6>
                    ) : (
                      // <DatePicker
                      //   placeholderText="Pilih Tanggal"
                      //   className="form-control digits"
                      //   timeIntervals={15}
                      //   style={{ width: "150px" }}
                      //   minDate={new Date()}
                      //   selected={formData.start_voucher}
                      //   onChange={(e) =>
                      //     handleChange({
                      //       target: { name: "start_voucher", value: e },
                      //     })
                      //   }
                      //   dateFormat="dd-m-yyyy"
                      //   required
                      // />
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Pilih tanggal"
                        name="start_voucher"
                        style={{ width: "150px" }}
                        onChange={handleChange}
                      />
                    )}
                    <p
                      className="mb-0 text-nunito mx-3"
                      style={{ fontSize: "12px" }}
                    >
                      hingga
                    </p>
                    {generate ? (
                      <h6 className="mt-2">
                        {voucherData.end}
                      </h6>
                    ) : (
                      // <DatePicker
                      //   placeholderText="Pilih Tanggali"
                      //   className="form-control digits"
                      //   timeIntervals={15}
                      //   selected={formData.end_voucher}
                      //   minDate={formData.start_voucher}
                      //   style={{ width: "150px" }}
                      //   dateFormat="dd-m-yyyy"
                      //   onChange={(e) =>
                      //     handleChange({
                      //       target: { name: "end_voucher", value: e },
                      //     })
                      //   }
                      //   required
                      // />
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Pilih tanggal"
                        name="end_voucher"
                        style={{ width: "150px" }}
                        onChange={handleChange}
                      />
                    )}
                  </div>
                </div>
                <div className="form-group mt-3">
                  {labelForm("Keterangan")}
                  {generate ? (
                    <h6 className="mt-2">{voucherData.description}</h6>
                  ) : (
                    <textarea
                      className="form-control"
                      name="description"
                      placeholder="Masukkan keterangan penggunaan voucher"
                      style={{ height: "94px", width: "409px" }}
                      onChange={handleChange}
                      required
                    ></textarea>
                  )}
                </div>
                <div className="form-group">
                  {labelForm("Perusahaan")}
                  {generate ? (
                    <h6 className="mt-2">{voucherData.company}</h6>
                  ) : (
                    <select
                      className="form-control digits "
                      defaultValue="default"
                      style={{ width: "274px" }}
                      name="company_id"
                      onChange={handleChange}
                      required
                    >
                      <option value="default" disabled>
                        Pilih Perusahaan
                      </option>
                      {company &&
                        company.map((value) => (
                          <option value={value.id} selected={value.id === parseInt(props.company_id)}>{value.name}</option>
                        ))}
                    </select>
                  )}
                </div>
                {generate ? (
                  <div>
                    {labelForm("Kode Voucher")}
                    <table className="table table-hover table-borderless w-50">
                      {voucherData?.codes &&
                        voucherData?.codes.map((data, key) => (
                          <tbody>
                            <tr>
                              <td>{key + 1}</td>
                              <td>{data}</td>
                            </tr>
                          </tbody>
                        ))}
                    </table>
                  </div>
                ) : (
                  <div className="form-group">
                    <div className="form-check">
                      <div className="checkbox p-0">
                        <input
                          className="form-check-input"
                          id="invalidCheck"
                          type="checkbox"
                          checked={agreement}
                          onClick={() => {
                            setAgreement(!agreement);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="invalidCheck"
                        >
                          Saya menyetujui bahwa setiap nilai voucher akan
                          otomatis ditagihkan kepada perusahaan ketika terpakai
                          dan sepenuhnya menjadi tanggung jawab perusahaan
                        </label>
                      </div>
                      <div className="invalid-feedback">Ceklis</div>
                    </div>
                  </div>
                )}
                <div className="text-center mx-3 mt-4">
                  {generate ? (
                    <div>
                      <button
                        className="btn btn-link btn-sm text-work"
                        style={{
                          fontWeight: "600",
                          width: "260px",
                          height: "42px",
                        }}
                        type="button"
                        onClick={() => {
                          props.toastSucces();
                          props.settambahVoucher(false);
                        }}
                      >
                        {" "}
                        kembali ke List Voucher{" "}
                      </button>
                    </div>
                  ) : (
                    <>
                      <div>
                        <button
                          className="btn btn-pill btn-primary btn-sm text-work"
                          disabled={!agreement}
                          style={{
                            fontWeight: "600",
                            width: "260px",
                            height: "42px",
                          }}
                          type="button"
                          name="status"
                          value="Aktif"
                          onClick={
                            (() => propTypes.settambahVoucher(false),
                            () => handleSubmit("Aktif"))
                          }
                        >
                          {" "}
                          Aktifkan{" "}
                        </button>
                      </div>
                      <div>
                        <button
                          className="btn btn-pill btn-outline-primary btn-sm text-work mt-3"
                          disabled={!agreement}
                          style={{
                            fontWeight: "600",
                            width: "260px",
                            height: "42px",
                          }}
                          type="button"
                          name="status"
                          value="Draft"
                          onClick={
                            (() => propTypes.settambahVoucher(false),
                            () => handleSubmit("Draft"))
                          }
                        >
                          {" "}
                          Simpan sebagai draf{" "}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modalBerhasilDibuat} size="md" toggle={handleCloseModal}>
        <ModalBody>
          <div className="text-center w-75 mx-auto py-5">
            <img
              className="img-fluid mb-4"
              src={success}
              style={{ width: "64px" }}
              alt="omind"
            />
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              Kode Voucher Berhasil Dibuat
            </h3>
            <p className="text-nunito" style={{ fontSize: "16px" }}>
              Anda dapat melihat status penggunaan voucher di list voucher
            </p>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TambahVoucher;
