import React, { useState } from 'react';
import Exam from '../../../../assets/images/course_exam.png';

const PelatihanTugasFinalView = (props) => {
  console.log(props);
  const [View, setView] = useState('cobalagigagal');
  const [Attempt, setAttempt] = useState(1);
  
  const TugasItem = [
    {
      soal: 'Kita sudah mempersiapkan segalanya saat persentasi dengan matang, namun ditengah persentasi kita bank, terlebih ketika melihat audiens yang sebelumnya tidak pernah melihat persentasi kita. Mengapa hal itu bisa terjadi? Bagaimana cara mengatasinya?',
    },
    {
      soal: 'Kita sudah mempersiapkan segalanya saat persentasi dengan matang, namun ditengah persentasi kita bank. Mengapa hal itu bisa terjadi? ',
    },
    {
      soal: 'Terlebih ketika melihat audiens yang sebelumnya tidak pernah melihat persentasi Mengapa hal itu bisa terjadi? Bagaimana cara mengatasinya? ',
    },
  ]

  return (
    <>
      <div className='d-flex align-items-center'>
        <img className='img-60 mr-3' src={Exam} alt='omind' />
        <label className='mb-0 ml-2 mr-auto'>
          <h6 className='mb-0 font-weight-bold'>Ujian Akhir</h6>
          <p className='mb-0'>{props.questions.name}</p>
        </label>
      </div>  
      <div className='card p-5 mt-4'>
          <div>
            <ol className='d-block'>
              {props.questions.question.map((data, index)=>(
                <div className='mb-5'>
                    <li>{data.question}</li>
                    <button class="btn btn-success text-work mt-3" style={{ fontWeight: '600', fontSize: '12px' }} type="button"><i className="fa fa-cloud-download" onClick={() => {window.location.href = data.question_file}}></i> &nbsp;Download Soal</button>
                </div>
              ))}
            </ol>
          </div>
      </div>
    </>
  )
}

export default PelatihanTugasFinalView;