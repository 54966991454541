import React, { Fragment, useState, useEffect } from 'react';
import man from '../../../assets/images/dashboard/user.png';
import { User, Mail, Lock, Settings, LogOut, Info } from 'react-feather';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

const UserMenu = ({ history, as, role }) => {
    const [profile, setProfile] = useState('');
    const user = JSON.parse(localStorage.getItem('data'));
    const photo = user?.profile_picture;

    useEffect(() => {
        setProfile(photo || man);
    }, []);

    const logOut = () => {
        localStorage.removeItem(`${as}Token` );
        window.location = `/${user.company_id}/${as === 'peserta' ? '' : as+'/'}login`;
    };

    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="media align-items-center">
                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={profile} alt="header-user" style={{height: '50px', objectFit: 'cover'}} />
                    <div className="dotted-animation">
                        <span className="animate-circle"></span>
                        <span className="main-circle"></span>
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li><Link to={`${role}/profil-saya`}><User />Edit Profile</Link></li>
                    <li><Link to={`${role}/about-us`}><Info />About Us</Link></li>
                    <li><a onClick={logOut}><LogOut /> Log out</a></li>
                </ul>
            </li>
        </Fragment>
    );
};


export default withRouter(UserMenu);
