import React from 'react';
import Quiz from '../../../../../assets/images/quiz.png';

const CobaLagiTugas = (props) => {
  const { Attempt, setView } = props;

  return (
    <>
      <p className={`text-right mb-0 ml-auto mt-3 ${Attempt === 1 ? 'txt-primary' : Attempt === 2 ? 'text-warning' : 'txt-danger'}`} style={{ fontWeight: '600', fontSize: '14px' }}>Attempt {Attempt}/3</p>
      <div className='card p-5'>
        <div className='text-center p-4'>
          <img className="img-fluid mb-4" src={Quiz} alt="Omind Tech" style={{ width: '112.73px' }} />
          <h6 style={{ fontWeight: '600', fontSize: '16px' }}>Tugas Final</h6>
          <p className='text-muted' style={{ fontSize: '12px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem a viverra tristique ornare velit orci orci. Mi purus augue at at pulvinar leo.</p>
          <p className='txt-warning' style={{ fontSize: '14px', fontWeight: '600' }}>Anda belum memenuhi syarat minimal</p>
          <button className="btn btn-pill btn-primary mt-2 font-weight-bold mb-3" type="button" onClick={() => setView('tugas')}> Coba Lagi</button>
        </div>
      </div>
    </>
  )
}

export default CobaLagiTugas;
