import React, { useState, useEffect } from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Layers } from 'react-feather';
import { Collapse } from 'reactstrap';
import {
  useLocation,
  Link, useHistory
} from "react-router-dom";
import learning_dummy from '../../../assets/images/learning-dummy-image.png';
import API from "../../../api";

const DetailPelatihan = () => {
  const history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const status = query.get("status");

  const [course, setCourse] = useState(null);

  const [Pengenalan, setPengenalan] = useState(false);
  const [Rahasia, setRahasia] = useState(false);
  const [Teknik, setTeknik] = useState(false);
  const [Jadwal, setJadwal] = useState(false);

  const [accordions, setAccordion] = useState([]);

  const columnsTabelTransaksi = [
    {
      dataField: 'peserta',
      text: 'Nama Peserta',
      headerStyle: () => ({ width: '20%', textAlign: 'left',}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cell, row) => {
        return(
          <div className='d-flex align-items-center mb-2'>
            <div>
                <img className="img-40 rounded-circle" src={row.avatar} alt="#"></img>
            </div>
            <label className='mb-0 ml-2'>
              <p className='font-weight-bold'>{row.peserta}</p>
            </label>
          </div>
        )
      }
    },
    {
      dataField: 'periode',
      text: 'Periode',
      headerStyle: () => ({ width: '20%', textAlign: 'center'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: 'jadwal',
      text: 'Jadwal yang diambil',
      headerStyle: () => ({ width: '20%', textAlign: 'center'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cel,row) => {
      return  (
        <div className='text-center'>
          <p>{cel}</p>
        </div>
        )
      },
    },
    {
      dataField: '',
      text: '',
      headerStyle: () => ({ width: '10%', textAlign: 'center',}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cel,row) => {
        return (
          <Link to={`/ketua/manajemen-detail-pelatihan?id=${row.id}&&status=${row.status}&&type=${course.type}`}>
            <button className=" btn btn-pill btn-outline-primary btn-sm" type="button"> Lihat Progress </button>
          </Link>
        )
      },
    }
  ];

  const per_page = 5;
  let page = query.get('page') || 1;

  const [participants, setParticipant] = useState({
    data:[],
    meta:{
      from:1,
      to:0,
      total: 0,
      current_page: page,
      per_page,
      url: ''
    }
  });

  useEffect(() => {
    API.ketua.course.course_detail(id)
    .then(json => {
      setCourse(json.data);

      let participant = {...participants};
      participant.data = [];

      json.data.participants.map(val => {
        participant.data.push({
          id: val.id,
          peserta: val.name,
          avatar: val.photo,
          periode: val.period+' WIB',
          jadwal: val.jadwal,
          status: val.status,
        })
      });

      setParticipant(participant);

    })
  }, []);

  return (
    course &&
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Pelatihan Saya'}>
          <li className="breadcrumb-item">
            <Link to="/dashboard/default" className='text-warning'>
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Saya</li>
          <li className="breadcrumb-item active">Detail Pelatihan</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <p className='btn p-0 text-theme' style={{cursor: 'pointer'}} onClick={() => history.goBack()}>
            <i className="fa fa-arrow-left"></i> Kembali
          </p>
        </div>
        <div className='col-md-7'>
          <div className='card p-4'>
            <h3 className='font-weight-bold'>{course.nama}</h3>
            <div className='d-flex'>
              <label>
                <div className={`badge text-wrap text-capitalize ${course.type === 'online' ? 'badge-primary' : course.type === 'Face to Face' ? 'badge-danger' : 'badge-pink'}`} >
                  {course.type}
                </div>
              </label>
              <p className='text-muted mr-4 ml-2'> <span className='text-muted'><i className='fa fa-question-circle'></i></span></p>
              <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-clock-o'></i></span>&nbsp; Durasi Pelatihan <span className='text-dark font-weight-bold'>{course.day_duration} hari</span></p>
            </div>

            <h5 className='text-muted mt-4 text-work' style={{fontWeight:'600'}}>Pelatihan Terdiri dari</h5>
            <div className='d-flex'>
              {
                course.type === 'Virtual Class' &&
                <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-play-circle-o'></i></span>&nbsp; {course.count_meeting} Virtual Class</p>
              }
              {
                course.type === 'Face to Face' &&
                <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-play-circle-o'></i></span>&nbsp; {course.count_meeting} Tatap Muka</p>
              }
              <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa fa-play-circle-o'></i></span>&nbsp; {course.count_videos} Video</p>
              <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa fa-file-pdf-o'></i></span>&nbsp; {course.count_ebooks} PDF</p>
              <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa fa-file-powerpoint-o'></i></span> &nbsp; {course.count_ppt} PPT</p>
              <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-pencil-square-o'></i></span>&nbsp; {course.count_quiz} Kuis</p>
              <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-clipboard'></i></span>&nbsp; {course.count_final} Ujian Final</p>
            </div>
            {course.type !== 'Online' &&
              <>
              <h5 className='font-weight-bold text-theme mt-4'>Jadwal Pertemuan</h5>
              <div className="default-according style-1 faq-accordion job-accordion w-75" id="accordionoc">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header shadow-sm">
                        <h5 className="mb-0">
                          <button className="btn btn-link pl-0" data-toggle="collapse" onClick={() => setJadwal(!Jadwal)}
                            data-target="#collapseicon" aria-expanded={Jadwal} aria-controls="collapseicon">
                              <label className='text-work' style={{fontSize:'16px', fontWeight: '600'}}>
                                {course.schedules[0].location}
                                <br />
                                <span className='text-muted' style={{fontSize:'12px',  fontWeight: 'normal'}}>{course.schedules[0].detail_location}</span>
                              </label>
                            </button>
                        </h5>
                      </div>
                      <Collapse isOpen={Jadwal}>
                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                          <div className="card-body filter-cards-view animate-chk p-3">
                            {
                              course.schedules.map(val => {
                                return <>
                                <div className="ribbon-wrapper card border">
                                  <div className="card-body">
                                    <div className="ribbon ribbon-warning text-work font-weight-bold"> {val.name} </div>
                                    {
                                      val.meetings.map(val => {
                                        return <>
                                          <p className='font-weight-bold text-dark mb-0'>{val.name}</p>
                                          <span className='d-flex '>
                                            <p className='text-dark'>{val.date}</p>
                                            <p className='text-dark ml-auto'>{val.start} - {val.end} WIB</p>
                                          </span>
                                        </>
                                      })
                                    }
                                  </div>
                                </div>
                                </>
                              })
                            }
                            {/* <div className="ribbon-wrapper card border">
                              <div className="card-body">
                                <div className="ribbon ribbon-warning text-work font-weight-bold"> Jadwal A </div>
                                <p className='font-weight-bold text-dark mb-0'>Mengatasi Rasa Malu Berbicara </p>
                                <span className='d-flex '>
                                  <p className='text-dark'>Sabtu, 14 Juni 2020</p>
                                  <p className='text-dark ml-auto'>15.00 - 18.00 WIB</p>
                                </span>
                                <p className='font-weight-bold text-dark mb-0'> Solve Problem </p>
                                <span className='d-flex '>
                                  <p className='text-dark'>Minggu, 25 Juni 2020</p>
                                  <p className='text-dark ml-auto'>15.00 - 18.00 WIB</p>
                                </span>
                                <p className='font-weight-bold text-dark mb-0'> Mengatasi Kaki Bergetar</p>
                                <span className='d-flex '>
                                  <p className='text-dark'>Rabu, 15 Juli 2020</p>
                                  <p className='text-dark ml-auto'>15.00 - 18.00 WIB</p>
                                </span>
                              </div>
                            </div>
                            <div className="ribbon-wrapper card border">
                              <div className="card-body">
                                <div className="ribbon ribbon-secondary text-work font-weight-bold"> Jadwal B </div>
                                <p className='font-weight-bold text-dark mb-0'>Mengatasi Rasa Malu Berbicara </p>
                                <span className='d-flex '>
                                  <p className='text-dark'>Sabtu, 14 Juni 2020</p>
                                  <p className='text-dark ml-auto'>15.00 - 18.00 WIB</p>
                                </span>
                                <p className='font-weight-bold text-dark mb-0'> Solve Problem </p>
                                <span className='d-flex '>
                                  <p className='text-dark'>Minggu, 25 Juni 2020</p>
                                  <p className='text-dark ml-auto'>15.00 - 18.00 WIB</p>
                                </span>
                                <p className='font-weight-bold text-dark mb-0'> Mengatasi Kaki Bergetar</p>
                                <span className='d-flex '>
                                  <p className='text-dark'>Rabu, 15 Juli 2020</p>
                                  <p className='text-dark ml-auto'>15.00 - 18.00 WIB</p>
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
              </>
            }
            <p className='text-justify'>{course.description}</p>

            <h5 className='text-muted my-4 text-work' style={{fontWeight:'600'}}>Materi Pelatihan</h5>

            {
              course.theories_list.map((val, key) => {
                return <>
                <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-header shadow-sm">
                          <h5 className="mb-0">
                            <button className="btn btn-link pl-0 text-work" style={{fontWeight:'normal', fontSize:'16px'}} data-toggle="collapse"
                              onClick={() => {
                                let accord = [...accordions];
                                accord[key] = !accord[key];
                                setAccordion(accord);
                              }}
                              data-target="#collapseicon" aria-expanded={accordions[key]}
                              aria-controls="collapseicon">{val.name}</button>
                          </h5>
                        </div>
                        <Collapse isOpen={accordions[key]}>
                          <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                            <div className="card-body filter-cards-view animate-chk p-3">
                              {
                                val.theories.map(v => {
                                  return <>
                                  <p className='text-muted mb-0'>{v.name}</p>
                                  {
                                    v.type === 'Slide Powerpoint' ?
                                    <p className='text-muted'><span className='text-theme'><i
                                        className='fa fa fa-file-powerpoint-o'></i></span>&nbsp;PPT</p> :
                                    v.type === 'Video' ?
                                        <p className='text-muted'><span className='text-theme'><i
                                            className='fa fa-play-circle-o'></i></span>&nbsp;Video</p> :
                                        v.type === 'E-Book' ?
                                            <p className='text-muted'><span className='text-theme'><i
                                                className='fa fa fa-file-pdf-o'></i></span>&nbsp;PDF</p> :
                                            v.type === 'Quiz' ?
                                                <p className='text-muted'><span className='text-theme'><i
                                                    className='fa fa-pencil-square-o'></i></span>&nbsp;Kuis</p> :
                                                <p className='text-muted'><span className='text-theme'><i className='fa fa-clipboard'></i></span>&nbsp;Ujian Final</p>
                                  }
                                  </>
                                })
                              }
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </div>
                </>
              })
            }
          </div>
        </div>

        <div className='col-md-5'>
          <div className='card p-4'>
            <img class="img-fluid w-100 mb-3" src={course.photo} alt="omind" />
            <p className='text-muted mb-0'>Kategori <span className='text-dark font-weight-bold'>{course.category}</span></p>
            <p className='text-muted mb-0'>Dibuat oleh <span className='text-dark font-weight-bold'>{course.created_by}</span></p>
            <Link to={`/ketua/pelatihan-detail-lainnya?id=${course.id}`}>
              <button className="btn btn-pill btn-primary btn-sm mt-3 font-weight-bold text-work" type="button">Lihat Materi</button>
            </Link>
          </div>
        </div>

        <div className="col-sm-12">
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <div className='mr-auto'>
                <h5 className='text-work text-warning' style={{fontSize:'24px', fontWeight:'600'}}>LIST PESERTA AKTIF</h5>
              </div>
              <div>
                <select className="form-control btn-pill digits" id="exampleFormControlSelect7">
                  <option>Sorting berdasarkan</option>
                </select>
              </div>
            </div>
            <div className="card-body">
              <div className='overflow-auto w-100'>
                <TableComp
                    title_paggination='pelatihan'
                    columns={columnsTabelTransaksi}
                    data={participants}
                  />
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default DetailPelatihan;
