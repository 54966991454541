import React, {useState, useEffect} from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useLocation } from "react-router-dom";
import { CompanyIcon } from '../../../component/common/constant/menu';
import { ToastContainer, toast } from 'react-toastify';
import learning_dummy from '../../../assets/images/learning-dummy-image.png';
import API from "../../../api";

const TambahPelatihanPeserta = () => {


  const [course, setCourse] = useState(null)
  const [participant, setParticipant] = useState([])
  const [query_search, setQuerySearch] = useState(null)

  const handleSearchCourse = (event) => {
    event.preventDefault()

    if(event.target.value !== ''){
      API.superadmin.course.search_pelatihan({s: event.target.value})
        .then(json => {
          console.log(json.data);
          setCourse(json.data)
        }).catch(err => {
          toast.error("Pelatihan Tidak Ditemukan", { position: toast.POSITION.TOP_CENTER });
          console.log(err);
        })
    }
  }

  const handleSearchParticipant = (event) => {
    event.preventDefault()

    if(event.target.value !== ''){
      API.superadmin.participant.search_participant({s: event.target.value})
        .then(json => {
          console.log(json.data);
          const peserta = Object.assign([], participant)

          const found = peserta.some(el => el.id === json.data.id);

          if(!found){
            peserta.push({
              id: json.data.id,
              name: json.data.name,
              photo: json.data.photo,
            })
          }

          console.log(peserta)
          setParticipant(peserta)
        }).catch(err => {
          toast.error("Peserta Tidak Ditemukan", { position: toast.POSITION.TOP_CENTER });
          console.log(err);
        })
    }
  }

  const handleRemoveParticipant = (index) => {
    const peserta = Object.assign([], participant)

    delete peserta[index]

    setParticipant(peserta)
  }

  const handleSubmited = (e) => {
    e.preventDefault();

    let participants = []

    participant.map(v => {
      participants.push(v.id)
    })

    let user_data = JSON.stringify(participants)

    let data = new FormData()

    data.append('course_id', course.id)
    data.append('user', user_data)

    if(participants.length === 0){
      toast.error("Peserta Harus Ditambahkan", { position: toast.POSITION.TOP_CENTER });
    }else{
      API.superadmin.ctp.add_ctp(data)
        .then(json => {
          toast.success("Pelatihan Berhasil Ditambah ke Peserta", { position: toast.POSITION.TOP_CENTER });
          setCourse(null)
          setParticipant([])
        }).catch(err => {
          console.log(err);
          toast.error("Pelatihan Tidak Berhasil Ditambah ke Peserta", { position: toast.POSITION.TOP_CENTER });
        })
    }
  }

  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <BreadcrumbComp title={'Tambah Peserta'}>
          <li className="breadcrumb-item">
            <Link to="/admin/tambah-pelatihan-peserta" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Peserta</li>
          <li className="breadcrumb-item">Peserta terregistrasi</li>
          <li className="breadcrumb-item">Tambah Course Ke Peserta</li>
        </BreadcrumbComp>
      </div>

      <div className='col-md-12'>
          <Link to='/superadmin/peserta-terregistrasi'>
            <p className='btn p-0 text-theme'>
              <i className="fa fa-arrow-left"></i> Kembali
            </p>
          </Link>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
          <div className="card-header d-flex">
              <div className='mr-auto'>
                <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>TAMBAH PELATIHAN KE PESERTA</h4>
                <span className='text-muted'>Data Peserta</span>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className="card-body pt-0">
                <p style={{textAlign : 'center'}}>Mekanisme Tambah Pelatihan ke Peserta adalah Superadmin melakukan request pembelian pelatihan secara bulk kepada PT Samita Daniswara Mandiri.</p>
                <p style={{textAlign : 'justify'}}>&emsp;Superadmin dapat menambahkan peserta dari perusahaannya untuk mengikuti pelatihan yang diminati. Request penambahan ini akan tampil di menu ‘Transaksi Perusahaan’. Perusahaan harus melakukan pembayaran sesuai dengan nominal yang ditunjukkan dan admin PT Samita Daniswara Mandiri harus mengkonfirmasi apabila nominal transaksi sudah dibayarkan baru pengguna dapat menggunakan pelatihan.</p>
              </div>
              <form className="mx-4">
                <div className="my-3">
                  <label htmlFor="searchpelatihan"> <b> Pilih pelatihan yang ingin ditambahkan </b> </label>
                  <input type="search" placeholder="Ketikkan nama pelatihan" className="form-control w-50" aria-label="Search" id="searchpelatihan" name
                  ="searchpelatihan" onBlur={handleSearchCourse} />

                  {/*Pelatihan yang ditambah */}
                  {
                    course &&
                    <div className="row">
                      <div className="col-md-3 ">
                        <img src={course.photo} alt="" className="my-3 w-100 "/>
                      </div>
                      <div className="col-md-9">
                        <h6 className="mt-3" style={{fontWeight: '600'}}>{course.nama}</h6>

                        <div className='d-flex'>
                        <label>
                          <div className={`badge text-wrap text-capitalize ${course.type === 'online' ? 'badge-primary' : course.type === 'face to face' ? 'badge-danger' : 'badge-pink'} mr-2`} >
                            {course.type}
                          </div>
                        </label>
                        <p className='text-muted mr-3'> <span className='text-theme'><i className='fa fa-clock-o'></i></span> Durasi Pelatihan <span className='text-dark font-weight-bold'>{course.day_duration} hari</span></p>
                        </div>
                        <p className="text-muted my-0">Kategori <b>{course.category}</b></p>
                        <p className="text-muted">Dibuat oleh <b>{course.created_by}</b></p>
                        <div className='d-flex'>
                          {
                            course.type === 'Virtual Class' &&
                            <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-play-circle-o'></i></span>&nbsp; {course.count_meeting} Virtual Class</p>
                          }
                          {
                            course.type === 'Face to Face' &&
                            <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-play-circle-o'></i></span>&nbsp; {course.count_meeting} Tatap Muka</p>
                          }
                          <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa fa-play-circle-o'></i></span>&nbsp; {course.count_videos} Video</p>
                          <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa fa-file-pdf-o'></i></span>&nbsp; {course.count_ebooks} PDF</p>
                          <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa fa-file-powerpoint-o'></i></span> &nbsp; {course.count_ppt} PPT</p>
                          <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-pencil-square-o'></i></span>&nbsp; {course.count_quiz} Kuis</p>
                          <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-clipboard'></i></span>&nbsp; {course.count_final} Ujian Final</p>
                        </div>
                      </div>
                    </div>
                  }

                </div>
                <div className="my-4">
                  <label htmlFor="searchPeserta"> <b> Peserta yang ingin ditambahkan course </b> </label>
                  <input type="search" placeholder="Ketikkan nama peserta" className="form-control w-50" aria-label="Search" id="searchPeserta" name
                  ="searchpeserta" onBlur={handleSearchParticipant} />

                  {/* JS ke nama" orang */}
                  <div className='d-flex  my-3'>
                    {
                      participant.map((v,index) => {
                        return <div className="btn btn-outline-warning mr-3 d-flex align-items-center" style={{color :'black'}}>
                          <img src={v.photo} alt="" className="img-30 rounded-circle mr-2" />
                          <p className="mb-0">{v.name} <i class="fa fa-times" onClick={() => handleRemoveParticipant(index)}></i></p>
                        </div>
                      })
                    }
                  </div>

                </div>
                <div className="col-md-12 m-5">
                    <div className="text-right mb-5">
                    <button type="cancel" className="btn btn-outline-primary btn-pill mr-2">Batal</button>
                    <button type="submit" className="btn btn-primary btn-pill" onClick={handleSubmited}>Request Pelatihan</button>
                    </div>
                </div>
              </form>

            </div>
          </div>

        </div>
      </div>
      </div>
    </>
  )
}

export default TambahPelatihanPeserta;
