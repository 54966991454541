import React, { useState, useEffect } from 'react';
import Exam from '../../../../assets/images/course_exam.png';
import API from "../../../../api";

const PelatihanTugasFinalView = ({id}) => {

  const [View, setView] = useState('cobalagigagal');
  const [Attempt, setAttempt] = useState(1);
  const [finalTasks, setFinalTasks] = useState([]);
  const [name, setName] = useState('');

  const TugasItem = [
    {
      soal: 'Kita sudah mempersiapkan segalanya saat persentasi dengan matang, namun ditengah persentasi kita bank, terlebih ketika melihat audiens yang sebelumnya tidak pernah melihat persentasi kita. Mengapa hal itu bisa terjadi? Bagaimana cara mengatasinya?',
    },
    {
      soal: 'Kita sudah mempersiapkan segalanya saat persentasi dengan matang, namun ditengah persentasi kita bank. Mengapa hal itu bisa terjadi? ',
    },
    {
      soal: 'Terlebih ketika melihat audiens yang sebelumnya tidak pernah melihat persentasi Mengapa hal itu bisa terjadi? Bagaimana cara mengatasinya? ',
    },
  ]

  useEffect(() =>{
      API.trainer.course.get_final_task(id)
          .then(json => {
              let temp_finalTasks = [];

              setName(json.data.theory);

              json.data.final_tasks.map(v => {
                  temp_finalTasks.push({
                      soal: v.question,
                      file: v.question_file.replace(/ /g, '%20')
                  });
              });

              setFinalTasks(temp_finalTasks);
          })
  }, []);

  return (
    <>
      <div className='d-flex align-items-center'>
        <img className='img-60 mr-3' src={Exam} alt='omind' />
        <label className='mb-0 ml-2 mr-auto'>
          <h6 className='mb-0 font-weight-bold'>Ujian Akhir</h6>
          <p className='mb-0'>{name}</p>
        </label>
      </div>
      <div className='card p-5 mt-4'>
          <div>
            <ol className='d-block'>
              {finalTasks.map((data, index)=>(
                <div className='mb-5'>
                    <li>{data.soal}</li>
                    {
                        data.file &&
                        <button className="btn btn-success text-work mt-3" onClick={() => window.open(data.file, '_blank')} style={{ fontWeight: '600', fontSize: '12px' }} type="button"><i className="fa fa-cloud-download"></i> &nbsp;Download Soal</button>
                    }

                </div>
              ))}
            </ol>
          </div>
      </div>
    </>
  )
}

export default PelatihanTugasFinalView;
