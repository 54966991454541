import React, { useState, useEffect } from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link, useLocation, useHistory, Prompt } from "react-router-dom";
import { CompanyIcon } from '../../../component/common/constant/menu';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import moment from 'moment'
import API from "../../../api";
import {checkEmpty} from "../../../helper";
import swal from 'sweetalert2';

const EditPelatihan = () => {
  let history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  const [courses, setCourses] = useState(null);
  const [deleteTheories, setDeleteTheories] = useState([]);
  const [deleteModules, setDeleteModules] = useState([]);
  const [deleteMeetings, setDeleteMeetings] = useState([]);
  const [deleteSchedules, setDeleteSchedules] = useState([]);
  const id = query.get("id");
  const [imagePreview, setimagePreview] = useState(null);
  const [imagePreviewUrl, setimagePreviewUrl] = useState('');
  const [jenisPelatihan, setjenisPelatihan] = useState("Enquiries");
  const [InputJadwalPertemuan, setInputJadwalPertemuan] = useState([])
  const [InputModulPelatihan, setModulPelatihan] = useState([])
  const [list_category, setListCategory] = useState([])
  const [list_trainer, setListTrainer] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [name, setName] = useState('');
  const [type, setType] = useState(null)
  const [category, setCategory] = useState(null)
  const [thumbnail, setThumbnail] = useState(null)
  const [day_duration, setdayDuration] = useState(null)
  const [description, setDescription] = useState(null)
  const [topic, setTopic] = useState(null)
  const [purpose, setPurpose] = useState(null);
  const [short_description, setShortDescription] = useState(null);
  const [target_audience, setTargetAudience] = useState(null)
  const [precondition, setPrecondition] = useState(null)
  const [trainer, setTrainer] = useState(null)
  const [theories, setTheories] = useState([])
  const [certificate, setCertificate] = useState(null)
  const [certificatePreview, setCertificatePreview] = useState(null)
  const [price, setPrice] = useState(null)
  const [status, setStatus] = useState('Active, Private')
  const [schedule, setSchedule] = useState([]);
  const [uploadPercent, setUploadPercent] = useState(0);

  useEffect(() => {
    if (id) {
      API.adminc.course.course_get_update(id)
          .then(json => {
            console.log(json.data);

            setStatus(json.data.status)
            setName(json.data.nama)
            setjenisPelatihan(json.data.type)
            setType(json.data.type)
            setCategory(json.data.category)
            setimagePreviewUrl(json.data.photo.replace(/ /g,"%20"))
            setdayDuration(json.data.day_duration)
            setPrice(json.data.price)
            setDescription(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(json.data.description)
                )));
            setTopic(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(json.data.topic)
                )))
            setPurpose(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(json.data.purpose)
                )))
            setTargetAudience(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(json.data.target_audience)
                )))
            setPrecondition(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(json.data.precondition)
                )))
            setShortDescription(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(json.data.short_description)
                )))
            setTrainer(json.data.trainer)
            setSchedule(json.data.schedules)
            setTheories(json.data.theories)
            setCertificatePreview(json.data.certificate_template);

            let data_schedule = []
            let data_meeting = []

            if(json.data.schedules){
              json.data.schedules.map(v => {
                v.meetings.map(s => {
                  data_meeting.push({
                    name: s.name,
                    date: s.date,
                    start_meeting: s.start,
                    end_meeting: s.end,
                  })
                })
                data_schedule.push({
                  name: v.name,
                  location: v.location,
                  detail_location: v.detail_location,
                  trainer_id: '',
                  meetings: data_meeting
                })
              })
              setInputJadwalPertemuan(data_schedule)
            }

            let data_modules = []
            let data_theories = []

            json.data.theories_list.map(t => {
              t.theories.map(u => {
                data_theories.push({
                  id: u.id,
                  type: u.type,
                  name: u.name,
                  file: u.file,
                })
              })
              data_modules.push({
                id: t.id,
                name: t.name,
                theories: data_theories,
              });

              data_theories = [];
            });
            console.log(data_modules);
            setModulPelatihan(data_modules);
          }).catch(err => {
        console.log(err);
      })
    }

    API.category.get_all()
      .then(json => {
        setListCategory(json.data)
      }).catch(err => {
        console.log(err);
      })

    API.adminc.course.list_trainer()
      .then(json => {
        setListTrainer(json.data)
      }).catch(err => {
        console.log(err);
      })
  }, []);

  const materiOptions = [
    { label: 'Slide Powerpoint', value: 'Slide Powerpoint' },
    { label: 'Video', value: 'Video' },
    { label: 'E-Book', value: 'E-Book' },
    { label: 'Quiz', value: 'Quiz' },
    { label: 'Tugas Final', value: 'Tugas Final' }
  ]

  const caption = {
    'Face to Face': "Jika Anda memilih jenis pelatihan Face to Face, Anda diharuskan mengisikan jadwal pertemuan peserta dan trainer secara offline",
    'Enquiries': "Jika Anda memilih jenis pelatihan Enquiry, Anda diharuskan mengisikan rencana jadwal pertemuan yang akan diselenggarkan nantinya.",
    'Virtual Class': "Jika Anda memilih jenis pelatihan Virtual Class, Anda diharuskan mengisikan jadwal pertemuan peserta dan trainer secara online."
  }

  const handleAddJadwalPertemuan = () => {
    const newField = Object.assign([], InputJadwalPertemuan);
    newField.push({ name: '', location: '', detail_location: '', trainer_id: '', meetings: [] });

    setInputJadwalPertemuan(newField);
  };

  const handleAddModulPelatihan = () => {
    const newField = Object.assign([], InputModulPelatihan);
    newField.push({ name: '', theories: [] });

    setModulPelatihan(newField);
  };

  const handleRemoveJadwalPertemuan = (index, type, field = null) => {
    const newField = Object.assign([], type === 'jadwalPertemuan' ? InputJadwalPertemuan : InputModulPelatihan);
    delete newField[index];


    if (type === 'jadwalPertemuan') {
      if(field.id){
        let deleteSchedules_temp = [...deleteSchedules];
        deleteSchedules_temp.push(field.id);
        setDeleteSchedules(deleteSchedules_temp);
      }

      setInputJadwalPertemuan(newField);
    } else {
      if(field.id){
        let deleteModules_temp = [...deleteModules];
        deleteModules_temp.push(field.id);
        setDeleteModules(deleteModules_temp);
      }
      setModulPelatihan(newField);
    }
  };

  const handleFieldMateri = (modulIndex, materiIndex, type, theory = null) => {
    const newModul = Object.assign([], InputModulPelatihan);
    const newField = Object.assign([], InputModulPelatihan[modulIndex]);

    if (type === 'add') {
      newField.theories.push({ type: 'Slide Powerpoint', name: '' });
    } else {
      if(theory.id){
        let deleteTheories_temp = [...deleteTheories];
        deleteTheories_temp.push(theory.id);
        setDeleteTheories(deleteTheories_temp);
      }
      newField.theories.splice(materiIndex, 1);
    }

    newModul[modulIndex] = newField;
    setModulPelatihan(newModul);
  }

  const handleMateriPertemuan = (modulIndex, indexPertemuan, type, meeting = null) => {
    const newModul = Object.assign([], InputJadwalPertemuan);
    const newField = Object.assign([], InputJadwalPertemuan[modulIndex]);

    if (type === 'add') {
      newField.meetings.push({ name: '', date: '', start_meeting: '', end_meeting: '' });
    } else {
      if(meeting.id){
        let deleteMeetings_temp = [...deleteMeetings];
        deleteMeetings_temp.push(meeting.id);
        setDeleteMeetings(deleteMeetings_temp);
      }
      delete newField.meetings[indexPertemuan];
    }

    newModul[modulIndex] = newField;
    setInputJadwalPertemuan(newModul);
  }

  const handleChangeMateri = (name, value, modulIndex, materiIndex) => {
    const newModul = Object.assign([], InputModulPelatihan);
    const newField = Object.assign([], InputModulPelatihan[modulIndex]);
    newField.theories[materiIndex] = { ...newField.theories[materiIndex], [name]: value }

    newModul[modulIndex] = newField;
    setModulPelatihan(newModul);
    console.log(InputModulPelatihan)
  }

  const handleChangeMeeting = (name, value, modulIndex, materiIndex) => {
    const newModul = Object.assign([], InputJadwalPertemuan);
    const newField = Object.assign([], InputJadwalPertemuan[modulIndex]);
    newField.meetings[materiIndex] = { ...newField.meetings[materiIndex], [name]: value }

    newModul[modulIndex] = newField;
    setInputJadwalPertemuan(newModul);
  }

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    setThumbnail(file)

    reader.onloadend = () => {
      setimagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file)
  };

  const handleJenisPelatihan = (type) => {
    setType(type)
    setjenisPelatihan(type)
  }

  const handleChange = (event) => {
    const { name, value, index } = event.target;
    const newInput = Object.assign([], InputModulPelatihan);
    newInput[index] = { ...newInput[index], [name]: value };

    setModulPelatihan(newInput);
    console.log('pelatihan : '+InputModulPelatihan)
  };

  const handleChangeJadwal = (event) => {
    const { name, value, index } = event.target;
    const newInput = Object.assign([], InputJadwalPertemuan);
    newInput[index] = { ...newInput[index], [name]: value };

    setInputJadwalPertemuan(newInput);
    console.log(InputJadwalPertemuan)
  };

  const buttonPelatihan = (color, type, label) => (
    <button
      className={`btn ${jenisPelatihan === type ? color : 'btn-secondary'} btn-xs text-capitalize mr-3`}
      type="button"
      onClick={() => handleJenisPelatihan(type)}
    >{label}</button>
  )

  const labelForm = (text) => (
    <label className='text-work' style={{ fontSize: '16px' }}>{text}</label>
  )

  const handleuploadCertificate = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setCertificate(file)

    reader.onloadend = () => {
      setCertificatePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleSubmited = (e) => {
    e.preventDefault();
    setIsSubmitting(true)

    if(checkEmpty({
      name,
      type,
      category,
      thumbnail,
      day_duration,
      description,
      short_description,
      topic,
      purpose,
      target_audience,
      precondition,
      trainer
    }, [
      {
        name: 'name',
        alias: 'Nama'
      },
      {
        name: 'type',
        alias: 'Jenis'
      },
      {
        name: 'category',
        alias: 'Kategori'
      },
      {
        name: 'day_duration',
        alias: 'Durasi'
      },
      {
        name: 'description',
        alias: 'Deskripsi'
      },
      {
        name: 'short_description',
        alias: 'Deskripsi singkat'
      },
      {
        name: 'topic',
        alias: 'Topik'
      },
      {
        name: 'purpose',
        alias: 'Tujuan pembelajaran'
      },
      {
        name: 'target_audience',
        alias: 'Target audience'
      },
      {
        name: 'precondition',
        alias: 'Prekondisi'
      },
      {
        name: 'trainer',
        alias: 'Trainer'
      },
    ])){
      console.log('bener');
      let data = new FormData();


      data.append("name", name);
      data.append("type", type);
      data.append("category_id", category);
      if(imagePreviewUrl.includes('data:image'))
        data.append("thumbnail", thumbnail);
      data.append("day_duration", day_duration);
      data.append("description", description.getCurrentContent().getPlainText());
      data.append("short_description", short_description.getCurrentContent().getPlainText());
      data.append("topic", topic.getCurrentContent().getPlainText());
      data.append("purpose", purpose.getCurrentContent().getPlainText());
      data.append("target_audience", target_audience.getCurrentContent().getPlainText());
      data.append("precondition", precondition.getCurrentContent().getPlainText());
      data.append("trainer_id", trainer);

      InputModulPelatihan.map((value, index) => {
        if(value.id)
          data.append(`theories[${index}][id]`, value.id);
        data.append(`theories[${index}][name]`, value.name);

        value.theories.map((val, ind) => {
          if(val.id)
            data.append(`theories[${index}][theories][${ind}][id]`, val.id);

          data.append(`theories[${index}][theories][${ind}][type]`, val.type);
          data.append(`theories[${index}][theories][${ind}][name]`, val.name);

          if(val.file){
            if(val.type === 'Video')
              data.append(`theories[${index}][theories][${ind}][file]`, val.file);
            else
              if(val.file instanceof File)
                data.append(`theories[${index}][theories][${ind}][file]`, val.file);
          }
        });
      });

      if(certificate)
        data.append("certificate_template", certificate);
      data.append("price", price);
      data.append("status", status);

      if (type !== "Online") {
        InputJadwalPertemuan.map((value, index) => {
          if(value.id)
            data.append(`schedules[${index}][id]`, value.id);
          data.append(`schedules[${index}][name]`, value.name);
          data.append(`schedules[${index}][location]`, value.location);
          data.append(
              `schedules[${index}][detail_location]`,
              value.detail_location
          );

          value.meetings.map((val, ind) => {
            if(val.id)
              data.append(`schedules[${index}][meetings][${ind}][id]`, val.id);
            data.append(`schedules[${index}][meetings][${ind}][name]`, val.name);
            data.append(`schedules[${index}][meetings][${ind}][date]`, val.date);
            data.append(
                `schedules[${index}][meetings][${ind}][start_meeting]`,
                val.start_meeting
            );
            data.append(
                `schedules[${index}][meetings][${ind}][end_meeting]`,
                val.end_meeting
            );
          });
        });
        // data.schedules = JSON.stringify(InputJadwalPertemuan);
        if(deleteMeetings.length)
          data.append('deleteMeetings', JSON.stringify(deleteMeetings));

        if(deleteSchedules.length)
          data.append('deleteSchedules', JSON.stringify(deleteSchedules));

      }

      console.log("InputModulPelatihan", InputModulPelatihan);
      console.log(
          "InputModulPelatihan stringify",
          JSON.stringify(InputModulPelatihan)
      );

      if(deleteTheories.length)
        data.append('deleteTheories', JSON.stringify(deleteTheories));

      if(deleteModules.length)
        data.append('deleteModules', JSON.stringify(deleteModules));

      console.log(data);

      const onUploadProgress = ({loaded, total}) => {
        let percent = Math.floor((loaded * 100) / total);
        setUploadPercent(percent);
      };

      API.adminc.course
          .update_pelatihan(id, data, onUploadProgress)
          .then((json) => {
            setIsSuccess(true);
            setIsSubmitting(false);
            setUploadPercent(0);
            swal.fire('Sukses', json.message, 'success').then(res => {
              history.push("/admin/list-pelatihan");
            });
          })
          .catch((err) => {
            console.log(err);
            let errors = Object.values(err.errors);
            let errors_msg = '';

            errors.map(val => {
              errors_msg += val+', ';
            });

            swal.fire('Gagal', errors_msg, 'error');
            setIsSubmitting(false);
            /*toast.error("Pelatihan Tidak Berhasil Diupdate", {
              position: toast.POSITION.TOP_CENTER,
            });*/
          });
    }
    else
      setIsSubmitting(false);
  };

  return (
    <>
      <Prompt
          when={!isSuccess}
          message="Anda yakin? Data pelatihan yang anda isi akan hilang"
      />
      <ToastContainer />
      <div className="page-header">
        <BreadcrumbComp title={'Edit Pelatihan'}>
          <li className="breadcrumb-item">
            <Link to="/admin/tambah-pelatihan" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Pelatihan</li>
          <li className="breadcrumb-item">Edit Pelatihan</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header d-flex pb-2">
              <div className='mr-auto'>
                <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>EDIT PELATIHAN</h4>
                <span className='text-muted'>Data Pelatihan</span>
              </div>
              <div className="mr-3 d-flex">
                <span className='text-work mt-2' style={{ fontWeight: '500' }}>Status &emsp;&emsp;&emsp;</span>
                <select className="form-control digits " id="exampleFormControlSelect7" onChange={(event) => setStatus(event.target.value)} >
                  <option value="Active, Private" selected={status == 'Active, Private'}>Aktif, Private</option>
                  <option value="Aktif, Public" selected={status == 'Aktif, Public'}>Aktif, Publik</option>
                  <option value="Nonaktif" selected={status == 'Nonaktif'}>Non Aktif</option>
                </select>
              </div>
            </div>
            <div className="card-body pt-4 pb-3">
              <h6 className='text-muted mb-3' style={{ fontWeight: '700' }}>Informasi Pelatihan</h6>
              <form onSubmit={handleSubmited}>
                <div className='row'>
                  <div className="col-md-6">
                    <div className='form-group' style={name.length > 50 ? {backgroundColor: 'pink'} : {}}>
                      {labelForm(`Nama Pelatihan (maks. 50 karakter) ${name.length}/50`)}
                      <input type="text" maxLength="50" className="form-control" name="namaPelatihan" id="namaPelatihan" placeholder="Masukan nama pelatihan" defaultValue={name} onChange={(event) => setName(event.target.value)} />
                    </div>
                    <div className='form-group'>
                      {labelForm('Jenis Pelatihan')}
                      <div>
                        {buttonPelatihan('btn-primary', 'Online', 'online')}
                        {buttonPelatihan('btn-danger', 'Face to Face', 'Face to Face')}
                        {buttonPelatihan('btn-success', 'Enquiries', 'enquiries')}
                        {buttonPelatihan('badge-pink', 'Virtual Class', 'virtual class')}
                      </div>
                    </div>
                    <div className='form-group'>
                      {labelForm('Bidang Pelatihan')}
                      <select type='select' className='form-control' defaultValue='default' onChange={(event) => setCategory(event.target.value)} >
                        <option value='default' disabled>Pilih Bidang Pelatihan</option>
                        {
                          list_category.map(v => {
                            return <option value={v.id} selected={category == v.id}>{v.name}</option>
                          })
                        }
                      </select>
                    </div>
                    <div className='form-group'>
                      {labelForm('Durasi Pelatihan')}
                      <span className='d-flex align-items-center'>
                        <input type="number" className="form-control mr-3" style={{ width: "75px" }} defaultValue={day_duration} onChange={(event) => setdayDuration(event.target.value)} />
                        <p>hari</p>
                      </span>
                    </div>
                    <div className='form-group'>
                      {labelForm('Harga')}
                      <span className='d-flex align-items-center'>
                        <p className='mb-0'>Rp</p>
                        <input type="number" className="form-control ml-3" style={{ width: "125px" }} defaultValue={price} onChange={(event) => setPrice(event.target.value.replace(/[^\d]/,''))} />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className='mx-auto d-flex justify-content-center align-items-center'
                      style={{
                        backgroundColor: '#F1F3F6',
                        width: '400px',
                        height: '200px',
                        backgroundImage: `url(${imagePreviewUrl})`,
                        backgroundSize: 'cover',
                        borderRadius: '20px'
                      }} >
                      {imagePreviewUrl ? imagePreview : <p style={{ color: '#8B8B8B', fontSize: '12px', fontWeight: 'bold' }}>Tambah foto pelatihan</p>}
                    </div>
                    <div className='w-100 mx-auto mt-3 text-center'>
                      <p>Ukuran gambar sebaiknya 334 px X 168 px</p>
                    </div>
                    <div className='w-50 mx-auto mt-3'>
                      <input className="form-control text-dark" type="file" onChange={handleImageChange} />
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='border-bottom py-3'>
                      <div className='form-group'>
                        {labelForm('Deskripsi Pelatihan')}
                        <Editor
                          editorState={description}
                          toolbarClassName="border-bottom"
                          wrapperClassName="border"
                          editorClassName="p-3"
                          name="description"
                          onEditorStateChange={(event) => {
                            setDescription(event)
                          }}
                        />
                      </div>
                      <div className='form-group'>
                        {labelForm('Topik Pelatihan')}
                        <Editor
                          editorState={topic}
                          toolbarClassName="border-bottom"
                          wrapperClassName="border"
                          editorClassName="p-3"
                          onEditorStateChange={(event) => {
                            setTopic(event)
                          }}
                        />
                      </div>
                      <div className='form-group'>
                        {labelForm('Tujuan Pembelajaran')}
                        <Editor
                          editorState={purpose}
                          toolbarClassName="border-bottom"
                          wrapperClassName="border"
                          editorClassName="p-3"
                          onEditorStateChange={(event) => {
                            setPurpose(event)
                          }}
                        />
                      </div>
                      <div className='form-group'>
                        {labelForm('Target Audience')}
                        <Editor
                          editorState={target_audience}
                          toolbarClassName="border-bottom"
                          wrapperClassName="border"
                          editorClassName="p-3"
                          onEditorStateChange={(event) => {
                            setTargetAudience(event)
                          }}
                        />
                      </div>
                      <div className='form-group'>
                        {labelForm('Prekondisi')}
                        <Editor
                          editorState={precondition}
                          toolbarClassName="border-bottom"
                          wrapperClassName="border"
                          editorClassName="p-3"
                          onEditorStateChange={(event) => {
                            setPrecondition(event)
                          }}
                        />
                      </div>
                      <div className='form-group'>
                        {labelForm('Deskripsi Singkat')}
                        <Editor
                            editorState={short_description}
                            toolbarClassName="border-bottom"
                            wrapperClassName="border"
                            editorClassName="p-3"
                            onEditorStateChange={(event) => {
                              setShortDescription(event)
                            }}
                        />
                      </div>
                    </div>
                    {jenisPelatihan ?
                      <div className='border-bottom py-3'>
                        <h6 className='text-muted mb-3' style={{ fontWeight: '700' }}>Pilih Trainer</h6>
                        <div className='form-group'>
                          {labelForm('Nama Trainer')}
                          <select type='select' className='form-control' defaultValue='default' style={{ width: '343px' }} onChange={(event) => setTrainer(event.target.value)} >
                            <option value='default' disabled>Pilih Trainer</option>
                            {
                              list_trainer.map(v => {
                                return <option value={v.id} selected={trainer == v.id}>{v.name}</option>
                              })
                            }
                          </select>
                          {/* <input
                            type="text"
                            className="form-control"
                            name="namaPelatihan"
                            id="namaPelatihan"

                            placeholder="Masukan Nama Trainer"
                            style={{ width: '343px' }}
                          /> */}
                        </div>
                      </div> : ''
                    }

                    {jenisPelatihan !== 'Online' &&
                      <div className='border-bottom py-3'>
                        <h6 className='text-muted mb-3' style={{ fontWeight: '700' }}>Atur Jadwal Pertemuan</h6>
                        <span className='border rounded p-3 d-flex align-items-center'>
                          <svg className='mr-3' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" fill="#FCC932" />
                            <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" fill="#FCC932" />
                            <path d="M8.99784 17.0678C8.82646 17.0678 8.6875 16.9288 8.6875 16.7574C8.6875 16.5861 8.82646 16.4471 8.99784 16.4471C13.1094 16.4423 16.4414 13.1104 16.4461 8.99882C16.4461 8.82743 16.5851 8.68848 16.7565 8.68848C16.9279 8.68848 17.0668 8.82743 17.0668 8.99882C17.0618 13.4531 13.4521 17.0628 8.99784 17.0678Z" fill="#FCF05A" />
                            <path d="M1.24003 9.30901C1.06864 9.30901 0.929688 9.17005 0.929688 8.99866C0.934653 4.54436 4.54436 0.934653 8.99866 0.929688C9.17005 0.929688 9.30901 1.06864 9.30901 1.24003C9.30901 1.41142 9.17005 1.55038 8.99866 1.55038C4.88705 1.55515 1.55515 4.88705 1.55038 8.99866C1.55038 9.17005 1.41142 9.30901 1.24003 9.30901Z" fill="#FCF05A" />
                            <path d="M8.99959 4.65234C9.3424 4.65234 9.62027 4.93022 9.62027 5.27303C9.62027 5.61585 9.3424 5.89372 8.99959 5.89372C8.65678 5.89372 8.37891 5.61585 8.37891 5.27303C8.37891 4.93022 8.65678 4.65234 8.99959 4.65234Z" fill="#5F79BA" />
                            <path d="M9.61948 12.4126V7.13672H7.13672V7.75741C7.13672 7.9288 7.27568 8.06775 7.44706 8.06775H8.3781V13.3436H10.8609V12.7229C10.8609 12.5515 10.7219 12.4126 10.5505 12.4126H9.61948Z" fill="#5F79BA" />
                          </svg>
                          {caption[jenisPelatihan]}
                        </span>
                        {!InputJadwalPertemuan[0] &&
                          <button
                            className='btn btn-link p-0 text-theme text-roboto font-weight-bold mt-3'
                            type='button'
                            onClick={handleAddJadwalPertemuan()}
                          > + Tambah Pertemuan</button>
                        }
                        {InputJadwalPertemuan.length !== 0 &&
                          InputJadwalPertemuan.map((field, index) => (
                          <div className='mt-3 w-75'>
                            <div className='form-group pl-3 d-flex' style={{ borderLeft: '3px solid #FCC932' }}>
                              <div>
                                {labelForm('Nama Jadwal')}
                                <input type="text" className="form-control mr-3" style={{ width: "343px" }} defaultValue={field.name}
                                  onChange={event => handleChangeJadwal({
                                    target: { name: 'name', value: event.target.value, index: index }
                                  })}  />
                              </div>
                              <div className='ml-auto'>
                                <button
                                  className='btn btn-link p-0 text-theme text-roboto font-weight-bold'
                                  type='button'
                                  onClick={() => handleRemoveJadwalPertemuan(index, "jadwalPertemuan")}
                                > X </button>
                              </div>
                            </div>
                            <div className='form-group pl-3 d-flex'>
                              <div>
                                {labelForm('Lokasi Pertemuan')}
                                <input type="text" className="form-control mr-3" style={{ width: "343px" }} defaultValue={field.location}
                                  onChange={event => handleChangeJadwal({
                                    target: { name: 'location', value: event.target.value, index: index }
                                  })} />
                              </div>
                              <div>
                                {labelForm('Detail Lokasi')}
                                <input type="text" className="form-control mr-3" style={{ width: "343px" }} defaultValue={field.detail_location}
                                  onChange={event => handleChangeJadwal({
                                    target: { name: 'detail_location', value: event.target.value, index: index }
                                  })} />
                              </div>
                            </div>
                            {jenisPelatihan !== 'enquiries' &&
                              <div className='form-group pl-3'>
                                {labelForm('Nama Trainer')}
                                <input type="text" className="form-control mr-3" style={{ width: "343px" }} />
                              </div>
                            }
                            <div className='ml-4'>
                              {field.meetings.map((meeting, indexPertemuan) => (
                                <div className='d-flex' key={indexPertemuan}>
                                  <div>
                                    <button
                                      className='btn btn-link p-0 text-roboto font-weight-bold mr-3'
                                      type='button'
                                      style={{ color: '#C4C4C4' }}
                                      onClick={() => handleMateriPertemuan(index, indexPertemuan, meeting)}
                                    > X </button>
                                  </div>
                                  <div className='form-group pl-3' style={{ borderLeft: '2px solid #F1F3F6' }}>
                                    <input
                                      style={{ width: '343px' }}
                                      type="text"
                                      className="form-control"
                                      placeholder='Masukkan nama materi pertemuan'
                                      value={meeting.name}
                                      onChange={(e) => handleChangeMeeting('name', e.target.value, index, indexPertemuan)} />
                                    <DatePicker className="form-control digits mt-3" placeholderText='Pilih Tanggal' value={meeting.date} onChange={(e) => handleChangeMeeting('date', e.toLocaleDateString(), index, indexPertemuan)} />
                                    <div className='d-flex align-items-center mt-3'>
                                      <DatePicker
                                        placeholderText='Waktu mulai'
                                        className="form-control digits"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        style={{ width: '150px' }}
                                        timeCaption="Time"
                                        dateFormat="H:mm"
                                        value={meeting.start_meeting}
                                        onChange={(e) => handleChangeMeeting('start_meeting', moment(e).format("H:m"), index, indexPertemuan)} />
                                      <p className='mb-0 text-nunito mx-3' style={{ fontSize: '12px' }}>hingga</p>
                                      <DatePicker
                                        placeholderText='Waktu selesai'
                                        className="form-control digits"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        style={{ width: '150px' }}
                                        timeCaption="Time"
                                        dateFormat="H:mm"
                                        value={meeting.end_meeting}
                                        onChange={(e) => handleChangeMeeting('end_meeting', moment(e).format("H:m"), index, indexPertemuan)} />
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <button
                                className='btn btn-link p-0 text-theme text-roboto font-weight-bold'
                                type='button'
                                onClick={() => handleMateriPertemuan(index, 0, 'add')}
                              > + Tambah Materi Pertemuan</button>
                            </div>
                          </div>
                        ))}
                        <button
                          className='btn btn-link p-0 text-theme text-roboto font-weight-bold'
                          type='button'
                          onClick={handleAddJadwalPertemuan}
                        > + Tambah Pertemuan</button>
                      </div>
                    }

                    <div className='border-bottom py-3'>
                      <h6 className='text-muted mb-3' style={{ fontWeight: '700' }}>Materi Pelatihan</h6>
                      {!InputModulPelatihan[0] &&
                        <button
                          className='btn btn-link p-0 text-theme text-roboto font-weight-bold'
                          type='button'
                          onClick={handleAddModulPelatihan}
                        > + Tambah modul pelatihan</button>
                      }
                      {InputModulPelatihan.length !== 0 &&
                        InputModulPelatihan.map((field, index) => (
                        <div className='mt-3 w-75'>
                          <div className='form-group pl-3 d-flex' style={{ borderLeft: '3px solid #FCC932' }}>
                            <div>
                              <input
                                type="text"
                                className="form-control mr-3"
                                style={{ width: "343px" }}
                                placeholder='Masukan nama modul pelatihan'
                                defaultValue={field.name}
                                onChange={event => handleChange({
                                  target: { name: 'name', value: event.target.value, index: index }
                                })} />
                            </div>
                            <div className='ml-auto'>
                              <button
                                className='btn btn-link p-0 text-theme text-roboto font-weight-bold'
                                type='button'
                                onClick={() => handleRemoveJadwalPertemuan(index, 'modulPelatihan', field)}
                              > X </button>
                            </div>
                          </div>
                          <div className='ml-4'>
                            {field.theories && field.theories.map((theory, indexMateri) => (
                              <div className='d-flex' key={indexMateri}>
                                <div>
                                  <button
                                    className='btn btn-link p-0 text-roboto font-weight-bold mr-3'
                                    type='button'
                                    style={{ color: '#C4C4C4' }}
                                    onClick={() => handleFieldMateri(index, indexMateri, null, theory)}
                                  > X </button>
                                </div>
                                <div className='form-group pl-3 d-flex' style={{ borderLeft: '2px solid #F1F3F6' }}>
                                  <div className='ml-auto'>
                                    <select
                                      defaultValue={theory.type}
                                      className='form-control'
                                      style={{ width: '166px' }}
                                      onChange={(e) => handleChangeMateri('type', e.target.value, index, indexMateri)}
                                    >
                                      {materiOptions.map((options, indexOptions) => (
                                        <option value={options.value} key={indexOptions}>{options.label}</option>
                                      ))}
                                    </select>
                                    <input
                                      style={{ width: '343px' }}
                                      type="text"
                                      className="form-control mt-3"
                                      placeholder='Masukan nama materi'
                                      onChange={(e) => handleChangeMateri('name', e.target.value, index, indexMateri)}
                                      Value={theory.name} />
                                    {theory.type === 'Slide Powerpoint' ?
                                      <div className="d-flex align-items-center mt-3">
                                        <div style={{ width: '12rem' }}>
                                          {labelForm("Upload slide PPT (format .pdf)")}
                                        </div>
                                        <input className="form-control text-dark" type="file" style={{ width: '298px' }} onChange={(e) => {handleChangeMateri('file', e.target.files[0], index, indexMateri)}} />
                                        {!(theory.file instanceof File) && theory.file !== undefined && <p className='ml-3'>{theory.file.split('/').slice(-1)[0]}</p>}
                                      </div>
                                      : theory.type === 'Video' ?
                                        <div className="d-flex align-items-center mt-3">
                                          <div style={{ width: '12rem' }}>
                                            {labelForm("Link video")}
                                          </div>
                                          <input className="form-control text-dark" defaultValue={theory.file} placeholder="Masukkan link video" type="text" style={{ width: '298px' }} onChange={(e) => {handleChangeMateri('file', e.target.value, index, indexMateri)}} />
                                        </div>
                                        : theory.type === 'E-Book' ?
                                          <div className="d-flex align-items-center mt-3">
                                            <div style={{ width: '12rem' }}>
                                              {labelForm("Upload E-Book")}
                                            </div>
                                            <input className="form-control text-dark" type="file" style={{ width: '298px' }} onChange={(e) => {handleChangeMateri('file', e.target.files[0], index, indexMateri)}} />
                                            {!(theory.file instanceof File) && theory.file !== undefined && <p className='ml-3'>{theory.file.split('/').slice(-1)[0]}</p>}

                                          </div>
                                          : ''
                                    }

                                  </div>
                                </div>
                              </div>
                            ))}
                            <button
                              className='btn btn-link p-0 text-theme text-roboto font-weight-bold'
                              type='button'
                              onClick={() => handleFieldMateri(index, 0, 'add')}
                            > + Tambah Materi</button>
                          </div>
                          {InputModulPelatihan.length <= index + 1 &&
                            <button
                              className='btn btn-link p-0 text-theme text-roboto font-weight-bold'
                              type='button'
                              onClick={handleAddModulPelatihan}
                            > + Tambah modul pelatihan</button>
                          }
                        </div>
                      ))}
                    </div>

                    <div className='border-bottom py-3'>
                      <h6 className='text-muted mb-3' style={{ fontWeight: '700' }}>Sertifikat</h6>
                      <p>Fermentum mauris blandit eget accumsan aliquam integer condimentum dolor. Scelerisque commodo consectetur at sit. Velit diam duis facilisis id netus.</p>
                      <div className='form-group'>
                        {labelForm('Upload Sertifikat')}
                        {certificatePreview && <center><img src={certificatePreview} style={{margin: '0 auto', maxHeight: '500px', maxWidth: '100%'}}/></center>}
                        <input className="form-control text-dark mt-3" type="file" onChange={handleuploadCertificate} />
                      </div>
                    </div>
                    {isSubmitting && <>
                      <h3 className='text-center mt-4'>{uploadPercent}%</h3>
                      <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{width: uploadPercent+'%'}} aria-valuenow="25"
                             aria-valuemin="0" aria-valuemax="100"/>
                      </div>
                    </>}

                    <div className='my-5 d-flex align-items-center justify-content-end'>
                      <button disabled={isSubmitting} type='button' className='btn btn-outline-primary btn-pill mr-3' onClick={() => {
                        history.goBack();
                      }}>Batal</button>
                      <button className='btn btn-primary btn-pill' disabled={isSubmitting}>Simpan</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default EditPelatihan;
