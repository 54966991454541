import axios from "axios";
import {RootPath, Headers} from "./Config";

const Post = (path, data = null, onUploadProgress = null) => {
    if( !(data instanceof FormData)){
        let form_data = new FormData();
        for ( let key in data ) {
            form_data.append(key, data[key]);
        }
        data = form_data;
    }

    let headers = Headers;

    if(onUploadProgress){
        headers = {
            ...headers,
            onUploadProgress
        }
    }

    const promise = new Promise((resolve, reject) => {
        axios.post(`${RootPath}/${path}`, data, headers)
            .then((result) => {
                resolve(result.data);
            },(err) => {
                reject(err.response.data);
            });
    });

    return promise;
};

export default Post;
