import Get from "../Get";

const list_transaction = (data) => Get(`ketua-klien/transactions?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const transaction_detail = id => Get(`ketua-klien/transactions/${id}`);

const TransactionKetua = {
    list_transaction,
    transaction_detail
};

export default TransactionKetua;