import Get from "../Get";
import Post from "../Post";

const list_transaction = (data) =>
  Get(
    `admin-c/transactions?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const transaction_detail = (id) => Get(`admin-c/transactions/${id}`);
const list_company_transaction = (data) =>
  Get(
    `admin-c/transactions/company?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const transaction_company_detail = (id) => Get(`admin-c/course-to-participants/${id}`);
const confirm_authorization = (id, data) => Post(`admin-c/transactions/change-status/${id}`, data);

const TransactionAdminC = {
  list_transaction,
  transaction_detail,
  list_company_transaction,
  transaction_company_detail,
  confirm_authorization
};

export default TransactionAdminC;
