import React, { useState, useEffect } from 'react';
import { Users } from 'react-feather';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useLocation, useHistory } from "react-router-dom";
import API from "../../../api";
import swal from 'sweetalert2';

const PesertaTerregistrasi = () => {
  const history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  let page = query.get('page') || 1;
  let per_page = query.get('per_page') || 20;

  const [participants, setParticipant] = useState({
    data:[],
    meta:{
      from:1,
      to:0,
      total: 0,
      current_page: page,
      per_page,
      url: '',
      custom_queries: {per_page}
    }
  });

  const [order, setOrder] = useState('desc');

  const columnsTabel = [
    {
      dataField: 'id',
      text: 'ID',
      headerStyle: () => ({ width: '5%', textAlign: 'left'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: 'peserta',
      text: 'Nama Peserta',
      headerStyle: () => ({ width: '20%', textAlign: 'left'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cell, row) => {
        return(
          <div className='d-flex align-items-center mb-2'>
            {
              row.avatar &&
              <div>
                  <img className="img-40 rounded-circle" style={{height:'40px'}} src={row.avatar} alt="#"></img>
              </div>
            }
            <label className='mb-0 ml-2'>
              <p className='font-weight-bold'>{row.peserta}</p>
            </label>
          </div>
        )
      }
    },
    {
      dataField: 'tanggal',
      text: 'Tanggal Registrasi',
      headerStyle: () => ({ width: '20%', textAlign: 'center'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: 'bidang',
      text: 'Nama Perusahaan',
      headerStyle: () => ({ width: '20%', textAlign: 'center'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: 'status',
      text: 'Status',
      headerStyle: () => ({ width: '10%', textAlign: 'center'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: '',
      text: 'Aksi',
      headerStyle: () => ({ width: '10%', textAlign: 'center',}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cel,row) => {
        return <div className="d-flex justify-content-center">
          <Link to={`/admin/peserta-terregistrasi/detail-peserta?id=${row.id}`}>
          <button className=" mx-1 btn rounded-circle btn-success btn-xs py-1" type="button"> <i className='fa fa-eye'></i> </button>
          </Link>
          <Link to={`/admin/peserta-terregistrasi/ubah-peserta?id=${row.id}`}>
          <button className=" mx-1 btn rounded-circle btn-warning btn-xs py-1" type="button"> <i className='fa fa-pencil'></i> </button>
          </Link>
          <button className=" mx-1 btn rounded-circle btn-danger btn-xs py-1" type="button" onClick={() => handleDelete(row.id)}> <i className='fa fa-trash'></i> </button>
          <button className=" mx-1 btn rounded-circle btn-info btn-xs py-1" type="button" onClick={() => handleResetPassword(row.id)}> <i className='fa fa-refresh'></i> </button>
        </div>;
      },
    }
  ]

  const getParticipants = () => {
    API.adminc.participant.list_participant({page, per_page, order})
        .then(json => {
          let participant = {...participants};
          participant.data = [];

          if(json.data){
            json.data.data.map(val => {
              participant.data.push({
                id: val.id,
                peserta: val.name,
                avatar: val.photo,
                tanggal: val.registered_at+' WIB',
                bidang: val.company_name,
                status: val.status,
                time_added: val.time_added
              })
            });
          }

          participant.meta.custom_queries = {per_page};
          participant.meta.per_page = per_page;
          participant.meta.from = (page - 1) * per_page + 1;
          participant.meta.to = per_page > json.data.total ? json.data.total : page * per_page > json.data.total ? json.data.total : page * per_page;
          participant.meta.total = json.data.total;
          participant.meta.current_page = page;
          participant.meta.total_page = Math.ceil(participant.meta.total / participant.meta.per_page);

          setParticipant(participant);
        }).catch(err => {
      console.log(err);
    })
  }

  useEffect(() => {
    getParticipants();
  }, [page, order, per_page]);

  const handleActiveFilter = e => {
    setOrder(e.target.value);
  };

  const handleSearch = (search) => {
    API.adminc.participant.list_participant({page, per_page, search})
      .then(json => {
        let participant = {...participants};
        participant.data = [];

        if(json.data){
          json.data.data.map(val => {
            participant.data.push({
              id: val.id,
              peserta: val.name,
              avatar: val.photo,
              tanggal: val.registered_at+' WIB',
              bidang: val.company_name,
              status: val.status,
              time_added: val.time_added
            })
          });
        }

        participant.meta.from = (page - 1) * per_page + 1;
        participant.meta.to = per_page > json.data.total ? json.data.total : page * per_page > json.data.total ? json.data.total : page * per_page;
        participant.meta.total = json.data.total;
        participant.meta.current_page = page;
        participant.meta.total_page = Math.ceil(participant.meta.total / participant.meta.per_page);

        setParticipant(participant);
      }).catch(err => {
        console.log(err);
      })
  }

  const handleDelete = (id) => {
    swal.fire({
      title: 'Konfirmasi hapus',
      text: "Anda yakin ingin menghapus peserta ini?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus',
      cancelButtonText: 'Batal',
    }).then(result => {
      if (result.isConfirmed){
        swal.fire({
          onBeforeOpen () {
            swal.showLoading()
          },
          onAfterClose () {
            swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });

        API.adminc.participant.delete_participant(id)
            .then(() => {
              getParticipants();
              swal.fire('Sukses', 'Berhasil menghapus peserta', 'success');
            })
      }
    })

  };

  const handleResetPassword = (id) => {
    swal.fire({
      title: 'Konfirmasi reset password',
      text: "Anda yakin ingin mereset password peserta ini ke 123123 ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, reset password',
      cancelButtonText: 'Batal',
    }).then(result => {
      if (result.isConfirmed){
        swal.fire({
          onBeforeOpen () {
            swal.showLoading()
          },
          onAfterClose () {
            swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        });

        API.adminc.participant.reset_password(id)
            .then(() => {
              swal.fire('Sukses', 'Berhasil me-reset password peserta!', 'success');
            })
      }
    })

  };

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Peserta Terregistrasi'}>
          <li className="breadcrumb-item">
            <Link to="/admin/peserta-terregistrasi" className='text-warning'>
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Peserta</li>
          <li className="breadcrumb-item">Peserta Terregistrasi</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
      <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 pb-3 d-flex align-items-center">
              <div className='mr-auto'>
                <h5 className='text-work text-theme' style={{fontSize:'24px', fontWeight:'600'}}>LIST PESERTA</h5>
                <div className="col mt-3">
                  <div className="row">
                    <form className="search-form contact-search">
                      <div className="form-group mb-0">
                        <input
                          className="form-control badge-pill"
                          type="text"
                          placeholder="Cari peserta..."
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <Link to="/admin/tambah-pelatihan-peserta">
              <div className="row-xl-3 m-1 mt-5">
                <button className=" btn btn-pill btn-outline-primary btn-sm text-work" type="button" style={{ fontWeight: '600' }}><i className="fa fa-pencil"></i> &nbsp;Tambah Pelatihan ke peserta</button>
              </div>
              </Link>
              <Link to="/admin/tambah-bulk-peserta">
                <div className="row-xl-3 m-1 mt-5">
                  <button className=" btn btn-pill btn-outline-primary btn-sm text-work" type="button" style={{ fontWeight: '600' }}><i className="fa fa-pencil"></i> &nbsp;Tambah Bulk Peserta</button>
                </div>
              </Link>
              <Link to="/admin/tambah-peserta">
                <div className="row-xl-3 m-1 mt-5">
                  <button className=" btn btn-pill btn-outline-primary btn-sm text-work" type="button" style={{ fontWeight: '600' }}><i className="fa fa-pencil"></i> &nbsp;Tambah peserta</button>
                </div>
              </Link>
            </div>
            <div className="card-body">
            <div className='d-flex align-items-center justify-content-end mb-3'>
              <p className='my-auto mr-3 text-work' style={{fontSize:'16px'}}>Sort by</p>
              <div>
                <select
                  className="form-control digits"
                  id="exampleFormControlSelect7"
                  onChange={handleActiveFilter}
                >
                  <option value='' disabled selected>Sorting berdasarkan</option>
                  <option value='desc'>Terbaru</option>
                  <option value='asc'>Terlama</option>
                </select>
              </div>
            </div>
              <div className='d-flex align-items-center justify-content-end mb-3'>
                <p className='my-auto mr-3 text-work' style={{fontSize:'16px'}}>Peserta per halaman</p>
                <div>
                  <select
                      className="form-control digits"
                      id="exampleFormControlSelect7"
                      defaultValue={per_page}
                      onChange={e => {
                        history.push({
                          pathname: '/admin/peserta-terregistrasi',
                          search: `?page=1&per_page=${e.target.value}`
                        })
                      }}
                  >
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
              <div className='overflow-auto w-100'>
                <TableComp
                    rowClasses='text-center'
                    title_paggination='peserta'
                    columns={columnsTabel}
                    data={participants}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default PesertaTerregistrasi;
