import React from 'react';
import { PrivateRoute } from '../../component';
import {
    Switch,
    Route,
    useRouteMatch
  } from "react-router-dom";
import DashboardKetua from './dashboard';
import TrainerList from './trainer';
import DetailTrainer from './detailTrainer';
import Chat from './chat';
import ProfilSaya from './profilSaya';
import SertifikatCek from './sertifikatCek';
import DataCompany from './dataCompany';
import ListPelatihan from './listPelatihan';
import PesertaTerregistrasi from './pesertaTerregistrasi';
import TransaksiPeserta from './transaksiPeserta';
import DetailPelatihan from './detailPelatihan';
import DetailPesertaTerregistrasi from './detailPesertaTerregistrasi';
import EditPelatihan from './editPelatihan';
import DetailPelatihanCourse from './detailPelatihanCourse';
import ManajemenDetailPelatihan from './manajemenDetailPelatihan';
import EditCompany from './editCompany';
import AboutUs from './aboutUs';

const Ketua = () => {
    const { path } = useRouteMatch();

    return(
        <Switch>
            <PrivateRoute
                routeAs='ketua'
                redirectPath='/1/ketua/login'
                role_id={3}
            >
                <div className="container-fluid">
                    <Route exact path={path}>
                        <DashboardKetua />
                    </Route>
                    <Route exact path={`${path}/trainer`}>
                       <TrainerList />
                    </Route>
                    <Route exact path={`${path}/about-us`}>
                       <AboutUs />
                    </Route>
                    <Route exact path={`${path}/trainer-detail`}>
                       <DetailTrainer />
                    </Route>
                    <Route exact path={`${path}/chat`}>
                       <Chat />
                    </Route>
                    <Route exact path={`${path}/profil-saya`}>
                       <ProfilSaya   />
                    </Route>
                    <Route exact path={`${path}/cek-sertifikat`}>
                       <SertifikatCek />
                    </Route>
                    <Route exact path={`${path}/data-company`}>
                       <DataCompany />
                    </Route>
                    <Route exact path={`${path}/list-pelatihan`}>
                       <ListPelatihan />
                    </Route>
                    <Route exact path={`${path}/peserta-terregistrasi`}>
                       <PesertaTerregistrasi />
                    </Route>
                    <Route exact path={`${path}/transaksi-peserta`}>
                       <TransaksiPeserta />
                    </Route>
                    <Route exact path={`${path}/detail-pelatihan`}>
                       <DetailPelatihan />
                    </Route>
                    <Route exact path={`${path}/edit-pelatihan`}>
                       <EditPelatihan />
                    </Route>
                    <Route exact path={`${path}/peserta-terregistrasi/detail-peserta`}>
                       <DetailPesertaTerregistrasi />
                    </Route>
                    <Route exact path={`${path}/pelatihan-detail-lainnya`}>
                       <DetailPelatihanCourse />
                    </Route>
                    <Route exact path={`${path}/manajemen-detail-pelatihan`}>
                       <ManajemenDetailPelatihan />
                    </Route>
                    <Route exact path={`${path}/edit-company`}>
                       <EditCompany />
                    </Route>
                </div>
            </PrivateRoute>
        </Switch>
    )
}

export default Ketua;
