import React, { useState, useEffect } from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useLocation } from "react-router-dom";
import { CompanyIcon } from '../../../component/common/constant/menu';
import logo_SDM from '../../../assets/images/logo-SDM.png';
import API from "../../../api";

const DetailTrainer = () => {
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const per_page = 6;
  let page = query.get('page') || 1;

  const [trainer, setTrainer] = useState(null);
  const [order, setOrder] = useState('desc');
  const [courses, setCourse] = useState({
    data:[],
    meta:{
      from:1,
      to:0,
      total: 0,
      current_page: page,
      per_page,
      url: `detail-trainer?id=${query.get("id")}`
    }
  });

  useEffect(() => {
    API.adminc.trainer.trainer_detail(id)
      .then(json => {
        setTrainer(json.data)
      }).catch(err => {
        console.log(err);
      })
  }, [])

  useEffect(() => {
    API.adminc.trainer.course_trainer(id, {page, per_page, order})
      .then(json => {
        let course = {...courses};
        course.data = [];

        course.data = json.data.data

        course.meta.from = (page - 1) * per_page + 1;
        course.meta.to = per_page > json.data.total ? json.data.total : page * per_page > json.data.total ? json.data.total : page * per_page;
        course.meta.total = json.data.total;
        course.meta.current_page = page;
        course.meta.total_page = Math.ceil(course.meta.total / course.meta.per_page);

        setCourse(course)
      }).catch(err => {
        console.log(err);
      })
  }, [page, order])

  const handleActiveFilter = e => {
    setOrder(e.target.value);
  };

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Trainer'}>
          <li className="breadcrumb-item">
            <Link to="/admin/detail-trainer" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Perusahaan</li>
          <li className="breadcrumb-item">Trainer</li>
          <li className="breadcrumb-item">Detail Trainer</li>
        </BreadcrumbComp>
      </div>
      {
        trainer &&
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
            <div className="card-header d-flex">
                <div className='mr-auto'>
                  <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>TRAINER</h4>
                  <span className='text-muted'>Data Trainer</span>
                </div>
                <div>
                  <Link to={`/admin/tambah-trainer?id=${id}`}>
                    <button className=" btn btn-pill btn-outline-primary btn-sm text-work" type="button" style={{ fontWeight: '600' }}><i className="fa fa-pencil"></i> &nbsp;Ubah Data Trainer</button>
                  </Link>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-9'>
                  <div className="card-body">
                    <div className='d-flex mb-4'>
                      <div>
                        {
                          trainer.photo &&
                          <img className="img-80 rounded-circle" src={trainer.photo} style={{height: 80, objectFit: 'cover'}} alt="#" />
                        }
                      </div>
                      <div className='ml-4'>
                        <h3 className='font-weight-bold'>{trainer.name}</h3>
                        <p className='text-muted mb-0'> <i className='fa fa-building-o'></i> {trainer.company.name}</p>
                        <p className='text-muted mb-0'> <i className='fa fa-envelope-o'></i> {trainer.company.email}</p>
                        <p className='text-muted mb-0'> <i className='fa fa-phone'></i> {trainer.company.phone_number}</p>
                        <div className='d-flex align-items-center mt-3'>
                          <p className='mb-0'>Bidang Pelatihan </p>
                          {
                            trainer.categories.map(v => {
                              return <button className="btn btn-pill btn-outline-warning btn-xs ml-3" type="button">{v.name}</button>
                            })
                          }
                        </div>
                      </div>
                    </div>

                    <h5>Kontak Perusahaan Trainer</h5>
                    <div className='row'>
                      <div className='col-md-6'>
                        <p>PIC Admin</p>
                        <div className='d-flex mb-2'>
                          <div>
                            {
                              trainer.company.pic_adminc.photo &&
                              <img className="img-40 rounded-circle" src={trainer.company.pic_adminc.photo} style={{height: 40, objectFit: 'cover'}} alt="#" />
                            }
                          </div>
                          <label className='mb-0 ml-2'>
                            <h6 className='font-weight-bold mb-0'>{trainer.company.pic_adminc.name}</h6>
                            <p className='mb-0'><i className='fa fa-envelope-o'></i> {trainer.company.pic_adminc.email}</p>
                            <p><i className='fa fa-phone'></i>{trainer.company.pic_adminc.phone} (WA / Call)</p>
                          </label>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <p>PIC Ketua</p>
                        <div className='d-flex mb-2'>
                          <div>
                            {
                              trainer.company.pic_chairman.photo &&
                              <img className="img-40 rounded-circle" src={trainer.company.pic_chairman.photo} style={{height: 40, objectFit: 'cover'}} alt="#" />
                            }
                          </div>
                          <label className='mb-0 ml-2'>
                            <h6 className='font-weight-bold mb-0'>{trainer.company.pic_chairman.name}</h6>
                            <p className='mb-0'><i className='fa fa-envelope-o'></i> {trainer.company.pic_chairman.email}</p>
                            <p><i className='fa fa-phone'></i>{trainer.company.pic_chairman.phone} (WA / Call)</p>
                          </label>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <h5>Profil Singkat</h5>
                    <p className='text-muted text-justify'>{trainer.short_profile}</p>
                  </div>
                </div>
                <div className='col-md-3 pt-5'>
                  <div className='w-50 card border p-3 mx-auto'>
                    <img className="img-fluid w-100" src={trainer.company.logo} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header d-flex">
                <div className='mr-auto'>
                  <h5 className='text-warning '>PELATIHAN TRAINER</h5>
                  <span className='text-muted'>Pelatihan Aktif</span>
                </div>
                <div>
                  <select className="form-control btn-pill digits" id="exampleFormControlSelect7" value={order} onChange={handleActiveFilter}>
                    <option>Sorting berdasarkan</option>
                    <option value='desc'>Terbaru</option>
                    <option value='asc'>Terlama</option>
                  </select>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                    {
                      courses.data.map((v, key) => {
                          return <div className='col-md-4' key={key}>
                              <div className='border card shadow-0'>
                                  <div className='row'>
                                      <div className='col-md-12'>
                                          <div className='h-100 rounded-left' style={{ backgroundImage: `url(${v.photo.replace(/ /g, "%20")})`, backgroundSize: 'cover' }}>
                                              <div className='h-100 d-flex flex-column p-3'>
                                                  <div className={`badge ${v.type === 'Online' ? 'badge-primary' : 'badge-danger'} text-wrap w-50`} style={{marginBottom: '150px'}}>
                                                      {v.type}
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className='col-md-12 px-5 py-3'>
                                          <h5 className={`text-muted font-weight-bold text-center`}>{v.nama}</h5>
                                      </div>
                                      <div className='col-md-12 px-5 py-3 mb-2 text-center'>
                                          <Link to={`/trainer/detail-pelatihan?id=${v.id}`}>
                                              <button  className={`btn btn-pill btn-primary btn-sm w-75`}  type="button">Detail pelatihan</button>
                                          </Link>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      })
                    }
                </div>

                <div className="d-md-flex mt-3">
                  <p className="my-auto" style={{color: '#C4C4C4'}}>Menampilkan <b>{courses.meta.from} - {courses.meta.to}</b> dari <b>{courses.meta.total} pelatihan </b></p>
                  <div className="btn-group ml-md-auto" >
                    <div className="">
                      <div>
                        {courses.meta.current_page > 1 &&
                        <>
                          <Link to={`${courses.meta.url}&page=${parseInt(1)}`} style={{color: 'black'}}>
                            <a className="btn rounded-circle btn-xs mx-1">
                              <i className="fa fa-angle-double-left"></i>
                            </a>
                          </Link>
                          <Link to={`${courses.meta.url}&page=${parseInt(courses.meta.current_page) - 1}`} style={{color: 'black'}}>
                            <a  className="btn rounded-circle btn-xs mx-1">
                              <i className="fa fa-angle-left"></i>
                            </a>
                          </Link>
                        </>
                        }
                        {
                          parseInt(courses.meta.current_page) + 3 >= courses.meta.total_page ?
                              courses.meta.total_page - 3 <= 0 ?
                                  <>
                                    {
                                      Array(courses.meta.total_page).fill(1).map((x, y) => x + y)
                                          .map(val => {
                                            return val === parseInt(courses.meta.current_page) ?
                                                <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{val}</a> :
                                                <Link to={`${courses.meta.url}&page=${val}`} style={{color: 'black'}}>
                                                  <a className="btn rounded-circle btn-xs mx-1">{val}</a>
                                                </Link>
                                          })
                                    }
                                  </> :
                                  <>
                                    {
                                      Array(4).fill(courses.meta.total_page - 3).map((x, y) => x + y)
                                          .map(val => {
                                            return val === parseInt(courses.meta.current_page) ?
                                                <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{val}</a> :
                                                <Link to={`${courses.meta.url}&page=${val}`} style={{color: 'black'}}>
                                                  <a className="btn rounded-circle btn-xs mx-1">{val}</a>
                                                </Link>
                                          })
                                    }
                                  </>
                              :
                              <>
                                <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{courses.meta.current_page}</a>
                                <Link to={`${courses.meta.url}&page=${parseInt(courses.meta.current_page) + 1}`} style={{color: 'black'}}>
                                  <a className="btn rounded-circle btn-xs mx-1">{parseInt(courses.meta.current_page) + 1}</a>
                                </Link>
                                <Link to={`${courses.meta.url}&page=${parseInt(courses.meta.current_page) + 2}`} style={{color: 'black'}}>
                                  <a className="btn rounded-circle btn-xs mx-1">{parseInt(courses.meta.current_page) + 2}</a>
                                </Link>
                                <Link to={`${courses.meta.url}&page=${parseInt(courses.meta.current_page) + 3}`} style={{color: 'black'}}>
                                  <a className="btn rounded-circle btn-xs mx-1">{parseInt(courses.meta.current_page) + 3}</a>
                                </Link>
                              </>
                        }
                        {
                          parseInt(courses.meta.current_page) !== courses.meta.total_page &&
                          <>
                            <Link to={`${courses.meta.url}&page=${parseInt(courses.meta.current_page) + 1}`} style={{color: 'black'}}>
                              <a  className="btn rounded-circle btn-xs mx-1">
                                <i className="fa fa-angle-right"></i>
                              </a>
                            </Link>
                            <Link to={`${courses.meta.url}&page=${courses.meta.total_page}`} style={{color: 'black'}}>
                              <a className="btn rounded-circle btn-xs mx-1">
                                <i className="fa fa-angle-double-right"></i>
                              </a>
                            </Link>
                          </>
                        }
                      </div>
                      {/* <Pagination
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <i className='fa fa-angle-double-left' />, icon: true }}
                        lastItem={{ content: <i className='fa fa-angle-double-right' />, icon: true }}
                        prevItem={{ content: <i className='fa fa-angle-left' />, icon: true }}
                        nextItem={{ content: <i className='fa fa-angle-right' />, icon: true }}
                        defaultActivePage={courses.meta.current_page}
                        totalPages={courses.meta.last_page}
                        onPageChange={props.handlePaggination} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default DetailTrainer;
