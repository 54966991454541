import Post from "../Post";
import Get from "../Get";

const update_account = data => Post(`peserta/account`, data);
const update_profile = data => Post(`peserta/profile`, data);
const get_update_profile = data => Get(`peserta/profile?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);

const ParticipantPeserta = {
    update_account,
    update_profile,
    get_update_profile
};

export default ParticipantPeserta;
