import React, { useState, useEffect, useRef } from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useHistory } from "react-router-dom";
import { CompanyIcon } from '../../../component/common/constant/menu';
// EDITOR
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment'
import API from "../../../api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";

const EditCompany = () => {
  const user = JSON.parse(localStorage.getItem('data'));
  const history = useHistory();
  const adminPhotoRef = useRef(null);
  const chairmanPhotoRef = useRef(null);
  const [company, setCompany] = useState(null);

  const [uploadKTPPreview, setuploadKTPPreview] = useState(null);
  const [uploadKTPPreviewUrl, setuploadKTPPreviewUrl] = useState("");

  const [listProvinsi, setListProvinsi] = useState([]);
  const [listKota, setListKota] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);
  const [listKategori, setListKategori] = useState([]);

  const [provinsi, setProvinsi] = useState(null);
  const [kota, setKota] = useState(null);
  const [kecamatan, setKecamatan] = useState(null);

  const [name, setName] = useState(null);
  const [kode_pos, setKodePos] = useState(null);
  const [address, setAddress] = useState(null);
  const [phone, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [since, setSince] = useState(null);
  const [category, setCategory] = useState([]);
  const [short_profile, setShortProfile] = useState(null);
  const [director_name, setDirectorName] = useState(null);
  const [director_ktp, setDirectorKtp] = useState(null);
  const [adminName, setAdminName] = useState(null);
  const [adminEmail, setAdminEmail] = useState(null);
  const [adminPhone, setAdminPhone] = useState(null);
  const [adminPhoto, setAdminPhoto] = useState(null);
  const [adminPhotoPreview, setAdminPhotoPreview] = useState(null);
    const [chairmanName, setChairmanName] = useState(null);
    const [chairmanEmail, setChairmanEmail] = useState(null);
    const [chairmanPhone, setChairmanPhone] = useState(null);
    const [chairmanPhoto, setChairmanPhoto] = useState(null);
    const [chairmanPhotoPreview, setChairmanPhotoPreview] = useState(null);

  const handleuploadKTPChange = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setDirectorKtp(file)

    reader.onloadend = () => {
      setuploadKTPPreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

    const handleAdminPhoto = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        setAdminPhoto(file)

        reader.onloadend = () => {
            setAdminPhotoPreview(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handleChairmanPhoto = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        setChairmanPhoto(file);

        reader.onloadend = () => {
            setChairmanPhotoPreview(reader.result);
        };

        reader.readAsDataURL(file);
    };

  useEffect(() => {
    if (uploadKTPPreviewUrl) {
      setuploadKTPPreview(uploadKTPPreviewUrl);
    } else {
      setuploadKTPPreview(false);
    }
  }, [uploadKTPPreviewUrl]);

  useEffect(() => {
    API.adminc.company.get_one_update()
      .then(json => {
        console.log(json.data);
        setCompany(json.data)

        setName(json.data.name)
        setKodePos(json.data.postal_code)
        setAddress(json.data.address)
        setPhoneNumber(json.data.phone_number)
        setEmail(json.data.email)
        setSince(json.data.since)
        setShortProfile(json.data.short_profile)
        setDirectorName(json.data.director_name);
        setAdminName(json.data.pic_adminc.name);
        setAdminEmail(json.data.pic_adminc.email);
        setAdminPhone(json.data.pic_adminc.phone);
        setChairmanName(json.data.pic_chairman.name);
        setChairmanEmail(json.data.pic_chairman.email);
        setChairmanPhone(json.data.pic_chairman.phone);

        const kategori = []

        json.data.categories.map(v => {
          kategori.push({
            id: v.id,
            name: v.name,
          })
        })

        setCategory(kategori)

        API.rajaongkir.kecamatan(json.data.region_id)
          .then(json => {
            setProvinsi(json.province_id)
            setKota(json.city_id)

            API.rajaongkir.list_provinsi()
              .then(json => {
                setListProvinsi(json)
              }).catch(err => {
                console.log(err);
              })

            API.rajaongkir.list_kota(json.province_id)
              .then(json => {
                setListKota(json)
              }).catch(err => {
                console.log(err);
              })

            API.rajaongkir.list_kecamatan(json.city_id)
              .then(json => {
                setListKecamatan(json)
              }).catch(err => {
                console.log(err);
              })

          }).catch(err => {
            console.log(err);
          })
      }).catch(err => {
        console.log(err);
      })

      API.category.get_all()
        .then(json => {
          setListKategori(json.data)
        }).catch(err => {
          console.log(err);
        })

  }, [])

  const handleAddCategory = (val) => {
    const kategori = Object.assign([], category)

    const found = kategori.some(el => el.id === val.id);

    if(!found){
      kategori.push({
        id: val.id,
        name: val.name,
      })
    }

    console.log(kategori)
    setCategory(kategori)
  }

  const handleRemoveCategory = (index) => {
    const kategori = Object.assign([], category)

    delete kategori[index]

    console.log(kategori);
    setCategory(kategori)
  }

  const handleSubmited = (e) => {
    e.preventDefault()

    let cat = []

    category.map(v => {
      cat.push(v.id)
    })

    let category_data = JSON.stringify(cat)

    let data = new FormData()

    data.append('name', name)
    data.append('since', since)
    data.append('address', address)
    data.append('region_id', kecamatan)
    data.append('phone_number', phone)
    data.append('email', email)
    data.append('categories', category_data)
    data.append('short_profile', short_profile)
    data.append('director_name', director_name)
    data.append('admin_name', adminName);
    data.append('admin_email', adminEmail);
    data.append('admin_phone', adminPhone);
    data.append('chairman_name', chairmanName);
    data.append('chairman_email', chairmanEmail);
    data.append('chairman_phone', chairmanPhone);

    if(director_ktp){
      data.append('director_ktp', director_ktp)
    }

  if(adminPhoto){
      data.append('admin_photo', adminPhoto);
  }

  if(chairmanPhoto){
      data.append('chairman_photo', chairmanPhoto);
  }

    API.adminc.company.edit_company(data)
      .then(json => {
          let profile = {
              ...user,
              ...json.adminc,
              company_name: json.company.name,
              company_logo: json.company.logo,
          };
          localStorage.setItem('data', JSON.stringify(profile));
          toast.success("Data Perusahaan Berhasil Diubah", { position: toast.POSITION.TOP_CENTER });
        setTimeout(() => {
          history.push('/admin/company-data')
        }, 5000)
      }).catch(err => {
        console.log(err);
        toast.error("Data Perusahaan Tidak Berhasil Diubah", { position: toast.POSITION.TOP_CENTER });
      })
  }

  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <BreadcrumbComp title={'Company Data'}>
          <li className="breadcrumb-item">
            <Link to="/admin/edit-company" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Perusahaan</li>
          <li className="breadcrumb-item">Company Data</li>
          <li className="breadcrumb-item">Edit Company Data</li>
        </BreadcrumbComp>
      </div>

      <div className='col-md-12'>
          <Link to='/admin/company-data'>
            <p className='btn p-0 text-theme'>
              <i className="fa fa-arrow-left"></i> Kembali
            </p>
          </Link>
      </div>

      {
        company &&
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
            <div className="card-header d-flex pb-3">
              <div className='mr-auto'>
                <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>EDIT COMPANY DATA</h4>
                <span className='text-muted'>Ubah Data Perusahaan</span>
              </div>
            </div>
            <div className="mx-4 mb-2">
                <hr/>
            </div>

              <form className="row mx-4" onSubmit={handleSubmited}>

                {/* Kolom kiri */}
                  <div className='col-md-5 pr-5 ml-1 mr-5 mb-4'>
                    <div className="form-group ">
                      <label htmlFor="namaPerusahaan" className="font-weight-bold">Nama Perusahaan</label>
                      <input type="text" className="form-control" name="nama_perusahaan" id="namaPerusahaan"  value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="form-group mb-0 mt-1">
                      <label htmlFor="alamat"><b>Alamat</b></label>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <select disabled={listProvinsi.length === 0} name="provinsi" className="form-control" id="provinsi" onChange={e => {
                          setListKota([]);
                          setListKecamatan([]);

                          API.rajaongkir.list_kota(e.target.value)
                              .then(json => {
                                setListKota(json);
                              });
                        }} >
                          <option value="#">Provinsi</option>
                          {
                            listProvinsi.map(v => {
                              return <option value={v.province_id} selected={provinsi == v.province_id}>{v.province}</option>
                            })
                          }
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        <select disabled={listKota.length === 0} name="kabupaten" className="form-control" id="kabupaten" onChange={e => {
                          setListKecamatan([]);

                          API.rajaongkir.list_kecamatan(e.target.value)
                              .then(json => {
                                setListKecamatan(json);
                              }).catch(err => {
                                console.log(err);
                              });
                        }} >
                          <option value="#">Kabupaten</option>
                          {
                            listKota.map(v => {
                              return <option value={v.city_id} selected={kota == v.city_id}>{v.city_name}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <select disabled={listKecamatan.length === 0} name="kecamatan" className="form-control" id="kecamatan" onChange={(e)=>setKecamatan(e.target.value)} >
                          <option value="#">Kecamatan</option>
                          {
                            listKecamatan.map(v => {
                              return <option value={v.subdistrict_id} selected={company.region_id == v.subdistrict_id}>{v.subdistrict_name}</option>
                            })
                          }
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        <input type="text" name="kodepos" className="form-control" id="alamat" value={company.postal_code} />
                      </div>
                      <textarea className="form-control" name="alamat" id="alamatKantor"  placeholder="Nama Jalan / Alamat Detail"
                      value={address} rows="5" onChange={(e) => setAddress(e.target.value)}>{address}</textarea>
                    </div>
                  </div>

                  {/* Kolom Kanan */}
                  <div className='ml-5 col-md-3'>
                    <div className="form-group ">
                    <label htmlFor="tahunBerdiri"><b>Tahun Berdiri</b></label>
                    <input type="date" placeholder="Pilih tanggal" className="form-control" id="tahunBerdiri" value={since} onChange={(e) => setSince(e.target.value)} />
                    </div>
                  </div>

                  {/* Kiri */}
                  <div className='col-md-5 pr-5 ml-2 mr-5 mb-4'>
                    <div className="form-group ">
                      <label htmlFor="inputNmor"><b>Nomor Telepon</b></label>
                      <input type="phone" className="form-control" name="nomorTelepon" id="inputNmor"  value={phone} onChange={(e) => setPhoneNumber(e.target.value)} />
                    </div>
                    <div className="form-group mt-1">
                      <label htmlFor="bidang"><b>Bidang Pelatihan</b></label>
                      {/* <input type="text" className="form-control" name="bidangpelatihan" id="bidang"  placeholder="Ketikkan bidang pelatihan..."/> */}
                    </div>
                    <div className="d-flex">
                      {
                        category.map((v, index) => {
                          return <div className="mr-2">
                            <p className="btn btn-warning" >{v.name} &nbsp;<a className="text-light"><i class="fa fa-times " onClick={() => handleRemoveCategory(index)}></i></a></p>
                          </div>
                        })
                      }
                    </div>
                  </div>

              {/* Kanan */}
                  <div className='ml-5 col-md-4'>
                    <div className="form-group ">
                      <label htmlFor="email"><b>Email</b></label>
                      <input type="email" className="form-control" name="email" id="email"  placeholder="Masukkan email trainer " value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-5" >
                        <ul>
                          {
                            listKategori.map(v => {
                              return <li><a className="text-muted" style={{ cursor: 'pointer' }} onClick={() => handleAddCategory(v)}><big><i class="fa fa-plus-circle"></i>&nbsp;{v.name}</big></a></li>
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>



                {/* Kiri */}
                  <div className="mt-5 ml-2 col-md-12">
                    <div className="form-group mb-3">
                        <label htmlFor="profil"><b>Profil Singkat</b></label>
                        {
                            short_profile !== null &&
                            <CKEditor
                                editor={ ClassicEditor }
                                config={{
                                    toolbar: [ 'bold', 'italic', 'underline' ]
                                }}
                                data={short_profile}
                                onChange={ ( event, editor ) => {
                                        setShortProfile(editor.getData())
                                    }
                                }
                            />
                        }

                        <hr/>
                    </div>
                  </div>

                {/* Kiri */}
                <div className='col-md-5 pr-5 ml-2 mr-5'>
                    <div className="form-group ">
                      <label htmlFor="namaDirektur"><b>Nama Direktur</b></label>
                      <input type="text" className="form-control" name="namaDirektur" id="namaDirektur"  value={director_name} onChange={(e) => setDirectorName(e.target.value)} />
                    </div>
                    <div className="form-group mt-1">
                      <label htmlFor="KyaTPDirektur"><b>KTP Direktur</b></label>
                    </div>

                    <div className="form-group">
                      {/* Preview */}
                      <img className="img-fluid w-50 mb-3 h-50" src={uploadKTPPreview || company.director_ktp} alt="KTP"/>
                      {/* Input */}
                      <div className="button-custom w-50 py-3">
                        <div className="btn btn-pill btn-primary upload-image">
                          <span>
                            <i className="fa fa-cloud-upload"></i> &ensp; Upload KTP
                          </span>
                          <input
                            id="file-select"
                            type="file"
                            onChange={handleuploadKTPChange}
                          />
                        </div>
                      </div>
                      <p style={{ color : 'red' }} >*Ukuran Foto KTP Maksimal 100KB</p>
                    </div>


                </div>

                {/* Kiri */}
                <div className="col-md-12 m-5">
                  <div className="mt-3">
                    <label><b>Kontak Perusahaan</b></label>
                  </div>
                  <div className="form-row my-2">
                    <div className="col-md-6">
                      <label htmlFor="">PIC Admin</label>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="">PIC Ketua</label>
                    </div>
                  </div>
                  <div className="form-row">
                    {/* kiri */}
                      <div className="form-group col-md-6">
                        <div className="form-row">
                          <div className="form-group col-md-3">
                            <img src={adminPhotoPreview ? adminPhotoPreview : company.pic_adminc.photo} style={{height:100, width: 100, objectFit: 'cover', cursor: 'pointer'}} onClick={() => {
                                adminPhotoRef.current.click()
                            }} className="rounded-circle"/>
                          </div>
                          <input type="file" style={{display: 'none'}} ref={adminPhotoRef} onChange={handleAdminPhoto}/>
                          <div className="form-group col-md-6">
                          <div className="form-group ">
                            <label htmlFor="namaadmin"><b>Nama</b></label>
                            <input type="text" className="form-control" name="namaAdmin" id="namaadmin" value={adminName} onChange={(e) => setAdminName(e.target.value)} />
                          </div>
                          <div className="form-group ">
                            <label htmlFor="emailAdmin"><b>Email</b></label>
                            <input type="email" className="form-control" name="emailAdmin" id="emailAdmin" value={adminEmail} onChange={(e) => setAdminEmail(e.target.value)} />
                          </div>
                          <div className="form-group ">
                            <label htmlFor="namaPerusahaan"><b>Nomor Telepon</b></label>
                            <input type="text" className="form-control" name="nomorTeleponadmin" id="namaPerusahaan" value={adminPhone}  onChange={(e) => setAdminPhone(e.target.value)}/>
                          </div>
                          </div>
                        </div>
                      </div>

                    {/* kanan */}
                    <div className="form-group col-md-6">
                        <div className="form-row">
                          <div className="form-group col-md-3">
                            <img src={chairmanPhotoPreview ? chairmanPhotoPreview :company.pic_chairman.photo} style={{height:100, width: 100, objectFit: 'cover', cursor: 'pointer'}} className="rounded-circle" onClick={() => chairmanPhotoRef.current.click()}/>
                          </div>
                            <input type="file" style={{display: 'none'}} ref={chairmanPhotoRef} onChange={handleChairmanPhoto}/>
                          <div className="form-group col-md-6">
                          <div className="form-group ">
                            <label><b>Nama</b></label>
                            <input type="text" className="form-control" name="namaKetua" value={chairmanName} onChange={(e) => setChairmanName(e.target.value)}/>
                          </div>
                          <div className="form-group ">
                            <label><b>Email</b></label>
                            <input type="email" className="form-control" name="emailKetua"   value={chairmanEmail} onChange={(e) => setChairmanEmail(e.target.value)} />
                          </div>
                          <div className="form-group ">
                            <label><b>Nomor Telepon</b></label>
                            <input type="text" className="form-control" name="nomorTeleponKetua" value={chairmanPhone} onChange={(e) => setChairmanPhone(e.target.value)} />
                          </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>

                <div className='col-md-5 pr-5 ml-2 mr-5'>

                </div>


                <div className="col-md-12 m-5">
                  <div className="text-right mb-5">
                    <button type="cancel" className="btn btn-outline-primary btn-pill mr-2">Batal</button>
                    <button type="submit" className="btn btn-primary btn-pill">Simpan</button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      }

    </>
  )
}

export default EditCompany;
