import Get from "../Get";
import Post from "../Post";
const my_profile = () => Get(`admin-c/my-profile`);
const update_profile = (data) => Post(`admin-c/my-profile/profile`, data);
const update_account = (data) => Post(`admin-c/my-profile/account`, data);

const ProfileAdminC = {
  my_profile,
  update_profile,
  update_account,
};

export default ProfileAdminC;
