import React, { useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import { useLocation } from "react-router-dom";
import IMGEmailVerifikasi from '../../assets/images/email-konfirmasi-verifikasi.png';
import VerifPhoneBerhasil from '../../assets/images/Verif-phone-berhasil.png';
import OtpInput from 'react-otp-input';
import API from "../../api";

const VerifEmailBerhasil = (props) => {
    const [VerifPhone, setVerifPhone] = useState(false);
    const [counter, setCounter] = useState(60);
    const [otpValue, setotpValue] = useState(0);
    const [isResend, setIsResend] = useState(false);

    let query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        const timer =
          counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
      }, [counter]);

    useEffect(() => {
        if(otpValue.length === 6)
            API.user.verify_phone(query.get('phone'), {kode: otpValue})
                .then(json => {
                    setVerifPhone('Berhasil');
                })
                .catch(e => {
                    alert(e.message);
                });
    }, [otpValue]);

    const resend = () => {
        setIsResend(true);
        API.user.forgot_password_resend({phone_number: query.get('phone')})
            .then(json => {
                alert('Sukses mengirim ulang kode OTP');
                setIsResend(false);
            })
            .catch(e => {
                alert(e.message);
                setIsResend(false);
            })
    };


    if(VerifPhone === 'Berhasil'){
        return(
            <div className='text-center mx-auto'>
                <img src={VerifPhoneBerhasil} className='w-25' alt='Omind' />

                <h4 className='font-weight-bold mt-5'>Verifikasi Nomor Telepon Berhasil</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed aliquet et lectus turpis in mattis vitae. Magna viverra mauris sit</p>

                <Button type='button' className="btn btn-outline-primary btn-block w-50 mx-auto mt-5" primary loading={false} basic onClick={() => props.setviewLogin('login')}>Kembali ke Login</Button>

            </div>
        )
    }

    if(VerifPhone === 'OTPForm'){
        return(
            <div className='text-center mx-auto'>
            <h4>Masukkan OTP untuk Verifikasi Ubah Password Anda</h4>
            <p>Kode OTP telah dikirimkan ke nomor<br/><span className='font-weight-bold'>{query.get('phone')}</span></p>
            <div className='w-100'>
                <OtpInput
                    inputStyle={{
                        width: '100%',
                        height: '3rem',
                        margin: '20px 1rem',
                        fontSize: '2rem',
                        borderRadius: 4,
                        border: '2px solid rgba(0,0,0,0.3)',
                    }}
                    onChange={otp => setotpValue(otp)}
                    numInputs={6}
                    value={otpValue}
                    separator={<span>-</span>}
                />
            </div>
            {counter ?
                <>
                    <p>Anda dapat mengirim ulang kode verifikasi setelah {counter} detik</p>
                    <Button type='button' className="btn btn-primary btn-block" secondary disabled loading={false}>Kirim Ulang Kode</Button>
                </>
                :
                <>
                    <p>Belum menerima kode verifikasi?</p>
                        <Button type='button' className="btn btn-primary btn-block" primary loading={false} disabled={isResend} onClick={resend}>Kirim Ulang Kode</Button>
                </>
            }
            <Button type='button' className="btn btn-outline-primary btn-block mt-3" primary loading={false} basic onClick={()=>props.setviewLogin('login')}>Kembali ke Login</Button>
        </div>
        )
    }

    return(
        <div className='text-center mx-auto'>
            <img src={IMGEmailVerifikasi} className='w-25' alt='Omind' />

            <h4 className='font-weight-bold mt-5'>Verifikasikan Email Berhasil</h4>
            <p>Email Anda telah berhasil diverifikasi Sekarang Anda dapat menggunakan SDM-Training</p>

            <p className='mt-5'>Ingin verifikasi Nomor Ponsel?</p>
            <div className='d-flex mt-3 w-50 mx-auto'>
                <Button type='button' className="btn btn-primary btn-block mr-3" primary loading={false} basic onClick={() => setVerifPhone('OTPForm')}>Ya</Button>
                <Button type='button' className="btn btn-outline-primary btn-block mt-0" primary loading={false} basic onClick={() => props.setviewLogin('login')}>Nanti Saja</Button>
            </div>
            <Button type='button' className="btn btn-outline-primary btn-block w-50 mx-auto mt-5" primary loading={false} basic onClick={() => props.setviewLogin('login')}>Ke Dashboard</Button>

        </div>
    )
}

export default VerifEmailBerhasil;
