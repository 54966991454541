import React from 'react';

const BreadcrumbComp = (props) => {
    return(
        <div className="row">
            <div className="col">
                <div className="page-header-left">
                    <h3 className='text-capitalize text-nunito' style={{fontWeight: '900'}}>{props.title}</h3>
                    <ol className="breadcrumb pull-right">
                        {props.children}
                    </ol>
                </div>
            </div>
            {props.menuRight && 
                <div>
                    {props.menuRight}
                </div>
            }
        </div>
    )
}

export default BreadcrumbComp;