import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import { Form, Checkbox, Message, Button } from 'semantic-ui-react';
import API from "../../api";

const FormLogin = (props) => {
    const [DataLogin, setDataLogin] = useState({});
    const [ErrMessage, setErrMessage] = useState(false);
    const [isLoading, setisLoading] = useState(false);

    const { id } = useParams();

    const history = useHistory();

    const handleChange = (event) => {
        setDataLogin({
            ...DataLogin,
            company_id: id,
            [event.target.name]: event.target.value
        })
      }

    const handleSubmited = () => {
        setisLoading(true);

        let role_id = 0;
        switch (props.as) {
            case 'peserta': role_id = 5; break;
            case 'trainer': role_id = 4; break;
            case 'ketua': role_id = 3; break;
            case 'admin': role_id = 2; break;
            case 'superadmin': role_id = 1; break;
        }

        API.user.login(DataLogin)
            .then(json => {
                if(json.data.user.role.id === role_id){
                    localStorage.setItem(`${props.as}Token`, json.data.token_access);
                    localStorage.setItem(`data`, JSON.stringify(json.data.user));
                    window.location.reload();
                }
                else{
                    setErrMessage('Akun ini bukan akun '+props.as);
                    setisLoading(false);
                }
            }).catch(e => {
                if(e.message === 'unauthorized')
                    e.message = 'E-mail atau password salah.';
                setErrMessage(e.message);
                setisLoading(false);
            });

        /*if(DataLogin.email === 'tester@mail.com'){
            localStorage.setItem(`${props.as}Token`, true);
            window.location.reload();
        } else {
            setErrMessage('Email anda belum diverifikasi!');
            setisLoading(false);
        }*/
    }

    return(
        <>
            {
                props.as !== 'superadmin' &&
                <div className='mb-3'>
                    <button className={`btn btn-pill btn-${props.as !== 'peserta' ? 'outline-' : ''}primary btn-sm mr-3`} type="button"
                            onClick={() => {history.push(`/${id}/login`);}}>Peserta</button>
                    <button className={`btn btn-pill btn-${props.as !== 'trainer' ? 'outline-' : ''}primary btn-sm mr-3`} type="button"
                            onClick={() => {history.push(`/${id}/trainer/login`);}}>Trainer</button>
                    <button className={`btn btn-pill btn-${props.as !== 'ketua' ? 'outline-' : ''}primary btn-sm mr-3`} type="button"
                            onClick={() => {history.push(`/${id}/ketua/login`);}}>Ketua Klien</button>
                    <button className={`btn btn-pill btn-${props.as !== 'admin' ? 'outline-' : ''}primary btn-sm`} type="button"
                            onClick={() => {history.push(`/${id}/admin/login`);}}>Admin C</button>
                </div>
            }

            <Form
                className='w-100 theme-form'
                onSubmit={handleSubmited}
            >
                <div>
                    <h2>Masuk</h2>
                    <h6>Enter your Username and Password </h6>
                </div>
                {ErrMessage &&
                    <div className='form-error_message mt-3 d-flex'>
                        <label className='mb-0 mr-auto'>
                            {ErrMessage}
                        </label>
                        <label className='m-0 p-0 btn' onClick={()=>setErrMessage(false)}>
                            X
                        </label>
                    </div>
                }
                <div className="form-group mt-4">
                    <input className="form-control" type="text" name="email" required onChange={handleChange} placeholder="Alamat Email"  />
                </div>
                <div className="form-group">
                    <input className="form-control" type="password" name="password" required onChange={handleChange} placeholder="Password"  />
                </div>
                <div className="checkbox p-0">
                    <input id="checkbox1" type="checkbox" />
                    <label htmlFor="checkbox1">Ingat Saya</label>
                </div>
                <div className="form-group form-row mt-3 mb-0">
                    <button disabled={isLoading} className="btn btn-primary btn-block" type="submit">Login</button>
                </div>

                <Form.Field className='d-block d-md-flex mt-3'>
                    <p> Belum daftar ? <Link onClick={()=>props.setviewLogin('register')}>&nbsp; Daftar</Link> </p>
                    <p className='ml-auto'><Link onClick={()=>props.setviewLogin('resetPasswordByEmail')}>Lupa Password</Link></p>
                </Form.Field>
            </Form>
        </>
    )
}

export default FormLogin;
