import Post from "../Post";
import Get from "../Get";
import Delete from "../Delete";

const get_trainer = (data) => Get(`admin-c/trainers?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const trainer_detail = id => Get(`admin-c/trainers/${id}`);
const delete_trainer = id => Delete(`admin-c/trainers/${id}`);
const course_trainer = (id, data) => Get(`admin-c/trainers/courses/${id}?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const add_trainer = (data) => Post(`admin-c/trainers`, data);
const get_update = id => Get(`admin-c/trainers/${id}/update`);
const update_trainer = (id, data) => Post(`admin-c/trainers/${id}/update`, data);

const TrainerAdminC = {
    get_trainer,
    trainer_detail,
    delete_trainer,
    course_trainer,
    add_trainer,
    get_update,
    update_trainer,
};

export default TrainerAdminC;
