import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { TransaksiIcon } from "../../../component/common/constant/menu";
import { BreadcrumbComp, TableComp } from "../../../component";
import DatePicker from "react-datepicker";
import API from "../../../api";
import moment from 'moment';
import swal from 'sweetalert2';
import {checkEmpty} from "../../../helper";

const TambahKodePromo = (props) => {
  const {promo, company_id} = props;

  const [formData, setformData] = useState({});
  const [company, setCompany] = useState(null);
  const [category, setCategory] = useState(null);
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const labelForm = (text) => (
    <label className="text-work" style={{ fontSize: "16px" }}>
      {text}
    </label>
  );
  useEffect(() => {
    if(promo){
      setformData(promo);
    }

    API.superadmin.company
      .list_perusahaan({per_page: 9999})
      .then((json) => {
        setCompany(json.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    API.superadmin.category
      .list_category()
      .then((json) => {
        console.log(json);
        setCategory(json.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmited = (status) => {
    if(checkEmpty(formData, [
      {
        name: 'name',
        alias: 'Nama'
      },
      {
        name: 'start_promo',
        alias: 'Tanggal mulai promo'
      },
      {
        name: 'end_promo',
        alias: 'Tanggal selesai promo'
      },
      {
        name: 'status',
        alias: 'Status'
      },
      {
        name: 'company_id',
        alias: 'Perusahaan'
      },
      {
        name: 'discount_type',
        alias: 'Tipe Diskon'
      },
      {
        name: 'nominal',
        alias: 'Nominal'
      },
      {
        name: 'condition',
        alias: 'Syarat'
      },
    ])){
      if(moment(formData.end_promo).diff(moment(formData.start_promo)) < 1){
        swal.fire('Error', 'Tanggal mulai tidak boleh lebih dari tanggal selesai', 'error');
      }
      else{
        status !== "Submit" &&
        setformData({
          ...formData,
          status: status,
        });

        if(props.isUpdate){
          let temp_formdata = Object.fromEntries(Object.entries({...formData}).filter(([k, v]) => v !== null));

          API.superadmin.promo
              .update_promo(formData.id, temp_formdata)
              .then((json) => {
                console.log(json);
                props.setPromo({});
                props.settambahPromo(false);
              })
              .catch((err) => {
                console.log(err);
              });
        }
        else{
          API.superadmin.promo
              .insert_promo(formData)
              .then((json) => {
                console.log(json);
                props.setPromo({});
                props.settambahPromo(false);
              })
              .catch((err) => {
                console.log(err);
              });
        }
      }
    }
  };
  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"List Kode Promo"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/data-company" className="text-warning">
              <TransaksiIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">Kode Promo Perusahaan</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h5
                className="text-work text-theme"
                style={{ fontSize: "24px", fontWeight: "600" }}
              >
                {" "}
                {props.isUpdate ? 'Edit' : 'Tambah'} Kode Promo{" "}
              </h5>
            </div>
            <div className="card-body">
              <h6
                className="text-muted mb-3"
                style={{ fontWeight: "bold", fontSize: "18px" }}
              >
                Detail Promo
              </h6>
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      {labelForm("Nama Promo")}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Masukan nama promo"
                        style={{ width: "343px" }}
                        name="name"
                        onChange={handleChange}
                        value={formData.name}
                      />
                    </div>
                    <div className="form-group">
                      {labelForm("Durasi")}
                      <div className="d-flex align-items-center">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Pilih tanggal"
                          name="start_promo"
                          style={{ width: "150px" }}
                          onChange={handleChange}
                          value={formData.start_promo}
                        />
                        <p
                          className="mb-0 text-nunito mx-3"
                          style={{ fontSize: "12px" }}
                        >
                          hingga
                        </p>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Pilih tanggal"
                          name="end_promo"
                          style={{ width: "150px" }}
                          onChange={handleChange}
                          value={formData.end_promo}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      {labelForm("Status")}
                      <select
                        className="form-control digits "
                        defaultValue="default"
                        style={{ width: "164px" }}
                        name="status"
                        onChange={handleChange}
                        value={formData.status}
                      >
                        <option value="default" disabled>
                          Pilih Status
                        </option>
                        <option value="Aktif">Aktif</option>
                        <option value="Non Aktif">Non Aktif</option>
                        <option value="Draft">Draft</option>
                      </select>
                    </div>
                    <div className="form-group">
                      {labelForm("Perusahaan")}
                      <select
                        className="form-control digits "
                        defaultValue="default"
                        style={{ width: "274px" }}
                        name="company_id"
                        onChange={handleChange}
                        value={formData.company_id}
                      >
                        <option value="default" disabled>
                          Pilih Perusahaan
                        </option>
                        {company &&
                          company.map((value) => (
                            <option value={value.id} selected={parseInt(company_id) === value.id}>{value.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      {labelForm("Diskon")}
                      <div className="form-group">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="discount_type"
                            id="inlineRadio1"
                            value="Rupiah"
                            onChange={handleChange}
                            checked={formData.discount_type === "Rupiah"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Rupiah
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input ml-3"
                            type="radio"
                            name="discount_type"
                            id="inlineRadio2"
                            value="Persen"
                            onChange={handleChange}
                            checked={formData.discount_type === "Persen"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Persen
                          </label>
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Masukkan diskon"
                            className="form-control mt-2"
                            style={{ width: "274px" }}
                            name="nominal"
                            onChange={handleChange}
                            value={formData.nominal}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      {labelForm("Syarat")}
                      <select
                        className="form-control digits "
                        defaultValue="default"
                        style={{ width: "274px" }}
                        name="condition"
                        onChange={handleChange}
                        value={formData.condition}
                        >
                        <option value="default" disabled>
                          Pilih Kategori
                        </option>
                        <option value="Semua Kategori">Semua Kategori</option>
                        <option value="Kategori Tertentu">
                          Kategori Tertentu
                        </option>
                        <option value="Pelatihan Spesifik">
                          Pelatihan Spesifik
                        </option>
                      </select>
                      {formData.condition === "Kategori Tertentu" ? (
                        <select
                          className="form-control digits mt-3"
                          defaultValue="default"
                          style={{ width: "274px" }}
                          name="category_id"
                          onChange={handleChange}
                          value={formData.category_id}
                        >
                          <option value="default" disabled>
                            Pilih ...
                          </option>
                          {category &&
                            category.map((value) => (
                              <option value={value.id}>{value.name}</option>
                            ))}
                        </select>
                      ) : formData.condition === "Pelatihan Spesifik" ? (
                        <select
                          className="form-control digits mt-3"
                          defaultValue="default"
                          style={{ width: "274px" }}
                          name="category_id"
                          onChange={handleChange}
                          value={formData.category_id}
                        >
                          <option value="default" disabled>
                            Pilih ...
                          </option>
                          {category &&
                            category.map((value) => (
                              <option value={value.id}>{value.name}</option>
                            ))}
                        </select>
                      ) : (
                        ""
                      )}

                      {/* {formData.subSyarat === "tech" && (
                        <select
                          className="form-control digits mt-3"
                          defaultValue="default"
                          style={{ width: "274px" }}
                        >
                          <option value="default" disabled>
                            Pilih ...
                          </option>
                          <option value="tech">Public Speaking King</option>
                        </select>
                      )} */}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-end mx-3 mt-4">
                  <button
                    className="btn btn-pill btn-outline-primary btn-sm text-work mr-3"
                    style={{ fontWeight: "600" }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmited("Draft");
                    }}
                  >
                    {" "}
                    Simpan sebagai draf{" "}
                  </button>
                  <button
                    className="btn btn-pill btn-primary btn-sm text-work"
                    style={{ fontWeight: "600" }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmited("Submit");
                    }}
                  >
                    {" "}
                    Aktifkan{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TambahKodePromo;
