import React from 'react';
import virtualclass from '../../../../assets/images/virtualclass .png';
import API from "../../../../api";

const PelatihanVirtualClassView = (props) => {
  const { data } = props;

  let statusVirtualClass = 'Kedaluarsa';

  return (
      <div className='card p-5'>

        <div className='w-100 border rounded mb-5'>
          <div className='text-center p-4'>
            <img className="img-fluid mb-5" src={virtualclass} alt="Omind Tech" style={{ width: '112.73px' }} />
            <div className='text-left'>
              <h6 className={statusVirtualClass === 'Kedaluarsa' && 'text-muted'} style={{ fontWeight: '600', fontSize: '20px' }}>{data.name}</h6>
              <p className={statusVirtualClass === 'Kedaluarsa' ? 'text-muted' : 'text-success'} style={{ fontSize: '14px' }}>{data.start_meeting.substr(0,5)} - {data.end_meeting.substr(0,5)} WIB &nbsp; &nbsp; {data.date}</p>
              {
                statusVirtualClass === 'Kedaluarsa' &&
                <>
                  <p className='text-muted mb-0' style={{ fontSize: '12px' }}>Link Zoom Meeting</p>
                  <a href={data.vc_link} style={{ fontSize: '12px', marginBottom: 15 }}>{data.vc_link}</a>

                  <p className='text-muted mb-0' style={{ fontSize: '12px' }}>Meeting ID: {data.vc_meeting_id}</p>
                  <p className='text-muted' style={{ fontSize: '12px' }}>Password: {data.vc_password}</p>
                </>
              }
            </div>
            {
              statusVirtualClass === 'Belum Ada Link' ?
                  <h6 className='text-muted' style={{ fontSize: '20px', marginTop: 30 }}>Belum ada link virtual class</h6>
                  : statusVirtualClass === 'Kedaluarsa' ?
                  ''
                  :
                  <>
                    <p className='text-muted' style={{ fontSize: '12px', marginTop: 30 }}>Masukkan Password berikut untuk Join ke Virtual Class</p>
                    <h6 className='text-theme' style={{ fontSize: '24px' }}>{data.vc_password}</h6>
                    <button className="btn btn-pill btn-primary mt-3 font-weight-bold" type="button" onClick={() => {
                      API.peserta.course.meeting_accessed({meeting_id: data.id});
                      window.open(data.vc_link, '_blank');
                    }}> Join Virtual Class </button>
                  </>
            }
          </div>
        </div>
      </div>
  )
}

export default PelatihanVirtualClassView;
