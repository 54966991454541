import React from 'react';
import Quiz from '../../../../../assets/images/quiz.png';

const MulaiKuis = (props) => {
  const { Attempt, setView, name } = props;
  return (
    <div className='card p-5'>
      <div className='text-center p-4'>
        <img className="img-fluid mb-4" src={Quiz} alt="Omind Tech" style={{ width: '112.73px' }} />
        <h6 style={{ fontWeight: '600', fontSize: '16px' }}>Quiz</h6>
        <p className='text-muted' style={{ fontSize: '16px' }}>{name}</p>
        <p className={`mb-0 ml-auto mt-3 ${Attempt === 1 ? 'txt-primary' : Attempt === 2 ? 'text-warning' : 'txt-danger'}`} style={{ fontWeight: '600', fontSize: '14px' }}>Attempt {Attempt}/3</p>
        <button className="btn btn-pill btn-primary mt-2 font-weight-bold" type="button" onClick={()=>setView('kuis')}> Mulai Quiz </button>
      </div>
    </div>
  )
}

export default MulaiKuis;
