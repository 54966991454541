import React, { useState, useEffect } from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link } from "react-router-dom";
import { CompanyIcon } from '../../../component/common/constant/menu';

const EditForm = (props) => {
  const { dataInput } = props;
  const [dataInputForm, setdataInputForm] = useState({
    syarat: "tertentu"
  });

  useEffect(() => {
    if (dataInput) {
      setdataInputForm(dataInput)
    }
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setdataInputForm({
      ...dataInputForm,
      [name]: value
    })
  }

  const handleSubmited = () => {

  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Edit Kode Promo Perusahaan'}>
          <li className="breadcrumb-item">
            <Link to="/admin/tambah-promo" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">Edit Kode Promo Perusahaan</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm">
          <div className="card">

            <div className="card-header">
              <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>EDIT KODE PROMO</h4>
            </div>

            <div className="card-body">
              <label className="text-muted text-work mb-4" style={{ fontWeight: '700' }}>Detail Promo</label>
              <form className="row mb-5" onSubmit={handleSubmited}>
                {/* Krii */}
                <div className="col-md-5 mr-5">
                  <div className="form-group">
                    <label htmlFor="namaPromo" className="font-weight-bold text-muted">Nama Promo</label>
                    <input type="text" className="form-control w-75" id="namaPromo" />
                  </div>
                  <label className="font-weight-bold text-muted">Durasi</label>
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-5">
                        <input type="date" className="form-control" placeholder="Pilih tanggal" />
                      </div>
                      <div className="col-md-7 d-flex">
                        <label htmlFor="hingga" className="mx-4 my-auto">hingga</label>
                        <input type="date" className="form-control" placeholder="Pilih tanggal" id="hingga" />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="status" className="font-weight-bold text-muted">Status</label>
                    <select name="status" id="status" placeholder="pilih status" className="form-control w-25">
                      <option value="aktif">Aktif</option>
                      <option value="non aktif">Non Aktif</option>
                    </select>
                  </div>
                </div>

                {/* kanan */}
                <div className='col-md-4 ml-5'>
                  <label className="font-weight-bold text-muted">Diskon</label>
                  <div className="form-group">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="Rupiah" />
                      <label className="form-check-label" htmlFor="inlineRadio1">Rupiah</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input ml-3" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="Persen" />
                      <label className="form-check-label" htmlFor="inlineRadio2">Persen</label>
                    </div>
                    <div>
                      <input type="text" placeholder="Masukkan diskon" className="form-control mt-2" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="syarat" className="font-weight-bold text-muted">Syarat</label>
                    <select name="syarat" id="syarat" className="form-control" defaultValue="default" value={dataInputForm.syarat} onChange={handleChange}>
                      <option value="default" disabled>Select ...</option>
                      <option value="tertentu">Kategori Tertentu</option>
                      <option value="all">Semua Pelatihan</option>
                      <option value="spesifik">Pelatihan Sepesifik</option>
                    </select>
                    {dataInputForm.syarat === 'tertentu' ?
                      <select name="syarat_tertentu" id="syarat_tertentu" className="form-control mt-3" defaultValue="default" onChange={handleChange}>
                        <option value="default" disabled>Select ...</option>
                        <option value="tertentu">Insurance</option>
                      </select> :
                      dataInputForm.syarat === 'spesifik' ?
                        <select name="syarat_spesifik" id="syarat_spesifik" className="form-control mt-3" defaultValue="default" onChange={handleChange}>
                          <option value="default" disabled>Select ...</option>
                          <option value="Technology">Technology</option>
                        </select>
                        : ""
                    }

                    {dataInputForm.syarat_spesifik &&
                      <select name="syarat_spesifik2" id="syarat_spesifik2" className="form-control mt-3" defaultValue="default" onChange={handleChange}>
                        <option value="default" disabled>Select ...</option>
                        <option value="public">Public Speaking King</option>
                      </select>
                    }
                  </div>
                </div>
                <div className="col-md-11 mt-5">
                  <div className="text-right">
                    <button type="simpan" className="btn btn-outline-primary btn-pill mr-2">Simpan sebagai draf</button>
                    <button type="submit" className="btn btn-primary btn-pill">Aktifkan</button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default EditForm;