import Get from "../Get";

const list_transaction = (data) =>
  Get(
    `super-admin/transaction?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const transaction_detail = (id) => Get(`super-admin/transaction/${id}`);
const invoice = (id) => Get(`super-admin/transaction/${id}/detail`);
const list_company_transaction = (data) =>
  Get(
    `super-admin/transaction/company?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const company_transaction_detail = (id) =>
  Get(`super-admin/transaction/company/${id}`);
const SuperadminTransaction = {
  list_transaction,
  transaction_detail,
  invoice,
  list_company_transaction,
  company_transaction_detail,
};

export default SuperadminTransaction;
