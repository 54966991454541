import Post from "../Post";
import Get from "../Get";

const company_overview = (id) => Get(`peserta/company/overview/${id}`);
const company_all_logo = () => Get(`peserta/company/all_logo?per_page=9999`);

const CompanyPeserta = {
    company_overview,
    company_all_logo
};

export default CompanyPeserta;
