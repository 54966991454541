import React, { useState, useEffect, useRef, createRef } from 'react';
import { Input, Modal, ModalBody } from 'reactstrap';
import API from "../../../../../api";
import {titleCase} from "../../../../../helper";
import swal from 'sweetalert2';

const Tugas = (props) => {
  const {id} = props;

  const user = JSON.parse(localStorage.getItem('data'));

  const [modalSelesai, setmodalSelesai] = useState(false);
  const [finalTasks, setFinalTasks] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [fileWillUploaded, setFileWillUploaded] = useState([]);
  const [attemptFinalTask, setAttemptFinalTask] = useState(0);
  const [hiddenFileInputs, setHiddenFileInputs] = useState([]);

  const commentTextArea = useRef(null);

  const handleChange = (e) => {
    const fileUploaded = e.target.files[0];

    let temp_fileWillUploaded = [...fileWillUploaded];
    temp_fileWillUploaded[e.target.name] = fileUploaded;

    console.log(temp_fileWillUploaded);

    setFileWillUploaded(temp_fileWillUploaded);
  };

  const TugasItem = [
    {
      soal: 'Kita sudah mempersiapkan segalanya saat persentasi dengan matang, namun ditengah persentasi kita bank, terlebih ketika melihat audiens yang sebelumnya tidak pernah melihat persentasi kita. Mengapa hal itu bisa terjadi? Bagaimana cara mengatasinya?',
      status: true,
      file_soal: 'http://google.com',
      download_koreksi: [],
      upload_jawaban: Array(2).fill(1).map((x, y) => x + y),
      upload_koreksi: [],
      attempt: 1,
      komentar: [],
    },
    /*{
      soal: 'Kita sudah mempersiapkan segalanya saat persentasi dengan matang, namun ditengah persentasi kita bank, terlebih ketika melihat audiens yang sebelumnya tidak pernah melihat persentasi kita. Mengapa hal itu bisa terjadi? Bagaimana cara mengatasinya?',
      status: false,
      download_koreksi: ['Download Koreksi 1'],
      download_jawaban: ['Download Jawaban'],
      upload_koreksi: ['Upload Koreksi 1'],
      download_attempt: [],
      attempt: 2,
      komentar: [
        {
          text: 'Kamu seharusnya mengingat materi kembali',
          role: 'Trainer',
          name: 'Donny',
          company: 'PT Berkah Nusantara',
          ava: 'https://mdbootstrap.com/img/Photos/Avatars/avatar-4.jpg'
        },
      ],
    },
    {
      soal: 'Kita sudah mempersiapkan segalanya saat persentasi dengan matang, namun ditengah persentasi kita bank, terlebih ketika melihat audiens yang sebelumnya tidak pernah melihat persentasi kita. Mengapa hal itu bisa terjadi? Bagaimana cara mengatasinya?',
      status: false,
      download_koreksi: ['Download Koreksi 1', 'Download Koreksi 2'],
      download_jawaban: ['Download Jawaban'],
      download_attempt: [],
      upload_koreksi: ['Upload Koreksi 1', 'Upload Koreksi 2'],
      komentar: [
        {
          text: 'Ini seharusnya dibagi 4 terus dikali 2',
          role: 'Trainer',
          name: 'Donny',
          company: 'PT Berkah Nusantara',
          ava: 'https://mdbootstrap.com/img/Photos/Avatars/avatar-4.jpg'
        },
        {
          text: 'Ohiya hampura pak 😅',
          role: 'Peserta',
          name: 'Elya',
          company: 'PT Samitha Nusantara',
          ava: 'https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg'
        }
      ],
      attempt: 3
    },*/
  ];

  useEffect(() => {
    API.peserta.course.show_final_task(id)
        .then(json => {
          let fnltsks = [];

          setAttemptFinalTask(json.data.attempt_final_task);

          setHiddenFileInputs(hidden => (
              Array(json.data.data.length).fill().map((_, i) => hidden[i] || createRef())
          ));

          json.data.data.map((v, key) => {
            let corrections = [];
            let comments = [];

            v.finaltask_answer.map(val => {
                if(val.correction_file)
                    corrections.push(val.correction_file.replace(/ /g, '%20'));
            });

            v.finaltask_comment.map(val => {
                comments.push({
                    text: val.comment,
                    role: titleCase(val.user_id.role_id.name),
                    name: val.user_id.name,
                    company: val.user_id.company_id.name,
                    attempt: val.attempt,
                    ava: val.user_id.profile_picture.replace(/ /g, '%20'),
                });
            });

            for(let i = 1; i <= 3 - v.finaltask_answer.length; i++){
                v.finaltask_answer.push({
                    answer_file: null,
                    status: false,
                })
            }

            fnltsks.push({
              id: v.id,
              soal: v.question,
              status: v.finaltask_answer.filter(v => v.answer_file).length > 0 && v.finaltask_answer.filter(v => v.answer_file)[v.finaltask_answer.filter(v => v.answer_file).length - 1].status === 'Pass',
              file_soal: v.question_file.replace(/ /g, '%20'),
              download_koreksi: corrections,
              answers: v.finaltask_answer,
              upload_jawaban: Array(v.attempt_answer).fill(1).map((x, y) => x + y),
              attempt: v.attempt_answer,
              komentar: comments,
            })
          });

          setFinalTasks(fnltsks);
        });
  }, []);

  const submitFile = () => {
      swal.fire({
          onBeforeOpen () {
              swal.showLoading()
          },
          onAfterClose () {
              swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
      });
      let promises = [];

      finalTasks.map((v,k) => {
          if(fileWillUploaded[k]){
              let data = new FormData();
              data.append('final_task_id', v.id);
              data.append('answer_file', fileWillUploaded[k]);
              data.append('attempt_answer', v.attempt);
              data.append('attempt', attemptFinalTask);

              promises.push(API.peserta.course.answer_final_task(id, data));
          }
      });

      Promise.all(promises).then(res => {
          swal.close();
          props.setView('mengirim');
      }).catch(e => {
          swal.close();
          swal.fire('Error', e.message, 'error');
      });

  }

  return (
    <div className='card px-4 py-3'>
      <ol className='d-block' style={{ paddingInlineStart: 10 }}>
        {finalTasks.map((data, key) => (
          <div className='row mt-3'>
            <div className='col-md-9'>
              <li key={key}><p className='text-nunito' style={{ fontWeight: '600', fontSize: '14px' }}>{data.soal}</p></li>
              <div className='mb-3'>
                {data.upload_jawaban.map((v, k) => (
                  <>
                    <button disabled={data.answers[k].answer_file} className="btn btn-primary text-work mr-2 mb-2" style={{ fontWeight: '600', fontSize: '12px' }} type="button" key={k} onClick={() => {hiddenFileInputs[key].current.click();}}><i className="fa fa-cloud-download"></i> &nbsp;{data.answers[k].status === 'Pass' ? 'Jawaban Benar' : data.answers[k].answer_file ? data.download_koreksi[k] ? 'Jawaban Salah' : 'Sedang Diperiksa Trainer' : `Upload Jawaban ${fileWillUploaded[key] ? `(${fileWillUploaded[key].name})` : ''}`}</button>
                    <input
                        type="file"
                        name={key}
                        ref={hiddenFileInputs[key]}
                        onChange={e => {handleChange(e)}}
                        style={{display: 'none'}}
                    />
                  </>
                ))}
                  <br className='mb-3'/>
                {data.download_koreksi.map((data, key) => (
                  <button className="btn btn-success text-work mr-2 mb-2" style={{ fontWeight: '600', fontSize: '12px' }} type="button" key={key} onClick={() => {
                    window.open(data, '_blank')
                  }}><i className="fa fa-cloud-download"></i> &nbsp;Koreksi {key+1}</button>
                ))}
              </div>
              {data.komentar.map((data, key) => (
                <div className={`border p-4 ${data.role === 'Peserta' && 'bg-light txt-dark'}`}>
                  <div className='d-flex'>
                    <div className='mr-3'>
                      <img className="img-40 rounded-circle" style={{height: '40px', objectFit: 'cover'}} src={data.ava} alt="#"/>
                    </div>
                    <div>
                      <label className='mb-2'>
                        <h6 className='mb-0'>{data.name}</h6>
                        <p className='mb-0'>{data.role} - {data.company}</p>
                      </label>
                      <h6>{data.text}</h6>

                    </div>
                  </div>
                </div>
              ))}
              <div className='border bg-light p-4'>
                <div className='d-flex'>
                  <div className='mr-3'>
                    <img className="img-40 rounded-circle" src={user.profile_picture.replace(/ /g, '%20')} alt="#"/>
                  </div>
                  <textarea placeholder='Tulis Komentar...' className='p-2 w-100 rounded' ref={commentTextArea} onChange={e => setCommentText(e.target.value)}>
                      {commentText}
                  </textarea>
                </div>
                <div className='mt-3 text-right'>
                  <button className="btn mr-1 text-work txt-primary bg-light" type="button" style={{ fontWeight: '600', fontSize: '12px' }} onClick={() => {
                      commentTextArea.current.value = '';
                      setCommentText('');
                  }}>Batal</button>
                  <button className="btn btn-primary  text-work" type="button" style={{ fontWeight: '600', fontSize: '12px' }}
                    onClick={() => {
                        API.peserta.course.comment_final_task(id, {
                            final_task_id : data.id,
                            comment: commentText,
                            attempt: attemptFinalTask,
                            attempt_answer: data.attempt
                        });

                        let newComments = [...data.komentar];
                        newComments.push({
                            text: commentText,
                            role: 'Peserta',
                            name: user.name,
                            attempt: key + 1,
                            company: user.company_name,
                            ava: user.profile_picture.replace(/ /g, '%20'),
                        });
                        commentTextArea.current.value = '';
                        setCommentText('');

                        let fnl = [...finalTasks];
                        fnl[key].komentar = newComments;
                        setFinalTasks(fnl);

                    }}
                  >Kirim</button>
                </div>
              </div>
            </div>
            <div className='col-md-3 text-right'>
              <p className={`mb-2 ml-auto ${data.attempt === 1 ? 'txt-primary' : data.attempt === 2 ? 'text-theme' : 'text-danger'}`} style={{ fontSize: '14px', fontWeight: '600' }}>Attempt {data.attempt}/3</p>
              <button className="btn btn-success btn-sm text-work mb-2 py-1 px-2" style={{ fontWeight: '600', fontSize: '12px', whiteSpace: 'normal' }} type="button" onClick={() => {
                window.open(data.file_soal, '_blank')
              }}><i className="fa fa-cloud-download"></i> &nbsp;Soal</button>
            </div>
            <br />
          </div>
        ))}
      </ol>
      <div className='text-right'>
        <button className=" btn btn-primary btn-sm text-work px-3" type="button" style={{ fontWeight: '600' }} onClick={() => {
            console.log('fileWillUploaded.filter(v => v).length', fileWillUploaded.filter(v => v).length);
            console.log('finalTasks.filter(v => !v.status).length', finalTasks.filter(v => !v.status).length);
            console.log('finalTasks', finalTasks);
            if(fileWillUploaded.filter(v => v).length === finalTasks.filter(v => !v.status).length){
                setmodalSelesai(!modalSelesai)
            }
            else{
                swal.fire('Gagal', 'Anda belum mengisi jawaban', 'error');
            }
        }}>Selesai</button>
      </div>


      <Modal isOpen={modalSelesai} toggle={() => setmodalSelesai(!modalSelesai)}>
        <ModalBody>
          <div className='text-center mt-3'>
            <h6>Apakah Anda Yakin Ingin Kirim Tugas Final?</h6>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Metus risus, bibendum ultrices ornare interdum commodo </p>
            <button
              className="btn btn-pill btn-primary btn-sm my-3 mr-3"
              type="button"
              onClick={submitFile}
            > Ya </button>
            <button
              className="btn btn-pill btn-outline-primary btn-sm my-3"
              type="button"
              onClick={() => setmodalSelesai(!modalSelesai)}
            > Tidak
                </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default Tugas
