import React, {useState, useEffect} from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Users } from 'react-feather';
import { Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from "../../../api";
import swal from 'sweetalert2';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";

const UpdateTugasFinal = () => {
  let query = new URLSearchParams(useLocation().search);
  let final_id = query.get('final_id');
  const history = useHistory();

  const [question, setQuestion] = useState('');
  const [question_file, setQuestionFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmited = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    let data = {
      question: question,
    }

    if(question_file)
      data['question_file'] = question_file;

    API.adminc.final.update(final_id, data)
        .then(json => {
          setIsSubmitting(false);
          swal.fire('Sukses', "Soal Tugas Final Berhasil Diupdate", 'success').then(() => {
            history.goBack();
          });
        }).catch(e => {
          setIsSubmitting(false);
          swal.fire('Error', e.message, 'error');
        });
  }

  useEffect(() => {
    API.adminc.final.getOne(final_id)
        .then(json => {
          setQuestion(json.data.question);
        })
  }, []);

  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <BreadcrumbComp title={'List pelatihan'}>
          <li className="breadcrumb-item">
            <Link to="/admin/list-pelatihan" className='text-warning'>
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Soal Tugas Final</li>
          <li className="breadcrumb-item">Update Soal Tugas Final</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5 className='text-work text-theme' style={{ fontSize: '24px', fontWeight: '600' }}>PELATIHAN PERUSAHAAN</h5>
              <p className='text-work text-muted' style={{ fontSize: '18px', fontWeight: '600' }}>Atur Soal Tugas</p>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmited}>

                <h6 style={{ fontSize: '16px' }}>Soal</h6>
                <CKEditor
                    editor={ ClassicEditor }
                    data={question}
                    onChange={ ( event, editor ) => {
                        setQuestion(editor.getData());
                      }
                    }
                />
                <div className="d-flex align-items-center mt-4">
                  <h6 style={{ fontSize: '12px' }}>Upload Soal</h6>
                  <input className="form-control text-dark ml-5" accept='application/pdf' type="file" style={{ width: '298px' }} onChange={(e) => {setQuestionFile(e.target.files[0])}}/>
                </div>
                <div className='my-5 d-flex align-items-center justify-content-end border-top pt-4'>
                    <button type='button' className='btn btn-outline-primary btn-pill mr-3' onClick={() => history.goBack()}>Batal</button>
                    <button className='btn btn-primary btn-pill' disabled={isSubmitting}>Simpan</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateTugasFinal;
