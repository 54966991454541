import Get from "../Get";
import Post from "../Post";

const get = () => Get(`trainer/my-profile`);
const get_update = data => Get(`trainer/my-profile/update_profile?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const update = data => Post(`trainer/my-profile/update_profile`, data);
const update_account = data => Post(`trainer/my-profile/update_account`, data);

const ProfileTrainer = {
    get,
    get_update,
    update,
    update_account
};

export default ProfileTrainer;
