import Get from "./Get";
import Post from "./Post";

const get = (id) => Get(`about-us?id=${id}`);
const update = (id, data) => Post(`admin-c/about-us/${id}`, data);
const update_super = data => Post(`super-admin/about-us`, data);

const AboutUs = {
    get,
    update,
    update_super
};

export default AboutUs;
