import Post from "../Post";
import Get from "../Get";

const check_certificate = data => Post(`trainer/course/check_certificate`, data);
const get_progress = (id, data) => Get(`trainer/course/get_progress/${id}?per_page=9999&${data && Object.keys(data).map((key) => key + "=" + data[key]).join("&")}`);
const get_progress_detail = id => Get(`trainer/course/get_progress/detail/${id}`);
const show_final_task = id => Get(`trainer/course/get_progress/detail/final_task/${id}`);
const detail = id => Get(`trainer/course/${id}`);
const get_comment = id => Get(`trainer/course/course_comment/${id}`);
const send_comment = (id, data) => Post(`trainer/course/course_comment/send/${id}`, data);
const progress_quiz = id => Get(`trainer/course/get_progress/detail/quiz/${id}`);
const quiz_discussion = id => Get(`trainer/course/get_progress/detail/quiz_discussion/${id}`);
const list_theory = id => Get(`trainer/course/list_theory/${id}`);
const get_quiz = id => Get(`trainer/course/get_quiz/${id}`);
const get_final_task = id => Get(`trainer/course/get_final_task/${id}`);
const get_schedule_course = id => Get(`trainer/course/get_schedules_course/${id}`);
const update_meeting = (id, data) => Post(`trainer/course/update_meeting/${id}`, data);
const upload_correction_final_task = (id, data) => Post(`trainer/course/get_progress/detail/final_task/upload_correction/${id}`, data);
const change_status_final_task = (id, query, data) => Post(`trainer/course/get_progress/detail/final_task/change_status/${id}?${query && Object.keys(query).map((key) => key + "=" + query[key]).join("&")}`, data);

const CourseTrainer = {
    check_certificate,
    get_progress,
    get_progress_detail,
    show_final_task,
    detail,
    get_comment,
    send_comment,
    progress_quiz,
    quiz_discussion,
    list_theory,
    get_quiz,
    get_final_task,
    get_schedule_course,
    update_meeting,
    upload_correction_final_task,
    change_status_final_task
};

export default CourseTrainer;
