import Get from "../Get";

const total = () => Get(`ketua-klien/dashboard/total`);
const topCourse = (data) => Get(`ketua-klien/dashboard/top-5-courses?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const course = (data) => Get(`ketua-klien/dashboard/courses?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const trainer = (data) => Get(`ketua-klien/dashboard/trainers?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);

const DashboardKetua = {
    total,
    topCourse,
    course,
    trainer
};

export default DashboardKetua;