import React, { useState, useEffect } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation } from "react-router-dom";
import { CompanyIcon } from "../../../component/common/constant/menu";
import { ToastContainer, toast } from 'react-toastify';
import remove from "../../../assets/images/remove.png";
import { Modal, ModalBody } from "reactstrap";
import EditForm from "./EditForm";
import API from "../../../api";
const KodePromo = () => {
  const [detailSelected, setdetailSelected] = useState({});
  const [keyPromo, setkeyPromo] = useState(null);
  const [editView, seteditView] = useState(false);
  const [modalRemove, setmodalRemove] = useState();
  const [modalDetail, setModalDetail] = useState(false);
  const [search, setSearch] = useState('')
  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get("page") || 1;
  const [promos, setPromos] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });
  const columnsTabel = [
    {
      dataField: "no",
      text: "No",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "promo",
      text: "Nama Kode Promo",
      headerStyle: () => ({ width: "18%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="text-left">
            <label className="mb-0 ml-2">
              <p className="font-weight-bold">{row.promo}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "syarat",
      text: "Syarat Promo",
      headerStyle: () => ({ width: "20%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "tanggal",
      text: "Periode",
      headerStyle: () => ({ width: "16%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "13%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <div className="align-items-center">
            <div
              className={`w-75 my-auto py-2 mx-auto my-auto text-center card btn-status btn-status_${
                cel === "Aktif"
                  ? "aktif"
                  : cel === "Expired"
                  ? "expired"
                  : "draft"
              }`}
            >
              {cel}
            </div>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Aksi",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row, index) => {
        return (
          <div className="d-flex">
            {row.status === "Aktif" ? (
              <div>
                <button
                  className=" mx-auto btn rounded-circle btn-success btn-xs py-1 ml-2"
                  type="button"
                  onClick={() => {
                    API.adminc.promo.detail_promo(row.id).then((json) => {
                      setdetailSelected(json.data);
                    });
                    setModalDetail(!modalDetail);
                  }}
                >
                  {" "}
                  <i className="fa fa-eye"></i>{" "}
                </button>
                &nbsp;
                <button
                  className=" mx-auto btn rounded-circle btn-danger btn-xs py-1 ml-2"
                  onClick={() => {
                    setdetailSelected(row);
                    setkeyPromo(index);
                    setmodalRemove(!modalRemove);
                  }}
                  type="button"
                >
                  {" "}
                  <i className="fa fa-trash"></i>{" "}
                </button>
              </div>
            ) : (
              <div>
                <button
                  className=" mx-auto btn rounded-circle btn-success btn-xs py-1"
                  type="button"
                  onClick={() => {
                    API.adminc.promo.detail_promo(row.id).then((json) => {
                      setdetailSelected(json.data);
                      setModalDetail(!modalDetail);
                    });
                  }}
                >
                  {" "}
                  <i className="fa fa-eye"></i>{" "}
                </button>
                &nbsp;
                <Link to={`/admin/tambah-promo?no=${row.no}&id=${row.id}`}>
                  <button
                    className=" mx-auto btn rounded-circle btn-warning btn-xs py-1"
                    type="button"
                  >
                    {" "}
                    <i className="fa fa-pencil"></i>{" "}
                  </button>
                </Link>
                &nbsp;
                <button
                  className=" mx-auto btn rounded-circle btn-danger btn-xs py-1"
                  onClick={() => {
                    setdetailSelected(row);
                    setkeyPromo(index);
                    setmodalRemove(!modalRemove);
                  }}
                  type="button"
                >
                  {" "}
                  <i className="fa fa-trash"></i>{" "}
                </button>
              </div>
            )}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    getPromo(search)
  }, [page]);

  const getPromo = (search) => {
    API.adminc.promo.list_promo({ page, per_page, search }).then((json) => {
      let promo = { ...promos };
      promo.data = [];

      json.promos.data.map((val, index) => {
        promo.data.push({
          id: val.id,
          no: index + 1,
          promo: val.code,
          tanggal: val.period,
          syarat: val.condition,
          status: val.status,
        });
      });

      promo.meta.from = (page - 1) * per_page + 1;
      promo.meta.to =
        per_page > json.promos.total
          ? json.promos.total
          : page * per_page > json.promos.total
          ? json.promos.total
          : page * per_page;
      promo.meta.total = json.promos.total;
      promo.meta.current_page = page;
      promo.meta.total_page = Math.ceil(promo.meta.total / promo.meta.per_page);

      setPromos(promo);
    });
  }

  const deletePromo = (id, key) => {
    API.adminc.promo.delete_promo(id)
      .then(json => {
        let temp_promos = {...promos}

        temp_promos.data.splice(key, 1)

        setPromos(temp_promos)

        toast.success("Data Promo Berhasil Dihapus", { position: toast.POSITION.TOP_CENTER });
        setmodalRemove(false)
      }).catch(err => {
        toast.error("Data Promo Tidak Berhasil Dihapus", { position: toast.POSITION.TOP_CENTER });
        setmodalRemove(false)
      })
  }

  if (editView) {
    return (
      <EditForm seteditView={seteditView} detailSelected={detailSelected} />
    );
  }
  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <BreadcrumbComp title={"Kode Promo Perusahaan"}>
          <li className="breadcrumb-item">
            <Link to="/admin/kode-promo" className="text-warning">
              <CompanyIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">Kode Promo Perusahaan</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 pb-3 d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  LIST KODE PROMO
                </h5>
                <div className="row mt-3">
                  <div className="col-xl-12">
                    <form className="search-form contact-search ">
                      <div className="form-group mb-0">
                        <input
                          className="form-control badge-pill"
                          type="text"
                          placeholder="Cari kode promo..."
                          onChange={(e) => {
                            getPromo(e.target.value)
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div>
                <Link to="/admin/tambah-promo">
                  <button
                    className=" btn btn-pill btn-outline-primary btn-sm text-work"
                    type="button"
                    style={{ fontWeight: "600" }}
                  >
                    <i className="fa fa-pencil"></i> &nbsp;Tambah Kode Promo
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="overflow-auto w-100">
                <TableComp
                  rowClasses="text-center"
                  title_paggination="promo"
                  columns={columnsTabel}
                  data={promos}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalRemove}
        toggle={() => setmodalRemove(!modalRemove)}
        size="md"
        style={{ maxWidth: "548px", maxHeight: "275px" }}
      >
        <ModalBody className="pb-5">
          <div className="text-center mt-5 mb-4">
            <img src={remove} alt="" />
          </div>
          <div className="text-center mb-4">
            <h5 className="text-weight-bold">
              Apakah anda yakin ingin menghapus promo ini?
            </h5>
          </div>
          {
            detailSelected && (
              <div className="text-center">
                <button className="btn btn-pill btn-primary px-5" onClick={() => {
                  deletePromo(detailSelected.id, keyPromo)
                }}>ya</button>
                <button
                  className="btn btn-pill btn-outline-primary ml-2 px-4 pl-2"
                  type="cancel"
                  onClick={() => {
                    setmodalRemove(false)
                  }}
                >
                  Tidak
                </button>
              </div>
            )
          }
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalDetail}
        toggle={() => setModalDetail(!modalDetail)}
        size="lg"
        style={{ maxWidth: "548px", maxHeight: "275px" }}
      >
        <ModalBody className="pb-5">
          {detailSelected && (
            <>
              <div className="text-center">
                <p
                  style={{ fontSize: "18px", fontWeight: "" }}
                  className="text-muted font-weight-bold m-2 mb-4"
                >
                  Detail Promo
                </p>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h6 className="text-work text-muted mb-0">Nama Promo</h6>
                  <div className="d-flex align-items-center mb-3">
                    <div>
                      <p
                        className="text-muted mb-0 font-weight-bold"
                        style={{ fontSize: "16px" }}
                      >
                        {detailSelected.name}
                      </p>
                    </div>
                  </div>
                  <label className="text-muted text-work mb-0">Durasi</label>
                  <div className="d-flex align-items-center mb-3">
                    <label className="mb-0">
                      <p
                        className="font-weight-bold mb-0"
                        style={{ fontSize: "14px" }}
                      >
                        {detailSelected.start_promo}{" "}
                        <span
                          className="mx-2 text-muted"
                          style={{ fontWeight: "400", fontSize: "12px" }}
                        >
                          {" "}
                          hingga{" "}
                        </span>{" "}
                        {detailSelected.end_promo}
                      </p>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="text-muted text-work mb-1">Status</label>
                  <div className="align-items-center mb-3">
                    <div
                      className={`my-auto py-2 text-center card btn-status btn-status_${
                        detailSelected.status === "Aktif"
                          ? "aktif"
                          : detailSelected.status === "Expired"
                          ? "expired"
                          : detailSelected.status === "Terpakai"
                          ? "terpakai"
                          : "draft"
                      }`}
                    >
                      {detailSelected.status}
                    </div>
                  </div>
                  <label className="text-muted text-work mb-0">Diskon</label>
                  <div className="d-flex align-items-center mb-3">
                    <div>
                      <p
                        className="txt-success font-weight-bold mb-0"
                        style={{ fontSize: "16px" }}
                      >
                        {new Intl.NumberFormat("id", {
                          style: "currency",
                          currency: "IDR",
                        }).format(detailSelected.nominal)}
                      </p>
                    </div>
                  </div>
                  <label className="text-muted text-work mb-0">Syarat</label>
                  <div className="d-flex align-items-center mb-3">
                    <div>
                      <p
                        className="txt-muted font-weight-bold mb-0"
                        style={{ fontSize: "16px" }}
                      >
                        {detailSelected.condition}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default KodePromo;
