import Get from "../Get";
import Post from "../Post";
import Delete from "../Delete";
const list_vouchers = (data) =>
  Get(
    `super-admin/vouchers?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const detail_voucher = (id) => Get(`super-admin/vouchers/${id}`);
const insert_voucher = (data) => Post(`super-admin/vouchers`, data);
const edit_voucher = (id, data) => Post(`super-admin/vouchers/${id}`, data);
const list_vouchers_bills = (data) =>
  Get(
    `super-admin/vouchers/bills?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
  const confirm_paid_vocuher = (id, data) => Post(`super-admin/vouchers/${id}/confirm-paid`, data);
  const delete_voucher = (id) => Delete(`super-admin/vouchers/code/${id}`);

const SuperadminVouchers = {
  list_vouchers,
  detail_voucher,
  insert_voucher,
  edit_voucher,
  list_vouchers_bills,
  confirm_paid_vocuher,
  delete_voucher
};

export default SuperadminVouchers;
