import User from "./user";
import CompanyPeserta from "./peserta/company";
import CoursePeserta from "./peserta/course";
import PointPeserta from "./peserta/point";
import IntroCoursePeserta from "./peserta/intro_course";
import DashboardTrainer from "./trainer/dashboard";
import ParticipantTrainer from "./trainer/participant";
import CourseTrainer from "./trainer/course";
import ProfileTrainer from "./trainer";
import ChatTrainer from "./trainer/chat";
import CategoryPeserta from "./peserta/category";
import DashboardKetua from "./ketua/dashboard";
import TrainerKetua from "./ketua/trainer";
import CompanyKetua from "./ketua/company";
import CourseKetua from "./ketua/course";
import ParticipantKetua from "./ketua/participant";
import TransactionKetua from "./ketua/transaksi";
import ChatKetua from "./ketua/chat";
import ProfileKetua from "./ketua/profile";
import ChatPeserta from "./peserta/chat";
import ListCourseAdminC from "./adminc/course";
import DashboardAdminC from "./adminc/dashboard";
import ProfileAdminC from "./adminc/profile";
import ChatAdminC from "./adminc/chat";
import VouchersAdminC from "./adminc/vouchers";
import TransactionAdminC from "./adminc/transaksi";
import CompanyAdminC from "./adminc/company";
import TrainerAdminC from "./adminc/trainer";
import ParticipantAdminC from "./adminc/participant";
import PromoAdminC from "./adminc/promo";
import ChairmanAdminC from "./adminc/chairman";
import ProfessionAdminC from "./adminc/profession";
import CrosspayAdminC from "./adminc/crosspay";
import CtpAdminC from "./adminc/ctp";
import ParticipantPeserta from "./peserta/participant";
import RajaOngkir from "./rajaongkir";
import Category from "./category";

import SuperadminListTrainer from "./superadmin/trainer";
import SuperadminTransaction from "./superadmin/transaksi";
import SuperadminCompany from "./superadmin/company";
import SuperadminCourses from "./superadmin/courses";
import SuperadminVouchers from "./superadmin/vouchers";
import SuperadminChat from "./superadmin/chat";
import SuperadminProfile from "./superadmin/profile";
import SuperadminCrosspay from "./superadmin/crosspay";
import SuperadminPromo from "./superadmin/promo";
import SuperadminCategory from "./superadmin/category";
import SuperadminAdminC from "./superadmin/adminc";
import SuperadminProfession from "./superadmin/profession";
import SuperadminChairman from "./superadmin/chairman";
import SuperadminParticipant from "./superadmin/participant";
import SuperadminIntroCourses from "./superadmin/intro_courses";
import SuperadminDashboard from "./superadmin/dashboard";
import Notification from "./notification";
import SuperadminCtp from "./superadmin/ctp";
import ProfessionKetua from "./ketua/profession";
import AboutUs from "./about-us";
import QuizAdminC from "./adminc/quiz";
import FinalAdminC from "./adminc/final";
const API = {
  user: User,
  rajaongkir: RajaOngkir,
  category: Category,
  notification: Notification,
  about_us: AboutUs,
  peserta: {
    company: CompanyPeserta,
    course: CoursePeserta,
    intro_course: IntroCoursePeserta,
    point: PointPeserta,
    category: CategoryPeserta,
    chat: ChatPeserta,
    participant: ParticipantPeserta,
  },
  trainer: {
    dashboard: DashboardTrainer,
    participant: ParticipantTrainer,
    course: CourseTrainer,
    profile: ProfileTrainer,
    chat: ChatTrainer,
  },
  ketua: {
    dashboard: DashboardKetua,
    company: CompanyKetua,
    trainer: TrainerKetua,
    course: CourseKetua,
    participant: ParticipantKetua,
    transaction: TransactionKetua,
    chat: ChatKetua,
    profile: ProfileKetua,
    profession: ProfessionKetua,
  },
  adminc: {
    dashboard: DashboardAdminC,
    course: ListCourseAdminC,
    company: CompanyAdminC,
    trainer: TrainerAdminC,
    participant: ParticipantAdminC,
    chairman: ChairmanAdminC,
    profession: ProfessionAdminC,
    profile: ProfileAdminC,
    chat: ChatAdminC,
    transaction: TransactionAdminC,
    vouchers: VouchersAdminC,
    promo: PromoAdminC,
    crosspay: CrosspayAdminC,
    ctp: CtpAdminC,
    quiz: QuizAdminC,
    final: FinalAdminC,
  },
  superadmin: {
    trainer: SuperadminListTrainer,
    transaction: SuperadminTransaction,
    company: SuperadminCompany,
    course: SuperadminCourses,
    vouchers: SuperadminVouchers,
    chat: SuperadminChat,
    profile: SuperadminProfile,
    crosspay: SuperadminCrosspay,
    promo: SuperadminPromo,
    category: SuperadminCategory,
    adminc: SuperadminAdminC,
    profession: SuperadminProfession,
    chairman: SuperadminChairman,
    participant: SuperadminParticipant,
    intro_courses: SuperadminIntroCourses,
    dashboard: SuperadminDashboard,
    ctp: SuperadminCtp
  },
};

export default API;
