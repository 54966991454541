import React, {useState, useEffect} from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useHistory, useLocation } from "react-router-dom";
import { CompanyIcon } from '../../../component/common/constant/menu';
import camera from '../../../assets/images/camera.png';
import upload from '../../../assets/images/upload.png';
import { ToastContainer, toast } from 'react-toastify';
import API from "../../../api";

const UbahDataPeserta = () => {
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const history = useHistory();

  const [participant,  setParticipant] = useState(null)

  const [DoneSubmit,  setDoneSubmit] = useState(false)
  const [isLoading, setisLoading] = useState(false)

  const [ktpPreview, setktpPreview] = useState(null);
  const [ktpPreviewUrl, setktpPreviewUrl] = useState("");

  const [data_profession, setDataProfession] = useState([]);
  const [listProvinsi, setListProvinsi] = useState([]);
  const [listKota, setListKota] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);

  const [status, setStatus] = useState('0');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [office_address, setOfficeAddress] = useState('');

  const [date_birth, setDateBirth] = useState('');
  const [photo, setPhoto] = useState('');
  const [nik, setNik] = useState('');
  const [ktp, setKtp] = useState('');
  const [phone, setPhone] = useState('');
  const [provinsi, setProvinsi] = useState('');
  const [kecamatan, setKecamatan] = useState('');
  const [kabupaten, setKabupaten] = useState('');
  const [kodepos, setKodepos] = useState('');
  const [address, setAddress] = useState('');
  const [short_profile, setShortProfile] = useState('');
  const [company_name, setCompanyName] = useState('');

  const handleUploadKTP = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setKtp(file)

    reader.onloadend = () => {
      setktpPreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (ktpPreviewUrl) {
      setktpPreview(<img src={ktpPreviewUrl} alt="" className="w-50" alt="KTP" />)
    } else {
      setktpPreview(<img src={upload} alt="" className="w-50" alt="KTP" />)
    }
  }, [ktpPreviewUrl]);

  useEffect(() => {
    API.adminc.participant.get_update(id)
      .then(json => {
        console.log(json.data);
        setParticipant(json.data)

        setStatus(json.data.status)
        setEmail(json.data.email)
        setName(json.data.name)
        setOfficeAddress(json.data.office_address)

        setDateBirth(json.data.date_of_birth)
        setPhoto(json.data.photo)

        setPhone(json.data.phone_number)
        setNik(json.data.nik)
        setKtp(json.data.ktp)
        if(json.data.ktp){
          setktpPreview(<img src={json.data.ktp} alt="" className="w-50" alt="KTP" />)
        }

        setKecamatan(json.data.region_id)
        setKodepos(json.data.postal_code)
        setAddress(json.data.address)
        setShortProfile(json.data.short_profile)
        setCompanyName(json.data.company_name)

        API.rajaongkir.kecamatan(json.data.region_id)
          .then(json => {
            setProvinsi(json.province_id)
            setKabupaten(json.city_id)

            API.rajaongkir.list_kota(json.province_id)
              .then(json => {
                setListKota(json)
              }).catch(err => {
                console.log(err);
              })

            API.rajaongkir.list_kecamatan(json.city_id)
              .then(json => {
                setListKecamatan(json)
              }).catch(err => {
                console.log(err);
              })

          }).catch(err => {
            console.log(err);
          })

      }).catch(err => {
        console.log(err);
      })

      API.rajaongkir.list_provinsi()
        .then(json => {
          setListProvinsi(json)
        }).catch(err => {
          console.log(err);
        })

  }, [])

  const handleSubmited = (event) => {
    event.preventDefault();

    setisLoading(true);

    const data = {
      email: email,
      name: name,
      office_address: office_address,
      is_active: status
    }

    if(date_birth){
      data.date_of_birth = date_birth
    }
    if(phone){
      data.phone_number = phone
    }
    if(kecamatan){
      data.region_id = kecamatan
    }
    if(kodepos){
      data.postal_code = kodepos
    }
    if(address){
      data.address = address
    }
    if(nik){
      data.nik = nik
    }
    if(ktp){
      if(id){
        if(participant.ktp !== ktp){
          data.ktp = ktp
        }
      }else{
        data.ktp = ktp
      }
    }

    console.log(data)

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    API.adminc.participant.update_participant(id, data, config)
      .then(json => {
        console.log(json.data);
        setisLoading(true);
        toast.success("Data Participant Berhasil Diubah", { position: toast.POSITION.TOP_CENTER });
        setDoneSubmit(true)
        setTimeout(() => {
          history.push('/admin/peserta-terregistrasi')
        }, 5000)
      }).catch(err => {
        console.log(err);
        toast.error("Data Participant Tidak Berhasil Diubah", { position: toast.POSITION.TOP_CENTER });
      })
  }


  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <BreadcrumbComp title={'Peserta'}>
          <li className="breadcrumb-item">
            <Link to="/admin/peserta-terregistrasi/ubah-peserta" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Peserta</li>
          <li className="breadcrumb-item">Peserta Terregistrasi</li>
          <li className="breadcrumb-item">{name}</li>
        </BreadcrumbComp>
      </div>

      <div className='col-md-12'>
        <Link to='/admin/peserta-terregistrasi'>
          <p className='btn p-0 text-theme'>
            <i className="fa fa-arrow-left"></i> Kembali
          </p>
        </Link>
    </div>

      <div className="row">
      <div className="col-sm">
        <div className="card">

          <div className="card-header">
              <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>PESERTA</h4>
              <span className='text-muted'>Data Peserta</span>
          </div>

          <form className="row mx-4" onSubmit={handleSubmited}>
              {/* kiri */}
              <div className="col-md-1 mr-5">
                {
                  photo &&
                  <img src={photo} alt="" className="rounded-circle" style={{width : '85px', height : '85px'}}/>
                }
              </div>

              {/* Tengah */}
              <div className='col-md-4'>
                <div className="form-group ">
                  <label htmlFor="Nama"><b>Nama</b></label>
                  <input type="text" className="form-control" name="nama" id="Nama" required defaultValue={name} onChange={(e)=>setName(e.target.value)} />
                </div>
                <div className="form-group ">
                  <label htmlFor="email"><b>Email</b></label>
                  <input type="email" className="form-control" name="email" id="email" required defaultValue={email} onChange={(e)=>setEmail(e.target.value)} />
                </div>
                <div className="form-group ">
                  <label htmlFor="TTL"><b>Tanggal Lahir</b></label>
                  <input type="text" className="form-control" name="TTL" id="TTL" required defaultValue={date_birth} onChange={(e)=>setDateBirth(e.target.value)} />
                </div>
                <div className="form-group ">
                  <label htmlFor="noTel"><b>Nomor Telepon</b></label>
                  <input type="number" className="form-control" name="noTel" id="noTel" required defaultValue={phone} onChange={(e)=>setPhone(e.target.value)} />
                </div>
                <div className="form-group ">
                  <label htmlFor="NIK"><b>NIK</b></label>
                  <input type="number" className="form-control" name="NIK" id="NIK" required defaultValue={nik} onChange={(e)=>setNik(e.target.value)} />
                </div>
                <div className="form-group">
                  <label><b>KTP</b></label> <br/>
                  {/* Preview */}
                  {ktpPreview}

                  <div className="button-custom w-50 py-3 my-3">
                    <div className="btn btn-pill btn-primary upload-image">
                        <span>
                          <i className="fa fa-cloud-upload"></i> &ensp; Upload KTP
                        </span>
                      <input
                          id="file-select"
                          type="file"
                          onChange={handleUploadKTP}
                      />
                    </div>
                  </div>
                  <p style={{ color : 'red', fontSize : '10px', marginBottom : '0px'}} >*Ukuran Foto KTP Maksimal 100KB</p>
                  <p style={{ color : 'red', fontSize : '10px' }} >*KTP hanya bisa diupload sekali, pastikan data tersebut adalah benar</p>
                </div>
                <div className="form-group mb-0 mt-1">
                  <label><b>Alamat</b></label>
                </div>
                <div className="form-group col-md-6 pl-0">
                  <select disabled={listProvinsi.length === 0} name="provinsi" className="form-control" id="provinsi" onChange={e => {
                    setProvinsi(e.target.value)
                    setListKota([]);
                    setListKecamatan([]);

                    API.rajaongkir.list_kota(e.target.value)
                        .then(json => {
                          setListKota(json);
                        });
                  }} required>
                    <option value="#">Provinsi</option>
                    {
                      listProvinsi.map(v => {
                        return <option value={v.province_id} selected={provinsi == v.province_id}>{v.province}</option>
                      })
                    }
                  </select>
                </div>
                <div className="form-group col-md-6 pl-0">
                  <select disabled={listKota.length === 0} name="kabupaten" className="form-control" id="kabupaten" onChange={e => {
                    setKabupaten(e.target.value)
                    setListKecamatan([]);

                    API.rajaongkir.list_kecamatan(e.target.value)
                        .then(json => {
                          setListKecamatan(json);
                        });
                  }} required>
                    <option value="#">Kota / Kabupaten</option>
                    {
                      listKota.map(v => {
                        return <option value={v.city_id} selected={kabupaten == v.city_id}>{v.city_name}</option>
                      })
                    }
                  </select>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <select disabled={listKecamatan.length === 0} name="kecamatan" className="form-control" id="kecamatan" onChange={(e)=>setKecamatan(e.target.value)} >
                      <option value="#">Kecamatan</option>
                      {
                        listKecamatan.map(v => (
                            <option value={v.subdistrict_id} selected={kecamatan == v.subdistrict_id}>{v.subdistrict_name}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="form-group col-md-4">
                    <input type="number" className="form-control" name="kodePos" id="kodePos" placeholder="Masukkan kode pos" required defaultValue={kodepos} onChange={(e)=>setKodepos(e.target.value)} />
                  </div>
                </div>
                <div className="form-group ">
                  <textarea className="form-control" name="alamat" required defaultValue={address} rows="5" onChange={(e)=>setAddress(e.target.value)}></textarea>
                </div>
              </div>


              {/* Kanan */}
              <div className='col-md-6 ml-3'>
                <div className="form-row">
                  <div className="col-md-5">
                    <div className="form-group ">
                      <label><b>Tanggal Registrasi</b></label>
                      {
                        participant &&
                        <p className="text-work" style={{fontSize : '14px'}}>{participant.registered_at} WIB</p>
                      }
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group ">
                      <label><b>Terakhir Aktif</b></label>
                      {
                        participant &&
                        <p className="text-work" style={{fontSize : '14px'}}>{participant.last_active_at} WIB</p>
                      }
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pl-0">
                <div className="form-group ">
                  <label htmlFor="exampleFormControlSelect7"><b>Status</b></label>
                  <select className="form-control digits" id="exampleFormControlSelect7" onChange={(e)=>setStatus(e.target.value)}>
                    <option value='0' selected={ status == 'Nonaktif' }>Non Aktif</option>
                    <option value='1' selected={ status == 'Aktif' }>Aktif</option>
                  </select>
                </div>
                </div>
                <div className="col-md-6 pl-0">
                <div className="form-group ">
                  <label htmlFor="namaPerusahaan"><b>Nama Perusahaan</b></label>
                  <input type="text" className="form-control" name="nama_perusahaan" id="namaPerusahaan" required  defaultValue={company_name} disabled/>
                </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="alamatKantor"><b>Alamat kantor</b></label>
                  <textarea className="form-control" name="alamat" id="alamatKantor" required defaultValue={office_address} rows="5" onChange={(e)=>setOfficeAddress(e.target.value)}></textarea>
                </div>
              </div>

              {/* bawahkanan */}
              <div className="col-md-12 m-5">
                <div className="text-right mb-5">
                  <Link to='/admin/peserta-terregistrasi'>
                    <button type="cancel" className="btn btn-outline-primary btn-pill mr-2">Batal</button>
                  </Link>
                  <button type="submit" className="btn btn-primary btn-pill">Simpan</button>
                </div>
              </div>
          </form>

        </div>
      </div>
      </div>
    </>
  )
}
export default UbahDataPeserta;
