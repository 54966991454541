import React, { useState, useEffect, createRef } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { TransaksiIcon } from "../../../component/common/constant/menu";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Input } from "semantic-ui-react";
import dummy_king from "../../../assets/images/dummy_king.png";
import overdue_transaksi from "../../../assets/images/overdue_transaksi.png";
import success from "../../../assets/images/success.png";
import "semantic-ui-css/semantic.min.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Pdf from "react-to-pdf";
import API from "../../../api";

const TransaksiPerusahaan = () => {
  const history = useHistory();
  const [modalDetail, setmodalDetail] = useState(false);
  const [detailSelected, setdetailSelected] = useState(null);
  const [overdueTransaksiSukses, setoverdueTransaksiSukses] = useState(false);
  const [overdueTransaksiModal, setoverdueTransaksiModal] = useState(false);
  const [overdueTagihan, setoverdueTagihan] = useState(false);
  const [order, setOrder] = useState('desc');

  const ref = createRef();

  let today = new Date();
  let day = new Intl.DateTimeFormat('en-US', {day: '2-digit'}).format(today)
  let month = new Intl.DateTimeFormat('en-US', {month: '2-digit'}).format(today)
  let date = today.getFullYear()+'-'+month+'-'+day+'_'+Math.floor(1000 + Math.random() * 9000);

  const statusModalDetail = "online";

  const columnsTabel = [
    {
      dataField: "id",
      text: "ID Transaksi",
      headerStyle: () => ({ width: "15%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p className="my-1 " style={{ fontWeight: "500", fontSize: "17px" }}>
          {column.text}
        </p>
      ),
      formatter: (cell, row) => <p className="font-weight-bold">{cell}</p>,
    },
    {
      dataField: "tanggal",
      text: "Tanggal",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p className="my-1 " style={{ fontWeight: "500", fontSize: "17px" }}>
          {column.text}
        </p>
      ),
    },
    {
      dataField: "deskripsi",
      text: "Deskripsi",
      headerStyle: () => ({ width: "25%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p className="my-1 " style={{ fontWeight: "500", fontSize: "17px" }}>
          {column.text}
        </p>
      ),
    },
    {
      dataField: "peserta",
      text: "Peserta",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p className="my-1 " style={{ fontWeight: "500", fontSize: "17px" }}>
          {column.text}
        </p>
      ),
    },
    {
      dataField: "harga",
      text: "Harga Total",
      headerStyle: () => ({ width: "15%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p className="my-1 " style={{ fontWeight: "500", fontSize: "17px" }}>
          {column.text}
        </p>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "15%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p className="my-1 " style={{ fontWeight: "500", fontSize: "17px" }}>
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <div
            className={` my-auto w-75 p-2 text-center text-capitalize card btn-status btn-status_${
              cel === "Lunas"
                ? "aktif"
                : cel === "Kedaluarsa"
                ? "expired"
                : cel === "Menunggu Pembayaran"
                ? "terpakai"
                : "ditolak"
            }`}
          >
            {cel}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Aksi",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p className="my-1 " style={{ fontWeight: "500", fontSize: "17px" }}>
          {column.text}
        </p>
      ),
      formatter: (cel, row) => (
        <button
          className="btn btn-pill btn-outline-primary btn-sm text-work"
          type="button"
          style={{ fontWeight: "600" }}
          onClick={() => {
            API.adminc.transaction
              .transaction_company_detail(row.id_transaction)
              .then((json) => {
                setdetailSelected(json.data);
                setmodalDetail(!modalDetail);
              });
          }}
        >
          Detail
        </button>
      ),
    },
  ];
  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get("page") || 1;

  const [transactions, setTransaction] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });
  useEffect(() => {
    API.adminc.transaction
      .list_company_transaction({ page, per_page, order })
      .then((json) => {
        let transaction = { ...transactions };
        transaction.data = [];

        json.data.data.map((val) => {
          transaction.data.push({
            id_transaction: val.id,
            id: val.no_transaction,
            tanggal: val.created_at,
            deskripsi: val.course.name,
            company: val.company.name,
            avatar: val.company.photo,
            peserta: val.total_participant,
            harga: new Intl.NumberFormat("id", {
              style: "currency",
              currency: "IDR",
            }).format(val.price),
            status: val.status,
          });
        });

        transaction.meta.from = (page - 1) * per_page + 1;
        transaction.meta.to =
          per_page > json.data.total
            ? json.data.total
            : page * per_page > json.data.total
            ? json.data.total
            : page * per_page;
        transaction.meta.total = json.data.total;
        transaction.meta.current_page = page;
        transaction.meta.total_page = Math.ceil(
          transaction.meta.total / transaction.meta.per_page
        );

        setTransaction(transaction);
      });
  }, [page, order]);

  const handleCetakLaporan = () => {
    API.adminc.transaction
      .list_company_transaction()
      .then((json) => {
        let transaction = { ...transactions };
        transaction.data = [];
        const tableRows = []

        json.data.data.map((v) => {
          const transactionData = [
            v.no_transaction,
            v.created_at,
            v.course.name,
            new Intl.NumberFormat("id", {
              style: "currency",
              currency: "IDR",
            }).format(v.price),
            v.status,
          ]

          tableRows.push(transactionData)
        });

        const doc = new jsPDF()
        const tableColumn = ['ID Transaksi', 'Tanggal', 'Deskripsi', 'Harga Total', 'Status']

        doc.text("List Transaksi Perusahaan", 14, 15);
        doc.autoTable(tableColumn, tableRows, { startY: 20 })
        doc.save(`transaksi-perusahaan-report_${date}.pdf`);
      });
  };

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"Transaksi Perusahaan"}>
          <li className="breadcrumb-item">
            <Link to="/admin/list-pelatihan" className="text-warning">
              <TransaksiIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">Transaksi Perusahaan</li>
        </BreadcrumbComp>
      </div>
      <div className="row" ref={ref}>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <div>
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  LIST TRANSAKSI
                </h5>
              </div>
              <div className="d-flex">
                <button
                  className=" btn btn-pill btn-outline-primary btn-sm text-work"
                  type="button"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                    handleCetakLaporan()
                  }}
                >
                  Download Laporan
                </button>
                {/* <Pdf targetRef={ref} filename={`transaksi-perusahaan-report_${date}.pdf`} scale={0.6} options={{
                  orientation: 'potrait',
                  unit: 'in',
                  format: [500, 950]
                }}>
                  {({ toPdf }) => <button
                      className=" btn btn-pill btn-outline-primary btn-sm text-work"
                      type="button"
                      style={{ fontWeight: "600" }}
                      onClick={toPdf}
                  > Download Laporan </button>}
                </Pdf> */}
                <div className="d-flex ml-4">
                  <label className="mr-3 my-auto">Urutkan berdasarkan</label>
                  <div>
                    <select
                      className="form-control digits"
                      id="exampleFormControlSelect7"
                      onChange={e => {
                        setOrder(e.target.value)
                      }}
                    >
                      <option value='desc'>Terbaru</option>
                      <option value='asc'>Terlama</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="overflow-auto w-100">
                <TableComp
                  rowClasses="text-left"
                  title_paggination="peserta"
                  columns={columnsTabel}
                  data={transactions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={overdueTransaksiModal}
        toggle={() => setoverdueTransaksiModal(!overdueTransaksiModal)}
        size="lg"
        style={{ maxWidth: "1050px" }}
      >
        <ModalBody className="text-center py-5">
          {overdueTransaksiSukses ? (
            <>
              <div>
                <img className="img-80" src={success} alt="#"></img>
                <h5
                  className="mt-3 text-nunito"
                  style={{ fontSize: "20px", fontWeight: "800" }}
                >
                  Pembayaran Sukses
                </h5>
                <p
                  className="text-nunito"
                  style={{ fontSize: "16", fontWeight: "400" }}
                >
                  Status transaksi kini berubah menjadi Lunas{" "}
                </p>
              </div>
            </>
          ) : (
            <>
              <div>
                <img className="img-100" src={overdue_transaksi} alt="#"></img>
              </div>
              <h5
                className="mt-3 text-nunito"
                style={{ fontSize: "20px", fontWeight: "800" }}
              >
                Masukkan jumlah nominal transfer
              </h5>
              <p
                className="text-nunito"
                style={{ fontSize: "16", fontWeight: "400" }}
              >
                Pastikan Anda memasukkan nominal transfer yang telah sesuai
                dengan yang telah disepakati sebelumnya{" "}
              </p>
              <div className="my-4">
                <Input label="Rp" placeholder="500000" />
              </div>
              <button
                className="btn btn-pill btn-primary btn-sm text-work"
                type="button"
                style={{ fontSize: "16px", fontWeight: "600" }}
                onClick={() => {
                  setoverdueTransaksiSukses(!overdueTransaksiSukses);
                  setoverdueTagihan(!overdueTagihan);
                }}
              >
                {" "}
                Bayar Transaksi
              </button>
            </>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalDetail}
        toggle={() => setmodalDetail(!modalDetail)}
        size="lg"
        style={{ maxWidth: "1050px" }}
      >
        <ModalHeader toggle={() => setmodalDetail(!modalDetail)}>
          Detail Transaksi
        </ModalHeader>
        <ModalBody className="pb-5">
          {detailSelected && (
            <>
              <div className="d-flex">
                <div className="mr-auto">
                  <label className="text-muted text-work">No Transaksi</label>
                  <h6 className="mb-4">{detailSelected.no_transaction}</h6>
                </div>
                {detailSelected.status === "Kedaluarsa" ? (
                  <div className="text-right">
                    <h6
                      className={`p-2 mb-2 card btn-status btn-status_expired`}
                      style={{ fontSize: "14px" }}
                    >
                      Anda sudah melewati batas pembayaran offline
                    </h6>
                    {overdueTagihan ? (
                      <Link to="/admin/lihat-tagihan">
                        <button
                          className="btn btn-pill btn-primary btn-sm text-work"
                          type="button"
                          style={{ fontSize: "16px", fontWeight: "600" }}
                        >
                          {" "}
                          Lihat Tagihan
                        </button>
                      </Link>
                    ) : (
                      <button
                        className="btn btn-pill btn-primary btn-sm text-work"
                        type="button"
                        style={{ fontSize: "16px", fontWeight: "600" }}
                        onClick={() => {
                          setmodalDetail(!modalDetail);
                          setoverdueTransaksiModal(!overdueTransaksiModal);
                        }}
                      >
                        {" "}
                        Bayar via Payment Gateway
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="text-center">
                    <button
                      className="btn btn-pill btn-outline-primary btn-sm"
                      type="button"
                      onClick={() => {
                        history.push({
                          pathname: '/admin/unduh-invoice',
                          state: { detail: detailSelected }
                        })
                      }}
                    >
                      {" "}
                      <i className="fa fa-download"></i> &nbsp;Unduh Invoice{" "}
                    </button>
                  </div>
                )}
              </div>
              <label className="text-muted text-work">Status</label>
              <h6
                className={`w-25 p-2 text-center card btn-status btn-status_${
                  detailSelected.status === "Lunas"
                    ? "aktif"
                    : detailSelected.status === "Kedaluarsa"
                    ? "expired"
                    : detailSelected.status === "Menunggu Pembayaran"
                    ? "terpakai"
                    : "ditolak"
                }`}
              >
                {detailSelected.status}
              </h6>
              <label className="text-muted text-work">Tanggal Pembelian</label>
              <h6 className="mb-4">{detailSelected.date}</h6>
              <div className="border-top border-bottom py-3 mb-3">
                <p
                  className="text-muted"
                  style={{ fontWeight: "600", fontSize: "18px" }}
                >
                  Daftar Pelatihan
                </p>
                {detailSelected?.detail_course &&
                  <div className="row mb-4">
                    <div className="col-md-6 d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <div>
                          <img
                            className="img-60"
                            src={detailSelected.detail_course.course.thumbnail}
                            alt="#"
                          ></img>
                        </div>
                        <label className="mb-0 ml-2">
                          <p
                            className="mb-0"
                            style={{ fontSize: "14px", fontWeight: "600" }}
                          >
                            {detailSelected.detail_course.course.name}
                          </p>
                          <div
                            className={`badge text-wrap text-capitalize ${
                              detailSelected.detail_course.course.type === "online"
                                ? "badge-primary"
                                : detailSelected.detail_course.course.type === "Face to Face"
                                ? "badge-danger"
                                : "badge-pink"
                            }`}
                          >
                            {detailSelected.detail_course.course.type}
                          </div>
                          <p
                            className="mb-0"
                          >
                            {detailSelected.detail_course.course.date_range}
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 border-right border-left d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <div>
                          <img
                            className="img-40 rounded-circle"
                            src={detailSelected.detail_course.trainer.photo}
                            alt="#"
                          ></img>
                        </div>
                        <label className="mb-0 ml-2">
                          <p className="font-weight-bold mb-0">
                            {detailSelected.detail_course.trainer.name}
                          </p>
                          <p className="mb-0">{detailSelected.detail_course.trainer.company}</p>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                      <p className="font-weight-bold mb-0 txt-success">
                        {new Intl.NumberFormat("id", {
                          style: "currency",
                          currency: "IDR",
                        }).format(detailSelected.detail_course.course.price)}
                      </p>
                    </div>
                  </div>
                }
              </div>
              <p
                className="text-muted"
                style={{ fontWeight: "600", fontSize: "18px" }}
              >
                Daftar Peserta
              </p>
              <table class="table table-striped w-75">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Nama Peserta</th>
                  </tr>
                </thead>
                <tbody>
                { detailSelected.participant &&
                  detailSelected.participant.map((data, key) => (
                    <tr>
                      <th scope="row">{key+1}</th>
                      <td>{data.name}</td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
              <div className="border-top py-3 mb-3">
                <p
                  className="text-muted"
                  style={{ fontWeight: "600", fontSize: "18px" }}
                >
                  Pembayaran
                </p>
                <div className="d-flex mx-5">
                  <p
                    className="text-muted text-work mb-0 mr-auto"
                    style={{ fontSize: "16px" }}
                  >
                    Subtotal Produk ({detailSelected.total_course} pelatihan)
                  </p>
                  <p
                    className="mb-0"
                    style={{ fontSize: "18px", fontWeight: "600" }}
                  >
                    {new Intl.NumberFormat("id", {
                      style: "currency",
                      currency: "IDR",
                    }).format(detailSelected.total_price)}
                  </p>
                </div>
                <div className="d-flex mx-5">
                  <p
                    className="text-muted text-work mb-0 mr-auto"
                    style={{ fontSize: "16px" }}
                  >
                    Total Keseluruhan
                  </p>
                  <p
                    className="mb-0 txt-success"
                    style={{ fontSize: "18px", fontWeight: "600" }}
                  >
                    {new Intl.NumberFormat("id", {
                      style: "currency",
                      currency: "IDR",
                    }).format(detailSelected.total_price)}
                  </p>
                </div>
                <div className="d-flex mx-5">
                  <p
                    className="text-muted text-work mb-0 mr-auto"
                    style={{ fontSize: "16px" }}
                  >
                    Metode Pembayaran
                  </p>
                  <p
                    className="mb-0"
                    style={{ fontSize: "18px", fontWeight: "600" }}
                  >
                    {detailSelected.metode}
                  </p>
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default TransaksiPerusahaan;
