import Post from "../Post";
import Get from "../Get";

const list_trainer = (data) => Get(`ketua-klien/trainers?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const trainer_detail = id => Get(`ketua-klien/trainers/${id}`);
const course_trainer = (id, data) => Get(`ketua-klien/trainers/courses/${id}?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);

const TrainerKetua = {
    list_trainer,
    trainer_detail,
    course_trainer
};

export default TrainerKetua;