import React, {useState, useEffect} from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useLocation } from 'react-router-dom'
import { Layers } from 'react-feather';
import API from "../../../api";

const ListPelatihan = () => {
  let query = new URLSearchParams(useLocation().search);
  const columnsTabel = [
    {
      dataField: 'id',
      text: 'ID',
      headerStyle: () => ({ width: '10%', textAlign: 'left' }),
    },
    {
      dataField: 'pelatihan',
      text: 'Nama Pelatihan',
      headerStyle: () => ({ width: '20%', textAlign: 'left' }),
    },
    {
      dataField: 'jenis_pelatihan',
      text: 'Jenis Pelatihan',
      headerStyle: () => ({ width: '15%', textAlign: 'left' }),
    },
    {
      dataField: 'tanggal',
      text: 'Tanggal Dibuat',
      headerStyle: () => ({ width: '20%', textAlign: 'left' }),
    },
    {
      dataField: 'status',
      text: 'Status',
      headerStyle: () => ({ width: '10%', textAlign: 'left' }),
    },
    {
      dataField: '',
      text: 'Action',
      headerStyle: () => ({ width: '10%', textAlign: 'center' }),
      formatter: (cel,row) => {
        return <div className="d-flex justify-content-center">
          <Link to={`/ketua/detail-pelatihan?id=${row.id}`} className='mx-auto'>
            <button className=" btn rounded-circle btn-success btn-xs py-1" type="button"> <i className='fa fa-eye'></i> </button>
          </Link>
          <Link to={`/ketua/edit-pelatihan?id=${row.id}&&status=${row.status}&&pelatihan=${row.pelatihan}&&jenis=${row.jenis_pelatihan}&&bidang=${row.bidang}`} className='mx-auto'>
            <button className=" mx-auto btn rounded-circle btn-warning btn-xs py-1" type="button"> <i className='fa fa-pencil'></i> </button>
          </Link>
          <button className=" mx-auto btn rounded-circle btn-danger btn-xs" type="button"> <i className='fa fa-trash'></i> </button>
        </div>;
      },
    }
  ];

  const per_page = 10;
  let page = query.get('page') || 1;

  const [courses, setCourse] = useState({
    data:[],
    meta:{
      from:1,
      to:0,
      total: 0,
      current_page: page,
      per_page,
      url: ''
    }
  });

  useEffect(() => {
    API.ketua.course.listCourse()
        .then(json => {
          let course = {...courses};
          course.data = [];

          json.data.data.map(val => {
            course.data.push({
              id: val.id,
              pelatihan: val.name,
              jenis_pelatihan: val.category,
              tanggal: val.created_at+' WIB',
              status: val.status,
            })
          });

          course.meta.from = (page - 1) * per_page + 1;
          course.meta.to = per_page > json.data.total ? json.data.total : page * per_page > json.data.total ? json.data.total : page * per_page;
          course.meta.total = json.data.total;
          course.meta.current_page = page;
          course.meta.total_page = Math.ceil(course.meta.total / course.meta.per_page);

          setCourse(course);
        });
  }, [page]);

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'List Pelatihan'}>
          <li className="breadcrumb-item">
            <Link to="/ketua/list-pelatihan" className='text-warning'>
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Pelatihan</li>
          <li className="breadcrumb-item">List Pelatihan</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 d-flex">
              <div className='mr-auto'>
                <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>PELATIHAN PERUSAHAAN</h4>
                <div className="row mt-3">
                  <div className="col-xl-12">
                    <form className="search-form contact-search ">
                      <div className="form-group mb-0">
                        <input
                          className="form-control badge-pill"
                          type="text"
                          placeholder="Cari pelatihan ..."
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div>
                <button className=" btn btn-pill btn-outline-primary btn-sm text-work" type="button" style={{ fontWeight: '600' }}><i className="fa fa-pencil"></i> &nbsp;Tambah Pelatihan</button>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className='overflow-auto w-100'>
                <TableComp
                    title_paggination='pelatihan'
                    columns={columnsTabel}
                    data={courses}
                  />
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default ListPelatihan;
