import React from 'react';
import Exam from '../../../../../assets/images/course_exam.png';

const MulaiTugas = (props) => {
    return(
        <div className='card p-5'>
        <div className='w-100 rounded mb-5'>
          <div className='text-center p-4'>
            <img className="img-fluid mb-4" src={Exam} alt="Omind Tech" style={{ width: '112.73px' }} />
            <h6 style={{ fontWeight: '600', fontSize: '16px' }}>Tugas Final</h6>
            <p className='text-muted' style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem a viverra tristique ornare velit orci orci. Mi purus augue at at pulvinar leo.</p>
            <button className="btn btn-pill btn-primary mt-3 font-weight-bold" type="button" onClick={()=>props.setView('tugas')}>Mulai Ujian</button>
          </div>
        </div>
      </div>
    )
}

export default MulaiTugas;