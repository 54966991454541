import React, { useState, useEffect } from 'react';
import { useLocation, Link, useHistory } from "react-router-dom";
import { BreadcrumbComp } from '../../../component';
import { Layers } from 'react-feather';
import { Collapse } from 'reactstrap';
import PelatihanPPTView from './pptView';
import PelatihanVideoView from './videoView';
import PelatihanPDFView from './pdfView';
import PelatihanKuisView from './kuisView';
import PelatihanTugasFinalView from './tugasFinalView';
import PelatihanVirtualClassView from './virtualClassView';
import PelatihanFaceToFaceView from './faceToFaceView';
import API from "../../../api";

const DetailPelatihanCourse = () => {
  let query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const id = query.get("id");
  const status = query.get("status");

  const [position, setposition] = useState(6);
  const [checkedValue, setcheckedValue] = useState(6);

  const [Pengenalan, setPengenalan] = useState(false);
  const [Rahasia, setRahasia] = useState(false);
  const [Teknik, setTeknik] = useState(false);

  const [accordions, setAccordion] = useState([]);
  const [course, setCourse] = useState(null);
  const [theory, setTheory] = useState(null);
  const [totTheory, setTotTheory] = useState([]);

  useEffect(() => {
    API.trainer.course.list_theory(id)
        .then(json => {
          setCourse(json.data);

          let accordion = [];
          let tottheory = [];
          let i = 1;

          if(json.data.meeting.length > 0){
            json.data.meeting.map(val => {
              tottheory.push(i-1);
              i += json.data.meeting.length;
              accordion.push(false);
            });
          }

          json.data.list_theory.map(val => {
            tottheory.push(i-1);
            i += val.theory.length;
            accordion.push(false);
          });

          setTotTheory(tottheory);
          setAccordion(accordion);
        })
  },[]);

  const handleChangeView = (value, theory) => {
    setposition(value);
    let teori = {...theory};
    if(teori.file)
      teori.file = teori.file.replace(/ /g,"%20");
    setTheory(teori);
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Pelatihan Saya'}>
          <li className="breadcrumb-item">
            <Link to="/pelatihan-saya" className='text-warning'>
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Saya</li>
          <li className="breadcrumb-item active">{course ? course.course.name : '...'}</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className='col-md-12'>
            <p className='btn p-0 text-theme' onClick={() => {
              history.goBack();
            }}>
              <i className="fa fa-arrow-left"></i> Kembali
            </p>
        </div>
        <div className='col-md-7'>
          {
            theory && (
                theory.type === 'Video' ?
                    <PelatihanVideoView
                        url={theory.file}
                        user={{
                          name: course.course.trainer_id.name,
                          img: course.course.trainer_id.profile_picture,
                          role:'Trainer',
                          perusahaan: course.course.company_id.name
                        }}
                    /> :
                    theory.type === 'Slide Powerpoint' ?
                        <PelatihanPPTView
                            file={theory.file}
                            user={{
                              name: course.course.trainer_id.name,
                              img: course.course.trainer_id.profile_picture.replace(/ /g,"%20"),
                              role:'Trainer',
                              perusahaan: course.course.company_id.name
                            }}
                        /> :
                        theory.type === 'E-Book' ?
                            <PelatihanPDFView link={theory.file.replace(/ /g,"%20")} name={theory.name} /> :
                            theory.type === 'Quiz' ?
                                <PelatihanKuisView id={theory.id} /> :
                                theory.type === 'Tugas Final' ?
                                    <PelatihanTugasFinalView id={theory.id} /> :
                                    course.course.type === 'Virtual Class' ? <PelatihanVirtualClassView data={theory} /> :
                                        course.course.type === 'Face to Face' ? <PelatihanFaceToFaceView data={theory} schedule={course.course_schedule} /> :
                                            course.course.type === 'Enquiries' ? <PelatihanFaceToFaceView data={theory} schedule={course.course_schedule} /> : ''
            )
          }
        </div>
        <div className='col-md-5'>
          {
            (course && course.course.type !== 'Online') && course.meeting.length > 0 &&
            <div className='card p-5'>
              <h6 className='text-muted mb-3' style={{ fontWeight: '600', fontSize: '22px' }}>Jadwal Pertemuan</h6>
              <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        {
                          course.meeting.map((t,key) => {
                            return <div className="checkbox p-0">
                              <input id="checkbox1" type="checkbox" checked={true} />
                              <label htmlFor="checkbox1" onClick={() => {
                                handleChangeView(totTheory[key], t)
                              }}>
                                <p className={position === totTheory[key] ? `text-theme mb-0` : `text-dark mb-0`}>{t.name}</p>
                                <p className={position === totTheory[key] ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i className='fa fa-clipboard'/></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{course.course.type}</p>
                              </label>
                            </div>
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            course &&
            <div className='card p-5'>
              <h6 className='text-muted mb-3' style={{ fontWeight: '600', fontSize: '22px' }}>Topik yang dibahas</h6>
              {
                course.list_theory.map((val, key) => {
                  if(course.meeting.length > 0)
                    key += course.meeting.length;
                  return <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-header">
                            <h5 className="mb-0">
                              <button className="btn btn-link pl-0 font-weight-bold" data-toggle="collapse"
                                      onClick={() => {
                                        let accord = [...accordions];
                                        accord[key] = !accord[key];
                                        setAccordion(accord);
                                      }}
                                      data-target="#collapseicon" aria-expanded={accordions[key]} aria-controls="collapseicon">{val.name}</button>
                            </h5>
                          </div>
                          <Collapse isOpen={accordions[key]}>
                            <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                              <div className="card-body filter-cards-view animate-chk p-3">
                                {
                                  val.theory.map((t,k) => {
                                    return <div className="checkbox p-0">
                                      <input id="checkbox1" type="checkbox" checked={true} />
                                      <label htmlFor="checkbox1" onClick={() => {
                                        handleChangeView(totTheory[key]+k, t)
                                      }}>
                                        <p className={position === totTheory[key]+k ? `text-theme mb-0` : `text-dark mb-0`}>{t.name}</p>
                                        {
                                          t.type === 'Slide Powerpoint' ?
                                              <p className={position === totTheory[key]+k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                                  className='fa fa fa-file-powerpoint-o'/></span>&nbsp;PPT</p> :
                                              t.type === 'Video' ?
                                                  <p className={position === totTheory[key]+k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                                      className='fa fa-play-circle-o'/></span>&nbsp;Video</p> :
                                                  t.type === 'E-Book' ?
                                                      <p className={position === totTheory[key]+k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                                          className='fa fa fa-file-pdf-o'/></span>&nbsp;PDF</p> :
                                                      t.type === 'Quiz' ?
                                                          <p className={position === totTheory[key]+k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                                              className='fa fa-pencil-square-o'/></span>&nbsp;Kuis</p> :
                                                          <p className={position === totTheory[key]+k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i className='fa fa-clipboard'/></span>&nbsp;Ujian Final</p>
                                        }
                                      </label>
                                    </div>
                                  })
                                }
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default DetailPelatihanCourse;
