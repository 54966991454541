import React, { useState, useEffect } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation } from "react-router-dom";
import { CompanyIcon } from "../../../component/common/constant/menu";
import remove from "../../../assets/images/remove.png";
import { Modal, ModalBody } from "reactstrap";
import API from "../../../api";
import { ToastContainer, toast } from "react-toastify";
const KodeVoucher = () => {
  const [detailSelected, setdetailSelected] = useState({});
  const [modalRemove, setmodalRemove] = useState();
  const [keyVoucher, setkeyVoucher] = useState(null);
  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get("page") || 1;

  const [vouchers, setVouchers] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });

  const columnsTabel = [
    {
      dataField: "voucher",
      text: "Kode Voucher",
      headerStyle: () => ({ width: "8%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="text-left">
            <label className="mb-0 ml-2">
              <p className="font-weight-bold">{row.voucher}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "tanggal",
      text: "Masa Berlaku",
      headerStyle: () => ({ width: "15%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "keterangan",
      text: "Keterangan",
      headerStyle: () => ({ width: "25%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="text-left">
            <label className="mb-0 ml-2">
              <p style={{ fontWeight: "400", fontSize: "14px" }}>
                {row.keterangan}
              </p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "nominal",
      text: "Nominal",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="text-center">
            <label className="mb-0 ml-2">
              <p style={{ fontWeight: "500", fontSize: "14px" }}>
                Rp{row.nominal}
              </p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <div className="align-items-center">
            <div
              className={`w-75 my-auto py-2 mx-auto my-auto text-center card btn-status btn-status_${
                cel === "Aktif"
                  ? "aktif"
                  : cel === "Expired"
                  ? "expired"
                  : cel === "Terpakai"
                  ? "terpakai"
                  : "draft"
              }`}
            >
              {cel}
            </div>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Aksi",
      headerStyle: () => ({ width: "8%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row, index) => {
        return (
          <div className="d-flex">
            {row.status === "Draft" ? (
              <div>
                {/* <button className=" mx-auto btn rounded-circle btn-success btn-xs py-1 ml-2" type="button"> <i className='fa fa-eye'></i> </button> */}
                <Link
                  to={`/admin/detail-voucher?status=${row.status}&id=${row.id}`}
                >
                  <button
                    className=" mx-auto btn rounded-circle btn-success btn-xs py-1 ml-2"
                    type="button"
                  >
                    {" "}
                    <i className="fa fa-eye"></i>{" "}
                  </button>
                </Link>
                &nbsp;
                <Link
                  to={`/admin/detail-voucher?status=${row.status}&id=${row.id}`}
                >
                  <button
                    className=" mx-auto btn rounded-circle btn-warning btn-xs py-1"
                    type="button"
                  >
                    {" "}
                    <i className="fa fa-pencil"></i>{" "}
                  </button>
                </Link>
                &nbsp;
                <button
                  className=" mx-auto btn rounded-circle btn-danger btn-xs py-1 ml-2"
                  onClick={() => {
                    setdetailSelected(row);
                    setkeyVoucher(index)
                    setmodalRemove(!modalRemove);
                  }}
                  type="button"
                >
                  {" "}
                  <i className="fa fa-trash"></i>{" "}
                </button>
              </div>
            ) : (
              <div>
                <Link
                  to={`/admin/detail-voucher?status=${row.status}&id=${row.id}`}
                >
                  <button
                    className=" mx-auto btn rounded-circle btn-success btn-xs py-1"
                    type="button"
                  >
                    {" "}
                    <i className="fa fa-eye"></i>{" "}
                  </button>
                </Link>
                &nbsp;
                <button
                  className=" mx-auto btn rounded-circle btn-danger btn-xs py-1"
                  onClick={() => {
                    setdetailSelected(row);
                    setkeyVoucher(index)
                    setmodalRemove(!modalRemove);
                  }}
                  type="button"
                >
                  {" "}
                  <i className="fa fa-trash"></i>{" "}
                </button>
              </div>
            )}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    API.adminc.vouchers.list_vouchers({ page, per_page }).then((json) => {
      let voucher = { ...vouchers };
      voucher.data = [];

      json.voucher.data.map((val) => {
        voucher.data.push({
          id: val.id,
          voucher: val.code,
          tanggal: val.due_date,
          keterangan: val.description,
          nominal: val.nominal,
          status: val.status,
        });
      });

      voucher.meta.from = (page - 1) * per_page + 1;
      voucher.meta.to =
        per_page > json.voucher.total
          ? json.voucher.total
          : page * per_page > json.voucher.total
          ? json.voucher.total
          : page * per_page;
      voucher.meta.total = json.voucher.total;
      voucher.meta.current_page = page;
      voucher.meta.total_page = Math.ceil(
        voucher.meta.total / voucher.meta.per_page
      );

      setVouchers(voucher);
    });
  }, [page]);

  const deleteVoucher = (id, key) => {
    API.adminc.vouchers.delete_voucher_code(id)
      .then(json => {
        let temp_vouchers = {...vouchers}

        temp_vouchers.data.splice(key, 1)

        setVouchers(temp_vouchers)

        toast.success("Data Voucher Berhasil Dihapus", { position: toast.POSITION.TOP_CENTER });
        setmodalRemove(false)
      }).catch(err => {
        toast.error("Data Voucher Tidak Berhasil Dihapus", { position: toast.POSITION.TOP_CENTER });
        setmodalRemove(false)
      })
  }

  return (
    <>
      <div className="page-header">
        <ToastContainer/>
        <BreadcrumbComp title={"List Voucher"}>
          <li className="breadcrumb-item">
            <Link to="/admin/kode-voucher" className="text-warning">
              <CompanyIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">List Voucher</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 pb-3 d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  LIST VOUCHER PERUSAHAAN
                </h5>
              </div>
              <div>
                <Link to="/admin/tambah-voucher">
                  <button
                    className=" btn btn-pill btn-outline-primary btn-sm text-work"
                    type="button"
                    style={{ fontWeight: "600" }}
                  >
                    <i className="fa fa-pencil"></i> &nbsp;Tambah Voucher
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="overflow-auto w-100">
                <TableComp
                  rowClasses="text-center"
                  title_paggination="voucher"
                  columns={columnsTabel}
                  data={vouchers}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalRemove}
        toggle={() => setmodalRemove(!modalRemove)}
        size="md"
        style={{ maxWidth: "548px", maxHeight: "275px" }}
      >
        <ModalBody className="pb-5">
          <div className="text-center mt-5 mb-4">
            <img src={remove} alt="" />
          </div>
          <div className="text-center mb-4">
            <h5 className="text-weight-bold">
              Apakah anda yakin ingin menghapus voucher ini?
            </h5>
          </div>
          <div className="text-center">
            <button className="btn btn-pill btn-primary px-5" onClick={() => {
              deleteVoucher(detailSelected.id, keyVoucher)
            }}>ya</button>
            <button
              className="btn btn-pill btn-outline-primary ml-2 px-4 pl-2"
              type="cancel"
              onClick={() => {
                setmodalRemove(false)
              }}
            >
              Tidak
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default KodeVoucher;
