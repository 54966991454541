import React, {useState, useEffect} from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link } from 'react-router-dom'
import { Home } from 'react-feather';
import PengenalanItemsCard from './pengenalanItemsCard';
import laptop_promo from '../../../assets/images/laptop_promo.png';
import bg_promo from '../../../assets/images/bg_promo.png';
import API from "../../../api";

const GeneralResource = () => {
  const [courses, setCourses] = useState([]);
  const PelatihanItems = [
    {
      item_judul: 'Pengenalan Lingkungan Sistem',
      item_desc: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti. Velit lobortis amet, id aliquam...',
      user_name: 'SDM - Training',
      user_company: 'PT Samitra Daniswara Mandiri',
    },
    {
      item_judul: 'Mengapa Harus Online ?',
      item_desc: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti. Velit lobortis amet, id aliquam...',
      user_name: 'SDM - Training',
      user_company: 'PT Samitra Daniswara Mandiri',
    },
    {
      item_judul: 'Bagaimana cara dapat sertifikat ?',
      item_desc: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti. Velit lobortis amet, id aliquam...',
      user_name: 'SDM - Training',
      user_company: 'PT Samitra Daniswara Mandiri',
    },
  ];

  useEffect(() => {
    API.peserta.intro_course.general_resource()
        .then(json => {
          let pelatihan = [];

          json.data.data.map(val => {
            pelatihan.push({
              item_id: val.id,
              item_judul: val.name,
              item_desc: val.description,
            });
          });

          setCourses(pelatihan);
        })
  }, []);

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'General Course'}>
          <li className="breadcrumb-item">
            <Link to="/" className='text-warning'>
              <Home />
            </Link>
          </li>
          <li className="breadcrumb-item">General Course</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header text-warning">
              <h4 className='font-weight-bold'>PENGENALAN UMUM</h4><span className='font-weight-bold'>Tata Cara Penggunaan Sistem</span>
            </div>
            <div className="card-body">
              {courses.map((data,index)=>(
                <PengenalanItemsCard
                  key={index}
                  {...data}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='card shadow-0' style={{background: `url(${bg_promo})`, minHeight: '16rem', backgroundSize:'100%', boxShadow:'0 !important', backgroundColor: '#FFEA2D'}}>
        <div className="card-body d-flex p-0">
          <div className='my-auto ml-4'>
            <h1 className='font-weight-bold text-nunito'>Belajar dari mana saja, kondisi apa saja!</h1>
            <p>Mulai perkembangan Anda dengan bergabung dengan pelatihan yang sesuai dengan Anda</p>

            <Link to='pelatihan-lainnya'>
              <button className="btn btn-pill btn-primary btn-sm mt-3" type="button">Temukan Pelatihan Lainnya</button>
            </Link>
          </div>
          <img className='ml-auto mt-auto mr-4 w-25' src={laptop_promo} />
        </div>
      </div>
    </>
  )
}

export default GeneralResource
