import Get from "../Get";
import Post from "../Post";
import Delete from "../Delete";
const list_vouchers = (data) =>
  Get(
    `admin-c/voucher?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const detail_voucher = (id) => Get(`admin-c/voucher/${id}`);
const insert_voucher = (data) => Post(`admin-c/voucher`, data);
const update_voucher = (id, data) => Post(`admin-c/voucher/${id}`, data);
const delete_voucher_code = (id) => Delete(`admin-c/voucher/code/${id}`);
const list_vouchers_bills = (data) =>
  Get(
    `admin-c/voucher/bills?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const VouchersAdminC = {
  list_vouchers,
  detail_voucher,
  insert_voucher,
  update_voucher,
  delete_voucher_code,
  list_vouchers_bills,
};

export default VouchersAdminC;
