import Get from "../Get";
import Post from "../Post";

const get_detail_chairman = (id) => Get(`super-admin/chairman/${id}`);
const insert_chairman = (data) => Post(`super-admin/chairman`, data);
const update_chairman = (id, data) => Post(`super-admin/chairman/${id}`, data);

const SuperadminChairman = {
  get_detail_chairman,
  insert_chairman,
  update_chairman,
};

export default SuperadminChairman;
