import React, { useState, useEffect,Fragment } from 'react';
import logo_SDM from '../../../assets/images/logo-SDM.png';
import UserMenu from './userMenu';
import Notification from './notification';
import SearchHeader from './searchHeader';
import { Link, useHistory } from 'react-router-dom';
import { AlignLeft, Bell, MoreHorizontal } from 'react-feather';
import { Checkbox } from 'semantic-ui-react';
import { WalletIcon } from '../constant/menu';
import {numberWithDots} from "../../../helper";
import API from "../../../api";
import Pusher from 'pusher-js';
import {pusher_app_key} from "../../../helper";

let pusher = new Pusher(pusher_app_key(), {
  cluster: 'ap1',
});

let channel = pusher.subscribe('notification');

const Header = (props) => {
  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [headerbar, setHeaderbar] = useState(true);
  const [kategori, setkategori] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [kategoriToggle, setkategoriToggle] = useState(false);

  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState([]);
  const [unread, setUnread] = useState(0);
  const [newUnread, setNewUnread] = useState(0);

  const user = JSON.parse(localStorage.getItem('data'));

  const updateNotification = () => {
    API.notification.get_all({per_page: 3})
        .then(json => {
          let notification = json.data.data;
          setNotifications(notification);

          let unread_t = notification.filter(v => {
            return v.status_read === 0;
          }).length;

          setUnread(unread_t);
        });
  };

  useEffect(() => {
    updateNotification();
    channel.bind(`notify-${user.id}`, res => {
      updateNotification();
    });
  }, []);

  const history = useHistory();

  let role = '';

  switch (user.role.name) {
    case 'trainer' : role = '/trainer'; break;
    case 'ketua klien' : role = '/ketua'; break;
    case 'admin c' : role = '/admin'; break;
    case 'super admin' : role = '/superadmin'; break;
  }

  useEffect(() => {
    API.peserta.category.all()
        .then(json => {
          let clist = [];
          let col_list = [];

          json.data.data.map((val, key) => {
            col_list.push(val);
            if((key+1) % 10 === 0 || (key+1 === json.data.data.length)){
              clist.push(col_list);
              col_list = [];
            }
          });
          setCategoryList(clist);

        })
  }, []);

  const handleChangeKategori = (e, { value }) => {
    setkategori(value);
    history.push(`/pelatihan-lainnya/detail?kategori_id=${value[0]}&kategori=${value[1]}`);
    setkategoriToggle(!kategoriToggle)
  };

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar)
      document.querySelector(".page-main-header").classList.remove('open');
      document.querySelector(".page-sidebar").classList.remove('open');
    } else {
      setSidebar(!sidebar)
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open');
    }
  }

  function showRightSidebar() {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar)
      document.querySelector(".right-sidebar").classList.add('show');
    } else {
      setRightSidebar(!rightSidebar)
      document.querySelector(".right-sidebar").classList.remove('show');
    }
  }

  //full screen function
  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <Fragment>
      <div className="page-main-header" >
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">
            <div className="logo-wrapper">
              <Link to="/">
                <img className="img-fluid" src={user?.company_logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="mobile-sidebar d-block">
            <div className="media-body text-right switch-sm">
              <label className="switch" onClick={() => openCloseSidebar()} style={{ cursor: 'pointer' }}>
                <AlignLeft />
              </label>
            </div>
          </div>
          {props.as === 'peserta' &&
            <li className="onhover-dropdown ml-4">
              <div className="media align-items-center">
                <span className='text-warning mr-2'>
                  <i className="fa fa-th"></i>
                </span>
                <label className='mb-0'>Kategori</label>
              </div>
              <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover" style={{ width: "50rem" }}>
                <div className='px-3 row'>
                  {
                    categoryList.map(val => {
                      return <div className='col-md-4'>
                        {
                          val.map(v => {
                            return <div>
                              <Checkbox
                                  label={v.name}
                                  name='kategori'
                                  value={[v.id, v.name]}
                                  checked={kategori[0] === v.id}
                                  onChange={handleChangeKategori}
                              />
                            </div>
                          })
                        }
                      </div>
                    })
                  }
                </div>
              </ul>
            </li>
          }

          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
              <li>
                {props.as === 'peserta' &&
                  <SearchHeader />
                }
              </li>
              {props.as === 'peserta' &&
                  <div className='border badge-pill py-2 d-flex align-items-center mr-3'>
                    <WalletIcon />
                    <p className='text-theme mb-0 ml-2 font-weight-bold text-nunito'>{numberWithDots(user?.point)}</p>
                  </div>
              }
              <li className="onhover-dropdown">
                <Notification role={role}
                    notifications={notifications}
                    newNotifications={newNotifications}
                    unread={unread}
                    newUnread={newUnread}
                    setNotifications={setNotifications}
                    setUnread={setUnread}
                />
                <Bell color='black' />
                {
                  unread + newUnread > 0 && <span className="dot"></span>
                }
                <Notification role={role}
                    notifications={notifications}
                    newNotifications={newNotifications}
                    unread={unread}
                    newUnread={newUnread}
                    setNotifications={setNotifications}
                    setUnread={setUnread}
                />
              </li>
              <UserMenu as={props.as} role={role} />
            </ul>
            <div className="d-lg-none mobile-toggle pull-right" onClick={() => setHeaderbar(!headerbar)}><MoreHorizontal /></div>
          </div>
          <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-airplay m-0"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1">
                </path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName"></div>
              </div>
            </div>
          </script>
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">Your search turned up 0 results. This most likely means the backend is down, yikes!</div>
          </script>
        </div>
      </div>
    </Fragment>
  )
};
export default Header;
