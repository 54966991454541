import Get from "../Get";

const list_crosspay = (data) =>
  Get(
    `super-admin/crosspay?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const crosspay_detail = (id) => Get(`super-admin/crosspay/${id}`);
const SuperadminCrosspay = {
  list_crosspay,
  crosspay_detail,
};

export default SuperadminCrosspay;
