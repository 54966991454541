import React, {useState, useEffect, useRef} from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useLocation, useHistory } from "react-router-dom";
import { Users } from 'react-feather';
import API from "../../../api";
import swal from 'sweetalert2';

const TambahSoalQuis = () => {
  let query = new URLSearchParams(useLocation().search);
  let id = query.get('id');

  let fileBulkRef = useRef(null);

  const history = useHistory();

  const [list_quiz, setListQuiz] = useState([]);
  const [fileBulk, setFileBulk] = useState(null);
  const [bulkDownload, setBulkDownload] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUploadBulk = e => {
    setFileBulk(e.target.files[0]);
  };

  const handleSubmitBulk = () => {
    setIsSubmitting(true);
    swal.fire({
      onBeforeOpen () {
        swal.showLoading()
      },
      onAfterClose () {
        swal.hideLoading()
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    });

    API.adminc.quiz.bulk(id, {
      file: fileBulk
    }).then(json => {
      swal.fire('Sukses', json.message, 'success');
      loadQuiz();
      setIsSubmitting(false);
    }).catch(e => {
      swal.fire('Error', e.message, 'error');
      setIsSubmitting(false);
    })
  }

  const handleDelete = (id, key) => {
    swal.fire({
      title: 'Konfirmasi hapus',
      text: "Anda yakin ingin menghapus soal ini?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus',
      cancelButtonText: 'Batal',
    }).then((result) => {
      if (result.isConfirmed) {
        swal.fire({
          onBeforeOpen () {
            swal.showLoading()
          },
          onAfterClose () {
            swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });

        API.adminc.quiz.del(id)
            .then(json => {
              let quiz_temp = [...list_quiz];
              quiz_temp.splice(key, 1);
              setListQuiz(quiz_temp);

              swal.fire('Sukses',json.message,'success');
            }).catch(e => {
              swal.fire('Error',e.message,'error');
            });
      }
    })
  }

  const columnsTabel = [
    {
      dataField: 'no',
      text: 'No',
      headerStyle: () => ({ width: '10%', textAlign: 'left' }),
      headerFormatter: (column) => <p className='my-1 ' style={{ fontWeight: '500', fontSize: '17px' }}>{column.text}</p>,
    },
    {
      dataField: 'soal',
      text: 'Soal',
      headerStyle: () => ({ width: '25%', textAlign: 'left' }),
      headerFormatter: (column) => <p className='my-1 ' style={{ fontWeight: '500', fontSize: '17px' }}>{column.text}</p>,
      formatter: (cel, row) => (
          <span dangerouslySetInnerHTML={{__html: row.soal}} />
      )
    },
    {
      dataField: 'jawaban',
      text: 'Jawaban',
      headerStyle: () => ({ width: '16%', textAlign: 'left' }),
      headerFormatter: (column) => <p className='my-1 ' style={{ fontWeight: '500', fontSize: '17px' }}>{column.text}</p>,
    },
    {
      dataField: '',
      text: 'Aksi',
      headerStyle: () => ({ width: '10%', textAlign: 'center', }),
      headerFormatter: (column) => <p className='my-1 ' style={{ fontWeight: '500', fontSize: '17px' }}>{column.text}</p>,
      formatter: (cel, row, idx) => (
        <div className="d-flex justify-content-between w-75 mx-auto">
          <button className="btn rounded-circle btn-warning btn-xs py-1" type="button" onClick={() => history.push(`/admin/atur-quiz/update?quiz_id=${row.id}`)}> <i className='fa fa-pencil'></i> </button>
          <button className="btn rounded-circle btn-danger btn-xs" type="button" onClick={() => handleDelete(row.id, idx)}> <i className='fa fa-trash'></i> </button>
        </div>
      ),
    }
  ]

  const loadQuiz = () => {
    let data = [];
    let i = 1;

    API.adminc.quiz.getAll(id)
        .then(json => {
          json.data.map(v => {
            data.push({
              no: i,
              id: v.id,
              soal: v.question,
              jawaban: v.correct_answer,
            });
            i++;
          });

          setListQuiz(data);
        });
  }

  useEffect(() => {
    API.adminc.quiz.bulk_download()
        .then(json => {
          setBulkDownload(json.data);
        })
    loadQuiz();
  }, []);

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'List pelatihan'}>
          <li className="breadcrumb-item">
            <Link to="/admin/list-pelatihan" className='text-warning'>
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item" style={{ cursor: 'pointer' }}>Manajemen Pelatihan</li>
          <li className="breadcrumb-item">Manajemen Soal Quiz</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center border-0">
              <div>
                <h5 className='text-work text-theme' style={{ fontSize: '24px', fontWeight: '600' }}>PELATIHAN PERUSAHAAN</h5>
                <p className='text-work text-muted' style={{ fontSize: '18px', fontWeight: '600' }}>Atur Soal Quiz</p>
              </div>
              <div>
                <center>
                  <button className=" btn btn-pill btn-outline-primary btn-sm text-work" type="button" style={{ fontWeight: '600' }} onClick={() => {
                    history.push(`/admin/atur-quiz/tambah?id=${id}`)
                  }}>
                    <i className="fa fa-pencil"></i> &nbsp;Tambah Soal Quiz</button>
                </center>

                <p className='mr-3 my-2 text-center' style={{ fontSize: '12px', fontWeight: '600' }}>Atau</p>
                <button className='btn btn-success mr-3' style={{ fontSize: '12px', fontWeight: '600' }} onClick={() => window.open(bulkDownload , '_blank')}> <i className="fa fa-cloud-download"></i> &nbsp;Download Template Bulk Soal</button>
                <button className='btn btn-primary' style={{ fontSize: '12px', fontWeight: '600' }} onClick={() => fileBulkRef.current.click()}><i className="fa fa-cloud-upload"></i> &nbsp;{fileBulk ? fileBulkRef.current.files[0].name : 'Upload Soal (.xlsx)'} </button>
                <input type="file" ref={fileBulkRef} onChange={handleUploadBulk} accept='.xlsx' style={{display: 'none'}}/>
                <br/>
                <button disabled={isSubmitting || !fileBulk} className='btn btn-primary mt-3' style={{ fontSize: '12px', fontWeight: '600' }} onClick={handleSubmitBulk}>Simpan Bulk Soal </button>
                <button className='btn btn-danger mt-3 ml-3' style={{ fontSize: '12px', fontWeight: '600' }} onClick={() => {
                  history.push(`/admin/detail-pelatihan?id=${localStorage.getItem('course_id')}`)
                }}>Kembali </button>
              </div>
            </div>

            <div className="card-body">
              <div className='overflow-auto w-100'>
                {
                  list_quiz.length !== 0 && (
                    <TableComp
                      rowClasses='text-left'
                      title_paggination='peserta'
                      columns={columnsTabel}
                      data={{
                        data: list_quiz
                      }}
                    />
                  )
                }
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default TambahSoalQuis;
