import React, { useState, useEffect, createRef } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation, useHistory } from "react-router-dom";
import { CompanyIcon } from "../../../component/common/constant/menu";
import remove from "../../../assets/images/remove.png";
import { Modal, ModalBody } from "reactstrap";
import { ModalHeader } from "semantic-ui-react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Pdf from "react-to-pdf";
import API from "../../../api";
import {numberWithDots} from "../../../helper";

const TagihanVoucher = () => {
  const history = useHistory();
  const [detailSelected, setdetailSelected] = useState({});
  const [modalDetail, setmodalDetail] = useState();
  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get("page") || 1;

  const ref = createRef();

  let today = new Date();
  let day = new Intl.DateTimeFormat('en-US', {day: '2-digit'}).format(today)
  let month = new Intl.DateTimeFormat('en-US', {month: '2-digit'}).format(today)
  let date = today.getFullYear()+'-'+month+'-'+day+'_'+Math.floor(1000 + Math.random() * 9000);

  const [vouchers, setVouchers] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });
  const columnsTabel = [
    {
      dataField: "voucher",
      text: "Kode Voucher",
      headerStyle: () => ({ width: "8%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="text-left">
            <label className="mb-0 ml-2">
              <p className="font-weight-bold">{row.voucher}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "tanggal",
      text: "Tanggal",
      headerStyle: () => ({ width: "15%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "keterangan",
      text: "Keterangan",
      headerStyle: () => ({ width: "25%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="text-left">
            <label className="mb-0 ml-2">
              <p style={{ fontWeight: "400", fontSize: "14px" }}>
                {row.keterangan}
              </p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "nominal",
      text: "Nominal",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="text-center">
            <label className="mb-0 ml-2">
              <p style={{ fontWeight: "500", fontSize: "14px" }}>
                Rp {numberWithDots(row.nominal)}
              </p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "15%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <div className="align-items-center my-auto">
            <div
              className={`w-75 my-auto py-2 mx-auto my-auto text-center card btn-status btn-status_${
                cel === "Terpakai" ? "terpakai" : cel === "Aktif" ? "aktif" : "draft"
              }`}
              style={{ fontSize: "14px" }}
            >
              {cel}
            </div>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Aksi",
      headerStyle: () => ({ width: "8%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <div className="text-center">
            {row.status === "Aktif" ? (
              <div>
                <button
                  className=" mx-auto btn rounded-circle btn-success btn-xs py-1 ml-2"
                  type="button"
                  onClick={() => {
                    API.adminc.vouchers
                    .detail_voucher(row.id)
                    .then((json) => {
                      setdetailSelected(json.data);
                    });
                    setmodalDetail(!modalDetail);
                  }}
                >
                  {" "}
                  <i className="fa fa-eye"></i>
                </button>
              </div>
            ) : (
              <div>
                <button
                  className=" mx-auto btn rounded-circle btn-warning btn-xs py-1"
                  onClick={() => {
                    API.adminc.vouchers
                    .detail_voucher(row.id)
                    .then((json) => {
                      setdetailSelected(json.data);
                    });
                    setmodalDetail(!modalDetail);
                  }}
                  type="button"
                >
                  {" "}
                  <i className="fa fa-pencil"></i>{" "}
                </button>
              </div>
            )}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    API.adminc.vouchers.list_vouchers_bills({ page, per_page }).then((json) => {
      let voucher = { ...vouchers };
      voucher.data = [];

      json.voucher.data.map((val) => {
        voucher.data.push({
          id: val.id,
          voucher: val.code,
          tanggal: val.date,
          keterangan: val.description,
          nominal: val.nominal,
          status: val.status,
        });
      });

      voucher.meta.from = (page - 1) * per_page + 1;
      voucher.meta.to =
        per_page > json.voucher.total
          ? json.voucher.total
          : page * per_page > json.voucher.total
          ? json.voucher.total
          : page * per_page;
      voucher.meta.total = json.voucher.total;
      voucher.meta.current_page = page;
      voucher.meta.total_page = Math.ceil(
        voucher.meta.total / voucher.meta.per_page
      );

      setVouchers(voucher);
    });
  }, [page]);

  const handleCetakTagihan = () => {
    API.adminc.vouchers.list_vouchers_bills().then((json) => {
      let voucher = { ...vouchers };
      voucher.data = [];
      const tableRows = [];

      json.voucher.data.map((v) => {
        const voucherData = [
          v.code,
          v.date,
          v.description,
          'Rp'+v.nominal,
          v.status,
        ]

        tableRows.push(voucherData)
      });

      const doc = new jsPDF()
      const tableColumn = ['Kode Voucher', 'Tanggal', 'Keterangan', 'Nominal', 'Status']

      doc.autoTable(tableColumn, tableRows, { startY: 20 })
      doc.text("List Tagihan Voucher", 14, 15);
      doc.save(`tagihan-voucher-report_${date}.pdf`);
    });
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"Tagihan Voucher"}>
          <li className="breadcrumb-item">
            <Link to="/admin/kode-voucher" className="text-warning">
              <CompanyIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">List Voucher</li>
        </BreadcrumbComp>
      </div>
      <div className="row" ref={ref}>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 pb-3 d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  TAGIHAN VOUCHER
                </h5>
              </div>
              <div>
                {/* <button
                  className=" btn btn-pill btn-outline-primary btn-sm text-work"
                  type="button"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                    handleCetakTagihan()
                  }}
                >
                  <i className="fa fa-print"></i> &nbsp;Cetak List Tagihan
                </button> */}
                <Pdf targetRef={ref} filename={`tagihan-voucher-report_${date}.pdf`} scale={0.6} options={{
                  orientation: 'potrait',
                  unit: 'in',
                  format: [500, 950]
                }}>
                  {({ toPdf }) => <button
                      className=" btn btn-pill btn-outline-primary btn-sm text-work"
                      type="button"
                      style={{ fontWeight: "600" }}
                      onClick={toPdf}
                  > <i className="fa fa-print"></i> &nbsp;Cetak List Tagihan </button>}
                </Pdf>
              </div>
            </div>
            <div className="card-body">
              <div className="overflow-auto w-100">
                <TableComp
                  rowClasses="text-center"
                  title_paggination="voucher"
                  columns={columnsTabel}
                  data={vouchers}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalDetail}
        toggle={() => setmodalDetail(!modalDetail)}
        size="md"
        style={{ maxWidth: "700px", maxHeight: "350px" }}
        className="modal-dialog modal-dialog-centered rounded"
      >
        <ModalBody className="pb-5">
          <div className="text-center">
            <p
              style={{ fontSize: "18px", fontWeight: "" }}
              className="text-muted font-weight-bold m-2 mb-4"
            >
              Detail Voucher
            </p>
          </div>
          <div className="row">
            <div className="col-md-6 ml-5 ">
              <h6 className="text-work text-muted mb-0">Kode Voucher</h6>
              <div className="d-flex align-items-center mb-3">
                <div>
                  <p className="text-muted mb-0">{detailSelected.code}</p>
                </div>
                <label className="ml-2 mb-0">
                  <p className="font-weight-bold mb-0 text-theme">Terpakai</p>
                </label>
              </div>
              <label className="text-muted text-work mb-0">Perusahaan</label>
              <div className="d-flex align-items-center mb-3">
                <label className="mb-0 ml-2">
                  <p className="font-weight-bold mb-0">
                    {detailSelected.company}
                  </p>
                </label>
              </div>
              <label className="text-muted text-work mb-0">
                Tanggal Berlaku
              </label>
              <div className="d-flex align-items-center mb-3">
                <label className="mb-0">
                  <p className="font-weight-bold mb-0">
                    {detailSelected.start}{" "}
                    <span
                      className="mx-2 text-muted"
                      style={{ fontWeight: "400" }}
                    >
                      {" "}
                      hingga{" "}
                    </span>{" "}
                    {detailSelected.end}
                  </p>
                </label>
              </div>
              <label className="text-muted text-work mb-0">
                Keterangan Voucher
              </label>
              <div className="d-flex align-items-center mb-3">
                <p className="text-work text-muted mb-0">
                  {detailSelected.description}
                </p>
              </div>
            </div>
            <div className="col-md-4 ml-5">
              <label className="text-muted text-work mb-0">
                Nominal Voucher
              </label>
              <div className="d-flex align-items-center mb-3">
                <div>
                  <p className="txt-success font-weight-bold mb-0">
                    Rp {numberWithDots(detailSelected.nominal)}
                  </p>
                </div>
              </div>
              <label className="text-muted text-work mb-1">Status</label>
              <div className="align-items-center mb-0">
                <div
                  className={`p-2 px-3 badge text-capitalize ${
                    detailSelected.status === "Terpakai"
                      ? "badge-warning"
                      : "badge-success"
                  }`}
                >
                  {detailSelected.status}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TagihanVoucher;
