import Get from "../Get";
import Post from "../Post";

const my_profile = () => Get(`ketua-klien/my-profile`);
const update_profile = (data) => Post(`ketua-klien/my-profile/profile`, data);
const update_account = (data) => Post(`ketua-klien/my-profile/account`, data);

const ProfileKetua = {
    my_profile,
    update_profile,
    update_account
};

export default ProfileKetua;
