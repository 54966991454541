import React from 'react';
import { Progress } from 'reactstrap';
import { Link } from "react-router-dom";

const ItemsPelatihanDetail = (props) => {
  const {
    id,
    judul,
    status,
    desc,
    progress,
  } = props;

  return (
    <div className='card-body p-0'>
      <div className='card' style={{ background: '#F8F8F8' }}>
        <div className='card-body p-3'>
          <h5 className='font-weight-bold mb-0'>{judul}</h5>
          <label>
            <div className={`badge text-wrap text-capitalize ${status === 'Online' ? 'badge-primary' : status === 'Face to Face' ? 'badge-danger' : 'badge-pink'}`} >
              {status}
            </div>
          </label>
          <p className='text-justify'>{desc.length > 200 ? desc.substr(0, 200)+'...' : desc}</p>
          <div className='mb-3'>
            <div className='d-flex'>
              <h6 className='font-weight-bold mr-auto text-muted'>PROGRESS PELATIHAN</h6>
              <h6 className='font-weight-bold text-muted'>{progress}%</h6>
            </div>
            <Progress color="primary" value={progress} className="sm-progress-bar" />
          </div>
          <div className='text-right'>
            <Link to={`/trainer/progress-peserta?id=${id}`}>
              <button className="ml-auto btn btn-pill btn-outline-primary btn-sm font-weight-bold" type="button">Detail Pelatihan</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemsPelatihanDetail;
