import React, {useState, useEffect} from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useHistory } from "react-router-dom";
import { CompanyIcon } from '../../../component/common/constant/menu';
import { ToastContainer, toast } from 'react-toastify';
import camera from '../../../assets/images/camera.png';
import upload from '../../../assets/images/upload.png';
import API from "../../../api";

const TambahPeserta = () => {
  const user = JSON.parse(localStorage.getItem('data'));
  const history = useHistory();
  const [DoneSubmit,  setDoneSubmit] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [isDisabled, setisDisabled] = useState(false)

  const [ktpPreview, setktpPreview] = useState(null);
  const [ktpPreviewUrl, setktpPreviewUrl] = useState("");

  const [tambahFotoPreview, settambahFotoPreview] = useState(null);
  const [tambahFotoPreviewUrl, settambahFotoPreviewUrl] = useState("");

  const [data_profession, setDataProfession] = useState([]);
  const [listProvinsi, setListProvinsi] = useState([]);
  const [listKota, setListKota] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);

  const [status, setStatus] = useState('0');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [office_address, setOfficeAddress] = useState('');

  const [date_birth, setDateBirth] = useState('');
  const [photo, setPhoto] = useState('');
  const [nik, setNik] = useState('');
  const [ktp, setKtp] = useState('');
  const [phone, setPhone] = useState('');
  const [provinsi, setProvinsi] = useState('');
  const [kecamatan, setKecamatan] = useState('');
  const [kabupaten, setKabupaten] = useState('');
  const [kodepos, setKodepos] = useState('');
  const [address, setAddress] = useState('');
  const [short_profile, setShortProfile] = useState('');

  const handletambahFotoChange = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setPhoto(file);

    reader.onloadend = () => {
      settambahFotoPreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (tambahFotoPreviewUrl) {
      settambahFotoPreview(tambahFotoPreviewUrl);
    } else {
      settambahFotoPreview(false);
    }
  }, [tambahFotoPreviewUrl]);

  const handleUploadKTP = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setKtp(file)

    reader.onloadend = () => {
      setktpPreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (ktpPreviewUrl) {
      setktpPreview(ktpPreviewUrl);
    } else {
      setktpPreview(false);
    }
  }, [ktpPreviewUrl]);

  useEffect(() => {
    if (ktpPreviewUrl) {
      setktpPreview(ktpPreviewUrl);
    } else {
      setktpPreview(false);
    }
  }, [ktpPreviewUrl]);

  useEffect(() => {
    API.adminc.profession.get_profession()
      .then(json => {
        setDataProfession(json.data)
      }).catch(err => {
        console.log(err);
      })
  }, [])

  useEffect(() => {
    API.rajaongkir.list_provinsi()
      .then(json => {
        setListProvinsi(json)
      }).catch(err => {
        console.log(err);
      })
  }, [])

  const handleSubmited = (event) => {
    event.preventDefault();

    if(password !== confirm_password){
      toast.error("Confirm Password Tidak Sesuai", { position: toast.POSITION.TOP_CENTER });
    }else{
      const data = {
        email: email,
        password: password,
        name: name,
        office_address: office_address,
        is_active: status
      }

      if(date_birth){
        data.date_of_birth = date_birth
      }
      if(photo){
        data.profile_picture = photo
      }
      if(phone){
        data.phone_number = phone
      }
      if(kecamatan){
        data.region_id = kecamatan
      }
      if(address){
        data.address = address
      }
      if(address){
        data.address = address
      }
      if(ktp){
        data.ktp = ktp
      }
      if(short_profile){
        data.short_profile = short_profile
      }

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }

      API.adminc.participant.add_participant(data, config)
        .then(json => {
          console.log(json.data);
          setisLoading(true);
          toast.success("Peserta Berhasil Ditambahkan", { position: toast.POSITION.TOP_CENTER });
          setDoneSubmit(true)
          setTimeout(() => {
            history.push('/admin/peserta-terregistrasi')
          }, 5000)
        }).catch(err => {
          console.log(err);
          toast.error("Peserta Tidak Berhasil Ditambahkan", { position: toast.POSITION.TOP_CENTER });
        })

      console.log(data)
    }
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Tambah Peserta'}>
          <li className="breadcrumb-item">
            <Link to="/admin/tambah-peserta" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Peserta</li>
          <li className="breadcrumb-item">Tambah Peserta</li>
        </BreadcrumbComp>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
          <div className="card-header d-flex pb-3">
            <div className='mr-auto'>
              <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>TAMBAH PESERTA BARU</h4>
              <span className='text-muted'>Data Peserta</span>
            </div>
            <div className="mr-3">
              <span className='text-work' style={{ fontWeight: '500' }}>Status</span>
              <select className="form-control digits mt-2 pr-5 pl-1 p-0" id="exampleFormControlSelect7" onChange={(e)=>setStatus(e.target.value)}>
                <option value='0'>Non Aktif</option>
                <option value='1'>Aktif</option>
              </select>
            </div>
          </div>
          <div className="mx-4 mb-2">
              <hr/>
          </div>

          <form className="row mx-4" onSubmit={handleSubmited}>
          <div className='col-md-5 pr-5 ml-1'>
              <div className="form-group ">
                <label htmlFor="InputEmail"><b>Email</b></label>
                <input type="email" className="form-control" name="email" id="InputEmail" required placeholder="Masukkan Email Peserta" onChange={(e)=>setEmail(e.target.value)} />
              </div>
              <div className="form-group ">
                <label htmlFor="InputPassword"><b>Password</b></label>
                <input type="password" className="form-control" name="password" id="InputPassword" required placeholder="Masukkan password" onChange={(e)=>setPassword(e.target.value)} />
              </div>
              <div className="form-group ">
                <label htmlFor="konfirmasi_password"><b>Konfirmasi Password</b></label>
                <input type="password" className="form-control" name="konfirmasi_password" id="konfirmasi_password" required placeholder="Masukkan ulang password" onChange={(e)=>setConfirmPassword(e.target.value)} />
              </div>
              <div className="form-group ">
                <label htmlFor="nama"><b>Nama</b></label>
                <input type="text" className="form-control" name="nama" id="nama" required placeholder="Masukkan nama peserta" onChange={(e)=>setName(e.target.value)} />
              </div>
            </div>

            <div className='ml-4 col-md-5 pr-5'>
              <div className="form-group ">
                <label htmlFor="namaPerusahaan"><b>Nama Perusahaan</b></label>
                <input type="text" className="form-control" name="nama_perusahaan" id="namaPerusahaan" required placeholder="PT Berkah Nusantara" value={user.company_name} disabled/>
              </div>

              <div className="form-group ">
                <label htmlFor="alamatKantor"><b>Alamat kantor - Cabang</b></label>
                <textarea className="form-control" name="alamat" id="alamatKantor" required placeholder="Masukkan alamat kantor" rows="5" onChange={(e)=>setOfficeAddress(e.target.value)}></textarea>
              </div>
            </div>
            <div className="col card-body px-2 pb-2">
              <hr/>
            </div>
            <div className="form-group pl-2 pt-0 mt-0">
              <input
                type="checkbox"
                id="inlineCheckbox1"
                value="option1"
                onChange={() => {
                  if(isDisabled === true){
                    setisDisabled(false)
                  }else{
                    setisDisabled(true)
                  }
                }} />
              <label className="text-muted" htmlFor="inlineCheckbox1">&nbsp;&nbsp;Biarkan peserta mengisi biodata sendiri</label>
            </div>
            <div className="col card-body pl-1 pb-3">
              <h6 className='text-muted' style={{ fontWeight: '700' }}>Biodata Peserta</h6>
            </div>
            <div className='col-md-5 pr-5 ml-2'>
            <div className="form-group ">
                <label htmlFor="InputTTL"><b>Tanggal Lahir</b></label>
                <input type="date" className="form-control" name="email" id="InputTTL" placeholder="Masukkan Email Peserta" disabled={isDisabled} onChange={(e)=>setDateBirth(e.target.value)} />
              </div>
              {/* Input File */}
              <div className="form-group my-3">
                {/* Preview */}
                <div className="text-left ml-4">
                <img className="img-fluid my-3 rounded-circle ml-3" width="100px" height="100px" src={tambahFotoPreview || camera} alt="camera"/>
                </div>
                {/* Input */}
                <div className="button-custom w-50 py-3">
                  <div className="upload-image">
                    <span className="mr-5">
                        Tambah Foto
                    </span>
                    <input
                      id="file-select"
                      type="file"
                      disabled={isDisabled}
                      onChange={handletambahFotoChange}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-2">
                <label htmlFor="nomorTelepon"><b>Nomor Telepon</b></label>
                <input type="number" className="form-control" name="nomorTelepon" id="nomorTelepon" placeholder="Masukkan nomor telepon" disabled={isDisabled} onChange={(e)=>setPhone(e.target.value)} />
              </div>
              <div className="form-group mb-0 mt-1">
                <label htmlFor="alamat"><b>Alamat</b></label>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <select disabled={listProvinsi.length === 0 || isDisabled} name="provinsi" className="form-control" id="provinsi" onChange={e => {
                    setProvinsi(e.target.value)
                    setListKota([]);
                    setListKecamatan([]);

                    API.rajaongkir.list_kota(e.target.value)
                        .then(json => {
                          setListKota(json);
                        });
                  }} required>
                    <option value="#">Provinsi</option>
                    {
                      listProvinsi.map(v => {
                        return <option value={v.province_id}>{v.province}</option>
                      })
                    }
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <select disabled={listKota.length === 0 || isDisabled} name="kabupaten" className="form-control" id="kabupaten" onChange={e => {
                    setKabupaten(e.target.value)
                    setListKecamatan([]);

                    API.rajaongkir.list_kecamatan(e.target.value)
                        .then(json => {
                          console.log(json)
                          setListKecamatan(json);
                        }).catch(err => {
                          console.log(err);
                        });
                  }} required>
                    <option value="#">Kota / Kabupaten</option>
                    {
                      listKota.map(v => {
                        return <option value={v.city_id}>{v.city_name}</option>
                      })
                    }
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <select disabled={listKecamatan.length === 0 || isDisabled} name="kecamatan" className="form-control" id="kecamatan" onChange={(e)=>setKecamatan(e.target.value)} >
                    <option value="#">Kecamatan</option>
                    {
                      listKecamatan.map(v => (
                          <option value={v.subdistrict_id}>{v.subdistrict_name}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <input type="number" className="form-control" name="kodePos" id="kodePos" placeholder="Masukkan kode pos" disabled={isDisabled} onChange={(e)=>setKodepos(e.target.value)} />
                </div>
              </div>
            </div>

            <div className='ml-4 col-md-5 pr-5'>
            <div className="form-group mt-4">
              <div className="form-group ">
                <label htmlFor="nikKTP"><b>NIK KTP</b></label>
                <input type="text" className="form-control" name="nik" id="nikKTP" placeholder="Masukkan NIK KTP Peserta" disabled={isDisabled} onChange={(e)=>setNik(e.target.value)}/>
              </div>
              {/* Preview */}
              <img className="img-fluid w-50 mb-3 h-50" src={ktpPreview || upload} alt="KTP"/>
              {/* Input */}
              <div className="button-custom w-50 py-3">
                <div className="btn btn-pill btn-primary upload-image">
                  <span>
                    <i className="fa fa-cloud-upload"></i> &ensp; Upload KTP
                  </span>
                  <input
                    id="file-select"
                    type="file"
                    disabled={isDisabled}
                    onChange={handleUploadKTP}
                  />
                </div>
              </div>
            </div>
            </div>

            <div className="ml-2 col-md-6">
              <div className="form-group mt-2">
                  <textarea className="form-control" name="alamatDetail" id="alamatDetail" rows="5" placeholder="Nama Jalan / Alamat Detail" disabled={isDisabled} onChange={(e)=>setAddress(e.target.value)}></textarea>
              </div>
            </div>

            <div className="ml-2 col-md-12">
              <div className="form-group mb-3">
                  <label htmlFor="profil"><b>Profil Singkat</b></label>
                  <textarea className="form-control p-4" name="profil" id="profil" rows="10" disabled={isDisabled} onChange={(e)=>setShortProfile(e.target.value)} ></textarea>
                  <hr/>
              </div>
            </div>

            <div className="col-md-12 m-5">
              <div className="text-right mb-5">
                <Link to='/admin/peserta-terregistrasi'>
                  <button type="cancel" className="btn btn-outline-primary btn-pill mr-2">Batal</button>
                </Link>
                <button type="submit" className="btn btn-primary btn-pill">Simpan</button>
                <ToastContainer />
              </div>
            </div>
            </form>

          </div>
        </div>
      </div>


    </>
  )
}

export default TambahPeserta;
