import Get from "../Get";
import Post from "../Post";

const get = (data) => Get(`super-admin/courses?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const get_total = () => Get(`super-admin/dashboard/total`);
const topCourse = (data) => Get(`super-admin/dashboard/top-5-courses?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const getPromo = (data) => Get(`super-admin/dashboard/promos?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const getVoucher = (data) => Get(`super-admin/dashboard/vouchers?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const getTransaction = (data) => Get(`super-admin/dashboard/transactions?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);

const SuperadminDashboard = {
    get,
    get_total,
    topCourse,
    getPromo,
    getVoucher,
    getTransaction,
};

export default SuperadminDashboard;
