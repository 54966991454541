import React, { useState, useEffect } from 'react';
import MulaiTugas from './mulaiTugas';
import Tugas from './tugas';
import MengirimTugas from './mengirimTugas';
import LulusTugas from './lulusTugas';
import HubungiTrainerTugas from './hubungiTrainerTugas';
import CobaLagiTugas from './cobaLagiTugas';
import CobaLagiGagalTugas from './cobaLagiGagalTugas';
import API from "../../../../api";

const PelatihanTugasFinalView = (props) => {
  const {id} = props;
  const [View, setView] = useState('mulai');
  const [Attempt, setAttempt] = useState(1);
  const [status, setStatus] = useState(false);
  const [result, setResult] = useState(null);

  useEffect(() => {
    API.peserta.course.check_final_task(id)
        .then(json => {
          console.log(json);
          setStatus(json.status);
          setResult(json.result);
          if(json.result !== null){
            setAttempt(json.result.attempt + 1)
          }else{
            setAttempt(1)
          }
        })
  }, []);

  return (View === 'lulus' || status === 'Sukses') ? (
    <LulusTugas Attempt={Attempt} setView={setView} result={result} />
  ):  View === 'tugas' ? (
    <Tugas Attempt={Attempt} setView={setView} id={id} />
  ) :  View === 'mengirim' ? (
      <MengirimTugas Attempt={Attempt} setView={setView}/>
  ) : View === 'cobalagi' ? (
      <CobaLagiTugas Attempt={Attempt} setView={setView}  />
  ) :  View === 'cobalagigagal' || (status !== 'Sukses' && Attempt > 1 && Attempt < 4) ? (
      <CobaLagiGagalTugas Attempt={Attempt} setView={setView} result={result}  />
  ) : View === 'hubungi' || (status !== 'Sukses' && Attempt === 4) ? (
      <HubungiTrainerTugas Attempt={Attempt} setView={setView} result={result}  />
  ) :View === 'mulai' ? (
    <MulaiTugas Attempt={Attempt} setView={setView}/>
  ) :  View === 'cobalagigagal' ? (
    <CobaLagiGagalTugas Attempt={Attempt} setView={setView}  />
  ) :   ''
}

export default PelatihanTugasFinalView;
