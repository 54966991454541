import Post from "../Post";
import Get from "../Get";

const general_resource = () => Get(`peserta/intro-course/general_resource?per_page=9999`);
const start_course = (id) => Get(`peserta/intro-course/start_intro_course/${id}`);
const theory_accessed = data => Post(`peserta/intro-course/theory_accessed`, data);

const IntroCoursePeserta = {
    general_resource,
    start_course,
    theory_accessed,
};

export default IntroCoursePeserta;
