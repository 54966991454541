import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import quiz from "../../../../assets/images/quiz.png";
import { Input, UncontrolledCollapse, Button } from "reactstrap";

const PelatihanKuisView = (props) => {
  const { questions } = props;
  let query = new URLSearchParams(useLocation().search);
  const status = query.get("status");
  let id = query.get("id");
  const [View, setView] = useState("pembahasan");
  const [Attempt, setAttempt] = useState(1);

  const soal = [
    {
      soal:
        "Candi Borobudur merupakan kenampakan buatan yang merupakan ikon dari provinsi",
      penjelasan:
        "Candi Borrobudur adalah sebuah candi Buddha yang terletak di Borobudur, Magelang, Jawa Tengah, Indonesia. Candi ini terletak kurang lebih 100 km di sebelah barat daya Semarang, 86 km di sebelah barat Surakarta, dan 40 km di sebelah barat laut Yogyakarta.",
      a: "Jawa Tengah",
      b: "Daerah Istimewa Yogyakarta ",
      c: "Jawa Timur",
      d: "DKI Jakarta",
    },
    {
      soal: "1 + 1 =",
      penjelasan:
        "Candi Borrobudur adalah sebuah candi Buddha yang terletak di Borobudur, Magelang, Jawa Tengah, Indonesia. Candi ini terletak kurang lebih 100 km di sebelah barat daya Semarang, 86 km di sebelah barat Surakarta, dan 40 km di sebelah barat laut Yogyakarta.",
      a: "2",
      b: "4",
      c: "6",
      d: "7",
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <img className="img-60 mr-3" src={quiz} alt="omind" />
        <label className="mb-0 ml-2 mr-auto">
          <h6 className="mb-0 font-weight-bold">Quiz</h6>
          <p className="mb-0">Quiz Teknik Bicara</p>
        </label>
      </div>
      {questions.question.map((data, index) => (
        <div className="card p-5 mt-4" key={index}>
          <div className="mb-3">
            <p>
              {index + 1}. {data.question}
            </p>
            <div className="ml-4">
              {data.options.map((option) => {
                return (
                  <>
                    <label>
                      <Input type="radio" name="radio1" />
                      {option.option}. {option.description}
                    </label>{" "}
                    <br />
                  </>
                );
              })}
            </div>
            <Button
              className="text-theme text-nunito p-0"
              color="link"
              id={`toggler${index}`}
              style={{ fontSize: "14px", fontWeight: "800" }}
            >
              Lihat Penjelasan <i className="fa fa-chevron-right"></i>
            </Button>
            <UncontrolledCollapse toggler={`#toggler${index}`}>
              <div
                className="ml-3 mt-2 bg-light p-3 rounded text-dark"
                style={{ fontWeight: "600", fontSize: "14px" }}
              >
                {data.correct_answer}. {data.discussion}
              </div>
            </UncontrolledCollapse>
          </div>
        </div>
      ))}
      <div className="text-center mb-5">
        <Link to={`/trainer/detail-pelatihan?id=${id}&status=${status}`}>
          <Button
            className="text-theme text-nunito"
            color="link"
            style={{ fontSize: "14px", fontWeight: "800" }}
          >
            <i className="fa fa-chevron-left"></i> Kembali ke Detail Pelatihan
          </Button>
        </Link>
      </div>
    </>
  );
};

export default PelatihanKuisView;
