import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Form, Button } from 'semantic-ui-react';
import IMGEmailKonfirmasi from '../../assets/images/email-konfirmasi.png';
import API from "../../api";

const ResetPasswordByEmail = (props) => {
    const [DataEmail, setDataEmail] = useState('');
    const [DoneSubmit, setDoneSubmit] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmited = () => {
        setIsSubmitting(true);

        API.user.forgot_password({email: DataEmail})
            .then(json => {
                setIsSubmitting(false);
                setDoneSubmit(true)
            })
            .catch(e => {
                alert(e.message);
            })
    }

    const resend = () => {
        setIsSubmitting(true);
        API.user.forgot_password({email: DataEmail})
            .then(json => {
                setIsSubmitting(false);
                alert('Sukses mengirim ulang e-mail konfirmasi');
            })
            .catch(e => {
                alert(e.message);
            })
    }

    if(DoneSubmit){
        return(
            <div className='text-center mx-auto'>
                <img src={IMGEmailKonfirmasi} className='w-25' alt='Omind'/>

                <h4 className='font-weight-bold mt-3'>Email Konfirmasi Dikirim</h4>
                <p>Kami telah mengirimkan email konfirmasi ke email Anda. Klik link pada email tersebut untuk mereset password Anda</p>

                <p>Belum menerima kode verifikasi?</p>
                <Button disabled={isSubmitting} onClick={resend} type='button' className="btn btn-primary btn-block mb-3" primary loading={false}>{isSubmitting ? 'Mengirim ulang...' : 'Kirim Ulang Email Konfirmasi'}</Button>

                <Button type='button' className="btn btn-outline-primary btn-block " primary loading={false} basic onClick={()=>props.setviewLogin('login')}>Kembali ke Login</Button>

            </div>
        )
    }

    return(
        <Form
            className='w-100'
            onSubmit={handleSubmited}
        >
            <Form.Field className='mb-3'>
                <h2>Lupa Password</h2>
                <p>
                    Jika Anda tidak ingat dengan password Anda, Anda dapat memilih salah satu cara di bawah untuk reset password
                </p>
            </Form.Field>

            <div className="form-group">
                <input className="form-control" type="text" name="email" required onChange={(e)=>setDataEmail(e.target.value)}  placeholder="Alamat Email Anda"  />
            </div>

            <Button type='submit' className="btn btn-primary btn-block" primary loading={false}>{isSubmitting ? 'Submitting...' : 'Kirim Email Konfirmasi'}</Button>

            <p className='text-center'>Atau</p>

            <Button type='button' className="btn btn-outline-primary btn-block "  primary loading={false} basic onClick={()=>props.setviewLogin('resetPasswordByPhone')}>Verifikasi via Nomor Ponsel</Button>

        </Form>
    )
}

export default ResetPasswordByEmail;
