import React, {useState, useEffect, createRef, useRef} from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Users, MessageSquare, ArrowLeft } from 'react-feather';
import CardItem from './CardItem';
import API from "../../../api";
import {titleCase} from "../../../helper";
import {Modal, ModalBody} from "reactstrap";
import swal from "sweetalert2";

const TugasFinal = () => {
  const user = JSON.parse(localStorage.getItem('data'));
  let query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  let id = query.get('id');
  const [modalSelesai, setmodalSelesai] = useState(false);
  const [finalTasks, setFinalTasks] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [attemptFinalTask, setAttemptFinalTask] = useState(0);
  const [hiddenFileInputs, setHiddenFileInputs] = useState([]);
  const [fileWillUploaded, setFileWillUploaded] = useState([]);
  const [status, setStatus] = useState([]);
  const [peserta, setPeserta] = useState(null);

  const commentTextArea = useRef(null);

  useEffect(() => {
    getFinalTask();
  }, []);

  const getFinalTask = () => {
    API.trainer.course.show_final_task(id)
        .then(json => {
          API.trainer.participant.get_one(json.data.user_id)
              .then(json => {
                setPeserta(json.data);
              });

          let fnltsks = [];
          let fn_status = [];

          setAttemptFinalTask(json.data.attempt_final_task);

          setHiddenFileInputs(hidden => (
              Array(json.data.data.length).fill().map((_, i) => hidden[i] || createRef())
          ));

          json.data.data.map((v, key) => {
            let comments = [];

            v.finaltask_comment.map(val => {
              comments.push({
                text: val.comment,
                role: titleCase(val.user_id.role_id.name),
                name: val.user_id.name,
                company: val.user_id.company_id.name,
                attempt: val.attempt,
                ava: val.user_id.profile_picture.replace(/ /g, '%20'),
              });
            });

            let tmp_fn_status = v.finaltask_answer.length > 0 && v.finaltask_answer[v.finaltask_answer.length - 1].status === 'Pass';

            fn_status.push(tmp_fn_status);

            fnltsks.push({
              id: v.id,
              soal: v.question,
              file_soal: v.question_file.replace(/ /g, '%20'),
              download_jawaban: v.finaltask_answer,
              upload_koreksi: v.finaltask_answer,
              status: tmp_fn_status,
              attempt: v.attempt_answer,
              komentar: comments,
            })
          });

          console.log(fnltsks);

          setStatus(fn_status);
          setFinalTasks(fnltsks);
        });
  };

  const handleChange = (e) => {
    const fileUploaded = e.target.files[0];

    let temp_fileWillUploaded = [...fileWillUploaded];
    temp_fileWillUploaded[e.target.name] = fileUploaded;

    console.log(temp_fileWillUploaded);

    setFileWillUploaded(temp_fileWillUploaded);
  };

  const postComment = (data, key) => {
    API.peserta.course.comment_final_task(id, {
      final_task_id : data.id,
      comment: commentText,
      attempt: attemptFinalTask,
      attempt_answer: data.attempt
    });

    let newComments = [...data.komentar];
    newComments.push({
      text: commentText,
      role: 'Trainer',
      name: user.name,
      attempt: key + 1,
      company: user.company_name,
      ava: user.profile_picture.replace(/ /g, '%20'),
    });
    commentTextArea.current.value = '';
    setCommentText('');

    let fnl = [...finalTasks];
    fnl[key].komentar = newComments;
    setFinalTasks(fnl);
  }

  const submitFile = () => {
    swal.fire({
      onBeforeOpen () {
        swal.showLoading()
      },
      onAfterClose () {
        swal.hideLoading()
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    });
    let promises = [];

    for(let i = 1; i < finalTasks.length; i++){
      if(!status[i] && !fileWillUploaded[i]){
        swal.fire('Error', 'Anda harus mengupload koreksi jika jawabannya salah', 'error');
        setmodalSelesai(false);
        return
      }
      else if (status[i] && fileWillUploaded[i]){
        swal.fire('Error', 'Jangan upload koreksi jika jawabannya benar', 'error');
        setmodalSelesai(false);
        return
      }
    }

    finalTasks.map((v,k) => {

      promises.push(API.trainer.course.change_status_final_task(id, {final_task_id: v.id}, {status: status[k] ? 'Pass' : 'Coba Lagi'}));

      if(fileWillUploaded[k]){
        let data = new FormData();
        data.append('final_task_id', v.id);
        data.append('correction_file', fileWillUploaded[k]);
        data.append('attempt', attemptFinalTask);

        promises.push(API.trainer.course.upload_correction_final_task(id, data));
      }
    });

    Promise.all(promises).then(res => {
      swal.close();
      setmodalSelesai(false);
      swal.fire('Sukses', 'Berhasil mengoreksi tugas final', 'success');
      getFinalTask();
    }).catch(e => {
      swal.close();
      setmodalSelesai(false);
      swal.fire('Error', e.message, 'error');
    });

  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Tugas Final'}>
          <li className="breadcrumb-item">
            <Link to="/trainer/peserta-saya" className='text-warning'>
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Peserta Saya</li>
          <li className="breadcrumb-item">Detail Peserta</li>
          <li className="breadcrumb-item">Progress Peserta</li>
        </BreadcrumbComp>
        <Link className="text-theme d-flex align-items-center font-weight-bold mt-4 text-work" to={`/trainer/progress-peserta?id=${id}`}>
          <ArrowLeft /> &nbsp;Kembali
      </Link>
      </div>
      <div className='row'>

        <div className='col-md-8'>
          {
            attemptFinalTask <= 3 ?
              finalTasks && <CardItem
                data={finalTasks}
                hiddenFileInputs={hiddenFileInputs}
                handleChange={handleChange}
                status={status}
                setStatus={setStatus}
                commentText={commentText}
                setCommentText={setCommentText}
                commentTextArea={commentTextArea}
                postComment={postComment}
                user={user}
                modalSelesai={modalSelesai}
                setmodalSelesai={setmodalSelesai}
                fileWillUploaded={fileWillUploaded}
                attemptFinalTask={attemptFinalTask}
            /> :
                <p>Tugas Final Berakhir</p>
          }

        </div>
        <div className='col-md-4'>
          <div className='card p-4'>
            {
              peserta &&
              <div className="card-header p-0">
                <h5 className='text-muted font-weight-bold'>Profil Peserta</h5>
                <div className='d-flex align-items-center my-3'>
                  <div>
                    <img className="img-60 rounded-circle" src={peserta.profile_picture.replace(/ /g, '%20')} alt="#" />
                  </div>
                  <label className='mb-0 ml-2'>
                    <h6 className='mb-0'>{peserta.name}</h6>
                    <p className='mb-0'><span className="font-weight-bold">Peserta</span> - {peserta.company_id}</p>
                  </label>
                </div>
                <p className='mb-0'>No Telepon</p>
                <p className="font-weight-bold">{peserta.phone_number}</p>
                <p className='mb-0'>Email</p>
                <p className="font-weight-bold">{peserta.email}</p>
                <p className='mb-0'>Status Pelatihan</p>
                <p className="font-weight-bold text-success">{peserta.is_active ? 'Aktif' : 'Nonaktif'}</p>
                <p className='mb-0'>Asal Kota</p>
                <p className="font-weight-bold">{peserta.city}</p>
              </div>
            }
            <div className="card-body d-flex align-items-center justify-content-center text-muted btn">
              <MessageSquare />
              <h6 className='ml-2 font-weight-bold mb-0'>Chat</h6>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modalSelesai} toggle={() => setmodalSelesai(!modalSelesai)}>
        <ModalBody>
          <div className='text-center mt-3'>
            <h6>Apakah Anda Yakin Ingin Simpan Koreksi Tugas Final?</h6>
            <button
                className="btn btn-pill btn-primary btn-sm my-3 mr-3"
                type="button"
                onClick={submitFile}
            > Ya </button>
            <button
                className="btn btn-pill btn-outline-primary btn-sm my-3"
                type="button"
                onClick={() => setmodalSelesai(!modalSelesai)}
            > Tidak
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default TugasFinal;
