import React, { useState, useEffect } from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { CompanyIcon } from '../../../component/common/constant/menu';
import {
  useLocation,
  Link, useHistory
} from "react-router-dom";
import logo_SDM from '../../../assets/images/logo-SDM.png';
import API from "../../../api";

const DetailTrainer = () => {
  const history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const per_page = 2;
  let page = query.get('page') || 1;

  const [trainer, setTrainer] = useState(null);
  const [order_by, setOrderBy] = useState('desc');
  const [courses, setCourse] = useState({
    data:[],
    meta:{
      from:1,
      to:0,
      total: 0,
      current_page: page,
      per_page,
      url: `trainer-detail?id=${id}`,
    }
  });

  useEffect(() => {
    API.ketua.trainer.trainer_detail(id)
      .then(json => {
        setTrainer(json.data)
      }).catch(err => {
        console.log(err);
      })
  }, []);

  useEffect(() => {
    API.ketua.trainer.course_trainer(id, {page, per_page, order_by})
      .then(json => {
        let course = {...courses};
        course.data = [];

        course.data = json.data.data

        course.meta.from = (page - 1) * per_page + 1;
        course.meta.to = per_page > json.data.total ? json.data.total : page * per_page > json.data.total ? json.data.total : page * per_page;
        course.meta.total = json.data.total;
        course.meta.current_page = page;
        course.meta.total_page = Math.ceil(course.meta.total / course.meta.per_page);

        setCourse(course)
      }).catch(err => {
        console.log(err);
      })
  }, [page, order_by])

  const handleActiveFilter = e => {
    setOrderBy(e.target.value)
  };

  return (
    trainer &&
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Trainer'}>
          <li className="breadcrumb-item">
            <Link to="/ketua/trainer" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Perusahaan</li>
          <li className="breadcrumb-item">Trainer</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h4 className='text-theme text-work text-uppercase mb-0' style={{ fontWeight: '600' }}>Trainer</h4>
              <p className='text-muted mb-0' style={{fontSize:'18px', fontWeight:'600'}}>Data Trainer</p>
            </div>
            <div className='row'>
              <div className='col-md-9'>
                <div className="card-body">
                  <div className='d-flex mb-4'>
                    <div>
                      <img class="img-80 rounded-circle" style={{height: 80, objectFit: 'cover'}} src={trainer.photo} alt="#"/>
                    </div>
                    <div className='ml-4'>
                      <h3 className='font-weight-bold'>{trainer.name}</h3>
                      <p className='text-muted mb-0'> <i className='fa fa-building-o'></i> Berdiri sejak {trainer.company.since}</p>
                      <p className='text-muted mb-0'> <i className='fa fa-map-marker'></i> {trainer.company.address}</p>
                      <p className='text-muted mb-0'> <i className='fa fa-phone'></i> {trainer.company.phone_number} &nbsp;&nbsp;<i className='fa fa-envelope-o'></i> {trainer.company.email}</p>
                      <div className='d-flex align-items-center mt-3'>
                        <p className='mb-0'>Bidang Pelatihan </p>
                        {
                          trainer.categories.map(val => {
                            return <>
                            <button className="btn btn-pill btn-outline-warning btn-xs ml-3" type="button">{val.name}</button>
                            </>
                          })
                        }
                      </div>
                    </div>
                  </div>

                  <h5>Kontak Perusahaan Trainer</h5>
                  <div className='row'>
                    <div className='col-md-6'>
                      <p>PIC Admin</p>
                      <div className='d-flex mb-2'>
                        <div>
                          <img class="img-40 rounded-circle" style={{height: 40, objectFit: 'cover'}} src={trainer.company.pic_adminc.photo} alt="#"/>
                        </div>
                        <label className='mb-0 ml-2'>
                          <h6 className='font-weight-bold mb-0'>{trainer.company.pic_adminc.name}</h6>
                          <p className='mb-0'><i className='fa fa-envelope-o'></i> {trainer.company.pic_adminc.email}</p>
                          <p><i className='fa fa-phone'></i>{trainer.company.pic_adminc.phone_number} (WA / Call)</p>
                        </label>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <p>PIC Ketua</p>
                      <div className='d-flex mb-2'>
                        <div>
                          <img class="img-40 rounded-circle" style={{height: 40, objectFit: 'cover'}} src={trainer.company.pic_chairman.photo} alt="#"/>
                        </div>
                        <label className='mb-0 ml-2'>
                          <h6 className='font-weight-bold mb-0'>{trainer.company.pic_chairman.name}</h6>
                          <p className='mb-0'><i className='fa fa-envelope-o'></i> {trainer.company.pic_chairman.email}</p>
                          <p><i className='fa fa-phone'></i>{trainer.company.pic_chairman.phone_number} (WA / Call)</p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <h5>Profil Singkat</h5>
                  <p className='text-muted text-justify'>{trainer.company.short_profile}</p>
                </div>
              </div>
              <div className='col-md-3 pt-5'>
                <div className='w-50 card border p-3 mx-auto'>
                  <img className="img-fluid w-100" src={trainer.company.logo} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header d-flex">
              <div className='mr-auto'>
              <h4 className='text-theme text-work text-uppercase mb-0' style={{ fontWeight: '600' }}>Pelatihan Trainer</h4>
              <p className='text-muted mb-0' style={{fontSize:'18px', fontWeight:'600'}}>Pelatihan Aktif</p>
              </div>
              <div>
                <select className="form-control btn-pill digits" id="exampleFormControlSelect7" onChange={handleActiveFilter}>
                  <option value='desc' selected={true}>Terbaru</option>
                  <option value='asc'>Terlama</option>
                </select>
              </div>
            </div>
            <div className="card-body">
              {
                courses.data.map(val => {
                  return <div className='card bg-light text-dark p-3'>
                    <div className='row'>
                      <div className='col-md-2'>
                        <div className='border rounded' style={{ backgroundImage: `url(${val.photo.replace(/ /g,"%20")})`, backgroundPosition: 'center', height: '10rem', width: '10rem' }}>
                          <div className={`badge text-wrap text-capitalize ${val.type === 'online' ? 'badge-primary' : val.type === 'Face to Face' ? 'badge-danger' : 'badge-pink'}`} >
                            {val.type}
                          </div>
                        </div>
                      </div>
                      <div className='col-md-10'>
                        <h5 className='font-weight-bold'>{val.nama}</h5>
                        <div className='d-flex'>
                          <p className='text-muted mr-4'>
                            Kategori  <span className='text-dark font-weight-bold'>{val.category} </span>
                          </p>
                          <p className='text-muted'> <span className='text-theme'><i className='fa fa-clock-o'></i></span> Durasi Pelatihan <span className='text-dark font-weight-bold'>{val.day_duration} hari</span></p>
                        </div>
                        <div className='d-flex'>
                          <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-play-circle-o'></i></span>&nbsp; {val.count_videos} Video</p>
                          <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa fa-file-pdf-o'></i></span>&nbsp; {val.count_ebooks} PDF</p>
                          <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa fa-file-powerpoint-o'></i></span> &nbsp;{val.count_ppt} PPT</p>
                          <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-pencil-square-o'></i></span>&nbsp; {val.count_quiz} Kuis</p>
                          <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-clipboard'></i></span>&nbsp; {val.count_final} Ujian Final</p>
                        </div>
                        <hr />
                        <p>{val.description}</p>
                        <div className='d-flex justify-content-end'>
                          <button className=" btn btn-pill btn-outline-primary btn-sm mt-3" type="button" onClick={() => history.push(`/ketua/detail-pelatihan?id=${val.id}`)}>Detail Pelatihan</button>
                        </div>
                      </div>
                    </div>
                  </div>
                })
              }

              <div className="d-md-flex mt-3">
                <p className="my-auto" style={{color: '#C4C4C4'}}>Menampilkan <b>{courses.meta.from} - {courses.meta.to}</b> dari <b>{courses.meta.total} pelatihan </b></p>
                <div className="btn-group ml-md-auto" >
                  <div className="">
                    <div>
                      {courses.meta.current_page > 1 &&
                      <>
                        <Link to={`${courses.meta.url}&page=${parseInt(1)}`} style={{color: 'black'}}>
                          <a className="btn rounded-circle btn-xs mx-1">
                            <i className="fa fa-angle-double-left"></i>
                          </a>
                        </Link>
                        <Link to={`${courses.meta.url}&page=${parseInt(courses.meta.current_page) - 1}`} style={{color: 'black'}}>
                          <a  className="btn rounded-circle btn-xs mx-1">
                            <i className="fa fa-angle-left"></i>
                          </a>
                        </Link>
                      </>
                      }
                      {
                        parseInt(courses.meta.current_page) + 3 >= courses.meta.total_page ?
                            courses.meta.total_page - 3 <= 0 ?
                                <>
                                  {
                                    Array(courses.meta.total_page).fill(1).map((x, y) => x + y)
                                        .map(val => {
                                          return val === parseInt(courses.meta.current_page) ?
                                              <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{val}</a> :
                                              <Link to={`${courses.meta.url}&page=${val}`} style={{color: 'black'}}>
                                                <a className="btn rounded-circle btn-xs mx-1">{val}</a>
                                              </Link>
                                        })
                                  }
                                </> :
                                <>
                                  {
                                    Array(4).fill(courses.meta.total_page - 3).map((x, y) => x + y)
                                        .map(val => {
                                          return val === parseInt(courses.meta.current_page) ?
                                              <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{val}</a> :
                                              <Link to={`${courses.meta.url}&page=${val}`} style={{color: 'black'}}>
                                                <a className="btn rounded-circle btn-xs mx-1">{val}</a>
                                              </Link>
                                        })
                                  }
                                </>
                            :
                            <>
                              <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{courses.meta.current_page}</a>
                              <Link to={`${courses.meta.url}&page=${parseInt(courses.meta.current_page) + 1}`} style={{color: 'black'}}>
                                <a className="btn rounded-circle btn-xs mx-1">{parseInt(courses.meta.current_page) + 1}</a>
                              </Link>
                              <Link to={`${courses.meta.url}&page=${parseInt(courses.meta.current_page) + 2}`} style={{color: 'black'}}>
                                <a className="btn rounded-circle btn-xs mx-1">{parseInt(courses.meta.current_page) + 2}</a>
                              </Link>
                              <Link to={`${courses.meta.url}&page=${parseInt(courses.meta.current_page) + 3}`} style={{color: 'black'}}>
                                <a className="btn rounded-circle btn-xs mx-1">{parseInt(courses.meta.current_page) + 3}</a>
                              </Link>
                            </>
                      }
                      {
                        parseInt(courses.meta.current_page) !== courses.meta.total_page &&
                        <>
                          <Link to={`${courses.meta.url}&page=${parseInt(courses.meta.current_page) + 1}`} style={{color: 'black'}}>
                            <a  className="btn rounded-circle btn-xs mx-1">
                              <i className="fa fa-angle-right"></i>
                            </a>
                          </Link>
                          <Link to={`${courses.meta.url}&page=${courses.meta.total_page}`} style={{color: 'black'}}>
                            <a className="btn rounded-circle btn-xs mx-1">
                              <i className="fa fa-angle-double-right"></i>
                            </a>
                          </Link>
                        </>
                      }
                    </div>
                    {/* <Pagination
                      ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                      firstItem={{ content: <i className='fa fa-angle-double-left' />, icon: true }}
                      lastItem={{ content: <i className='fa fa-angle-double-right' />, icon: true }}
                      prevItem={{ content: <i className='fa fa-angle-left' />, icon: true }}
                      nextItem={{ content: <i className='fa fa-angle-right' />, icon: true }}
                      defaultActivePage={courses.meta.current_page}
                      totalPages={courses.meta.last_page}
                      onPageChange={props.handlePaggination} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


    </>
  )
}

export default DetailTrainer;
