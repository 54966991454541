import React, { useState, useEffect } from 'react';
import { Redirect, useLocation, useParams, useHistory } from "react-router-dom";
import Carousel from './Carousel';
import LOGOSDM from '../../assets/images/logo-SDM.png';
import bg_login from '../../assets/images/bg_login.png';
import FormLogin from './FormLogin';
import ResetPasswordByEmail from './ResetPasswordByEmail';
import ResetPasswordByPhone from './ResetPasswordByPhone';
import VerifEmailBerhasil from './VerifEmailBerhasil';
import Register from './Register';
import NewPassword from "./NewPassword";
import API from "../../api";


const Login = (props) => {
  const [viewLogin, setviewLogin] = useState('login');
  const [imgCompany, setImgCompany] = useState(null);
  const [aboutUs, setAboutUs] = useState('');
  const [ErrMessage, setErrMessage] = useState(false);

  const { id } = useParams();
  let query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    API.user.get_company(id)
      .then(json => {
        setImgCompany(json.data.logo);
        setAboutUs(json.data.about);
      });

    if (query.get('code')) {
      API.user.forgot_password_check({ code_verification_password: query.get('code') })
        .then(json => {
          setviewLogin('newPassword');
        })
        .catch(e => {
          setErrMessage('Link tidak valid');
        })
    }
    else if (query.get('email_code')) {
      API.user.verify_email({ code_verification_email: query.get('email_code') })
        .then(json => {
          setviewLogin('VerifEmailBerhasil');
        })
        .catch(e => {
          console.log(e);
          setErrMessage('Link tidak valid');
        })
    }
  }, []);

  if (localStorage.getItem(`${props.as}Token`)) {
    return <Redirect to={`/${props.as === 'peserta' ? '' : props.as}`} />
  }

  return (
    <div className='container-fluid  h-100'>
      <div className="row h-100">
        <div className="col-md-5 d-flex align-items-center bg-color_theme justify-content-center px-5 py-3" style={{ background: `url(${bg_login})`, backgroundSize: '100%' }}>
          <div className='card px-5' style={{ minHeight: '80%', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
            <div className='text-center my-auto'>
              {imgCompany && <img src={imgCompany} className='w-25 mb-3' alt='Omind' />}
              <h2>Persiapkan Diri Anda!</h2>
              <span dangerouslySetInnerHTML={{__html: aboutUs}} />
            </div>
          </div>
        </div>
        <div className="col-md-7 shadow px-5 py-3 d-flex align-items-center bg-white">
          <div className='w-75 mx-auto'>
            {ErrMessage &&
              <div className='form-error_message my-3 d-flex'>
                <label className='mb-0 mr-auto'>
                  {ErrMessage}
                </label>
                <label className='m-0 p-0 btn' onClick={() => setErrMessage(false)}>
                  X
                  </label>
              </div>
            }
            {
              viewLogin === 'resetPasswordByEmail' ? <ResetPasswordByEmail as={props.as} setviewLogin={setviewLogin} /> :
                viewLogin === 'resetPasswordByPhone' ? <ResetPasswordByPhone as={props.as} setviewLogin={setviewLogin} /> :
                  viewLogin === 'VerifEmailBerhasil' ? <VerifEmailBerhasil as={props.as} setviewLogin={setviewLogin} /> :
                    viewLogin === 'register' ? <Register as={props.as} setviewLogin={setviewLogin} /> :
                      viewLogin === 'newPassword' ? <NewPassword as={props.as} setviewLogin={setviewLogin} /> :
                        <FormLogin as={props.as} setviewLogin={setviewLogin} />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
