import React, { useState, useEffect, useRef } from "react";
import { BreadcrumbComp } from '../../../component';
import { Link } from 'react-router-dom'
import API from "../../../api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import swal from 'sweetalert2';
import {checkEmpty} from "../../../helper";

const EditAboutUs = () => {
    let user = JSON.parse(localStorage.getItem('data'));
    const [aboutUs, setAboutUs] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [imgPreview, setImgPreview] = useState(null);
    const [ceoPhotoPreview, setCeoPhotoPreview] = useState(null);

    let imageRef = useRef(null);
    let ceoPhotoRef = useRef(null);

    useEffect(() => {
        API.about_us.get(user.company_id)
            .then(json => {
              setAboutUs(json.about_us);
            });
    }, []);

    const handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        setAboutUs({...aboutUs, image: file});

        reader.onloadend = () => {
            setImgPreview(reader.result);
        }

        reader.readAsDataURL(file)
    }

    const handleCeoPhotoChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        setAboutUs({...aboutUs, ceo_photo: file});

        reader.onloadend = () => {
            setCeoPhotoPreview(reader.result);
        }

        reader.readAsDataURL(file)
    }

    const handleSubmit = () => {
        setIsSubmitting(true);
        swal.fire({
            onBeforeOpen () {
                swal.showLoading()
            },
            onAfterClose () {
                swal.hideLoading()
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false
        });

        if(checkEmpty(aboutUs, [
            {
                name: 'about_us',
                alias: 'About Us',
            },
            {
                name: 'ceo_name',
                alias: 'Nama CEO',
            },
            {
                name: 'about_ceo',
                alias: 'Tentang CEO',
            },
        ])){
            let aboutUsTemp = {...aboutUs};

            if(imgPreview === null){
                delete aboutUsTemp.image;
            }

            if(ceoPhotoPreview === null){
                delete aboutUsTemp.ceo_photo;
            }

            API.about_us.update_super(aboutUsTemp)
                .then(json => {
                    swal.fire(json.message, '', 'success');
                    setIsSubmitting(false);
                }).catch(e => {
                swal.fire(e.message, '', 'error');
                setIsSubmitting(false);
            });
        }
        else {
            setIsSubmitting(false);
        }
    };

    return (
      <>
        <div className="page-header">
          <BreadcrumbComp
              title={'About us'}
          />
        </div>
        <Link to='/superadmin/about-us'>
          <p className='p-0 text-theme'>
            <i className="fa fa-arrow-left"></i>&nbsp;Kembali
          </p>
        </Link>
        {aboutUs && <>
          <div className="w-100 card p-5 mt-4">
            <img src={imgPreview ? imgPreview : aboutUs.image} style={{borderRadius: 25, cursor: 'pointer'}} alt="Omind Tech" className="w-50 mx-auto" onClick={() => {
                imageRef.current.click();
            }}/>
              <input style={{display: 'none'}} type="file" ref={imageRef} name='image' onChange={handleImageChange}/>
            <div className="mt-5">
              <h3 className="text-work" style={{fontSize: "24px"}}>About Us</h3>
                <CKEditor
                    editor={ ClassicEditor }
                    config={{
                        toolbar: [ 'bold', 'italic', 'underline' ]
                    }}
                    data={aboutUs.about_us}
                    onChange={ ( event, editor ) => {
                            let aboutUsTemp = {...aboutUs};
                            aboutUsTemp.about_us = editor.getData();
                            setAboutUs(aboutUsTemp);
                        }
                    }
                />
            </div>
          </div>
          <div className="w-100 card p-5 mt-4">
            <h3 className="text-work text-muted" style={{fontSize: "24px", fontWeight: "600"}}>Pesan Manajemen</h3>
            <div className='d-flex align-items-center mb-2 py-2'>
              <div>
                <img className="img-50 rounded-circle" style={{height: 50, objectFit: 'cover', cursor: 'pointer'}} src={ceoPhotoPreview ? ceoPhotoPreview : aboutUs.ceo_photo}
                     alt="#" onClick={() => ceoPhotoRef.current.click()}/>
                  <input style={{display: 'none'}} type="file" ref={ceoPhotoRef} name='ceo_photo' onChange={handleCeoPhotoChange} />
              </div>
              <label className='mb-0 ml-3'>
                  <input type="text" className='form-control' name='ceo_name' value={aboutUs.ceo_name} onChange={e => {
                      let aboutUsTemp = {...aboutUs};
                      aboutUsTemp.ceo_name = e.target.value;
                      setAboutUs(aboutUsTemp);
                  }}/>
                <p className="text-muted font-weight-bold" style={{fontSize: "14px"}}>CEO SDM</p>
              </label>
            </div>
            <p className="text-justify" style={{fontSize: "14px"}}>
                <CKEditor
                    editor={ ClassicEditor }
                    config={{
                        toolbar: [ 'bold', 'italic', 'underline' ]
                    }}
                    data={aboutUs.about_ceo}
                    onChange={ ( event, editor ) => {
                            let aboutUsTemp = {...aboutUs};
                            aboutUsTemp.about_ceo = editor.getData();
                            setAboutUs(aboutUsTemp);
                        }
                    }
                />
            </p>
          </div>
            <button className="mr-3 btn btn-pill btn-primary btn-sm mb-4 w-100 text-work" type="button" style={{fontSize:'16px', fontWeight:'600'}} disabled={isSubmitting} onClick={handleSubmit}>Simpan</button>
        </>}

      </>
    )
}

export default EditAboutUs;
