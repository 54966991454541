import React from 'react';
import {useHistory} from 'react-router-dom';
import API from "../../../api";

const PelatihanTidakAktif = (props) => {
    const history = useHistory();

    return(
        <div className='p-3'>
            <div className='card border'>
                <div className='h-100 rounded-top' style={{ backgroundImage: `url(${props.data?.item_bg})`, backgroundPosition: 'center', minHeight:'12rem' }}>
                  <div className='d-flex flex-column p-3' style={{ background: 'rgba(90, 90, 90, 0.7)', minHeight:'12rem'  }}>
                    <div className="badge badge-secondary text-wrap mt-3  w-25">
                        {props.data?.online}
                    </div>
                    <div className={`text-center mt-auto mb-5 ${props.data?.status === 'Sukses' ? 'text-success' : props.data?.status === 'Gagal' ? 'text-danger' : 'text-secondary'} `}>
                        <h3>
                        {props.data?.status === 'Sukses' ?
                            <i className="fa fa-check-circle-o"></i>
                        : props.data?.status === 'Gagal' ?
                            <i className="fa fa-times-circle-o"></i>
                        : ''
                        }
                        </h3>
                        <h4 className='font-weight-bold'>{props.data?.status === 'Sukses' ? 'Selesai' : props.data.status}</h4>
                    </div>
                  </div>
                </div>
                <div className='p-3'>
                <h5 className='font-weight-bold text-work text-truncate-3 text-capitalize'>{props.data?.item_judul}</h5>
                <div className='d-flex align-items-center mb-2 py-2'>
                    {props.data.user_image &&
                        <div>
                            <img className="img-40 rounded-circle" style={{height: '40px', objectFit: 'cover'}} src={props.data.user_image} alt="#" />
                        </div>
                    }
                    <label className='mb-0 ml-2'>
                        <h6 className='font-weight-bold mb-0'>{props.data?.user_name}</h6>
                        <p>{props.data?.user_role}</p>
                    </label>
                </div>
                <div className='text-center'>
                    <button className="btn btn-pill btn-outline-secondary btn-sm w-100 mb-3 disabled" type="button">Lanjutkan</button>
                    <button className="btn btn-pill btn-outline-primary btn-sm w-100" type="button" onClick={async () => {
                        await API.peserta.course.add_to_cart(props.data.course_id).catch(e => {history.push('/pelatihan-lainnya/cart');});
                        history.push('/pelatihan-lainnya/cart');
                    }}>Beli Pelatihan</button>
                </div>
                </div>
            </div>
        </div>
    )
}

export default PelatihanTidakAktif;
