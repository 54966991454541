import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Pagination, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory, {
  PaginationProvider
} from 'react-bootstrap-table2-paginator';


function TableComp(props) {
  const indication = () => {
    return <p className="text-center">Table is Empty</p>;
  };
  const options = {
    custom: true,
    totalSize: props.data?.meta?.total || 0,
    sizePerPage: props?.data?.meta?.per_page
  };

  let total_page = Math.ceil(props?.data?.meta?.total / props?.data?.meta?.per_page);

  return (
    <PaginationProvider
      pagination={paginationFactory(options)}
    >
      {
        ({
          paginationProps,
          paginationTableProps
        }) =>
          <div>
            {props.data?.meta &&
            <div className="d-md-flex mb-3">
              <p className="my-auto">Menampilkan {props.data.meta.from} - {props.data.meta.to} dari {paginationProps.totalSize} {props.title_paggination}</p>
              <div className="btn-group ml-md-auto" >
                <div className="">
                  <div>
                    {props.data.meta.current_page > 1 &&
                    <>
                      <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${parseInt(1)}`} style={{color: 'black'}}>
                        <a className="btn rounded-circle btn-xs mx-1">
                          <i className="fa fa-angle-double-left"></i>
                        </a>
                      </Link>
                      <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${parseInt(props.data.meta.current_page) - 1}`} style={{color: 'black'}}>
                        <a  className="btn rounded-circle btn-xs mx-1">
                          <i className="fa fa-angle-left"></i>
                        </a>
                      </Link>
                    </>
                    }
                    {
                      parseInt(props.data.meta.current_page) + 3 >= total_page ?
                          total_page - 3 <= 0 ?
                              <>
                                {
                                  Array(total_page).fill(1).map((x, y) => x + y)
                                      .map(val => {
                                        return val === parseInt(props.data.meta.current_page) ?
                                            <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{val}</a> :
                                            <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${val}`} style={{color: 'black'}}>
                                              <a className="btn rounded-circle btn-xs mx-1">{val}</a>
                                            </Link>
                                      })
                                }
                              </> :
                              <>
                                {
                                  Array(4).fill(total_page - 3).map((x, y) => x + y)
                                      .map(val => {
                                        return val === parseInt(props.data.meta.current_page) ?
                                            <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{val}</a> :
                                            <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${val}`} style={{color: 'black'}}>
                                              <a className="btn rounded-circle btn-xs mx-1">{val}</a>
                                            </Link>
                                      })
                                }
                              </>
                          :
                          <>
                            <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{props.data.meta.current_page}</a>
                            <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${parseInt(props.data.meta.current_page) + 1}`} style={{color: 'black'}}>
                              <a className="btn rounded-circle btn-xs mx-1">{parseInt(props.data.meta.current_page) + 1}</a>
                            </Link>
                            <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${parseInt(props.data.meta.current_page) + 2}`} style={{color: 'black'}}>
                              <a className="btn rounded-circle btn-xs mx-1">{parseInt(props.data.meta.current_page) + 2}</a>
                            </Link>
                            <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${parseInt(props.data.meta.current_page) + 3}`} style={{color: 'black'}}>
                              <a className="btn rounded-circle btn-xs mx-1">{parseInt(props.data.meta.current_page) + 3}</a>
                            </Link>
                          </>
                    }
                    {
                      parseInt(props.data.meta.current_page) !== total_page &&
                      <>
                        <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${parseInt(props.data.meta.current_page) + 1}`} style={{color: 'black'}}>
                          <a  className="btn rounded-circle btn-xs mx-1">
                            <i className="fa fa-angle-right"></i>
                          </a>
                        </Link>
                        <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${parseInt(Math.ceil(props.data.meta.total / props.data.meta.per_page))}`} style={{color: 'black'}}>
                          <a className="btn rounded-circle btn-xs mx-1">
                            <i className="fa fa-angle-double-right"></i>
                          </a>
                        </Link>
                      </>
                    }
                  </div>
                  {/* <Pagination
                      ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                      firstItem={{ content: <i className='fa fa-angle-double-left' />, icon: true }}
                      lastItem={{ content: <i className='fa fa-angle-double-right' />, icon: true }}
                      prevItem={{ content: <i className='fa fa-angle-left' />, icon: true }}
                      nextItem={{ content: <i className='fa fa-angle-right' />, icon: true }}
                      defaultActivePage={props.data.meta.current_page}
                      totalPages={props.data.meta.last_page}
                      onPageChange={props.handlePaggination} /> */}
                </div>
              </div>
            </div>
            }
            <div className={props.tableContainer}>
              <div style={props.customStyle}>
                <BootstrapTable
                  keyField='id'
                  rowClasses={ props.rowClasses }
                  data={props.data?.data || []}
                  columns={props.columns || [{}]}
                  noDataIndication={indication}
                  bordered={false}
                  striped
                  filter={filterFactory()}
                  {...paginationTableProps}
                />
              </div>
            </div>
            {props.data?.meta &&
              <div className="d-md-flex mt-3">
                <p className="my-auto">Menampilkan {props.data.meta.from} - {props.data.meta.to} dari {paginationProps.totalSize} {props.title_paggination}</p>
                <div className="btn-group ml-md-auto" >
                  <div className="">
                    <div>
                      {props.data.meta.current_page > 1 &&
                      <>
                      <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${parseInt(1)}`} style={{color: 'black'}}>
                        <a className="btn rounded-circle btn-xs mx-1">
                          <i className="fa fa-angle-double-left"></i>
                        </a>
                      </Link>
                      <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${parseInt(props.data.meta.current_page) - 1}`} style={{color: 'black'}}>
                        <a  className="btn rounded-circle btn-xs mx-1">
                          <i className="fa fa-angle-left"></i>
                        </a>
                      </Link>
                      </>
                      }
                      {
                        parseInt(props.data.meta.current_page) + 3 >= total_page ?
                            total_page - 3 <= 0 ?
                            <>
                              {
                                Array(total_page).fill(1).map((x, y) => x + y)
                                    .map(val => {
                                      return val === parseInt(props.data.meta.current_page) ?
                                          <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{val}</a> :
                                          <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${val}`} style={{color: 'black'}}>
                                            <a className="btn rounded-circle btn-xs mx-1">{val}</a>
                                          </Link>
                                    })
                              }
                            </> :
                            <>
                              {
                                Array(4).fill(total_page - 3).map((x, y) => x + y)
                                    .map(val => {
                                      return val === parseInt(props.data.meta.current_page) ?
                                          <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{val}</a> :
                                          <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${val}`} style={{color: 'black'}}>
                                            <a className="btn rounded-circle btn-xs mx-1">{val}</a>
                                          </Link>
                                    })
                              }
                            </>
                            :
                            <>
                              <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{props.data.meta.current_page}</a>
                              <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${parseInt(props.data.meta.current_page) + 1}`} style={{color: 'black'}}>
                                <a className="btn rounded-circle btn-xs mx-1">{parseInt(props.data.meta.current_page) + 1}</a>
                              </Link>
                              <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${parseInt(props.data.meta.current_page) + 2}`} style={{color: 'black'}}>
                                <a className="btn rounded-circle btn-xs mx-1">{parseInt(props.data.meta.current_page) + 2}</a>
                              </Link>
                              <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${parseInt(props.data.meta.current_page) + 3}`} style={{color: 'black'}}>
                                <a className="btn rounded-circle btn-xs mx-1">{parseInt(props.data.meta.current_page) + 3}</a>
                              </Link>
                            </>
                      }
                      {
                        parseInt(props.data.meta.current_page) !== total_page &&
                          <>
                            <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${parseInt(props.data.meta.current_page) + 1}`} style={{color: 'black'}}>
                              <a  className="btn rounded-circle btn-xs mx-1">
                                <i className="fa fa-angle-right"></i>
                              </a>
                            </Link>
                            <Link to={`${props.data.meta.url}?${typeof props.data.meta.custom_queries === 'object' ? Object.keys(props.data.meta.custom_queries).map((key) => key + "=" + props.data.meta.custom_queries[key]).join("&") + '&' : ''}page${!!props.data.meta.custom_page ? props.data.meta.custom_page : ''}=${parseInt(Math.ceil(props.data.meta.total / props.data.meta.per_page))}`} style={{color: 'black'}}>
                              <a className="btn rounded-circle btn-xs mx-1">
                                <i className="fa fa-angle-double-right"></i>
                              </a>
                            </Link>
                          </>
                      }
                    </div>
                    {/* <Pagination
                      ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                      firstItem={{ content: <i className='fa fa-angle-double-left' />, icon: true }}
                      lastItem={{ content: <i className='fa fa-angle-double-right' />, icon: true }}
                      prevItem={{ content: <i className='fa fa-angle-left' />, icon: true }}
                      nextItem={{ content: <i className='fa fa-angle-right' />, icon: true }}
                      defaultActivePage={props.data.meta.current_page}
                      totalPages={props.data.meta.last_page}
                      onPageChange={props.handlePaggination} /> */}
                  </div>
                </div>
              </div>
            }

          </div>
      }
    </PaginationProvider>
  )
}

export default TableComp;
