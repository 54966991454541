import Post from "../Post";
import Get from "../Get";

const listCourse = (data) => Get(`ketua-klien/courses?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const course_detail = id => Get(`ketua-klien/courses/${id}`);
const course_detail_update = id => Get(`ketua-klien/courses/${id}/update`);
const course_detail_edit = (id, data) => Post(`ketua-klien/courses/${id}`, data);
const check_certificate = data => Post(`ketua-klien/courses/check-certificate`, data);
const course_progress = id => Get(`ketua-klien/courses/progress/${id}`);
const course_comment = (id, data) => Post(`ketua-klien/courses/comment/${id}`, data);
const course_theory = id => Get(`ketua-klien/courses/${id}/theories`);

const ListCourse = {
    listCourse,
    course_detail,
    check_certificate,
    course_detail_update,
    course_detail_edit,
    course_progress,
    course_comment,
    course_theory,
};

export default ListCourse;
