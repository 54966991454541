import React, {useState, useEffect} from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useLocation } from "react-router-dom";
import { CompanyIcon } from '../../../component/common/constant/menu';
import API from "../../../api";
import swal from 'sweetalert2';
import trainer1 from '../../../assets/images/trainer1.PNG';
import trainer2 from '../../../assets/images/trainer2.PNG';
import trainer3 from '../../../assets/images/trainer3.PNG';
import trainer4 from '../../../assets/images/trainer4.PNG';
import trainer5 from '../../../assets/images/trainer5.PNG';
import trainer6 from '../../../assets/images/trainer6.PNG';

const TrainerList = () => {
  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get('page') || 1;

  const [search, setSearch] = useState('')

  const [trainers, setTrainer] = useState({
    data:[],
    meta:{
      from:1,
      to:0,
      total: 0,
      current_page: page,
      per_page,
      url: ''
    }
  });

  const columnsTabel = [
    {
      dataField: 'peserta',
      text: 'Nama Trainer',
      headerStyle: () => ({ width: '20%', textAlign: 'left'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cell, row) => {
        return(
          <div className='d-flex align-items-center mb-2'>
            {
              row.avatar &&
              <div>
                  <img className="img-40 rounded-circle" src={row.avatar} style={{height: 40, objectFit: 'cover'}} alt="#" />
              </div>
            }
            <label className='mb-0 ml-2'>
              <p className='font-weight-bold'>{row.peserta}</p>
            </label>
          </div>
        )
      }
    },
    {
      dataField: 'bidang',
      text: 'Bidang pelatihan',
      headerStyle: () => ({ width: '20%', textAlign: 'center'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: 'tanggal',
      text: 'Tanggal Registrasi',
      headerStyle: () => ({ width: '20%', textAlign: 'center'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: 'status',
      text: 'Status',
      headerStyle: () => ({ width: '10%', textAlign: 'center'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: '',
      text: '',
      headerStyle: () => ({ width: '10%', textAlign: 'center',}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cel,row, index) => {
        return  (
          <>
            <Link to={`/admin/detail-trainer?id=${row.id}`}>
              <button className=" btn btn-pill btn-outline-primary btn-sm font-weight-bold" type="button"> Detail </button>
            </Link>
            <button onClick={() => deleteTrainer(row.id, index)} className=" btn btn-pill btn-outline-danger btn-sm font-weight-bold mt-3" type="button"> Hapus </button>
          </>
        )
      },
    }
  ];

  const deleteTrainer = (id, key) => {
    swal.fire({
      title: 'Yakin hapus trainer?',
      text: "Trainer tidak akan bisa dikembalikan lagi",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        API.adminc.trainer.delete_trainer(id);

        let temp_trainers = {...trainers};

        temp_trainers.data.splice(key, 1);

        setTrainer(temp_trainers);

        swal.fire(
            'Sukses!',
            'Trainer berhasil dihapus.',
            'success'
        )
      }
    })
  };

  useEffect(() => {
    getTrainer(search)
  }, [page]);

  const getTrainer = (search) => {
    API.adminc.trainer.get_trainer({page, per_page, search})
      .then(json => {
        let trainer = {...trainers};
          trainer.data = [];

          json.data.data.map(val => {
            trainer.data.push({
              id: val.id,
              peserta: val.name,
              tanggal: val.created_at+' WIB',
              bidang: val.profession,
              status: val.status,
              avatar: val.photo
            })
          });

          trainer.meta.from = (page - 1) * per_page + 1;
          trainer.meta.to = per_page > json.data.total ? json.data.total : page * per_page > json.data.total ? json.data.total : page * per_page;
          trainer.meta.total = json.data.total;
          trainer.meta.current_page = page;
          trainer.meta.total_page = Math.ceil(trainer.meta.total / trainer.meta.per_page);

          setTrainer(trainer);
      }).catch(err => {
        console.log(err);
      })
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Trainer'}>
          <li className="breadcrumb-item">
            <Link to="/admin/trainer" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Perusahaan</li>
          <li className="breadcrumb-item">Trainer</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
      <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 pb-3 d-flex align-items-center">
              <div className='mr-auto'>
                <h5 className='text-work text-theme' style={{fontSize:'24px', fontWeight:'600'}}>LIST TRAINER</h5>
                <div className="row mt-3">
                  <div className="col-xl-12">
                    <form className="search-form contact-search ">
                      <div className="form-group mb-0">
                        <input
                          className="form-control badge-pill"
                          type="text"
                          placeholder="Cari trainer..."
                          onChange={(e) => {
                            getTrainer(e.target.value)
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div>
              <Link to='/admin/tambah-trainer'>
                <button className=" btn btn-pill btn-outline-primary btn-sm text-work" type="button" style={{ fontWeight: '600' }}><i className="fa fa-pencil"></i> &nbsp;Tambah Trainer</button>
              </Link>
              </div>
            </div>
            <div className="card-body">
              <div className='overflow-auto w-100'>
                <TableComp
                    rowClasses='text-center'
                    title_paggination='trainer'
                    columns={columnsTabel}
                    data={trainers}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default TrainerList;
