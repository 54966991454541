import React, { useState } from 'react';
import {useHistory} from 'react-router-dom';
import voucher_redeem from '../../../assets/images/voucher-redeem.png';
import voucher_redeem_valid from '../../../assets/images/voucher-redeem-valid.png';
import { BreadcrumbComp } from '../../../component';
import { Link } from 'react-router-dom'
import { WalletIcon } from '../../../component/common/constant/menu';
import { Form } from 'semantic-ui-react';
import API from "../../../api";
import {numberWithDots} from "../../../helper";

const RedeemVoucher = () => {
  const history = useHistory();
  const [Voucher, setVoucher] = useState('');
  const [nominal, setNominal] = useState(0);
  const [VoucherValid, setVoucherValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    API.peserta.point.redeem({voucher_code: Voucher})
        .then(json => {
          setVoucherValid(true);
          setIsSubmitting(false);
          setNominal(json.data.nominal);
          let data = {...JSON.parse(localStorage.getItem('data'))};
          let point = parseInt(JSON.parse(data.point));
          point += parseInt(json.data.nominal);
          data.point = point;
          localStorage.setItem('data', JSON.stringify(data));
        })
        .catch(e => {
          if(e.errors.voucher_code)
            alert(e.errors.voucher_code);
          else
            alert(e.message);

          setIsSubmitting(false);
        });
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Redeem Voucher'}>
          <li className="breadcrumb-item">
            <Link to="/" className='text-warning'>
              <WalletIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Poin Saya</li>
          <li className="breadcrumb-item">Redeem Voucher</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='card p-5 text-center'>
            {VoucherValid ?
              <>
                <h4 className='font-weight-bold'>Redeem Voucher Berhasil !</h4>
                <img className='img-100 mx-auto mb-2 mt-4' src={voucher_redeem_valid} alt='omind' />
                <h4 className='font-weight-bold mt-4 text-theme'>+ {numberWithDots(nominal)} poin</h4>
                <p className='text-muted'>berhasil ditambahkan ke akun Anda</p>
                <div>
                  <button onClick={() => history.push('/pelatihan-lainnya')} className={`btn btn-pill btn-primary btn-sm font-weight-bold text-work`}  type="submit">Ke Pelatihan Lainnya</button>
                </div>
                <Link to='/poin-saya'>
                  <p className='font-weight-bold mt-4 text-theme'>kembali ke Poin Saya</p>
                </Link>
              </>
            :
            <>
              <img className='img-100 mx-auto mb-2' src={voucher_redeem} alt='omind' />
              <h4 className='font-weight-bold mt-4'>Redeem Voucher</h4>
              <p className='text-muted'>Masukkan kode voucher (8 digit kode) untuk redeem voucher menjadi points</p>
              <Form className='w-50 mx-auto mb-3' onSubmit={handleSubmit}>
                <div className="form-group">
                    <input className="form-control text-center" type="text" name="no_voucher" required placeholder="Masukkan kode voucher" onChange={(e)=>setVoucher(e.target.value)}  />
                </div>
                <button  className={`btn btn-pill btn-primary btn-sm w-100 font-weight-bold text-work`}  type="submit" disabled={isSubmitting}>Redeem Kode Voucher</button>
              </Form>
            </>}
          </div>
        </div>
      </div>
    </>
  )
}

export default RedeemVoucher
