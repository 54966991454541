import Post from "../Post";
import Get from "../Get";

const redeem = (data) => Post(`peserta/point/redeem_voucher`, data);
const get = () => Get(`peserta/point/get_point`);
const history = data => Get(`peserta/point/history?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);

const PointPeserta = {
    redeem,
    get,
    history
};

export default PointPeserta;
