import Get from "../Get";
import Post from "../Post";
import Delete from "../Delete"

const list_participant = (data) =>
  Get(
    `super-admin/participants?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const participant_detail = (id) => Get(`super-admin/participants/${id}`);
const insert_participant = (data) => Post(`super-admin/participants`, data);
const insert_bulk_participant = (data) => Post(`super-admin/participants/import-participant`, data);
const update_participant = (id, data) => Post(`super-admin/participants/${id}`, data);
const delete_participant = (id) => Delete(`super-admin/participants/${id}`);
const search_participant = (data) => Get(`super-admin/participants/search?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const SuperadminParticipant = {
  list_participant,
  participant_detail,
  insert_participant,
  insert_bulk_participant,
  update_participant,
  delete_participant,
  search_participant
};

export default SuperadminParticipant;
