import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Peserta, Trainer, Ketua, AdminC, SuperAdmin } from "./pages";
import { Login, OfflinePage } from "./component";
import { Provider } from "react-redux";
import store from "./store/index";
import { Offline, Online } from "react-detect-offline";
import Error404 from "./pages/peserta/error404";
import NewPassword from "./component/Login/NewPassword";

export default function App() {
  localStorage.setItem('uploadPercent', 0);
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          {/* loginRoute */}
          <Route
            exact
            path="/superadmin/login"
            children={<Login as="superadmin" />}
          />
          <Route exact path="/:id/login" children={<Login as="peserta" />} />
          <Route
            exact
            path="/:id/trainer/login"
            children={<Login as="trainer" />}
          />
          <Route
            exact
            path="/:id/ketua/login"
            children={<Login as="ketua" />}
          />
          <Route
            exact
            path="/:id/admin/login"
            children={<Login as="admin" />}
          />
          <Route path="/admin" children={<AdminC />} />
          <Route path="/trainer" children={<Trainer />} />
          <Route path="/ketua" children={<Ketua />} />
          <Route path="/superadmin" children={<SuperAdmin />} />
          <Route path="/" children={<Peserta />} />
        </Switch>
      </Router>
    </Provider>
  );
}
