import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import { BreadcrumbComp } from '../../../component';
import { Link,useLocation } from "react-router-dom";
import { TransaksiIcon } from '../../../component/common/constant/menu';
import dummy_king from '../../../assets/images/dummy_king.png';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import API from "../../../api";
import {numberWithDots} from "../../../helper";

const RiwayatTransaksi = () => {
  const history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  const jenis = query.get("jenis");

  const [modalDetail, setmodalDetail] = useState(false);
  const [detailSelected, setdetailSelected] = useState({});
  const [otorisasiAdmin, setotorisasiAdmin] = useState(false);
  const [otorisasiSuccess, setotorisasiSuccess] = useState(false);

  const [transactions, setTransactions] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const rebuy = data => {
    data.pelatihan.map(async (v, key) => {
      await API.peserta.course.add_to_cart(v.id).catch(e => {
        if(data.pelatihan.length === key +1)
          history.push('/pelatihan-lainnya/cart');
      })
    });
    history.push('/pelatihan-lainnya/cart');
  }

  useEffect(() => {
    API.peserta.course.history_transaction()
        .then(json => {
          let transaksi = [];
          json.data.map(val => {
            let courses = [];

            val.transaction_detail.map(v => {
              let course = v.course_id;
              let schedule = v.schedule_id;

              courses.push({
                id: course.id,
                judul: `${course.name} ${schedule ? `- ${schedule.name}` : ''}`,
                status: course.type,
                thumbnail: course.thumbnail,
                price: course.price,
                tanggal: v.periode_course,
                user_name: course.trainer_id.name,
                user_company: course.company_id.name,
                user_image: course.trainer_id.profile_picture,
              });
            });

            transaksi.push({
              id: val.id,
              tanggal: val.transaction_date,
              status: val.status,
              no_transaksi: val.no_transaction,
              metode: val.metode,
              promo: val.promo_id ? (val.promo_id.discount_type === 'Persen' ? `-${val.promo_id.nominal}%` : `- Rp ${val.promo_id.nominal}`) : null,
              harga_promo: val.promo_id ? (val.promo_id.discount_type === 'Persen' ? (val.subtotal * val.promo_id.nominal / 100) : `Rp ${numberWithDots(val.promo_id.nominal)}`) : null,
              tanggal_pembayaran: val.expired_pay,
              invoice: val.status === 'Lunas',
              total_harga: `Rp ${numberWithDots(val.total)}`,
              pelatihan: courses,
              subtotal: val.subtotal,
              point: val.point,
              admin_fee: val.admin_fee,
              total: val.total,
              token: val.token,
              va_account: val.va_account,
            });

            transaksi = transaksi.reverse();

            setTransactions(transaksi);
            setFiltered([...transaksi]);
          })
        });
  },[]);

  useEffect(() => {
    let transaksi = [...transactions];
    if(jenis){
      transaksi = transaksi.filter(val => {
        return val.status === jenis;
      });
    }
    console.log(transaksi);
    setFiltered(transaksi);
  }, [jenis]);

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Riwayat Transaksi'}>
          <li className="breadcrumb-item">
            <Link to="/riwayat-transaksi" className='text-warning'>
              <TransaksiIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Riwayat Transaksi</li>
        </BreadcrumbComp>
        <div className='d-flex mt-4 mx-3'>
          <Link to='/riwayat-transaksi'>
            <button className={`btn btn-pill btn-outline-warning mr-2 btn-sm text-dark bg-white ${!jenis && 'active'}`} type="button">Semua</button>
          </Link>
          <Link to='/riwayat-transaksi?jenis=Menunggu Pembayaran'>
            <button className="btn btn-pill btn-outline-warning mr-2 btn-sm text-dark bg-white" type="button">Menunggu Pembayaran</button>
          </Link>
          <Link to='/riwayat-transaksi?jenis=Lunas'>
            <button className="btn btn-pill btn-outline-warning mr-2 btn-sm text-dark bg-white" type="button">Lunas</button>
          </Link>
          <Link to='/riwayat-transaksi?jenis=Tagihan'>
            <button className="btn btn-pill btn-outline-warning mr-2 btn-sm text-dark bg-white" type="button">Tagihan</button>
          </Link>
          <Link to='/riwayat-transaksi?jenis=Ditolak Admin'>
            <button className="btn btn-pill btn-outline-warning mr-2 btn-sm text-dark bg-white" type="button">Ditolak Admin</button>
          </Link>
          <Link to='/riwayat-transaksi?jenis=Kedaluarsa'>
            <button className="btn btn-pill btn-outline-warning mr-2 btn-sm text-dark bg-white" type="button">Kedaluarsa</button>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          {filtered.map((data,key)=>(
            <div className="card p-3 mb-3" key={key}>
              <div className='card-header'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='d-flex align-items-center'>
                      <p className='mb-0 text-muted'>{data.tanggal}</p>
                      <div className={`mb-0 mx-3 py-2 px-3 text-center card btn-status btn-status_${data.status === 'Lunas' ? 'aktif' : data.status === 'Ditolak Admin' ? 'ditolak': data.status === 'Tagihan' ? 'terpakai': data.status === 'Kedaluarsa' ? 'kedaluarsa': 'expired'}`}>{data.status}</div>
                      {data.invoice &&
                        <button className=" btn btn-pill btn-outline-primary btn-sm text-work" type="button" onClick={() => {
                          history.push({
                            pathname: '/unduh-invoice',
                            state: { detail: data }
                          })
                        }} style={{ fontWeight: '600' }}><i className="fa fa-cloud-download"></i> &nbsp;Unduh Invoice</button>
                      }
                    </div>
                  </div>
                  <div className='col-md-6 d-flex align-items-center justify-content-end'>
                    {(data.tanggal_pembayaran && data.status === 'Menunggu Pembayaran') &&
                      <div className={`mb-0 mx-3 py-2 px-3 text-center card btn-status btn-status_expired`}>Bayar sebelum {data.tanggal_pembayaran}</div>
                    }
                    {data.status === 'Kedaluarsa' ?
                      <button className=" btn btn-pill btn-primary btn-sm text-work" type="button" style={{ fontWeight: '600' }}
                        onClick={() => rebuy(data)}
                      >Buat Ulang Pesanan</button>
                    :
                      <button className=" btn btn-pill btn-outline-primary btn-sm text-work" type="button" style={{ fontWeight: '600' }}
                        onClick={()=>{
                          data.key = key;
                          setdetailSelected(data);
                          setmodalDetail(true)
                        }}>Lihat Detail</button>
                    }
                  </div>
                </div>
              </div>
              <div className='card-body'>
                <div className='row mb-4'>
                    <div className='col-md-4'>
                      <h6 className='text-muted'>No Transaksi</h6>
                      <h6 className={data.status === 'Kedaluarsa' || data.status === 'Ditolak Admin' && 'text-muted'} style={{ fontSize: '16px', fontWeight: '600' }}>{data.no_transaksi}</h6>
                    </div>
                    <div className='col-md-4 border-right border-left'>
                      <h6 className='text-muted'>Metode Pembayaran</h6>
                      <h6 className={data.status === 'Kedaluarsa' || data.status === 'Ditolak Admin' && 'text-muted'} style={{ fontSize: '16px', fontWeight: '600' }}>{data.metode}</h6>
                    </div>
                    <div className='col-md-4'>
                      <h6 className='text-muted'>Total Harga</h6>
                      <h6 className={data.status === 'Kedaluarsa' || data.status === 'Ditolak Admin'  ? 'text-muted' : 'txt-success'} style={{ fontSize: '16px', fontWeight: '600' }}>{data.total_harga}</h6>
                    </div>
                </div>
              </div>
              <div className='card-footer'>
                {data.pelatihan.map((pelatihan,key)=>(
                  <div className='row mb-3' key={key}>
                    <div className='col-md-8 border-right'>
                      <div className='d-flex align-items-center'>
                        <div>
                            <img className="img-60" src={pelatihan.thumbnail} alt="#" />
                        </div>
                        <label className='mb-0 ml-2'>

                          <p className='mb-0' style={{fontSize:'14px', fontWeight:'600'}}>{pelatihan.judul}</p>
                          <div className={`badge text-wrap text-capitalize ${pelatihan.status === 'Online' ? 'badge-primary' : pelatihan.status === 'Face to Face' ? 'badge-danger' : pelatihan.status === 'Virtual Class' ? 'badge-pink' : pelatihan.status === 'Equiries' ? 'badge-success' : 'badge-secondary'}`} >{pelatihan.status}</div>
                          <p className='mb-0 text-muted' style={{fontSize:'14px', fontWeight:'normal'}}>{pelatihan.tanggal}</p>
                        </label>
                      </div>
                    </div>
                    <div className='col-md-4 d-flex align-items-center'>
                      <div className='d-flex align-items-center'>
                        {pelatihan.user_image &&
                          <div>
                            <img className="img-40 rounded-circle" src={pelatihan.user_image.replace(/ /g,"%20")} style={{height: '40px', objectFit: 'cover'}} alt="#" />
                          </div>
                        }

                        <label className='mb-0 ml-2'>
                          <p className='font-weight-bold mb-0'>{pelatihan.user_name}</p>
                          <p className='mb-0'>{pelatihan.user_company}</p>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal isOpen={modalDetail} toggle={()=>setmodalDetail(!modalDetail)} size='lg' style={{maxWidth: '1050px'}}>
        <ModalHeader toggle={()=>setmodalDetail(!modalDetail)}>Detail Transaksi</ModalHeader>
        <ModalBody className='pb-5'>
          <div className='d-flex'>
            <div className='mr-auto'>
              <label className='text-muted text-work'>No Transaksi</label>
              <h6 className='mb-4'>{detailSelected.no_transaksi}</h6>
              <label className='text-muted text-work'>Status</label>
              <div className={` py-2 px-3 text-center card btn-status btn-status_${detailSelected.status === 'Lunas' ? 'aktif' : detailSelected.status === 'Ditolak Admin' ? 'ditolak': detailSelected.status === 'Tagihan' ? 'terpakai': detailSelected.status === 'Kedaluarsa' ? 'kedaluarsa': 'expired'}`}>{detailSelected.status}</div>
              <label className='text-muted text-work'>Tanggal Pembelian</label>
              <h6 className='mb-4'>{detailSelected.tanggal}</h6>
            </div>
            {
              detailSelected.status === 'Menunggu Pembayaran' ?
              <div>
                {detailSelected.tanggal_pembayaran &&
                  <div className={`py-2 px-3 text-center card btn-status btn-status_expired`}>{detailSelected.tanggal_pembayaran}</div>
                }
               <div className='text-right'>
                <button
                  className="btn btn-pill btn-primary btn-sm my-3"
                  type="button"
                  onClick={() => {
                    history.push({
                      pathname: '/lihat-tagihan',
                      state: { detail: detailSelected }
                    })
                  }}
                > Lihat Tagihan
                </button>
                <br />
                <button
                  className="btn btn-pill btn-outline-primary btn-sm"
                  type="button"
                  onClick={()=>setotorisasiAdmin(!otorisasiAdmin)}
                > Minta Otorisasi Pembayaran
                </button>
              </div>

              </div>
              : detailSelected.status === 'Ditolak Admin' ?
              <div className='text-center'>
                <button
                  className="btn btn-pill btn-primary btn-sm"
                  type="button"
                > Buat Ulang Pemesanan
                </button>
              </div>
              :
              <div className='text-center'>
                <button
                  className="btn btn-pill btn-outline-primary btn-sm"
                  type="button"
                  onClick={() => {
                    history.push({
                      pathname: '/unduh-invoice',
                      state: { detail: detailSelected }
                    })
                  }}
                > <i className="fa fa-download"></i> &nbsp;Unduh Invoice
                </button>
              </div>
            }
          </div>
          <hr />
          <div>
            <p className='text-muted' style={{fontWeight:'600', fontSize:'18px'}}>Daftar Pelatihan</p>
            {detailSelected?.pelatihan && detailSelected.pelatihan.map((data, key)=> (
              <div className='row mb-4'>
                  <div className='col-md-6 d-flex align-items-center'>
                    <div className='d-flex align-items-center'>
                      <div>
                          <img className="img-60" src={data.thumbnail.replace(/ /g,"%20")} alt="#" />
                      </div>
                      <label className='mb-0 ml-2'>
                        <p className='mb-0' style={{fontSize:'14px', fontWeight:'600'}}>{data.judul}</p>
                        <div className={`badge text-wrap text-capitalize ${data.status === 'Online' ? 'badge-primary' : data.status === 'Face to Face' ? 'badge-danger' : 'badge-pink'}`} >{data.status}</div>
                      </label>
                    </div>
                  </div>
                  <div className='col-md-4 border-right border-left d-flex align-items-center'>
                    <div className='d-flex align-items-center'>
                      <div>
                          <img className="img-40 rounded-circle" src={data.user_image.replace(/ /g,"%20")} style={{height: '40px', objectFit: 'cover'}} alt="#" />
                      </div>
                      <label className='mb-0 ml-2'>
                        <p className='font-weight-bold mb-0'>{data.user_name}</p>
                        <p className='mb-0'>{data.user_company}</p>
                      </label>
                    </div>
                  </div>
                  <div className='col-md-2 d-flex align-items-center'>
                    <p className='font-weight-bold mb-0 txt-success'>Rp {numberWithDots(data.price)}</p>
                  </div>
              </div>
            ))}
          </div>
          <hr />
          <p className='text-muted' style={{fontWeight:'600', fontSize:'18px'}}>Pembayaran</p>
          <div className='d-flex mx-5'>
            <p className='text-muted text-work mb-0 mr-auto' style={{fontSize:'16px'}}>Subtotal Produk ({detailSelected.pelatihan ? detailSelected.pelatihan.length : 0} pelatihan)</p>
            <p className='mb-0' style={{fontSize:'18px', fontWeight:'600'}}>Rp {numberWithDots(''+detailSelected.subtotal)}</p>
          </div>
          {detailSelected.promo &&
            <div className='d-flex mx-5'>
              <p className='text-muted text-work mb-0 mr-auto' style={{fontSize:'16px'}}>Kupon Promo ({detailSelected.promo})</p>
              <p className='mb-0' style={{fontSize:'18px', fontWeight:'600'}}>- Rp {numberWithDots(''+detailSelected.harga_promo)}</p>
            </div>
          }
          {detailSelected.point !== '0' &&
            <div className='d-flex mx-5'>
              <p className='text-muted text-work mb-0 mr-auto' style={{fontSize:'16px'}}>Points</p>
              <p className='mb-0' style={{fontSize:'18px', fontWeight:'600'}}>- Rp {numberWithDots(''+detailSelected.point)}</p>
            </div>
          }
          <div className='d-flex mx-5'>
            <p className='text-muted text-work mb-0 mr-auto' style={{fontSize:'16px'}}>Biaya Admin</p>
            <p className='mb-0' style={{fontSize:'18px', fontWeight:'600'}}>Rp {numberWithDots(''+detailSelected.admin_fee)}</p>
          </div>
          <div className='d-flex mx-5'>
            <p className='text-muted text-work mb-0 mr-auto' style={{fontSize:'16px'}}>Total Keseluruhan</p>
            <p className='mb-0 txt-success' style={{fontSize:'18px', fontWeight:'600'}}>Rp {numberWithDots(''+detailSelected.total)}</p>
          </div>
          <div className='d-flex mx-5'>
            <p className='text-muted text-work mb-0 mr-auto' style={{fontSize:'16px'}}>Metode Pembayaran</p>
            <p className='mb-0' style={{fontSize:'18px', fontWeight:'600'}}>{detailSelected.metode}</p>
          </div>
          <Modal isOpen={otorisasiAdmin} toggle={()=>setotorisasiAdmin(!otorisasiAdmin)} size='lg'>
            <ModalBody>
              <div className='text-center mt-3'>
                <h6>Apakah Anda yakin ingin meminta otorisasi Admin?</h6>
                <p>Dengan meminta otorisasi Admin, pembayaran akan menjadi tagihan bagi perusahaan Anda</p>
                <button
                  className="btn btn-pill btn-primary btn-sm my-3 mr-3"
                  type="button"
                  onClick={()=>{
                    setotorisasiAdmin(!otorisasiAdmin);
                    API.peserta.course.request_admin_authorization({transaction_id: detailSelected.id})
                        .then(json => {
                          let newTransactions = [...transactions];
                          newTransactions[detailSelected.key].status = 'Tagihan';
                          setFiltered(newTransactions);
                          setmodalDetail(!modalDetail);
                          setotorisasiSuccess(!otorisasiSuccess)
                        });
                  }}
                > Ya
                </button>
                <button
                  className="btn btn-pill btn-outline-primary btn-sm my-3"
                  type="button"
                  onClick={()=>setotorisasiAdmin(!otorisasiAdmin)}
                > Tidak
                </button>
              </div>
            </ModalBody>
          </Modal>
        </ModalBody>
      </Modal>

      <Modal isOpen={otorisasiSuccess} toggle={()=>setotorisasiSuccess(!otorisasiSuccess)}size='lg'>
            <ModalBody>
              <div className='text-center mt-3'>
                <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M35 2.91016C17.336 2.91016 3 17.5565 3 35.6028C3 53.6491 17.336 68.2954 35 68.2954C52.664 68.2954 67 53.6491 67 35.6028C67 17.5565 52.664 2.91016 35 2.91016ZM26.328 49.6279L14.84 37.8913C14.5437 37.5886 14.3087 37.2293 14.1484 36.8338C13.9881 36.4383 13.9055 36.0145 13.9055 35.5864C13.9055 35.1584 13.9881 34.7345 14.1484 34.3391C14.3087 33.9436 14.5437 33.5843 14.84 33.2816C15.1363 32.9789 15.488 32.7388 15.8751 32.575C16.2621 32.4112 16.677 32.3269 17.096 32.3269C17.515 32.3269 17.9299 32.4112 18.3169 32.575C18.704 32.7388 19.0557 32.9789 19.352 33.2816L28.6 42.6971L50.616 20.2046C51.2143 19.5933 52.0258 19.2499 52.872 19.2499C53.7182 19.2499 54.5297 19.5933 55.128 20.2046C55.7263 20.8158 56.0625 21.6449 56.0625 22.5094C56.0625 23.3739 55.7263 24.2029 55.128 24.8142L30.84 49.6279C30.544 49.931 30.1923 50.1715 29.8052 50.3355C29.4181 50.4996 29.0031 50.584 28.584 50.584C28.1649 50.584 27.7499 50.4996 27.3628 50.3355C26.9757 50.1715 26.624 49.931 26.328 49.6279Z" fill="#31A05F"/>
                </svg>
                <h6 className='mt-3'>Sukses memohon otorisasi Admin</h6>
                <p>Anda akan bisa mengakses pelatihan apabila admin mengonfimasi permohonan otorisasi dan tagihan akan dimasukkan ke dalam billing perusahaan</p>
                <button
                  className="btn btn-pill btn-primary btn-sm my-3"
                  type="button"
                  onClick={()=>setotorisasiSuccess(!otorisasiSuccess)}
                > OK
                </button>
              </div>
            </ModalBody>
          </Modal>
    </>
  )
}

export default RiwayatTransaksi;
