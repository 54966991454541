import React from 'react';
import facetoface from '../../../../assets/images/facetoface.png';



const PelatihanFaceToFaceView = props => {
  const {data, schedule} = props;

  return (
      <div className='card p-5'>
        <div className='w-100 border rounded mb-5'>
          <div className='text-center p-4'>
            <img className="img-fluid mb-5" src={facetoface} alt="Omind Tech" style={{ width: '112.73px' }} />
            <div className='text-left'>
              <h6 style={{ fontWeight: '600', fontSize: '20px' }}>{data.name}</h6>

              <h6 className='mt-4' style={{ fontWeight: '600', fontSize: '16px' }}>{schedule.location}</h6>
              <p className='text-muted' style={{ fontSize: '12px' }}>{schedule.detail_location}</p>
              <p style={{ fontSize: '14px' }}>{`${data.start_meeting.substr(0,5)} - ${data.end_meeting.substr(0,5)}`} WIB &nbsp; &nbsp; {data.date}</p>

              <p className='text-muted' style={{ fontSize: '12px' }}>Posuere mi, vulputate odio mus. Arcu sit semper scelerisque morbi amet eget platea risus. Nulla ante diam mauris massa consectetur semper duis imperdiet semper. Arcu sit semper scelerisque morb.</p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default PelatihanFaceToFaceView;
