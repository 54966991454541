
// export const RootPath = 'https://api.etraininghub.id/api';
export const RootPath = 'https://api-learning.omindtech.id/api';
// export const RootPath = 'http://localhost:8000/api';
export const Headers = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
        'Access-Control-Allow-Headers': "X-Requested-With, Content-Type, X-Token-Auth, Authorization",
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
        'Authorization': localStorage.getItem('pesertaToken') ||
            localStorage.getItem('trainerToken') ||
            localStorage.getItem('ketuaToken') ||
            localStorage.getItem('adminToken') ||
            localStorage.getItem('superadminToken')
        // "api-key": "Mk43udsc60R0Sf2EN6ej",
    }
};
