import React from 'react';
import facetoface from '../../../../assets/images/facetoface.png';



const PelatihanFaceToFaceView = (props) => {
  return (
    <div className='card p-5'>
      <div className='text-center p-4'>
        <img className="img-fluid mb-5" src={facetoface} alt="Omind Tech" style={{ width: '112.73px' }} />
        <div className='text-left'>
          <h6 style={{ fontWeight: '600', fontSize: '20px' }}>{props.theory.name}</h6>

          <h6 className='mt-4' style={{ fontWeight: '600', fontSize: '16px' }}>{props.theory.location}</h6>
          <p className='text-muted' style={{ fontSize: '12px' }}>{props.theory.detail_location} </p>
          <p style={{ fontSize: '14px' }}>{props.theory.start} - {props.theory.end} WIB &nbsp; &nbsp; {props.theory.date}</p>

          <p className='text-muted' style={{ fontSize: '12px' }}>Posuere mi, vulputate odio mus. Arcu sit semper scelerisque morbi amet eget platea risus. Nulla ante diam mauris massa consectetur semper duis imperdiet semper. Arcu sit semper scelerisque morb.</p>
        </div>
      </div>
    </div>
  )
}

export default PelatihanFaceToFaceView;