import React, {useState, useEffect} from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useLocation } from 'react-router-dom'
import { WalletIcon } from '../../../component/common/constant/menu';
import bg_mainmenu_points from '../../../assets/images/bg_mainmenu_points.png';
import laptop_promo from '../../../assets/images/laptop_promo.png';
import {numberWithDots} from "../../../helper";
import API from "../../../api";

const PoinSaya = () => {
  const columnsTabel = [
    {
      dataField: 'aktivitas',
      text: 'AKTIVITAS',
      headerStyle: () => ({ width: '30%', textAlign: 'left' }),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: 'tanggal',
      text: 'TANGGAL BELI',
      headerStyle: () => ({ width: '20%', textAlign: 'left' }),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: 'point',
      text: 'POINTS',
      headerStyle: () => ({ width: '15%', textAlign: 'left' }),
      headerFormatter: (column, row)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cel,row) => <p className={`${row.status === '-' ? 'txt-danger': 'txt-success'}`}>{cel}</p>,
    },
  ];

  let query = new URLSearchParams(useLocation().search);
  const per_page = 5;
  let page = query.get('page') || 1;

  const [points, setPoints] = useState(null);
  const [history, setHistory] = useState({data:[],
    meta:{
      from:1,
      to:0,
      total: 0,
      current_page: page,
      per_page,
      url: 'poin-saya'
    }
  });

  let user = JSON.parse(localStorage.getItem('data'));

  useEffect(() => {
    API.peserta.point.get()
        .then(json => {
          setPoints(json.data);
        });
  }, []);

  useEffect(() => {
    API.peserta.point.history({per_page, page})
        .then(json => {
          let hPoint = {...history};
          hPoint.data = [];

          json.data.data.map(val => {
            hPoint.data.push({
              aktivitas: val.activity,
              tanggal: val.date,
              point: val.point+' points',
              status: val.point >= 0 ? '+' : '-'
            })
          });

          hPoint.meta.from = (page - 1) * per_page + 1;
          hPoint.meta.to = per_page > json.data.num_rows_filter ? json.data.num_rows_filter : page * per_page > json.data.num_rows_filter ? json.data.num_rows_filter : page * per_page;
          hPoint.meta.total = json.data.num_rows_filter;
          hPoint.meta.current_page = page;

          setHistory(hPoint);
        });
  }, [page]);

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Poin Saya'}>
          <li className="breadcrumb-item">
            <Link to="/" className='text-warning'>
              <WalletIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Poin Saya</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='card shadow-0 bg-white' style={{background: `url(${bg_mainmenu_points})`, minHeight: '16rem', backgroundSize:'100%', boxShadow:'0 !important'}}>
            <div className="card-body px-5 py-4">
              <div className='row'>
                <div className='col-md-8'>
                  <p className='mb-0 text-muted' style={{fontSize:'14px', fontWeight: '600'}}>Saldo Poin Sekarang</p>
                  <div className='d-flex align-items-end text-theme border-bottom w-50 pb-2 mb-3'>
                    <label className='rounded-circle' style={{border: '1px solid #FCC932', padding:'8px 12px'}}>
                      <WalletIcon color='#FCC932' />
                    </label>
                    <p className='ml-3 mb-0' style={{fontSize:'36px', fontWeight: '600'}}>{points ? numberWithDots(points.point) : 0} <span style={{fontSize:'18px', fontWeight: 'normal'}}>points</span></p>
                  </div>
                  <p className='mb-0 text-theme' style={{fontSize:'18px', fontWeight: 'normal'}}>{points ? numberWithDots(points.total_point_month) : 0} <span style={{fontSize:'18px', fontWeight: 'normal'}}>points digunakan pada bulan ini</span></p>
                  <p className='mb-0 text-theme' style={{fontSize:'18px', fontWeight: 'normal'}}>{points ? numberWithDots(points.total_point_used) : 0} <span style={{fontSize:'18px', fontWeight: 'normal'}}>total points digunakan</span></p>

                </div>
                <div className='col-md-4 d-flex align-items-center'>
                  <div className='text-white'>
                    <h6 style={{fontSize:'22px', fontWeight: 'bold'}}>Punya kode voucher?</h6>
                    <p style={{fontSize:'16px', fontWeight: '500'}}>Klik ‘Redeem Points’ untuk menukarkan kode voucher jadi points</p>
                    <Link to='/redeem-voucher'>
                      <button className="btn bg-white text-theme btn-sm mt-3 d-flex align-items-center font-weight-bold ml-auto" type="button"><WalletIcon color='#FCC932' /> &nbsp; Redeem Voucher</button>
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className='my-auto ml-4'>

              </div> */}
              {/* <div className='my-auto text-right'>

              </div> */}
            </div>
          </div>
          <div className="card">
            <div className="card-header border-0 d-flex">
              <div className='mr-auto'>
                <h4 className='text-muted text-work' style={{ fontWeight: '600' }}>Riwayat Penggunaan Poin</h4>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className='overflow-auto w-100'>
                <TableComp
                    title_paggination='riwayat'
                    columns={columnsTabel}
                    data={history}
                  />
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default PoinSaya
