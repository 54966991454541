import React, { useState, useEffect } from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Home } from 'react-feather';
import { Collapse } from 'reactstrap';
import {
  useLocation,
  Link,
    useHistory
} from "react-router-dom";
import API from "../../../api";

const DetailPelatihan = () => {
  let query = new URLSearchParams(useLocation().search);
  const status = query.get("status");
  const id = query.get("id");
  const history = useHistory();

  const per_page = 7;
  let page = query.get('page') || 1;

  const [course, setCourse] = useState(null);
  const [accordions, setAccordion] = useState([]);
  const [scheduleAccordions, setScheduleAccordions] = useState([]);
    const [participants, setParticipants] = useState({
        data:[],
        meta:{
            from:1,
            to:0,
            total: 0,
            current_page: page,
            per_page,
        }
});

  const columnsTabelTransaksi = [
      {
          dataField: 'peserta_id',
          text: 'ID',
          headerStyle: () => ({ width: '10%', textAlign: 'left'}),
          headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      },
    {
      dataField: 'peserta',
      text: 'Nama Peserta',
      headerStyle: () => ({ width: '20%', textAlign: 'left',}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cell, row) => {
        return(
          <div className='d-flex align-items-center mb-2'>
            <div>
                <img className="rounded-circle" style={{height: 40, width: 40}} src={row.avatar} alt="#"/>
            </div>
            <label className='mb-0 ml-2'>
              <p className='font-weight-bold'>{row.peserta}</p>
            </label>
          </div>
        )
      }
    },
    {
      dataField: 'periode',
      text: 'Periode',
      headerStyle: () => ({ width: '20%', textAlign: 'center'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: 'jadwal',
      text: 'Jadwal yang diambil',
      headerStyle: () => ({ width: '20%', textAlign: 'center'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cel,row) => {
      return  (
        <div className='text-center'>
          <p>{cel}</p>
        </div>
        )
      },
    },
    {
      dataField: '',
      text: '',
      headerStyle: () => ({ width: '10%', textAlign: 'center',}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cel,row) => {
        return  <button className=" btn btn-pill btn-outline-primary btn-sm" type="button" onClick={() => {history.push(`/trainer/progress-peserta?id=${row.id}`)}}> Lihat Progress </button>
      },
    }
  ]

    const columnsTabelTransaksiOnline = [
        {
            dataField: 'peserta_id',
            text: 'ID',
            headerStyle: () => ({ width: '10%', textAlign: 'left'}),
            headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
        },
        {
            dataField: 'peserta',
            text: 'Nama Peserta',
            headerStyle: () => ({ width: '20%', textAlign: 'left',}),
            headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
            formatter: (cell, row) => {
                return(
                    <div className='d-flex align-items-center mb-2'>
                        <div>
                            <img className="rounded-circle" style={{height: 40, width: 40}} src={row.avatar} alt="#"/>
                        </div>
                        <label className='mb-0 ml-2'>
                            <p className='font-weight-bold'>{row.peserta}</p>
                        </label>
                    </div>
                )
            }
        },
        {
            dataField: 'periode',
            text: 'Periode',
            headerStyle: () => ({ width: '20%', textAlign: 'left'}),
            headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
        },
        {
            dataField: '',
            text: '',
            headerStyle: () => ({ width: '10%', textAlign: 'center',}),
            headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
            formatter: (cel,row) => {
                return  <button className=" btn btn-pill btn-outline-primary btn-sm" type="button" onClick={() => {history.push(`/trainer/progress-peserta?id=${row.id}`)}}> Lihat Progress </button>
            },
        }
    ];

  useEffect(() => {
    API.trainer.course.detail(id)
        .then(json => {
          setCourse(json.data);

          let accordion = [];
          let schedule_accordions = [];

          json.data.course_contents.map(val => {
            accordion.push(false);
          });

          json.data.course_schedule.map(val => {
            schedule_accordions.push(false);
          });

          setAccordion(accordion);
          setScheduleAccordions(schedule_accordions);
        });
  },[]);

    useEffect(() => {
        API.trainer.participant.get_all({
            status: 'aktif',
            per_page,
            page,
            course_id: id,
        }).then(json => {
            let peserta = {...participants};
            peserta.data = [];

            json.data.data.map(val => {
                peserta.data.push({
                    id: val.id,
                    peserta: val.user_id.name,
                    peserta_id: val.user_id.id,
                    periode: val.periode,
                    jadwal: val.schedule_id ? val.schedule_id.name : null,
                    avatar: val.user_id.profile_picture ? val.user_id.profile_picture.replace(/ /g, '%20') : require('../../../assets/images/user.png'),
                })

            });

            peserta.meta.from = (page - 1) * per_page + 1;
            peserta.meta.to = per_page > json.data.num_rows_filter ? json.data.num_rows_filter : page * per_page > json.data.num_rows_filter ? json.data.num_rows_filter : page * per_page;
            peserta.meta.total = json.data.num_rows_filter;
            peserta.meta.current_page = page;

            setParticipants(peserta);
        })

    },[page]);

    const handleFilter = (e) => {
        API.trainer.participant.get_all({
            status: 'aktif',
            per_page,
            page,
            course_id: id,
            descending: e.target.value === 'new' ? 'true' : 'false'
        }).then(json => {
            let peserta = {...participants};
            peserta.data = [];

            json.data.data.map(val => {
                peserta.data.push({
                    peserta: val.user_id.name,
                    periode: val.periode,
                    jadwal: val.schedule_id ? val.schedule_id.name : null,
                    avatar: val.user_id.profile_picture.replace(/ /g, '%20'),
                })

            });

            peserta.meta.from = (page - 1) * per_page + 1;
            peserta.meta.to = per_page > json.data.num_rows_filter ? json.data.num_rows_filter : page * per_page > json.data.num_rows_filter ? json.data.num_rows_filter : page * per_page;
            peserta.meta.total = json.data.num_rows_filter;
            peserta.meta.current_page = page;

            setParticipants(peserta);
        })
    };

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Pelatihan Saya'}>
          <li className="breadcrumb-item">
            <Link to="/trainer" className='text-warning'>
              <Home />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Saya</li>
          <li className="breadcrumb-item active">Detail Pelatihan</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className='col-md-12 mb-3'>
            <p className='btn p-0 text-theme' onClick={() => history.goBack()}>
                <i className="fa fa-arrow-left"></i> Kembali
            </p>
        </div>
        {
          course && <div className='col-md-7'>
            <div className='card p-4'>
              <h3 className='font-weight-bold'>{course.name}</h3>
              <div className='d-flex'>
                <label>
                  <div className={`badge text-wrap text-capitalize ${course.type === 'Online' ? 'badge-primary' : course.type === 'Face to Face' ? 'badge-danger' : course.type === 'Enquiries' ? 'badge-success' : 'badge-pink'}`} >
                    {course.type}
                  </div>
                </label>
                <p className='text-muted mr-4 ml-2'> <span className='text-muted'><i className='fa fa-question-circle'></i></span></p>
                <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-clock-o'></i></span>&nbsp; Durasi Pelatihan <span className='text-dark font-weight-bold'>{course.day_duration} hari</span></p>
              </div>

              <h5 className='text-muted mt-4 text-work' style={{fontWeight:'600'}}>Pelatihan Terdiri dari</h5>
              <div className='d-flex'>
                <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-play-circle-o'></i></span>&nbsp; 2 {course.type}</p>
                <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-play-circle-o'></i></span>&nbsp; {course.course_consists_of.filter(val => {return val.type === 'Video'})[0] ? course.course_consists_of.filter(val => {return val.type === 'Video'})[0].total : 0} Video</p>
                <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa fa-file-pdf-o'></i></span>&nbsp; {course.course_consists_of.filter(val => {return val.type === 'E-Book'})[0] ? course.course_consists_of.filter(val => {return val.type === 'E-Book'})[0].total : 0} PDF</p>
                <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa fa-file-powerpoint-o'></i></span> &nbsp; {course.course_consists_of.filter(val => {return val.type === 'Slide Powerpoint'})[0] ? course.course_consists_of.filter(val => {return val.type === 'Slide Powerpoint'})[0].total : 0} PPT</p>
                <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-pencil-square-o'></i></span>&nbsp; {course.course_consists_of.filter(val => {return val.type === 'Quiz'})[0] ? course.course_consists_of.filter(val => {return val.type === 'Quiz'})[0].total : 0} Kuis</p>
                <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-clipboard'></i></span>&nbsp; {course.course_consists_of.filter(val => {return val.type === 'Tugas Final'})[0] ? course.course_consists_of.filter(val => {return val.type === 'Tugas Final'})[0].total : 0} Ujian Final</p>
              </div>
              {course.type !== 'Online' &&
              <>
                <h5 className='font-weight-bold text-theme mt-4'>Jadwal Pertemuan</h5>
                <div className="default-according style-1 faq-accordion job-accordion w-75" id="accordionoc">
                  <div className="row">
                    {
                      course.course_schedule.map((val, key) => (
                          <div className="col-xl-12">
                            <div className="card">
                              <div className="card-header shadow-sm">
                                <h5 className="mb-0">
                                  <button className="btn btn-link pl-0" data-toggle="collapse" onClick={() => {
                                    let schedule_accordions = [...scheduleAccordions];
                                    schedule_accordions[key] = !schedule_accordions[key];
                                    setScheduleAccordions(schedule_accordions);
                                  }}
                                          data-target="#collapseicon" aria-expanded={scheduleAccordions[key]} aria-controls="collapseicon">
                                    <label className='text-work' style={{fontSize:'16px', fontWeight: '600'}}>
                                      {val.location}
                                      <br />
                                      <span className='text-muted' style={{fontSize:'12px',  fontWeight: 'normal'}}>{val.detail_location}</span>
                                    </label>
                                  </button>
                                </h5>
                              </div>
                              <Collapse isOpen={scheduleAccordions[key]}>
                                <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                                  <div className="card-body filter-cards-view animate-chk p-3">
                                    <div className="ribbon-wrapper card border">
                                      <div className="card-body">
                                        <div className="ribbon ribbon-warning text-work font-weight-bold"> Jadwal {val.name}</div>
                                        {
                                          val.meeting.map(v => (
                                              <>
                                                <p className='font-weight-bold text-dark mb-0'>{v.name}</p>
                                                <span className='d-flex '>
                                                  <p className='text-dark'>{v.date}</p>
                                                  <p className='text-dark ml-auto'>{v.start_meeting.substr(0,5)} - {v.end_meeting.substr(0,5)} WIB</p>
                                                </span>
                                              </>
                                          ))
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          </div>
                      ))
                    }
                  </div>
                </div>
              </>
              }
              <p className='text-justify'>{course.description}</p>

              <h5 className='text-muted my-4 text-work' style={{fontWeight:'600'}}>Materi Pelatihan</h5>
              {
                course.course_contents.map((val, key) => (
                    <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="card">
                            <div className="card-header shadow-sm">
                              <h5 className="mb-0">
                                <button className="btn btn-link pl-0 text-work" style={{fontWeight:'normal', fontSize:'16px'}} data-toggle="collapse" onClick={() => {
                                    let accordion = [...accordions];
                                    accordion[key] = !accordion[key];
                                    setAccordion(accordion);
                                }}
                                        data-target="#collapseicon" aria-expanded={accordions[key]} aria-controls="collapseicon">{val.name}</button>
                              </h5>
                            </div>
                            <Collapse isOpen={accordions[key]}>
                              <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                                <div className="card-body filter-cards-view animate-chk p-3">
                                  {
                                    val.theory.map(t => {
                                      return <div className="card-body filter-cards-view animate-chk p-3">
                                        <p className='mb-0 text-dark'>{t.name}</p>
                                        {
                                          t.type === 'Slide Powerpoint' ?
                                              <p className='text-dark'><span className='text-theme'><i
                                                  className='fa fa fa-file-powerpoint-o'></i></span>&nbsp;PPT</p> :
                                              t.type === 'Video' ?
                                                  <p className='text-dark'><span className='text-theme'><i
                                                      className='fa fa-play-circle-o'></i></span>&nbsp;Video</p> :
                                                  t.type === 'E-Book' ?
                                                      <p className='text-dark'><span className='text-theme'><i
                                                          className='fa fa fa-file-pdf-o'></i></span>&nbsp;PDF</p> :
                                                      t.type === 'Quiz' ?
                                                          <p className='text-dark'><span className='text-theme'><i
                                                              className='fa fa-pencil-square-o'></i></span>&nbsp;Kuis</p> :
                                                          <p className='text-dark'><span className='text-theme'><i className='fa fa-clipboard'></i></span>&nbsp;Ujian Final</p>
                                        }
                                      </div>
                                    })
                                  }
                                </div>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      </div>
                    </div>
                ))
              }

            </div>
          </div>
        }

        {
          course &&
          <div className='col-md-5'>
            <div className='card p-4'>
              <img className="img-fluid w-100 mb-3" src={course.thumbnail.replace(/ /g, '%20')} alt="omind"/>
              <p className='text-muted mb-0'>Kategori <span className='text-dark font-weight-bold'>{course.category_id.name}</span>
              </p>
              <p className='text-muted mb-0'>Dibuat oleh <span
                  className='text-dark font-weight-bold'>{course.trainer_id.name}</span></p>
              <Link to={`/trainer/pelatihan-detail-lainnya?id=${id}`}>
                <button className="btn btn-pill btn-primary btn-sm mt-3 font-weight-bold text-work w-100"
                        type="button">Lihat Materi
                </button>
              </Link>
            </div>
          </div>
        }

        {
          course &&
          <div className="col-sm-12">
              <div className="card">
                  <div className="card-header d-flex align-items-center">
                      <div className='mr-auto'>
                          <h5 className='text-work text-warning' style={{fontSize:'24px', fontWeight:'600'}}>LIST PESERTA AKTIF</h5>
                      </div>
                      <div>
                          <select className="form-control btn-pill digits" id="exampleFormControlSelect7" onChange={handleFilter}>
                              <option disabled selected>Sorting berdasarkan</option>
                              <option value='new'>Terbaru</option>
                              <option value='old'>Terlama</option>
                          </select>
                      </div>
                  </div>
                  <div className="card-body">
                      <div className='overflow-auto w-100'>
                          <TableComp
                              title_paggination='pelatihan'
                              columns={course.type === 'Online' ? columnsTabelTransaksiOnline : columnsTabelTransaksi}
                              data={participants}
                          />
                      </div>
                  </div>
              </div>
          </div>
        }

      </div>
    </>
  )
}

export default DetailPelatihan;
