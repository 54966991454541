import React, { useState, useEffect } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation } from "react-router-dom";
import { Layers } from "react-feather";
import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import dupikat from "../../../assets/images/duplikat.png";
import learning_dummy from "../../../assets/images/learning-dummy-image.png";
import API from "../../../api";
import LihatMateri from "../lihatMateri/index";
import swal from 'sweetalert2';

const ListPelatihan = () => {
  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get("page") || 1;
  const status = "online";
  const [courses, setCourses] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });

  const [modalConfirm, setmodalConfirm] = useState(false);
  const [modalDuplikatDipilih, setmodalDuplikatDipilih] = useState(false);
  const [detailCourse, setDetailCourse] = useState(null);
  const [search, setSearch] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const columnsTabel = [
    {
      dataField: "id",
      text: "ID",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
    },
    {
      dataField: "pelatihan",
      text: "Nama Pelatihan",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
    },
    {
      dataField: "jenis_pelatihan",
      text: "Jenis Pelatihan",
      headerStyle: () => ({ width: "15%", textAlign: "left" }),
    },
    {
      dataField: "tanggal",
      text: "Tanggal Dibuat",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
    },
    {
      dataField: "",
      text: "Action",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      formatter: (cel, row, idx) => {
        return (
          <div className="d-flex justify-content-center">
            <Link
              to={`/superadmin/detail-pelatihan?id=${row.id}&company=${row.company}`}
              className="mx-auto"
            >
              <button
                className=" btn rounded-circle btn-success btn-xs py-1"
                type="button"
              >
                {" "}
                <i className="fa fa-eye"></i>{" "}
              </button>
            </Link>
            <Link
              to={`/superadmin/edit-pelatihan/?id=${row.id}`}
              className="mx-auto"
            >
              <button
                className=" mx-auto btn rounded-circle btn-warning btn-xs py-1"
                type="button"
              >
                {" "}
                <i className="fa fa-pencil"></i>{" "}
              </button>
            </Link>
            <button
              className=" mx-auto btn rounded-circle btn-danger btn-xs"
              type="button"
              onClick={() => deleteCourse(row.id, idx)}
            >
              {" "}
              <i className="fa fa-trash"></i>{" "}
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    API.superadmin.course
      .list_pelatihan({ page, per_page, search })
      .then((json) => {
        let course = { ...courses };
        course.data = [];

        json.data.data.map((val) => {
          course.data.push({
            id: val.id,
            pelatihan: val.name,
            company: val.company.name,
            jenis_pelatihan: val.category,
            tanggal: val.created_at,
            status: val.status,
          });
        });

        course.meta.from = (page - 1) * per_page + 1;
        course.meta.to =
          per_page > json.data.total
            ? json.data.total
            : page * per_page > json.data.total
            ? json.data.total
            : page * per_page;
        course.meta.total = json.data.total;
        course.meta.current_page = page;
        course.meta.total_page = Math.ceil(
          course.meta.total / course.meta.per_page
        );

        setCourses(course);
      });
  }, [page, search]);

  const deleteCourse = (id, idx) => {
    swal.fire({
      title: 'Anda yakin?',
      text: "Pastikan pelatihan ini belum dibeli. Pelatihan yang dihapus tidak bisa dikembalikan",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Batal',
    }).then((result) => {
      if (result.isConfirmed) {
        swal.fire({
          onBeforeOpen () {
            swal.showLoading()
          },
          onAfterClose () {
            swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });

        API.superadmin.course.hapus(id)
            .then(json => {
              swal.fire('Berhasil menghapus pelatihan!', '', 'success');

              let temp_courses = {...courses};
              temp_courses.data.splice(idx, 1);
              setCourses(temp_courses);
            }).catch(e => {
              swal.fire('Gagal menghapus pelatihan!', 'Mungkin pelatihan ini sudah ada yang membeli', 'error');
            })
      }
    })
  }

  const chooseCourse = (id) => {
    API.superadmin.course
      .detail_pelatihan(id)
      .then((json) => {
        setDetailCourse(json.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"List Pelatihan"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/manajemen-pelatihan" className="text-warning">
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Pelatihan</li>
          <li className="breadcrumb-item">List Pelatihan</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 d-flex">
              <div className="mr-auto">
                <h4
                  className="text-theme text-work"
                  style={{ fontWeight: "600" }}
                >
                  {" "}
                  PELATIHAN PERUSAHAAN
                </h4>
                <div className="row mt-3">
                  <div className="col-xl-12">
                    <form className="search-form contact-search">
                      <div className="form-group mb-0">
                        <input
                          className="form-control badge-pill"
                          type="text"
                          placeholder="Cari pelatihan ..."
                          value={search}
                          onChange={e => {
                            setSearch(e.target.value)
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-pill btn-outline-primary btn-sm text-work"
                  type="button"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                    setmodalConfirm(true);
                  }}
                >
                  <i className="fa fa-pencil" />
                  &nbsp;Tambah Pelatihan
                </button>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="overflow-auto w-100">
                <TableComp
                  title_paggination="pelatihan"
                  columns={columnsTabel}
                  data={courses}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalConfirm}
        toggle={() => setmodalConfirm(!modalConfirm)}
        size="md"
      >
        <ModalBody>
          <div className="text-center mt-3">
            <h6>Apakah ini pelatihan baru atau pelatihan duplikat?</h6>
            <Link to="/superadmin/tambah-pelatihan">
              <button
                className="btn btn-pill btn-primary btn-sm my-3 mr-3"
                type="button"
                onClick={() => {
                  setmodalConfirm(!modalConfirm);
                }}
              >
                Pelatihan Baru
              </button>
            </Link>
            <button
              className="btn btn-pill btn-outline-primary btn-sm my-3"
              type="button"
              onClick={() => {
                setmodalConfirm(!modalConfirm);
                setmodalDuplikatDipilih(true);
              }}
            >
              {" "}
              Pelatihan Duplikat
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalDuplikatDipilih}
        size="lg"
        style={{ maxWidth: "1050px" }}
      >
        <ModalHeader
          toggle={() => setmodalDuplikatDipilih(!modalDuplikatDipilih)}
        />
        <ModalBody>
          <div className="text-center mt-5 mb-4">
            <img src={dupikat} alt="" />
          </div>
          <div className="text-center mb-4 border-bottom pb-3">
            <h5 className="text-weight-bold">
              Pilih pelatihan yang mau diduplikat
            </h5>
            <div className="w-50 mx-auto text-left">
              <label className="text-work" style={{ fontSize: "16px" }}>
                Nama Pelatihan
              </label>
              <select
                className="form-control digits"
                defaultValue="default"
                onChange={(event) => chooseCourse(event.target.value)}
                required
              >
                <option value="default" disabled>
                  Pilih Pelatihan
                </option>
                {courses &&
                  courses.data.map((value) => {
                    return (
                      <option value={value.id}>
                        {value.pelatihan} - {value.company}
                      </option>
                    );
                  })}
              </select>
              {/* <input
                type="text"
                className="form-control"
                name="namaPelatihan"
                id="namaPelatihan"
                required
              /> */}
            </div>
            {detailCourse && (
              <div className="row w-75 mx-auto mt-4">
                <div className="col-md-4">
                  <img
                    class="img-fluid w-100 mb-3"
                    src={detailCourse.photo}
                    alt="omind"
                  />
                </div>
                <div className="col-md-8 text-left">
                  <h3 className="font-weight-bold">{detailCourse.nama}</h3>
                  <div className="d-flex">
                    <label>
                      <div
                        className={`badge text-wrap text-capitalize ${
                          detailCourse.type === "Online"
                            ? "badge-primary"
                            : detailCourse.type === "Face to Face"
                            ? "badge-danger"
                            : "badge-pink"
                        }`}
                      >
                        {status}
                      </div>
                    </label>
                    <p className="text-muted mr-4 ml-2">
                      {" "}
                      <span className="text-muted">
                        <i className="fa fa-question-circle"></i>
                      </span>
                    </p>
                    <p className="text-muted mr-4">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa-clock-o"></i>
                      </span>
                      &nbsp; Durasi Pelatihan{" "}
                      <span className="text-dark font-weight-bold">
                        {detailCourse.day_duration} hari
                      </span>
                    </p>
                  </div>
                  <p className="text-muted mb-0">
                    Kategori{" "}
                    <span className="text-dark font-weight-bold">
                      {detailCourse.category}
                    </span>
                  </p>
                  <p className="text-muted mb-0">
                    Dibuat oleh{" "}
                    <span className="text-dark font-weight-bold">
                      {detailCourse.created_by}
                    </span>
                  </p>
                  <div className="d-flex justify-content-between mt-3">
                    <p className="text-muted">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa-play-circle-o"></i>
                      </span>
                      &nbsp; {detailCourse.count_videos} {detailCourse.type}
                    </p>
                    <p className="text-muted">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa fa-file-pdf-o"></i>
                      </span>
                      &nbsp; {detailCourse.count_ebooks} PDF
                    </p>
                    <p className="text-muted">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa fa-file-powerpoint-o"></i>
                      </span>{" "}
                      &nbsp; {detailCourse.count_ppt} PPT
                    </p>
                    <p className="text-muted">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa-pencil-square-o"></i>
                      </span>
                      &nbsp; {detailCourse.count_quiz} Kuis
                    </p>
                    <p className="text-muted">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa-clipboard"></i>
                      </span>
                      &nbsp; {detailCourse.count_final} Ujian Final
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-pill btn-outline-primary btn-sm my-3 mr-3"
            type="button"
            onClick={() => {
              setmodalDuplikatDipilih(!modalDuplikatDipilih);
            }}
          >
            {" "}
            Batal
          </button>
          {detailCourse && (
            <Link
              to={`/superadmin/tambah-pelatihan?duplicate=${detailCourse.id}`}
            >
              <button
                className="btn btn-pill btn-primary btn-sm my-3"
                type="button"
                onClick={() => {
                  setmodalDuplikatDipilih(!modalDuplikatDipilih);
                }}
              >
                {" "}
                Duplikat Pelatihan
              </button>
            </Link>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};
export default ListPelatihan;
