import Get from "../Get";

const get_all = (data = null) => Get(`trainer/participant?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const get_one = id => Get(`trainer/participant/${id}`);

const ParticipantTrainer = {
  get_all,
  get_one
};

export default ParticipantTrainer;
