import React from 'react';
import { Progress } from 'reactstrap';
import { Link } from 'react-router-dom'

const PelatihanItemsCard = (props) => {
  const {
    id,
    name,
    type,
    description,
    progress,
    status,
    due_date
  } = props;
  return (
    <div className='border card bg-light border-0'>
      <div className='row'>
        <div className='col-md-12 px-5 py-3'>
          <h4 className='text-work text-dark' style={{ fontSize: '18px', fontWeight: 'bold' }}>{name}</h4>
          <div className={`badge text-wrap text-capitalize ${type === 'Online' ? 'badge-primary' : type === 'Face to Face' ? 'badge-danger' : 'badge-pink'}`} >
            {type}
          </div>
          <p className='text-muted my-3'>{description}</p>
          <div className='mb-3'>
            <div className='d-flex text-dark'>
              <h6 className='text-muted mr-auto'>PROGRESS PELATIHAN</h6>
              <h6 className='text-muted'>{parseInt(progress)}%</h6>
            </div>
            <Progress color="primary" value={parseInt(progress)} className="sm-progress-bar" />
          </div>
          <div className='text-right'>
            <Link to={`/admin/detail-pelatihan/progress-latihan?id=${id}&&status=${status}&&type=${type}`}>
              <button className="btn btn-pill btn-outline-primary btn-sm" type="button">Detail Pelatihan</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PelatihanItemsCard;