import Get from "./Get";

const list_provinsi = () => Get(`provinsi`);
const list_kota = id => Get(`provinsi/${id}/kota`);
const kota = id => Get(`kota/${id}`);
const list_kecamatan = id => Get(`kota/${id}/kecamatan`);
const kecamatan = id => Get(`kecamatan/${id}`);

const RajaOngkir = {
    list_provinsi,
    list_kota,
    kota,
    list_kecamatan,
    kecamatan,
};

export default RajaOngkir;
