import React, { useState, useEffect } from "react";
import { BreadcrumbComp } from "../../../component";
import { Link, useLocation, useHistory } from "react-router-dom";
import { CompanyIcon } from "../../../component/common/constant/menu";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import moment from "moment";
import API from "../../../api";
import {checkEmpty} from "../../../helper";

const TambahPelatihan = () => {
  let history = useHistory();
  let state = useLocation().state;

  let company_id = '';

  if(state){
    company_id = state.company_id;
  }
    console.log(company_id);

  let query = new URLSearchParams(useLocation().search);
  const duplicate = query.get("duplicate");
  const [imagePreview, setimagePreview] = useState(null);
  const [imagePreviewUrl, setimagePreviewUrl] = useState("");
  const [jenisPelatihan, setjenisPelatihan] = useState("Enquiries");
  const [InputJadwalPertemuan, setInputJadwalPertemuan] = useState([
    {
      name: "",
      location: "",
      detail_location: "",
      trainer_id: "",
      meetings: [
        { name: "Pertemuan 1", date: "", start_meeting: "", end_meeting: "" },
        { name: "Pertemuan 1", date: "", start_meeting: "", end_meeting: "" },
      ],
    },
  ]);
  const [InputModulPelatihan, setModulPelatihan] = useState([
    {
      name: "",
      theories: [
        { type: "Quiz", name: "Materi 1", diatur: true, file: null },
        { type: "Tugas Final", name: "Materi 1", diatur: false, file: null },
        { type: "Video", name: "Materi 2", file: null },
      ],
    },
  ]);
  const [list_category, setListCategory] = useState([]);
  const [list_trainer, setListTrainer] = useState([]);

  const [name, setName] = useState(null);
  const [type, setType] = useState(null);
  const [category, setCategory] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [day_duration, setdayDuration] = useState(null);
  const [description, setDescription] = useState(null);
  const [topic, setTopic] = useState(null);
  const [purpose, setPurpose] = useState(null);
  const [short_description, setShortDescription] = useState(null);
  const [target_audience, setTargetAudience] = useState(null);
  const [precondition, setPrecondition] = useState(null);
  const [trainer, setTrainer] = useState(null);
  const [theories, setTheories] = useState([]);
  const [certificate, setCertificate] = useState(null);
  const [price, setPrice] = useState(null);
  const [status, setStatus] = useState("Active, Private");
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    if (duplicate) {
      toast.success("Berhasil menduplikasi pelatihan", {
        position: toast.POSITION.TOP_CENTER,
      });
      API.superadmin.course.detail_pelatihan(duplicate).then((json) => {
        setName(json.data.nama);
        setType(json.data.type);
        setCategory(json.data.category);
        setThumbnail(json.data.thumbnail);
        setdayDuration(json.data.day_duration);
        setDescription(json.data.description);
        API.superadmin.trainer
          .list_trainer({company_id})
          .then(async (trainer) => {
            await trainer.data.map((value) => {
              value.name === json.data.trainer && setTrainer(value.id);
            });
          })
          .catch((err) => {
            console.log(err);
          });
        API.category
          .get_all()
          .then(async (category) => {
            category.data.map((value) => {
              value.name === json.data.category && setCategory(value.id);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  }, []);

  const materiOptions = [
    { label: "Slide Powerpoint", value: "Slide Powerpoint" },
    { label: "Video", value: "Video" },
    { label: "E-Book", value: "E-Book" },
    { label: "Quiz", value: "Quiz" },
    { label: "Tugas Final", value: "Tugas Final" },
  ];

  const caption = {
    "Face to Face":
      "Jika Anda memilih jenis pelatihan Face to Face, Anda diharuskan mengisikan jadwal pertemuan peserta dan trainer secara offline",
    Enquiries:
      "Jika Anda memilih jenis pelatihan Enquiry, Anda diharuskan mengisikan rencana jadwal pertemuan yang akan diselenggarkan nantinya.",
    "Virtual Class":
      "Jika Anda memilih jenis pelatihan Virtual Class, Anda diharuskan mengisikan jadwal pertemuan peserta dan trainer secara online.",
  };

  const handleAddJadwalPertemuan = () => {
    const newField = Object.assign([], InputJadwalPertemuan);
    newField.push({
      name: "",
      location: "",
      detail_location: "",
      trainer_id: "",
      meetings: [],
    });

    setInputJadwalPertemuan(newField);
  };

  const handleAddModulPelatihan = () => {
    const newField = Object.assign([], InputModulPelatihan);
    newField.push({ name: "", theories: [] });

    setModulPelatihan(newField);
  };

  const handleRemoveJadwalPertemuan = (index, type) => {
    const newField = Object.assign(
      [],
      type === "jadwalPertemuan" ? InputJadwalPertemuan : InputModulPelatihan
    );
    delete newField[index];

    if (type === "jadwalPertemuan") {
      setInputJadwalPertemuan(newField);
    } else {
      setModulPelatihan(newField);
    }
  };

  const handleFieldMateri = (modulIndex, materiIndex, type) => {
    const newModul = Object.assign([], InputModulPelatihan);
    const newField = Object.assign([], InputModulPelatihan[modulIndex]);

    if (type === "add") {
      newField.theories.push({ type: "Slide Powerpoint", name: "" });
    } else {
      newField.theories.splice(materiIndex, 1);
    }

    newModul[modulIndex] = newField;
    setModulPelatihan(newModul);
  };

  const handleMateriPertemuan = (modulIndex, indexPertemuan, type) => {
    const newModul = Object.assign([], InputJadwalPertemuan);
    const newField = Object.assign([], InputJadwalPertemuan[modulIndex]);

    if (type === "add") {
      newField.meetings.push({
        name: "",
        date: "",
        start_meeting: "",
        end_meeting: "",
      });
    } else {
      delete newField.meetings[indexPertemuan];
    }

    newModul[modulIndex] = newField;
    setInputJadwalPertemuan(newModul);
  };

  const handleChangeMateri = (name, value, modulIndex, materiIndex) => {
    const newModul = Object.assign([], InputModulPelatihan);
    const newField = Object.assign([], InputModulPelatihan[modulIndex]);
    newField.theories[materiIndex] = {
      ...newField.theories[materiIndex],
      [name]: value,
    };

    newModul[modulIndex] = newField;
    setModulPelatihan(newModul);
  };

  const handleChangeMeeting = (name, value, modulIndex, materiIndex) => {
    const newModul = Object.assign([], InputJadwalPertemuan);
    const newField = Object.assign([], InputJadwalPertemuan[modulIndex]);
    newField.meetings[materiIndex] = {
      ...newField.meetings[materiIndex],
      [name]: value,
    };

    newModul[modulIndex] = newField;
    setInputJadwalPertemuan(newModul);
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    setThumbnail(file);

    reader.onloadend = () => {
      setimagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleJenisPelatihan = (type) => {
    setType(type);
    setjenisPelatihan(type);
  };

  const handleChange = (event) => {
    const { name, value, index } = event.target;
    const newInput = Object.assign([], InputModulPelatihan);
    newInput[index] = { ...newInput[index], [name]: value };

    setModulPelatihan(newInput);
    console.log(InputModulPelatihan);
  };

  const handleChangeJadwal = (event) => {
    const { name, value, index } = event.target;
    const newInput = Object.assign([], InputJadwalPertemuan);
    newInput[index] = { ...newInput[index], [name]: value };

    setInputJadwalPertemuan(newInput);
    console.log(InputJadwalPertemuan);
  };

  const buttonPelatihan = (color, type, label) => (
    <button
      className={`btn ${
        jenisPelatihan === type ? color : "btn-secondary"
      } btn-xs text-capitalize mr-3`}
      type="button"
      onClick={() => handleJenisPelatihan(type)}
    >
      {label}
    </button>
  );

  const labelForm = (text) => (
    <label className="text-work" style={{ fontSize: "16px" }}>
      {text}
    </label>
  );

  const onEditorStateChange = ({ description }) => {
    console.log(description);
  };

  const handleuploadCertificate = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setCertificate(file);

    reader.readAsDataURL(file);
  };

  const handleSubmited = (e) => {
    e.preventDefault();

    if(checkEmpty({
      name,
      type,
      category,
      thumbnail,
      day_duration,
      description,
      short_description,
      topic,
      purpose,
      target_audience,
      precondition,
      trainer
    }, [
      {
        name: 'name',
        alias: 'Nama'
      },
      {
        name: 'type',
        alias: 'Jenis'
      },
      {
        name: 'category',
        alias: 'Kategori'
      },
      {
        name: 'thumbnail',
        alias: 'Foto'
      },
      {
        name: 'day_duration',
        alias: 'Durasi'
      },
      {
        name: 'description',
        alias: 'Deskripsi'
      },
      {
        name: 'short_description',
        alias: 'Deskripsi singkat'
      },
      {
        name: 'topic',
        alias: 'Topik'
      },
      {
        name: 'purpose',
        alias: 'Tujuan pembelajaran'
      },
      {
        name: 'target_audience',
        alias: 'Target audience'
      },
      {
        name: 'precondition',
        alias: 'Prekondisi'
      },
      {
        name: 'trainer',
        alias: 'Trainer'
      },
    ])){
      console.log('bener');
      let data = new FormData();

      data.append("name", name);
      data.append("type", type);
      data.append("category_id", category);
      data.append("thumbnail", thumbnail);
      data.append("day_duration", day_duration);
      data.append("description", description);
      data.append("short_description", short_description);
      data.append("topic", topic);
      data.append("purpose", purpose);
      data.append("target_audience", target_audience);
      data.append("precondition", precondition);
      data.append("trainer_id", trainer);

      InputModulPelatihan.map((value, index) => {
        data.append(`theories[${index}][name]`, value.name);

        value.theories.map((val, ind) => {
          data.append(`theories[${index}][theories][${ind}][type]`, val.type);
          data.append(`theories[${index}][theories][${ind}][name]`, val.name);
          data.append(`theories[${index}][theories][${ind}][file]`, val.file);
        });
      });

      data.append("certificate_template", certificate);
      data.append("price", price);
      data.append("status", status);

      if (type !== "Online") {
        InputJadwalPertemuan.map((value, index) => {
          data.append(`schedules[${index}][name]`, value.name);
          data.append(`schedules[${index}][location]`, value.location);
          data.append(
              `schedules[${index}][detail_location]`,
              value.detail_location
          );

          value.meetings.map((val, ind) => {
            data.append(`schedules[${ind}][meetings][${ind}][name]`, val.name);
            data.append(`schedules[${ind}][meetings][${ind}][date]`, val.date);
            data.append(
                `schedules[${ind}][meetings][${ind}][start_meeting]`,
                val.start_meeting
            );
            data.append(
                `schedules[${ind}][meetings][${ind}][end_meeting]`,
                val.end_meeting
            );
          });
        });
        // data.schedules = JSON.stringify(InputJadwalPertemuan);
      }

      console.log("InputModulPelatihan", InputModulPelatihan);
      console.log(
          "InputModulPelatihan stringify",
          JSON.stringify(InputModulPelatihan)
      );
      console.log(data);

      API.superadmin.course
          .insert_pelatihan(data)
          .then((json) => {
            toast.success(json.message, { position: toast.POSITION.TOP_CENTER });
            setTimeout(() => {
              history.push("/superadmin/list-pelatihan");
            }, 5000);
          })
          .catch((err) => {
            console.log(err);
            toast.success("Pelatihan Tidak Berhasil Ditambahkan", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
    }
  };

  useEffect(() => {
    API.category
      .get_all()
      .then((json) => {
        setListCategory(json.data);
      })
      .catch((err) => {
        console.log(err);
      });

    API.superadmin.trainer
      .list_trainer({company_id})
      .then((json) => {
        setListTrainer(json.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="page-header">
        <BreadcrumbComp title={"Tambah Pelatihan"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/tambah-pelatihan" className="text-warning">
              <CompanyIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Pelatihan</li>
          <li className="breadcrumb-item">Tambah Pelatihan</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header d-flex pb-2">
              <div className="mr-auto">
                <h4
                  className="text-theme text-work"
                  style={{ fontWeight: "600" }}
                >
                  TAMBAH PELATIHAN BARU
                </h4>
                <span className="text-muted">Data Pelatihan</span>
              </div>
              <div className="mr-3 d-flex">
                <span className="text-work mt-2" style={{ fontWeight: "500" }}>
                  Status &emsp;&emsp;&emsp;
                </span>
                <select
                  className="form-control digits "
                  id="exampleFormControlSelect7"
                  onChange={(event) => setStatus(event.target.value)}
                  value={status}
                >
                  <option
                    value="Active, Private"
                    selected={status == "Active, Private"}
                  >
                    Aktif, Private
                  </option>
                  <option
                    value="Aktif, Public"
                    selected={status == "Aktif, Public"}
                  >
                    Aktif, Publik
                  </option>
                  <option value="Nonaktif" selected={status == "Nonaktif"}>
                    Non Aktif
                  </option>
                </select>
              </div>
            </div>
            <div className="card-body pt-4 pb-3">
              <h6 className="text-muted mb-3" style={{ fontWeight: "700" }}>
                Informasi Pelatihan
              </h6>
              <form onSubmit={handleSubmited}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      {labelForm("Nama Pelatihan")}
                      <input
                        type="text"
                        className="form-control"
                        name="namaPelatihan"
                        id="namaPelatihan"
                        placeholder="Masukan nama pelatihan"
                        onChange={(event) => setName(event.target.value)}
                        value={name}
                      />
                    </div>
                    <div className="form-group">
                      {labelForm("Jenis Pelatihan")}
                      <div>
                        {buttonPelatihan("btn-primary", "Online", "online")}
                        {buttonPelatihan(
                          "btn-danger",
                          "Face to Face",
                          "Face to Face"
                        )}
                        {buttonPelatihan(
                          "btn-success",
                          "Enquiries",
                          "enquiries"
                        )}
                        {buttonPelatihan(
                          "badge-pink",
                          "Virtual Class",
                          "virtual class"
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      {labelForm("Bidang Pelatihan")}
                      <select
                        type="select"
                        className="form-control"
                        defaultValue="default"
                        onChange={(event) => setCategory(event.target.value)}
                        value={category}
                      >
                        <option value="default" disabled>
                          Pilih Bidang Pelatihan
                        </option>
                        {list_category.map((v) => {
                          return <option value={v.id}>{v.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      {labelForm("Durasi Pelatihan")}
                      <span className="d-flex align-items-center">
                        <input
                          type="number"
                          className="form-control mr-3"
                          style={{ width: "75px" }}
                          onChange={(event) =>
                            setdayDuration(event.target.value)
                          }
                          value={day_duration}
                        />
                        <p>hari</p>
                      </span>
                    </div>
                    <div className="form-group">
                      {labelForm("Harga")}
                      <span className="d-flex align-items-center">
                        <p className="mb-0">Rp</p>
                        <input
                          type="number"
                          className="form-control ml-3"
                          style={{ width: "125px" }}
                          onChange={(event) => setPrice(event.target.value)}
                          value={price}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="mx-auto d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#F1F3F6",
                        width: "400px",
                        height: "200px",
                        backgroundImage: `url(${imagePreviewUrl})`,
                        backgroundSize: "cover",
                        borderRadius: "20px",
                      }}
                    >
                      {!imagePreviewUrl && (
                        <p
                          style={{
                            color: "#8B8B8B",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          Tambah foto pelatihan
                        </p>
                      )}
                    </div>
                    <div className="w-50 mx-auto mt-3">
                      <input
                        className="form-control text-dark"
                        type="file"
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="border-bottom py-3">
                      <div className="form-group">
                        {labelForm("Deskripsi Pelatihan")}
                        <Editor
                          toolbarClassName="border-bottom"
                          wrapperClassName="border"
                          editorClassName="p-3"
                          name="description"
                          onChange={(event) => {
                            setDescription(event.blocks[0].text);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        {labelForm("Topik Pelatihan")}
                        <Editor
                          toolbarClassName="border-bottom"
                          wrapperClassName="border"
                          editorClassName="p-3"
                          onChange={(event) => {
                            setTopic(event.blocks[0].text);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        {labelForm("Tujuan Pembelajaran")}
                        <Editor
                          toolbarClassName="border-bottom"
                          wrapperClassName="border"
                          editorClassName="p-3"
                          onChange={(event) => {
                            setPurpose(event.blocks[0].text);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        {labelForm("Target Audience")}
                        <Editor
                          toolbarClassName="border-bottom"
                          wrapperClassName="border"
                          editorClassName="p-3"
                          onChange={(event) => {
                            setTargetAudience(event.blocks[0].text);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        {labelForm("Prekondisi")}
                        <Editor
                          toolbarClassName="border-bottom"
                          wrapperClassName="border"
                          editorClassName="p-3"
                          onChange={(event) => {
                            setPrecondition(event.blocks[0].text);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        {labelForm("Deskripsi Singkat")}
                        <Editor
                          toolbarClassName="border-bottom"
                          wrapperClassName="border"
                          editorClassName="p-3"
                          onChange={(event) => {
                            setShortDescription(event.blocks[0].text);
                          }}
                        />
                      </div>
                    </div>
                    {jenisPelatihan ? (
                      <div className="border-bottom py-3">
                        <h6
                          className="text-muted mb-3"
                          style={{ fontWeight: "700" }}
                        >
                          Pilih Trainer
                        </h6>
                        <div className="form-group">
                          {labelForm("Nama Trainer")}
                          <select
                            type="select"
                            className="form-control"
                            defaultValue="default"
                            style={{ width: "343px" }}
                            onChange={(event) => setTrainer(event.target.value)}
                            value={trainer}
                          >
                            <option value="default" disabled>
                              Pilih Trainer
                            </option>
                            {list_trainer.map((v) => {
                              return <option value={v.id}>{v.name}</option>;
                            })}
                          </select>
                          {/* <input
                            type="text"
                            className="form-control"
                            name="namaPelatihan"
                            id="namaPelatihan"

                            placeholder="Masukan Nama Trainer"
                            style={{ width: '343px' }}
                          /> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {jenisPelatihan !== "Online" && (
                      <div className="border-bottom py-3">
                        <h6
                          className="text-muted mb-3"
                          style={{ fontWeight: "700" }}
                        >
                          Atur Jadwal Pertemuan
                        </h6>
                        <span className="border rounded p-3 d-flex align-items-center">
                          <svg
                            className="mr-3"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
                              fill="#FCC932"
                            />
                            <path
                              d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
                              fill="#FCC932"
                            />
                            <path
                              d="M8.99784 17.0678C8.82646 17.0678 8.6875 16.9288 8.6875 16.7574C8.6875 16.5861 8.82646 16.4471 8.99784 16.4471C13.1094 16.4423 16.4414 13.1104 16.4461 8.99882C16.4461 8.82743 16.5851 8.68848 16.7565 8.68848C16.9279 8.68848 17.0668 8.82743 17.0668 8.99882C17.0618 13.4531 13.4521 17.0628 8.99784 17.0678Z"
                              fill="#FCF05A"
                            />
                            <path
                              d="M1.24003 9.30901C1.06864 9.30901 0.929688 9.17005 0.929688 8.99866C0.934653 4.54436 4.54436 0.934653 8.99866 0.929688C9.17005 0.929688 9.30901 1.06864 9.30901 1.24003C9.30901 1.41142 9.17005 1.55038 8.99866 1.55038C4.88705 1.55515 1.55515 4.88705 1.55038 8.99866C1.55038 9.17005 1.41142 9.30901 1.24003 9.30901Z"
                              fill="#FCF05A"
                            />
                            <path
                              d="M8.99959 4.65234C9.3424 4.65234 9.62027 4.93022 9.62027 5.27303C9.62027 5.61585 9.3424 5.89372 8.99959 5.89372C8.65678 5.89372 8.37891 5.61585 8.37891 5.27303C8.37891 4.93022 8.65678 4.65234 8.99959 4.65234Z"
                              fill="#5F79BA"
                            />
                            <path
                              d="M9.61948 12.4126V7.13672H7.13672V7.75741C7.13672 7.9288 7.27568 8.06775 7.44706 8.06775H8.3781V13.3436H10.8609V12.7229C10.8609 12.5515 10.7219 12.4126 10.5505 12.4126H9.61948Z"
                              fill="#5F79BA"
                            />
                          </svg>
                          {caption[jenisPelatihan]}
                        </span>
                        {!InputJadwalPertemuan[0] && (
                          <button
                            className="btn btn-link p-0 text-theme text-roboto font-weight-bold mt-3"
                            type="button"
                            onClick={handleAddJadwalPertemuan()}
                          >
                            {" "}
                            + Tambah Pertemuan
                          </button>
                        )}
                        {InputJadwalPertemuan.map((field, index) => (
                          <div className="mt-3 w-75">
                            <div
                              className="form-group pl-3 d-flex"
                              style={{ borderLeft: "3px solid #FCC932" }}
                            >
                              <div>
                                {labelForm("Nama Jadwal")}
                                <input
                                  type="text"
                                  className="form-control mr-3"
                                  style={{ width: "343px" }}
                                  onChange={(event) =>
                                    handleChangeJadwal({
                                      target: {
                                        name: "name",
                                        value: event.target.value,
                                        index: index,
                                      },
                                    })
                                  }
                                />
                              </div>
                              <div className="ml-auto">
                                <button
                                  className="btn btn-link p-0 text-theme text-roboto font-weight-bold"
                                  type="button"
                                  onClick={() =>
                                    handleRemoveJadwalPertemuan(
                                      index,
                                      "jadwalPertemuan"
                                    )
                                  }
                                >
                                  {" "}
                                  X{" "}
                                </button>
                              </div>
                            </div>
                            <div className="form-group pl-3 d-flex">
                              <div>
                                {labelForm("Lokasi Pertemuan")}
                                <input
                                  type="text"
                                  className="form-control mr-3"
                                  style={{ width: "343px" }}
                                  onChange={(event) =>
                                    handleChangeJadwal({
                                      target: {
                                        name: "location",
                                        value: event.target.value,
                                        index: index,
                                      },
                                    })
                                  }
                                />
                              </div>
                              <div>
                                {labelForm("Detail Lokasi")}
                                <input
                                  type="text"
                                  className="form-control mr-3"
                                  style={{ width: "343px" }}
                                  onChange={(event) =>
                                    handleChangeJadwal({
                                      target: {
                                        name: "detail_location",
                                        value: event.target.value,
                                        index: index,
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                            {jenisPelatihan !== "enquiries" && (
                              <div className="form-group pl-3">
                                {labelForm("Nama Trainer")}
                                <input
                                  type="text"
                                  className="form-control mr-3"
                                  style={{ width: "343px" }}
                                />
                              </div>
                            )}
                            <div className="ml-4">
                              {field.meetings.map((meeting, indexPertemuan) => (
                                <div className="d-flex" key={indexPertemuan}>
                                  <div>
                                    <button
                                      className="btn btn-link p-0 text-roboto font-weight-bold mr-3"
                                      type="button"
                                      style={{ color: "#C4C4C4" }}
                                      onClick={() =>
                                        handleMateriPertemuan(
                                          index,
                                          indexPertemuan
                                        )
                                      }
                                    >
                                      {" "}
                                      X{" "}
                                    </button>
                                  </div>
                                  <div
                                    className="form-group pl-3"
                                    style={{ borderLeft: "2px solid #F1F3F6" }}
                                  >
                                    <input
                                      style={{ width: "343px" }}
                                      type="text"
                                      className="form-control"
                                      placeholder="Masukkan nama materi pertemuan"
                                      value={meeting.name}
                                      onChange={(e) =>
                                        handleChangeMeeting(
                                          "name",
                                          e.target.value,
                                          index,
                                          indexPertemuan
                                        )
                                      }
                                    />
                                    <DatePicker
                                      className="form-control digits mt-3"
                                      placeholderText="Pilih Tanggal"
                                      value={meeting.date}
                                      onChange={(e) =>
                                        handleChangeMeeting(
                                          "date",
                                          e.toLocaleDateString(),
                                          index,
                                          indexPertemuan
                                        )
                                      }
                                    />
                                    <div className="d-flex align-items-center mt-3">
                                      <DatePicker
                                        placeholderText="Waktu mulai"
                                        className="form-control digits"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        style={{ width: "150px" }}
                                        timeCaption="Time"
                                        dateFormat="H:mm"
                                        value={meeting.start_meeting}
                                        onChange={(e) =>
                                          handleChangeMeeting(
                                            "start_meeting",
                                            moment(e).format("H:m"),
                                            index,
                                            indexPertemuan
                                          )
                                        }
                                      />
                                      <p
                                        className="mb-0 text-nunito mx-3"
                                        style={{ fontSize: "12px" }}
                                      >
                                        hingga
                                      </p>
                                      <DatePicker
                                        placeholderText="Waktu selesai"
                                        className="form-control digits"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        style={{ width: "150px" }}
                                        timeCaption="Time"
                                        dateFormat="H:mm"
                                        value={meeting.end_meeting}
                                        onChange={(e) =>
                                          handleChangeMeeting(
                                            "end_meeting",
                                            moment(e).format("H:m"),
                                            index,
                                            indexPertemuan
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <button
                                className="btn btn-link p-0 text-theme text-roboto font-weight-bold"
                                type="button"
                                onClick={() =>
                                  handleMateriPertemuan(index, 0, "add")
                                }
                              >
                                {" "}
                                + Tambah Materi Pertemuan
                              </button>
                            </div>
                          </div>
                        ))}
                        <button
                          className="btn btn-link p-0 text-theme text-roboto font-weight-bold"
                          type="button"
                          onClick={handleAddJadwalPertemuan}
                        >
                          {" "}
                          + Tambah Pertemuan
                        </button>
                      </div>
                    )}

                    <div className="border-bottom py-3">
                      <h6
                        className="text-muted mb-3"
                        style={{ fontWeight: "700" }}
                      >
                        Materi Pelatihan
                      </h6>
                      {!InputModulPelatihan[0] && (
                        <button
                          className="btn btn-link p-0 text-theme text-roboto font-weight-bold"
                          type="button"
                          onClick={handleAddModulPelatihan}
                        >
                          {" "}
                          + Tambah modul pelatihan
                        </button>
                      )}
                      {InputModulPelatihan.map((field, index) => (
                        <div className="mt-3 w-75">
                          <div
                            className="form-group pl-3 d-flex"
                            style={{ borderLeft: "3px solid #FCC932" }}
                          >
                            <div>
                              <input
                                type="text"
                                className="form-control mr-3"
                                style={{ width: "343px" }}
                                placeholder="Masukan nama modul pelatihan"
                                onChange={(event) =>
                                  handleChange({
                                    target: {
                                      name: "name",
                                      value: event.target.value,
                                      index: index,
                                    },
                                  })
                                }
                              />
                            </div>
                            <div className="ml-auto">
                              <button
                                className="btn btn-link p-0 text-theme text-roboto font-weight-bold"
                                type="button"
                                onClick={() =>
                                  handleRemoveJadwalPertemuan(
                                    index,
                                    "modulPelatihan"
                                  )
                                }
                              >
                                {" "}
                                X{" "}
                              </button>
                            </div>
                          </div>
                          <div className="ml-4">
                            {field.theories &&
                              field.theories.map((theories, indexMateri) => (
                                <div className="d-flex" key={indexMateri}>
                                  <div>
                                    <button
                                      className="btn btn-link p-0 text-roboto font-weight-bold mr-3"
                                      type="button"
                                      style={{ color: "#C4C4C4" }}
                                      onClick={() =>
                                        handleFieldMateri(index, indexMateri)
                                      }
                                    >
                                      {" "}
                                      X{" "}
                                    </button>
                                  </div>
                                  <div
                                    className="form-group pl-3 d-flex"
                                    style={{ borderLeft: "2px solid #F1F3F6" }}
                                  >
                                    <div className="ml-auto">
                                      <select
                                        defaultValue={theories.type}
                                        className="form-control"
                                        style={{ width: "166px" }}
                                        onChange={(e) =>
                                          handleChangeMateri(
                                            "type",
                                            e.target.value,
                                            index,
                                            indexMateri
                                          )
                                        }
                                      >
                                        {materiOptions.map(
                                          (options, indexOptions) => (
                                            <option
                                              value={options.value}
                                              key={indexOptions}
                                            >
                                              {options.label}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <input
                                        style={{ width: "343px" }}
                                        type="text"
                                        className="form-control mt-3"
                                        placeholder="Masukan nama materi"
                                        onChange={(e) =>
                                          handleChangeMateri(
                                            "name",
                                            e.target.value,
                                            index,
                                            indexMateri
                                          )
                                        }
                                        value={theories.name}
                                      />
                                      {theories.type === "Slide Powerpoint" ? (
                                        <div className="d-flex align-items-center mt-3">
                                          <div style={{ width: "12rem" }}>
                                            {labelForm("Upload slide PPT")}
                                          </div>
                                          <input
                                            className="form-control text-dark"
                                            type="file"
                                            accept='application/pdf'
                                            style={{ width: "298px" }}
                                            onChange={(e) => {
                                              handleChangeMateri(
                                                "file",
                                                e.target.files[0],
                                                index,
                                                indexMateri
                                              );
                                            }}
                                          />
                                        </div>
                                      ) : theories.type === "Video" ? (
                                        <div className="d-flex align-items-center mt-3">
                                          <div style={{ width: "12rem" }}>
                                            {labelForm("Link video")}
                                          </div>
                                          <input
                                            className="form-control text-dark"
                                            placeholder="Masukkan link video"
                                            type="text"
                                            style={{ width: "298px" }}
                                            onChange={(e) => {
                                              handleChangeMateri(
                                                "file",
                                                e.target.files[0],
                                                index,
                                                indexMateri
                                              );
                                            }}
                                          />
                                        </div>
                                      ) : theories.type === "E-Book" ? (
                                        <div className="d-flex align-items-center mt-3">
                                          <div style={{ width: "12rem" }}>
                                            {labelForm("Upload E-Book")}
                                          </div>
                                          <input
                                            className="form-control text-dark"
                                            type="file"
                                            accept='application/pdf'
                                            style={{ width: "298px" }}
                                            onChange={(e) => {
                                              handleChangeMateri(
                                                "file",
                                                e.target.files[0],
                                                index,
                                                indexMateri
                                              );
                                            }}
                                          />
                                        </div>
                                      ) : theories.type === "Quiz" ? (
                                        <div className="d-flex align-items-center mt-3">
                                          <button
                                            className={`btn ${
                                              theories.diatur
                                                ? "btn-secondary"
                                                : "btn-primary"
                                            } mr-3`}
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "600",
                                            }}
                                            disabled={theories.diatur}
                                          >
                                            Atur Soal Quiz
                                          </button>
                                          {theories.diatur ? (
                                            <div className="d-flex align-items-center">
                                              <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"
                                                  fill="#00BA42"
                                                />
                                              </svg>
                                              <p className="mb-0 ml-3">
                                                Soal Quiz sudah diatur
                                              </p>
                                            </div>
                                          ) : (
                                            <>
                                              <p
                                                className="mr-3 mb-0"
                                                tyle={{
                                                  fontSize: "12px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                Atau
                                              </p>
                                              <button
                                                className="btn btn-success mr-3"
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {" "}
                                                <i className="fa fa-cloud-download"></i>{" "}
                                                &nbsp;Download Template Bulk
                                                Soal
                                              </button>
                                              <button
                                                className="btn btn-primary"
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                <i className="fa fa-cloud-upload"></i>{" "}
                                                &nbsp;Upload Soal (.csv){" "}
                                              </button>
                                            </>
                                          )}
                                        </div>
                                      ) : (
                                        <div className="d-flex align-items-center mt-3">
                                          <button
                                            className="btn btn-primary mr-3"
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            Atur Tugas Final
                                          </button>
                                          {theories.diatur ? (
                                            <div className="d-flex align-items-center">
                                              <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z"
                                                  fill="#00BA42"
                                                />
                                              </svg>
                                              <p className="mb-0 ml-3 ">
                                                Soal Tugas Final sudah diatur
                                              </p>
                                            </div>
                                          ) : (
                                            <>
                                              <p
                                                className="mr-3 mb-0"
                                                tyle={{
                                                  fontSize: "12px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                Atau
                                              </p>
                                              <button
                                                className="btn btn-success mr-3"
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                <i className="fa fa-cloud-download"></i>{" "}
                                                &nbsp;Download Template Bulk
                                                Soal
                                              </button>
                                              <button
                                                className="btn btn-primary"
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                <i className="fa fa-cloud-upload"></i>{" "}
                                                &nbsp;Upload Soal (.csv){" "}
                                              </button>
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            <button
                              className="btn btn-link p-0 text-theme text-roboto font-weight-bold"
                              type="button"
                              onClick={() => handleFieldMateri(index, 0, "add")}
                            >
                              {" "}
                              + Tambah Materi
                            </button>
                          </div>
                          {InputModulPelatihan.length <= index + 1 && (
                            <button
                              className="btn btn-link p-0 text-theme text-roboto font-weight-bold"
                              type="button"
                              onClick={handleAddModulPelatihan}
                            >
                              {" "}
                              + Tambah modul pelatihan
                            </button>
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="border-bottom py-3">
                      <h6
                        className="text-muted mb-3"
                        style={{ fontWeight: "700" }}
                      >
                        Sertifikat
                      </h6>
                      <p>
                        Fermentum mauris blandit eget accumsan aliquam integer
                        condimentum dolor. Scelerisque commodo consectetur at
                        sit. Velit diam duis facilisis id netus.
                      </p>
                      <div className="d-flex align-items-center">
                        <div
                          className="text-right pr-3"
                          style={{ width: "15rem" }}
                        >
                          {labelForm("Upload Sertifikat")}
                        </div>
                        <input
                          className="form-control text-dark"
                          type="file"
                          style={{ width: "298px" }}
                          onChange={handleuploadCertificate}
                        />
                      </div>
                    </div>
                    <div className="my-5 d-flex align-items-center justify-content-end">
                      <button className="btn btn-outline-primary btn-pill mr-3">
                        Batal
                      </button>
                      <button className="btn btn-primary btn-pill">
                        Simpan
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TambahPelatihan;
