import React from 'react';
import PDFViewer from 'pdf-viewer-reactjs'
import CustomNavigation from './navigation';

const PelatihanPPTView = (props) => {
  return (
    <div className='card p-5'>
      <div className='w-100 border rounded mb-5'>
        <PDFViewer
          hideZoom
          hideRotation
          document={{
            url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf',
          }}
          css='customViewer'
          navigation={(e) => CustomNavigation(e, props.user)}
          canvasCss='customCanvas'
        />
      </div>
    </div>
  )
}

export default PelatihanPPTView;