import Post from "../Post";
import Get from "../Get";
import Delete from "../Delete";

const listCourse = (data) => Get(`admin-c/courses?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const course_detail = id => Get(`admin-c/courses/${id}`);
const course_participants = (id, data) => Get(`admin-c/courses/${id}/participants?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const delete_course = id => Delete(`admin-c/courses/${id}`);
const course_get_update = id => Get(`admin-c/courses/update/${id}`);
const course_detail_update = id => Get(`admin-c/courses/${id}/update`);
const course_detail_edit = (id, data) => Post(`admin-c/courses/${id}`, data);
const check_certificate = data => Post(`admin-c/courses/check-certificate`, data);
const list_participant = (id, data) => Get(`admin-c/courses/participants/${id}?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const insert_pelatihan = (data, uploadProgress) => Post(`admin-c/courses`, data, uploadProgress);
const duplicate = (data, uploadProgress) => Post(`admin-c/courses/duplicate`, data, uploadProgress);
const update_pelatihan = (id, data, uploadProgress) => Post(`admin-c/courses/update/${id}`, data, uploadProgress);
const course_progress = id => Get(`admin-c/courses/progress/${id}`);
const course_comment = (id, data) => Post(`admin-c/courses/comment/${id}`, data);
const list_trainer = () => Get(`admin-c/courses/list_trainer`);
const add_course = (data) => Post(`admin-c/courses`, data);
const duplicate_course = (data) => Post(`admin-c/courses/duplicate`, data);
const search_course = (data) => Get(`admin-c/courses/search?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const course_theories = id => Get(`admin-c/courses/${id}/theories`);
const delete_participant = id => Delete(`admin-c/courses/participant/${id}`);

const ListCourseAdminC = {
    listCourse,
    course_detail,
    course_participants,
    course_get_update,
    check_certificate,
    course_detail_update,
    course_detail_edit,
    list_participant,
    insert_pelatihan,
    duplicate,
    update_pelatihan,
    course_progress,
    course_comment,
    list_trainer,
    add_course,
    duplicate_course,
    search_course,
    course_theories,
    delete_course,
    delete_participant,
};

export default ListCourseAdminC;
