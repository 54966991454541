import React, { useState, useEffect } from "react";
import { Users } from "react-feather";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import dupikat from "../../../assets/images/duplikat.png";
import learning_dummy from "../../../assets/images/learning-dummy-image.png";
import { ToastContainer, toast } from "react-toastify";
import API from "../../../api";

const ListPelatihan = () => {
  const history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  const [modalDetail, setmodalDetail] = useState(false);
  const [modalDelete, setmodalDelete] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const [keyCourse, setkeyCourse] = useState(null);
  const [duplicatView, setduplicatView] = useState(false);
  const [one_course, setOneCourse] = useState(null);
  const [search, setSearch] = useState('')

  useEffect(() => {
    setduplicatView(false);
  }, [modalDetail]);
  const [idDuplikatTerpilih, setidDuplikatTerpilih] = useState("132456");
  const [id_course, setIdCourse] = useState(null);
  const toastSuccessAdd = localStorage.getItem("tambahPelatihan");
  const status = "online";

  useEffect(() => {
    setduplicatView(false);
  }, [modalDetail]);

  const per_page = 10;
  let page = query.get("page") || 1;

  const [courses, setCourse] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });

  useEffect(() => {
    if (toastSuccessAdd) {
      toast.success("Berhasil menambahkan pelatihan", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("tambahPelatihan");
    }
  }, [toastSuccessAdd]);

  const columnsTabel = [
    {
      dataField: "id",
      text: "ID",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p className="my-1 " style={{ fontWeight: "500", fontSize: "17px" }}>
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div>
            <label>
              <p style={{ textTransform: "uppercase", fontSize: "14px" }}>
                {row.id}
              </p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "pelatihan",
      text: "Nama Pelatihan",
      headerStyle: () => ({ width: "25%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p className="my-1 " style={{ fontWeight: "500", fontSize: "17px" }}>
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="ml-2" style={row.pelatihan.length > 50 ? {backgroundColor: 'pink'} : {}}>
            <label>
              <p style={{ fontSize: "14px" }}>{row.pelatihan}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "bidang",
      text: "Jenis Pelatihan",
      headerStyle: () => ({ width: "16%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p className="my-1 " style={{ fontWeight: "500", fontSize: "17px" }}>
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div>
            <label className="ml-2">
              <p style={{ textTransform: "capitalize", fontSize: "14px" }}>
                {row.bidang}
              </p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "tanggal",
      text: "Tanggal Dibuat",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p className="my-1 " style={{ fontWeight: "500", fontSize: "17px" }}>
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div>
            <label className="ml-2">
              <p style={{ textTransform: "capitalize", fontSize: "14px" }}>
                {row.tanggal}
              </p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "15%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p className="my-1 " style={{ fontWeight: "500", fontSize: "17px" }}>
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div>
            <label className="ml-2">
              <p style={{ textTransform: "capitalize", fontSize: "14px" }}>
                {cell}
              </p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Aksi",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p className="my-1 " style={{ fontWeight: "500", fontSize: "17px" }}>
          {column.text}
        </p>
      ),
      formatter: (cel, row, index) => {
        return (
          <div className="d-flex justify-content-center">
            <Link
              to={`/admin/detail-pelatihan?id=${row.id}&&status=${row.status}`}
              className="mx-auto"
            >
              <button
                className=" mx-auto btn rounded-circle btn-success btn-xs py-1"
                type="button"
              >
                {" "}
                <i className="fa fa-eye"></i>{" "}
              </button>
            </Link>
             <Link
              to={`/admin/edit-pelatihan?id=${row.id}`}
            >
              <button
                className=" mx-auto btn rounded-circle btn-warning btn-xs py-1"
                type="button"
              >
                {" "}
                <i className="fa fa-pencil"></i>{" "}
              </button>
            </Link>
            <button
              className=" mx-auto btn rounded-circle btn-danger btn-xs"
              type="button"
              onClick={() => {
                setmodalDelete(true);
                setkeyCourse(index)
                setCourseId(row.id);
              }}
            >
              {" "}
              <i className="fa fa-trash"></i>{" "}
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getCourse(search)
  }, [page]);

  const getCourse = (search) => {
    API.adminc.course
      .listCourse({ page, per_page, search })
      .then((json) => {
        let course = { ...courses };
        course.data = [];

        json.data.data.map((val) => {
          course.data.push({
            id: val.id,
            pelatihan: val.name,
            bidang: val.category,
            tanggal: val.created_at + " WIB",
            status: val.status,
          });
        });

        course.meta.from = (page - 1) * per_page + 1;
        course.meta.to =
          per_page > json.data.total
            ? json.data.total
            : page * per_page > json.data.total
            ? json.data.total
            : page * per_page;
        course.meta.total = json.data.total;
        course.meta.current_page = page;
        course.meta.total_page = Math.ceil(
          course.meta.total / course.meta.per_page
        );

        setCourse(course);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSearchCourse = (event) => {
    event.preventDefault();

    if (event.target.value !== "") {
      API.adminc.course
        .search_course({ s: event.target.value })
        .then((json) => {
          setOneCourse(json.data[0]);
          setIdCourse(json.data[0].id);
        })
        .catch((err) => {
          toast.error("Pelatihan Tidak Ditemukan", {
            position: toast.POSITION.TOP_CENTER,
          });
          console.log(err);
        });
    }
  };
  const handleDelete = () => {
    let temp_courses = {...courses}
    temp_courses.data.splice(keyCourse, 1)
    setCourse(temp_courses)

    API.adminc.course
      .delete_course(courseId)
      .then((json) => {
        toast.success(json.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setmodalDelete(!modalDelete);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Tidak Berhasil Menghapus Pelatihan", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  return (
    <>
      <ToastContainer />
      <div className="page-header">
        <BreadcrumbComp title={"List pelatihan"}>
          <li className="breadcrumb-item">
            <Link to="/admin/list-pelatihan" className="text-warning">
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Pelatihan</li>
          <li className="breadcrumb-item">List Pelatihan</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 pb-3 d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  PELATIHAN PERUSAHAAN
                </h5>
                <div className="col-sm-12 mt-4">
                  <div className="row">
                    <form className="search-form contact-search">
                      <div className="form-group mb-0">
                        <input
                          className="form-control badge-pill"
                          type="text"
                          placeholder="Cari pelatihan..."
                          onChange={(e) => {
                            getCourse(e.target.value)
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="row-xl-3 m-1 mt-5">
                <button
                  className=" btn btn-pill btn-outline-primary btn-sm text-work"
                  type="button"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                    setmodalDetail(true);
                  }}
                >
                  <i className="fa fa-pencil"></i> &nbsp;Tambah Pelatihan
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="overflow-auto w-100">
                <TableComp
                  rowClasses="text-left"
                  title_paggination="pelatihan"
                  columns={columnsTabel}
                  data={courses}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalDetail}
        toggle={() => setmodalDetail(!modalDetail)}
        size="md"
        style={{ maxWidth: one_course ? "1000px" : "548px", maxHeight: "275px" }}
      >
        <ModalBody className="pb-5">
          <div className="text-center mt-5 mb-4">
            <img src={dupikat} alt="" />
          </div>
          {duplicatView ? (
            <div>
              <div className="text-center mb-4 border-bottom pb-3">
                <h5 className="text-weight-bold">
                  Pilih pelatihan yang mau diduplikat
                </h5>
                <div className="w-50 mx-auto text-left">
                  <label className="text-work" style={{ fontSize: "16px" }}>
                    Nama Pelatihan
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="namaPelatihan"
                    id="namaPelatihan"
                    required
                    onBlur={handleSearchCourse}
                  />
                </div>
                {one_course && (
                  <div className="row w-75 mx-auto mt-4">
                    <div className="col-md-4">
                      <img
                        class="img-fluid w-100 mb-3"
                        src={one_course.photo}
                        alt="omind"
                      />
                    </div>
                    <div className="col-md-8 text-left">
                      <h3 className="font-weight-bold">{one_course.nama}</h3>
                      <div className="d-flex">
                        <label>
                          <div
                            className={`badge text-wrap text-capitalize ${
                              one_course.type === "online"
                                ? "badge-primary"
                                : one_course.type === "face to face"
                                ? "badge-danger"
                                : "badge-pink"
                            } mr-2`}
                          >
                            {status}
                          </div>
                        </label>
                        <p className="text-muted mr-4 ml-2">
                          {" "}
                          <span className="text-muted">
                            <i className="fa fa-question-circle"></i>
                          </span>
                        </p>
                        <p className="text-muted mr-4">
                          {" "}
                          <span className="text-theme">
                            <i className="fa fa-clock-o"></i>
                          </span>
                          &nbsp; Durasi Pelatihan{" "}
                          <span className="text-dark font-weight-bold">
                            {one_course.day_duration} hari
                          </span>
                        </p>
                      </div>
                      <p className="text-muted mb-0">
                        Kategori{" "}
                        <span className="text-dark font-weight-bold">
                          {one_course.category}
                        </span>
                      </p>
                      <p className="text-muted mb-0">
                        Dibuat oleh{" "}
                        <span className="text-dark font-weight-bold">
                          {one_course.created_by}
                        </span>
                      </p>
                      <div className="d-flex justify-content-between mt-3">
                        <div className="row">
                          {one_course.type === "Virtual Class" && (
                              <div className="col-4">
                                <p className="text-muted mr-4">
                                  {" "}
                                  <span className="text-theme">
                                    <i className="fa fa-play-circle-o"></i>
                                  </span>
                                  &nbsp; {one_course.count_meeting} Virtual Class
                                </p>
                              </div>
                          )}
                          {one_course.type === "Face to Face" && (
                              <div className="col-4">
                                <p className="text-muted mr-4">
                                  {" "}
                                  <span className="text-theme">
                                <i className="fa fa-play-circle-o"></i>
                              </span>
                                  &nbsp; {one_course.count_meeting} Tatap Muka
                                </p>
                              </div>
                          )}
                          <div className="col-4">
                            <p className="text-muted mr-4">
                              {" "}
                              <span className="text-theme">
                            <i className="fa fa fa-play-circle-o"></i>
                          </span>
                              &nbsp; {one_course.count_videos} Video
                            </p>
                          </div>
                          <div className="col-4">
                            <p className="text-muted mr-4">
                              {" "}
                              <span className="text-theme">
                            <i className="fa fa fa-file-pdf-o"></i>
                          </span>
                              &nbsp; {one_course.count_ebooks} PDF
                            </p>
                          </div>
                          <div className="col-4">
                            <p className="text-muted mr-4">
                              {" "}
                              <span className="text-theme">
                            <i className="fa fa fa-file-powerpoint-o"></i>
                          </span>{" "}
                              &nbsp; {one_course.count_ppt} PPT
                            </p>
                          </div>
                          <div className="col-4">
                            <p className="text-muted mr-4">
                              {" "}
                              <span className="text-theme">
                            <i className="fa fa-pencil-square-o"></i>
                          </span>
                              &nbsp; {one_course.count_quiz} Kuis
                            </p>
                          </div>
                          <div className="col-4">
                            <p className="text-muted mr-4">
                              {" "}
                              <span className="text-theme">
                            <i className="fa fa-clipboard"></i>
                          </span>
                              &nbsp; {one_course.count_final} Ujian Final
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-pill btn-outline-primary mr-3"
                  onClick={() => {
                    setOneCourse(null);
                    setduplicatView(!duplicatView)
                  }}
                >
                  Batal
                </button>
                <button
                  className="btn btn-pill btn-primary"
                  disabled={!one_course}
                  onClick={() => {
                    history.push({
                      pathname: "/admin/tambah-pelatihan",
                      state: {
                        id: one_course.id,
                        isDuplicate: true,
                        index_modul: null,
                        index_materi: null,
                      },
                    });
                  }}
                >
                  Duplikat Pelatihan
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="text-center mb-4">
                <h5 className="text-weight-bold">
                  Apakah ini pelatihan baru atau <br /> pelatihan duplikat?
                </h5>
              </div>
              <div className="text-center">
                <button
                  className="btn btn-pill btn-primary"
                  onClick={() => {
                    history.push({
                      pathname: "/admin/tambah-pelatihan",
                      state: {
                        course: null,
                        isDuplicate: false,
                        index_modul: null,
                        index_materi: null,
                      },
                    });
                  }}
                >
                  Pelatihan Baru
                </button>
                <button
                  className="btn btn-pill btn-outline-primary ml-2"
                  onClick={() => setduplicatView(!duplicatView)}
                >
                  Pelatihan Duplikat
                </button>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalDelete}
        toggle={() => setmodalDelete(!modalDelete)}
        size="md"
        style={{ maxWidth: "548px", maxHeight: "275px" }}
      >
        <ModalBody className="pb-5">
          <>
            <div className="text-center mb-4">
              <h5 className="text-weight-bold">
                Anda yakin akan menghapus pelatihan ini?
              </h5>
            </div>
            <div className="text-center">
              <button
                className="btn btn-pill btn-outline-primary"
                onClick={() => {
                  setmodalDelete(!modalDelete);
                  setCourseId(null);
                  setkeyCourse(null);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-pill btn-danger ml-2"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ListPelatihan;
