import Get from "../Get";
import Post from "../Post";
import Delete from "../Delete";
const list_promo = (data) =>
  Get(
    `admin-c/promo?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const detail_promo = (id) => Get(`admin-c/promo/${id}`);
const insert_promo = (data) => Post(`admin-c/promo`, data);
const update_promo = (id, data) => Post(`admin-c/promo/${id}`, data);
const delete_promo = (id) => Delete(`admin-c/promo/${id}`);

const PromoAdminC = {
  list_promo,
  detail_promo,
  insert_promo,
  update_promo,
  delete_promo,
};

export default PromoAdminC;
