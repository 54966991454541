import React, { useState, useEffect, useRef } from "react";
import { Users } from "react-feather";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import API from "../../../api";

const EditForm = (props) => {
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const history = useHistory();
  const { participant, setEditView } = props;
  const [DoneSubmit, setDoneSubmit] = useState(false);
  const [uploadKTPPreview, setuploadKTPPreview] = useState(null);
  const [uploadKTPPreviewUrl, setuploadKTPPreviewUrl] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [userPhoto, setuserPhoto] = useState(null);
  const [userPhotoUrl, setuserPhotoUrl] = useState("");
  const [data_profession, setDataProfession] = useState([]);
  const [listProvinsi, setListProvinsi] = useState([]);
  const [listKota, setListKota] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);
  const [company, setCompany] = useState(null);
  const [provinsi, setProvinsi] = useState("");
  const [kecamatan, setKecamatan] = useState("");
  const [kabupaten, setKabupaten] = useState("");

  const photoRef = useRef(null);

  const [dataParticipant, setDataParticipant] = useState(null);
  const handleImagePelatihanChange = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setDataParticipant({
      ...dataParticipant,
      profile_picture: file,
    });

    reader.onloadend = () => {
      setuserPhotoUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleuploadKTPChange = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setDataParticipant({
      ...dataParticipant,
      ktp: file,
    });

    reader.onloadend = () => {
      setuploadKTPPreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (uploadKTPPreviewUrl) {
      setuploadKTPPreview(uploadKTPPreviewUrl);
    } else {
      setuploadKTPPreview(false);
    }
  }, [uploadKTPPreviewUrl]);

  useEffect(() => {
    if (userPhotoUrl) {
      setuserPhoto(userPhotoUrl);
    } else {
      setuserPhoto(false);
    }
  }, [userPhotoUrl]);

  useEffect(() => {
    API.superadmin.profession
      .get_profession()
      .then((json) => {
        setDataProfession(json.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    viewParticipant()
  }, []);

  const viewParticipant = async () => {
    try{
      let {data: {data}} = await API.superadmin.company.list_perusahaan();
      setCompany(data);
      data.map((value) => {
        value.name === participant.company_name &&
        setDataParticipant({
          ...participant,
          company_id: value.id,
          profession_id: participant.profession.id,
        });
      });
    }
    catch (e) {
      console.log(e);
    }

    let temp_participant = {...participant};
    setuploadKTPPreview(temp_participant.ktp)
    setuserPhoto(temp_participant.photo)
    setDataParticipant(temp_participant);

    API.rajaongkir
        .list_provinsi()
        .then((json) => {
          setListProvinsi(json);
        })
        .catch((err) => {
          console.log(err);
        });

    console.log(dataParticipant);
  }

  const handleChange = (e) => {
    setDataParticipant({
      ...dataParticipant,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    delete dataParticipant.registered_at;
    delete dataParticipant.last_active_at;
    delete dataParticipant.profession;
    delete dataParticipant.company_name;
    if (id) {
      API.superadmin.participant
        .update_participant(id, dataParticipant)
        .then((json) => {
          console.log(json.message);
          setisLoading(true);
          setDoneSubmit(true);
          setTimeout(() => {
            history.push("/superadmin/peserta-terregistrasi");
          }, 5000);
          setEditView(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      API.superadmin.participant
        .insert_participant(dataParticipant)
        .then((json) => {
          console.log(json.message);
          setisLoading(true);
          setDoneSubmit(true);
          setEditView(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      {dataParticipant && (
        <>
          <div className="page-header">
            <BreadcrumbComp title={"Peserta"}>
              <li className="breadcrumb-item">
                <Link to="/ketua/list-pelatihan" className="text-warning">
                  <Users />
                </Link>
              </li>
              <li className="breadcrumb-item">Manajemen Pelatihan</li>
              <li className="breadcrumb-item">Peserta Terregistrasi</li>
              <li className="breadcrumb-item">{dataParticipant.name}</li>
            </BreadcrumbComp>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header pb-3 d-flex align-items-center">
                  <div className="mr-auto">
                    <h5
                      className="text-work text-theme"
                      style={{ fontSize: "24px", fontWeight: "600" }}
                    >
                      Peserta
                    </h5>
                    <span className="text-muted">Data Peserta</span>
                  </div>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-1 mb-5">
                        <div className="form-group mt-4 text-left">
                          <img src={userPhoto} style={{
                            height: "92px",
                            width: "92px",
                            borderRadius: "50% 50%",
                            objectFit: 'cover',
                            cursor: 'pointer'
                          }}
                          onClick={() => photoRef.current.click()}
                          />
                          <input
                              ref={photoRef}
                              id="file-select"
                              type="file"
                              onChange={handleImagePelatihanChange}
                              style={{
                                display: 'none'
                              }}
                          />
                        </div>
                      </div>
                      <div className="col-md-5 mb-5">
                        <div className="form-group mt-4 text-left">
                          <h6 className="text-work">Nama</h6>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Masukkan nama"
                            style={{ width: "343px" }}
                            name="name"
                            onChange={handleChange}
                            value={dataParticipant.name}
                          />
                        </div>
                        <div className="form-group mt-4 text-left">
                          <h6 className="text-work">Email</h6>
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Masukkan email"
                            style={{ width: "343px" }}
                            name="email"
                            onChange={handleChange}
                            value={dataParticipant.email}
                          />
                        </div>
                        <div className="form-group mt-4 text-left">
                          <h6 className="text-work">Tanggal Lahir</h6>
                          <input
                            className="form-control"
                            type="date"
                            placeholder="Masukkan tanggal lahir"
                            style={{ width: "343px" }}
                            name="date_of_birth"
                            onChange={handleChange}
                            value={dataParticipant.date_of_birth}
                          />
                        </div>
                        <div className="form-group mt-4 text-left">
                          <h6 className="text-work">Nomer Telepon</h6>
                          <input
                            className="form-control"
                            type="phone"
                            placeholder="Masukkan telepon"
                            style={{ width: "343px" }}
                            name="phone_number"
                            onChange={handleChange}
                            value={dataParticipant.phone_number}
                          />
                        </div>
                        <div className="form-group mt-4 text-left">
                          <h6 className="text-work">NIK KTP</h6>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Masukkan nik"
                            style={{ width: "343px" }}
                            name="nik"
                            onChange={handleChange}
                            value={dataParticipant.nik}
                          />
                        </div>
                        <div className="form-group">
                          {/* Preview */}
                          <img
                            className="img-fluid w-50 mb-3 h-50"
                            src={uploadKTPPreview}
                            alt="KTP"
                          />
                          {/* Input */}
                          <div className="button-custom w-50 py-3">
                            <div className="btn btn-pill btn-primary upload-image">
                              <span>
                                <i className="fa fa-cloud-upload"></i> &ensp;
                                Upload KTP
                              </span>
                              <input
                                id="file-select"
                                type="file"
                                onChange={handleuploadKTPChange}
                              />
                            </div>
                          </div>
                          <p style={{ color: "red" }}>
                            *Ukuran Foto KTP Maksimal 100KB
                          </p>
                        </div>
                        <div className="form-group mb-0 mt-1">
                          <label htmlFor="alamat">
                            <b>Alamat</b>
                          </label>
                        </div>
                        <div className="d-flex">
                          <div className="form-group mr-5">
                            <select
                              name="provinsi"
                              className="form-control"
                              id="provinsi"
                              style={{ width: "200px" }}
                              name="address"
                              onChange={(e) => {
                                setProvinsi(e.target.value);
                                setListKota([]);
                                setListKecamatan([]);

                                API.rajaongkir
                                  .list_kota(e.target.value)
                                  .then((json) => {
                                    setListKota(json);
                                  });
                              }}
                            >
                              <option value="#">Provinsi</option>
                              {listProvinsi.map((v) => {
                                return (
                                  <option value={v.province_id}>
                                    {v.province}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="form-group">
                            <select
                              name="kabupaten"
                              className="form-control"
                              id="kabupaten"
                              style={{ width: "200px" }}
                              onChange={(e) => {
                                setKabupaten(e.target.value);
                                setListKecamatan([]);

                                API.rajaongkir
                                  .list_kecamatan(e.target.value)
                                  .then((json) => {
                                    console.log(json);
                                    setListKecamatan(json);
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              }}
                            >
                              <option value="#">Kabupaten</option>
                              {listKota.map((v) => {
                                return (
                                  <option value={v.city_id}>
                                    {v.city_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="form-group mr-5">
                            <select
                              name="region_id"
                              className="form-control"
                              id="kecamatan"
                              style={{ width: "200px" }}
                              onChange={(e) => setKecamatan(e.target.value)}
                            >
                              <option value="#">Kecamatan</option>
                              {listKecamatan.map((v) => (
                                <option value={v.subdistrict_id}>
                                  {v.subdistrict_name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              name="postal_code"
                              id="kodePos"
                              placeholder="Masukkan kode pos"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <textarea
                          className="form-control"
                          name="address"
                          id="alamatKantor"
                          placeholder="Nama Jalan / Alamat Detail"
                          rows="5"
                          style={{ width: "530px" }}
                          onChange={handleChange}
                          value={dataParticipant.address}
                        ></textarea>
                      </div>
                      <div className="col-md-6 mb-5">
                        <div className="row">
                          <div className="col-md-6 form-group mt-4 text-left">
                            <h6 className="text-work">Tanggal Registrasi</h6>
                            <h6
                              className="text-work"
                              style={{ fontWeight: "normal" }}
                            >
                              {participant.registered_at}
                            </h6>
                          </div>
                          <div className="col-md-6 form-group mt-4 text-left">
                            <h6 className="text-work">Tanggal Aktif</h6>
                            <h6
                              className="text-work"
                              style={{ fontWeight: "normal" }}
                            >
                              {participant.last_active_at}
                            </h6>
                          </div>
                        </div>
                        <div className="form-group mt-4 text-left">
                          <h6 className="text-work">Status</h6>
                          <select
                            className="form-control"
                            style={{ width: "250px" }}
                          >
                            <option>Aktif</option>
                            <option>Non-Aktif</option>
                          </select>
                        </div>
                        <div className="form-group mt-4 text-left">
                          <h6 className="text-work">Nama Perusahaan</h6>
                          <select
                            className="form-control digits "
                            defaultValue="default"
                            style={{ width: "274px" }}
                            name="company_id"
                            onChange={handleChange}
                          >
                            <option value="default" disabled>
                              Pilih Perusahaan
                            </option>
                            {company &&
                              company.map((value) => {
                                return (
                                  <option value={value.id}>{value.name}</option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="form-group mt-4 text-left">
                          <h6 className="text-work">Pekerjaan</h6>
                          <select
                            className="form-control"
                            style={{ width: "250px" }}
                            name="profession_id"
                            onChange={handleChange}
                            value={dataParticipant.profession_id}
                          >
                            <option>--Pilih Pekerjaan--</option>
                            {data_profession &&
                              data_profession.map((v) => {
                                return <option value={v.id}>{v.name}</option>;
                              })}
                          </select>
                        </div>
                        <div className="form-group mt-4 text-left">
                          <h6 className="text-work">Alamat Kantor</h6>
                          <textarea
                            className="form-control"
                            placeholder=""
                            name="office_address"
                            onChange={handleChange}
                            value={dataParticipant.office_address}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-12 m-5 border-top mt-5 pt-5">
                        <div className="text-right mb-5">
                          <button
                            type="cancel"
                            className="btn btn-outline-primary btn-pill mr-2"
                            type="button"
                            onClick={() => setEditView(false)}
                          >
                            Batal
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary btn-pill"
                          >
                            Simpan
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditForm;
