import Post from "../Post";
import Get from "../Get";

const add_ctp = (data) => Post(`admin-c/course-to-participants`, data);

const CtpAdminC = {
    add_ctp,
};

export default CtpAdminC;
