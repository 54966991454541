import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Users } from 'react-feather';
import { ToastContainer, toast } from 'react-toastify';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useLocation } from 'react-router-dom'
import API from "../../../api";

const PesertaTerregistrasi = () => {
  const history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  const toastText = localStorage.getItem("toast")
  const columnsTabel = [
    {
      dataField: 'id',
      text: 'ID',
      headerStyle: () => ({ width: '5%', textAlign: 'center' }),
      headerFormatter: (column) => <p className='text-muted my-2' style={{ fontWeight: '600', fontSize: '18px' }}>{column.text}</p>,
    },
    {
      dataField: 'peserta',
      text: 'Nama Peserta',
      headerStyle: () => ({ width: '20%', textAlign: 'left' }),
      headerFormatter: (column) => <p className='text-muted my-2' style={{ fontWeight: '600', fontSize: '18px' }}>{column.text}</p>,
      formatter: (cell, row) => {
        return (
          <div className='d-flex align-items-center mb-2'>
            {row.avatar &&
              <div>
                <img className="img-40 rounded-circle" style={{height: 40, objectFit: 'cover'}} src={row.avatar} alt="#"/>
              </div>
            }
            <label className='mb-0 ml-2'>
              <p className='font-weight-bold'>{row.peserta}</p>
            </label>
          </div>
        )
      }
    },
    {
      dataField: 'tanggal',
      text: 'Tanggal Registrasi',
      headerStyle: () => ({ width: '20%', textAlign: 'center' }),
      headerFormatter: (column) => <p className='text-muted my-2' style={{ fontWeight: '600', fontSize: '18px' }}>{column.text}</p>,
    },
    {
      dataField: 'bidang',
      text: 'Nama Perusahaan',
      headerStyle: () => ({ width: '20%', textAlign: 'center' }),
      headerFormatter: (column) => <p className='text-muted my-2' style={{ fontWeight: '600', fontSize: '18px' }}>{column.text}</p>,
    },
    {
      dataField: 'status',
      text: 'Status',
      headerStyle: () => ({ width: '10%', textAlign: 'center' }),
      headerFormatter: (column) => <p className='text-muted my-2' style={{ fontWeight: '600', fontSize: '18px' }}>{column.text}</p>,
    },
    {
      dataField: '',
      text: 'Aksi',
      headerStyle: () => ({ width: '10%', textAlign: 'center', }),
      headerFormatter: (column) => <p className='text-muted my-2' style={{ fontWeight: '600', fontSize: '18px' }}>{column.text}</p>,
      formatter: (cel, row) => {
        return (
          <Link to={`/superadmin/detail-peserta-terregistrasi?id=${row.id}`}>
            <button className=" btn btn-pill btn-outline-primary btn-sm font-weight-bold" type="button"> Detail </button>
          </Link>
        )
      },
    }
  ];

  const per_page = 30;
  let page = query.get('page') || 1;

  const [participants, setParticipant] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: ''
    }
  });
  const [search, setSearch] = useState('');

  useEffect(() => {
    API.superadmin.participant.list_participant({ page, per_page, search })
      .then(json => {
        let participant = { ...participants };
        participant.data = [];

        if (json.data) {
          json.data.data.map(val => {
            participant.data.push({
              id: val.id,
              peserta: val.name,
              avatar: val.photo,
              tanggal: val.created_at + ' WIB',
              bidang: val.company,
              status: val.status,
            })
          });
        }

        participant.meta.from = (page - 1) * per_page + 1;
        participant.meta.to = per_page > json.data.total ? json.data.total : page * per_page > json.data.total ? json.data.total : page * per_page;
        participant.meta.total = json.data.total;
        participant.meta.current_page = page;
        participant.meta.total_page = Math.ceil(participant.meta.total / participant.meta.per_page);

        setParticipant(participant);
      });
  }, [page, search]);

  useEffect(() => {
    history.push('/superadmin/peserta-terregistrasi');
  }, [search]);

  useEffect(() => {
    if (toast) {
      toast.success(toastText, { position: toast.POSITION.TOP_CENTER });
      localStorage.removeItem("toast")
    }
  }, [toastText])

  return (
    <>
      <ToastContainer />
      <div className="page-header">
        <BreadcrumbComp title={'Peserta Terregistrasi'}>
          <li className="breadcrumb-item">
            <Link to="/ketua/list-pelatihan" className='text-warning'>
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Pelatihan</li>
          <li className="breadcrumb-item">Peserta Terregistrasi</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-3 d-flex align-items-center">
              <div className='mr-auto'>
                <h5 className='text-work text-theme' style={{ fontSize: '24px', fontWeight: '600' }}>LIST PESERTA TERREGISTRASI</h5>
                <div className="row mt-3">
                  <div className="col-xl-12">
                    <form className="search-form contact-search ">
                      <div className="form-group mb-0">
                        <input
                          className="form-control badge-pill"
                          type="text"
                          placeholder="Cari peserta . . ."
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div>
                <button className=" btn btn-pill btn-outline-primary btn-sm" type="button"> <i className='fa fa-pencil'></i> Tambah Pelatihan ke Peserta </button>
                <Link to="/superadmin/tambah-bulk">
                  <button className=" btn btn-pill btn-outline-primary btn-sm mx-3" type="button"> <i className='fa fa-pencil'></i> Tambah Bulk Peserta</button>
                </Link>
                <Link to="/superadmin/tambah-peserta">
                  <button className=" btn btn-pill btn-outline-primary btn-sm" type="button"> <i className='fa fa-pencil'></i> Tambah Peserta</button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className='overflow-auto w-100'>
                <TableComp
                  rowClasses='text-center'
                  title_paggination='peserta'
                  columns={columnsTabel}
                  data={participants}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default PesertaTerregistrasi;
