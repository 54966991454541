import Get from "../Get";
import Post from "../Post";

const list_chat = () => Get(`admin-c/chat/list_chat`);
const list_contact = () => Get(`admin-c/chat/list_contact`);
const get_chat = id => Get(`admin-c/chat/get_chat/${id}`);
const send_chat = (id, data) => Post(`admin-c/chat/send_chat/${id}`, data);
const get_contact = id => Get(`admin-c/chat/get_contact/${id}`);
const change_status = (id) => Post(`admin-c/chat/change_status/${id}`);

const ChatAdminC = {
    list_chat,
    list_contact,
    get_chat,
    send_chat,
    get_contact,
    change_status,
};

export default ChatAdminC;