import React, {createRef} from "react";
import {useLocation} from 'react-router-dom';
import logo_SDM from '../../../assets/images/logo-SDM.png';
import {numberWithDots} from "../../../helper";
import Pdf from "react-to-pdf";

const UnduhInvoice = () => {
  const status = 'Lunas';
  const {detail} = useLocation().state;

  const user = JSON.parse(localStorage.getItem('data'));

  console.log(detail);
  // let produk = [];
  // let jenis = [];
  // let harga = [];

  // detail.courses.map(v => {
  //   produk.push({
  //     judul: v.course.name,
  //     trainer: v.trainer.name,
  //     company: v.trainer.company,
  //   });
  //   jenis.push(v.course.type);
  //   harga.push(v.course.price);
  // });

  const ref = createRef();

  return (
    <>
      <div className="card rounded-0 py-3 shadow">
        <div className="container-fluid text-right">
          <Pdf targetRef={ref} filename={`${detail.no_transaction}.pdf`} options={{
            orientation: 'landscape',
            unit: 'in',
            format: [500, 950]
          }}>
            {({ toPdf }) => <button
                className="btn btn-pill btn-primary btn-sm"
                type="button"
                onClick={toPdf}
            > Cetak </button>}
          </Pdf>
        </div>
      </div>
      <div className="container-fluid" ref={ref}>
        <div className="card px-4 py-5 mt-5">
          <div className="row mb-5">
            <div className="col-md-4 d-flex align-items-center">
              <div>
                <h6 className='text-muted' style={{ fontWeight: 'bold', fontSize: '24px' }}>INVOICE</h6>
                <div className='d-flex'>
                  <div className={`mb-0 py-2 px-3 text-center card btn-status btn-status_${detail.status === 'Lunas' ? 'aktif' : detail.status === 'Ditolak Admin' ? 'ditolak' : detail.status === 'Tagihan' ? 'terpakai' : detail.status === 'Kedaluarsa' ? 'kedaluarsa' : 'expired'}`}>{detail.status}</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <img className="img-fluid w-25 mx-auto" src={logo_SDM} alt="" />
            </div>
            <div className="col-md-4 text-right d-flex align-items-center justify-content-end">
              <div className='text-right'>
                <h6 className='text-muted' style={{ fontWeight: 'normal', fontSize: '16px' }}>No Transaksi</h6>
                <h6 className='text-muted' style={{ fontWeight: 'normal', fontSize: '30px' }}>{detail.no_transaction}</h6>
              </div>
            </div>
          </div>

          <div className="row pb-3" style={{ borderBottom: '1px dashed #C4C4C4' }}>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-2"> <h6 style={{ fontWeight: 'normal', fontSize: '14px' }}>Dijual ke</h6> </div>
                <div className="col-md-10"> <h6 style={{ fontWeight: '600', fontSize: '14px' }}>{detail.detail_course.trainer.company}</h6> </div>
              </div>
              <div className="row">
                <div className="col-md-2"> <h6 style={{ fontWeight: 'normal', fontSize: '14px' }}>Tanggal</h6> </div>
                <div className="col-md-10"> <h6 style={{ fontWeight: '600', fontSize: '14px' }}>{detail.date}</h6> </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4"> <h6 style={{ fontWeight: 'normal', fontSize: '14px' }}>Metode Pembayaran</h6> </div>
                <div className="col-md-8"> <h6 style={{ fontWeight: '600', fontSize: '14px' }}>{detail.metode}</h6> </div>
              </div>
              <div className="row">
                <div className="col-md-4"> <h6 style={{ fontWeight: 'normal', fontSize: '14px' }}>Waktu Pembayaran</h6> </div>
                <div className="col-md-8"> <h6 style={{ fontWeight: '600', fontSize: '14px' }}>{detail.date}</h6> </div>
              </div>
            </div>
          </div>

          <div className="row mt-4 border-bottom pb-4">
            <div className="col-md-6">
              <h6 className='mb-3' style={{ fontWeight: '600', fontSize: '19px', color: '#C4C4C4' }}>NAMA PELATIHAN</h6>
                <h6 style={{ fontWeight: '600', fontSize: '16px' }}>{detail.detail_course.course.name}</h6>
                <h6 style={{ fontWeight: 'normal', fontSize: '12px' }}>{detail.detail_course.trainer.name} <span style={{ color: '#797979' }}>{detail.detail_course.trainer.company}</span></h6>
            </div>
            <div className="col-md-2 text-center">
              <h6 className='mb-3' style={{ fontWeight: '600', fontSize: '19px', color: '#C4C4C4' }}>JENIS</h6>
                <h6 style={{ fontWeight: 'normal', fontSize: '16px', marginBottom: 30 }}>{detail.detail_course.course.type}</h6>
            </div>
            <div className="col-md-2 text-center">
              <h6 className='mb-3' style={{ fontWeight: '600', fontSize: '19px', color: '#C4C4C4' }}>TANGGAL BELI</h6>
                <h6 style={{ fontWeight: 'normal', fontSize: '16px' }}>{detail.date}</h6>
            </div>
            <div className="col-md-2 text-center">
              <h6 className='mb-3' style={{ fontWeight: '600', fontSize: '19px', color: '#C4C4C4' }}>HARGA</h6>
                <h6 style={{ fontWeight: 'normal', fontSize: '16px' }}>Rp {numberWithDots(detail.detail_course.course.price)}</h6>
            </div>
          </div>

          <div className='d-flex justify-content-end mt-5'>
            <div className='w-25'>
              <div className="row">
                <div className="col-md-6"> <h6 style={{ fontWeight: '600', fontSize: '18px' }}>Subtotal</h6> </div>
                <div className="col-md-6"> <h6 style={{ fontWeight: '600', fontSize: '18px' }}>Rp {numberWithDots(''+detail.total_price)}</h6> </div>
              </div>
              <div className="row">
                <div className="col-md-6"> <h6 style={{ fontWeight: '600', fontSize: '18px' }}>Total Kuantitas</h6> </div>
                <div className="col-md-6"> <h6 style={{ fontWeight: '600', fontSize: '18px' }}>{detail.total_course ? detail.total_course : 0} Produk</h6> </div>
              </div>
              <div className='bg-light p-3 text-dark mt-3'>
                <div className="row">
                  <div className="col-md-6"> <h6 style={{ fontWeight: 'normal', fontSize: '14px' }}>Subtotal Produk</h6> </div>
                  <div className="col-md-6 text-right"> <h6 style={{ fontWeight: 'normal', fontSize: '14px' }}>Rp {numberWithDots(''+detail.total_price)}</h6> </div>
                </div>
                {/* {detail.promo_discount.nominal !== null &&
                  <div className="row">
                    <div className="col-md-6"> <h6 style={{ fontWeight: 'normal', fontSize: '14px' }}>Kupon Promo</h6> </div>
                    <div className="col-md-6 text-right"> <h6 style={{ fontWeight: 'normal', fontSize: '14px' }}>- Rp {numberWithDots(''+detail.nominal)}</h6> </div>
                  </div>
                }

                {detail.point !== '0' &&
                <div className="row">
                  <div className="col-md-6"> <h6 style={{ fontWeight: 'normal', fontSize: '14px' }}>Points</h6> </div>
                  <div className="col-md-6 text-right"> <h6 style={{ fontWeight: 'normal', fontSize: '14px' }}>- Rp {numberWithDots(''+detail.point)}</h6> </div>
                </div>
                }

                <div className="row">
                  <div className="col-md-6"> <h6 style={{ fontWeight: 'normal', fontSize: '14px' }}>Biaya Admin</h6> </div>
                  <div className="col-md-6 text-right"> <h6 style={{ fontWeight: 'normal', fontSize: '14px' }}>Rp {numberWithDots(''+detail.admin_fee)}</h6> </div>
                </div> */}
                <hr />
                <div className="row">
                  <div className="col-md-6"> <h6 style={{ fontWeight: '600', fontSize: '14px' }}>Total Keseluruhan</h6> </div>
                  <div className="col-md-6 text-right"> <h6 style={{ fontWeight: '600', fontSize: '14px' }}>Rp {numberWithDots(''+detail.total_price)}</h6> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UnduhInvoice;
