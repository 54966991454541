import React, {useState} from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Users } from 'react-feather';
import { Input } from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import API from "../../../api";
import swal from 'sweetalert2';

const TambahTugasFinalForm = () => {
  let query = new URLSearchParams(useLocation().search);
  let id = query.get('id');
  const history = useHistory();

  const [question, setQuestion] = useState('');
  const [question_file, setQuestionFile] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmited = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if(question === ''){
      swal.fire('Error', 'Soal harus diisi', 'error');
      setIsSubmitting(false);
      return;
    }

    let data = {
      question: question,
      question_file: question_file,
      theory_id: id
    }

    API.adminc.final.insert(data)
        .then(json => {
          setIsSubmitting(false);
          swal.fire('Sukses', "Soal Tugas Final Berhasil Ditambahkan", 'success').then(() => {
            history.goBack();
          });
        }).catch(e => {
          setIsSubmitting(false);
          swal.fire('Error', e.message, 'error');
        });
  }

  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <BreadcrumbComp title={'List pelatihan'}>
          <li className="breadcrumb-item">
            <Link to="/admin/list-pelatihan" className='text-warning'>
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Soal Tugas Final</li>
          <li className="breadcrumb-item">Tambah Soal Tugas Final</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5 className='text-work text-theme' style={{ fontSize: '24px', fontWeight: '600' }}>PELATIHAN PERUSAHAAN</h5>
              <p className='text-work text-muted' style={{ fontSize: '18px', fontWeight: '600' }}>Atur Soal Tugas</p>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmited}>

                <h6 style={{ fontSize: '16px' }}>Soal</h6>
                <Editor
                  toolbarClassName="border-bottom"
                  wrapperClassName="border"
                  editorClassName="p-3"
                  onChange={(event) => {
                    setQuestion(event.blocks[0].text)
                  }}
                />
                <div className="d-flex align-items-center mt-4">
                  <h6 style={{ fontSize: '12px' }}>Upload Soal</h6>
                  <input className="form-control text-dark ml-5" accept='application/pdf' type="file" style={{ width: '298px' }} onChange={(e) => {setQuestionFile(e.target.files[0])}}/>
                </div>
                <div className='my-5 d-flex align-items-center justify-content-end border-top pt-4'>
                    <button type='button' className='btn btn-outline-primary btn-pill mr-3' onClick={() => history.goBack()}>Batal</button>
                    <button className='btn btn-primary btn-pill'  disabled={isSubmitting}>Simpan</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TambahTugasFinalForm;
