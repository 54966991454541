import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import quiz from "../../../../assets/images/quiz.png";
import { Input, UncontrolledCollapse, Button } from "reactstrap";

const PelatihanKuisView = (props) => {
  const { questions } = props;
  let query = new URLSearchParams(useLocation().search);
  const status = query.get("status");
  let id = query.get("id");
  const [View, setView] = useState("pembahasan");
  const [Attempt, setAttempt] = useState(1);

  return (
    <>
      <div className="d-flex align-items-center">
        <img className="img-60 mr-3" src={quiz} alt="omind" />
        <label className="mb-0 ml-2 mr-auto">
          <h6 className="mb-0 font-weight-bold">Quiz</h6>
          <p className="mb-0">Quiz Teknik Bicara</p>
        </label>
      </div>
      {questions.question.map((data, index) => (
        <div className="card p-5 mt-4" key={index}>
          <div className="mb-3">
            <span dangerouslySetInnerHTML={{__html: `${index + 1}. ${data.question}`}}/>
            <div className="ml-4">
              {data.options.map((option) => {
                return (
                  <>
                    <label style={{fontWeight: option.option === data.correct_answer ? 'bold' : 'normal'}}>
                      {option.option}. {option.description}
                    </label>{" "}
                    <br />
                  </>
                );
              })}
            </div>
            <Button
              className="text-theme text-nunito p-0"
              color="link"
              id={`toggler${index}`}
              style={{ fontSize: "14px", fontWeight: "800" }}
            >
              Lihat Penjelasan <i className="fa fa-chevron-right"></i>
            </Button>
            <UncontrolledCollapse toggler={`#toggler${index}`}>
              <div
                className="ml-3 mt-2 bg-light p-3 rounded text-dark"
                style={{ fontWeight: "600", fontSize: "14px" }}
              >
                {data.correct_answer}. {data.discussion}
              </div>
            </UncontrolledCollapse>
          </div>
        </div>
      ))}
      <div className="text-center mb-5">
        <Link to={`/admin/detail-pelatihan?id=${id}&status=${status}`}>
          <Button
            className="text-theme text-nunito"
            color="link"
            style={{ fontSize: "14px", fontWeight: "800" }}
          >
            <i className="fa fa-chevron-left"></i> Kembali ke Detail Pelatihan
          </Button>
        </Link>
      </div>
    </>
  );
};

export default PelatihanKuisView;
