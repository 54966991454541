import React, {useState, useEffect} from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useHistory, useLocation } from "react-router-dom";
import { CompanyIcon } from '../../../component/common/constant/menu';
import { ToastContainer, toast } from 'react-toastify';
import learning_dummy from '../../../assets/images/learning-dummy-image.png';
import API from "../../../api";

const TambahPelatihanPeserta = () => {
  let query = new URLSearchParams(useLocation().search);
  const course_id = query.get("course");

  const history = useHistory();
  const {state} = useLocation();
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState({});
  const [foundParticipants, setFoundParticipant] = useState([]);
  const [participant, setParticipant] = useState([]);
  const [query_search, setQuerySearch] = useState('');
  const [courseSearch, setCourseSearch] = useState('');
  const [isSearchingCourses, setIsSearchingCourses] = useState(false);
  const [isAddingParticipant, setIsSearchingParticipant] = useState(false);

   useEffect(() => {
       if(state)
           setQuerySearch(state.nama);

       if(course_id){
           API.adminc.course.course_detail(course_id)
               .then(({data}) => {
                   setCourses([data]);
                   setCourse(data);
               });
       }
   }, []);


  const handleSearchCourse = () => {
    setIsSearchingCourses(true);
    if(courseSearch !== ''){
      API.adminc.course.search_course({s: courseSearch})
        .then(json => {
          console.log(json.data);
          setCourses(json.data);
          setIsSearchingCourses(false);
          if(json.data.length < 1)
              toast.error("Pelatihan Tidak Ditemukan", { position: toast.POSITION.TOP_CENTER });
        }).catch(err => {
          toast.error("Pelatihan Tidak Ditemukan", { position: toast.POSITION.TOP_CENTER });
          console.log(err);
          setIsSearchingCourses(false);
        })
    }
    else
        setIsSearchingCourses(false);
  }

  const handleSearchParticipant = (event) => {
    setIsSearchingParticipant(true);

    if(query_search !== ''){
      API.adminc.participant.search_participant({s: query_search})
        .then(json => {
          console.log(json.data);
          const peserta = [];

          json.data.map(v => {
              if(!participant.some(val => val.id === v.id))
                  peserta.push({
                      id: v.id,
                      name: v.name,
                      photo: v.photo,
                  });
          });

          console.log(peserta)
          setFoundParticipant(peserta)
          setIsSearchingParticipant(false);
        }).catch(err => {
          toast.error("Peserta Tidak Ditemukan", { position: toast.POSITION.TOP_CENTER });
          console.log(err);
          setIsSearchingParticipant(false);
        })
    }
    else
        setIsSearchingParticipant(false);
  };

  const handleAddParticipant = v => {
      let temp_participant = [...participant];
      temp_participant.push(v);

      let temp_foundParticipants = [...foundParticipants];
      temp_foundParticipants = temp_foundParticipants.filter(val => v.id !== val.id);
      temp_foundParticipants = temp_foundParticipants.filter(() => true);

      setParticipant(temp_participant);
      setFoundParticipant(temp_foundParticipants);
  };

  const handleRemoveParticipant = (index) => {
    let temp_participant = [...participant];
    let temp_foundParticipants = [...foundParticipants];

    temp_foundParticipants.unshift(temp_participant[index]);
    temp_foundParticipants = temp_foundParticipants.filter(() => true);

    delete temp_participant[index];
    temp_participant = temp_participant.filter(() => true);

    setParticipant(temp_participant);
    setFoundParticipant(temp_foundParticipants);
  }

  const handleSubmited = (e) => {
    e.preventDefault();

    let participants = []

    participant.map(v => {
      participants.push(v.id)
    })

    let user_data = JSON.stringify(participants)

    let data = new FormData()

    data.append('course_id', course.id)
    data.append('user', user_data)

    if(Object.keys(course).length === 0 )
      toast.error("Pelatihan Harus Dipilih", { position: toast.POSITION.TOP_CENTER });

    if(participants.length === 0){
      toast.error("Peserta Harus Ditambahkan", { position: toast.POSITION.TOP_CENTER });
    }else{
      API.adminc.ctp.add_ctp(data)
        .then(json => {
          toast.success("Pelatihan Berhasil Ditambah ke Peserta", { position: toast.POSITION.TOP_CENTER });
          setCourse({});
          setParticipant([]);
          setTimeout(() => {
            history.goBack();
          }, 5000)
        }).catch(err => {
          console.log(err);
          toast.error("Pelatihan Tidak Berhasil Ditambah ke Peserta", { position: toast.POSITION.TOP_CENTER });
        })
    }
  }

  const foundParticipantsView = (v, idx, select) => {
      return <div className="row" style={{cursor: select ? 'pointer' : 'default'}} onClick={() => select ? handleAddParticipant(v) : null}>
          <div className="col-3 text-center">
              <img src={v.photo.split("/")[3] ? v.photo.replace(/ /g,"%20") : require('../../../assets/images/user.png')} alt="" className="rounded-circle mr-2" style={{width: 50, height: 50, objectFit: 'cover', margin: '0 auto'}} />
          </div>
          <div className="col-7 d-flex align-items-center">
              {v.id} - {v.name}
          </div>
          <div className="col-2 text-right">
              {
                  !select &&
                  <i className="fa fa-times" style={{cursor: 'pointer'}} onClick={() => handleRemoveParticipant(idx)} />
              }
          </div>
          <div className="col-12">
              <hr style={{height: 10}}/>
          </div>
          <hr/>
      </div>
  }

  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <BreadcrumbComp title={'Tambah Peserta'}>
          <li className="breadcrumb-item">
            <Link to="/admin/tambah-pelatihan-peserta" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Peserta</li>
          <li className="breadcrumb-item">Peserta terregistrasi</li>
          <li className="breadcrumb-item">Tambah Course Ke Peserta</li>
        </BreadcrumbComp>
      </div>

      <div className='col-md-12'>
          <Link to='/admin/peserta-terregistrasi'>
            <p className='btn p-0 text-theme'>
              <i className="fa fa-arrow-left"></i> Kembali
            </p>
          </Link>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
          <div className="card-header d-flex">
              <div className='mr-auto'>
                <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>TAMBAH PELATIHAN KE PESERTA</h4>
                <span className='text-muted'>Data Peserta</span>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className="card-body pt-0">
                <p style={{textAlign : 'center'}}>Mekanisme Tambah Pelatihan ke Peserta adalah Admin C melakukan request pembelian pelatihan secara bulk kepada PT Samita Daniswara Mandiri.</p>
                <p style={{textAlign : 'justify'}}>&emsp;Admin C dapat menambahkan peserta dari perusahaannya untuk mengikuti pelatihan yang diminati. Request penambahan ini akan tampil di menu ‘Transaksi Perusahaan’. Perusahaan harus melakukan pembayaran sesuai dengan nominal yang ditunjukkan dan admin PT Samita Daniswara Mandiri harus mengkonfirmasi apabila nominal transaksi sudah dibayarkan baru pengguna dapat menggunakan pelatihan.</p>
              </div>
              <div className="mx-4">
                <div className="my-3" >
                  <label htmlFor="searchpelatihan" className='d-block'> <b> Pilih pelatihan yang ingin ditambahkan </b> </label>
                  <input type="search" placeholder="Ketikkan nama pelatihan" className="form-control w-50 d-inline" aria-label="Search" id="searchpelatihan" name="searchpelatihan" onChange={e => setCourseSearch(e.target.value)} value={courseSearch} />
                    <button className="btn btn-pill ml-3" onClick={handleSearchCourse} disabled={isSearchingCourses} >Cari</button>

                    {/*Pelatihan yang ditambah */}
                    <div style={{maxHeight: 500, overflowX: 'hidden', overflowY: 'auto', marginTop: 15}}>
                        {
                            courses.map(v => (
                                <div className="row mt-3"
                                     style={{cursor: 'pointer', backgroundColor: course.id === v.id ? '#fcffde' : ''}}
                                     onClick={() => {
                                         setCourse(v)
                                     }}
                                >
                                    <div className="col-md-3 ">
                                        <img src={v.photo.replace(/ /g, "%20")} alt="" className="my-3 w-100 "/>
                                    </div>
                                    <div className="col-md-9">
                                        <h6 className="mt-3" style={{fontWeight: '600'}}>{v.nama}</h6>

                                        <div className='d-flex'>
                                            <label>
                                                <div className={`badge text-wrap text-capitalize ${v.type === 'online' ? 'badge-primary' : v.type === 'face to face' ? 'badge-danger' : 'badge-pink'} mr-2`} >
                                                    {v.type}
                                                </div>
                                            </label>
                                            <p className='text-muted mr-3'> <span className='text-theme'><i className='fa fa-clock-o'></i></span> Durasi Pelatihan <span className='text-dark font-weight-bold'>{v.day_duration} hari</span></p>
                                        </div>
                                        <p className="text-muted my-0">Kategori <b>{v.category}</b></p>
                                        <p className="text-muted">Dibuat oleh <b>{v.created_by}</b></p>
                                        <div className='d-flex'>
                                            {
                                                v.type === 'Virtual Class' &&
                                                <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-play-circle-o'></i></span>&nbsp; {v.count_meeting} Virtual Class</p>
                                            }
                                            {
                                                v.type === 'Face to Face' &&
                                                <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-play-circle-o'></i></span>&nbsp; {v.count_meeting} Tatap Muka</p>
                                            }
                                            <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa fa-play-circle-o'></i></span>&nbsp; {v.count_videos} Video</p>
                                            <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa fa-file-pdf-o'></i></span>&nbsp; {v.count_ebooks} PDF</p>
                                            <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa fa-file-powerpoint-o'></i></span> &nbsp; {v.count_ppt} PPT</p>
                                            <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-pencil-square-o'></i></span>&nbsp; {v.count_quiz} Kuis</p>
                                            <p className='text-muted mr-4'> <span className='text-theme'><i className='fa fa-clipboard'></i></span>&nbsp; {v.count_final} Ujian Final</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                </div>
                <div className="my-4">
                  <label htmlFor="searchPeserta" className='d-block'> <b> Peserta yang ingin ditambahkan course </b> </label>
                  <input
                      type="search"
                      placeholder="Ketikkan nama peserta"
                      className="form-control w-50 d-inline"
                      aria-label="Search"
                      id="searchPeserta"
                      name="searchpeserta"
                      value={query_search}
                      onChange={(e) => setQuerySearch(e.target.value)}
                  />
                 <button
                     className="btn btn-pill ml-3"
                     onClick={handleSearchParticipant}
                     disabled={isAddingParticipant}
                 >Cari</button>
                </div>
                  {/* JS ke nama" orang */}
                  <div className="row">
                      <div className="col-6">
                          <div className="row">
                              <div className="col-12 mb-2">
                                  {foundParticipants.length > 0 && <p>Hasil pencarian :</p>}
                              </div>
                              <div className='col-12' style={{overflowY: "auto", overflowX: 'hidden', maxHeight: 400}}>
                                  {
                                      foundParticipants.map((v, idx) => foundParticipantsView(v, idx, true))
                                  }
                              </div>
                          </div>
                      </div>

                      <div className="col-6">
                          <div className="row">
                              <div className="col-12 mb-2">
                                  {participant.length > 0 && <p>Peserta yang ditambahkan : </p>}
                              </div>
                              <div className='col-12' style={{overflowY: "auto", overflowX: 'hidden', maxHeight: 400}}>
                                  {
                                      participant.map((v, idx) => foundParticipantsView(v, idx, false))
                                  }
                              </div>
                          </div>
                      </div>


                  </div>

                <div className="col-md-12 m-5">
                    <div className="text-right mb-5">
                    <button type="cancel" className="btn btn-outline-primary btn-pill mr-2">Batal</button>
                    <button className="btn btn-primary btn-pill" onClick={handleSubmited}>Masukkan Peserta ke Pelatihan</button>
                    </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
      </div>
    </>
  )
}

export default TambahPelatihanPeserta;
