import Get from "../Get";
import Post from "../Post";
const list_pelatihan_pengenalan = (data) =>
  Get(
    `super-admin/intro-courses?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const insert_pelatihan_pengenalan = (data) =>
  Post(`super-admin/intro-courses`, data);
const SuperadminIntroCourses = {
  list_pelatihan_pengenalan,
  insert_pelatihan_pengenalan,
};

export default SuperadminIntroCourses;
