import Get from "../Get";
import Post from "../Post";
import Delete from "../Delete";
const list_pelatihan = (data) =>
  Get(
    `super-admin/courses?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const list_pelatihan_global = (data) =>
    Get(
        `super-admin/courses/global?${
        data &&
        Object.keys(data)
            .map((key) => key + "=" + data[key])
            .join("&")
            }`
    );

const insert_pelatihan = (data) => Post(`super-admin/courses`, data);
const update_pelatihan = (id, data) => Post(`super-admin/courses/${id}`, data);
const hapus = (id, data) => Delete(`super-admin/courses/${id}`, data);
const check_certificate = (data) =>
  Post(`super-admin/courses/check-certificate`, data);
const course_theory = (id) => Get(`super-admin/courses/${id}/theories`);
const detail_pelatihan = (id) => Get(`super-admin/courses/${id}`);
const progress_pelatihan = (id) => Get(`super-admin/courses/progress/${id}`);
const search_pelatihan = (data) => Get(`admin-c/courses/search?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);


const SuperadminCourses = {
  list_pelatihan,
  list_pelatihan_global,
  check_certificate,
  course_theory,
  detail_pelatihan,
  progress_pelatihan,
  insert_pelatihan,
  update_pelatihan,
  search_pelatihan,
  hapus
};

export default SuperadminCourses;
