import React, { useState, useEffect } from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link } from 'react-router-dom'
import { Layers } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';

import laptop_promo from '../../../assets/images/laptop_promo.png';
import bg_promo from '../../../assets/images/bg_promo.png';
import PelatihanAktif from './pelatihanAktif';
import PelatihanTidakAktif from './pelatihanTidakAktif';
import API from "../../../api";
import moment from 'moment';

const PelatihanSaya = () => {
  const [PelatihanIntro, setPelatihanIntro] = useState(false);

  const PelatihanItems = [
    {
      item_judul: 'Strategi Mengelola Bisnis dari Rumah Sendiri',
      item_desc: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti. Velit lobortis amet, id aliquam...',
      item_bg: 'https://cdn.mos.cms.futurecdn.net/6t8Zh249QiFmVnkQdCCtHK.jpg',
      progress_item: 0,
      user_image: 'https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg',
      user_name: 'Tanto Haryanto',
      user_company: 'PT Berkah Nusantara ',
      online: true,
      status: 'online'
    },
    {
      item_judul: 'Strategi Mengelola Bisnis dari Rumah Sendiri',
      item_desc: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti. Velit lobortis amet, id aliquam...',
      item_bg: 'https://cdn.mos.cms.futurecdn.net/6t8Zh249QiFmVnkQdCCtHK.jpg',
      progress_item: 85,
      user_image: 'https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg',
      user_name: 'Tanto Haryanto',
      user_company: 'PT Berkah Nusantara ',
      online: true,
      status: 'Face to Face'
    },
    {
      item_judul: 'Strategi Mengelola Bisnis dari Rumah Sendiri',
      item_desc: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti. Velit lobortis amet, id aliquam...',
      item_bg: 'https://cdn.mos.cms.futurecdn.net/6t8Zh249QiFmVnkQdCCtHK.jpg',
      progress_item: 85,
      user_image: 'https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg',
      user_name: 'Tanto Haryanto',
      user_company: 'PT Berkah Nusantara ',
      online: true,
      status: 'Virtual Class'
    }
  ];

  const PelatihanItems3 = [
    {
      item_judul: 'How to be the King of Public Speaking ',
      item_desc: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Velit lobortis amet, id aliquam...',
      item_bg: 'https://cdn.mos.cms.futurecdn.net/6t8Zh249QiFmVnkQdCCtHK.jpg',
      user_image: 'https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg',
      user_name: 'Tanto Haryanto',
      user_role: 'PT Berkah Nusantara ',
      status:'Kedaluarsa',
      online: true
    },
    {
      item_judul: 'How to be the King of Public Speaking ',
      item_desc: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Velit lobortis amet, id aliquam...',
      item_bg: 'https://cdn.mos.cms.futurecdn.net/6t8Zh249QiFmVnkQdCCtHK.jpg',
      user_image: 'https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg',
      user_name: 'Tanto Haryanto',
      user_role: 'PT Berkah Nusantara ',
      status:'Pelatihan Selesai',
    },
    {
      item_judul: 'How to be the King of Public Speaking ',
      item_desc: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Velit lobortis amet, id aliquam...',
      item_bg: 'https://cdn.mos.cms.futurecdn.net/6t8Zh249QiFmVnkQdCCtHK.jpg',
      user_image: 'https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg',
      user_name: 'Tanto Haryanto',
      user_role: 'PT Berkah Nusantara ',
      status:'Pelatihan Gagal',
    },
  ];

  const [active_courses, setActiveCourse] = useState([]);
  const [inactive_courses, setInactiveCourse] = useState([]);

  useEffect(() => {
    API.peserta.course.my_course()
        .then(json => {
          let courses = [];

          json.data.data.map(val => {
            let course = val.course_id;
            courses.push({
              id: val.id,
              course_id: course.id,
              item_judul: course.name,
              item_desc: course.description,
              item_bg: course.thumbnail.replace(/ /g, '%20'),
              progress_item: parseFloat(val.progress),
              user_image: course.trainer_id.profile_picture,
              user_name: course.trainer_id.name,
              user_company: course.company_id.name,
              online: val.status === 'Berjalan' && moment().isBefore(val.due_date) ? 'online' : '',
              status: moment().isBefore(val.due_date) && val.status !== 'Selesai' ? val.status : 'Kedaluarsa',
              type: course.type
            });
          });

          let active = courses.filter(val => {
            return val.online === 'online';
          });

          let inactive = courses.filter(val => {
            return val.online !== 'online';
          });

          setActiveCourse(active);
          setInactiveCourse(inactive);

        });
  }, []);

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Pelatihan Saya'}
          menuRight={
            <>
              <Link to='/cek-sertifikat'>
                <button className="btn btn-outline-warning btn-sm bg-white mr-3" type="button">
                  <label className='d-flex align-items-center mb-0'>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.25 17.501H4.5V20.501H5.25C5.3895 20.501 5.5005 20.3915 5.5005 20.2505V17.75C5.5005 17.6105 5.3895 17.501 5.25 17.501Z" fill="#FFD200"/>
                      <path d="M10.2492 17.501H9.74972C9.61022 17.501 9.49922 17.6105 9.49922 17.7515V20.2505C9.49922 20.39 9.60872 20.501 9.74972 20.501H10.2492C10.3887 20.501 10.4997 20.3915 10.4997 20.2505V17.75C10.4997 17.6105 10.3902 17.501 10.2492 17.501Z" fill="#FFD200"/>
                      <path d="M19.29 12.03C18.4995 12.33 17.64 12.4995 16.7505 12.4995C12.75 12.4995 9.501 9.249 9.501 5.25C9.501 3.1905 10.3605 1.32 11.751 0H2.751C1.23 0 0 1.23 0 2.7495V21.249C0 22.77 1.23 24 2.7495 24H17.25C18.7695 24 19.9995 22.77 19.9995 21.2505V12.741L19.29 12.03ZM7.0005 20.25C7.0005 21.21 6.21 22.0005 5.25 22.0005H3.75C3.3405 22.0005 3 21.66 3 21.2505V16.7505C3 16.341 3.3405 16.0005 3.75 16.0005H5.25C6.21 16.0005 7.0005 16.791 7.0005 17.751V20.25ZM12 20.25C12 21.21 11.2095 22.0005 10.2495 22.0005H9.75C8.79 22.0005 7.9995 21.21 7.9995 20.25V17.7495C7.9995 16.7895 8.79 15.999 9.75 15.999H10.2495C11.2095 15.999 12 16.7895 12 17.7495V20.25ZM14.7495 20.5005H16.2495C16.6635 20.5005 16.9995 20.8365 16.9995 21.2505C16.9995 21.6645 16.6635 22.0005 16.2495 22.0005H14.7495C13.785 22.0005 12.999 21.216 12.999 20.25V17.7495C12.999 16.785 13.7835 15.999 14.7495 15.999H16.2495C16.6635 15.999 16.9995 16.335 16.9995 16.749C16.9995 17.163 16.6635 17.499 16.2495 17.499H14.7495C14.6115 17.499 14.499 17.6115 14.499 17.7495V20.25C14.5005 20.388 14.613 20.5005 14.7495 20.5005Z" fill="#FFD200"/>
                      <path d="M16.75 10.5C13.855 10.5 11.5 8.145 11.5 5.25C11.5 2.355 13.855 0 16.75 0C19.645 0 22 2.355 22 5.25C22 8.145 19.645 10.5 16.75 10.5ZM16.75 1.9995C14.9575 1.9995 13.4995 3.4575 13.4995 5.25C13.4995 7.0425 14.9575 8.5005 16.75 8.5005C18.5425 8.5005 20.0005 7.0425 20.0005 5.25C20.0005 3.4575 18.541 1.9995 16.75 1.9995Z" fill="#FFE777"/>
                      <path d="M22.9995 12.499C22.743 12.499 22.488 12.4015 22.293 12.2065L19.0425 8.95602C18.6525 8.56602 18.6525 7.93302 19.0425 7.54152C19.4325 7.15152 20.0655 7.15152 20.457 7.54152L23.7075 10.792C24.0975 11.182 24.0975 11.815 23.7075 12.2065C23.511 12.4015 23.256 12.499 22.9995 12.499Z" fill="#FFE777"/>
                    </svg>
                    <p className='mb-0 font-weight-bold ml-2'>
                      Cek keaslian sertifikat
                    </p>
                  </label>
                </button>
              </Link>
              <Link to='/sertifikat-saya'>
                <button className="btn btn-outline-warning btn-sm bg-white mr-3" type="button">
                  <label className='d-flex align-items-center mb-0'>
                    <svg width="20" height="20" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.915 20.6468C17.6165 20.6468 22.2384 16.0248 22.2384 10.3234C22.2384 4.62193 17.6165 0 11.915 0C6.21359 0 1.59165 4.62193 1.59165 10.3234C1.59165 16.0248 6.21359 20.6468 11.915 20.6468Z" fill="#FFD15C"/>
                      <path d="M3.55202 16.3643L0 22.5214L4.14573 22.3014L6.02922 26.0018L9.31509 20.3053C6.96073 19.6962 4.93905 18.2785 3.55202 16.3643Z" fill="#40596B"/>
                      <path d="M20.298 16.334C18.9212 18.2533 16.8996 19.6762 14.5503 20.2955L17.8464 26.0022L19.7299 22.3018L23.8757 22.5219L20.298 16.334Z" fill="#40596B"/>
                      <path d="M11.9153 17.8064C16.048 17.8064 19.3981 14.4562 19.3981 10.3236C19.3981 6.19098 16.048 2.84082 11.9153 2.84082C7.7827 2.84082 4.43254 6.19098 4.43254 10.3236C4.43254 14.4562 7.7827 17.8064 11.9153 17.8064Z" fill="#F8B64C"/>
                      <path d="M17.0427 9.51447C17.273 9.28927 17.145 8.89516 16.8277 8.8491L13.8745 8.41917C13.7465 8.3987 13.6391 8.32193 13.5828 8.20421L12.2623 5.5274C12.119 5.23566 11.7044 5.23566 11.5611 5.5274L10.2457 8.20421C10.1894 8.31681 10.0768 8.3987 9.95397 8.41917L7.00077 8.8491C6.68345 8.89516 6.55549 9.28927 6.78581 9.51447L8.92009 11.5976C9.01222 11.6897 9.05317 11.8177 9.03269 11.9405L8.53111 14.8783C8.47481 15.1957 8.81261 15.4413 9.09923 15.2878L11.7402 13.9008C11.8528 13.8393 11.991 13.8393 12.1036 13.9008L14.7446 15.2878C15.0312 15.4362 15.3639 15.1957 15.3127 14.8783L14.806 11.9405C14.7855 11.8125 14.8265 11.6846 14.9186 11.5976L17.0427 9.51447Z" fill="white"/>
                    </svg>
                    <p className='mb-0 font-weight-bold ml-2'>
                    Sertifikat saya
                    </p>
                  </label>
                </button>
              </Link>
            </>
          }
        >
          <li className="breadcrumb-item">
            <Link to="/" className='text-warning'>
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Saya</li>
          <li className="breadcrumb-item">Pelatihan Saya</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card p-3">
            <div className='row theme-tab'>
              <Tabs className="col-sm-12">
                  <TabList className="tabs tab-title border-0">
                      <Tab className="current">
                        Semua pelatihan
                      </Tab>
                      <Tab>
                        Pelatihan Aktif
                      </Tab>
                      <Tab>
                        Pelatihan Tidak Aktif
                      </Tab>
                  </TabList>
                  <div className="tab-content-cls">
                      <TabPanel>
                        <div className='mb-5'>
                          <h4 className='text-muted font-weight-bold'>Pelatihan Aktif</h4>
                          <span className='text-muted'>{active_courses.length} pelatihan tersedia</span>
                          <div className='px-5 mt-3 row'>
                            {PelatihanIntro &&
                              <PelatihanAktif
                                  PelatihanIntro={false}
                                  item_judul = 'Strategi Mengelola Bisnis dari Rumah Sendiri'
                                  item_desc = ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti. Velit lobortis amet, id aliquam...'
                                  item_bg = 'https://cdn.mos.cms.futurecdn.net/6t8Zh249QiFmVnkQdCCtHK.jpg'
                                  progress_item = {0}
                                  user_image = 'https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg'
                                  user_name = 'Tanto Haryanto'
                                  user_company = 'PT Berkah Nusantara '
                                  online={true}
                              />
                            }
                            {active_courses.map((data, key) => (
                                <div className='col-md-4' key={key}>
                                    <PelatihanAktif key={key} PelatihanIntro={PelatihanIntro} {...data}/>
                                </div>
                            ))}
                          </div>
                        </div>
                        <div>
                            <h4 className='text-muted font-weight-bold'>Pelatihan Tidak Aktif</h4>
                            <span className='text-muted'>{inactive_courses.length} pelatihan tersedia</span>
                            <div className='px-5 mt-3 row'>
                              {inactive_courses.map((data, key) => (
                                <div className='col-md-4' key={key}>
                                  <PelatihanTidakAktif data={data}/>
                                </div>
                              ))}
                            </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div>
                            <h4 className='text-muted font-weight-bold'>Pelatihan Aktif</h4>
                            <span className='text-muted'>{active_courses.length} pelatihan tersedia</span>
                            <div className='px-5 mt-3 row'>
                              {PelatihanIntro &&
                              <PelatihanAktif
                                  PelatihanIntro={false}
                                  item_judul = 'Strategi Mengelola Bisnis dari Rumah Sendiri'
                                  item_desc = ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti. Velit lobortis amet, id aliquam...'
                                  item_bg = 'https://cdn.mos.cms.futurecdn.net/6t8Zh249QiFmVnkQdCCtHK.jpg'
                                  progress_item = {0}
                                  user_image = 'https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg'
                                  user_name = 'Tanto Haryanto'
                                  user_company = 'PT Berkah Nusantara '
                                  online={true}
                              />
                              }
                              {active_courses.map((data, key) => (
                                <div className='col-md-4' key={key}>
                                    <PelatihanAktif key={key} PelatihanIntro={PelatihanIntro} {...data}/>
                                </div>
                              ))}
                            </div>
                          </div>
                      </TabPanel>
                      <TabPanel >
                        <div>
                            <h4 className='text-muted font-weight-bold'>Pelatihan Tidak Aktif</h4>
                            <span className='text-muted'>{inactive_courses.length} pelatihan tersedia</span>
                            <div className='px-5 mt-3 row'>
                              {inactive_courses.map((data, key) => (
                                <div className='col-md-4' key={key}>
                                  <PelatihanTidakAktif data={data}/>
                                </div>
                              ))}
                            </div>
                        </div>
                      </TabPanel>
                  </div>
              </Tabs>
            </div>
          </div>
          <div className='card shadow-0' style={{background: `url(${bg_promo})`, minHeight: '16rem', backgroundSize:'100%', boxShadow:'0 !important', backgroundColor: '#FFEA2D'}}>
            <div className="card-body d-flex p-0">
              <div className='my-auto ml-4'>
                <h1 className='font-weight-bold text-nunito'>Belajar dari mana saja, kondisi apa saja!</h1>
                <p>Mulai perkembangan Anda dengan bergabung dengan pelatihan yang sesuai dengan Anda</p>

                <Link to='pelatihan-lainnya'>
                <button className="btn btn-pill btn-primary btn-sm mt-3" type="button">Temukan Pelatihan Lainnya</button>
                </Link>
              </div>
              <img className='ml-auto mt-auto mr-4 w-25' src={laptop_promo} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PelatihanSaya;
