import React, { useState, useEffect } from "react";
import { Users } from "react-feather";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation, useHistory } from "react-router-dom";
import tambah_bulk from "../../../assets/images/tambah-bulk.png";
import { ToastContainer, toast } from "react-toastify";
import API from "../../../api";

const TambahBulk = () => {
  let history = useHistory();
  const [tambahBerhasil, settambahBerhasil] = useState(false);
  const [bulk, setBulk] = useState("");
  const [participant, setParticipant] = useState([]);

  const handleuploadBulk = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setBulk(file);

    reader.readAsDataURL(file);
  };

  const handleSubmited = (event) => {
    event.preventDefault();

    API.superadmin.participant
      .insert_bulk_participant({ file: bulk })
      .then((json) => {
        setParticipant(json.data);
        settambahBerhasil(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Import Bulk Peserta Tidak Berhasil Dilakukan", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleBackToListAndToast = () => {
    localStorage.setItem("toast", "Berhasil menambahkan 3 peserta baru");
    history.push("/superadmin/peserta-terregistrasi");
  };

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"Peserta"}>
          <li className="breadcrumb-item">
            <Link to="/ketua/list-pelatihan" className="text-warning">
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Pelatihan</li>
          <li className="breadcrumb-item">Tambah Bulk</li>
        </BreadcrumbComp>
      </div>
      {tambahBerhasil ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card text-center py-5">
              <div className="d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src={tambah_bulk}
                  alt=""
                  style={{ width: "97px" }}
                />
              </div>
              <h5
                className="text-work text-theme"
                style={{ fontSize: "24px", fontWeight: "600" }}
              >
                Tambah Bulk Peserta Berhasil
              </h5>
              <p className="text-muted">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <br />
                Sed aliquet et lectus turpis in mattis vitae. Magna viverra
                mauris sit{" "}
              </p>
              <button
                className="btn btn-link"
                onClick={handleBackToListAndToast}
              >
                Kembali ke List Peserta
              </button>
            </div>
            <div className="card" style={{ width: "602px" }}>
              <div className="card-header pb-3 d-flex align-items-center">
                <div className="mr-auto">
                  <h5
                    className="text-work text-theme"
                    style={{ fontSize: "24px", fontWeight: "600" }}
                  >
                    PESERTA BARU YANG DITAMBAHKAN
                  </h5>
                </div>
              </div>
              <div className="card-body">
                <h6
                  className="text-muted"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  Nama Peserta
                </h6>
                <div className="d-flex align-items-center mb-2">
                  <div>
                    <img
                      className="img-40 rounded-circle"
                      src="https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg"
                      alt="#"
                    ></img>
                  </div>
                  <label className="mb-0 ml-2">
                    <p className="font-weight-bold">Nama Peserta</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header pb-3 d-flex align-items-center">
                <div className="mr-auto">
                  <h5
                    className="text-work text-theme"
                    style={{ fontSize: "24px", fontWeight: "600" }}
                  >
                    TAMBAH BULK PESERTA
                  </h5>
                  <span className="text-muted">Data Peserta</span>
                </div>
              </div>
              <div className="card-body">
                <h6 className="text-muted mb-3" style={{ fontSize: "14px" }}>
                  Anda dapat menambahkan peserta secara banyak (bulk) dengan
                  melakukan upload dokumen berekstensi .csv yang telah sesuai
                  dengan format yang disediakan.
                </h6>
                <div>
                  <button className="btn btn-success btn-sm" type="button">
                    {" "}
                    <i className="fa fa-download"></i> &nbsp;Download Template
                    Bulk Registrasi
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-primary btn-sm mt-3" id="upload-bulk"
                    type="button"
                  >
                    {" "}
                    <i className="fa fa-upload"></i> &nbsp;Upload List Peserta
                    (.csv)
                  <input type="file" onChange={handleuploadBulk} />
                  </button>
                </div>
                <div className="border-top mt-5 pt-5">
                  <div className="text-right mb-5">
                    <Link to="/superadmin/peserta-terregistrasi">
                      <button
                        type="cancel"
                        className="btn btn-outline-primary btn-pill mr-2"
                        type="button"
                      >
                        Batal
                      </button>
                    </Link>
                    <button
                      type="submit"
                      className="btn btn-primary btn-pill"
                      onClick={handleSubmited}
                    >
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TambahBulk;
