import Get from "../Get";
import Post from "../Post";

const list_chat = () => Get(`peserta/chat/list_chat`);
const list_contact = () => Get(`peserta/chat/list_contact`);
const get_chat = id => Get(`peserta/chat/get_chat/${id}`);
const send_chat = (id, data) => Post(`peserta/chat/send_chat/${id}`, data);
const change_status = (id) => Post(`peserta/chat/change_status/${id}`);

const ChatPeserta = {
    list_chat,
    list_contact,
    get_chat,
    send_chat,
    change_status,
};

export default ChatPeserta;
