import Get from "../Get";

const listParticipant = (data) => Get(`ketua-klien/participants?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const participant_detail = id => Get(`ketua-klien/participants/${id}`);

const ListParticipant = {
    listParticipant,
    participant_detail
};

export default ListParticipant;