import React from "react";
import virtualclass from "../../../../assets/images/virtualclass .png";
import { Link } from "react-router-dom";

const PelatihanVirtualClassView = (props) => {
  const { theory } = props;
  return (
    <div className="card p-5">
      <div className="text-center p-4">
        <img
          className="img-fluid mb-5"
          src={virtualclass}
          alt="Omind Tech"
          style={{ width: "112.73px" }}
        />
        <div className="text-left">
          <h6 style={{ fontWeight: "600", fontSize: "20px" }}>{theory.name}</h6>
          <p className="text-success" style={{ fontSize: "14px" }}>
            {theory.start} - {theory.end} WIB &nbsp; &nbsp; {theory.date}
          </p>
          <p className="text-muted" style={{ fontSize: "12px" }}>
            Posuere mi, vulputate odio mus. Arcu sit semper scelerisque morbi
            amet eget platea risus. Nulla ante diam mauris massa consectetur
            semper duis imperdiet semper. Arcu sit semper scelerisque morb.
          </p>
        </div>
        <p className="text-muted" style={{ fontSize: "12px" }}>
          Masukkan Password berikut untuk Join ke Virtual Class
        </p>
        <h6 className="text-theme" style={{ fontSize: "24px" }}>
          {theory.vc_password}
        </h6>
        <Link to={theory.vc_link}>
          <button
            className="btn btn-pill btn-primary mt-3 font-weight-bold"
            type="button"
          >
            {" "}
            Join Virtual Class{" "}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PelatihanVirtualClassView;
