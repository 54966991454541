import React, { useState, useEffect } from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link, useHistory } from "react-router-dom";
import { User } from 'react-feather';
import API from "../../../api";
import swal from 'sweetalert2';

const ProfilSaya = () => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('data'));
  const [url, setUrl] = useState();
  const [terverifikasi, setterverifikasi] = useState(true)
  const [imagePreview, setimagePreview] = useState(null);
  const [imagePreviewUrl, setimagePreviewUrl] = useState('');
  const [ubahEmail, setubahEmail] = useState(true)
  const [ubahHp, setubahHp] = useState(true)
  const [profession, setProfession] = useState('');
  const [isChangePassword, setIsChangePassword] = useState(false);

  const [DataAkun, setDataAkun] = useState(null)
  const [labelNama, setLabelNama] = useState(null)
  const [isSubmittingProfile, setIsSubmittingProfile] = useState(false);
  const [isSubmittingAccount, setIsSubmittingAccount] = useState(false);

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setimagePreviewUrl(reader.result);
    }

    reader.readAsDataURL(file)
  }

  const updateProfile = () => {
    setIsSubmittingProfile(true);
    API.ketua.profile.update_profile(DataAkun)
        .then(json => {
          setIsSubmittingProfile(false);
          swal.fire('Sukses', 'Berhasil update profil', 'success')
              .then(res => {
                json.data.profile_picture = json.data.photo;
                let newUser = {
                  ...user,
                  ...json.data
                };

                localStorage.setItem('data', JSON.stringify(newUser));
                history.push('/ketua');
              });
        }).catch(e => {
          swal.fire('Gagal', 'Gagal update profil', 'error');
          setIsSubmittingProfile(false);
        });
  };

  const updateAccount = () => {
    setIsSubmittingAccount(true);

    if(DataAkun.password === DataAkun.confirm_password)
      API.ketua.profile.update_account(DataAkun)
          .then(json => {
            setIsSubmittingAccount(false);
            swal.fire('Sukses', 'Berhasil update akun', 'success')
                .then(res => {
                  json.data.profile_picture = json.data.photo;
                  let newUser = {
                    ...user,
                    ...json.data
                  };

                  localStorage.setItem('data', JSON.stringify(newUser));
                  history.push('/ketua');
                });
          }).catch(e => {
        swal.fire('Gagal', 'Gagal update akun', 'error');
        setIsSubmittingAccount(false);
      });
      else {
        setIsSubmittingAccount(false);
        swal.fire('Error', 'Konfirmasi password tidak sama', 'error')
      }
  }

  useEffect(() => {
    if (imagePreviewUrl) {
      setimagePreview(<img className="w-100" src={imagePreviewUrl} alt='Global English' />)
    } else {
      setimagePreview(<div className=""></div>);
    }
  }, [imagePreviewUrl])

  const readUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result)
    }
  }

  useEffect(() => {
    API.ketua.profile.my_profile()
    .then(json => {
      let profile = json.data
      setDataAkun(profile)
      setLabelNama(profile.name)
    })

    API.ketua.profession.get_profession()
      .then(json => {
        let profesi = {...profession}
        profesi.data = []

        json.data.map(v => {
          profesi.data.push({
            id: v.id,
            name: v.name,
          })
        })
        setProfession(profesi)
      }).catch(err => {
        console.log(err);
      })
  }, [])

  return (
    DataAkun &&
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Profil Saya'}>
          <li className="breadcrumb-item">
            <Link to="/ketua/profil-saya" className='text-warning'>
              <User />
            </Link>
          </li>
          <li className="breadcrumb-item">Profil Saya</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className='col-md-5'>
          <div className="card">
            <div className='card-header'>
              <h6 style={{ fontSize: '24px', fontWeight: 'normal' }}>Akun Saya</h6>
            </div>
            <div className='card-body'>
              <div className='d-flex align-items-center user-profile'>
                <div className="user-image">
                  <div className="avatar ">
                    <img className="img-70 rounded-circle" style={{height: 70, objectFit: 'cover'}} alt="" src={DataAkun.photo} data-intro="This is Profile image" />
                  </div>
                  {/* <div className="icon-wrapper">
                      <i className="icofont icofont-pencil-alt-5" data-intro="Change Profile image here" >
                          <input className="pencil" type="file" onChange={(e) => readUrl(e)} />
                      </i>
                  </div> */}
                </div>
                <label className='mb-0 ml-4'>
                  <h6 className='mb-0' style={{ fontSize: '24px', fontWeight: 'normal' }}>{labelNama}</h6>
                  <h6 className='mb-0' style={{ fontSize: '18px', fontWeight: '600' }}>Ketua Klien</h6>
                  <p className='mb-0'>{DataAkun.company_name}</p>
                </label>
              </div>
              <form className="form theme-form mt-4">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1" className='text-work d-flex' style={{fontSize:'16px'}}>Email {terverifikasi && <span className='ml-auto text-success'><i className="fa fa-check-circle-o"></i> &nbsp;Terverifikasi</span>}</label>
                      {ubahEmail ?
                        <label htmlFor="exampleFormControlInput1" className='text-work d-flex' style={{fontSize:'16px'}}>{DataAkun.email} <a className='ml-auto btn p-0 txt-primary'onClick={()=>setubahEmail(!ubahEmail)}><i className="fa fa-pencil"></i> <u>Ubah Email</u></a></label>
                      :
                        <input className="form-control text-dark" type="email" placeholder="name@example.com" defaultValue={DataAkun.email} onChange={e => setDataAkun({
                          ...DataAkun, email : e.target.value
                        })}  />
                      }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1" className='text-work d-flex' style={{fontSize:'16px'}}>Nomor Telepon <a className='ml-auto btn p-0 txt-primary'onClick={()=>setubahEmail(!ubahEmail)}><u>Verifkasikan nomor telepon</u></a></label>
                      {ubahHp ?
                        <label htmlFor="exampleFormControlInput1" className='text-work d-flex' style={{fontSize:'16px'}}>{DataAkun.phone} <a className='ml-auto btn p-0 txt-primary'onClick={()=>setubahHp(!ubahHp)}><u>Ubah</u></a></label>
                      :
                        <input className="form-control text-dark" type="number" defaultValue={DataAkun.phone} onChange={e => setDataAkun({
                          ...DataAkun, phone_number : e.target.value
                        })} />
                      }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="defaultCheck1" onClick={() => setIsChangePassword(!isChangePassword)} />
                      <label className="form-check-label" htmlFor="defaultCheck1">
                        Ganti Password
                      </label>
                    </div>
                  </div>
                </div>
                {
                  isChangePassword &&
                    <>
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>Password</label>
                            <input className="form-control text-dark" type="password" onChange={e => setDataAkun({
                              ...DataAkun, password : e.target.value
                            })} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>Konfirmasi Password</label>
                            <input className="form-control text-dark" type="password" onChange={e => setDataAkun({
                              ...DataAkun, confirm_password : e.target.value
                            })} />
                          </div>
                        </div>
                      </div>
                    </>
                }

                <button className="mr-3 btn btn-pill btn-primary btn-sm mt-3 w-100 text-work" type="button" style={{fontSize:'16px', fontWeight:'600'}} disabled={isSubmittingAccount} onClick={updateAccount}>Simpan</button>
              </form>
            </div>
          </div>
        </div>
        <div className='col-md-7'>
          <div className="card">
            <div className='card-header'>
              <h6 style={{ fontSize: '24px', fontWeight: 'normal' }}>Ubah Data Profil</h6>
            </div>
            <div className='card-body'>
              <form className="form theme-form">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>Nama</label>
                      <input className="form-control text-dark" value={DataAkun.name} onChange={e => setDataAkun({
                        ...DataAkun, name : e.target.value
                      })}/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>Jabatan</label>
                      <select required
                          className="form-control"
                          name="profession_id"
                          onChange={e => setDataAkun({
                            ...DataAkun, profession_id : e.target.value
                          })}
                          defaultValue={DataAkun.profession_real_id}
                        >
                          <option disabled selected>Position</option>
                          {
                            profession &&
                            profession.data.map(v => {
                              return <option value={v.id}>{v.name}</option>
                            })
                          }
                      </select>
                    </div>
                  </div>
                </div>
                <button className="mr-3 btn btn-pill btn-primary btn-sm mt-3 w-100 text-work" type="button" style={{fontSize:'16px', fontWeight:'600'}} disabled={isSubmittingProfile} onClick={updateProfile}>Update Profil</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfilSaya;
