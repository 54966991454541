import React, { useState, useEffect, useRef } from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link,useHistory } from "react-router-dom";
import {Edit, User} from 'react-feather';
import API from "../../../api";
import swal from 'sweetalert2';
import {titleCase} from "../../../helper";

const ProfileSaya = () => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('data'));
  const [url, setUrl] = useState(null);
  const [terverifikasi, setterverifikasi] = useState(true)
  const [imagePreview, setimagePreview] = useState(null);
  const [imagePreviewUrl, setimagePreviewUrl] = useState('');
  const [ubahEmail, setubahEmail] = useState(true)
  const [ubahHp, setubahHp] = useState(true)
  const [newPassword, setNewPassword] = useState('');
  const [isAccountSubmitting, setIsAccountSubmitting] = useState(false);
  const [isProfileSubmitting, setIsProfileSubmitting] = useState(false);
  const [professions, setProfessions] = useState([]);
  const [isChangePassword, setIsChangePassword] = useState(false);

  const [DataAkun, setDataAkun] = useState(null);
  const [newAccountData, setNewAccountData] = useState({});
  const [newProfileData, setNewProfileData] = useState({});
  const photoRef = useRef(null);

  const handleAccountData = e => {
    setNewAccountData({
      ...newAccountData,
      [e.target.name]: e.target.value
    })
  };

  const handleProfileData = e => {
    setNewProfileData({
      ...newProfileData,
      [e.target.name]: e.target.value
    })
  };

  const handleSubmitAccount = () => {
    setIsAccountSubmitting(true);
    if(newAccountData.password){
      if(newAccountData.password !== newPassword){
        swal.fire('Error', 'Konfirmasi password salah', 'error');
        setIsAccountSubmitting(false);
        return
      }
    }

    API.superadmin.profile.update_account(newAccountData)
        .then(json => {
          swal.fire('Sukses', 'Ubah akun sukses', 'success')
            .then(res => {
              json.data.profile_picture = json.data.photo;
              let newUser = {
                ...user,
                ...json.data
              };

              localStorage.setItem('data', JSON.stringify(newUser));
              history.push('/superadmin');
            });
        }).catch(e => {
          swal.fire('Error', e.message, 'error');
          setIsAccountSubmitting(false);
        })
  };

  const handleSubmitProfile = () => {
    setIsProfileSubmitting(true);

    API.superadmin.profile.update_profile(newProfileData)
        .then(json => {
          setIsProfileSubmitting(false);
          swal.fire('Sukses', 'Ubah profil sukses', 'success')
              .then(res => {
                let newUser = {
                  ...user,
                  ...json.data
                };

                localStorage.setItem('data', JSON.stringify(newUser));
                history.push('/superadmin');
              });
        }).catch(e => {
          swal.fire('Error', e.message, 'error');
          setIsProfileSubmitting(false);
        })
  }

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setimagePreviewUrl(reader.result);
    }

    reader.readAsDataURL(file)
  }

  useEffect(() => {
    if (imagePreviewUrl) {
      setimagePreview(<img className="w-100" src={imagePreviewUrl} alt='Global English' />)
    } else {
      setimagePreview(<div className=""></div>);
    }
  }, [imagePreviewUrl])

  const readUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    let file = event.target.files[0];

    setNewAccountData({
      ...newAccountData,
      [event.target.name]: file
    })

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result)
    }
  }

  useEffect(() => {
    API.superadmin.profile.my_profile()
    .then(json => {
      let profile = json.data;

      if(!profile.photo.split('/')[3])
        profile.photo = require('../../../assets/images/user.png');

      setDataAkun(profile)
      API.superadmin.profile.professions()
          .then(json => {
            setProfessions(json.data);
          })

    })
  }, []);

  return (
    DataAkun &&
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Profil Saya'}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/profile-saya" className='text-warning'>
              <User />
            </Link>
          </li>
          <li className="breadcrumb-item">Profil Saya</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className='col-md-5'>
          <div className="card">
            <div className='card-header'>
              <h6 style={{ fontSize: '24px', fontWeight: 'normal' }}>Akun Saya</h6>
            </div>
            <div className='card-body'>
              <div className='d-flex align-items-center user-profile'>
                <div className="user-image">
                  <div className="avatar ">
                    <img className="img-70 rounded-circle" alt="" style={{height: 70, objectFit: 'cover'}} src={url ? url : DataAkun.photo} data-intro="This is Profile image" />
                    <div className="profile-edit">
                      <Link onClick={e => {
                        e.stopPropagation();
                        photoRef.current.click();
                      }} style={{
                        position: 'relative',
                        bottom: 28,
                        right: -45,
                        padding: 5,
                        background: '#fff',
                        borderRadius: 30,
                        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.13)',
                        display: 'inline-block',
                        alignItems:'center'}}>
                        <Edit width='18' height='18' />
                      </Link>
                      <input
                          type="file"
                          name='profile_picture'
                          ref={photoRef}
                          onChange={readUrl}
                          style={{display: 'none'}}
                      />
                    </div>
                  </div>
                </div>
                <label className='mb-0 ml-4'>
                  <h6 className='mb-0' style={{ fontSize: '24px', fontWeight: 'normal' }}>{DataAkun.name}</h6>
                  <h6 className='mb-0' style={{ fontSize: '18px', fontWeight: '600' }}>Super Admin</h6>
                  <p className='mb-0'>{DataAkun.company_name}</p>
                </label>
              </div>
              <form className="form theme-form mt-4">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1" className='text-work d-flex' style={{fontSize:'16px'}}>Email {terverifikasi && <span className='ml-auto text-success'><i className="fa fa-check-circle-o"></i> &nbsp;Terverifikasi</span>}</label>
                      {ubahEmail ?
                        <label htmlFor="exampleFormControlInput1" className='text-work d-flex' style={{fontSize:'16px'}}>{DataAkun.email} <a className='ml-auto btn p-0 txt-primary'onClick={()=>setubahEmail(!ubahEmail)}><i className="fa fa-pencil"></i> <u>Ubah Email</u></a></label>
                      :
                        <input className="form-control text-dark" name='email' type="email" placeholder="name@example.com" defaultValue={DataAkun.email} onChange={handleAccountData} />
                      }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput2" className='text-work d-flex' style={{fontSize:'16px'}}>Nomor Telepon</label>
                      {ubahHp ?
                        <label htmlFor="exampleFormControlInput2" className='text-work d-flex' style={{fontSize:'16px'}}>{DataAkun.phone} <a className='ml-auto btn p-0 txt-primary'onClick={()=>setubahHp(!ubahHp)}><u>Ubah</u></a></label>
                      :
                        <input className="form-control text-dark" type="number" name='phone_number' placeholder="name@example.com" defaultValue={DataAkun.phone} onChange={handleAccountData}  />
                      }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="defaultCheck1" onClick={() => setIsChangePassword(!isChangePassword)} />
                      <label className="form-check-label" htmlFor="defaultCheck1">
                        Ganti Password
                      </label>
                    </div>
                  </div>
                </div>
                {
                  isChangePassword &&
                    <>
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>Password</label>
                            <input className="form-control text-dark" name='password' type="password" onChange={handleAccountData} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>Konfirmasi Password</label>
                            <input className="form-control text-dark" type="password" onChange={e => {
                              setNewPassword(e.target.value);
                            }} />
                          </div>
                        </div>
                      </div>
                    </>
                }

                <button className="mr-3 btn btn-pill btn-primary btn-sm mt-3 w-100 text-work" type="button" style={{fontSize:'16px', fontWeight:'600'}} onClick={handleSubmitAccount} disabled={isAccountSubmitting}>Simpan</button>
              </form>
            </div>
          </div>
        </div>
        <div className='col-md-7'>
          <div className="card">
            <div className='card-header'>
              <h6 style={{ fontSize: '24px', fontWeight: 'normal' }}>Ubah Data Profil</h6>
            </div>
            <div className='card-body'>
              <form className="form theme-form">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>Nama</label>
                      <input className="form-control text-dark" name='name' defaultValue={DataAkun.name} onChange={handleProfileData}/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>Jabatan</label>
                      <select required defaultValue='default' className='form-control' name='profession_id' onChange={handleProfileData}>
                        <option selected={!DataAkun.profession_real_id} disabled>Jabatan</option>
                        {
                          professions.map(v => (
                              <option value={v.id} selected={v.id === DataAkun.profession_real_id}>{titleCase(v.name)}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <button className="mr-3 btn btn-pill btn-primary btn-sm mt-3 w-100 text-work" type="button" style={{fontSize:'16px', fontWeight:'600'}} disabled={isProfileSubmitting} onClick={handleSubmitProfile}>Update Profil</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileSaya;
