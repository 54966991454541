import React, { useState, useEffect } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation } from "react-router-dom";
import { Layers } from "react-feather";
import { Modal, ModalFooter, ModalBody } from "reactstrap";
import learning_dummy from "../../../assets/images/learning-dummy-image.png";
import API from "../../../api";
import swal from "sweetalert2";
const ListPelatihanGlobal = () => {
  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get("page") || 1;

  const [globalCourses, setGlobalCourses] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });

  const [search, setSearch] = useState('');

  const [modalConfirm, setmodalConfirm] = useState(false);
  const [modalDuplikatDipilih, setmodalDuplikatDipilih] = useState(false);

  const columnsTabel = [
    {
      dataField: "id",
      text: "ID",
      headerStyle: () => ({ width: "5%", textAlign: "left" }),
    },
    {
      dataField: "pelatihan",
      text: "Nama Pelatihan",
      headerStyle: () => ({ width: "20%", textAlign: "center" }),
    },
    {
      dataField: "jenis_pelatihan",
      text: "Jenis Pelatihan",
      headerStyle: () => ({ width: "15%", textAlign: "center" }),
    },
    {
      dataField: "nama_perusahaan",
      text: "Nama Perusahaan",
      headerStyle: () => ({ width: "20%", textAlign: "center" }),
      formatter: (cel, row) => {
        return (
          <div className="d-flex align-items-center mb-2">
            <div>
              <img class="img-40" src={row.logo} alt="#"></img>
            </div>
            <label className="mb-0 ml-2">
              <p>{row.name}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
    },
    {
      dataField: "",
      text: "Action",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      formatter: (cel, row, idx) => {
        return (
          <div className="d-flex justify-content-center">
            <Link
              to={`/superadmin/detail-pelatihan?id=${row.id}`}
              className="mx-auto"
            >
              <button
                className=" btn rounded-circle btn-success btn-xs py-1"
                type="button"
              >
                {" "}
                <i className="fa fa-eye"></i>{" "}
              </button>
            </Link>
            <Link to={`/superadmin/edit-pelatihan?id=${row.id}`} className="mx-auto">
              <button
                className=" mx-auto btn rounded-circle btn-warning btn-xs py-1"
                type="button"
              >
                {" "}
                <i className="fa fa-pencil"></i>{" "}
              </button>
            </Link>
            <button
              className=" mx-auto btn rounded-circle btn-danger btn-xs"
              type="button"
              onClick={() => deleteCourse(row.id, idx)}
            >
              {" "}
              <i className="fa fa-trash"></i>{" "}
            </button>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    API.superadmin.course
      .list_pelatihan_global({ page, per_page, search })
      .then((json) => {
        let globalCourse = { ...globalCourses };
        globalCourse.data = [];

        json.data.data.map((val) => {
          globalCourse.data.push({
            id: val.id,
            pelatihan: val.name,
            jenis_pelatihan: val.category,
            name: val.company.name,
            logo: val.company.logo,
            status: val.status,
          });
        });

        globalCourse.meta.from = (page - 1) * per_page + 1;
        globalCourse.meta.to =
          per_page > json.data.total
            ? json.data.total
            : page * per_page > json.data.total
            ? json.data.total
            : page * per_page;
        globalCourse.meta.total = json.data.total;
        globalCourse.meta.current_page = page;
        globalCourse.meta.total_page = Math.ceil(
          globalCourse.meta.total / globalCourse.meta.per_page
        );

        setGlobalCourses(globalCourse);
      });
  }, [page, search]);

  const deleteCourse = (id, idx) => {
    swal.fire({
      title: 'Anda yakin?',
      text: "Pastikan pelatihan ini belum dibeli. Pelatihan yang dihapus tidak bisa dikembalikan",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Batal',
    }).then((result) => {
      if (result.isConfirmed) {
        swal.fire({
          onBeforeOpen () {
            swal.showLoading()
          },
          onAfterClose () {
            swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });

        API.superadmin.course.hapus(id)
            .then(json => {
              swal.fire('Berhasil menghapus pelatihan!', '', 'success');

              let temp_courses = {...globalCourses};
              temp_courses.data.splice(idx, 1);
              setGlobalCourses(temp_courses);
            }).catch(e => {
          swal.fire('Gagal menghapus pelatihan!', 'Mungkin pelatihan ini sudah ada yang membeli', 'error');
        })
      }
    })
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"List Pelatihan Global"}>
          <li className="breadcrumb-item">
            <Link
              to="/superadmin/list-pelatihan-golbal"
              className="text-warning"
            >
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Pelatihan</li>
          <li className="breadcrumb-item">List Pelatihan Global</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 d-flex">
              <div className="mr-auto">
                <h4
                  className="text-theme text-work"
                  style={{ fontWeight: "600" }}
                >
                  PELATIHAN GLOBAL
                </h4>
                <div className="row mt-3">
                  <div className="col-xl-12">
                    <form className="search-form contact-search ">
                      <div className="form-group mb-0">
                        <input
                          className="form-control badge-pill"
                          type="text"
                          placeholder="Cari pelatihan ..."
                          value={search}
                          onChange={e => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className=" btn btn-pill btn-outline-primary btn-sm text-work"
                  type="button"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                    setmodalConfirm(true);
                  }}
                >
                  <i className="fa fa-pencil"></i> &nbsp;Tambah Pelatihan
                </button>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="overflow-auto w-100">
                <TableComp
                  title_paggination="pelatihan global"
                  columns={columnsTabel}
                  data={globalCourses}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalConfirm}
        toggle={() => setmodalConfirm(!modalConfirm)}
        size="md"
      >
        <ModalBody>
          <div className="text-center mt-3">
            <h6>Apakah ini pelatihan baru atau pelatihan duplikat?</h6>
            <Link to="/superadmin/tambah-pelatihan">
              <button
                className="btn btn-pill btn-primary btn-sm my-3 mr-3"
                type="button"
                onClick={() => {
                  setmodalConfirm(!modalConfirm);
                }}
              >
                Pelatihan Baru
              </button>
            </Link>
            <button
              className="btn btn-pill btn-outline-primary btn-sm my-3"
              type="button"
              onClick={() => {
                setmodalConfirm(!modalConfirm);
                setmodalDuplikatDipilih(true);
              }}
            >
              {" "}
              Pelatihan Duplikat
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalDuplikatDipilih}
        toggle={() => setmodalDuplikatDipilih(!modalDuplikatDipilih)}
        size="lg"
        style={{ maxWidth: "1050px" }}
      >
        <ModalBody>
          <div className="text-center mt-3">
            <h6>Pilih Pelatihan yang mau diduplikat</h6>
            <div className="d-flex justify-content-center">
              <div className="form-group mt-4 text-left">
                <label className="text-work">Nama Pelatihan</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Masukkan nama pelatihan"
                />
              </div>
            </div>
            <div className="row-md-12 mr-auto d-flex justify-content-center">
              <div className="col-md-5">
                <img
                  className="img-fluid w-100 mb-3"
                  src={learning_dummy}
                  alt="omind"
                />
              </div>
              <div className="col-md-5 text-left">
                <h3 className="font-weight-bold">Public Speaking King</h3>
                <div className="d-flex">
                  <label>
                    <div
                      className={`badge text-wrap text-capitalize badge-primary
                      `}
                    >
                      Online
                    </div>
                  </label>
                  <p className="text-muted mr-4 ml-2">
                    {" "}
                    <span className="text-muted">
                      <i className="fa fa-question-circle"></i>
                    </span>
                  </p>
                  <p className="text-muted mr-4">
                    {" "}
                    <span className="text-theme">
                      <i className="fa fa-clock-o"></i>
                    </span>
                    &nbsp; Durasi Pelatihan{" "}
                    <span className="text-dark font-weight-bold">270 hari</span>
                  </p>
                </div>
                <div>
                  <p className="text-muted mr-4">
                    {" "}
                    Kategori{" "}
                    <span className="text-dark font-weight-bold">
                      Komunikasi dan Interpersonal
                    </span>
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-muted mr-4">
                    {" "}
                    Dibuat Oleh{" "}
                    <span className="text-dark font-weight-bold">
                      Aang Sukarna
                    </span>
                  </p>
                </div>
                <div className="d-flex">
                  <p className="text-muted mr-1">
                    {" "}
                    <span className="text-theme">
                      <i className="fa fa-play-circle-o"></i>
                    </span>
                    &nbsp; 4 Video
                  </p>
                  <p className="text-muted mr-1">
                    {" "}
                    <span className="text-theme">
                      <i className="fa fa fa-file-pdf-o"></i>
                    </span>
                    &nbsp; 2 PDF
                  </p>
                  <p className="text-muted mr-1">
                    {" "}
                    <span className="text-theme">
                      <i className="fa fa fa-file-powerpoint-o"></i>
                    </span>{" "}
                    &nbsp;1 PPT
                  </p>
                  <p className="text-muted mr-1">
                    {" "}
                    <span className="text-theme">
                      <i className="fa fa-pencil-square-o"></i>
                    </span>
                    &nbsp; 2 Kuis
                  </p>
                  <p className="text-muted mr-1">
                    {" "}
                    <span className="text-theme">
                      <i className="fa fa-clipboard"></i>
                    </span>
                    &nbsp; 1 Ujian Final
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-pill btn-outline-primary btn-sm my-3 mr-3"
            type="button"
            onClick={() => {
              setmodalDuplikatDipilih(!modalDuplikatDipilih);
            }}
          >
            {" "}
            Batal
          </button>
          <button
            className="btn btn-pill btn-primary btn-sm my-3"
            type="button"
            onClick={() => {
              setmodalDuplikatDipilih(!modalDuplikatDipilih);
            }}
          >
            {" "}
            Duplikat Pelatihan
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default ListPelatihanGlobal;
