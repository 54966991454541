import Get from "../Get";

const list_crosspay = (data) =>
  Get(
    `admin-c/crosspay?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const crosspay_detail = (id) => Get(`admin-c/crosspay/${id}`);
const CrosspayAdminC = {
  list_crosspay,
  crosspay_detail,
};

export default CrosspayAdminC;
