import React from 'react';
import error from '../../../../../assets/images/error.png';

const HubungiTrainer = (props) => {
  const { Attempt, result } = props;

  return (
    <>
      <p className={`text-right mb-0 ml-auto mt-3 ${Attempt === 1 ? 'txt-primary' : Attempt === 2 ? 'text-warning' : 'txt-danger'}`} style={{ fontWeight: '600', fontSize: '14px' }}>Attempt {Attempt > 3 ? 3 : Attempt}/3</p>
      <div className='card p-5'>
        <div className='text-center p-4'>
          <div className='text-center mt-3'>
            <img className="img-fluid mb-4" src={error} alt="Omind Tech" style={{ width: '112.73px' }} />
            <h6 className='text-nunito' style={{ fontWeight: '800', fontSize: '18px' }}>Mohon Maaf! Anda Belum Mencapai Nilai Minimal</h6>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem a viverra tristique ornare velit orci orci. Mi purus augue at at pulvinar leo.</p>
            <hr />
            <h6 className='text-nunito' style={{ fontWeight: '800', fontSize: '18px' }}>Hasil Ujian Anda</h6>
            <div className='row mt-3'>
              <div className='col-md-3 text-center'>
                <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Benar</h6>
                <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'18px'}}>{result.correct_answers}</h6>
              </div>
              <div className='col-md-3 text-center'>
              <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Salah</h6>
                <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'18px'}}>{result.wrong_answers}</h6>
              </div>
              <div className='col-md-3 text-center'>
              <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Nilai</h6>
                <h6 className='text-nunito txt-danger' style={{fontWeight:'800', fontSize:'18px'}}>{Number(result.score.toFixed(2))}</h6>
              </div>
              <div className='col-md-3 text-center'>
              <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Keterangan</h6>
                <h6 className='text-nunito txt-danger' style={{fontWeight:'800', fontSize:'18px'}}>Hubungi Trainer</h6>
              </div>
            </div>
            <h6 style={{ fontSize: '18px' }}>Silakan hubungi trainer Anda</h6>
          </div>
        </div>
      </div>
    </>
  )
}

export default HubungiTrainer
