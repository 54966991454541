import React, { useState, useEffect } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation, useHistory } from "react-router-dom";
import { CompanyIcon } from "../../../component/common/constant/menu";
import { ToastContainer, toast } from 'react-toastify';
import API from "../../../api";
import {checkEmpty} from "../../../helper";
import moment from "moment";
import swal from "sweetalert2";

const TambahPromo = () => {
  let query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  let id = query.get("id") || null;
  let today = new Date();
  let day = new Intl.DateTimeFormat('en-US', {day: '2-digit'}).format(today)
  let month = new Intl.DateTimeFormat('en-US', {month: '2-digit'}).format(today)
  let date = today.getFullYear()+'-'+month+'-'+day;
  const [category, setCategory] = useState({});
  const [name, setName] = useState(null);
  const [start_promo, setStartPromo] = useState(null);
  const [end_promo, setEndPromo] = useState(null);
  const [status, setStatus] = useState(null);
  const [company_id, setCompanyId] = useState(null);
  const [discount_type, setDiscountType] = useState(null);
  const [nominal, setNominal] = useState(null);
  const [condition, setCondition] = useState(null);
  const [category_id, setCategoryId] = useState(null);
  const [promo, setPromo] = useState(null);

  const handleChange = (e) => {
    setPromo({
      ...promo,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    API.adminc.company
      .getCompany()
      .then((json) => {
        setCompanyId(json.data.id);
      })
      .catch((err) => {
        console.log(err);
      });

    API.category.get_all()
      .then(json => {
        setCategory(json.data)
      }).catch(err => {
        console.log(err);
      })
  }, []);

  useEffect(() => {
    id !== null &&
      API.adminc.promo
        .detail_promo(id)
        .then((json) => {
          setPromo(json.data);
          console.log(json.data)
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  const handleSubmited = async (status) => {
    setStatus(status);
    const promos = { ...promo };
    promos.status = status;
    promos.company_id = company_id;

    if(checkEmpty(promos || {}, [
      {
        name: 'name',
        alias: 'Nama'
      },
      {
        name: 'start_promo',
        alias: 'Tanggal mulai promo'
      },
      {
        name: 'end_promo',
        alias: 'Tanggal selesai promo'
      },
      {
        name: 'status',
        alias: 'Status'
      },
      {
        name: 'company_id',
        alias: 'Perusahaan'
      },
      {
        name: 'condition',
        alias: 'Syarat'
      },
      {
        name: 'nominal',
        alias: 'Nominal'
      },
      {
        name: 'discount_type',
        alias: 'Tipe diskon'
      },
    ])){
      if(moment(promo.end_promo).diff(moment(promo.start_promo)) < 1){
        swal.fire('Error', 'Tanggal mulai tidak boleh lebih dari tanggal selesai', 'error');
      }
      else{
        const data = {
          name,
          start_promo,
          end_promo,
          nominal,
          company_id,
          status,
          discount_type,
          condition,
        };
        if(condition === 'kategori tertentu'){
          data.category_id = category_id
        }

        id !== null
            ? API.adminc.promo
                .update_promo(id, promos)
                .then((json) => {
                  // setPromo(json.data);
                  toast.success("Promo Berhasil Diubah", { position: toast.POSITION.TOP_CENTER });
                  setTimeout(() => {
                    history.push('/admin/kode-promo')
                  }, 5000)
                })
                .catch((err) => {
                  console.log(err);
                })
            : API.adminc.promo
                .insert_promo(promos)
                .then((json) => {
                  console.log(json.data);
                  toast.success("Promo Berhasil Ditambahkan", { position: toast.POSITION.TOP_CENTER });
                  setTimeout(() => {
                    history.push('/admin/kode-promo')
                  }, 5000)
                })
                .catch((err) => {
                  console.log(err);
                  toast.error("Promo Tidak Berhasil Ditambahkan", { position: toast.POSITION.TOP_CENTER });
                });
      }
    }
  };
  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <BreadcrumbComp title={"Kode Promo Perusahaan"}>
          <li className="breadcrumb-item">
            <Link to="/admin/tambah-promo" className="text-warning">
              <CompanyIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">Kode Promo Perusahaan</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm">
          <div className="card">
            <div className="card-header">
              <h4
                className="text-theme text-work"
                style={{ fontWeight: "600" }}
              >
                TAMBAH KODE PROMO
              </h4>
            </div>

            <div className="col-md-12 mx-4">
              <label
                className="text-muted text-work mb-4"
                style={{ fontWeight: "700" }}
              >
                Detail Promo
              </label>

              <form className="row mb-5" onSubmit={handleSubmited}>
                {/* Krii */}
                <div className="col-md-5 mr-5">
                  <div className="form-group">
                    <label
                      htmlFor="namaPromo"
                      className="font-weight-bold text-muted"
                    >
                      Nama Promo
                    </label>
                    {id !== null ? (
                      promo && (
                        <input
                          type="text"
                          className="form-control w-75"
                          id="name"
                          name="name"
                          onChange={handleChange}
                          value={promo.name}
                        />
                      )
                    ) : (
                      <input
                        type="text"
                        className="form-control w-75"
                        name="name"
                        onChange={handleChange}
                      />
                    )}
                  </div>
                  <label className="font-weight-bold text-muted">Durasi</label>
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-5">
                        {id !== null ? (
                          promo && (
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Pilih tanggal"
                              value={promo.start_promo}
                              max={promo.end_promo}
                              name="start_promo"
                              onChange={handleChange}
                            />
                          )
                        ) : (
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Pilih tanggal"
                            min={date}
                            max={end_promo ? end_promo : null}
                            name="start_promo"
                            onChange={handleChange}
                          />
                        )}
                      </div>
                      <div className="col-md-7 d-flex">
                        <label htmlFor="hingga" className="mx-4 my-auto">
                          hingga
                        </label>
                        {id !== null ? (
                          promo && (
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Pilih tanggal"
                              id="hingga"
                              defaultValue={promo.end_promo}
                              min={promo.start_promo}
                              name="end_promo"
                              onChange={handleChange}
                            />
                          )
                        ) : (
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Pilih tanggal"
                            id="hingga"
                            min={start_promo}
                            name="end_promo"
                            onChange={handleChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="status"
                      className="font-weight-bold text-muted"
                    >
                      Status
                    </label>
                    {id !== null ? (
                      promo && (
                        <select
                          id="status"
                          placeholder="pilih status"
                          className="form-control w-25"
                          defaultValue={promo.status}
                          name="status"
                          onChange={handleChange}
                        >
                          <option value="default" disabled>
                            Pilih Status
                          </option>
                          <option value="Aktif">Aktif</option>
                          <option value="Non Aktif">Non Aktif</option>
                        </select>
                      )
                    ) : (
                      <select
                        id="status"
                        placeholder="pilih status"
                        className="form-control w-25"
                        defaultValue="default"
                        name="status"
                        onChange={(event) => {
                          setStatus(event.target.value);
                          handleChange(event);
                        }}
                      >
                        <option value="default" disabled>
                          Pilih Status
                        </option>
                        <option value="Aktif">Aktif</option>
                        <option value="Non Aktif">Non Aktif</option>
                      </select>
                    )}
                  </div>
                </div>

                {/* kanan */}
                <div className="col-md-4 ml-5">
                  <label className="font-weight-bold text-muted">Diskon</label>
                  <div className="form-group">
                    <div className="form-check form-check-inline">
                      {id !== null ? (
                        promo && (
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineRadio1"
                            value="Rupiah"
                            checked={promo.discount_type === 'Rupiah'}
                            name="discount_type"
                            onChange={handleChange}
                          />
                        )
                      ) : (
                        <input
                          className="form-check-input"
                          type="radio"
                          name="discount_type"
                          id="inlineRadio1"
                          defaultValue="Rupiah"
                          onChange={(e) => {
                            setDiscountType("Rupiah");
                            handleChange(e);
                          }}
                        />
                      )}

                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Rupiah
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      {id !== null ? (
                        promo && (
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineRadio1"
                            value="Persen"
                            checked={promo.discount_type === 'Persen'}
                            name="discount_type"
                            onClick={handleChange}
                          />
                        )
                      ) : (
                        <input
                          className="form-check-input"
                          type="radio"
                          name="discount_type"
                          id="discount_type"
                          defaultValue="Persen"
                          onClick={(e) => {
                            setDiscountType("Persen");
                            handleChange(e);
                          }}
                        />
                      )}
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio2"
                      >
                        Persen
                      </label>
                    </div>
                    <div>
                      {id !== null ? (
                        promo && (
                          <input
                            type="text"
                            placeholder="Masukkan diskon"
                            className="form-control mt-2"
                            defaultValue={promo.nominal}
                            name="nominal"
                            onChange={handleChange}
                          />
                        )
                      ) : (
                        <input
                          type="text"
                          placeholder="Masukkan diskon"
                          className="form-control mt-2"
                          name="nominal"
                          onChange={(event) => {
                            setNominal(event.target.value);
                            handleChange(event);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="syarat"
                      className="font-weight-bold text-muted"
                    >
                      Syarat
                    </label>
                    {id !== null ? (
                      promo && (
                        <select
                          id="syarat"
                          className="form-control"
                          defaultValue={promo.condition.toLowerCase()}
                          name="condition"
                          onChange={handleChange}
                        >
                          <option value="default" disabled>
                            Pilih Syarat
                          </option>
                          <option value="kategori tertentu">
                            Kategori Tertentu
                          </option>
                          <option value="semua pelatihan">
                            Semua Pelatihan
                          </option>
                        </select>
                      )
                    ) : (
                      <select
                        name="condition"
                        id="syarat"
                        className="form-control"
                        defaultValue="default"
                        onChange={(event) => {
                          setCondition(event.target.value);
                          handleChange(event);
                        }}
                      >
                        <option value="default" disabled>
                          Pilih Syarat
                        </option>
                        <option value="kategori tertentu">
                          Kategori Tertentu
                        </option>
                        <option value="semua pelatihan">Semua Pelatihan</option>
                      </select>
                    )}
                  </div>
                  {id !== null ? (
                    promo && (
                      promo.condition === 'Kategori Tertentu' ? (
                        <div className="form-group">
                          <label
                            htmlFor="kategori"
                            className="font-weight-bold text-muted"
                          >
                            Kategori
                          </label>
                          <select
                            id="kategori"
                            className="form-control"
                            name="category_id"
                            onChange={handleChange}
                          >
                            <option value="default" disabled>
                              Pilih Kategori
                            </option>
                            {category.length !== 0 &&
                              category.map(v => {
                                return <option value={v.id} selected={promo.category == v.name}>
                                  {v.name}
                                </option>
                              })
                            }
                          </select>
                        </div>
                      ) : condition === 'kategori tertentu' && (
                        <div className="form-group">
                          <label
                            htmlFor="kategori"
                            className="font-weight-bold text-muted"
                          >
                            Kategori
                          </label>
                          <select
                            id="kategori"
                            className="form-control"
                            defaultValue="default"
                            name="category_id"
                            onChange={handleChange}
                          >
                            <option value="default" disabled>
                              Pilih Kategori
                            </option>
                            {
                              category.map(v => {
                                return <option value={v.id}>
                                  {v.name}
                                </option>
                              })
                            }
                          </select>
                        </div>
                      )
                    )
                  ) : condition === 'kategori tertentu' && (
                    <div className="form-group">
                      <label
                        htmlFor="kategori"
                        className="font-weight-bold text-muted"
                      >
                        Kategori
                      </label>
                      <select
                        name="kategori"
                        id="kategori"
                        className="form-control"
                        defaultValue="default"
                        onChange={(event) => setCategoryId(event.target.value)}
                      >
                        <option value="default" disabled>
                          Pilih Kategori
                        </option>
                        {
                          category.map(v => {
                            return <option value={v.id}>
                              {v.name}
                            </option>
                          })
                        }
                      </select>
                    </div>
                  )}
                </div>
                <div className="col-md-11 mt-5">
                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-pill mr-2"
                      onClick={() => {
                        handleSubmited("Draft");
                      }}
                    >
                      Simpan sebagai draf
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-pill"
                      onClick={() => {
                        handleSubmited("Aktif");
                      }}
                    >
                      Aktifkan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TambahPromo;
