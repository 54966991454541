import React, { useState, useEffect } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation } from "react-router-dom";
import { Layers } from "react-feather";
import logo from "../../../assets/images/bca-logo.png";
import API from "../../../api";
const ListPerusahaan = () => {
  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get("page") || 1;

  const [companies, setCompanies] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });
  const [order, setOrder] = useState('desc');

  const columnsTabel = [
    {
      dataField: "id",
      text: "ID",
      headerStyle: () => ({ width: "5%", textAlign: "left" }),
    },
    {
      dataField: "name",
      text: "Nama Perusahaan",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
      formatter: (cel, row) => {
        return (
          <div className="d-flex align-items-center mb-2">
            <div>
              <img class="img-40" src={row.logo} alt="#"></img>
            </div>
            <label className="mb-0 ml-2">
              <p>{row.name}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "pic_admin",
      text: "PIC Admin",
      headerStyle: () => ({ width: "15%", textAlign: "left" }),
    },
    {
      dataField: "chairman",
      text: "Ketua Klien",
      headerStyle: () => ({ width: "15%", textAlign: "left" }),
    },
    {
      dataField: "created_at",
      text: "Tanggal Dibuat",
      headerStyle: () => ({ width: "15%", textAlign: "left" }),
    },
    {
      dataField: "",
      text: "Aksi",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      formatter: (cel, row) => {
        return (
          <div className="d-flex justify-content-center">
            <Link to={`/superadmin/company-detail?id=${row.id}`} className="mx-auto">
              <button
                className=" btn btn-pill btn-outline-primary btn-sm"
                type="button"
              >
                {" "}
                Detail
              </button>
            </Link>
          </div>
        );
      },
    },
  ];

  const handleOrder = e => {
    setOrder(e.target.value);
  };

  useEffect(() => {
    API.superadmin.company.list_perusahaan({ page, per_page, order }).then((json) => {
      let company = { ...companies };
      company.data = [];

      json.data.data.map((val) => {
        company.data.push({
          id: val.id,
          name: val.name,
          pic_admin: val.pic_admin,
          chairman: val.chairman,
          logo: val.logo,
          created_at: val.created_at + " WIB",
        });
      });

      company.meta.from = (page - 1) * per_page + 1;
      company.meta.to =
        per_page > json.data.total
          ? json.data.total
          : page * per_page > json.data.total
          ? json.data.total
          : page * per_page;
      company.meta.total = json.data.total;
      company.meta.current_page = page;
      company.meta.total_page = Math.ceil(
        company.meta.total / company.meta.per_page
      );

      setCompanies(company);
    });
  }, [page, order]);

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"List Perusahaan"}>
          <li className="breadcrumb-item">
            <Link
              to="/superadmin/list-pelatihan-golbal"
              className="text-warning"
            >
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Perusahaan</li>
          <li className="breadcrumb-item">List Perusahaan</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-3 d-flex align-items-center">
              <div className="mr-auto">
                <h4
                  className="text-theme text-work"
                  style={{ fontWeight: "600" }}
                >
                  LIST PERUSAHAAN
                </h4>
              </div>
              <div className="form-group mt-4 text-left">
                <p
                  className="my-auto mr-3 text-work"
                  style={{ fontSize: "16px" }}
                >
                  Sort by
                </p>
                <div>
                  <select
                    className="form-control digits"
                    id="exampleFormControlSelect7"
                    onChange={handleOrder}
                  >
                    <option value="desc" selected={order === 'desc'}>Terbaru</option>
                    <option value="asc" selected={order === 'asc'}>Terlama</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="overflow-auto w-100">
                <TableComp
                  title_paggination="perusahaan"
                  columns={columnsTabel}
                  data={companies}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ListPerusahaan;
