import React, { useState, useEffect } from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link } from "react-router-dom";
import { CompanyIcon } from '../../../component/common/constant/menu';
import API from "../../../api";

const KetuaKlien = () => {
  const [chairman, setChairman] = useState(null);

  useEffect(() => {
    API.adminc.chairman.get_chairman()
      .then(json => {
        setChairman(json.data)
      }).catch(err => {
        console.log(err);
      })
  }, [])

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Ketua Klien'}>
            <Link to="/admin/ketua-klien" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          <li className="breadcrumb-item">Manajemen Perusahaan</li>
          <li className="breadcrumb-item">Ketua Klien</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">

            {/* ISI */}
            <div>
            { chairman === null ? (
                <div>
                  <div className="card-header d-flex">
                    <div className='mr-auto'>
                      <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>KETUA KLIEN</h4>
                      <span className='text-muted'>Data Ketua Klien</span>
                    </div>
                  </div>
                  <div className='card-body text-center'>
                  <div className='mx-auto mt-5'>
                    <span className='text-muted'>Ketua Klien belum ada</span>
                  </div>
                  <div className='mt-3 mb-5'>
                  <Link to='/admin/tambah-ketua-klien'>
                    <button className=" btn btn-pill btn-outline-primary btn-sm text-work py-2" type="button" style={{ fontWeight: '600' }}><i className="fa fa-pencil"></i> &nbsp;Tambah Ketua Klien</button>
                  </Link>
                  </div>
                  <div className='mb-5'>
                    &nbsp;
                  </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="card-header border-0 pb-3 d-flex align-items-center">
                    <div className='mr-auto'>
                      <h5 className='text-work text-theme' style={{fontSize:'24px', fontWeight:'600'}}>KETUA KLIEN</h5>
                      <span className='text-muted'>Data Ketua Klien</span>
                    </div>
                    <div>
                    <Link to={`/admin/edit-ketua-klien?id=${chairman.id}`}>
                      <button className=" btn btn-pill btn-outline-primary btn-sm text-work" type="button" style={{ fontWeight: '600' }}><i className="fa fa-pencil"></i> &nbsp;Ubah Data Ketua Klien</button>
                    </Link>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="card-body col-2 ml-5">
                      <img className="img-100 rounded-circle mb-2" style={{height: 100, objectFit: 'cover'}} src={chairman.photo} alt="#"/>
                      <br/>
                    </div>

                    <form className="card-body theme-form">
                    <div className="form-row">
                      <div className="form-group col-md-5">
                        <label >Email</label>
                        <input disabled={true} type="email" className="form-control" name="email" value={chairman.email} />
                      </div>
                      <div className="form-group col-md-5">
                        <label >Nama</label>
                        <input disabled={true} type="text" className="form-control" name="nama" value={chairman.name} />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-5">
                        <label >Password</label>
                        <input disabled={true} type="password" className="form-control" name="password" value="123123" />
                      </div>
                      <div className="form-group col-md-5">
                        <label >Jabatan</label>
                        <input disabled={true} type="text" className="form-control" name="jabatan"  value={chairman.profession.name} />
                      </div>
                    </div>
                    <div className="form-row mb-4">
                      <div className="form-group col-md-5">
                        <label >Konfirmasi Password</label>
                        <input disabled={true} type="password" className="form-control" name="konfirmasi_password" value="123123"/>
                      </div>
                      <div className="form-group col-md-5">
                        <label>Nomor HP</label>
                        <input disabled={true} type="text" className="form-control" name="no_hp" value={chairman.phone}/>
                      </div>
                    </div>

                  </form>
                  </div>
                </div>
              )
            }
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
export default KetuaKlien;
