import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { BreadcrumbComp, TableComp } from "../../../component";
import { TransaksiIcon } from "../../../component/common/constant/menu";
import TambahVoucher from "./TambahVoucher";
import remove from "../../../assets/images/remove.png";
import voucher from "../../../assets/images/voucher-redeem.png";

import API from "../../../api";
import {numberWithDots} from "../../../helper";

const ListVoucher = () => {
  let state = useLocation().state;
  let isTambah = false;
  let company_id = null;

  if(state){
    isTambah = state.isTambah;
    company_id = state.company_id;
  }

  const [modalDelete, setModalDelete] = useState(false);
  const [modalDetailVoucher, setmodalDetailVoucher] = useState(false);
  const [detailVoucherData, setdetailVoucherData] = useState({});
  const [tambahVoucher, settambahVoucher] = useState(isTambah);
  const [deleteId, setDeleteId] = useState([]);

  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get("page") || 1;

  const [vouchers, setVouchers] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });

  const handleViewDetail = (row) => {
    setdetailVoucherData(row);
    setmodalDetailVoucher(!modalDetailVoucher);
  };

  const voucherTabel = [
    {
      dataField: "code",
      text: "Kode Voucher",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
    },
    {
      dataField: "date",
      text: "Masa Berlaku",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
      formatter: (cel, row) => {
        return (
          <p className="d-flex align-items-center mb-2">
            {row.start} - {row.end}
          </p>
        );
      },
    },
    {
      dataField: "company",
      text: "Perusahaan",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
    },
    {
      dataField: "nominal",
      text: "Nominal",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      formatter: (cel, row) => {
        return (
          <p className="d-flex align-items-center mb-2">Rp. {numberWithDots(row.nominal)}</p>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      formatter: (cel, row) => {
        return (
          <div
            className={`my-auto mx-auto p-2 text-center card btn-status btn-status_${
              cel === "Aktif"
                ? "aktif"
                : cel === "Draft"
                ? "draft"
                : cel === "Terpakai"
                ? "terpakai"
                : "expired"
            }`}
          >
            {cel}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      formatter: (cel, row, idx) => {
        return (
          <div className="d-flex">
            <button
              className=" btn rounded-circle btn-success btn-xs py-1 mr-3"
              type="button"
              onClick={() => handleViewDetail(row)}
            >
              {" "}
              <i className="fa fa-eye"></i>{" "}
            </button>
            {row.status === "Draft" && (
              <Link
                to={`/superadmin/edit-voucher?id=${row.id}`}
                className=" btn rounded-circle btn-warning btn-xs py-1 mr-3"
                type="button"
              >
                {" "}
                <i className="fa fa-pencil"></i>{" "}
              </Link>
            )}
            <button
              className=" btn rounded-circle btn-danger btn-xs"
              type="button"
              onClick={() => {
                  setDeleteId([row.id, idx]);
                  setModalDelete(!modalDelete)
              }}
            >
              {" "}
              <i className="fa fa-trash"></i>{" "}
            </button>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    API.superadmin.vouchers.list_vouchers({ page, per_page }).then((json) => {
      let voucher = { ...vouchers };
      voucher.data = [];

      json.data.data.map((val) => {
        voucher.data.push({
          id: val.id,
          voucher_id: val.voucher_id,
          code: val.code,
          company: val.company,
          nominal: val.nominal,
          start: val.start,
          end: val.end,
          status: val.status,
          description: val.description
        });
      });

      voucher.meta.from = (page - 1) * per_page + 1;
      voucher.meta.to =
        per_page > json.data.total
          ? json.data.total
          : page * per_page > json.data.total
            ? json.data.total
            : page * per_page;
      voucher.meta.total = json.data.total;
      voucher.meta.current_page = page;
      voucher.meta.total_page = Math.ceil(
        voucher.meta.total / voucher.meta.per_page
      );

      setVouchers(voucher);
    });
  }, [page, tambahVoucher]);

  if (tambahVoucher) {
    return (
      <TambahVoucher
        settambahVoucher={settambahVoucher}
        company_id={company_id}
        toastSucces={() =>
          toast.success("Berhasil menambahkan voucher", {
            position: toast.POSITION.TOP_CENTER,
          })
        }
      />
    );
  }

  return (
    <>
      <ToastContainer />
      <div className="page-header d-flex">
        <BreadcrumbComp title={"List Voucher"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/list-voucher" className="text-warning">
              <TransaksiIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">List Voucher</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 d-flex">
              <div className="mr-auto">
                <h4
                  className="text-theme text-work"
                  style={{ fontWeight: "600" }}
                >
                  {" "}
                  List Voucher
                </h4>
              </div>
              <button
                className="btn btn-pill btn-outline-primary btn-sm text-work"
                type="button"
                style={{ fontWeight: "600" }}
                onClick={() => settambahVoucher(!tambahVoucher)}
              >
                <i className="fa fa-pencil" />
                &nbsp;Tambah Voucher
              </button>
            </div>
            <div className="card-body pt-0">
              <div className="overflow-auto w-100">
                <TableComp
                  title_pagination="peserta"
                  columns={voucherTabel}
                  data={vouchers}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modalDetailVoucher} toggle={handleViewDetail} size="lg">
        <ModalBody>
          <div className="text-center w-75 mx-auto py-5">
            <img
              className="img-fluid mb-4"
              src={voucher}
              style={{ width: "73px" }}
              alt="omind"
            />
            <h3
              className="text-work text-muted"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              Generate Kode Voucher
            </h3>
            <div className="row mt-3">
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-muted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Kode Voucher
                </label>
                <p
                  className="text-work"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  {detailVoucherData.code}
                </p>
              </div>
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-mutted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Status
                </label>
                <div
                  className={` my-auto w-75 p-2 text-center card btn-status btn-status_${
                    detailVoucherData.status === "Aktif"
                      ? "aktif"
                      : detailVoucherData.status === "Expired"
                      ? "expired"
                      : detailVoucherData.status === "Terpakai"
                      ? "terpakai"
                      : "draft"
                  }`}
                >
                  {detailVoucherData.status}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-muted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Perusahaan
                </label>
                <p
                  className="text-work"
                  style={{ fontSize: "18px", fontWeight: "normal" }}
                >
                  {detailVoucherData.company}
                </p>
              </div>
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-muted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Nominal Voucher
                </label>
                <p
                  className="text-work"
                  style={{ fontSize: "18px", fontWeight: "normal" }}
                >
                  {detailVoucherData.nominal}
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-muted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Tanggal Berlaku
                </label>
                <p
                  className="text-work"
                  style={{ fontSize: "18px", fontWeight: "normal" }}
                >
                  {detailVoucherData.start} - {detailVoucherData.end}
                </p>
              </div>
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-muted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Keterangan Voucher
                </label>
                <p
                  className="text-work"
                  style={{ fontSize: "18px", fontWeight: "normal" }}
                >
                  {detailVoucherData.description}
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalDelete}
        size="md"
        toggle={() => setModalDelete(!modalDelete)}
      >
        <ModalBody>
          <div className="text-center w-50 mx-auto py-5">
            <img
              className="img-fluid mb-4"
              src={remove}
              style={{ width: "73px" }}
              alt="omind"
            />
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              Apakah anda yakin menghapus voucher ini?
            </h3>
            <div className="d-flex justify-content-between mx-3 mt-4">
              <button
                className="btn btn-pill btn-primary btn-sm text-work"
                type="button"
                style={{ fontWeight: "600", width: "88px" }}
                onClick={() => {
                    let [id, idx] = deleteId;

                    API.superadmin.vouchers.delete_voucher(id);

                    let temp_vouchers = {...vouchers};
                    temp_vouchers.data.splice(idx, 1);
                    setVouchers(temp_vouchers);
                    setModalDelete(!modalDelete);
                }}
              >
                {" "}
                Ya{" "}
              </button>
              <button
                className="btn btn-pill btn-outline-primary btn-sm text-work"
                type="button"
                style={{ fontWeight: "600", width: "88px" }}
                onClick={() => setModalDelete(!modalDelete)}
              >
                {" "}
                Tidak{" "}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default ListVoucher;
