import React, { useState, useEffect } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation, useHistory } from "react-router-dom";
import { CompanyIcon } from "../../../component/common/constant/menu";
import company from "../../../assets/images/dummy_king.png";
import { Input } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";
import API from "../../../api";

const DetailVoucher = () => {
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const history = useHistory();
  const [voucher, setVoucher] = useState(null);
  const [company, setCompany] = useState(null);
  const [updateVoucher, setUpdateVoucher] = useState(null);
  const [check, setCheck] = useState(true);
  function convertDateIndotoDB(string) {
    let convertDate = string.substring(string.indexOf(",") + 2);
    let bulanIndo = [
      "",
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    let tanggal = convertDate.split(" ")[0];
    let bulan = bulanIndo.indexOf(convertDate.split(" ")[1]);
    if (parseInt(bulan) < 10) {
      bulan = "0" + bulan;
    }
    let tahun = convertDate.split(" ")[2];

    return tahun + "-" + bulan + "-" + tanggal;
  }
  useEffect(() => {
    let company_id = 0;
    API.adminc.company
      .getCompany()
      .then((json) => {
        setCompany(json.data);
        company_id = json.data.id;
      })
      .catch((err) => {
        console.log(err);
      });
    API.adminc.vouchers
      .detail_voucher(id)
      .then((json) => {
        setVoucher(json.data);
        const updateVouchers = { ...updateVoucher };
        updateVouchers.nominal = json.data.nominal;
        updateVouchers.start_voucher = convertDateIndotoDB(json.data.start);
        updateVouchers.end_voucher = convertDateIndotoDB(json.data.end);
        updateVouchers.description = json.data.description;
        updateVouchers.company_id = company_id;
        updateVouchers.voucher_id = json.data.voucher_id;
        setUpdateVoucher(updateVouchers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleUpdate = () => {
    API.adminc.vouchers
      .update_voucher(updateVoucher.voucher_id, updateVoucher)
      .then((json) => {
        console.log(json.data);
        toast.success("Data Voucher Berhasil Diubah", { position: toast.POSITION.TOP_CENTER });
        setTimeout(() => {
          history.push('/admin/kode-voucher')
        }, 5000)
      })
      .catch((err) => {
        console.log(err);
        toast.success("Data Voucher Tidak Berhasil Diubah", { position: toast.POSITION.TOP_CENTER });
      });
  };
  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"List Voucher"}>
          <li className="breadcrumb-item">
            <Link to="/admin/detail-voucher" className="text-warning">
              <CompanyIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">Kode Voucher</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Link to="/admin/kode-voucher">
            <p className="btn p-0 text-theme">
              <i className="fa fa-arrow-left"></i> Kembali
            </p>
          </Link>
        </div>
        <div className="col-sm-12">
          <div className="card">
            {voucher && (
              <div className="card-body">
                <div className="text-center">
                  <p
                    style={{ fontSize: "18px", fontWeight: "" }}
                    className="text-muted font-weight-bold m-2 mb-4"
                  >
                    Detail Voucher
                  </p>
                </div>
                {voucher.status === "Draft" ? (
                  <div>
                    {updateVoucher && (
                      <div className="row">
                        <div className="col-md-6 ml-5 ">
                          <h6 className="text-work text-muted mb-0">
                            Kode Voucher
                          </h6>
                          <div className="d-flex align-items-center mb-3">
                            <div>
                              <p
                                className="text-muted mb-0 font-weight-bold"
                                style={{ fontSize: "16px" }}
                              >
                                {voucher.code}
                              </p>
                            </div>
                          </div>
                          <label className="text-muted text-work mb-1">
                            Perusahaan
                          </label>
                          {company && (
                            <div className="d-flex align-items-center mb-3">
                              <div>
                                <img
                                  className="img-20 rounded-circle"
                                  src={company.logo}
                                  alt="logo_perusahaan"
                                ></img>
                              </div>
                              <label className="mb-0 ml-3">
                                {/* <input
                              type="text"
                              value="PT Grabak Grubuk"
                              className="form-control"
                            /> */}
                                {company.name}
                              </label>
                            </div>
                          )}
                          <label className="text-muted text-work mb-1">
                            Tanggal Berlaku
                          </label>
                          <div className="d-flex align-items-center mb-3">
                            <div>
                              <input
                                type="date"
                                className="form-control"
                                defaultValue={updateVoucher.start_voucher}
                                onChange={(event) => {
                                  const updateVouchers = { ...updateVoucher };
                                  updateVouchers.start_voucher =
                                    event.target.value;
                                  setUpdateVoucher(updateVouchers);
                                }}
                              />
                            </div>
                            <div className="mx-3">
                              <p
                                style={{ fontWeight: "400", fontSize: "12px" }}
                              >
                                {" "}
                                hingga{" "}
                              </p>
                            </div>
                            <div>
                              <input
                                type="date"
                                className="form-control"
                                defaultValue={updateVoucher.end_voucher}
                                onChange={(event) => {
                                  const updateVouchers = { ...updateVoucher };
                                  updateVouchers.end_voucher =
                                    event.target.value;
                                  setUpdateVoucher(updateVouchers);
                                }}
                              />
                            </div>
                          </div>
                          <label className="text-muted text-work font-weigh-bold mb-0">
                            Keterangan Voucher
                          </label>
                          <div className="align-items-center mb-3">
                            <textarea
                              rows="5"
                              placeholder="Masukkan keterangan penggunaan voucher"
                              className="form-control w-75"
                              defaultValue={updateVoucher.description}
                              onChange={(event) => {
                                const updateVouchers = { ...updateVoucher };
                                updateVouchers.description = event.target.value;
                                setUpdateVoucher(updateVouchers);
                              }}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-4 ml-5">
                          <label className="text-muted text-work mb-1">
                            Status
                          </label>
                          <div className="align-items-center mb-3">
                            <div
                              className={`w-25 my-auto py-2 text-center card btn-status btn-status_${
                                voucher.status === "Aktif"
                                  ? "aktif"
                                  : voucher.status === "Expired"
                                  ? "expired"
                                  : voucher.status === "Terpakai"
                                  ? "terpakai"
                                  : "draft"
                              }`}
                            >
                              {voucher.status}
                            </div>
                          </div>
                          <label className="text-muted text-work mb-2">
                            Nominal Voucher
                          </label>
                          <div className="d-flex align-items-center mb-3">
                            <div>
                              <Input
                                label="Rp"
                                defaultValue={updateVoucher.nominal}
                                onChange={(event) => {
                                  const updateVouchers = { ...updateVoucher };
                                  updateVouchers.nominal = event.target.value;
                                  setUpdateVoucher(updateVouchers);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-md-12 mt-2">
                      <div className="form-check my-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck1"
                          onChange={() => {
                            setCheck(!check)
                          }}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="defaultCheck1"
                        >
                          Saya menyetujui bahwa setiap nilai voucher akan
                          otomatis ditagihkan kepada perusahaan ketika terpakai
                          dan <br />
                          sepenuhnya menjadi tanggung jawab perusahaan
                        </label>
                      </div>
                    </div>
                    <div className="col-md-11">
                      <div className="my-5 d-flex align-items-center justify-content-end">
                        <Link to="/admin/kode-voucher">
                          <button className="btn btn-outline-primary btn-pill mr-3">
                            Batal
                          </button>
                        </Link>
                        <button
                          className="btn btn-primary btn-pill"
                          type="button"
                          disabled={check}
                          onClick={() => handleUpdate()}
                        >
                          Simpan
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="row">
                      <div className="col-md-6 ml-5 ">
                        <h6 className="text-work text-muted mb-0">
                          Kode Voucher
                        </h6>
                        <div className="d-flex align-items-center mb-3">
                          <div>
                            <p
                              className="text-muted mb-0 font-weight-bold"
                              style={{ fontSize: "16px" }}
                            >
                              {voucher.code}
                            </p>
                          </div>
                        </div>
                        <label className="text-muted text-work mb-1">
                          Perusahaan
                        </label>
                        <div className="d-flex align-items-center mb-3">
                          {
                              company &&
                              <div>
                                  <img
                                      className="img-20 rounded-circle"
                                      src={company.logo.replace(/ /g,"%20")}
                                      alt="logo_perusahaan"
                                  />
                              </div>
                          }

                          <label className="mb-0 ml-2">
                            <p className="font-weight-bold mb-0">
                              {voucher.company}
                            </p>
                          </label>
                        </div>
                        <label className="text-muted text-work mb-0">
                          Tanggal Berlaku
                        </label>
                        <div className="d-flex align-items-center mb-3">
                          <label className="mb-0">
                            <p
                              className="font-weight-bold mb-0"
                              style={{ fontSize: "14px" }}
                            >
                              {voucher.start}{" "}
                              <span
                                className="mx-2 text-muted"
                                style={{ fontWeight: "400", fontSize: "12px" }}
                              >
                                {" "}
                                hingga{" "}
                              </span>{" "}
                              {voucher.end}
                            </p>
                          </label>
                        </div>
                        <label className="text-muted text-work mb-0">
                          Keterangan Voucher
                        </label>
                        <div className="d-flex align-items-center mb-3">
                          <p
                            className="text-work mb-0"
                            style={{ fontWeight: "600" }}
                          >
                            {voucher.description}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 ml-5">
                        <label className="text-muted text-work mb-1">
                          Status
                        </label>
                        <div className="align-items-center mb-3">
                          <div
                            className={`w-25 my-auto py-2 text-center card btn-status btn-status_${
                              voucher.status === "Aktif"
                                ? "aktif"
                                : voucher.status === "Expired"
                                ? "expired"
                                : voucher.status === "Terpakai"
                                ? "terpakai"
                                : "draft"
                            }`}
                          >
                            {voucher.status}
                          </div>
                        </div>
                        <label className="text-muted text-work mb-0">
                          Nominal Voucher
                        </label>
                        <div className="d-flex align-items-center mb-3">
                          <div>
                            <p
                              className="txt-success font-weight-bold mb-0"
                              style={{ fontSize: "16px" }}
                            >
                              Rp {voucher.nominal}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 text-center mx-auto mt-5">
                      <Link to={`/admin/kode-voucher`}>
                        <p style={{ fontSize: "14px" }}>
                          {" "}
                          <u> Kembali ke List Voucher </u>
                        </p>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailVoucher;
