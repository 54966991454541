import React from 'react';
import swal from "sweetalert2";

const CardItem = ({data, hiddenFileInputs, handleChange, status, setStatus, commentText, setCommentText, commentTextArea, postComment, user, modalSelesai, setmodalSelesai, fileWillUploaded, attemptFinalTask}) => {
  return (
    <div className='card px-4 py-3'>
      <ol className='d-block' style={{paddingInlineStart: 10}}>
        {data.map((data,key)=>(
          <div className='row mt-3'>
            <div className='col-md-9'>
              <li key={key}><p className='text-nunito' style={{ fontWeight: '600', fontSize: '14px' }}>{data.soal}</p></li>
              <div className='mb-3'>
                {data.download_jawaban.map((v,k)=>(
                  <button onClick={() => window.open(v.answer_file.replace(/ /g, '%20'), '_blank')} className="btn btn-primary text-work mr-2 mb-2" style={{fontWeight:'600', fontSize:'12px'}} type="button" key={k}><i className="fa fa-cloud-download"></i> &nbsp;Download Jawaban {k+1}</button>
                ))}
                  <br className='mb-3'/>
                {data.download_jawaban.map((v,k)=>(
                  <>
                    <button disabled={v.status === 'Pass'} className="btn btn-success text-work mr-2 mb-2" onClick={() => {
                        if((k + 1 === data.download_jawaban.length) && (data.download_jawaban.length === data.attempt) && !v.correction_file)
                            hiddenFileInputs[key].current.click();
                        else
                            window.open(v.correction_file.replace(/ /g, '%20'), '_blank');
                    }} style={{fontWeight:'600', fontSize:'12px'}} type="button" key={k}><i className="fa fa-cloud-download"></i> &nbsp;{(k + 1 === data.download_jawaban.length) && (data.download_jawaban.length === data.attempt) && !v.correction_file ? `Upload Koreksi ${fileWillUploaded[key] ? `(${fileWillUploaded[key].name})` : ''}` : `Download Koreksi ${k+1}`} </button>
                      <input
                          type="file"
                          name={key}
                          ref={hiddenFileInputs[key]}
                          onChange={e => {handleChange(e)}}
                          style={{display: 'none'}}
                      />
                  </>
                ))}
              </div>
              {data.komentar.map((data,key)=>(
                <div className={`border p-4 ${data.role === 'Peserta' && 'bg-light txt-dark'}`}>
                  <div className='d-flex'>
                  <div className='mr-3'>
                    <img className="img-40 rounded-circle" style={{height: 40, objectFit: 'cover'}} src={data.ava.replace(/ /g, '%20')} alt="#"/>
                  </div>
                  <div>
                    <label className='mb-2'>
                      <h6 className='mb-0'>{data.name}</h6>
                      <p className='mb-0'>{data.role} - {data.company}</p>
                    </label>
                    <h6>{data.text}</h6>

                  </div>
                </div>
                </div>
              ))}
                <div className='border bg-light p-4'>
                    <div className='d-flex'>
                        <div className='mr-3'>
                            <img className="img-40 rounded-circle" style={{height: 40, objectFit: 'cover'}} src={user.profile_picture.replace(/ /g, '%20')} alt="#"/>
                        </div>
                        <textarea placeholder='Tulis Komentar...' className='p-2 w-100 rounded' ref={commentTextArea} onChange={e => setCommentText(e.target.value)}>
                      {commentText}
                  </textarea>
                    </div>
                    <div className='mt-3 text-right'>
                        <button className="btn mr-1 text-work txt-primary bg-light" type="button" style={{ fontWeight: '600', fontSize: '12px' }} onClick={() => {
                            commentTextArea.current.value = '';
                            setCommentText('');
                        }}>Batal</button>
                        <button className="btn btn-primary  text-work" type="button" style={{ fontWeight: '600', fontSize: '12px' }}
                                onClick={() => postComment(data, key)}
                        >Kirim</button>
                    </div>
                </div>
            </div>
            <div className='col-md-3 text-right'>
             <p className={`mb-2 ml-auto ${data.attempt === 1 ? 'txt-primary' : data.attempt === 2 ? 'text-theme' : 'text-danger'  }`} style={{fontSize:'14px', fontWeight:'600'}}>Attempt {data.attempt}/3</p>
                {
                    data.download_jawaban.length === data.attempt ?
                        <select disabled={data.status} className={`btn ${status[key] ? 'btn-success' : 'btn-danger'}`} onChange={e => {
                            let st = [...status];
                            st[key] = !!Number(e.target.value);
                            setStatus(st);
                        }}>
                            <option value={1} selected={data.status} className='w-100 bg-white txt-dark'>Pass</option>
                            <option value={0} selected={!data.status} className='w-100 bg-white txt-dark'>Coba Lagi</option>
                        </select> :
                    <button className='btn btn-outline-danger btn-sm text-work mb-2 py-1 px-2'>Peserta belum menjawab</button>
                }
            </div>
            <br/>
          </div>
        ))}
      </ol>
        {
            attemptFinalTask <= 3 &&
            <div className='text-right'>
                <button className=" btn btn-primary btn-sm text-work px-3" type="button" style={{ fontWeight: '600' }} onClick={() => {
                    setmodalSelesai(!modalSelesai)
                }}>Selesai</button>
            </div>
        }

    </div>
  )
}

export default CardItem;
