import Post from "../Post";
import Get from "../Get";

const add_ctp = (data) => Post(`super-admin/course-to-participants`, data);

const SuperadminCtp = {
    add_ctp,
};

export default SuperadminCtp;
