import React, { useEffect, useState } from 'react';
import { BreadcrumbComp } from '../../../component';
import { PelatihanLainnyaIcon } from '../../../component/common/constant/menu';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import {
  useLocation,
  Link
} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../../api";
import { numberWithDots } from "../../../helper";
import PelatihanAktif from "../pelatihanSaya/pelatihanAktif";

const PelatihanLainnyaDetail = () => {
  let query = new URLSearchParams(useLocation().search);
  const kategori_id = query.get("kategori_id");
  const kategori = query.get("kategori");
  const jenis = query.get("jenis");
  const search = query.get("s");
  const perusahaan_id = query.get("perusahaan_id");

  const [company, setCompany] = useState(null);
  const [courses, setCourses] = useState([]);
  const [cartnum, setCartNum] = useState(0);
  const [isAdding, setIsAdding] = useState(false);
  const [courseCategories, setCourseCategories] = useState([]);

  useEffect(() => {
    if (perusahaan_id)
      API.peserta.company.company_overview(perusahaan_id)
        .then(json => {
          setCompany(json.data);

          let courses = [];
          courses.push([]);

          let cc = json.data.course.map(val => { return val.category_id.name });
          cc = [...new Set(cc)];
          setCourseCategories(cc);

          let i = 0;
          json.data.course.map(val => {
            courses[i].push({
              id: val.id,
              item_judul: val.name,
              item_desc: val.description,
              item_bg: val.thumbnail,
              item_harga: val.price,
              user_image: val.trainer_id.profile_picture,
              user_name: val.trainer_id.name,
              user_role: val.trainer_id.company_id.name,
              status: val.type
            })
          });

          cc.map(val => {
            i++;
            courses.push([]);

            let filter = json.data.course.filter(v => {
              return v.category_id.name === val
            });

            filter.map(c => {
              courses[i].push({
                id: c.id,
                item_judul: c.name,
                item_desc: c.description,
                item_bg: c.thumbnail,
                item_harga: c.price,
                user_image: c.trainer_id.profile_picture,
                user_name: c.trainer_id.name,
                user_role: c.trainer_id.company_id.name,
                status: c.type
              });
            });
          });
          setCourses(courses);
        })
        .catch(e => {
          console.log(e);
        });
    else {
      let data = '';

      setCourses([]);

      if (kategori_id)
        data = { category_id: kategori_id };
      else
        data = { type: jenis };

      API.peserta.course.others(data)
        .then(json => {

          let courses = [];
          json.data.data.map(val => {
            courses.push({
              id: val.id,
              item_judul: val.name,
              item_desc: val.description,
              item_bg: val.thumbnail,
              item_harga: val.price,
              user_image: val.trainer_id.profile_picture,
              user_name: val.trainer_id.name,
              user_role: val.trainer_id.company_id.name,
              status: val.type
            })
          });
          setCourses(courses);
        })
    }

    API.peserta.course.get_cart()
      .then(json => {
        setCartNum(json.data.size);
      });
  }, [kategori_id, jenis]);

  useEffect(() => {
    if (search)
      API.peserta.course.others({ s: search })
        .then(json => {

          let courses = [];
          json.data.data.map(val => {
            courses.push({
              id: val.id,
              item_judul: val.name,
              item_desc: val.description,
              item_bg: val.thumbnail,
              item_harga: val.price,
              user_image: val.trainer_id.profile_picture,
              user_name: val.trainer_id.name,
              user_role: val.trainer_id.company_id.name,
              status: val.type
            })
          });
          setCourses(courses);
        })
  }, [search]);

  const addToCart = id => {
    setIsAdding(true);
    API.peserta.course.add_to_cart(id)
      .then(json => {
        setCartNum(num => num + 1);
        toast.success("Berhasil menambahkan ke Cart", { position: toast.POSITION.TOP_CENTER });
        setIsAdding(false);
      })
      .catch(e => {
        toast.error(e.message, { position: toast.POSITION.TOP_CENTER });
        setIsAdding(false);
      })
  };

  return (
    <>
      <ToastContainer />
      <div className="page-header">
        <BreadcrumbComp title={'Pelatihan Lainnya'}
          menuRight={
            <>
              <Link to='/pelatihan-lainnya/cart'>
                <button className="btn btn-outline-warning btn-sm bg-white mr-3" type="button">
                  <label className='d-flex align-items-center mb-0'>
                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 14C7.55228 14 8 13.5523 8 13C8 12.4477 7.55228 12 7 12C6.44772 12 6 12.4477 6 13C6 13.5523 6.44772 14 7 14Z" fill="#FFD200" />
                      <path d="M11.6665 14C12.2188 14 12.6665 13.5523 12.6665 13C12.6665 12.4477 12.2188 12 11.6665 12C11.1142 12 10.6665 12.4477 10.6665 13C10.6665 13.5523 11.1142 14 11.6665 14Z" fill="#FFD200" />
                      <path d="M14.0002 4.66667H4.8895L4.12016 2.82C4.01917 2.57677 3.84824 2.36899 3.62903 2.223C3.40982 2.07702 3.1522 1.99941 2.88883 2H1.3335V3.33334H2.8895L6.0515 10.9233C6.15483 11.1713 6.39816 11.3333 6.66683 11.3333H12.0002C12.2782 11.3333 12.5268 11.1607 12.6248 10.9013L14.6248 5.568C14.6623 5.46707 14.6748 5.35857 14.6614 5.25175C14.6479 5.14493 14.6089 5.04294 14.5475 4.95445C14.4862 4.86596 14.4044 4.79358 14.3091 4.74348C14.2138 4.69338 14.1078 4.66703 14.0002 4.66667ZM11.3335 8.66667H10.0002V10H8.66683V8.66667H7.3335V7.33334H8.66683V6H10.0002V7.33334H11.3335V8.66667Z" fill="#FFD200" />
                    </svg>
                    <p className='mb-0 font-weight-bold ml-2'>
                      Cart <span className='font-weight-normal'>({cartnum} items)</span>
                    </p>
                  </label>
                </button>
              </Link>
            </>
          }
        >
          <li className="breadcrumb-item">
            <Link to="/" className='text-warning'>
              <PelatihanLainnyaIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Lainnya</li>
        </BreadcrumbComp>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <Link to='/pelatihan-lainnya'>
            <p className='btn p-0 text-theme'>
              <i className="fa fa-arrow-left"></i> Kembali
            </p>
          </Link>
        </div>
        <div className='col-md-12'>
          <div className='card p-4'>
            {jenis && <h4 className='text-work text-muted'>Pelatihan untuk jenis <span className='font-weight-bold'>{jenis}</span></h4>}
            {search && <h4 className='text-work text-muted'>Hasil untuk pencarian <span className='font-weight-bold'>"{search}"</span></h4>}
            {company ?
              <>
                <div className='row'>
                  <div className='col-md-2 h-100 text-center py-3'>
                    <img className="img-100 rounded-circle" src={company.logo} alt="#" />
                  </div>
                  <div className='col-md-10'>
                    <h3 className='font-weight-bold'>{company.name}</h3>
                    <p className='text-muted mb-0'> <i className='fa fa-building-o'></i> Berdiri sejak {company.since}</p>
                    <p className='text-muted mb-0'> <i className='fa fa-map-marker'></i> {company.address}</p>
                    <p className='text-muted mb-0'> <i className='fa fa-phone'></i> {company.phone_number} &nbsp;&nbsp;<i className='fa fa-envelope-o'></i> {company.email}</p>
                    <div className='d-flex align-items-center mt-3'>
                      <p className='mb-0'>Bidang Pelatihan </p>
                      {
                        company.category_company.map(val => {
                          return <button className="btn btn-pill btn-outline-warning text-dark btn-xs ml-3" type="button">{val.category_id.name}</button>
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className='row theme-tab mt-5'>
                  <Tabs className="col-sm-12">
                    <TabList className="tabs tab-title border-0">
                      <Tab className="current">Semua pelatihan</Tab>
                      {
                        company.category_company.map(val => {
                          return <Tab className="current">{val.category_id.name}</Tab>
                        })
                      }
                    </TabList>
                    <div className="tab-content-cls">
                      {courses.map(val => {
                        return <TabPanel>
                          <div className='row mt-5 mx-5'>
                            {
                              val.map((data, key) => {
                                return <div className='col-md-4 mb-3' key={key}>
                                  <div className='card border'>
                                    <div className='h-100 px-4 rounded-top' style={{
                                      backgroundImage: `url(${data.item_bg.replace(/ /g, "%20")})`,
                                      backgroundSize: 'cover',
                                      minHeight: '12rem'
                                    }}>
                                      <div
                                        className={`badge text-wrap text-capitalize mt-3 ${data.status === 'Online' ? 'badge-primary' : data.status === 'Face to Face' ? 'badge-danger' : data.status === 'Enquiries' ? 'badge-success' : 'badge-pink'}`}>
                                        {data.status}
                                      </div>
                                    </div>
                                    <div className='p-3'>
                                      <h5 className='font-weight-bold text-truncate-2'>{data.item_judul}</h5>
                                      <div className='d-flex align-items-center mb-2 border-top border-bottom py-2'>
                                        <div>
                                          <img className="img-40 rounded-circle" src={data.user_image} alt="#"
                                            style={{ height: '40px', objectFit: 'cover' }} />
                                        </div>
                                        <label className='mb-0 ml-2'>
                                          <h6 className='font-weight-bold mb-0'>{data.user_name}</h6>
                                          <p>{data.user_role}</p>
                                        </label>
                                      </div>
                                      <p className='text-right font-weight-bold text-success'>Rp {data.item_harga}</p>
                                      <div className='text-center d-flex'>
                                        <button className="btn btn-pill btn-sm bg-white" type="button"
                                          onClick={() => addToCart(data.id)}>
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                              d="M10.5 21C11.3284 21 12 20.3284 12 19.5C12 18.6716 11.3284 18 10.5 18C9.67157 18 9 18.6716 9 19.5C9 20.3284 9.67157 21 10.5 21Z"
                                              fill="#2DABFF" />
                                            <path
                                              d="M17.5 21C18.3284 21 19 20.3284 19 19.5C19 18.6716 18.3284 18 17.5 18C16.6716 18 16 18.6716 16 19.5C16 20.3284 16.6716 21 17.5 21Z"
                                              fill="#2DABFF" />
                                            <path
                                              d="M21 7H7.334L6.18 4.23001C6.02851 3.86515 5.77211 3.55348 5.4433 3.33451C5.11448 3.11553 4.72806 2.99911 4.333 3.00001H2V5H4.334L9.077 16.385C9.232 16.757 9.597 17 10 17H18C18.417 17 18.79 16.741 18.937 16.352L21.937 8.35201C21.9932 8.2006 22.012 8.03786 21.9918 7.87763C21.9716 7.7174 21.913 7.56441 21.8211 7.43167C21.7291 7.29893 21.6064 7.19037 21.4634 7.11522C21.3205 7.04006 21.1615 7.00054 21 7ZM17 13H15V15H13V13H11V11H13V9H15V11H17V13Z"
                                              fill="#2DABFF" />
                                          </svg>
                                        </button>
                                        <Link to={`/pelatihan-detail-lainnya?id=${data.id}`}
                                          className='w-100 d-flex align-items-center'>
                                          <button className="btn btn-pill btn-primary btn-sm w-100" type="button">Detail
                                            Pelatihan
                                              </button>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              })
                            }
                          </div>
                        </TabPanel>
                      })}
                    </div>
                  </Tabs>
                </div>
              </>
              :
              <>
                {kategori_id ?
                  <h3 className='text-work text-muted'>{kategori}</h3> : ''
                }
                <div className='row mt-5 mx-5'>
                  {courses.map((data, key) => (
                    <div className='col-md-4 px-3 mb-3' key={key}>
                      <div className='card border h-100'>
                        <div className='h-100 px-4 rounded-top' style={{ backgroundImage: `url(${data.item_bg.replace(/ /g, "%20")})`, backgroundSize: 'cover', minHeight:'12rem' }}>
                          <div
                            className={`badge text-wrap text-capitalize mt-3 ${data.status === 'Online' ? 'badge-primary' : data.status === 'Face to Face' ? 'badge-danger' : data.status === 'Enquiries' ? 'badge-success' : 'badge-pink'}`}>
                            {data.status}
                          </div>
                        </div>
                        <div className='p-3'>
                          <h5 className='font-weight-bold text-truncate-2'>{data.item_judul}</h5>
                          <div className='d-flex align-items-center mb-2 border-top border-bottom py-2'>
                            <div>
                              <img className="img-40 rounded-circle" src={data.user_image} alt="#"
                                style={{ height: '40px', objectFit: 'cover' }} />
                            </div>
                            <label className='mb-0 ml-2'>
                              <h6 className='font-weight-bold mb-0'>{data.user_name}</h6>
                              <p>{data.user_role}</p>
                            </label>
                          </div>
                          <p className='text-right font-weight-bold text-success'>Rp {numberWithDots(data.item_harga)}</p>
                          <div className='text-center d-flex justify-content-between align-items-center'>
                            <div className="w-100">
                              {!['Virtual Class', 'Face to Face'].includes(data.status) &&
                                <button className="btn btn-pill btn-sm bg-white px-0" type="button"
                                  onClick={() => addToCart(data.id)}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M10.5 21C11.3284 21 12 20.3284 12 19.5C12 18.6716 11.3284 18 10.5 18C9.67157 18 9 18.6716 9 19.5C9 20.3284 9.67157 21 10.5 21Z"
                                      fill="#2DABFF" />
                                    <path
                                      d="M17.5 21C18.3284 21 19 20.3284 19 19.5C19 18.6716 18.3284 18 17.5 18C16.6716 18 16 18.6716 16 19.5C16 20.3284 16.6716 21 17.5 21Z"
                                      fill="#2DABFF" />
                                    <path
                                      d="M21 7H7.334L6.18 4.23001C6.02851 3.86515 5.77211 3.55348 5.4433 3.33451C5.11448 3.11553 4.72806 2.99911 4.333 3.00001H2V5H4.334L9.077 16.385C9.232 16.757 9.597 17 10 17H18C18.417 17 18.79 16.741 18.937 16.352L21.937 8.35201C21.9932 8.2006 22.012 8.03786 21.9918 7.87763C21.9716 7.7174 21.913 7.56441 21.8211 7.43167C21.7291 7.29893 21.6064 7.19037 21.4634 7.11522C21.3205 7.04006 21.1615 7.00054 21 7ZM17 13H15V15H13V13H11V11H13V9H15V11H17V13Z"
                                      fill="#2DABFF" />
                                  </svg>
                                </button>
                              }
                            </div>
                            <Link
                              to={`/pelatihan-detail-lainnya?id=${data.id}`}
                              className='w-100 d-flex align-items-center'>
                              <button className="btn btn-pill btn-primary btn-sm w-100" type="button">Detail Pelatihan</button>
                            </Link>
                          </div>
                        </div>
                      </div>

                    </div>
                  ))}
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default PelatihanLainnyaDetail;
