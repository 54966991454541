import React, { useState, useEffect } from "react";
import { BreadcrumbComp } from "../../../component";
import { Link, useHistory } from "react-router-dom";
import { User } from "react-feather";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';
import API from "../../../api";

const ProfilSaya = () => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('data'));
  const [url, setUrl] = useState();
  const [terverifikasi, setterverifikasi] = useState(true);
  const [imagePreview, setimagePreview] = useState(null);
  const [imagePreviewUrl, setimagePreviewUrl] = useState("");
  const [ubahEmail, setubahEmail] = useState(true);
  const [ubahHp, setubahHp] = useState(true);
  const [profession, setProfession] = useState('');
  const [DataAkun, setDataAkun] = useState(null);
  const [updateProfile, setUpdateProfile] = useState(null);
  const [updateAccount, setUpdateAccount] = useState(null);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setimagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (imagePreviewUrl) {
      setimagePreview(
        <img className="w-100" src={imagePreviewUrl} alt="Global English" />
      );
    } else {
      setimagePreview(<div className=""></div>);
    }
  }, [imagePreviewUrl]);

  const readUrl = (event) => {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result);
    };
  };

  useEffect(() => {
    console.log('aneh');
    API.adminc.profile.my_profile().then((json) => {
      let profile = json.data;
      setDataAkun(profile);
    });

    const updateAccounts = { ...updateAccount }
    setUpdateAccount(updateAccounts)

    const updateProfiles = { ...updateProfile };
    setUpdateProfile(updateProfiles);

    API.adminc.profession.get_profession()
      .then(json => {
        let profesi = {...profession}
        profesi.data = []

        json.data.map(v => {
          profesi.data.push({
            id: v.id,
            name: v.name,
          })
        })
        setProfession(profesi)
      }).catch(err => {
        console.log(err);
      })
  }, []);

  const handleUpdate = () => {
    API.adminc.profile
      .update_profile(updateProfile)
      .then((json) => {
        let profile = {
          ...user,
          ...json.data,
        };
        console.log(JSON.stringify(profile));
        localStorage.setItem('data', JSON.stringify(profile));
        history.push('/admin');
        // setDataAkun(profile);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateAccount = () => {
    if(updateAccount.password){
      if(updateAccount.confirm_password){
        if(updateAccount.password !== updateAccount.confirm_password){
          toast.error("Confirm Password Tidak Sesuai", { position: toast.POSITION.TOP_CENTER });
        }
      }else{
        toast.error("Confirm Password harap diisi", { position: toast.POSITION.TOP_CENTER });
      }
    }else{
      if(updateAccount.confirm_password){
        toast.error("Password harap diisi", { position: toast.POSITION.TOP_CENTER });
      }
    }
    API.adminc.profile
      .update_account(updateAccount)
      .then((json) => {
        let profile = {
          ...user,
          ...json.data,
        };
        localStorage.setItem('data', JSON.stringify(profile));
        history.push('/admin');
        // setDataAkun(profile);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    DataAkun && (
      <>
        <div className="page-header">
          <ToastContainer />
          <BreadcrumbComp title={"Profil Saya"}>
            <li className="breadcrumb-item">
              <Link to="/admin/profil-saya" className="text-warning">
                <User />
              </Link>
            </li>
            <li className="breadcrumb-item">Profil Saya</li>
          </BreadcrumbComp>
        </div>
        <div className="row">
          <div className="col-md-5">
            <div className="card">
              <div className="card-header">
                <h6 style={{ fontSize: "24px", fontWeight: "normal" }}>
                  Akun Saya
                </h6>
              </div>
              <div className="card-body">
                <div className="d-flex align-items-center user-profile">
                  <div className="user-image">
                    <div className="avatar ">
                      <img
                        className="img-70 rounded-circle"
                        style={{height: 70, objectFit: 'cover'}}
                        alt=""
                        src={DataAkun.photo}
                        data-intro="This is Profile image"
                      />
                    </div>
                    {/* <div className="icon-wrapper">
                      <i className="icofont icofont-pencil-alt-5" data-intro="Change Profile image here" >
                          <input className="pencil" type="file" onChange={(e) => readUrl(e)} />
                      </i>
                  </div> */}
                  </div>
                  <label className="mb-0 ml-4">
                    <h6
                      className="mb-1"
                      style={{ fontSize: "24px", fontWeight: "normal" }}
                    >
                      {DataAkun.name}
                    </h6>
                    <h6
                      className="mb-0"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      Admin C
                    </h6>
                    <p className="mb-0">{DataAkun.company_name}</p>
                  </label>
                </div>
                <form className="form theme-form mt-4">
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="text-work d-flex"
                          style={{ fontSize: "16px" }}
                        >
                          Email{" "}
                          {terverifikasi && (
                            <span className="ml-auto text-success">
                              <i className="fa fa-check-circle-o"></i>{" "}
                              &nbsp;Terverifikasi
                            </span>
                          )}
                        </label>
                        {ubahEmail ? (
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="text-work d-flex"
                            style={{ fontSize: "16px" }}
                          >
                            {DataAkun.email}{" "}
                            <a
                              className="ml-auto btn p-0 txt-primary"
                              onClick={() => setubahEmail(!ubahEmail)}
                            >
                              <i className="fa fa-pencil"></i> <u>Ubah Email</u>
                            </a>
                          </label>
                        ) : (
                          <input
                            className="form-control text-dark"
                            type="email"
                            placeholder="name@example.com"
                            defaultValue={DataAkun.email}
                            onChange={(e) => {
                              const updateAccounts = { ...updateAccount };
                              updateAccounts.email = e.target.value;
                              setUpdateAccount(updateAccounts);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="text-work d-flex"
                          style={{ fontSize: "16px" }}
                        >
                          Nomor Telepon{" "}
                          <a
                            className="ml-auto btn p-0 txt-primary"
                            onClick={() => setubahEmail(!ubahEmail)}
                          >
                            <u>Verifkasikan nomor telepon</u>
                          </a>
                        </label>
                        {ubahHp ? (
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="text-work d-flex"
                            style={{ fontSize: "16px" }}
                          >
                            {DataAkun.phone}{" "}
                            <a
                              className="ml-auto btn p-0 txt-primary"
                              onClick={() => setubahHp(!ubahHp)}
                            >
                              <u>Ubah</u>
                            </a>
                          </label>
                        ) : (
                          <input
                            className="form-control text-dark"
                            type="number"
                            placeholder="name@example.com"
                            defaultValue={DataAkun.phone}
                            onChange={(e) => {
                              const updateAccounts = { ...updateAccount };
                              updateAccounts.phone_number = e.target.value;
                              setUpdateAccount(updateAccounts);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-check mb-3">
                        <input className="form-check-input" type="checkbox" id="defaultCheck1" onClick={() => setIsChangePassword(!isChangePassword)} />
                        <label className="form-check-label" htmlFor="defaultCheck1">
                          Ganti Password
                        </label>
                      </div>
                    </div>
                  </div>
                  {
                    isChangePassword &&
                      <>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label
                                  htmlFor="exampleFormControlInput1"
                                  className="text-work"
                                  style={{ fontSize: "16px" }}
                              >
                                Password
                              </label>
                              <input
                                  className="form-control text-dark"
                                  type="password"
                                  placeholder="New Password"
                                  defaultValue={DataAkun.password}
                                  onChange={(e) => {
                                    const updateAccounts = { ...updateAccount };
                                    updateAccounts.password = e.target.value;
                                    setUpdateAccount(updateAccounts);
                                  }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label
                                  htmlFor="exampleFormControlInput1"
                                  className="text-work"
                                  style={{ fontSize: "16px" }}
                              >
                                Konfirmasi Password
                              </label>
                              <input
                                  className="form-control text-dark"
                                  type="password"
                                  placeholder="Confirm Password"
                                  defaultValue={DataAkun.password}
                                  onChange={(e) => {
                                    const updateAccounts = { ...updateAccount };
                                    updateAccounts.confirm_password = e.target.value;
                                    setUpdateAccount(updateAccounts);
                                  }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                  }

                  <button
                    className="mr-3 btn btn-pill btn-primary btn-sm mt-3 w-100 text-work"
                    type="button"
                    style={{ fontSize: "16px", fontWeight: "600" }}
                    onClick={() => handleUpdateAccount()}
                  >
                    Simpan
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="card">
              <div className="card-header">
                <h6 style={{ fontSize: "24px", fontWeight: "normal" }}>
                  Ubah Data Profil
                </h6>
              </div>
              <div className="card-body">
                <form className="form theme-form">
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="text-work"
                          style={{ fontSize: "16px" }}
                        >
                          Nama
                        </label>
                        <input
                          className="form-control text-dark"
                          defaultValue={DataAkun.name}
                          onChange={(event) => {
                            const updateProfiles = { ...updateProfile };
                            updateProfiles.name = event.target.value;
                            setUpdateProfile(updateProfiles);
                          }}
                          placeholder="Your Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="text-work"
                          style={{ fontSize: "16px" }}
                        >
                          Jabatan
                        </label>
                        <select required defaultValue='default'
                          className="form-control"
                          name="profession_id"
                          onChange={(e) => {
                            const updateProfiles = { ...updateProfile };
                            updateProfiles.profession_id = e.target.value;
                            setUpdateProfile(updateProfiles);
                          }}
                        >
                          <option disabled selected>Position</option>
                          {
                            profession &&
                            profession.data.map(v => {
                              return <option value={v.id} selected={DataAkun.profession_id === v.name}>{v.name}</option>
                            })
                          }
                      </select>
                      </div>
                    </div>
                  </div>
                  <button
                    className="mr-3 btn btn-pill btn-primary btn-sm mt-3 w-100 text-work"
                    type="button"
                    style={{ fontSize: "16px", fontWeight: "600" }}
                    onClick={() => handleUpdate()}
                  >
                    Update Profil
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default ProfilSaya;
