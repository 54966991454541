import Get from "../Get";

const total = () => Get(`trainer/dashboard/total`);
const my_courses = (data) => Get(`trainer/dashboard/my_course?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const new_participant = data => Get(`trainer/dashboard/new_participant?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);

const DashboardTrainer = {
  total,
  my_courses,
  new_participant
};

export default DashboardTrainer;
