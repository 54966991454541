import React from 'react';
import Quiz from '../../../../../assets/images/quiz.png';

const CobaLagi = (props) => {
  const { Attempt, setView } = props;

  return (
    <div className='card p-5'>
    <div className='text-center p-4'>
      <img className="img-fluid mb-4" src={Quiz} alt="Omind Tech" style={{ width: '112.73px' }} />
      <h6 style={{ fontWeight: '600', fontSize: '16px' }}>Quiz</h6>
      <p className='text-muted' style={{ fontSize: '12px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem a viverra tristique ornare velit orci orci. Mi purus augue at at pulvinar leo.</p>
      <p className='txt-warning' style={{ fontSize: '14px', fontWeight:'600' }}>Anda belum mencapai nilai minimal</p>
      <p className={`mb-0 ml-auto mt-3 ${Attempt === 1 ? 'txt-primary' : Attempt === 2 ? 'text-warning' : 'txt-danger'}`} style={{ fontWeight: '600', fontSize: '14px' }}>Attempt {Attempt}/3</p>
      <button className="btn btn-pill btn-primary mt-2 font-weight-bold mb-3" type="button" onClick={() => setView('kuis')}> Coba Lagi </button> <br />
      {
        Attempt > 1 &&
        <button className="btn btn-pill btn-outline-primary mt-2 font-weight-bold" type="button" onClick={()=>setView('pembahasan')}> Lihat Pembahasan </button>
      }

    </div>
  </div>
  )
}

export default CobaLagi;
