import Get from "../Get";
import Post from "../Post";

const get_chairman = () => Get(`admin-c/chairman`);
const add_chairman = (data) => Post(`admin-c/chairman`, data);
const update_chairman = (data) => Post(`admin-c/chairman/update`, data);

const ChairmanAdminC = {
    get_chairman,
    add_chairman,
    update_chairman
};

export default ChairmanAdminC;
