import React from 'react';
import logo_SDM from '../../../assets/images/logo-SDM.png';
import { Progress } from 'reactstrap';
import { Link } from 'react-router-dom'

const PelatihanAktif = (props) => {
    const {
        id,
        item_judul,
        item_bg,
        status,
        online,
        item_desc,
        user_image,
        user_name,
        user_company,
        progress_item,
        PelatihanIntro,
        type
      } = props;

    return(
        <div className='border card shadow-0'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='rounded-left' style={{ backgroundImage: `url(${item_bg.replace(/ /g, "%20")})`, backgroundSize: 'cover', maxHeight: '12rem' }}>
                        <div className='h-100 d-flex flex-column p-3' style={PelatihanIntro ? { background: '#b1b1b1bf' }: {}}>
                            <div className={`badge ${PelatihanIntro ? 'badge-secondary' : 'badge-primary' } text-wrap w-50`} style={{marginBottom: '150px'}}>
                                {online}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 px-5 py-3'>
                    <h5 className={`${PelatihanIntro && 'text-muted'} font-weight-bold`}>{item_judul}</h5>
                </div>
                <div className='col-md-12 px-5 py-3'>
                    <div className="row">
                        {user_image &&
                            <div className="col-md-2">
                                <img className="img-40 rounded-circle" src={ user_image } style={{height: '40px', objectFit: 'cover'}} alt="#" />
                            </div>
                        }

                        <div className="col-md-10">
                            <h6 className={`${PelatihanIntro && 'text-muted'}  font-weight-bold mb-0`}>{user_name}</h6>
                            <p className={`${PelatihanIntro && 'text-muted'}`}>{user_company}</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 px-5 py-3'>
                    <div className='d-flex'>
                        <h6 className={`${PelatihanIntro && 'text-muted'} font-weight-bold mr-auto`}>PROGRESS PELATIHAN</h6>
                        <h6 className={`${PelatihanIntro && 'text-muted'} font-weight-bold`}>{progress_item}%</h6>
                    </div>
                    <Progress color={PelatihanIntro ? "primary" : "secondary" } value={PelatihanIntro ? 0 : progress_item} className="sm-progress-bar" />
                </div>
                <div className='col-md-12 px-5 py-3 mb-2 text-center'>
                    <Link to={`/pelatihan-detail?id=${id}`}>
                        {
                            progress_item ?
                                <button className={`btn btn-pill ${PelatihanIntro ? "btn-outline-secondary disabled" : "btn-outline-primary"} btn-sm w-75`} type="button">Lanjutkan</button>
                                :
                                <button  className={`btn btn-pill ${PelatihanIntro ? "btn-outline-secondary disabled" : "btn-primary"} btn-sm w-75`}  type="button">Mulai</button>
                        }
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default PelatihanAktif;
