import React, { useState, useEffect } from 'react';
import { Users } from 'react-feather';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useLocation } from "react-router-dom";
import PelatihanItemsCard from './PelatihanItemCard';
import API from "../../../api";

const DetailPesertaTerregistrasi = () => {
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const [participant, setParticipant] = useState(null);
  const [course, setCourse] = useState([]);
  const [coursenonactive, setCourseNonActive] = useState([]);

  const [url, setUrl] = useState('https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg');
  const PelatihanItems = [
    {
      item_judul: 'Pengenalan Lingkungan Sistem',
      item_desc: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti. Velit lobortis amet, id aliquam...',
      status:'online',
      progress_item:100
    },
    {
      item_judul: 'Mengapa Harus Online ?',
      item_desc: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti. Velit lobortis amet, id aliquam...',
      status:'Face to Face'
    },
    {
      item_judul: 'Bagaimana cara dapat sertifikat ?',
      item_desc: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti. Velit lobortis amet, id aliquam...',
      status: 'Virtual Class'
    },
  ]
  const PelatihanItemsTidakAktif = [
    {
      item_judul: 'Pengenalan Lingkungan Sistem',
      item_desc: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti. Velit lobortis amet, id aliquam...',
      status:'online',
      progress_item:100
    },
  ]

  const readUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result)
    }
  }

  useEffect(() => {
    API.ketua.participant.participant_detail(id)
    .then(json => {
      setParticipant(json.data)

      let courses = [];

      json.data.courses.map(val => {
        courses.push({
          id: val.id,
          name: val.name,
          type: val.type,
          description: val.description,
          progress: val.progress,
          status: val.status,
          due_date: val.due_date,
        });
      });

      setCourse(courses);

      let coursesnonactive = [];

      json.data.courses_nonaktif.map(val => {
        coursesnonactive.push({
          id: val.id,
          name: val.name,
          type: val.type,
          description: val.description,
          progress: val.progress,
          status: val.status,
          due_date: val.due_date,
        });
      });

      setCourseNonActive(coursesnonactive);

    });
  }, []);

  return (
    participant &&
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Peserta'}>
          <li className="breadcrumb-item">
            <Link to="/ketua/list-pelatihan" className='text-warning'>
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Pelatihan</li>
          <li className="breadcrumb-item">Peserta Terregistrasi</li>
          <li className="breadcrumb-item">{participant.name}</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className="user-profile">
            <div className="row">
              {/* <!-- user profile first-style start--> */}
              <div className="col-sm-12">
                <div className="card hovercard text-center">
                  <div className="cardheader" style={{background:''}}></div>

                  <div className="user-image ">
                    <div className="avatar ">
                      <img className="rounded" alt="" src={participant.photo} data-intro="This is Profile image" />
                    </div>
                    {/* <div className="icon-wrapper">
                      <i className="icofont icofont-pencil-alt-5" data-intro="Change Profile image here" >
                        <input className="pencil" type="file" onChange={(e) => readUrl(e)} />
                      </i>
                    </div> */}
                  </div>

                  <div className="info">
                    <div className="row detail" data-intro="This is the your details">
                      <div className="col-sm-12">
                        <div className="user-designation">
                          <h3 className="title mt-3 text-work" style={{fontSize:'24px', fontWeight:'600'}}>{participant.name}</h3>
                          <p className="desc mt-2 text-dark" style={{fontSize:'14px', fontWeight:'normal'}}>PESERTA - {participant.company_name}</p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className='row text-left mx-5'>
                      <div className='col-md-6 pr-5'>
                        <label className='text-muted text-work'>Email</label>
                        <h6 className='mb-4'>{participant.email}</h6>
                        <label className='text-muted text-work'>Tanggal Lahir</label>
                        <h6 className='mb-4'>{participant.date_of_birth}</h6>
                        <label className='text-muted text-work'>Nomor Telepon</label>
                        <h6 className='mb-4'>{participant.phone_number}</h6>
                        <label className='text-muted text-work'>NIK KTP</label>
                        <h6 className='mb-4'>{participant.nik}</h6>
                        <label className='text-muted text-work'>KTP</label>
                        <div className='mb-4'>
                          {participant.ktp.split("/")[3] && <img className='w-50' src={participant.ktp}/>}
                        </div>
                        <label className='text-muted text-work'>Alamat</label>
                        <h6 className='mb-4'>{participant.address}</h6>
                      </div>
                      <div className='col-md-6 pr-5'>
                        <label className='text-muted text-work'>Tanggal Registrasi</label>
                        <h6 className='mb-4'>{participant.registered_at}</h6>
                        <label className='text-muted text-work'>Terakhir Aktif</label>
                        <h6 className='mb-4'>{participant.last_active_at}</h6>
                        <label className='text-muted text-work'>Status</label>
                        <h6 className='mb-4'>{participant.status}</h6>
                        <label className='text-muted text-work'>Nama Perusahaan</label>
                        <h6 className='mb-4'>{participant.company_name}</h6>
                        <label className='text-muted text-work'>Alamat Kantor</label>
                        <h6 className='mb-4'>{participant.office_address}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header text-warning">
              <h4 className='font-weight-bold'>PELATIHAN YANG DIIKUTI</h4><span className='font-weight-bold'>Pelatihan Aktif</span>
            </div>
            <div className="card-body">
              {course.map((data,index)=>(
                <PelatihanItemsCard
                  key={index}
                  {...data}
                />
              ))}
               <p className='font-weight-bold text-muted mt-5'>Pelatihan Tidak Aktif</p>
              {coursenonactive.map((data,index)=>(
                <PelatihanItemsCard
                  key={index}
                  {...data}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DetailPesertaTerregistrasi;
