import React, { useState, useEffect } from 'react';
import { useLocation, Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { BreadcrumbComp } from "../../../component";
import { TransaksiIcon } from "../../../component/common/constant/menu";
import API from "../../../api";
import {checkEmpty, minToday} from "../../../helper";
import swal from 'sweetalert2';
import moment from "moment";

const EditVoucher = () => {
  let query = new URLSearchParams(useLocation().search);
  const id = query.get('id');
  const history = useHistory();

  const [dataInput, setdataInput] = useState({});
  const [companies, setCompanies] = useState([]);
  const [agreement, setAgreement] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    setIsSubmitting(true);
    if(checkEmpty(dataInput, [
      {
        name: 'nominal',
        alias: 'Nominal'
      },
      {
        name: 'start_voucher',
        alias: 'Tanggal mulai'
      },
      {
        name: 'end_voucher',
        alias: 'Tanggal akhir'
      },
      {
        name: 'description',
        alias: 'Deskripsi'
      },
      {
        name: 'company_id',
        alias: 'Perusahaan'
      }
    ])){
      if(moment(dataInput.end_voucher).diff(moment(dataInput.start_voucher)) < 1){
        swal.fire('Error', 'Tanggal mulai tidak boleh lebih dari tanggal selesai', 'error');
        setIsSubmitting(false);
      }
      else{
        API.superadmin.vouchers
            .edit_voucher(dataInput.voucher_id, dataInput)
            .then((json) => {
              swal.fire('Sukses', 'Sukses update voucher', 'success')
                  .then(res => {
                    history.push('list-voucher')
                  });
            }).catch((e) => {
              swal.fire('Error', e.message, 'error');
            });
      }
    }
    else{
      setIsSubmitting(false);
    }
  };

  const handleAgreement = () => {
    setAgreement(!agreement);
  }

  useEffect(() => {
    API.superadmin.company.list_perusahaan({per_page: 9999})
        .then(json => {
          setCompanies(json.data.data);
        });

    API.superadmin.vouchers.detail_voucher(id)
        .then(json => {
          let data = json.data;
          setdataInput({
            voucher_id: data.voucher_id,
            code: data.code,
            nominal: data.nominal,
            start_voucher: data.start_voucher,
            end_voucher: data.end_voucher,
            description: data.description,
            company_id: data.company_id,
          });
        });
  }, []);

  const labelForm = (text) => (
    <label className='text-work mt-4 d-block' style={{ fontSize: '16px', fontWeight: "400", color: "#797979" }}>{text}</label>
  )

  const handleChange = (e) => {
    const { name, value } = e.target;

    setdataInput({
      ...dataInput,
      [name]: value
    })
  }

  return (
    <>
      <div className="page-header d-flex">
        <BreadcrumbComp title={"List Voucher"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/edit-voucher" className="text-warning">
              <TransaksiIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">List Voucher</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 text-center">
              <h3 className="text-work " style={{ fontSize: "24px", fontWeight: '600', color: "#797979" }}>Detail Voucher</h3>
            </div>
            <div className="card-body">
              <form className="row">
                <div className="col-md-8">
                  <div className='form-group mt-3'>
                    {labelForm('Kode Voucher')}
                    <h6 style={{ fontSize: "16px", fontWeight: "600", color: "#313131 " }}>{dataInput.code}</h6>
                  </div>
                  <div className='form-group'>
                    {labelForm('Perusahaan')}
                    <select className="form-control digits " style={{ width: "274px" }} name="company_id" onChange={handleChange} required>
                      <option value="default" disabled selected>Pilih Perusahaan</option>
                      {
                        companies.map(v => (
                            <option value={v.id} selected={dataInput.company_id === v.id}>{v.name}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className='form-group mt-3'>
                    {labelForm('Tanggal Berlaku')}
                    <div className='d-flex align-items-center'>
                      <input
                          type="date"
                          className="form-control"
                          placeholder="Pilih tanggal"
                          name="start_voucher"
                          min={minToday()}
                          style={{ width: "150px" }}
                          onChange={handleChange}
                          value={dataInput.start_voucher}
                      />
                      <p className='mb-0 text-nunito mx-3' style={{ fontSize: '12px' }}>hingga</p>
                      <input
                          type="date"
                          className="form-control"
                          placeholder="Pilih tanggal"
                          name="end_voucher"
                          min={minToday()}
                          style={{ width: "150px" }}
                          onChange={handleChange}
                          value={dataInput.end_voucher}
                      />
                    </div>
                  </div>
                  <div className='form-group mt-3'>
                    {labelForm('Keterangan')}
                    <textarea className="form-control" name="description" placeholder="Masukkan keterangan penggunaan voucher" style={{ height: "94px", width: "409px" }} onChange={handleChange} required defaultValue={dataInput.description} >{dataInput.description}</textarea>
                  </div>
                  <div className="form-group">
                    <div className="form-check">
                      <div className="checkbox p-0">
                        <input className="form-check-input" id="invalidCheck" type="checkbox" onClick={handleAgreement} />
                        <label className="form-check-label" htmlFor="invalidCheck">Saya menyetujui bahwa setiap nilai voucher akan otomatis ditagihkan kepada perusahaan ketika terpakai dan sepenuhnya menjadi tanggung jawab perusahaan</label>
                      </div>
                      <div className="invalid-feedback">Ceklis</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className='form-group mt-3'>
                    {labelForm('Status')}
                    <span className="btn btn-secondary">Draft</span>
                  </div>
                  <div className="form-group mb-0">
                    {labelForm('Nominal')}
                    <input
                      type="number"
                      name="nominal"
                      className="form-control"
                      placeholder="Isi nominal voucher"
                      style={{ width: "134px" }}
                      value={dataInput.nominal}
                      onChange={handleChange}
                      required />
                  </div>
                </div>
                <div className="col-md-12 m-5 border-top py-3">
                  <div className="text-right mb-5">
                    <button type="cancel" className="btn btn-outline-primary btn-pill mr-2">Batal</button>
                    <button type="button" className="btn btn-primary btn-pill" onClick={handleSubmit} disabled={agreement ? isSubmitting : true}>Simpan</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditVoucher;
