import React, { useState, useEffect, useRef } from "react";
import { BreadcrumbComp } from "../../../component";
import { Link, useLocation, useHistory } from "react-router-dom";
import { CompanyIcon } from "../../../component/common/constant/menu";
import { Editor } from "react-draft-wysiwyg";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import API from "../../../api";
import {checkEmpty} from "../../../helper";
import swal from "sweetalert2";

const TambahPerusahaan = () => {
  let history = useHistory();
  const imgAdminRef = useRef(null);
  const imgChairmanRef = useRef(null);
  const [imagePreview, setimagePreview] = useState("");
  const [imagePreviewUrl, setimagePreviewUrl] = useState("");
  const [ktpPreview, setktpPreview] = useState("");
  const [ktpPreviewUrl, setktpPreviewUrl] = useState("");
  const [adminPreview, setAdminPreview] = useState("");
  const [adminPreviewUrl, setAdminPreviewUrl] = useState("");
  const [chairmanPreview, setChairmanPreview] = useState("");
  const [chairmanPreviewUrl, setChairmanPreviewUrl] = useState("");
  const [listProvinsi, setListProvinsi] = useState([]);
  const [listKota, setListKota] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);

  const [provinsi, setProvinsi] = useState("");
  const [kabupaten, setKabupaten] = useState("");
  const [list_category, setListCategory] = useState([]);
  const [buttonCategory, setButtonCategory] = useState([]);
  const [date, setDate] = useState(new Date());
  const [shortProfile, setShortProfile] = useState(null);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [dataCompany, setDataCompany] = useState({
    since: new Date()
  });

  const labelForm = (text) => (
    <label className="text-work" style={{ fontSize: "16px" }}>
      {text}
    </label>
  );

  const handleDate = (event) => {
    const parseDate = new Date(event);
    setDate(event);
    let day = parseDate.getDate();
    let month = parseDate.getMonth();
    let year = parseDate.getFullYear();
    let date = [year, month, day].join("-");
    setDataCompany({
      ...dataCompany,
      since: date,
    });
  };

  const handleShortProfile = (event) => {
    setShortProfile(event.blocks[0].text);
    setDataCompany({
      ...dataCompany,
      short_profile: event.blocks[0].text,
    });
  };

  const handleCategory = (type, data) => {
    const dataCategory = Object.assign([], dataCompany.categories);
    if (type === "add") {
      if(!dataCategory.includes(data.id)){
        dataCategory.push(data.id);
        buttonCategory.push(data);
      }
    } else {
      var index = dataCompany.categories.indexOf(data.id);
      dataCategory.splice(index, 1);
      buttonCategory.splice(index, 1);
    }
    setDataCompany({
      ...dataCompany,
      categories: dataCategory,
    });
  };

  const handleLogoChange = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setDataCompany({
      ...dataCompany,
      logo: file,
    });

    reader.onloadend = () => {
      setimagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleChairmanPhotoChange = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setDataCompany({
      ...dataCompany,
      chairman_photo: file,
    });

    reader.onloadend = () => {
      setChairmanPreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleAdminPhotoChange = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setDataCompany({
      ...dataCompany,
      admin_photo: file,
    });

    reader.onloadend = () => {
      setAdminPreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleKtpChange = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setDataCompany({
      ...dataCompany,
      director_ktp: file,
    });

    reader.onloadend = () => {
      setktpPreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (ktpPreviewUrl) {
      setktpPreview(ktpPreviewUrl);
    } else {
      setktpPreview(false);
    }
  }, [ktpPreviewUrl]);

  useEffect(() => {
    if (imagePreviewUrl) {
      setimagePreview(imagePreviewUrl);
    } else {
      setimagePreview(false);
    }
  }, [imagePreviewUrl]);

  useEffect(() => {
    if (adminPreviewUrl) {
      setAdminPreview(adminPreviewUrl);
    } else {
      setAdminPreview(false);
    }
  }, [adminPreviewUrl]);

  useEffect(() => {
    if (chairmanPreviewUrl) {
      setChairmanPreview(chairmanPreviewUrl);
    } else {
      setChairmanPreview(false);
    }
  }, [chairmanPreviewUrl]);

  useEffect(() => {
    API.category
      .get_all()
      .then((json) => {
        setListCategory(json.data);
      })
      .catch((err) => {
        console.log(err);
      });
    API.rajaongkir
      .list_provinsi()
      .then((json) => {
        setListProvinsi(json);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (event) => {
    setDataCompany({
      ...dataCompany,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if(checkEmpty(dataCompany, [
      {
        name: 'name',
        alias: 'Nama',
      },
      {
        name: 'logo',
        alias: 'Logo',
      },
      {
        name: 'categories',
        alias: 'Kategori',
      },
      {
        name: 'postal_code',
        alias: 'Kode pos',
      },
      {
        name: 'address',
        alias: 'Alamat',
      },
      {
        name: 'phone_number',
        alias: 'Nomor telepon',
      },
      {
        name: 'email',
        alias: 'E-mail',
      },
      {
        name: 'since',
        alias: 'Tahun berdiri',
      },
      {
        name: 'short_profile',
        alias: 'Profil singkat',
      },
      {
        name: 'director_name',
        alias: 'Nama direktur',
      },
      {
        name: 'director_ktp',
        alias: 'KTP direktur',
      },
      {
        name: 'admin_name',
        alias: 'Nama Admin C',
      },
      {
        name: 'admin_email',
        alias: 'E-mail Admin C',
      },
      {
        name: 'admin_phone',
        alias: 'Nomor Telepon Admin C',
      },
      {
        name: 'admin_photo',
        alias: 'Foto Admin C',
      },
      {
        name: 'chairman_name',
        alias: 'Nama Ketua Klien',
      },
      {
        name: 'chairman_email',
        alias: 'E-mail Ketua Klien',
      },
      {
        name: 'chairman_phone',
        alias: 'Nomor Telepon Ketua Klien',
      },
      {
        name: 'chairman_photo',
        alias: 'Foto Ketua Klien',
      },
    ])){
      let data = {
        ...dataCompany,
        categories: "[" + dataCompany.categories.toString() + "]",
      };

      const onUploadProgress = ({loaded, total}) => {
        let percent = Math.floor((loaded * 100) / total);
        setUploadPercent(percent);
      };

      API.superadmin.company
          .tambah_perusahaan(data, onUploadProgress)
          .then((json) => {
            setIsSubmitting(false);
            swal.fire('Sukses', json.message, 'success').then(res => {
              history.push("/superadmin/list-perusahaan");
            });
          })
          .catch((err) => {
            setIsSubmitting(false);
            let errors = Object.values(err.errors);
            let errors_msg = '';

            errors.map(val => {
              errors_msg += val+', ';
            });

            swal.fire('Gagal', errors_msg, 'error');
          });
    }
    else
      setIsSubmitting(false);
  };
  return (
    <>
      <ToastContainer />
      <div className="page-header">
        <BreadcrumbComp title={"Tambah Perusahaan"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/tambah-perusahaan" className="text-warning">
              <CompanyIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Perusahaan</li>
          <li className="breadcrumb-item">Tambah Perusahaan</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header d-flex pb-2">
              <div className="mr-auto">
                <h4
                  className="text-theme text-work"
                  style={{ fontWeight: "600" }}
                >
                  TAMBAH PERUSAHAAN BARU
                </h4>
                <span className="text-muted">Isi Data Perusahaan</span>
              </div>
            </div>
            <div className="card-body pt-4 pb-3">
              <h6 className="text-muted mb-3" style={{ fontWeight: "700" }}>
                Informasi Perusahaan
              </h6>
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      {labelForm("Nama Perusahaan")}
                      <input
                        type="text"
                        className="form-control"
                        id="namaPelatihan"
                        placeholder="Masukan nama pelatihan"
                        name="name"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      {labelForm("Alamat")}
                      <div className="d-flex">
                        <div className="form-group mr-5">
                          <select
                            name="provinsi"
                            className="form-control"
                            id="provinsi"
                            style={{ width: "200px" }}
                            onChange={(e) => {
                              setProvinsi(e.target.value);
                              setListKota([]);
                              setListKecamatan([]);

                              API.rajaongkir
                                .list_kota(e.target.value)
                                .then((json) => {
                                  setListKota(json);
                                });
                            }}
                          >
                            <option value="#">Provinsi</option>
                            {listProvinsi.map((v) => {
                              return (
                                <option value={v.province_id}>
                                  {v.province}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <select
                            name="kabupaten"
                            className="form-control"
                            id="kabupaten"
                            style={{ width: "200px" }}
                            onChange={(e) => {
                              setKabupaten(e.target.value);
                              setListKecamatan([]);

                              API.rajaongkir
                                .list_kecamatan(e.target.value)
                                .then((json) => {
                                  setListKecamatan(json);
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            }}
                          >
                            <option value="#">Kota / Kabupaten</option>
                            {listKota.map((v) => {
                              return (
                                <option value={v.city_id}>{v.type} {v.city_name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-group mr-5">
                          <select
                            className="form-control"
                            id="kecamatan"
                            style={{ width: "200px" }}
                            name="region_id"
                            onChange={handleChange}
                          >
                            <option value="#">Kecamatan</option>
                            {listKecamatan.map((v) => (
                              <option value={v.subdistrict_id}>
                                {v.subdistrict_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            id="kodePos"
                            placeholder="Masukkan kode pos"
                            name="postal_code"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <textarea
                        className="form-control"
                        id="alamatKantor"
                        placeholder="Nama Jalan / Alamat Detail"
                        rows="5"
                        style={{ width: "530px" }}
                        name="address"
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      {labelForm("Nomor Telepon")}
                      <input
                        className="form-control"
                        id="namaPelatihan"
                        placeholder="Masukan nomor telepon"
                        name="phone_number"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      {labelForm("Email")}
                      <input
                        type="email"
                        className="form-control"
                        id="namaPelatihan"
                        placeholder="Masukan email perusahaan"
                        name="email"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ml-5 justify-content-center align-items-center">
                      {labelForm("Tahun Berdiri")}
                      <DatePicker
                        className="form-control"
                        name="since"
                        onChange={handleDate}
                        selected={date}
                      />
                      {/* <input
                        type="year"
                        className="form-control"
                        style={{ width: "200px" }}
                        name="since"
                        onChange={handleChange}
                      /> */}
                    </div>
                    <div
                      className="ml-5 d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#F1F3F6",
                        width: "200px",
                        height: "200px",
                        backgroundImage: `url(${imagePreview})`,
                        backgroundSize: "cover",
                        borderRadius: "20px",
                      }}
                    >
                      {imagePreview ? null : (
                        <p
                          style={{
                            color: "#8B8B8B",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          Tambah Logo Perusahaan
                        </p>
                      )}
                    </div>
                    <div className="form-group">
                      <div className="button-custom w-50 py-3 ml-5 mt-3">
                        <div className="btn btn-pill btn-primary upload-image">
                          <span>
                            <i className="fa fa-cloud-upload"></i> &ensp; Upload
                            Logo
                          </span>
                          <input
                            id="file-select"
                            type="file"
                            onChange={handleLogoChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="border-bottom py-3">
                      {dataCompany && (
                        <div className="form-group">
                          {labelForm("Bidang Pelatihan")}
                          {/*<input
                            type="text"
                            className="form-control"
                            id="namaPelatihan"
                            placeholder="Masukan bidang pelatihan"
                            style={{ width: 400 }}
                          />*/}
                          <br/>
                          {buttonCategory.length > 0 &&
                            buttonCategory.map((value) => {
                              return (
                                <button
                                  className={`btn btn-warning btn-s text-capitalize mr-1 mt-3 p-1`}
                                  type="button"
                                  onClick={() => handleCategory("", value)}
                                >
                                  {value.name} x
                                </button>
                              );
                            })}
                          <div className="row mt-3">
                            <div className="col-md-3">
                              {list_category.map((value, index) => {
                                if (index < 9) {
                                  return (
                                    <button
                                      className="btn btn-link text-muted text-roboto font-weight-bold"
                                      type="button"
                                      onClick={() =>
                                        handleCategory("add", value)
                                      }
                                    >
                                      <i className="fa fa-plus-circle"></i>{" "}
                                      &ensp; {value.name}
                                    </button>
                                  );
                                }
                              })}
                            </div>
                            <div className="col-md-3">
                              {list_category.map((value, index) => {
                                if (index > 9 && index < 19) {
                                  return (
                                    <button
                                      className="btn btn-link text-muted text-roboto font-weight-bold"
                                      type="button"
                                      onClick={() =>
                                        handleCategory("add", value)
                                      }
                                    >
                                      <i className="fa fa-plus-circle"></i>{" "}
                                      &ensp; {value.name}
                                    </button>
                                  );
                                }
                              })}
                            </div>
                            <div className="col-md-3">
                              {list_category.map((value, index) => {
                                if (index > 19 && index < 29) {
                                  return (
                                    <button
                                      className="btn btn-link text-muted text-roboto font-weight-bold"
                                      type="button"
                                      onClick={() =>
                                        handleCategory("add", value)
                                      }
                                    >
                                      <i className="fa fa-plus-circle"></i>{" "}
                                      &ensp; {value.name}
                                    </button>
                                  );
                                }
                              })}
                            </div>
                            <div className="col-md-3">
                              {list_category.map((value, index) => {
                                if (index > 29 && index < 39) {
                                  return (
                                    <button
                                      className="btn btn-link text-muted text-roboto font-weight-bold"
                                      type="button"
                                      onClick={() =>
                                        handleCategory("add", value)
                                      }
                                    >
                                      <i className="fa fa-plus-circle"></i>{" "}
                                      &ensp; {value.name}
                                    </button>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-group">
                        {labelForm("Profil Singkat")}
                        <Editor
                          toolbarClassName="border-bottom"
                          wrapperClassName="border"
                          editorClassName="p-3"
                          name="short_profile"
                          onChange={handleShortProfile}
                        />
                      </div>
                      <div className="form-group">
                        {labelForm("Nama Direktur")}
                        <input
                          type="text"
                          className="form-control"
                          id="namaPelatihan"
                          placeholder="Masukan nama direktur"
                          style={{ width: "400px" }}
                          name="director_name"
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          backgroundColor: "#F1F3F6",
                          width: "200px",
                          height: "200px",
                          backgroundImage: `url(${ktpPreview})`,
                          backgroundSize: "cover",
                          borderRadius: "20px",
                        }}
                      >
                        {ktpPreview ? null : (
                          <p
                            style={{
                              color: "#8B8B8B",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            Tambah KTP Direktur
                          </p>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="button-custom w-25 py-3 mt-3">
                          <div className="btn btn-pill btn-primary upload-image">
                            <span>
                              <i className="fa fa-cloud-upload"></i> &ensp;
                              Upload KTP Direktur
                            </span>
                            <input
                              id="file-select"
                              type="file"
                              onChange={handleKtpChange}
                            />
                          </div>
                        </div>
                      </div>
                      <p style={{ color: "red" }}>
                        *Ukuran Foto KTP Maksimal 100KB
                      </p>
                    </div>
                    <div className="border-bottom py-3">
                      <h6
                        className="text-muted mb-3"
                        style={{ fontWeight: "700" }}
                      >
                        Kontak Perusahaan
                      </h6>
                      <div className="row">
                        <div className="col-md-6">
                          <p>PIC Admin</p>
                          <div className="d-flex mb-2">
                            <div className="form-group mt-4 text-left">
                              <div
                                className="button-custom m-0"
                                style={{
                                  height: "92px",
                                  width: "92px",
                                }}
                              >
                                <div
                                  className="upload-image d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    imgAdminRef.current.click();
                                  }}
                                  style={{
                                    position: "relative",
                                    height: "92px",
                                    width: "92px",
                                    color: "#C4C4C4",
                                    backgroundColor: "#F2F2F2",
                                    fontSize: "35px",
                                    borderRadius: "50% 50%",
                                    cursor: 'pointer'
                                  }}
                                >
                                    <span>
                                      {
                                        adminPreview ?
                                        <img style={{
                                          borderRadius: "50% 50%",
                                          height: "92px",
                                          width: "92px",
                                        }} src={adminPreview} alt=""/> :
                                        <i className="fa fa-camera"></i>
                                      }
                                    </span>
                                  <input
                                    id="file-select"
                                    type="file"
                                    onChange={handleAdminPhotoChange}
                                    ref={imgAdminRef}
                                    style={{
                                      height: "92px",
                                      width: "92px",
                                      display: 'none'
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <label className="mb-0 ml-2">
                              <div className="form-group">
                                {labelForm("Nama")}
                                <input
                                  type="text"
                                  className="form-control"
                                  id="namaPelatihan"
                                  placeholder="Masukan nama"
                                  style={{ width: "400px" }}
                                  name="admin_name"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group">
                                {labelForm("Email")}
                                <input
                                  type="email"
                                  className="form-control"
                                  id="namaPelatihan"
                                  placeholder="Masukan email"
                                  style={{ width: "400px" }}
                                  name="admin_email"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group">
                                {labelForm("Nomor Telepon")}
                                <input
                                  type="text"
                                  className="form-control"
                                  id="namaPelatihan"
                                  placeholder="Masukan nomor telepon"
                                  style={{ width: "400px" }}
                                  name="admin_phone"
                                  onChange={handleChange}
                                />
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p>PIC Ketua</p>
                          <div className="d-flex mb-2">
                            <div className="form-group mt-4 text-left">
                              <div
                                className="button-custom m-0"
                                style={{
                                  height: "92px",
                                  width: "92px",
                                }}
                              >
                                <div
                                    className="upload-image d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                      imgChairmanRef.current.click();
                                    }}
                                    style={{
                                      position: "relative",
                                      height: "92px",
                                      width: "92px",
                                      color: "#C4C4C4",
                                      backgroundColor: "#F2F2F2",
                                      fontSize: "35px",
                                      borderRadius: "50% 50%",
                                      cursor: 'pointer'
                                    }}
                                >
                                    <span>
                                      {
                                        chairmanPreview ?
                                            <img style={{
                                              borderRadius: "50% 50%",
                                              height: "92px",
                                              width: "92px",
                                            }} src={chairmanPreview} alt=""/> :
                                            <i className="fa fa-camera"></i>
                                      }
                                    </span>
                                  <input
                                      id="file-select"
                                      type="file"
                                      onChange={handleChairmanPhotoChange}
                                      ref={imgChairmanRef}
                                      style={{
                                        height: "92px",
                                        width: "92px",
                                        display: 'none'
                                      }}
                                  />
                                </div>
                              </div>
                            </div>
                            <label className="mb-0 ml-2">
                              <div className="form-group">
                                {labelForm("Nama")}
                                <input
                                  type="text"
                                  className="form-control"
                                  id="namaPelatihan"
                                  placeholder="Masukan nama"
                                  style={{ width: "400px" }}
                                  name="chairman_name"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group">
                                {labelForm("Email")}
                                <input
                                  type="email"
                                  className="form-control"
                                  id="namaPelatihan"
                                  placeholder="Masukan email"
                                  style={{ width: "400px" }}
                                  name="chairman_email"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group">
                                {labelForm("Nomor Telepon")}
                                <input
                                  type="text"
                                  className="form-control"
                                  id="namaPelatihan"
                                  placeholder="Masukan nomor telepon"
                                  style={{ width: "400px" }}
                                  name="chairman_phone"
                                  onChange={handleChange}
                                />
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isSubmitting && <>
                      <h3 className='text-center mt-4'>{uploadPercent}%</h3>
                      <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{width: uploadPercent+'%'}} aria-valuenow="0"
                             aria-valuemin="0" aria-valuemax="100"/>
                      </div>
                    </>}
                    <div className="my-5 d-flex align-items-center justify-content-end">
                      <button type='button' className="btn btn-outline-primary btn-pill mr-3"
                      onClick={() => {
                        history.goBack();
                      }}
                      >
                        Batal
                      </button>
                      <button
                        className="btn btn-primary btn-pill"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TambahPerusahaan;
