import React, {useEffect, useState} from 'react';
import { Input, Modal, ModalBody } from 'reactstrap';
import congratulation from '../../../../../assets/images/congratulation.png';
import error from '../../../../../assets/images/error.png';
import API from "../../../../../api";
import swal from 'sweetalert2';

const Kuis = (props) => {
  const { Attempt, setView, id, setAttempt, setResultProps } = props;
  const [posisiSoal, setposisiSoal] = useState(0);
  const [modalSelesai, setmodalSelesai] = useState(false);
  const [modalLulus, setmodalLulus] = useState(false);
  const [modalCobaLagi, setmodalCobaLagi] = useState(false);
  const [modalHubungi, setmodalHubungi] = useState(false);
  const [NilaiKuis, setNilaiKuis] = useState('hubungi');
  const [namaKuis, setNamaKuis] = useState('');
  const [answers, setAnswers] = useState([]);
  const [result, setResult] = useState(null);

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    API.peserta.course.show_quiz(id)
        .then(json => {
          let pertanyaan = [];

          setNamaKuis(json.data.name);

          json.data.data.map(v => {
            pertanyaan.push({
              soal: v.question,
              a: [v.quiz_options[0].id, v.quiz_options[0].description],
              b: [v.quiz_options[1].id, v.quiz_options[1].description],
              c: [v.quiz_options[2].id, v.quiz_options[2].description],
              d: [v.quiz_options[3].id, v.quiz_options[3].description],
            })
          });
          setQuestions(pertanyaan.reverse());
        })
  }, []);

  const handleAnswer = (no, ans) => {
    let jawaban = [...answers];
    jawaban[no] = ans;
    setAnswers(jawaban);
  };

  const handleHasilKuis = () => {
    swal.fire({
      onBeforeOpen () {
        swal.showLoading()
      },
      onAfterClose () {
        swal.hideLoading()
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    });
    API.peserta.course.answer_quiz(id, {
      quiz_option_id: JSON.stringify(answers),
      attempt: Attempt
    }).then(json => {
      swal.close();
      let statusQuiz = json.data.information;
      setResultProps(json.data);
      setResult(json.data);
      if(statusQuiz === 'Pass'){
        setmodalLulus(!modalLulus)
      } else if (statusQuiz === 'Coba Lagi'){
        setAttempt(Attempt + 1);
        setmodalCobaLagi(!modalCobaLagi)
      } else {
        setmodalHubungi(!modalHubungi)
      }
    });
  /*  if(NilaiKuis === 'lulus'){
      setmodalLulus(!modalLulus)
    } else if (NilaiKuis === 'cobalagi'){
      setmodalCobaLagi(!modalCobaLagi)
    } else {
      setmodalHubungi(!modalHubungi)
    }*/
  }

  return (
    <>
      <p className={`text-right mb-0 ml-auto mt-3 ${Attempt === 1 ? 'txt-primary' : Attempt === 2 ? 'text-warning' : 'txt-danger'}`} style={{ fontWeight: '600', fontSize: '14px' }}>Attempt {Attempt}/3</p>
      {
        questions.length > 0 &&
        <div className='card p-5'>
          <p>{posisiSoal + 1}. {questions[posisiSoal].soal}</p>
          <div className='ml-3'>
            <label>
              <Input type="radio" name="radio1" checked={answers[posisiSoal] === questions[posisiSoal].a[0]} onClick={() => handleAnswer(posisiSoal, questions[posisiSoal].a[0])} />{' '}
              {questions[posisiSoal].a[1]}
            </label> <br />
            <label>
              <Input type="radio" name="radio1" checked={answers[posisiSoal] === questions[posisiSoal].b[0]} onClick={() => handleAnswer(posisiSoal, questions[posisiSoal].b[0])} />{' '}
              {questions[posisiSoal].b[1]}
            </label><br />
            <label>
              <Input type="radio" name="radio1" checked={answers[posisiSoal] === questions[posisiSoal].c[0]} onClick={() => handleAnswer(posisiSoal, questions[posisiSoal].c[0])} />{' '}
              {questions[posisiSoal].c[1]}
            </label><br />
            <label>
              <Input type="radio" name="radio1" checked={answers[posisiSoal] === questions[posisiSoal].d[0]} onClick={() => handleAnswer(posisiSoal, questions[posisiSoal].d[0])} />{' '}
              {questions[posisiSoal].d[1]}
            </label>
          </div>
        </div>
      }
      <div className='d-flex justify-content-end'>
        {
          posisiSoal > 0 &&
          <button className="btn btn-outline-primary btn-sm text-work px-3" type="button" style={{ fontWeight: '600' }} onClick={() => setposisiSoal(posisiSoal - 1)} >Sebelumnya</button>
        }
        <p className='my-auto mx-3'>{posisiSoal + 1}/{questions.length} soal</p>
        {posisiSoal + 1 === questions.length ?
          <button className=" btn btn-primary btn-sm text-work px-3" type="button" style={{ fontWeight: '600' }} onClick={() => setmodalSelesai(!modalSelesai)}>Selesai</button>
          :
          <button className=" btn btn-primary btn-sm text-work px-3" type="button" style={{ fontWeight: '600' }} onClick={() => setposisiSoal(posisiSoal + 1)} >Selanjutnya</button>
        }
      </div>

      <Modal isOpen={modalSelesai} toggle={() => setmodalSelesai(!modalSelesai)}>
        <ModalBody>
          <div className='text-center mt-3'>
            <h6>Apakah Anda Yakin Ingin Menyelesaikan Quiz?</h6>
            <p>Pastikan jawaban anda sudah benar</p>
            <button
              className="btn btn-pill btn-primary btn-sm my-3 mr-3"
              type="button"
              onClick={handleHasilKuis}
            > Ya
                </button>
            <button
              className="btn btn-pill btn-outline-primary btn-sm my-3"
              type="button"
              onClick={() => setmodalSelesai(!modalSelesai)}
            > Tidak
                </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalLulus}>
        {
          result &&
          <ModalBody>
            <div className='text-center mt-3'>
              <img className="img-fluid mb-4" src={congratulation} alt="Omind Tech" style={{ width: '112.73px' }} />
              <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'18px'}}>Selamat Anda Lulus Quiz</h6>
              <p>{namaKuis}</p>
              <hr />
              <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'18px'}}>Hasil Ujian Anda</h6>
              <div className='row mx-3 mt-3'>
                <div className='col-md-3 text-center'>
                  <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Benar</h6>
                  <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'18px'}}>{result.correct_answers}</h6>
                </div>
                <div className='col-md-3 text-center'>
                  <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Salah</h6>
                  <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'18px'}}>{result.wrong_answers}</h6>
                </div>
                <div className='col-md-3 text-center'>
                  <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Nilai</h6>
                  <h6 className='text-nunito txt-success' style={{fontWeight:'800', fontSize:'18px'}}>{Number(result.score.toFixed(2))}</h6>
                </div>
                <div className='col-md-3 text-center'>
                  <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Keterangan</h6>
                  <h6 className='text-nunito txt-success' style={{fontWeight:'800', fontSize:'18px'}}>{result.information}</h6>
                </div>

              </div>
              <button
                  className="btn btn-pill btn-outline-primary btn-sm my-3 mr-3"
                  type="button"
                  onClick={() => setView('lulus')}
              > Lihat Hasil
              </button>
              <button
                  className="btn btn-pill btn-primary btn-sm my-3 "
                  type="button"
                  onClick={() => {
                    setView('lulus')
                  }}
              > Kembali
              </button>
            </div>
          </ModalBody>
        }

      </Modal>

      <Modal isOpen={modalCobaLagi}>
        {
          result &&
          <ModalBody>
            <div className='text-center mt-3'>
              <img className="img-fluid mb-4" src={error} alt="Omind Tech" style={{ width: '112.73px' }} />
              <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'18px'}}>Maaf, Silakan Mencoba Lagi</h6>
              <p>{namaKuis}</p>
              <hr />
              <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'18px'}}>Hasil Ujian Anda</h6>
              <div className='row mt-3'>
                <div className='col-md-3 text-center'>
                  <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Benar</h6>
                  <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'18px'}}>{result.correct_answers}</h6>
                </div>
                <div className='col-md-3 text-center'>
                  <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Salah</h6>
                  <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'18px'}}>{result.wrong_answers}</h6>
                </div>
                <div className='col-md-3 text-center'>
                  <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Nilai</h6>
                  <h6 className='text-nunito txt-danger' style={{fontWeight:'800', fontSize:'18px'}}>{Number(result.score.toFixed(2))}</h6>
                </div>
                <div className='col-md-3 text-center'>
                  <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Keterangan</h6>
                  <h6 className='text-nunito txt-danger' style={{fontWeight:'800', fontSize:'18px'}}>{result.information}</h6>
                </div>

              </div>
              <button
                  className="btn btn-pill btn-outline-primary btn-sm my-3 mr-3"
                  type="button"
                  onClick={() => setView('cobalagi')}
              > Lihat Hasil
              </button>
              <button
                  className="btn btn-pill btn-primary btn-sm my-3 "
                  type="button"
                  onClick={() => {
                    setView('cobalagi')
                  }}
              > Kembali
              </button>
            </div>
          </ModalBody>
        }
      </Modal>

      <Modal isOpen={modalHubungi}>
        {
          result &&
          <ModalBody>
            <div className='text-center mt-3'>
              <img className="img-fluid mb-4" src={error} alt="Omind Tech" style={{ width: '112.73px' }} />
              <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'18px'}}>Maaf, Anda Belum Lulus Quiz</h6>
              <p>{namaKuis}</p>
              <hr />
              <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'18px'}}>Hasil Ujian Anda</h6>
              <div className='row mt-3'>
                <div className='col-md-3 text-center'>
                  <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Benar</h6>
                  <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'18px'}}>{result.correct_answers}</h6>
                </div>
                <div className='col-md-3 text-center'>
                  <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Salah</h6>
                  <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'18px'}}>{result.wrong_answers}</h6>
                </div>
                <div className='col-md-3 text-center'>
                  <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Nilai</h6>
                  <h6 className='text-nunito txt-danger' style={{fontWeight:'800', fontSize:'18px'}}>{Number(result.score.toFixed(2))}</h6>
                </div>
                <div className='col-md-3 text-center'>
                  <h6 className='text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>Keterangan</h6>
                  <h6 className='text-nunito txt-danger' style={{fontWeight:'800', fontSize:'18px'}}>Hubungi Trainer</h6>
                </div>

              </div>
              <button
                  className="btn btn-pill btn-outline-primary btn-sm my-3 mr-3"
                  type="button"
                  onClick={() => setView('hubungi')}
              > Lihat Hasil
              </button>
              <button
                  className="btn btn-pill btn-primary btn-sm my-3 "
                  type="button"
                  onClick={() => {
                    setView('hubungi')
                  }}
              > Kembali
              </button>
            </div>
          </ModalBody>
        }
      </Modal>
    </>
  )
}

export default Kuis
