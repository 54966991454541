import React, {useState, useEffect} from 'react';
import {convertDate} from "../../../../../helper";
import API from "../../../../../api";

const Pembahasan = (prop) => {
  const {id, name, result} = prop;

  const [pembahasan, setPembahasan] = useState([]);

  useEffect(() => {
      API.peserta.course.discussion_quiz(result.id)
          .then(json => {
              let temp_pembahasan = [];

              json.data.map(v => {
                  temp_pembahasan.push({
                      soal: v.question,
                      jawaban_peserta: v.user_answer,
                      jawaban_benar: v.answer,
                      status: v.user_answer === v.answer,
                      penjelasan: v.discussion
                  })
              });

              setPembahasan(temp_pembahasan);
          })
  }, []);

/*  const Soal = [
    {
      soal:'Candi Borobudur merupakan kenampakan buatan yang merupakan ikon dari provinsi ….',
      jawaban_peserta: 'Jawa Tengah',
      jawaban_benar: 'Jawa Tengah',
      status: true,
      penjelasan:'Candi Borrobudur adalah sebuah candi Buddha yang terletak di Borobudur, Magelang, Jawa Tengah, Indonesia. Candi ini terletak kurang lebih 100 km di sebelah barat daya Semarang, 86 km di sebelah barat Surakarta, dan 40 km di sebelah barat laut Yogyakarta.'
    },
    {
      soal:'Skala peta 1: 250.000, yaitu setiap 1 cm pada peta sesuai dengan … ',
      jawaban_peserta: '25 km',
      jawaban_benar: '2.5 km',
      status: false,
      penjelasan:'Candi Borrobudur adalah sebuah candi Buddha yang terletak di Borobudur, Magelang, Jawa Tengah, Indonesia. Candi ini terletak kurang lebih 100 km di sebelah barat daya Semarang, 86 km di sebelah barat Surakarta, dan 40 km di sebelah barat laut Yogyakarta.'
    },
    {
      soal:'Proyeksi peta diperoleh dengan memproyeksikan permukaan globe ke bidang silinder, disebut …',
      jawaban_peserta: 'Proyeksi Silinder',
      jawaban_benar: 'Proyeksi Silinder',
      status: true,
      penjelasan:'Candi Borrobudur adalah sebuah candi Buddha yang terletak di Borobudur, Magelang, Jawa Tengah, Indonesia. Candi ini terletak kurang lebih 100 km di sebelah barat daya Semarang, 86 km di sebelah barat Surakarta, dan 40 km di sebelah barat laut Yogyakarta.'
    },
    {
      soal:'Gambar konvensional dari berkurangnya permukaan bumi disebut …',
      jawaban_peserta: 'Kartu',
      jawaban_benar: 'Kartu',
      status: true,
      penjelasan:'Candi Borrobudur adalah sebuah candi Buddha yang terletak di Borobudur, Magelang, Jawa Tengah, Indonesia. Candi ini terletak kurang lebih 100 km di sebelah barat daya Semarang, 86 km di sebelah barat Surakarta, dan 40 km di sebelah barat laut Yogyakarta.'
    },
    {
      soal:'Elemen-elemen berikut dapat disadap di peta, kecuali …',
      jawaban_peserta: 'Situasi',
      jawaban_benar: 'Polanya',
      status: false,
      penjelasan:'Candi Borrobudur adalah sebuah candi Buddha yang terletak di Borobudur, Magelang, Jawa Tengah, Indonesia. Candi ini terletak kurang lebih 100 km di sebelah barat daya Semarang, 86 km di sebelah barat Surakarta, dan 40 km di sebelah barat laut Yogyakarta.'
    }
  ]*/

  return (
    <div className='card p-5'>
      <div className='d-flex align-items-center'>
        <h6 className='mb-0 text-muted' style={{fontSize:'28px', fontWeight:'600'}}>Hasil Quiz</h6>
      </div>
      <div className='d-flex align-items-center my-3'>
        <h6 className='mb-0 text-nunito' style={{fontWeight:'600', fontSize:'18px'}}>{name}</h6>
        <p className='mb-0 ml-auto text-nunito text-muted' style={{fontSize:'14px'}}>{convertDate(result.created_at.substr(0, 10), true, true, false)}</p>
      </div>
      <div className='d-flex align-items-center w-75'>
        <p className='mb-0 text-muted' style={{fontSize:'14px'}}>Benar <span className='text-dark font-weight-bold'>{result.correct_answers}</span></p>
        <p className='mb-0 mx-auto text-muted' style={{fontSize:'14px'}}>Salah <span className='text-danger font-weight-bold'>{result.wrong_answers}</span></p>
        <p className='mb-0 text-muted ' style={{fontSize:'14px'}}>Nilai <span className='text-primary font-weight-bold'>{Number(result.score.toFixed(2))}</span></p>
      </div>
      <hr />

      {pembahasan.map((data,key)=>(
        <div className='' key={key}>
          <p className='text-nunito' style={{fontWeight:'600', fontSize:'14px'}}>{key + 1}. {data.soal}</p>
          <div className='ml-3 w-75'>
            <div className='row'>
              <p className='col-md-6 text-nunito mb-0' style={{fontWeight:'600', fontSize:'14px'}}>Jawaban Peserta</p>
              <p className='col-md-1 text-nunito mb-0' style={{fontWeight:'600', fontSize:'14px'}}>:</p>
              <p className={`col-md-5 text-nunito mb-0 ${data.status ? 'txt-primary' : 'txt-danger'}`} style={{fontWeight:'600', fontSize:'14px'}}>{data.jawaban_peserta}</p>
            </div>
            <div className='row'>
              <p className='col-md-6 text-nunito mb-0' style={{fontWeight:'600', fontSize:'14px'}}>Jawaban yang Benar</p>
              <p className='col-md-1 text-nunito mb-0' style={{fontWeight:'600', fontSize:'14px'}}>:</p>
              <p className='col-md-5 text-nunito mb-0 text-success' style={{fontWeight:'600', fontSize:'14px'}}>{data.jawaban_benar}</p>
            </div>
          </div>
          <div className='ml-3 mt-2 mb-4 bg-light p-3 rounded text-dark' style={{fontWeight:'600', fontSize:'14px'}}>
            <h6>Alasan:</h6>
              {data.penjelasan}
            </div>
        </div>
      ))}
    </div>
  )
}

export default Pembahasan;
