import React, { useState, useEffect } from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link } from 'react-router-dom';
import { PelatihanLainnyaIcon } from '../../../component/common/constant/menu';
import Cart1 from './Cart1';
import Cart2 from './Cart2';
import Cart3 from './Cart3';
import API from "../../../api";

const Cart = () => {
  const [Step, setStep] = useState(1);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [pointsUsed, setPointsUsed] = useState(0);
  const [promo, setPromo] = useState(0);
  const [promoId, setPromoId] = useState('');
  const [midtransResult, setMidtransResult] = useState({});
  const product = [
    {
      name: 'How to be Public Speaking King',
      trainer: 'Clara Jessica - PT Berkah Nusantara',
      status: 'Online',
      harga: 280000,
      checked: false,
    }, {
      name: 'How to be Public Speaking King',
      trainer: 'Clara Jessica - PT Berkah Nusantara',
      status: 'Online',
      harga: 280000,
      checked: false,
    }
  ];

  useEffect(() => {
    API.peserta.course.get_cart()
        .then(json => {
          let arr_product = [];

          json.data.data.map(val => {
            arr_product.push({
              id: val.id,
              name: val.course_id.name,
              course_id: val.course_id.id,
              schedule_id: val.schedule_id,
              category_id: val.course_id.category_id,
              trainer: val.course_id.trainer_id.name,
              status: val.course_id.type,
              harga: val.course_id.price,
              img: val.course_id.thumbnail,
              checked: false,
            })
          });

          setProducts(arr_product);

          setTotal(json.data.total_price);
        })
  }, []);

  return (
    <>
    <div className="page-header">
        <BreadcrumbComp title={'Cart'}>
          <li className="breadcrumb-item">
            <Link to="/" className='text-warning'>
              <PelatihanLainnyaIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Lainnya</li>
          <li className="breadcrumb-item">Cart</li>
        </BreadcrumbComp>
      </div>
      <div className="row mb-5 w-75 mx-auto">
        <div className={`u-pearl ${Step < 1 ? 'disabled' : Step === 1 ? 'current' : 'done' } col-4`} >
          <div className="u-pearl-icon"><i className="fa fa-shopping-cart"></i></div>
        </div>
        <div className={`u-pearl ${Step < 2 ? 'disabled' : Step === 2 ? 'current' : 'done' } col-4`} >
          <div className="u-pearl-icon"><i className="fa fa-file-text-o"></i></div>
        </div>
        <div className={`u-pearl ${Step < 3 ? 'disabled' : Step === 3 ? 'current' : 'done' } col-4`}>
          <div className="u-pearl-icon"><i className="fa fa-credit-card"></i></div>
        </div>
      </div>

      <div className='row'>
        {
          Step === 1 ? <Cart1
                  setStep={setStep}
                  product={products}
                  setProducts={setProducts}
                  setTotal={setTotal}
                  total={total}
                  promo={promo}
                  setPromo={setPromo}
                  promoId={promoId}
                  setPromoId={setPromoId}
                  grandTotal={grandTotal}
                  setGrandTotal={setGrandTotal}
                  pointsUsed={pointsUsed}
                  setPointsUsed={setPointsUsed}
              /> :
          Step === 2 ? <Cart2
                  setStep={setStep}
                  product={products}
                  setProducts={setProducts}
                  setTotal={setTotal}
                  total={total}
                  setMidtransResult={setMidtransResult}
                  promo={promo}
                  setPromo={setPromo}
                  promoId={promoId}
                  setPromoId={setPromoId}
                  grandTotal={grandTotal}
                  setGrandTotal={setGrandTotal}
                  pointsUsed={pointsUsed}
                  setPointsUsed={setPointsUsed}
              /> :
          Step === 3 ? <Cart3
                  setMidtransResult={setMidtransResult}
                  midtransResult={midtransResult}
                  setTotal={setTotal}
                  total={total}
                  promo={promo}
                  setPromo={setPromo}
                  promoId={promoId}
                  setPromoId={setPromoId}
                  grandTotal={grandTotal}
                  setGrandTotal={setGrandTotal}
                  pointsUsed={pointsUsed}
                  setPointsUsed={setPointsUsed}
              /> :
          ''
        }
      </div>
    </>
  )
}

export default Cart;
