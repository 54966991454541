import Get from "./Get";
import Post from "./Post";
import Delete from "./Delete";

const get_all = (data) => Get(`notification/my_notification?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const read = id => Post(`notification/read/${id}`);
const readAll = () => Post(`notification/readAll`);
const del = id => Delete(`notification/delete/${id}`);

const Notification = {
    get_all,
    read,
    readAll,
    del
};

export default Notification;
