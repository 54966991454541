import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import bca_logo from '../../../assets/images/bca-logo.png';
import bni_logo from '../../../assets/images/bni.png';
import {convertDate, numberWithDots} from "../../../helper";
import API from "../../../api";

const Cart3 = (props) => {

  /*useEffect(() => {
    props.setGrandTotal(val => val + 5000);
  },[]);*/

  return (
    <>
      {
        props.midtransResult.token &&
        <div className='col-md-9'>
          <div className='card p-4'>
            <h3 className='font-weight-bold'>Detail Pesanan</h3>
            <label className='d-flex mt-3'>
              <p className='mr-auto mb-0' style={{fontWeight:'normal', fontSize:'16px'}}>Sisa Waktu Pembayaran:</p>
              <p className='mb-0' style={{fontWeight:'500', fontSize:'16px'}}>{`${convertDate(props.midtransResult.transaction_time.substr(0, 10), true, true, true)} ${props.midtransResult.transaction_time.substr(10, 6)}`} WIB</p>
            </label>
            {
              props.midtransResult?.va_numbers &&
              <>
                <label className='d-flex mt-3'>
                  <p className='mr-auto mb-0' style={{fontWeight:'normal', fontSize:'16px'}}>Pembayaran:</p>
                  <p className='mb-0' style={{fontWeight:'500', fontSize:'16px'}}>{props.midtransResult.va_numbers[0].bank.toUpperCase()} Virtual Account</p>
                </label>

                <div className='row w-75 mx-auto my-3'>
                  <div className='col-md-6 text-center border-right'>
                    <img className="w-50 mb-3" src={props.midtransResult.va_numbers[0].bank === 'bca' ? bca_logo : props.midtransResult.va_numbers[0].bank === 'bni' ? bni_logo : ''} alt="#"/>
                    <p className='text-danger' style={{fontWeight:'500', fontSize:'16px'}}>{props.midtransResult.va_numbers[0].va_number}</p>
                  </div>
                  <div className='col-md-6 text-center'>
                    <p className='mr-auto mb-0' style={{fontWeight:'500', fontSize:'16px'}}>Total Pembayaran</p>
                    <p className='mr-auto mb-0' style={{fontWeight:'600', fontSize:'28px'}}>Rp {numberWithDots(props.grandTotal)}</p>
                  </div>
                </div>
              </>
            }

            {
              props.midtransResult && <button className={`btn btn-pill btn-primary btn-sm font-weight-bold px-3 w-100`} onClick={(e) => {
                window.snap.pay(props.midtransResult.token, {
                  onPending: function(result){
                    e.preventDefault();
                  },
                });
              }}>Lihat Tagihan</button>
            }

            {/*<div>
            <p className='mr-auto mb-0' style={{fontWeight:'600', fontSize:'18px'}}>Petunjuk</p>
            <ol className='d-block'>
              <li>Login pada aplikasi M-BCA, masukkan PIN M-BCA.</li>
              <li>Pilih "m-Transfer", pilih "BCA Virtual Account".</li>
              <li>Klik "Input no. Virtual Account", lalu masukkan no BCA Virtual Account {props.midtransResult.bca_va_number}.</li>
              <li>Klik "OK" & "Send".</li>
              <li>Periksa data transaksi kemudian pilih "OK" kemudian masukkan PIN M-BCA Anda.</li>
              <li>Periksa kembali data transaksi Anda, kemudian klik "OK".</li>
            </ol>
          </div>*/}
          </div>
        </div>
      }
      <div className='col-md-3'>
        <div className='card p-4'>
          <p className='text-muted' style={{fontSize:'18px'}}>Detail Pembayaran</p>
          <label className='d-flex mt-3'>
            <p className='mr-auto mb-0' style={{fontWeight:'normal', fontSize:'16px'}}>Total Price</p>
            <p className='mb-0' style={{fontWeight:'500', fontSize:'16px'}}>Rp {numberWithDots(props.total)}</p>
          </label>
          {props.pointsUsed > 0 &&
          <label className='d-flex'>
            <p className='mr-auto mb-0' style={{fontWeight:'normal', fontSize:'16px'}}>Point</p>
            <p className='text-success mb-0' style={{fontWeight:'500', fontSize:'16px'}}>-Rp {numberWithDots(props.pointsUsed)}</p>
          </label>
          }
          {/*<label className='d-flex'>
            <p className='mr-auto mb-0' style={{fontWeight:'normal', fontSize:'16px'}}>Admin Fee</p>
            <p className='text-danger mb-0' style={{fontWeight:'500', fontSize:'16px'}}>+Rp {numberWithDots(5000)}</p>
          </label>*/}
          <label className='d-flex border-top'>
            <p className='mr-auto mb-0' style={{fontWeight:'600', fontSize:'18px'}}>Grand Total</p>
            <p className='mb-0' style={{fontWeight:'600', fontSize:'18px'}}>Rp {numberWithDots(props.grandTotal)}</p>
          </label>
          {/*<button className={`btn btn-pill btn-outline-primary btn-sm font-weight-bold px-3 w-100 mt-5 mb-3`} type="button">Upload Bukti Pembayaran</button>*/}
            <Link to='/riwayat-transaksi' className={`btn btn-pill btn-primary btn-sm font-weight-bold px-3 w-100`}>Selesai</Link>
        </div>
      </div>
    </>
  )
}

export default Cart3;
