import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import dummy_king from '../../../assets/images/dummy_king.png';
import { CustomInput } from 'reactstrap';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {numberWithDots} from "../../../helper";
import API from "../../../api";
import {Button, Input} from "semantic-ui-react";

const Cart2 = (props) => {
  const user = JSON.parse(localStorage.getItem('data'));

  const [otorisasiAdmin, setotorisasiAdmin] = useState(false);
  const [otorisasiSuccess, setotorisasiSuccess] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [payWithPoints, setPayWithPoints] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [cart, setCart] = useState([]);

  useEffect(() => {
    let arr_cart = [];
    props.product.map(val => {
      arr_cart.push(val.id);
    });

    setCart(JSON.stringify(arr_cart));
    props.setGrandTotal(props.total);
    props.setPointsUsed(user.point < props.total ? user.point : props.total)
  }, []);

  return (
    <>
      <div className='col-md-8'>
        <div className='card p-4'>
          <div className='card-body'>
            <h3 className='font-weight-bold'>Detail Pesanan</h3>
            {
              props.product.map((data, key) => (
                <div className={`row py-3 ${key !== props.product.length - 1 && 'border-bottom'}`}>
                  <div className='col-md-5'>
                    <div className='d-flex align-items-center'>
                      <img className="img-60 ml-2 mr-3" src={data.img} alt="#"/>
                      <label>
                        <p className='mb-0'>Pelatihan</p>
                        <h6> {data.name} {data.schedule_id ? `- ${data.schedule_id.name}` : ''} </h6>
                      </label>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <p className='mb-0'>Trainer</p>
                    <h6> {data.trainer} </h6>
                  </div>
                  <div className='col-md-3 d-flex align-items-center justify-content-end' >
                    <h6 className='text-success'>Rp. {numberWithDots(data.harga)} </h6>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='card p-4'>
          <p className='text-muted' style={{ fontSize: '18px' }}>Detail Pembayaran</p>
          {
            props.product.map((data, key) => (
              <div className='mb-3'>
                <div className='d-flex align-items-center'>
                  <img className="img-60 ml-2 mr-3" src={data.img} alt="#" />
                  <label className='w-100'>
                    <h6 className='mb-0' style={{ fontSize: '16px' }}> {data.name} </h6>
                    <h6 className='text-right mb-0 font-weight-bold'>Rp {numberWithDots(data.harga)}</h6>
                  </label>
                </div>
              </div>
            ))
          }
          <hr />
          <p className='text-muted'>Punya kupon promo?</p>
            <Input type='text' className='d-flex mb-3'  placeholder='Kode Promo' style={{height:'2.5rem'}} action>
                <input
                    onChange={e => setPromoCode(e.target.value)}
                    value={promoCode}
                    style={{borderRadius: "4px 0px 0px 4px", border: "1px solid #D5D5D5", paddingLeft: "1rem", width: '60%'}}
                />
                <Button
                    type='button'
                    onClick={() => {
                        if(promoCode){
                            props.setPromo(0);
                            props.setPromoId('');
                            props.setGrandTotal(props.total);

                            API.peserta.course.use_promo({product: props.product, code: promoCode})
                                .then(json => {
                                    let promo = 0;

                                    if(json.data.discount_type === 'Persen')
                                        promo = props.total * json.data.nominal / 100;
                                    else{
                                        promo = json.data.nominal;
                                    }

                                    console.log('PROMO : ', promo);
                                    console.log('props.grandTotal : ', props.grandTotal);
                                    console.log('props.grandTotal - promo : ', props.grandTotal - promo);

                                    let newGrandTotal = props.grandTotal - promo;

                                    props.setGrandTotal(newGrandTotal < 0 ? 0 : newGrandTotal);
                                    props.setPromoId(json.data.id);
                                    props.setPromo(promo);

                                }).catch(e => {
                                alert(e.message);
                            })
                        }
                    }}
                    className='btn btn-primary px-3 justify-content-center'
                    style={{cursor:'pointer', borderRadius: "0px 4px 4px 0px", color: 'white', fontWeight: 'normal'}}
                >Pakai Promo</Button>
            </Input>
          <h6 style={{ fontWeight: '600', fontSize: '16px' }}> Poin anda: &nbsp; <span className='text-warning'>{numberWithDots(user.point)} points</span> </h6>
          <CustomInput disabled={user.point === 0} checked={payWithPoints} onClick={() => {
              setPayWithPoints(val => !val);
              if(payWithPoints)
                  props.setGrandTotal(v => v + props.pointsUsed);
              else
                  props.setGrandTotal(v => v - props.pointsUsed);
          }} type="checkbox" id="checkAll" label='Bayar sebagian dengan point' />
          <label className='d-flex mt-3'>
            <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Total Price</p>
            <p className='mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>Rp {numberWithDots(props.total)}</p>
          </label>
            {props.promo > 0 && <label className='d-flex'>
                <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Promo</p>
                <p className='text-success mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>-Rp {numberWithDots(props.promo)}</p>
            </label>}
            {payWithPoints && <label className='d-flex'>
                <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Point</p>
                <p className='text-success mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>-Rp {numberWithDots(props.pointsUsed)}</p>
            </label>}
          {/*<label className='d-flex'>
            <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Kupon</p>
            <p className='text-success mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>-Rp50.000</p>
          </label>
          <label className='d-flex'>
            <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Kupon</p>
            <p className='text-success mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>-Rp50.000</p>
          </label>*/}
          <label className='d-flex border-top'>
            <p className='mr-auto mb-0' style={{ fontWeight: '600', fontSize: '18px' }}>Grand Total</p>
            <p className='mb-0' style={{ fontWeight: '600', fontSize: '18px' }}>Rp {numberWithDots(props.grandTotal)}</p>
          </label>
          <CustomInput checked={agreement} onClick={() => setAgreement(val => !val)} type="checkbox" id="checkAll2" label='Dengan ini saya menyetujui Ketentuan Layanan ini.' />
          <div className='text-center mt-5'>
            <button className={`btn btn-pill btn-primary btn-sm font-weight-bold px-3 w-100`} type="button"
                onClick={e => {
                  if(agreement){
                    API.user.snap_token({
                        cart,
                        promo: props.promo,
                        promo_id: props.promoId,
                        used_point: payWithPoints ? props.pointsUsed : 0
                    }).then(json => {
                      if(json.token){
                          window.snap.pay(json.token, {
                              onPending: function(result){
                                  e.preventDefault();
                                  console.log(result);

                                  API.user.set_payment_method({
                                      no_transaction: json.no_transaction,
                                      va_account: result.va_numbers ? JSON.stringify(result.va_numbers[0]) : null
                                  }).then(data => {
                                      if(payWithPoints){
                                          user.point -= props.pointsUsed;
                                      }
                                      else
                                          props.setPointsUsed(0);

                                      result.token = json.token;
                                      result.transaction_time = data.data.expired_pay;

                                      localStorage.setItem('data', JSON.stringify(user));
                                      props.setMidtransResult(result);
                                      props.setStep(3);
                                  });
                              },
                          });
                      }
                      else{
                          API.user.set_payment_method({
                              no_transaction: json.no_transaction,
                              va_account: 'Points'
                          }).then(data => {
                              if(payWithPoints){
                                  user.point -= props.pointsUsed;
                              }
                              else
                                  props.setPointsUsed(0);

                              let result = {};
                              result.transaction_time = data.data.expired_pay;

                              localStorage.setItem('data', JSON.stringify(user));
                              props.setMidtransResult(result);
                              props.setStep(3);
                          });
                      }
                    })
                  }
                  else {
                    alert('Anda harus menyetujui ketentuan layanan ini');
                  }
                }}
            >Bayar Pesanan</button>
            <p className='my-3'>atau</p>
            <button className={`btn btn-pill btn-outline-primary btn-sm font-weight-bold px-3 w-100`} type="button" onClick={() => setotorisasiAdmin(!otorisasiAdmin)}>Minta Otorisasi Admin </button>

          </div>
        </div>
      </div>


      <Modal isOpen={otorisasiAdmin} toggle={() => setotorisasiAdmin(!otorisasiAdmin)} size='lg'>
        <ModalBody>
          <div className='text-center mt-3'>
            <h6>Apakah Anda yakin ingin meminta otorisasi Admin?</h6>
            <p>Dengan meminta otorisasi Admin, pembayaran akan menjadi tagihan bagi perusahaan Anda</p>
            <button
              className="btn btn-pill btn-primary btn-sm my-3 mr-3"
              type="button"
              onClick={() => {
                setotorisasiAdmin(!otorisasiAdmin);

                API.peserta.course.request_admin_authorization({cart})
                    .then(json => {
                        setotorisasiSuccess(!otorisasiSuccess);
                    });

              }}
            > Ya
                </button>
            <button
              className="btn btn-pill btn-outline-primary btn-sm my-3"
              type="button"
              onClick={() => setotorisasiAdmin(!otorisasiAdmin)}
            > Tidak
                </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={otorisasiSuccess} toggle={() => setotorisasiSuccess(!otorisasiSuccess)} size='lg'>
        <ModalBody>
          <div className='text-center mt-3'>
            <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M35 2.91016C17.336 2.91016 3 17.5565 3 35.6028C3 53.6491 17.336 68.2954 35 68.2954C52.664 68.2954 67 53.6491 67 35.6028C67 17.5565 52.664 2.91016 35 2.91016ZM26.328 49.6279L14.84 37.8913C14.5437 37.5886 14.3087 37.2293 14.1484 36.8338C13.9881 36.4383 13.9055 36.0145 13.9055 35.5864C13.9055 35.1584 13.9881 34.7345 14.1484 34.3391C14.3087 33.9436 14.5437 33.5843 14.84 33.2816C15.1363 32.9789 15.488 32.7388 15.8751 32.575C16.2621 32.4112 16.677 32.3269 17.096 32.3269C17.515 32.3269 17.9299 32.4112 18.3169 32.575C18.704 32.7388 19.0557 32.9789 19.352 33.2816L28.6 42.6971L50.616 20.2046C51.2143 19.5933 52.0258 19.2499 52.872 19.2499C53.7182 19.2499 54.5297 19.5933 55.128 20.2046C55.7263 20.8158 56.0625 21.6449 56.0625 22.5094C56.0625 23.3739 55.7263 24.2029 55.128 24.8142L30.84 49.6279C30.544 49.931 30.1923 50.1715 29.8052 50.3355C29.4181 50.4996 29.0031 50.584 28.584 50.584C28.1649 50.584 27.7499 50.4996 27.3628 50.3355C26.9757 50.1715 26.624 49.931 26.328 49.6279Z" fill="#31A05F" />
            </svg>
            <h6 className='mt-3'>Sukses memohon otorisasi Admin</h6>
            <p>Anda akan bisa mengakses pelatihan apabila admin mengonfimasi permohonan otorisasi dan tagihan akan dimasukkan ke dalam billing perusahaan</p>
            <button
              className="btn btn-pill btn-primary btn-sm my-3"
              type="button"
              onClick={() => setotorisasiSuccess(!otorisasiSuccess)}
            > OK
                </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Cart2;
