import React, {useState, Fragment, useEffect} from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Users } from 'react-feather';
import { Input } from 'reactstrap';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import API from "../../../api";
import swal from 'sweetalert2';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";

const TambahSoalQuisFormUpdate = () => {
  let query = new URLSearchParams(useLocation().search);
  let quiz_id = query.get('quiz_id');
  const history = useHistory();

  const [question, setQuestion] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [correct_answer, setCorrectAnswer] = useState('');
  const [discussion, setDiscussion] = useState('');
  const [option, setOption] = useState([]);

  useEffect(() => {
    API.adminc.quiz.getOne(quiz_id)
        .then(json => {
          let data = json.data;
          setQuestion(data.question);
          setCorrectAnswer(data.correct_answer);
          setOption(data.quiz_option);
          setDiscussion(data.discussion);
        });
  }, []);

  const handleChangeDescription = (event) => {
    const { name, value, index } = event.target;
    const newInput = Object.assign([], option);
    newInput[index] = { ...newInput[index], [name]: value };

    setOption(newInput);
  };

  const handleSubmited = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    let data = new FormData();

    data.append('question', question);
    data.append('correct_answer', correct_answer);
    data.append('discussion', discussion);

    option.map((v, k) => {
      data.append(`quiz_option[${k}][option]`, v.option);
      data.append(`quiz_option[${k}][description]`, v.description);
    });

    API.adminc.quiz.update(quiz_id, data)
        .then(json => {
          setIsSubmitting(false);
          swal.fire('Sukses', 'Soal Quiz Berhasil Diupdate', 'success').then(() => {
            history.goBack();
          })
        }).catch(e => {
          setIsSubmitting(false);
          toast.error(e.message, { position: toast.POSITION.TOP_CENTER });
        })
  }
  return (
    <Fragment>
      <div className="page-header">
        <ToastContainer />
        <BreadcrumbComp title={'List pelatihan'}>
          <li className="breadcrumb-item">
            <Link to="/admin/list-pelatihan" className='text-warning'>
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Soal Quiz</li>
          <li className="breadcrumb-item">Update Soal Quiz</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5 className='text-work text-theme' style={{ fontSize: '24px', fontWeight: '600' }}>PELATIHAN PERUSAHAAN</h5>
              <p className='text-work text-muted' style={{ fontSize: '18px', fontWeight: '600' }}>Atur Soal Quiz</p>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmited}>

                <h6 style={{ fontSize: '16px' }}>Soal</h6>
                <CKEditor
                    editor={ ClassicEditor }
                    data={question}
                    onChange={ ( event, editor ) => {
                      setQuestion(editor.getData());
                      }
                    }
                />
                <div className='mt-5'>

                  {
                    option.map((field, index) => {
                      return <div style={{marginBottom: 20}}>
                        <h6 style={{ fontSize: '16px' }}>Pilihan Soal {field.option}</h6>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={field.description}
                            onChange={ ( event, editor ) => {
                                handleChangeDescription({
                                  target: { name: 'description', value: editor.getData(), index: index }
                                })
                              }
                            }
                        />
                      </div>
                    })
                  }

                  <h6 style={{ fontSize: '16px' }} className='mt-3'>Pilihan Jawaban</h6>
                  <div className='d-flex justify-content-between w-50 ml-4'>
                    <label>
                      <Input checked={correct_answer === 'A'} type="radio" name="radio1" onChange={()=>setCorrectAnswer('A')} />{' '}
                      Jawaban A
                  </label> <br />
                    <label>
                      <Input checked={correct_answer === 'B'} type="radio" name="radio1" onChange={()=>setCorrectAnswer('B')} />{' '}
                      Jawaban B
                  </label><br />
                    <label>
                      <Input checked={correct_answer === 'C'} type="radio" name="radio1" onChange={()=>setCorrectAnswer('C')} />{' '}
                      Jawaban C
                  </label><br />
                    <label>
                      <Input checked={correct_answer === 'D'} type="radio" name="radio1" onChange={()=>setCorrectAnswer('D')} />{' '}
                      Jawaban D
                  </label>
                  </div>
                  <textarea required value={discussion} placeholder="Masukan Pembahasan . . ." className='form-control' onChange={(e)=>setDiscussion(e.target.value)} />
                </div>
                <div className='my-5 d-flex align-items-center justify-content-end border-top pt-4'>
                    <button type='button' className='btn btn-outline-primary btn-pill mr-3' onClick={() => history.goBack()}>Batal</button>
                    <button className='btn btn-primary btn-pill' disabled={isSubmitting}>Simpan</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default TambahSoalQuisFormUpdate;
