import React, { useState, useEffect, useRef } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation } from "react-router-dom";
import { CompanyIcon } from "../../../component/common/constant/menu";
import { ToastContainer, toast } from "react-toastify";
import swal from 'sweetalert2';
import API from "../../../api";

const FormTrainer = (props) => {
  const [EditData, setEditData] = useState({});
  const [dataInput, setdataInput] = useState({});
  const [imagePelatihanPreview, setimagePelatihanPreview] = useState(null);
  const [imagePelatihanPreviewUrl, setimagePelatihanPreviewUrl] = useState("");
  const [Submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data_profession, setDataProfession] = useState([]);
  const [konfirmasi_password, setKonfirmasiPassword] = useState(null);
  const [profession_name, setProfessionName] = useState(null);
  const imgRef = useRef(null);

  const handleImagePelatihanChange = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];
    setdataInput({
      ...dataInput,
      [event.target.name]: file,
    });

    reader.onloadend = () => {
      setimagePelatihanPreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (imagePelatihanPreviewUrl) {
      setimagePelatihanPreview(imagePelatihanPreviewUrl);
    } else {
      setimagePelatihanPreview(false);
    }
  }, [imagePelatihanPreviewUrl]);

  useEffect(() => {
    if (props.editDataAdmin) {
      API.superadmin.adminc
        .get_detail_adminc(props.editDataAdmin.id)
        .then((json) => {
          setdataInput({
            name: json.data.name,
            email: json.data.email,
            profession_id: json.data.profession.id,
            phone_number: json.data.phone,
          });
          setimagePelatihanPreview(json.data.photo);
          setProfessionName(json.data.profession.name);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    API.superadmin.profession
      .get_profession()
      .then((json) => {
        setDataProfession(json.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setdataInput({
      ...dataInput,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (props.editDataAdmin) {
      API.superadmin.adminc
        .update_adminc(props.editDataAdmin.id, dataInput)
        .then((json) => {
          swal.fire('Sukses', json.message, 'success').then(res => {
            setIsSubmitting(false);
            setSubmitted(true);
            props.setpageView(null);
          });
        })
        .catch((err) => {
          console.log(err);
          swal.fire('Gagal', "Admin C Tidak Berhasil Diubah", 'error').then(res => {
            setIsSubmitting(false);
          });
        });
    } else {
      API.superadmin.adminc
        .insert_adminc(dataInput)
        .then((json) => {
          swal.fire('Sukses', json.message, 'success').then(res => {
            setIsSubmitting(false);
            setSubmitted(true);
            props.setpageView(null);
          });
        })
        .catch((err) => {
          console.log(err);
          setIsSubmitting(false);
          swal.fire('Gagal', "Admin C Tidak Berhasil Ditambahkan", 'error').then(res => {
            setIsSubmitting(false);
          });
        });
    }
  };
  console.log(profession_name)
  return (
    <>
      <ToastContainer />
      <div className="page-header">
        <BreadcrumbComp title={"Admin C"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/list-trainer" className="text-warning">
              <CompanyIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">List Trainer</li>
          <li className="breadcrumb-item">Admin C</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-3 d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  Admin C
                </h5>
                <p
                  className="text-muted"
                  style={{ fontWeight: "600", fontSize: "18px" }}
                >
                  Data Admin C
                </p>
              </div>
            </div>
            <div className="card-body">
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-md-2">
                  <div className="button-custom w-50 mx-auto">
                    <div
                        className="upload-image d-flex justify-content-center align-items-center mt-5"
                        onClick={() => !Submitted && imgRef.current.click()}
                        style={{
                          backgroundImage: `url(${imagePelatihanPreview})`,
                          height: "92px",
                          width: "100%",
                          color: "#C4C4C4",
                          backgroundColor: "#F2F2F2",
                          fontSize: "35px",
                          borderRadius: "50% 50%",
                        }}
                    >
                      {imagePelatihanPreview ?
                          <img
                              className="rounded-circle"
                              style={{
                                height: 92,
                                width: "100%",
                                color: "#C4C4C4",
                                backgroundColor: "#F2F2F2",
                                fontSize: "35px",
                                borderRadius: "50% 50%",
                                objectFit: 'cover',
                                cursor: Submitted ? '' : 'pointer'
                              }}
                              src={imagePelatihanPreview}
                              alt="#"
                          /> : <span>
                              <i className="fa fa-camera"></i>
                            </span>}
                      <input
                          ref={imgRef}
                          style={{display: 'none'}}
                          id="file-select"
                          type="file"
                          name="profile_picture"
                          onChange={handleImagePelatihanChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>Email</h6>
                    {Submitted ? (
                      <h6>{dataInput.email}</h6>
                    ) : (
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        value={dataInput.email}
                        placeholder="Masukkan email"
                        onChange={handleChange}
                        required
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <h6>Password</h6>
                    {Submitted ? (
                      <h6>{dataInput.password}</h6>
                    ) : props.editDataAdmin ? (
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        defaultValue={dataInput.password}
                        placeholder="Masukkan password"
                        onChange={handleChange}
                      />
                    ) : (
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        defaultValue={dataInput.password}
                        placeholder="Masukkan password"
                        onChange={handleChange}
                        required
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <h6>Konfirmasi Password</h6>
                    {Submitted ? (
                      <h6>{dataInput.konfirmasi_password}</h6>
                    ) : props.editDataAdmin ? (
                      <input
                        className="form-control"
                        type="password"
                        name="konfirmasi_password"
                        defaultValue={dataInput.password}
                        placeholder="Ketikkan ulang password"
                        onChange={handleChange}
                      />
                    ) : (
                      <input
                        className="form-control"
                        type="password"
                        name="konfirmasi_password"
                        defaultValue={dataInput.password}
                        placeholder="Ketikkan ulang password"
                        onChange={(event) =>
                          setKonfirmasiPassword(event.target.value)
                        }
                        required
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>Nama</h6>
                    {Submitted ? (
                      <h6>{dataInput.name}</h6>
                    ) : (
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={dataInput.name}
                        placeholder="Masukkan nama ketua klien"
                        onChange={handleChange}
                        required
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <h6>Jabatan</h6>
                    {Submitted ? (
                      <h6>{profession_name}</h6>
                    ) : (
                      <select
                        className="form-control digits"
                        id="exampleFormControlSelect7"
                        name="profession_id"
                        onChange={handleChange}
                        defaultValue="default"
                      >
                        <option value="default" disabled>
                          Pilih Jabatan
                        </option>
                        {data_profession.map((v) => {
                          return (
                            <option
                              value={v.id}
                              onClick={() => setProfessionName(v.name)}
                              selected={dataInput.profession_id === v.id}
                            >
                              {v.name}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </div>
                  <div className="form-group">
                    <h6>Nomor Telepon</h6>
                    {Submitted ? (
                      <h6>{dataInput.phone_number}</h6>
                    ) : (
                      <input
                        className="form-control"
                        type="text"
                        name="phone_number"
                        defaultValue={dataInput.phone_number}
                        placeholder="Masukkan nomor telepon"
                        onChange={handleChange}
                        required
                      />
                    )}
                  </div>
                </div>
                {!Submitted && (
                  <div className="text-right border-top mt-5 pt-3 w-100">
                    <button
                      className="btn btn-pill btn-outline-primary btn-sm my-3 mr-3"
                      type="button"
                      onClick={() => {
                        props.setpageView(null);
                      }}
                    >
                      {" "}
                      Batal
                    </button>
                    <button
                      className="btn btn-pill btn-primary btn-sm my-3"
                      disabled={isSubmitting}
                      onClick={
                        dataInput.password === konfirmasi_password
                          ? handleSubmit
                          : null
                      }
                    >
                      {" "}
                      Simpan
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormTrainer;
