import React, { useState, useEffect } from 'react';
import { BreadcrumbComp } from '../../../component';
import { Layers } from 'react-feather';
import {
  useLocation,
  useHistory,
  Link
} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import learning_dummy from '../../../assets/images/learning-dummy-image.png';
import { Form } from 'semantic-ui-react';
import API from "../../../api";

const EditPelatihan = () => {
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const history = useHistory();

  const status = query.get("status");
  const pelatihan = query.get("pelatihan");
  const jenis = query.get("jenis");
  const bidang = query.get("bidang");

  const [course, setCourse] = useState([]);

  const [imagePreview, setimagePreview] = useState(null);
  const [imagePreviewUrl, setimagePreviewUrl] = useState('');

  const [imagePelatihanPreview, setimagePelatihanPreview] = useState(null);
  const [imagePelatihanPreviewUrl, setimagePelatihanPreviewUrl] = useState('');

  const [statusCourse, setStatus] = useState(status);
  const [thumbnail, setThumbnail] = useState('');
  const [certificate, setCertificate] = useState('');

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    setCertificate(file)

    reader.onloadend = () => {
      setimagePreviewUrl(reader.result);
    }

    reader.readAsDataURL(file)
  }

  useEffect(() => {
    if (imagePreviewUrl) {
      setimagePreview(<img className="w-100" src={imagePreviewUrl} alt='Global English' />)
    } else {
      setimagePreview(<div className=""></div>);
    }
  }, [imagePreviewUrl])

  const handleImagePelatihanChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    setThumbnail(file)

    reader.onloadend = () => {
      setimagePelatihanPreviewUrl(reader.result);
    }

    reader.readAsDataURL(file)
  }

  useEffect(() => {
    if (imagePelatihanPreviewUrl) {
      setimagePelatihanPreview(imagePelatihanPreviewUrl)
    } else {
      setimagePelatihanPreview(false);
    }
  }, [imagePelatihanPreviewUrl])

  useEffect(() => {
    API.ketua.course.course_detail_update(id)
    .then(json => {
      setCourse(json.data);
    })
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(statusCourse)
    const data = new FormData()

    data.append('status', statusCourse)
    if(thumbnail)
      data.append('thumbnail', thumbnail)
    if(certificate)
      data.append('certificate', certificate)

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    API.ketua.course.course_detail_edit(id, data, config)
    .then(json => {
      console.log(json)
      toast.success("Data Pelatihan Berhasil Diubah", { position: toast.POSITION.TOP_CENTER });
      setTimeout(() => {
        history.push('/ketua/list-pelatihan')
      }, 5000)
    }).catch(err => {
      console.log(err)
      toast.error("Data Pelatihan Tidak Berhasil Diubah", { position: toast.POSITION.TOP_CENTER });
    })
  }

  return (
    course &&
    <>
      <div className="page-header">
        <ToastContainer />
        <BreadcrumbComp title={'Pelatihan Saya'}>
          <li className="breadcrumb-item">
            <Link to="/dashboard/default" className='text-warning'>
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Saya</li>
          <li className="breadcrumb-item active">Edit Pelatihan</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <Link to='/ketua/list-pelatihan'>
            <p className='btn p-0 text-theme'>
              <i className="fa fa-arrow-left"></i> Kembali
            </p>
          </Link>
        </div>
        <div className="col-sm-12">
          <div className="card">
            <Form onSubmit={handleSubmit}>
              <div className="card-header pb-3 d-flex align-items-center">
                <div className='mr-auto'>
                  <h5 className='text-work text-theme' style={{ fontSize: '24px', fontWeight: '600' }}>EditPelatihan</h5>
                  <p className='text-work text-muted' style={{ fontSize: '18px', fontWeight: '600' }}>Data Pelatihan</p>
                </div>
                <div className='d-flex align-items-center justify-content-end mb-3'>
                  <p className='my-auto mr-3 text-work' style={{ fontSize: '16px' }}>Status</p>
                  <div>
                    <select className="form-control digits" id="exampleFormControlSelect7" onChange={(e)=>setStatus(e.target.value)}>
                      <option value='Nonaktif' selected={ status == 'Nonaktif'}>Nonaktif</option>
                      <option value='Aktif, Public' selected={ status == 'Aktif, Public'}>Aktif, Public</option>
                      <option value='Active, Private' selected={ status == 'Active, Private'}>Aktif, Private</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-8'>
                    <p className='text-work text-muted' style={{ fontSize: '18px', fontWeight: '600' }}>Informasi Pelatihan</p>
                    <h6>Nama Pelatihan</h6>
                    <h6 className='ml-3' style={{ fontSize: '16px', fontWeight: '600' }}>{course.name}</h6>
                    <h6 className='mt-4'>Jenis Pelatihan</h6>
                    <h6 className='ml-3' style={{ fontSize: '16px', fontWeight: '600' }}>{course.type}</h6>
                    <h6 className='mt-4'>Bidang Pelatihan</h6>
                    <h6 className='ml-3' style={{ fontSize: '16px', fontWeight: '600' }}>{course.category}</h6>
                  </div>
                  <div className='col-md-4'>
                    <img class="img-fluid w-100 mb-3" src={ imagePelatihanPreview || course.thumbnail } alt="omind" />
                    <div class="button-custom w-50 mx-auto">
                      <div class="btn btn-pill btn-outline-primary upload-image">
                        <span><i className="fa fa-cloud-upload"></i> &nbsp;Unggah Foto</span>
                        <input id="file-select" type="file" onChange={handleImagePelatihanChange} />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12 mt-5'>
                    <p className='text-work text-muted' style={{ fontSize: '18px', fontWeight: '600' }}>Sertifikat</p>
                    <p style={{ fontSize: '14px'}}>Fermentum mauris blandit eget accumsan aliquam integer condimentum dolor. Scelerisque commodo consectetur at sit. Velit diam duis facilisis id netus. </p>
                    <div className="form-group row">
                      <label htmlFor="exampleFormControlInput1" className='text-work col-sm-2 col-form-label text-right' style={{fontSize:'16px'}}>Upload Sertifikat</label>
                      <div class="col-sm-10">
                        <input className="form-control text-dark w-50" type="file" onChange={handleImageChange} />
                      </div>
                    </div>
                    <div className="w-50 mt-3" >
                      {imagePreview}
                    </div>
                  </div>
                  <div className="col-md-12 m-5">
                    <div className="text-right mb-5">
                      <button type="cancel" className="btn btn-outline-primary btn-pill mr-2">Batal</button>
                      <button type="submit" className="btn btn-primary btn-pill">Simpan</button>
                  </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditPelatihan;
