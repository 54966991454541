import React, { useState, useEffect } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation } from "react-router-dom";
import { TransaksiIcon } from "../../../component/common/constant/menu";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import dummy_king from "../../../assets/images/dummy_king.png";
import question from "../../../assets/images/question.png";
import success from "../../../assets/images/success.png";
import API from "../../../api";

const TransaksiPeserta = () => {
  let query = new URLSearchParams(useLocation().search);
  const [modalDetail, setmodalDetail] = useState(false);
  const [setujuiModal, setsetujuiModal] = useState(false);
  const [konfirmasiSetuju, setkonfirmasiSetuju] = useState(false);
  const [konfirmasiTolak, setkonfirmasiTolak] = useState(false);
  const [tolakModal, settolakModal] = useState(false);
  const [detailSelected, setdetailSelected] = useState(null);

  const [konfirmasitidak, setkonfirmasitidak] = useState(false);
  const [order, setOrder] = useState('desc');

  const columnsTabelTransaksi = [
    {
      dataField: "no",
      text: "No Transaksi",
      headerStyle: () => ({ width: "15%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "tanggal",
      text: "Tanggal Transaksi",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "peserta",
      text: "Nama Peserta",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center mb-2">
            <div>
              {row.avatar.split('/')[3] && <img className="img-40 rounded-circle" style={{height: '40px', objectFit: 'cover'}} src={row.avatar} alt="#" />}
            </div>
            <label className="mb-0 ml-2">
              <p className="font-weight-bold">{row.peserta}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "harga",
      text: "Harga",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2 text-center"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <div
            className={`w-75 my-auto mx-auto p-2 text-center card btn-status btn-status_${
              cel === "Lunas"
                ? "aktif"
                : cel === "Ditolak Admin"
                ? "ditolak"
                : cel === "Dibatalkan"
                ? "ditolak"
                : cel === "Menunggu Pembayaran"
                ? "terpakai"
                : cel === "Tagihan"
                ? "terpakai"
                : "expired"
            }`}
          >
            {cel}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Aksi",
      headerStyle: () => ({ width: "20%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <div className="text-center">
            <button
              className="btn btn-pill btn-outline-primary btn-sm"
              type="button"
              onClick={() => {
                API.adminc.transaction
                  .transaction_detail(row.id)
                  .then((json) => {
                    setdetailSelected(json.data);
                  });
                setmodalDetail(!modalDetail);
              }}
            >
              {" "}
              Detail{" "}
            </button>
          </div>
        );
      },
    },
  ];

  const per_page = 10;
  let page = query.get("page") || 1;

  const [transactions, setTransaction] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });
  useEffect(() => {
    API.adminc.transaction.list_transaction({ page, per_page, order }).then((json) => {
      let transaction = { ...transactions };
      transaction.data = [];

      json.transactions.data.map((val) => {
        transaction.data.push({
          id: val.id,
          no: val.no_transaction,
          tanggal: val.created_at,
          peserta: val.participant.name,
          avatar: val.participant.photo,
          harga: new Intl.NumberFormat("id", {
            style: "currency",
            currency: "IDR",
          }).format(val.price),
          status: val.status,
          time_added: val.time_added,
        });
      });

      transaction.meta.from = (page - 1) * per_page + 1;
      transaction.meta.to =
        per_page > json.transactions.total
          ? json.transactions.total
          : page * per_page > json.transactions.total
          ? json.transactions.total
          : page * per_page;
      transaction.meta.total = json.transactions.total;
      transaction.meta.current_page = page;
      transaction.meta.total_page = Math.ceil(
        transaction.meta.total / transaction.meta.per_page
      );

      setTransaction(transaction);
    });
  }, [page, order]);

  const handleActiveFilter = e => {
    setOrder(e.target.value);
  };

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"Transaksi Pembelian Peserta"}>
          <li className="breadcrumb-item">
            <Link to="/admin/transaksi-peserta" className="text-warning">
              <TransaksiIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">Transaksi Pembelian Peserta</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  LIST TRANSAKSI
                </h5>
              </div>
              <div className="d-flex ">
                <label className="mr-3 my-auto">Urutkan berdasarkan</label>
                <div>
                <select
                  className="form-control digits"
                  id="exampleFormControlSelect7"
                  onChange={handleActiveFilter}
                >
                  <option value='desc'>Terbaru</option>
                  <option value='asc'>Terlama</option>
                </select>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="overflow-auto w-100">
                <TableComp
                  title_paggination="transaksi"
                  columns={columnsTabelTransaksi}
                  data={transactions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={setujuiModal}
        toggle={() => {
          setsetujuiModal(!setujuiModal)
          setkonfirmasiSetuju(!konfirmasiSetuju)
        }}
        size="md"
        style={{ maxWidth: "548px", maxHeight: "275px" }}
      >
        <ModalBody className="pb-5">
          {konfirmasiSetuju ? (
            <>
              <div className="text-center mb-4">
                <img className="img-80" src={success} alt="#"></img>
              </div>
              <div className="text-center mb-2">
                <h5
                  className="mt-3 text-nunito"
                  style={{ fontSize: "20px", fontWeight: "800" }}
                >
                  Sukses mengotorisasi peserta
                </h5>
                <p
                  className="text-nunito"
                  style={{ fontSize: "16", fontWeight: "400" }}
                >
                  Tagihan akan dimasukkan ke dalam billing perusahaan{" "}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="text-center mt-5 mb-4">
                <img src={question} alt="" />
              </div>
              <div className="text-center mb-4">
                <h5 className="text-weight-bold">
                  Apakah anda yakin ingin <br />
                  <span style={{ color: "green", fontWeight: "600" }}>
                    menyetujui
                  </span>{" "}
                  transaksi?
                </h5>
              </div>
              <div className="text-center">
                <button
                  className="btn btn-pill btn-primary px-5"
                  onClick={() => {
                    setkonfirmasiSetuju(!konfirmasiSetuju);
                  }}
                >
                  Ya
                </button>
                <button
                    onClick={() => {
                      setsetujuiModal(!setujuiModal)
                    }}
                    className="btn btn-pill btn-outline-primary ml-2 px-4">
                  Tidak
                </button>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={tolakModal}
        toggle={() => {
          settolakModal(!tolakModal);
          setkonfirmasiTolak(!konfirmasiTolak);
        }}
        size="md"
        style={{ maxWidth: "548px", maxHeight: "275px" }}
      >
        <ModalBody className="pb-5">
          {konfirmasiTolak ? (
            <>
              <div className="text-center mb-4">
                <img className="img-80" src={success} alt="#"></img>
              </div>
              <div className="text-center mb-2">
                <h5
                  className="mt-3 text-nunito"
                  style={{ fontSize: "20px", fontWeight: "800" }}
                >
                  Sukses menolak otorisasi peserta
                </h5>
                <p
                  className="text-nunito"
                  style={{ fontSize: "16", fontWeight: "400" }}
                >
                  Peserta diharuskan membuat ulang pesanan jika ingin membeli
                  pelatihan secara mandiri{" "}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="text-center mt-5 mb-4">
                <img src={question} alt="" />
              </div>
              <div className="text-center mb-4">
                <h5 className="text-weight-bold">
                  Apakah anda yakin ingin <br />
                  <span style={{ color: "red", fontWeight: "600" }}>
                    menolak
                  </span>{" "}
                  transaksi?
                </h5>
              </div>
              <div className="text-center">
                <button
                  className="btn btn-pill btn-primary px-5"
                  onClick={() => {
                    API.adminc.transaction.confirm_authorization(detailSelected.id, {
                      status: 'tolak'
                    }).then(json => {
                      setkonfirmasiTolak(!konfirmasiTolak);
                      let idx = transactions.data.findIndex(v => v.id == detailSelected.id)

                      let temp_transactions = [...transactions];
                      temp_transactions.data[idx].status = 'Ditolak Admin';
                      setTransaction(temp_transactions);

                    }).catch(e => {
                      console.log(e);
                    })
                  }}
                >
                  Ya
                </button>
                <button
                  className="btn btn-pill btn-outline-primary ml-2 px-4"
                  onClick={() => {
                    settolakModal(!tolakModal);
                  }}
                >
                  Tidak
                </button>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalDetail}
        toggle={() => setmodalDetail(!modalDetail)}
        size="lg"
        style={{ maxWidth: "1050px" }}
      >
        <ModalHeader toggle={() => setmodalDetail(!modalDetail)}>
          Detail Transaksi
        </ModalHeader>
        <ModalBody className="pb-5">
          {detailSelected && (
            <>
              <div className="d-flex">
                <div className="mr-auto">
                  <label className="text-muted text-work">No Transaksi</label>
                  <h6 className="mb-4">{detailSelected.no_transaction}</h6>
                </div>
                <div className="text-center">
                  {detailSelected.status === "Lunas" ? (
                    <button
                      className="btn btn-pill btn-outline-primary btn-sm"
                      type="button"
                    >
                      {" "}
                      <i className="fa fa-download"></i> &nbsp;Unduh Invoice
                    </button>
                  ) : detailSelected.status === "Butuh Otorisasi" ? (
                    <div>
                      <p
                        className="text-work mb-0"
                        style={{ fontSize: "14px" }}
                      >
                        Setujui Transaksi?
                      </p>
                      <br />
                      <a
                        style={{ color: "green" }}
                        className="btn btn-status_aktif mr-2"
                        onClick={() => {
                          setmodalDetail(!modalDetail);
                          setsetujuiModal(true);
                        }}
                      >
                        Setujui{" "}
                      </a>
                      <a
                        style={{ color: "red" }}
                        className="btn btn-status_expired mr-5"
                        onClick={() => {
                          setmodalDetail(!modalDetail);
                          settolakModal(true);
                        }}
                      >
                        Tolak{" "}
                      </a>
                    </div>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
              <label className="text-muted text-work">Nama Pembeli</label>
              <div className="d-flex align-items-center mb-4">
                <div>
                  <img
                    className="img-40 rounded-circle"
                    style={{objectFit: 'cover', width: 40, height: 40}}
                    src={detailSelected.buyer.photo}
                    alt="#"
                  />
                </div>
                <label className="mb-0 ml-2">
                  <p className="font-weight-bold mb-0">
                    {detailSelected.buyer.name}
                  </p>
                </label>
              </div>
              <label className="text-muted text-work">Status</label>
              <h6
                className={`w-25 p-2 text-center card btn-status btn-status_${
                  detailSelected.status === "Lunas"
                    ? "aktif"
                    : detailSelected.status === "Ditolak Admin"
                    ? "ditolak"
                    : detailSelected.status === "Dibatalkan"
                    ? "ditolak"
                    : detailSelected.status === "Menunggu Pembayaran"
                    ? "terpakai"
                    : detailSelected.status === "Tagihan"
                    ? "terpakai"
                    : "expired"
                }`}
              >
                {detailSelected.status}
              </h6>
              <label className="text-muted text-work">Tanggal Pembelian</label>
              <h6 className="mb-4">{detailSelected.buy_date}</h6>
              <hr />
              <div>
                <p
                  className="text-muted"
                  style={{ fontWeight: "600", fontSize: "18px" }}
                >
                  Daftar Pelatihan
                </p>
                {detailSelected?.courses &&
                  detailSelected.courses.map((data, key) => (
                    <div className="row mb-4">
                      <div className="col-md-6 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <div>
                            <img
                              className="img-60"
                              style={{objectFit: 'cover', width: 60, height: 60}}
                              src={data.course.photo}
                              alt="#"
                            />
                          </div>
                          <label className="mb-0 ml-2">
                            <p
                              className="mb-0"
                              style={{ fontSize: "14px", fontWeight: "600" }}
                            >
                              {data.course.name}
                            </p>
                            <div
                              className={`badge text-wrap text-capitalize ${
                                data.course.type === "online"
                                  ? "badge-primary"
                                  : data.course.type === "Face to Face"
                                  ? "badge-danger"
                                  : "badge-pink"
                              }`}
                            >
                              {data.course.type}
                            </div>
                          </label>
                        </div>
                        <div>
                          <div>
                            <img
                              className="img-40 rounded-circle"
                              style={{objectFit: 'cover', width: 40, height: 40}}
                              src={data.trainer.photo}
                              alt="#"
                            />
                          </div>
                          <label className="mb-0 ml-2">
                            <p className="font-weight-bold mb-0">
                              {data.trainer.name}
                            </p>
                            <p className="mb-0">{data.trainer.company}</p>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 d-flex align-items-center">
                        <p className="font-weight-bold mb-0 txt-success">
                          {new Intl.NumberFormat("id", {
                            style: "currency",
                            currency: "IDR",
                          }).format(data.course.price)}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
              <hr />
              <p
                className="text-muted"
                style={{ fontWeight: "600", fontSize: "18px" }}
              >
                Pembayaran
              </p>
              <div className="d-flex mx-5">
                <p
                  className="text-muted text-work mb-0 mr-auto"
                  style={{ fontSize: "16px" }}
                >
                  Subtotal Produk ({detailSelected.course_total} pelatihan)
                </p>
                <p
                  className="mb-0"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  {new Intl.NumberFormat("id", {
                    style: "currency",
                    currency: "IDR",
                  }).format(detailSelected.subtotal)}
                </p>
              </div>
              {detailSelected.promo_discount.discount_type !== null && (
                <>
                  <div className="d-flex mx-5">
                    <p
                      className="text-muted text-work mb-0 mr-auto"
                      style={{ fontSize: "16px" }}
                    >
                      Kupon Promo{" "}
                    </p>
                    <p
                      className="mb-0"
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      {detailSelected.promo_discount.discount_type === "Persen"
                        ? detailSelected.promo_discount.nominal + "%"
                        : detailSelected.promo_discount.discount_type ===
                          "Rupiah"
                        ? new Intl.NumberFormat("id", {
                            style: "currency",
                            currency: "IDR",
                          }).format(detailSelected.promo_discount.nominal)
                        : null}
                    </p>
                  </div>
                </>
              )}
              {detailSelected.point !== null && (
                <>
                  <div className="d-flex mx-5">
                    <p
                      className="text-muted text-work mb-0 mr-auto"
                      style={{ fontSize: "16px" }}
                    >
                      Points
                    </p>
                    <p
                      className="mb-0"
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      {new Intl.NumberFormat("id", {
                        style: "currency",
                        currency: "IDR",
                      }).format(detailSelected.point)}
                    </p>
                  </div>
                </>
              )}
              <div className="d-flex mx-5">
                <p
                  className="text-muted text-work mb-0 mr-auto"
                  style={{ fontSize: "16px" }}
                >
                  Total Keseluruhan
                </p>
                <p
                  className="mb-0 txt-success"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  {new Intl.NumberFormat("id", {
                    style: "currency",
                    currency: "IDR",
                  }).format(detailSelected.total)}
                </p>
              </div>
              <div className="d-flex mx-5">
                <p
                  className="text-muted text-work mb-0 mr-auto"
                  style={{ fontSize: "16px" }}
                >
                  Metode Pembayaran
                </p>
                <p
                  className="mb-0"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  {detailSelected.method}
                </p>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default TransaksiPeserta;
