import Get from "../Get";
import Post from "../Post";
import Delete from "../Delete"
const list_promo = (data) =>
  Get(
    `super-admin/promos?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const detail_promo = (id) => Get(`super-admin/promos/${id}`);
const get_update = id => Get(`super-admin/promos/${id}/update`);
const insert_promo = (data) => Post(`super-admin/promos`, data);
const update_promo = (id, data) => Post(`super-admin/promos/${id}`, data);
const delete_promo = (id) => Delete(`super-admin/promos/${id}`);

const SuperadminPromo = {
  list_promo,
  detail_promo,
  get_update,
  insert_promo,
  update_promo,
  delete_promo,
};

export default SuperadminPromo;
