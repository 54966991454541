import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Form, Button } from 'semantic-ui-react';
import IMGUbahPassDone from '../../assets/images/ubahPassword.png';
import API from "../../api";

const NewPassword = (props) => {
    const [DataPassword, setDataPassword] = useState({});
    const [matchPassword, setmatchPassword] = useState(false);
    const [DoneSubmit, setDoneSubmit] = useState(false);

    let query = new URLSearchParams(useLocation().search);

    const handleChange = (e) => {
        setDataPassword({
            ...DataPassword,
            code_verification_password: query.get('code') || props.token,
            [e.target.name]:e.target.value
        })
    }

    useEffect(() => {
        if(DataPassword.password){
            setmatchPassword(DataPassword.password === DataPassword.confirm_password)
        }
    }, [DataPassword]);

    if(DoneSubmit){
        return(
            <div className='text-center p-0 px-md-5 mx-auto'>
                <img src={IMGUbahPassDone} className='w-25' alt='Omind'/>
                <h2>Ubah Password Berhasil</h2>
                <p>Password baru Anda telah disimpan </p>

                <Button type='button' className="btn btn-primary btn-block" primary loading={false} onClick={()=>{
                    query.delete('code');
                    props.setviewLogin('login')
                }}>Login</Button>
            </div>
        )
    }

    return(
        <div className='text-center p-0 px-md-5 mx-auto'>
            <h2>Buat Password Baru</h2>
            <p>Verifikasi berhasil, sekarang Anda bisa mengubah password Anda</p>
            <Form className='text-left' onSubmit={()=>{
                API.user.forgot_password_new(DataPassword)
                    .then(json => {
                        setDoneSubmit(true)
                    })
                    .catch(e => {
                        alert(e.message);
                    });
                }}>
                <div className="form-group">
                    <input className="form-control" type="password" name="password" required onChange={handleChange} placeholder="Password Baru"  />
                </div>
                <div className="form-group">
                    <input className="form-control" type="password" name="confirm_password" required onChange={handleChange} placeholder="Konfirmasi Password Baru"   />
                </div>

                <Button type='submit'className="btn btn-primary btn-block" primary loading={false} disabled={!matchPassword}>Ubah Password</Button>

                <Button type='button' className="btn btn-outline-primary btn-block mt-3" primary loading={false} basic onClick={()=>{
                    props.setviewLogin('login')
                }}>Kembali ke Login</Button>

            </Form>
        </div>
    )
}

export default NewPassword;
