import React, { useState, useEffect } from 'react';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom';
import { BreadcrumbComp, OverviewPerusahaan } from '../../../component';
import { MoreHorizontal } from 'react-feather';
import API from "../../../api";
import Pusher from 'pusher-js';
import {pusher_app_key} from "../../../helper";
import swal from 'sweetalert2';

let pusher = new Pusher(pusher_app_key(), {
    cluster: 'ap1',
});

let channel = pusher.subscribe('notification');

const NotifDetail = () => {
    const user = JSON.parse(localStorage.getItem('data'));

    const [notifications, setNotifications] = useState([]);

    const updateNotification = () => {
        API.notification.get_all()
            .then(json => {
                let notification = json.data.data;
                setNotifications(notification);
            });
    };

    const deleteNotification = (val, key) => {
        API.notification.del(val.id);
        let notif = [...notifications];
        notif.splice(key, 1);
        setNotifications(notif);
        swal.fire('Sukses', 'Notifikasi berhasil dihapus', 'success');
    };

    useEffect(() => {
        API.notification.readAll();
        updateNotification();
        channel.bind(`notify-${user.id}`, res => {
            updateNotification();
        });
    }, []);

    return(
      <>
          <div className="page-header">
            <BreadcrumbComp title={'Notifikasi'}>
              <li className="breadcrumb-item">
                <Link to="/" className='text-warning'>
                  <Home />
                </Link>
              </li>
              <li className="breadcrumb-item">Dashboard</li>
              <li className="breadcrumb-item active">Notifikasi</li>
            </BreadcrumbComp>
          </div>
        <div className="card p-3">
            {
                notifications.map((v,k) => (
                    <>
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-md-1'>
                                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M25.1625 18.9221V32.0176H23.7421C22.9107 32.0176 22.276 32.3501 22.001 32.9299C21.7259 33.5097 21.87 34.2115 22.3959 34.8555L27.0756 40.5842C27.5472 41.1613 28.2175 41.4924 28.9147 41.4924C29.6118 41.4924 30.2822 41.1614 30.7539 40.5841L35.4334 34.8555C35.9595 34.2115 36.1034 33.5097 35.8283 32.9299C35.5533 32.3502 34.9187 32.0176 34.0873 32.0176H32.6669V18.9223C32.6669 17.9733 31.8948 17.2012 30.9458 17.2012H26.8835C25.9345 17.2012 25.1625 17.9732 25.1625 18.9221ZM23.6887 33.731H26.0192C26.4923 33.731 26.8759 33.3475 26.8759 32.8743V18.9221C26.8759 18.918 26.8793 18.9146 26.8835 18.9146H30.9458C30.95 18.9146 30.9533 18.918 30.9533 18.9223V32.8743C30.9533 33.3475 31.3369 33.731 31.8101 33.731L34.1493 33.7302L29.4268 39.5C29.2799 39.6798 29.0979 39.7789 28.9146 39.7789C28.7312 39.7789 28.5493 39.6798 28.4024 39.5L23.6887 33.731Z" fill="#FF6A5A"/>
                                        <path d="M13.9125 15.9826H15.333V29.078C15.333 30.027 16.105 30.799 17.054 30.799H21.1162C22.0653 30.799 22.8373 30.027 22.8373 29.0781V15.9826H24.2576C25.0891 15.9826 25.7238 15.6501 25.9988 15.0703C26.2738 14.4904 26.1298 13.7887 25.6038 13.1446L20.9242 7.41602C20.4526 6.83892 19.7823 6.50781 19.0851 6.50781C18.388 6.50781 17.7176 6.83883 17.2459 7.4161L12.5664 13.1446C12.0404 13.7887 11.8964 14.4905 12.1714 15.0704C12.4465 15.65 13.0811 15.9826 13.9125 15.9826ZM13.8604 14.2692L18.573 8.50022C18.72 8.32039 18.9019 8.22134 19.0852 8.22134C19.2686 8.22134 19.4505 8.32039 19.5974 8.50014L24.3107 14.2692H21.9807C21.5076 14.2692 21.124 14.6527 21.124 15.1259V29.0781C21.124 29.0822 21.1206 29.0856 21.1164 29.0856H17.0542C17.0499 29.0856 17.0466 29.0822 17.0466 29.078V15.1259C17.0466 14.6527 16.663 14.2692 16.1898 14.2692H13.8604Z" fill="#FF6A5A"/>
                                        <path d="M38.1099 4H9.89041C9.41726 4 9.03369 4.38357 9.03369 4.85672V43.1433C9.03369 43.6164 9.41726 44 9.89041 44H38.1099C38.5831 44 38.9666 43.6164 38.9666 43.1433V4.85672C38.9666 4.38357 38.5831 4 38.1099 4ZM37.2532 42.2866H10.7471V5.71345H37.2532V42.2866Z" fill="#FF6A5A"/>
                                    </svg>
                                </div>
                                <div className='col-md-11'>
                                    <div className="schedule">
                                        <div className="media">
                                            <div className="media-body">
                                                <h6 className='text-danger'>{v.title}</h6>
                                                <label>{v.description}</label>
                                                <p>{v.created_at_str}</p>
                                            </div>
                                            <div className="onhover-dropdown">
                                                <div className="mb-0">
                                                    <MoreHorizontal />
                                                </div>
                                                <ul className="notification-dropdown onhover-show-div p-0" style={{top:'39px'}}>
                                                    <li onClick={() => deleteNotification(v, k)}>Hapus notifikasi ini</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </>
                ))
            }
            {/*<div className="card-body">
                <div className='row'>
                    <div className='col-md-1'>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25.2587 17.3637C25.5037 17.6086 25.9009 17.6086 26.1459 17.3637L28.0282 15.4813C28.266 15.2351 28.2626 14.8438 28.0206 14.6017C27.7786 14.3597 27.3872 14.3563 27.141 14.5941L25.7023 16.0328L25.5184 15.849C25.2722 15.6112 24.8809 15.6146 24.6389 15.8566C24.3968 16.0987 24.3934 16.49 24.6312 16.7362L25.2587 17.3637Z" fill="#00BA42"/>
                            <path d="M8.7671 16.9209H21.3162C21.6627 16.9209 21.9436 16.64 21.9436 16.2935C21.9436 15.9469 21.6627 15.666 21.3162 15.666H8.7671C8.42057 15.666 8.13965 15.9469 8.13965 16.2935C8.13965 16.64 8.42057 16.9209 8.7671 16.9209Z" fill="#00BA42"/>
                            <path d="M25.2587 21.1303C25.5037 21.3752 25.9009 21.3752 26.1459 21.1303L28.0282 19.2479C28.266 19.0017 28.2626 18.6104 28.0206 18.3683C27.7786 18.1263 27.3872 18.1229 27.141 18.3607L25.7023 19.7995L25.5184 19.6156C25.2722 19.3778 24.8809 19.3812 24.6389 19.6233C24.3968 19.8653 24.3934 20.2566 24.6312 20.5028L25.2587 21.1303Z" fill="#00BA42"/>
                            <path d="M8.7671 20.6866H21.3162C21.6627 20.6866 21.9436 20.4056 21.9436 20.0591C21.9436 19.7126 21.6627 19.4316 21.3162 19.4316H8.7671C8.42057 19.4316 8.13965 19.7126 8.13965 20.0591C8.13965 20.4056 8.42057 20.6866 8.7671 20.6866Z" fill="#00BA42"/>
                            <path d="M25.2587 24.8939C25.5037 25.1389 25.9009 25.1389 26.1459 24.8939L28.0282 23.0116C28.266 22.7654 28.2626 22.374 28.0206 22.132C27.7786 21.89 27.3872 21.8866 27.141 22.1244L25.7023 23.5631L25.5184 23.3793C25.2722 23.1415 24.8809 23.1449 24.6389 23.3869C24.3968 23.6289 24.3934 24.0203 24.6312 24.2665L25.2587 24.8939Z" fill="#00BA42"/>
                            <path d="M8.7671 24.4492H21.3162C21.6627 24.4492 21.9436 24.1683 21.9436 23.8218C21.9436 23.4753 21.6627 23.1943 21.3162 23.1943H8.7671C8.42057 23.1943 8.13965 23.4753 8.13965 23.8218C8.13965 24.1683 8.42057 24.4492 8.7671 24.4492Z" fill="#00BA42"/>
                            <path d="M28.0282 25.8878C27.7832 25.6429 27.386 25.6429 27.141 25.8878L25.7023 27.3266L25.5184 27.1427C25.2722 26.9049 24.8809 26.9083 24.6389 27.1504C24.3968 27.3924 24.3934 27.7838 24.6312 28.03L25.2587 28.6574C25.5037 28.9024 25.9009 28.9024 26.1459 28.6574L28.0282 26.775C28.2732 26.53 28.2732 26.1328 28.0282 25.8878Z" fill="#00BA42"/>
                            <path d="M37.1152 30.9938L33.1516 36.6553L31.7982 35.3019C31.552 35.0641 31.1607 35.0675 30.9186 35.3096C30.6766 35.5516 30.6732 35.9429 30.911 36.1891L32.7934 38.0715C32.911 38.1892 33.0706 38.2553 33.237 38.2553C33.2551 38.2563 33.2734 38.2563 33.2916 38.2553C33.4764 38.2391 33.6445 38.142 33.7508 37.9899L38.143 31.7154C38.2797 31.5321 38.3058 31.2889 38.2112 31.0807C38.1165 30.8725 37.9161 30.7323 37.6881 30.7147C37.4601 30.6972 37.2406 30.8051 37.1152 30.9963V30.9938Z" fill="#00BA42"/>
                            <path d="M21.9436 27.5903C21.9436 27.2438 21.6627 26.9629 21.3162 26.9629H8.7671C8.42057 26.9629 8.13965 27.2438 8.13965 27.5903C8.13965 27.9369 8.42057 28.2178 8.7671 28.2178H21.3162C21.6627 28.2178 21.9436 27.9369 21.9436 27.5903Z" fill="#00BA42"/>
                            <path d="M16.2303 32.3249C16.1124 32.127 15.8991 32.0059 15.6688 32.0059C15.4385 32.0059 15.2251 32.127 15.1072 32.3249L12.5974 37.3445C12.4972 37.5451 12.5116 37.784 12.6353 37.971C12.7589 38.1581 12.973 38.2651 13.1969 38.2515C13.4207 38.238 13.6203 38.106 13.7205 37.9054L14.4848 36.37H16.8465L17.6139 37.9054C17.7688 38.2156 18.1458 38.3414 18.4559 38.1865C18.7661 38.0316 18.8919 37.6546 18.737 37.3445L16.2303 32.3249ZM15.1122 35.1151L15.6656 34.0083L16.2191 35.1151H15.1122Z" fill="#00BA42"/>
                            <path d="M21.939 33.2354H21.3116V32.6079C21.3116 32.2614 21.0306 31.9805 20.6841 31.9805C20.3376 31.9805 20.0567 32.2614 20.0567 32.6079V33.2354H19.4292C19.0827 33.2354 18.8018 33.5163 18.8018 33.8628C18.8018 34.2094 19.0827 34.4903 19.4292 34.4903H20.0567V35.1177C20.0567 35.4643 20.3376 35.7452 20.6841 35.7452C21.0306 35.7452 21.3116 35.4643 21.3116 35.1177V34.4903H21.939C22.2855 34.4903 22.5665 34.2094 22.5665 33.8628C22.5665 33.5163 22.2855 33.2354 21.939 33.2354Z" fill="#00BA42"/>
                            <path d="M34.4903 26.3334C33.8561 26.3357 33.2244 26.4121 32.6079 26.5612V12.5294C32.6079 12.363 32.5418 12.2035 32.4241 12.0858L25.5221 5.18384C25.4045 5.06617 25.2449 5.00004 25.0785 5H6.88236C5.84276 5 5 5.84276 5 6.88236V40.7648C5 41.8044 5.84276 42.6472 6.88236 42.6472H30.7256C31.157 42.6449 31.5745 42.4945 31.9083 42.2211C34.9737 43.2527 38.359 42.3757 40.5379 39.9856C42.7168 37.5955 43.2778 34.1437 41.9679 31.1866C40.6579 28.2295 37.7245 26.3256 34.4903 26.3334ZM25.7059 7.14212L30.4658 11.902H26.3334C25.9869 11.902 25.7059 11.6211 25.7059 11.2745V7.14212ZM6.88236 41.3923C6.53583 41.3923 6.25491 41.1113 6.25491 40.7648V6.88236C6.25491 6.53583 6.53583 6.25491 6.88236 6.25491H24.451V11.2745C24.451 12.3141 25.2938 13.1569 26.3334 13.1569H31.353V26.9608C28.5365 28.1297 26.6088 30.774 26.3577 33.8131C26.1067 36.8523 27.5743 39.777 30.1609 41.3923H6.88236ZM34.4903 41.3923C30.6784 41.3923 27.5883 38.3021 27.5883 34.4903C27.5883 30.6784 30.6784 27.5883 34.4903 27.5883C38.3021 27.5883 41.3923 30.6784 41.3923 34.4903C41.3878 38.3003 38.3003 41.3878 34.4903 41.3923Z" fill="#00BA42"/>
                        </svg>
                    </div>
                    <div className='col-md-11'>
                        <div className="schedule">
                            <div className="media">
                                <div className="media-body">
                                    <h6 className='text-success'>Lulus Tugas Final</h6>
                                    <label>Selamat Anda dinyatakan lulus dalam tugas final <span className='font-weight-bold'>Public Speaking King </span></label>
                                    <p>30 minutes yang lalu</p>
                                </div>
                                <div className="onhover-dropdown">
                                    <div className="mb-0">
                                        <MoreHorizontal />
                                    </div>
                                    <ul className="  notification-dropdown onhover-show-div p-0" style={{top:'39px'}}>
                                        <li>Hapus notifikasi ini</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <div className="card-body">
                <div className='row'>
                  <div className='col-md-1'>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25.1625 18.9221V32.0176H23.7421C22.9107 32.0176 22.276 32.3501 22.001 32.9299C21.7259 33.5097 21.87 34.2115 22.3959 34.8555L27.0756 40.5842C27.5472 41.1613 28.2175 41.4924 28.9147 41.4924C29.6118 41.4924 30.2822 41.1614 30.7539 40.5841L35.4334 34.8555C35.9595 34.2115 36.1034 33.5097 35.8283 32.9299C35.5533 32.3502 34.9187 32.0176 34.0873 32.0176H32.6669V18.9223C32.6669 17.9733 31.8948 17.2012 30.9458 17.2012H26.8835C25.9345 17.2012 25.1625 17.9732 25.1625 18.9221ZM23.6887 33.731H26.0192C26.4923 33.731 26.8759 33.3475 26.8759 32.8743V18.9221C26.8759 18.918 26.8793 18.9146 26.8835 18.9146H30.9458C30.95 18.9146 30.9533 18.918 30.9533 18.9223V32.8743C30.9533 33.3475 31.3369 33.731 31.8101 33.731L34.1493 33.7302L29.4268 39.5C29.2799 39.6798 29.0979 39.7789 28.9146 39.7789C28.7312 39.7789 28.5493 39.6798 28.4024 39.5L23.6887 33.731Z" fill="#FF6A5A"/>
                      <path d="M13.9125 15.9826H15.333V29.078C15.333 30.027 16.105 30.799 17.054 30.799H21.1162C22.0653 30.799 22.8373 30.027 22.8373 29.0781V15.9826H24.2576C25.0891 15.9826 25.7238 15.6501 25.9988 15.0703C26.2738 14.4904 26.1298 13.7887 25.6038 13.1446L20.9242 7.41602C20.4526 6.83892 19.7823 6.50781 19.0851 6.50781C18.388 6.50781 17.7176 6.83883 17.2459 7.4161L12.5664 13.1446C12.0404 13.7887 11.8964 14.4905 12.1714 15.0704C12.4465 15.65 13.0811 15.9826 13.9125 15.9826ZM13.8604 14.2692L18.573 8.50022C18.72 8.32039 18.9019 8.22134 19.0852 8.22134C19.2686 8.22134 19.4505 8.32039 19.5974 8.50014L24.3107 14.2692H21.9807C21.5076 14.2692 21.124 14.6527 21.124 15.1259V29.0781C21.124 29.0822 21.1206 29.0856 21.1164 29.0856H17.0542C17.0499 29.0856 17.0466 29.0822 17.0466 29.078V15.1259C17.0466 14.6527 16.663 14.2692 16.1898 14.2692H13.8604Z" fill="#FF6A5A"/>
                      <path d="M38.1099 4H9.89041C9.41726 4 9.03369 4.38357 9.03369 4.85672V43.1433C9.03369 43.6164 9.41726 44 9.89041 44H38.1099C38.5831 44 38.9666 43.6164 38.9666 43.1433V4.85672C38.9666 4.38357 38.5831 4 38.1099 4ZM37.2532 42.2866H10.7471V5.71345H37.2532V42.2866Z" fill="#FF6A5A"/>
                    </svg>
                  </div>
                  <div className='col-md-11'>
                    <div className="schedule">
                        <div className="media">
                            <div className="media-body">
                                <h6 className='text-danger'>Selesaikan Pembayaran</h6>
                                <label>Pembelian pelatihan Anda dengan no Transaksi</label>
                                <p>30 minutes yang lalu</p>
                            </div>
                            <div className="onhover-dropdown">
                                <div className="mb-0">
                                  <MoreHorizontal />
                                </div>
                                <ul className="  notification-dropdown onhover-show-div p-0" style={{top:'39px'}}>
                                    <li>Hapus notifikasi ini</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <hr />
            <div className="card-body">
                <div className='row'>
                  <div className='col-md-1'>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25.2587 17.3637C25.5037 17.6086 25.9009 17.6086 26.1459 17.3637L28.0282 15.4813C28.266 15.2351 28.2626 14.8438 28.0206 14.6017C27.7786 14.3597 27.3872 14.3563 27.141 14.5941L25.7023 16.0328L25.5184 15.849C25.2722 15.6112 24.8809 15.6146 24.6389 15.8566C24.3968 16.0987 24.3934 16.49 24.6312 16.7362L25.2587 17.3637Z" fill="#FCC932"/>
                      <path d="M8.7671 16.9209H21.3162C21.6627 16.9209 21.9436 16.64 21.9436 16.2935C21.9436 15.9469 21.6627 15.666 21.3162 15.666H8.7671C8.42057 15.666 8.13965 15.9469 8.13965 16.2935C8.13965 16.64 8.42057 16.9209 8.7671 16.9209Z" fill="#FCC932"/>
                      <path d="M25.2587 21.1303C25.5037 21.3752 25.9009 21.3752 26.1459 21.1303L28.0282 19.2479C28.266 19.0017 28.2626 18.6104 28.0206 18.3683C27.7786 18.1263 27.3872 18.1229 27.141 18.3607L25.7023 19.7995L25.5184 19.6156C25.2722 19.3778 24.8809 19.3812 24.6389 19.6233C24.3968 19.8653 24.3934 20.2566 24.6312 20.5028L25.2587 21.1303Z" fill="#FCC932"/>
                      <path d="M8.7671 20.6866H21.3162C21.6627 20.6866 21.9436 20.4056 21.9436 20.0591C21.9436 19.7126 21.6627 19.4316 21.3162 19.4316H8.7671C8.42057 19.4316 8.13965 19.7126 8.13965 20.0591C8.13965 20.4056 8.42057 20.6866 8.7671 20.6866Z" fill="#FCC932"/>
                      <path d="M25.2587 24.8939C25.5037 25.1389 25.9009 25.1389 26.1459 24.8939L28.0282 23.0116C28.266 22.7654 28.2626 22.374 28.0206 22.132C27.7786 21.89 27.3872 21.8866 27.141 22.1244L25.7023 23.5631L25.5184 23.3793C25.2722 23.1415 24.8809 23.1449 24.6389 23.3869C24.3968 23.6289 24.3934 24.0203 24.6312 24.2665L25.2587 24.8939Z" fill="#FCC932"/>
                      <path d="M8.7671 24.4492H21.3162C21.6627 24.4492 21.9436 24.1683 21.9436 23.8218C21.9436 23.4753 21.6627 23.1943 21.3162 23.1943H8.7671C8.42057 23.1943 8.13965 23.4753 8.13965 23.8218C8.13965 24.1683 8.42057 24.4492 8.7671 24.4492Z" fill="#FCC932"/>
                      <path d="M28.0282 25.8878C27.7832 25.6429 27.386 25.6429 27.141 25.8878L25.7023 27.3266L25.5184 27.1427C25.2722 26.9049 24.8809 26.9083 24.6389 27.1504C24.3968 27.3924 24.3934 27.7838 24.6312 28.03L25.2587 28.6574C25.5037 28.9024 25.9009 28.9024 26.1459 28.6574L28.0282 26.775C28.2732 26.53 28.2732 26.1328 28.0282 25.8878Z" fill="#FCC932"/>
                      <path d="M37.1152 30.9938L33.1516 36.6553L31.7982 35.3019C31.552 35.0641 31.1607 35.0675 30.9186 35.3096C30.6766 35.5516 30.6732 35.9429 30.911 36.1891L32.7934 38.0715C32.911 38.1892 33.0706 38.2553 33.237 38.2553C33.2551 38.2563 33.2734 38.2563 33.2916 38.2553C33.4764 38.2391 33.6445 38.142 33.7508 37.9899L38.143 31.7154C38.2797 31.5321 38.3058 31.2889 38.2112 31.0807C38.1165 30.8725 37.9161 30.7323 37.6881 30.7147C37.4601 30.6972 37.2406 30.8051 37.1152 30.9963V30.9938Z" fill="#FCC932"/>
                      <path d="M21.9436 27.5903C21.9436 27.2438 21.6627 26.9629 21.3162 26.9629H8.7671C8.42057 26.9629 8.13965 27.2438 8.13965 27.5903C8.13965 27.9369 8.42057 28.2178 8.7671 28.2178H21.3162C21.6627 28.2178 21.9436 27.9369 21.9436 27.5903Z" fill="#FCC932"/>
                      <path d="M16.2303 32.3249C16.1124 32.127 15.8991 32.0059 15.6688 32.0059C15.4385 32.0059 15.2251 32.127 15.1072 32.3249L12.5974 37.3445C12.4972 37.5451 12.5116 37.784 12.6353 37.971C12.7589 38.1581 12.973 38.2651 13.1969 38.2515C13.4207 38.238 13.6203 38.106 13.7205 37.9054L14.4848 36.37H16.8465L17.6139 37.9054C17.7688 38.2156 18.1458 38.3414 18.4559 38.1865C18.7661 38.0316 18.8919 37.6546 18.737 37.3445L16.2303 32.3249ZM15.1122 35.1151L15.6656 34.0083L16.2191 35.1151H15.1122Z" fill="#FCC932"/>
                      <path d="M21.939 33.2354H21.3116V32.6079C21.3116 32.2614 21.0306 31.9805 20.6841 31.9805C20.3376 31.9805 20.0567 32.2614 20.0567 32.6079V33.2354H19.4292C19.0827 33.2354 18.8018 33.5163 18.8018 33.8628C18.8018 34.2094 19.0827 34.4903 19.4292 34.4903H20.0567V35.1177C20.0567 35.4643 20.3376 35.7452 20.6841 35.7452C21.0306 35.7452 21.3116 35.4643 21.3116 35.1177V34.4903H21.939C22.2855 34.4903 22.5665 34.2094 22.5665 33.8628C22.5665 33.5163 22.2855 33.2354 21.939 33.2354Z" fill="#FCC932"/>
                      <path d="M34.4903 26.3334C33.8561 26.3357 33.2244 26.4121 32.6079 26.5612V12.5294C32.6079 12.363 32.5418 12.2035 32.4241 12.0858L25.5221 5.18384C25.4045 5.06617 25.2449 5.00004 25.0785 5H6.88236C5.84276 5 5 5.84276 5 6.88236V40.7648C5 41.8044 5.84276 42.6472 6.88236 42.6472H30.7256C31.157 42.6449 31.5745 42.4945 31.9083 42.2211C34.9737 43.2527 38.359 42.3757 40.5379 39.9856C42.7168 37.5955 43.2778 34.1437 41.9679 31.1866C40.6579 28.2295 37.7245 26.3256 34.4903 26.3334ZM25.7059 7.14212L30.4658 11.902H26.3334C25.9869 11.902 25.7059 11.6211 25.7059 11.2745V7.14212ZM6.88236 41.3923C6.53583 41.3923 6.25491 41.1113 6.25491 40.7648V6.88236C6.25491 6.53583 6.53583 6.25491 6.88236 6.25491H24.451V11.2745C24.451 12.3141 25.2938 13.1569 26.3334 13.1569H31.353V26.9608C28.5365 28.1297 26.6088 30.774 26.3577 33.8131C26.1067 36.8523 27.5743 39.777 30.1609 41.3923H6.88236ZM34.4903 41.3923C30.6784 41.3923 27.5883 38.3021 27.5883 34.4903C27.5883 30.6784 30.6784 27.5883 34.4903 27.5883C38.3021 27.5883 41.3923 30.6784 41.3923 34.4903C41.3878 38.3003 38.3003 41.3878 34.4903 41.3923Z" fill="#FCC932"/>
                    </svg>
                  </div>
                  <div className='col-md-11'>
                    <div className="schedule">
                        <div className="media">
                            <div className="media-body">
                                <h6 className='text-warning'>Komentar Ditambahkan</h6>
                                <label><span className='font-weight-bold'>DONNY P (Trainer)</span> Menambahkan Komentar di dalam <span className='font-weight-bold'>Tugas Final Anda</span></label>
                                <p>30 minutes yang lalu</p>
                            </div>
                            <div className="onhover-dropdown">
                                <div className="mb-0">
                                  <MoreHorizontal />
                                </div>
                                <ul className="  notification-dropdown onhover-show-div p-0" style={{top:'39px'}}>
                                    <li>Hapus notifikasi ini</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <hr />*/}

        </div>
      </>
    )
}

export default NotifDetail;
