import React, { useState, useEffect } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation, useHistory } from "react-router-dom";
import { TransaksiIcon } from "../../../component/common/constant/menu";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import dummy_king from "../../../assets/images/dummy_king.png";
import logo from "../../../assets/images/bca-logo.png";
import API from "../../../api";
import {numberWithDots} from "../../../helper";
import jsPDF from "jspdf";
const TransaksiPerPerusahaan = () => {
  const history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get("page") || 1;
  let query_status = query.get("status") || '';

  const [status, setStatus] = useState("");
  const [modalAutentifikasi, setmodalAutentifikasi] = useState(false);
  const [modalConfirm, setmodalConfirm] = useState(false);
  const [modalConfirmData, setmodalConfirmData] = useState("");
  const [modalDetail, setmodalDetail] = useState(false);
  const [detailSelected, setdetailSelected] = useState({});
  const [company, setCompany] = useState({});
  const [checkSudahLunas, setcheckSudahLunas] = useState(false);
  const columnsTabelTransaksi = [
    {
      dataField: "no",
      text: "No Transaksi",
      headerStyle: () => ({ width: "15%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2 text-center"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "tanggal",
      text: "Tanggal Transaksi",
      headerStyle: () => ({ width: "20%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2 text-center"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "nama_perusahaan",
      text: "Nama Perusahaan",
      headerStyle: () => ({ width: "25%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2 text-center"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center mb-2">
            <div>
              <img class="img-40" src={row.logo_perusahaan} alt="#"></img>
            </div>
            <label className="mb-0 ml-2">
              <p>{row.perusahaan}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "harga",
      text: "Harga",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2 text-center"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "15%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2 text-center"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <div
            className={`my-auto mx-auto p-2 text-center card btn-status btn-status_${
              cel === "Lunas"
                ? "aktif"
                : cel === "Tagihan"
                ? "terpakai"
                : cel === "Menunggu Pembayaran"
                ? "draft"
                : cel === "Dibatalkan" || cel === "Ditolak Admin"
                ? "ditolak"
                : "expired"
            }`}
          >
            {cel}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Aksi",
      headerStyle: () => ({ width: "20%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <div className="text-center">
            <button
              className="btn btn-pill btn-outline-primary btn-sm"
              type="button"
              onClick={() => {
                detailTransaction(row.id);
                setStatus(row.status);
                setmodalDetail(!modalDetail);
              }}
            >
              {" "}
              Detail{" "}
            </button>
          </div>
        );
      },
    },
  ];
  const [companyTransactions, setCompanyTransaction] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
      custom_queries: {status: query_status}
    },
  });
  const [search, setSearch] = useState('');

  let today = new Date();
  let day = new Intl.DateTimeFormat('en-US', {day: '2-digit'}).format(today);
  let month = new Intl.DateTimeFormat('en-US', {month: '2-digit'}).format(today);
  let date = today.getFullYear()+'-'+month+'-'+day+'_'+Math.floor(1000 + Math.random() * 9000);

  useEffect(() => {
    API.superadmin.transaction
      .list_company_transaction({ page, per_page, search, status: query_status })
      .then((json) => {
        json.data.data.map((val) => {
          let companyTransaction = { ...companyTransactions };
          companyTransaction.data = [];

          json.data.data.map((val) => {
            companyTransaction.data.push({
              id: val.id,
              no: val.no_transaction,
              tanggal: val.created_at,
              logo_perusahaan: val.company.photo,
              perusahaan: val.company.name,
              harga: new Intl.NumberFormat("id", {
                style: "currency",
                currency: "IDR",
              }).format(val.price),
              status: val.status,
            });
          });

          companyTransaction.meta.from = (page - 1) * per_page + 1;
          companyTransaction.meta.to =
            per_page > json.data.total
              ? json.data.total
              : page * per_page > json.data.total
              ? json.data.total
              : page * per_page;
          companyTransaction.meta.total = json.data.total;
          companyTransaction.meta.current_page = page;
          companyTransaction.meta.total_page = Math.ceil(
            companyTransaction.meta.total / companyTransaction.meta.per_page
          );

          setCompanyTransaction(companyTransaction);
        });
      });
  }, [page, search]);

  const handleCetakLaporan = () => {
    API.superadmin.transaction
        .list_company_transaction({per_page: 9999})
        .then((json) => {
          let transaction = { ...companyTransactions };
          transaction.data = [];
          const tableRows = []

          json.data.data.map((v) => {
            const transactionData = [
              v.no_transaction,
              v.created_at,
              v.company.name,
              new Intl.NumberFormat("id", {
                style: "currency",
                currency: "IDR",
              }).format(v.price),
              v.status,
            ]

            tableRows.push(transactionData)
          });

          const doc = new jsPDF()
          const tableColumn = ['ID Transaksi', 'Tanggal', 'Nama Perusahaan', 'Harga', 'Status'];

          doc.text(query_status === 'Dibatalkan' ? 'Tagihan Batal' : "List Transaksi Perusahaan", 14, 15);
          doc.autoTable(tableColumn, tableRows, { startY: 20 })
          doc.save(`transaksi-perusahaan-report_${date}.pdf`);
        });
  };

  const detailTransaction = (id) => {
    API.superadmin.transaction
      .company_transaction_detail(id)
      .then((json) => {
        setdetailSelected(json.data);
        setCompany(json.data.buyer);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(company);
  return (
    <>
      <div className="page-header d-flex">
        <BreadcrumbComp title={query_status === 'Dibatalkan' ? 'Tagihan Batal' : "Transaksi per Perusahaan"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/data-company" className="text-warning">
              <TransaksiIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">{query_status === 'Dibatalkan' ? 'Tagihan Batal' : "Transaksi per Perusahaan"}</li>
        </BreadcrumbComp>
        <div className="ml-auto">
          <button
            className="btn btn-pill btn-outline-primary btn-sm text-work bg-white"
            type="button"
            style={{ fontWeight: "600" }}
            onClick={() => handleCetakLaporan()}
          >
            <i className="fa fa-print" />
            &nbsp;Cetak List Tagihan
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  LIST TRANSAKSI {query_status === 'Dibatalkan' ? 'DIBATALKAN' : ''}
                </h5>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <form className="search-form contact-search">
                    <div className="form-group mb-0">
                      <input
                        className="form-control badge-pill"
                        type="text"
                        placeholder="Cari nomor invoice ..."
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="overflow-auto w-100">
                <TableComp
                  title_paggination="pelatihan"
                  columns={columnsTabelTransaksi}
                  data={companyTransactions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modalDetail} size="lg" style={{ maxWidth: "1050px" }}>
        <ModalHeader toggle={() => setmodalDetail(!modalDetail)}>
          <div
            className="text-muted my-2"
            style={{ fontWeight: "600", fontSize: "18px" }}
          >
            Detail Transaksi
          </div>
        </ModalHeader>
        <ModalBody className="pb-5">
          <div className="d-flex">
            <div className="d-flex mr-auto col pl-0">
              <div>
                <label className="text-muted text-work">No Transaksi</label>
                <h6 className="mb-4">{detailSelected.no_transaction}</h6>
              </div>
            </div>
            <div className="col-md-6 text-right justify-content-right">
              {status === "Lunas" ? (
                <button
                  className="btn btn-pill btn-outline-primary btn-sm"
                  type="button"
                  onClick={() => {
                    API.superadmin.transaction.invoice(detailSelected.id)
                        .then(json => {
                          let val = json.data;
                          let courses = [];

                          val.transaction_detail.map(v => {
                            let course = v.course_id;
                            let schedule = v.schedule_id;

                            courses.push({
                              id: course.id,
                              judul: `${course.name} ${schedule ? `- ${schedule.name}` : ''}`,
                              status: course.type,
                              thumbnail: course.thumbnail,
                              price: course.price,
                              tanggal: v.periode_course,
                              user_name: course.trainer_id.name,
                              user_company: course.company_id.name,
                              user_image: course.trainer_id.profile_picture,
                            });
                          });

                          history.push({
                            pathname: '/superadmin/unduh-invoice',
                            state: { detail: {
                                id: val.id,
                                user_name: val.user_name,
                                tanggal: val.transaction_date,
                                status: val.status,
                                no_transaksi: val.no_transaction,
                                metode: val.metode,
                                promo: val.promo_id ? (val.promo_id.discount_type === 'Persen' ? `-${val.promo_id.nominal}%` : `- Rp ${val.promo_id.nominal}`) : null,
                                harga_promo: val.promo_id ? (val.promo_id.discount_type === 'Persen' ? (val.subtotal * val.promo_id.nominal / 100) : `Rp ${numberWithDots(val.promo_id.nominal)}`) : null,
                                tanggal_pembayaran: val.expired_pay,
                                invoice: val.status === 'Lunas',
                                total_harga: `Rp ${numberWithDots(val.total)}`,
                                pelatihan: courses,
                                subtotal: val.subtotal,
                                point: val.point,
                                admin_fee: val.admin_fee,
                                total: val.total,
                                token: val.token,
                                va_account: val.va_account,
                              } }
                          })
                        });
                  }}
                >
                  {" "}
                  <i className="fa fa-download"></i> &nbsp;Unduh Invoice
                </button>
              ) : status === "Tagihan" ? (
                <div className="d-flex align-items-center">
                  <div className="mr-auto">
                    <button
                      onClick={() => {
                        setmodalConfirmData("batal");
                        setmodalConfirm(true);
                        setmodalDetail(!modalDetail);
                      }}
                      type="button"
                      className={`btn p-2 text-center card btn-status btn-status_expired
                  `}
                    >
                      Batalkan Transaksi Ini
                    </button>
                  </div>
                  <div>
                    <label className="text-muted text-work">
                      Status Transaksi (centang jika sudah lunas)
                    </label>
                    <div className="d-flex">
                      <div className="checkbox p-0 mr-auto">
                        <input
                          id="checkbox1"
                          type="checkbox"
                          value={checkSudahLunas}
                          onClick={() => setcheckSudahLunas(true)}
                        />
                        <label htmlFor="checkbox1">Sudah Lunas</label>
                      </div>
                      <button
                        onClick={() => {
                          setmodalConfirmData("konfirmasi");
                          setmodalConfirm(true);
                          setmodalDetail(!modalDetail);
                        }}
                        type="button"
                        className={`ml-2 btn p-2 text-center card btn-status btn-status_${
                          checkSudahLunas === true ? "aktif" : "draft"
                        } ${checkSudahLunas === false && "disabled"}
                  `}
                      >
                        Kofirmasi Lunas
                      </button>
                    </div>
                  </div>
                </div>
              ) : status === "Dibatalkan" ? (
                <>
                  <h6 className="text-danger text-center">
                    Transaksi ini dibatalkan karena
                  </h6>
                  <div className="p-2 card btn-status_draft text-center">
                    {detailSelected.reason_canceled}
                  </div>
                </>
              ) : (
                status === "Kedaluarsa" && (
                  <div className="p-2 card btn-status_expired text-center">
                    Perusahaan telah melewati batas pembayaran offline. Silakan
                    hubungi perusahaan untuk melakukan pembayaran melalui
                    payment gateway
                  </div>
                )
              )}
            </div>
          </div>
          <label className="text-muted text-work">Pembeli</label>
          <div className="d-flex align-items-center mb-4">
            <div>
              <img className="img-40" src={company.photo} alt="#"></img>
            </div>
            <label className="mb-0 ml-2">
              <p className="font-weight-bold mb-0">{company.name}</p>
            </label>
          </div>
          <label className="text-muted text-work">Status</label>
          <h6
            className={`w-25 p-2 text-center card btn-status btn-status_${
              status === "Lunas"
                ? "aktif"
                : status === "Tagihan"
                ? "terpakai"
                : status === "Dibatalkan"
                ? "ditolak"
                : status === "Ditolak Admin"
                ? "ditolak"
                : status === "Menunggu Pembayaran"
                ? "draft"
                : "expired"
            }`}
          >
            {status}
          </h6>
          <label className="text-muted text-work">Tanggal Pembelian</label>
          <h6 className="mb-4">{detailSelected.buy_date}</h6>
          <hr />
          <div>
            <p
              className="text-muted"
              style={{ fontWeight: "600", fontSize: "18px" }}
            >
              Daftar Pelatihan
            </p>
            {detailSelected?.courses &&
              detailSelected.courses.map((data, key) => (
                <div className="row mb-4">
                  <div className="col-md-6 d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <div>
                        <img
                          className="img-60"
                          src={data.course.photo}
                          alt="#"
                        ></img>
                      </div>
                      <label className="mb-0 ml-2">
                        <p
                          className="mb-0"
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          {data.course.name}
                        </p>
                        <div
                          className={`badge text-wrap text-capitalize ${
                            data.status === "online"
                              ? "badge-primary"
                              : data.status === "Face to Face"
                              ? "badge-danger"
                              : "badge-pink"
                          }`}
                        >
                          {data.course.type}
                        </div>
                        <p
                          className="mb-0"
                          style={{ fontSize: "10px", fontWeight: "400" }}
                        >
                          {data.course.date_range}
                        </p>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 border-right border-left d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <div>
                        <img
                          className="img-40 rounded-circle"
                          src={data.trainer.photo}
                          alt="#"
                        ></img>
                      </div>
                      <label className="mb-0 ml-2">
                        <p className="font-weight-bold mb-0">
                          {data.trainer.name}
                        </p>
                        <p className="mb-0">{data.trainer.company}</p>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2 d-flex align-items-center">
                    <p className="font-weight-bold mb-0 txt-success">
                      {data.course.price}
                    </p>
                  </div>
                </div>
              ))}
          </div>
          <hr />
          <p
            className="text-muted"
            style={{ fontWeight: "600", fontSize: "18px" }}
          >
            Pembayaran
          </p>
          <div className="d-flex mx-5">
            <p
              className="text-muted text-work mb-0 mr-auto"
              style={{ fontSize: "16px" }}
            >
              Subtotal Produk
            </p>
            <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>
              {new Intl.NumberFormat("id", {
                style: "currency",
                currency: "IDR",
              }).format(detailSelected.subtotal)}
            </p>
          </div>
          <div className="d-flex mx-5">
            <p
              className="text-muted text-work mb-0 mr-auto"
              style={{ fontSize: "16px" }}
            >
              Kupon Promo{" "}
            </p>
            <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>
              -{detailSelected.promo_discount}
            </p>
          </div>
          <div className="d-flex mx-5">
            <p
              className="text-muted text-work mb-0 mr-auto"
              style={{ fontSize: "16px" }}
            >
              Points
            </p>
            <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>
              {detailSelected.promo_discount}
            </p>
          </div>
          <div className="d-flex mx-5">
            <p
              className="text-muted text-work mb-0 mr-auto"
              style={{ fontSize: "16px" }}
            >
              Biaya Admin
            </p>
            <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>
              {new Intl.NumberFormat("id", {
                style: "currency",
                currency: "IDR",
              }).format(detailSelected.admin_fee)}
            </p>
          </div>
          <div className="d-flex mx-5">
            <p
              className="text-muted text-work mb-0 mr-auto"
              style={{ fontSize: "16px" }}
            >
              Total Keseluruhan
            </p>
            <p
              className="mb-0 txt-success"
              style={{ fontSize: "18px", fontWeight: "600" }}
            >
              {new Intl.NumberFormat("id", {
                style: "currency",
                currency: "IDR",
              }).format(detailSelected.total)}
            </p>
          </div>
          <div className="d-flex mx-5">
            <p
              className="text-muted text-work mb-0 mr-auto"
              style={{ fontSize: "16px" }}
            >
              Metode Pembayaran
            </p>
            <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>
              {detailSelected.method}
            </p>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalConfirm}
        toggle={() => setmodalConfirm(!modalConfirm)}
        size="md"
      >
        <ModalBody>
          <div className="text-center mt-3">
            <h6 className="d-flex justify-content-center">
              Apakah ada yakin akan&nbsp;
              {modalConfirmData === "konfirmasi" ? (
                <h6 className="text-success">mengkonfirmasi</h6>
              ) : (
                modalConfirmData === "batal" && (
                  <h6 className="text-danger">membatalkan</h6>
                )
              )}
              &nbsp;transaksi?
            </h6>
            <button
              className="btn btn-pill btn-primary btn-sm my-3 mr-3"
              type="button"
              onClick={() => {
                setmodalConfirm(!modalConfirm);
                setmodalAutentifikasi(true);
              }}
            >
              Ya
            </button>
            <button
              className="btn btn-pill btn-outline-primary btn-sm my-3"
              type="button"
              onClick={() => {
                setmodalConfirm(!modalConfirm);
              }}
            >
              {" "}
              Tidak
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalAutentifikasi}
        toggle={() => setmodalAutentifikasi(!modalAutentifikasi)}
        size="md"
      >
        <ModalBody>
          <div className="text-center mt-3 p-2">
            <h6>Dibutuhkan Autentikasi</h6>
            <p className="row justify-content-center">
              Masukkan password anda untuk&nbsp;{" "}
              {modalConfirmData === "konfirmasi" ? (
                <p>mengkonfirmasi</p>
              ) : (
                modalConfirmData === "batal" && <p>membatalkan</p>
              )}{" "}
              &nbsp;status pembayaran
            </p>
            <div className="justify-content-center mr-3">
              <input
                className="form-control"
                type="text"
                placeholder="Kata Sandi"
              />
            </div>
            <div>
              <button
                className="btn btn-pill btn-primary btn-sm my-3 mr-3"
                type="button"
                onClick={
                  modalConfirmData === "konfirmasi"
                    ? () => {
                        setmodalAutentifikasi(!modalAutentifikasi);
                        setStatus("Lunas");
                        setmodalDetail(true);
                      }
                    : modalConfirmData === "batal" &&
                      (() => {
                        setmodalAutentifikasi(!modalAutentifikasi);
                        setStatus("Dibatalkan");
                        setmodalDetail(true);
                      })
                }
              >
                Masuk
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TransaksiPerPerusahaan;
