import React, { useState, useEffect, Fragment } from 'react';
import {useHistory} from 'react-router-dom';
import { BreadcrumbComp } from '../../../component';
import {
  useLocation,
  Link
} from "react-router-dom";
import { PelatihanLainnyaIcon } from '../../../component/common/constant/menu';
import learning_dummy from '../../../assets/images/learning-dummy-image.png';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Collapse, ModalBody } from 'reactstrap';
import API from "../../../api";
import {numberWithDots, titleCase} from "../../../helper";


const PelatihanDetailLainnya = () => {
  let query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const status = query.get("status");
  const id = query.get("id");
  const [modalRequest, setmodalRequest] = useState(false);
  const [disableRequest, setdisableRequest] = useState(false);

  const [isAdding, setIsAdding] = useState(false);

  const [course, setCourse] = useState(null);
  const [accordions, setAccordion] = useState([]);
  const [cartnum, setCartNum] = useState(0);

  useEffect(() => {
    API.peserta.course.store_detail(id)
        .then(json => {
          setCourse(json.data);

          setdisableRequest(json.data.is_requested);

          let accordion = [];

          json.data.course_contents.map(val => {
            accordion.push(false);
          });

          setAccordion(accordion);
        })

    API.peserta.course.get_cart()
      .then(json => {
          setCartNum(json.data.size);
      });
  },[]);

    const buy = async () => {
        await API.peserta.course.add_to_cart(id).catch(e => {history.push('/pelatihan-lainnya/cart');});
        history.push('/pelatihan-lainnya/cart');
    }

  const addToCart = () => {
      setIsAdding(true);
      API.peserta.course.add_to_cart(id)
          .then(json => {
              setCartNum(num => num + 1);
              toast.success("Berhasil menambahkan ke Cart", { position: toast.POSITION.TOP_CENTER });
              setIsAdding(false);
          })
          .catch(e => {
              toast.error(e.message, { position: toast.POSITION.TOP_CENTER });
              setIsAdding(false);
          })
  };

  return (
    <>
      <ToastContainer />
      <div className="page-header">
        <BreadcrumbComp title={'Pelatihan Lainnya'}
          menuRight={
            <>
              <Link to='/pelatihan-lainnya/cart'>
                <button className="btn btn-outline-warning btn-sm bg-white mr-3" type="button">
                  <label className='d-flex align-items-center mb-0'>
                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 14C7.55228 14 8 13.5523 8 13C8 12.4477 7.55228 12 7 12C6.44772 12 6 12.4477 6 13C6 13.5523 6.44772 14 7 14Z" fill="#FFD200" />
                      <path d="M11.6665 14C12.2188 14 12.6665 13.5523 12.6665 13C12.6665 12.4477 12.2188 12 11.6665 12C11.1142 12 10.6665 12.4477 10.6665 13C10.6665 13.5523 11.1142 14 11.6665 14Z" fill="#FFD200" />
                      <path d="M14.0002 4.66667H4.8895L4.12016 2.82C4.01917 2.57677 3.84824 2.36899 3.62903 2.223C3.40982 2.07702 3.1522 1.99941 2.88883 2H1.3335V3.33334H2.8895L6.0515 10.9233C6.15483 11.1713 6.39816 11.3333 6.66683 11.3333H12.0002C12.2782 11.3333 12.5268 11.1607 12.6248 10.9013L14.6248 5.568C14.6623 5.46707 14.6748 5.35857 14.6614 5.25175C14.6479 5.14493 14.6089 5.04294 14.5475 4.95445C14.4862 4.86596 14.4044 4.79358 14.3091 4.74348C14.2138 4.69338 14.1078 4.66703 14.0002 4.66667ZM11.3335 8.66667H10.0002V10H8.66683V8.66667H7.3335V7.33334H8.66683V6H10.0002V7.33334H11.3335V8.66667Z" fill="#FFD200" />
                    </svg>
                    <p className='mb-0 font-weight-bold ml-2'>
                      Cart <span className='font-weight-normal'>({cartnum} items)</span>
                    </p>
                  </label>
                </button>
              </Link>
            </>
          }
        >
          <li className="breadcrumb-item">
            <Link to="/" className='text-warning'>
              <PelatihanLainnyaIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Lainnya</li>
          <li className="breadcrumb-item">{course ? course.company_id.name : 'Memuat...'}</li>
          <li className="breadcrumb-item">{course ? course.name : 'Memuat...'}</li>
        </BreadcrumbComp>
      </div>

      <div className='row' style={{ marginBottom: '7rem' }}>
        <div className='col-md-12'>
          <Link to='/pelatihan-lainnya'>
            <p className='btn p-0 text-theme'>
              <i className="fa fa-arrow-left"></i> Kembali
            </p>
          </Link>
        </div>

        {course &&
          <Fragment>
            <div className='col-md-7'>
              <div className='card p-4'>
                <img className="img-fluid w-100 mb-3" src={course.thumbnail} alt="omind"/>
                <div className='row'>
                  <div className='col-md-7'>
                    <h3 className='font-weight-bold'>{course.name}</h3>
                    <div className='d-flex'>
                      <label>
                        <div
                            className={`badge text-wrap text-capitalize ${course.type === 'Online' ? 'badge-primary' : course.type === 'Face to Face' ? 'badge-danger' : course.type === 'Enquiries' ? 'badge-success' : 'badge-pink'}`}>
                          {course.type}
                        </div>
                      </label>
                      <p className='text-muted mr-4 ml-2'><span className='text-muted'><i
                          className='fa fa-question-circle'></i></span></p>
                      <p className='text-muted mr-4'><span className='text-theme'><i
                          className='fa fa-clock-o'></i></span>&nbsp; Durasi <span
                          className='text-dark font-weight-bold'>{course.day_duration} hari</span></p>
                    </div>
                  </div>
                  <div className='col-md-5'>
                    <div className='d-flex '>
                      <div>
                        <img className="img-50 rounded-circle"
                             src={course.company_id.logo} alt="#"></img>
                      </div>
                      <label className='mb-0 ml-2'>
                        <p className='text-muted mb-0'>Penyelenggara</p>
                        <p className='font-weight-bold text-muted mb-0 mt-0'
                           style={{fontSize: '14px'}}>{course.company_id.name}</p>
                      </label>
                    </div>
                  </div>
                </div>
                <p className='text-muted mb-0'>Kategori <span className='text-dark font-weight-bold'>{course.category_id.name}</span>
                </p>
                <hr/>
                <h5 className='font-weight-bold text-theme mt-3'>Pelatihan Terdiri dari</h5>
                <div className='d-flex'>
                  <p className='text-muted mr-4'><span className='text-theme'><i
                      className='fa fa-play-circle-o'></i></span>&nbsp; {course.course_consists_of.filter(val => {return val.type === 'Video'})[0] ? course.course_consists_of.filter(val => {return val.type === 'Video'})[0].total : 0}  Video</p>
                  <p className='text-muted mr-4'><span className='text-theme'><i
                      className='fa fa fa-file-pdf-o'></i></span>&nbsp; {course.course_consists_of.filter(val => {return val.type === 'E-Book'})[0] ? course.course_consists_of.filter(val => {return val.type === 'E-Book'})[0].total : 0} PDF</p>
                  <p className='text-muted mr-4'><span className='text-theme'><i
                      className='fa fa fa-file-powerpoint-o'></i></span> &nbsp; {course.course_consists_of.filter(val => {return val.type === 'Slide Powerpoint'})[0] ? course.course_consists_of.filter(val => {return val.type === 'Slide Powerpoint'})[0].total : 0} PPT</p>
                  <p className='text-muted mr-4'><span className='text-theme'><i className='fa fa-pencil-square-o'></i></span>&nbsp; {course.course_consists_of.filter(val => {return val.type === 'Quiz'})[0] ? course.course_consists_of.filter(val => {return val.type === 'Quiz'})[0].total : 0} Kuis</p>
                  <p className='text-muted mr-4'><span className='text-theme'><i
                      className='fa fa-clipboard'></i></span>&nbsp;  {course.course_consists_of.filter(val => {return val.type === 'Tugas Final'})[0] ? course.course_consists_of.filter(val => {return val.type === 'Tugas Final'})[0].total : 0} Ujian Final</p>
                </div>
                <h5 className='font-weight-bold text-theme mt-3'>Deskripsi Pelatihan</h5>
                <p className='text-justify'>{course.description}</p>
                <h5 className='font-weight-bold text-theme mt-3'>Tujuan Pembelajaran</h5>
                <p className='text-justify'>{course.purpose}</p>
                <h5 className='font-weight-bold text-theme mt-3'>Target Peserta</h5>
                <p className='text-justify'>{course.target_audience}</p>
                <h5 className='font-weight-bold text-theme mt-3'>Prasyarat</h5>
                <p className='text-justify'>{course.precondition}</p>
              </div>
                <div className='card p-4'>
                    <h3 className='text-muted' style={{fontWeight:'600', fontSize:'22'}}>Trainer</h3>
                    {course.trainer.map(v => (
                       <>
                           <div className='d-flex my-3'>
                               {v.profile_picture &&
                                   <div>
                                       <img className="img-50 rounded-circle" style={{height: '50px', objectFit: 'cover'}} src={v.profile_picture.replace(/ /g, '%20')} alt="#" />
                                   </div>
                               }

                               <label className='mb-0 ml-2'>
                                   <p className='font-weight-bold text-muted mb-0 mt-0' style={{ fontSize: '14px' }}>{v.name}</p>
                                   <p className='text-muted mb-0'>{titleCase(v.profession_id.name)}</p>
                               </label>
                           </div>
                           <p style={{fontSize:'14px'}}>{v.short_profile}</p>
                       </>
                    ))}
                </div>
            </div>
            <div className='col-md-5'>
              <div className='card p-4'>

                {
                  course.course_contents.map((val, key) => {
                    return <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="card">
                            <div className="card-header">
                              <h5 className="mb-0">
                                <button className="btn btn-link pl-0 font-weight-bold" data-toggle="collapse"
                                        onClick={() => {
                                          let accord = [...accordions];
                                          accord[key] = !accord[key];
                                          setAccordion(accord);
                                        }}
                                        data-target="#collapseicon" aria-expanded={accordions[key]}
                                        aria-controls="collapseicon">{val.name}
                                </button>
                              </h5>
                            </div>
                            <Collapse isOpen={accordions[key]}>
                              <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon"
                                   data-parent="#accordion">
                                  {
                                    val.theory.map(t => {
                                        return <div className="card-body filter-cards-view animate-chk p-3">
                                            <p className='mb-0 text-dark'>{t.name}</p>
                                            {
                                                t.type === 'Slide Powerpoint' ?
                                                    <p className='text-dark'><span className='text-theme'><i
                                                        className='fa fa fa-file-powerpoint-o'></i></span>&nbsp;PPT</p> :
                                                t.type === 'Video' ?
                                                    <p className='text-dark'><span className='text-theme'><i
                                                        className='fa fa-play-circle-o'></i></span>&nbsp;Video</p> :
                                                t.type === 'E-Book' ?
                                                    <p className='text-dark'><span className='text-theme'><i
                                                        className='fa fa fa-file-pdf-o'></i></span>&nbsp;PDF</p> :
                                                t.type === 'Quiz' ?
                                                    <p className='text-dark'><span className='text-theme'><i
                                                        className='fa fa-pencil-square-o'></i></span>&nbsp;Kuis</p> :
                                                    <p className='text-dark'><span className='text-theme'><i className='fa fa-clipboard'></i></span>&nbsp;Ujian Final</p>
                                            }
                                        </div>
                                    })
                                  }
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      </div>
                    </div>
                  })
                }

                {
                  course.course_schedule.map((val, key) => {
                      return <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                          <div className="row">
                              <div className="col-xl-12">
                                  <div className="card">
                                      <div className="card-header">
                                          <h5 className="mb-0">
                                              <button className="btn btn-link pl-0 font-weight-bold" data-toggle="collapse"
                                                      onClick={() => {
                                                          let accord = [...accordions];
                                                          accord[key + course.course_contents.length] = !accord[key + course.course_contents.length];
                                                          setAccordion(accord);
                                                      }}
                                                      data-target="#collapseicon" aria-expanded={accordions[key + course.course_contents.length]}
                                                      aria-controls="collapseicon">{course.type} - {val.name}
                                              </button>
                                          </h5>
                                      </div>
                                      <Collapse isOpen={accordions[key + course.course_contents.length]}>
                                          <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon"
                                               data-parent="#accordion">
                                              {
                                                  val.meeting.map(m => {
                                                      return <div className="card-body filter-cards-view animate-chk p-3">
                                                          <p className='mb-0 text-dark'>{m.name}</p>
                                                          {
                                                              <p className='text-dark'><span className='text-theme'><i className='fa fa-clipboard'></i></span>&nbsp;{course.type}</p>
                                                          }
                                                      </div>
                                                  })
                                              }
                                          </div>
                                      </Collapse>
                                  </div>
                              </div>
                          </div>
                      </div>
                  })
                }

                {course.type === 'Enquiries' ?
                    <button className={`btn btn-pill btn-outline-primary btn-sm w-100 ${disableRequest && 'disabled'}`}
                            type="button"
                            disabled={disableRequest}
                            onClick={() => {
                                API.peserta.course.request_course(course.id)
                                    .then(json => {
                                        setmodalRequest(!modalRequest)
                                    }).catch(e => {
                                    alert(e.message);
                                });
                            }}>{disableRequest ? 'Request Pelatihan telah dikirim' : 'Request Pelatihan'}</button>
                    :
                    <div>
                      <button
                          disabled={isAdding}
                          onClick={addToCart}
                          className="btn btn-pill btn-primary btn-sm d-flex align-items-center w-100 justify-content-center"
                          type="button">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M7 14C7.55228 14 8 13.5523 8 13C8 12.4477 7.55228 12 7 12C6.44772 12 6 12.4477 6 13C6 13.5523 6.44772 14 7 14Z"
                              fill="white"/>
                          <path
                              d="M11.6667 14C12.219 14 12.6667 13.5523 12.6667 13C12.6667 12.4477 12.219 12 11.6667 12C11.1145 12 10.6667 12.4477 10.6667 13C10.6667 13.5523 11.1145 14 11.6667 14Z"
                              fill="white"/>
                          <path
                              d="M13.9999 4.66667H4.88925L4.11992 2.82C4.01893 2.57677 3.84799 2.36899 3.62878 2.223C3.40957 2.07702 3.15196 1.99941 2.88859 2H1.33325V3.33334H2.88925L6.05125 10.9233C6.15459 11.1713 6.39792 11.3333 6.66659 11.3333H11.9999C12.2779 11.3333 12.5266 11.1607 12.6246 10.9013L14.6246 5.568C14.662 5.46707 14.6746 5.35857 14.6611 5.25175C14.6477 5.14493 14.6086 5.04294 14.5473 4.95445C14.486 4.86596 14.4042 4.79358 14.3089 4.74348C14.2136 4.69338 14.1076 4.66703 13.9999 4.66667ZM11.3333 8.66667H9.99992V10H8.66659V8.66667H7.33325V7.33334H8.66659V6H9.99992V7.33334H11.3333V8.66667Z"
                              fill="white"/>
                        </svg>
                        &nbsp; {isAdding ? 'Menambahkan...' : 'Tambah ke Cart'}
                      </button>
                      <Link to='/pelatihan-lainnya/cart'>
                        <button className="btn btn-pill btn-outline-primary btn-sm w-100 mt-3" type="button" onClick={buy}>Beli
                          Pelatihan
                        </button>
                      </Link>
                    </div>
                }

              </div>
            </div>
          </Fragment>
        }
      </div>
        {course &&
          <div className="footer-custom py-3 px-4 bg-white shadow-lg d-flex" style={{ position: 'fixed', bottom: '0', marginLeft: '-30px', width: '86%' }}>
            <div className='mr-auto'>
              <p className='mb-0 mt-0' style={{ fontSize: '18px' }}>{course.name}</p>
              <div className='d-flex align-items-center'>
                  {course.trainer_id.profile_picture &&
                      <div>
                          <img className="img-40 rounded-circle" src={course.trainer_id.profile_picture.replace(/ /g, '%20')} alt="#" style={{height: '40px', objectFit: 'cover'}}/>
                      </div>
                  }

                <label className='mb-0 ml-2'>
                  <p className='mb-0 mt-0' style={{ fontSize: '14px', fontWeight: '600' }}>{course.trainer_id.name}</p>
                  <p className='text-muted mb-0'>{course.company_id.name}</p>
                </label>
              </div>
            </div>
            <p className='mr-5 my-auto text-success' style={{fontWeight:'bold', fontSize:'22px'}}>Rp {numberWithDots(course.price)}</p>
              {
                  course.type === 'Enquiries' ?
                      <div className='d-flex align-items-center mr-4'>
                          <button className={`btn btn-pill btn-outline-primary btn-sm w-100 ${disableRequest && 'disabled'}`}
                                  type="button"
                                  disabled={disableRequest}
                                  onClick={() => {
                                      API.peserta.course.request_course(course.id)
                                          .then(json => {
                                            setmodalRequest(!modalRequest)
                                          }).catch(e => {
                                            alert(e.message);
                                          });
                                  }}>{disableRequest ? 'Request Pelatihan telah dikirim' : 'Request Pelatihan'}</button>
                      </div>
                       :
                      <>
                          <a onClick={addToCart} className='d-flex align-items-center mr-4'>
                              <button disabled={isAdding} className="btn btn-pill btn-outline-primary btn-sm d-flex align-items-center" type="button">
                                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10.5 21C11.3284 21 12 20.3284 12 19.5C12 18.6716 11.3284 18 10.5 18C9.67157 18 9 18.6716 9 19.5C9 20.3284 9.67157 21 10.5 21Z" fill="#2DABFF" />
                                      <path d="M17.5 21C18.3284 21 19 20.3284 19 19.5C19 18.6716 18.3284 18 17.5 18C16.6716 18 16 18.6716 16 19.5C16 20.3284 16.6716 21 17.5 21Z" fill="#2DABFF" />
                                      <path d="M21 7H7.334L6.18 4.23001C6.02851 3.86515 5.77211 3.55348 5.4433 3.33451C5.11448 3.11553 4.72806 2.99911 4.333 3.00001H2V5H4.334L9.077 16.385C9.232 16.757 9.597 17 10 17H18C18.417 17 18.79 16.741 18.937 16.352L21.937 8.35201C21.9932 8.2006 22.012 8.03786 21.9918 7.87763C21.9716 7.7174 21.913 7.56441 21.8211 7.43167C21.7291 7.29893 21.6064 7.19037 21.4634 7.11522C21.3205 7.04006 21.1615 7.00054 21 7ZM17 13H15V15H13V13H11V11H13V9H15V11H17V13Z" fill="#2DABFF" />
                                  </svg>
                                  &nbsp;{isAdding ? 'Menambahkan...' : 'Tambah ke Cart'}
                              </button>
                          </a>
                          <div className='d-flex align-items-center mr-4'>
                              <button className="btn btn-pill btn-primary btn-sm" type="button" onClick={buy}>Beli Pelatihan</button>
                          </div>
                      </>
              }
            </div>
        }

      <Modal isOpen={modalRequest} toggle={()=>setmodalRequest(!modalRequest)} size='lg'>
            <ModalBody>
              <div className='text-center mt-3'>
                <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M35 2.91016C17.336 2.91016 3 17.5565 3 35.6028C3 53.6491 17.336 68.2954 35 68.2954C52.664 68.2954 67 53.6491 67 35.6028C67 17.5565 52.664 2.91016 35 2.91016ZM26.328 49.6279L14.84 37.8913C14.5437 37.5886 14.3087 37.2293 14.1484 36.8338C13.9881 36.4383 13.9055 36.0145 13.9055 35.5864C13.9055 35.1584 13.9881 34.7345 14.1484 34.3391C14.3087 33.9436 14.5437 33.5843 14.84 33.2816C15.1363 32.9789 15.488 32.7388 15.8751 32.575C16.2621 32.4112 16.677 32.3269 17.096 32.3269C17.515 32.3269 17.9299 32.4112 18.3169 32.575C18.704 32.7388 19.0557 32.9789 19.352 33.2816L28.6 42.6971L50.616 20.2046C51.2143 19.5933 52.0258 19.2499 52.872 19.2499C53.7182 19.2499 54.5297 19.5933 55.128 20.2046C55.7263 20.8158 56.0625 21.6449 56.0625 22.5094C56.0625 23.3739 55.7263 24.2029 55.128 24.8142L30.84 49.6279C30.544 49.931 30.1923 50.1715 29.8052 50.3355C29.4181 50.4996 29.0031 50.584 28.584 50.584C28.1649 50.584 27.7499 50.4996 27.3628 50.3355C26.9757 50.1715 26.624 49.931 26.328 49.6279Z" fill="#31A05F"/>
                </svg>
                <h5 className='mt-3'>Berhasil melakukan request pelatihan!</h5>
                <p>Terima kasih, Anda akan dihubungi dalam waktu 2x24 pada nomor telepon yang tertaut di Akun Anda</p>
                <button
                  className="btn btn-pill btn-primary btn-sm my-3"
                  type="button"
                  onClick={()=>{
                    setmodalRequest(!modalRequest)
                    setdisableRequest(true)
                  }}
                > OK
                </button>
              </div>
            </ModalBody>
          </Modal>
    </>
  )
}

export default PelatihanDetailLainnya
