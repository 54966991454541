import Post from "../Post";
import Get from "../Get";

const company_overview = (id) => Get(`ketua-klien/company`);
const company_update = () => Get(`ketua-klien/company/get_update`);
const edit_company = (data) => Post(`ketua-klien/company`, data);

const CompanyKetua = {
    company_overview,
    company_update,
    edit_company
};

export default CompanyKetua;