import React, { useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import { BreadcrumbComp, TableComp } from '../../../component';
import { Home } from 'react-feather';
import { Collapse } from 'reactstrap';
import PelatihanPPTView from './pptView';
import PelatihanVideoView from './videoView';
import PelatihanPDFView from './pdfView';
import PelatihanKuisView from './kuisView';
import PelatihanTugasFinalView from './tugasFinalView';
import API from "../../../api";

const GeneralResourceDetail = () => {
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const [position, setposition] = useState(4);
  const [checkedValue, setcheckedValue] = useState(6);

  const [Pengenalan, setPengenalan] = useState(false);
  const [Rahasia, setRahasia] = useState(false);
  const [Teknik, setTeknik] = useState(false);

  const [accordions, setAccordion] = useState([]);
  const [course, setCourse] = useState(null);
  const [theory, setTheory] = useState(null);
  const [totTheory, setTotTheory] = useState([]);

  useEffect(() => {
    API.peserta.intro_course.start_course(id)
      .then(json => {
        console.log(json.data);
        setCourse(json.data)

        let accordion = [];
        let tottheory = [];
        let i = 1;

        json.data.topic.map(val => {
          tottheory.push(i-1);
          i += val.theory.length;
          accordion.push(false);
        });

        setTotTheory(tottheory);
        setAccordion(accordion);
        setTheory(json.data.theory)
      }).catch(err => {
        console.log(err);
      })
  }, [])

  const handleChangeView = (value, data) => {
    setposition(value);
    let teori = {...theory};
    if(teori.file)
      teori.file = teori.file.replace(/ /g,"%20");

    setTheory(data);
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'General Course'}>
          <li className="breadcrumb-item">
            <Link to="/" className='text-warning'>
              <Home />
            </Link>
          </li>
          <li className="breadcrumb-item">General Course</li>
        </BreadcrumbComp>
      </div>
      {
        course &&
        <div className='row'>
          <div className='col-md-7'>
            <div className='card p-5'>
              <div className='w-100 border rounded mb-5'>
              {
                theory && (
                  theory.type === 'Video' ?
                    <PelatihanVideoView
                      url={theory.file}
                      user={{
                        name: theory.course_id.trainer_id.name,
                        img: theory.course_id.trainer_id.profile_picture,
                        role: 'Trainer',
                        perusahaan: theory.course_id.company_id.name
                      }}
                    /> :
                    theory.type === 'Slide Powerpoint' ?
                      <PelatihanPPTView
                        file={theory.file}
                        user={{
                          name: theory.course_id.trainer_id.name,
                          img: theory.course_id.trainer_id.profile_picture.replace(/ /g, "%20"),
                          role: 'Trainer',
                          perusahaan: theory.course_id.company_id.name
                        }}
                      /> :
                      theory.type === 'E-Book' ?
                        <PelatihanPDFView link={theory.file.replace(/ /g, "%20")} name={theory.name} /> :
                        theory.type === 'Quiz' ?
                          <PelatihanKuisView id={theory.stat_theory_id} attempt={theory.attempt} name={theory.name} res={theory.result} isSuccess={theory.is_accessed} /> :
                          theory.type === 'Tugas Final' ?
                            <PelatihanTugasFinalView id={theory.stat_theory_id} /> : ''
                )
              }
              </div>
              {/* {position < 5 &&
                <div>
                  <h6 style={{ fontWeight: '600', fontSize: '18px' }}>Deskripsi</h6>
                  <p className='text-justify'>Seperti yang sudah dipelajari dalam materi turunan, variabel dalam suatu fungsi mengalami penurunan pangkat. Berdasarkan contoh tersebut, diketahui bahwa ada banyak fungsi yang memiliki hasil turunan yang sama yaitu yI = 3x2. Fungsi dari variabel x3 ataupun fungsi dari variabel x3 yang ditambah atau dikurang suatu bilangan (misal contoh: +8, +17, atau -6) memiliki turunan yang sama. </p>
                  <p className='text-justify'>Seperti yang sudah dipelajari dalam materi turunan, variabel dalam suatu fungsi mengalami penurunan pangkat. Berdasarkan contoh tersebut, diketahui bahwa ada banyak fungsi yang memiliki hasil turunan yang sama yaitu yI = 3x2. Fungsi dari variabel x3 ataupun fungsi dari variabel x3 yang ditambah atau dikurang suatu bilangan (misal contoh: +8, +17, atau -6) memiliki turunan yang sama. </p>
                </div>
              } */}
            </div>
          </div>
          <div className='col-md-5'>
            <div className='card p-5'>
              <h6 className='text-muted mb-3' style={{ fontWeight: '600', fontSize: '22px' }}>Topik yang dibahas</h6>
              {
                course.topic.map((val, key) => {
                  return <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-header">
                            <h5 className="mb-0">
                              <button className="btn btn-link pl-0 font-weight-bold" data-toggle="collapse"
                                onClick={() => {
                                  let accord = [...accordions];
                                  accord[key] = !accord[key];
                                  setAccordion(accord);
                                }}
                                data-target="#collapseicon" aria-expanded={accordions[key]} aria-controls="collapseicon">{val.name}</button>
                            </h5>
                          </div>
                          <Collapse isOpen={accordions[key]}>
                            <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                              <div className="card-body filter-cards-view animate-chk p-3">
                                {
                                  val.theory.map((t, k) => {
                                    return <div className="checkbox p-0">
                                      <input id="checkbox1" type="checkbox" checked={t.is_accessed} />
                                      <label htmlFor="checkbox1" onClick={() => {
                                        API.peserta.intro_course.theory_accessed({ theory_id: t.id });
                                        if (!t.is_accessed && !['Quiz', 'Tugas Final'].includes(t.type)) {
                                          let newCourse = { ...course };
                                          newCourse.topic[key].theory[k].is_accessed = true;
                                          setCourse(newCourse);
                                        }

                                        handleChangeView(totTheory[key] + k, t)
                                      }}
                                        style={{ width: "85%" }}
                                      >
                                        <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}>{t.name}</p>
                                        {
                                          t.type === 'Slide Powerpoint' ?
                                            <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                              className='fa fa fa-file-powerpoint-o' /></span>&nbsp;PPT</p> :
                                            t.type === 'Video' ?
                                              <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                                className='fa fa-play-circle-o' /></span>&nbsp;Video</p> :
                                              t.type === 'E-Book' ?
                                                <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                                  className='fa fa fa-file-pdf-o' /></span>&nbsp;PDF</p> :
                                                t.type === 'Quiz' ?
                                                  <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                                    className='fa fa-pencil-square-o' /></span>&nbsp;Kuis</p> :
                                                  <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i className='fa fa-clipboard' /></span>&nbsp;Ujian Final</p>
                                        }
                                      </label>
                                    </div>
                                  })
                                }
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                })
              }
              {/* <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button className="btn btn-link pl-0 font-weight-bold" data-toggle="collapse" onClick={() => setPengenalan(!Pengenalan)}
                            data-target="#collapseicon" aria-expanded={Pengenalan} aria-controls="collapseicon">Pengenalan Isi Pelatihan</button>
                        </h5>
                      </div>
                      <Collapse isOpen={Pengenalan}>
                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                          <div className="card-body filter-cards-view animate-chk p-3">
                            <div className="checkbox p-0">
                              <input id="checkbox1" type="checkbox" checked={checkedValue >= 1} />
                              <label htmlFor="checkbox1" style={{ width: "85%" }} onClick={() => handleChangeView(1)}>
                                <p className={position === 1 ? `text-theme mb-0` : `text-dark mb-0`}>Gambaran Umum Pelatihan</p>
                                <p className={position === 1 ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i className='fa fa-play-circle-o'></i></span>&nbsp;Video</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>

              <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button className="btn btn-link pl-0 font-weight-bold" data-toggle="collapse" onClick={() => setRahasia(!Rahasia)}
                            data-target="#collapseicon" aria-expanded={Rahasia} aria-controls="collapseicon">Rahasia Teknik Berbicara di Depan Umum</button>
                        </h5>
                      </div>
                      <Collapse isOpen={Rahasia}>
                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                          <div className="card-body filter-cards-view animate-chk p-3">
                            <div className="checkbox p-0">
                              <input id="checkbox1" type="checkbox" checked={checkedValue >= 2} />
                              <label htmlFor="checkbox1" style={{ width: "85%" }} onClick={() => handleChangeView(2)}>
                                <p className={position === 2 ? `text-theme mb-0` : `text-dark mb-0`}>Kesalahan Umum ketika Berbicara di Depan Umum</p>
                                <p className={position === 2 ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i className='fa fa-play-circle-o'></i></span>&nbsp;Video</p>
                              </label>
                            </div>

                            <div className="checkbox p-0">
                              <input id="checkbox1" type="checkbox" checked={checkedValue >= 3} />
                              <label htmlFor="checkbox1" style={{ width: "85%" }} onClick={() => handleChangeView(3)}>
                                <p className={position === 3 ? `text-theme mb-0` : `text-dark mb-0`}>Tipe-Tipe Peserta</p>
                                <p className={position === 3 ? `text-theme mb-0` : `text-dark mb-0`}> <span className='text-theme'><i className='fa fa fa-file-powerpoint-o'></i></span>&nbsp;PPT</p>
                              </label>
                            </div>

                            <div className="checkbox p-0">
                              <input id="checkbox1" type="checkbox" checked={checkedValue >= 4} />
                              <label htmlFor="checkbox1" style={{ width: "85%" }} onClick={() => handleChangeView(4)}>
                                <p className={position === 4 ? `text-theme mb-0` : `text-dark mb-0`}>Kuasai Panggung dan Kendalikan Rasa Takut</p>
                                <p className={position === 4 ? `text-theme mb-0` : `text-dark mb-0`}> <span className='text-theme'><i className='fa fa fa-file-pdf-o'></i></span>&nbsp;PDF</p>
                              </label>
                            </div>

                            <div className="checkbox p-0">
                              <input id="checkbox1" type="checkbox" checked={checkedValue >= 5} />
                              <label htmlFor="checkbox1" style={{ width: "85%" }} onClick={() => handleChangeView(5)}>
                                <p className={position === 5 ? `text-theme mb-0` : `text-dark mb-0`}>Quiz Teknik Bicara</p>
                                <p className={position === 5 ? `text-theme mb-0` : `text-dark mb-0`}> <span className='text-theme'><i className='fa fa-pencil-square-o'></i></span>&nbsp;Kuis</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>

              <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button className="btn btn-link pl-0 font-weight-bold" data-toggle="collapse" onClick={() => setTeknik(!Teknik)}
                            data-target="#collapseicon" aria-expanded={Rahasia} aria-controls="collapseicon">Teknik Penutupan yang berkesan</button>
                        </h5>
                      </div>
                      <Collapse isOpen={Teknik}>
                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                          <div className="card-body filter-cards-view animate-chk p-3">
                            <div className="checkbox p-0">
                              <input id="checkbox1" type="checkbox" checked={checkedValue >= 5} />
                              <label htmlFor="checkbox1" style={{ width: "85%" }} onClick={() => handleChangeView(6)}>
                                <p className={position === 6 ? `text-theme mb-0` : `text-dark mb-0`}>Kesalahan Umum ketika Berbicara di Depan Umum</p>
                                <p className={position === 6 ? `text-theme mb-0` : `text-dark mb-0`}> <span className='text-theme'><i className='fa fa-clipboard'></i></span>&nbsp;Ujian Final</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      }
    </>
  )
}

export default GeneralResourceDetail;
