import React from 'react';
import coursePDF from '../../../../assets/images/course_pdf.png';

const PelatihanPDFView = ({link, name}) => {
  return (
      <div className='card p-5'>
        <div className='w-100 rounded mb-5'>
          <div className='text-center p-4'>
            <img className="img-fluid mb-4" src={coursePDF} alt="Omind Tech" style={{ width: '60px' }} />
            <h6 style={{ fontWeight: '600', fontSize: '16px' }}>{name}</h6>
            <button className="btn btn-primary mt-3" type="button" onClick={() => {window.location.href = link}}>
              <label className='d-flex align-items-center mb-0'>
                <i className="fa fa-cloud-download"></i>
                <p className='mb-0 font-weight-bold ml-2'>Download</p>
              </label>
            </button>
            &emsp;
            <button className="btn btn-primary mt-3" type="button" onClick={() => {window.location.href = link}}>
              <label className='d-flex align-items-center mb-0'>
                <i className="fa fa-cloud-download"></i>
                <p className='mb-0 font-weight-bold ml-2'>View</p>
              </label>
            </button>
          </div>
        </div>
      </div>
  )
}

export default PelatihanPDFView;
