import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { TransaksiIcon } from "../../../component/common/constant/menu";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import remove from "../../../assets/images/remove.png";
import cupon from "../../../assets/images/cupon.png";
import TambahKodePromo from "./TambahKodePromo";
import API from "../../../api";

const KodePromo = () => {
  const state = useLocation().state;
  let isTambah = false;
  let isUbah = false;
  let promoData = {};
  let company_id = null;

  if(state){
    isTambah = state.isTambah;
    promoData = state.promoData;
    isUbah = state.isUbah;
    company_id = state.company_id;
  }

  const [modalDelete, setmodalDelete] = useState(false);
  const [tambahPromo, settambahPromo] = useState(isTambah);
  const [lihatPromo, setlihatPromo] = useState();
  const [lihatPromoData, setlihatPromoData] = useState(promoData);
  const [search, setSearch] = useState('');
  const [isUpdate, setIsUpdate] = useState(isUbah);

  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get("page") || 1;
  const [promos, setPromos] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });

  useEffect(() => {
    API.superadmin.promo.list_promo({ page, per_page, search }).then((json) => {
      let promo = { ...promos };
      promo.data = [];

      json.data.data.map((val, index) => {
        promo.data.push({
          id: val.id,
          no: index + 1,
          kode: val.code,
          perusahaan: val.company,
          periode: val.period,
          syarat: val.condition,
          status: val.status,
        });
      });

      promo.meta.from = (page - 1) * per_page + 1;
      promo.meta.to =
        per_page > json.data.total
          ? json.data.total
          : page * per_page > json.data.total
          ? json.data.total
          : page * per_page;
      promo.meta.total = json.data.total;
      promo.meta.current_page = page;
      promo.meta.total_page = Math.ceil(promo.meta.total / promo.meta.per_page);

      setPromos(promo);
    });
  }, [page, search, tambahPromo]);

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  const handleModalDelete = () => {
    setmodalDelete(!modalDelete);
  };

  const handleEditPromo = (row) => {
    API.superadmin.promo.get_update(row.id).then((json) => {
      setlihatPromoData(json.data);
      setIsUpdate(true);
      settambahPromo(true);
    });
  };

  const handleLihatPromo = (row) => {
    API.superadmin.promo.detail_promo(row.id).then((json) => {
      setlihatPromoData(json.data);
    });
    setlihatPromo(!lihatPromo);
  };

  const columnsTabel = [
    {
      dataField: "id",
      text: "ID",
      headerStyle: () => ({ width: "2%", textAlign: "left" }),
    },
    {
      dataField: "kode",
      text: "Kode Promo",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      formatter: (cell) => <p className="font-weight-bold">{cell}</p>,
    },
    {
      dataField: "perusahaan",
      text: "Perusahaan",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
    },
    {
      dataField: "periode",
      text: "Periode",
      headerStyle: () => ({ width: "15%", textAlign: "left" }),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      formatter: (cel, row) => {
        return (
          <div
            className={` my-auto w-75 p-2 text-center card btn-status btn-status_${
              cel === "Aktif"
                ? "aktif"
                : cel === "Expired"
                ? "expired"
                : cel === "Terpakai"
                ? "terpakai"
                : "draft"
            }`}
          >
            {cel}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerStyle: () => ({ width: "5%", textAlign: "center" }),
      formatter: (cel, row, idx) => {
        return (
          <div className="d-flex">
            <button
              className=" btn rounded-circle btn-success btn-xs py-1 mr-3"
              type="button"
              onClick={() => handleLihatPromo(row)}
            >
              {" "}
              <i className="fa fa-eye"></i>{" "}
            </button>
            {row.status === "Draft" && (
              <button
                className=" btn rounded-circle btn-warning btn-xs py-1 mr-3"
                type="button"
                onClick={() => handleEditPromo(row)}
              >
                {" "}
                <i className="fa fa-pencil"></i>{" "}
              </button>
            )}
            <button
              className=" btn rounded-circle btn-danger btn-xs"
              type="button"
              onClick={() => {
                setlihatPromoData([row.id, idx]);
                handleModalDelete();
              }}
            >
              {" "}
              <i className="fa fa-trash"></i>{" "}
            </button>
          </div>
        );
      },
    },
  ];

  if (tambahPromo) {
    return <TambahKodePromo settambahPromo={settambahPromo} isUpdate={isUpdate} promo={lihatPromoData} setPromo={setlihatPromoData} company_id={company_id} />;
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"Kode Promo Perusahaan"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/data-company" className="text-warning">
              <TransaksiIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">Kode Promo Perusahaan</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h5
                className="text-work text-theme"
                style={{ fontSize: "24px", fontWeight: "600" }}
              >
                {" "}
                List Kode Promo Perusahaan{" "}
              </h5>
              <div className="d-flex align-items-center">
                <div style={{ position: "relative" }}>
                  <form className="search-form contact-search">
                    <div className="form-group mb-0">
                      <input
                        className="form-control badge-pill"
                        type="text"
                        placeholder="Cari promo..."
                        style={{ width: "273px" }}
                        onChange={handleSearch}
                      />
                    </div>
                  </form>
                </div>
                <button
                  className="btn btn-pill btn-outline-primary btn-sm text-work ml-3"
                  type="button"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                    setlihatPromoData(null);
                    settambahPromo(true)
                  }}
                >
                  <i className="fa fa-pencil" />
                  &nbsp;Tambah Kode Promo
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="overflow-auto w-100">
                <TableComp
                  title_paggination="pelatihan"
                  columns={columnsTabel}
                  data={promos}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={lihatPromo} toggle={handleLihatPromo} size="lg">
        <ModalBody>
          <div className="text-center w-75 mx-auto py-5">
            <img
              className="img-fluid mb-4"
              src={cupon}
              style={{ width: "73px" }}
              alt="omind"
            />
            <div className="row mt-3">
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-muted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Nama Promo
                </label>
                <p
                  className="text-work"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  {lihatPromoData.code}
                </p>
              </div>
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-mutted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Status
                </label>
                <div
                  className={` my-auto w-75 p-2 text-center card btn-status btn-status_${
                    lihatPromoData.status === "Aktif"
                      ? "aktif"
                      : lihatPromoData.status === "Expired"
                      ? "expired"
                      : lihatPromoData.status === "Terpakai"
                      ? "terpakai"
                      : "draft"
                  }`}
                >
                  {lihatPromoData.status}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-muted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Durasi
                </label>
                <p
                  className="text-work"
                  style={{ fontSize: "18px", fontWeight: "normal" }}
                >
                  {lihatPromoData.period}
                </p>
              </div>
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-muted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Diskon
                </label>
                <p
                  className="text-work"
                  style={{ fontSize: "18px", fontWeight: "normal" }}
                >
                  {lihatPromoData.discount}
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-muted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Perusahaan
                </label>
                <p
                  className="text-work"
                  style={{ fontSize: "18px", fontWeight: "normal" }}
                >
                  {lihatPromoData.company}
                </p>
              </div>
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-muted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Syarat
                </label>
                <p
                  className="text-work"
                  style={{ fontSize: "18px", fontWeight: "normal" }}
                >
                  {lihatPromoData.condition}
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalDelete} toggle={handleModalDelete}>
        <ModalBody>
          <div className="text-center w-50 mx-auto py-5">
            <img
              className="img-fluid mb-4"
              src={remove}
              style={{ width: "73px" }}
              alt="omind"
            />
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              Apakah anda yakin menghapus promo ini?
            </h3>
            <div className="d-flex justify-content-between mx-3 mt-4">
              <button
                className="btn btn-pill btn-primary btn-sm text-work"
                type="button"
                style={{ fontWeight: "600", width: "88px" }}
                onClick={() => {
                  let temp_promo = {...promos};
                  temp_promo.data.splice(lihatPromoData[1], 1);
                  setPromos(temp_promo);
                  handleModalDelete();

                  API.superadmin.promo
                    .delete_promo(lihatPromoData[0])
                    .then((json) => {
                      console.log(json);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
              >
                {" "}
                Ya{" "}
              </button>
              <button
                className="btn btn-pill btn-outline-primary btn-sm text-work"
                type="button"
                style={{ fontWeight: "600", width: "88px" }}
                onClick={handleModalDelete}
              >
                {" "}
                Tidak{" "}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default KodePromo;
