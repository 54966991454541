import Post from "../Post";
import Get from "../Get";

const get = (data) => Get(`admin-c/courses?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const get_total = () => Get(`admin-c/dashboard/total`);
const topCourse = (data) => Get(`admin-c/dashboard/top-5-courses?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const getPromo = (data) => Get(`admin-c/dashboard/promos?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const getVoucher = (data) => Get(`admin-c/dashboard/vouchers?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const getTransaction = (data) => Get(`admin-c/dashboard/transactions?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);

const DashboardAdminC = {
    get,
    get_total,
    topCourse,
    getPromo,
    getVoucher,
    getTransaction,
};

export default DashboardAdminC;
