import React, {useState, useEffect} from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useHistory } from "react-router-dom";
import { CompanyIcon } from '../../../component/common/constant/menu';
import { ToastContainer, toast } from 'react-toastify';
import woman from '../../../assets/images/woman1.png'
import API from "../../../api";
import swal from "sweetalert2";

const TambahBulkPeserta = () => {
  const history = useHistory();
  const [cekBerhasil, setcekBerhasil] = useState(false);
  const [bulk, setBulk] = useState('');
  const [participant, setParticipant] = useState([]);
  const [fileBulk, setfileBulk] = useState(false);
  const [fileName, setfileName] = useState(null);

  useEffect(() => {
    API.adminc.participant.get_bulk()
      .then(json => {
        setfileBulk(json.data)
      })
  }, [])

  const handleuploadBulk = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    if(file){
      setfileName(file.name)
      setBulk(file)

      reader.readAsDataURL(file);
    }else{
      setfileName(null)
      setBulk(null)
    }
  };

  const handleSubmited = (event) => {
    event.preventDefault();

    API.adminc.participant.upload_bulk({file: bulk})
      .then(json => {
        console.log('json.duplicate_data', json.duplicate_data);
        setParticipant(json.duplicate_data);
        setcekBerhasil(true);
      }).catch(err => {
        console.log(err);
        toast.error("Import Bulk Peserta Tidak Berhasil Dilakukan", { position: toast.POSITION.TOP_CENTER });
      })
  }

  const handleConfirmReplace = () => {
    swal.fire({
      title: 'Anda yakin?',
      text: "Data peserta akan ditimpa",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, timpa!',
      cancelButtonText: 'Batal',
    }).then((result) => {
      if (result.isConfirmed) {
        swal.fire({
          onBeforeOpen () {
            swal.showLoading()
          },
          onAfterClose () {
            swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });

        API.adminc.participant.upload_bulk_replace({
          users: JSON.stringify(participant)
        }).then(json => {
            swal.fire('Sukses', 'Berhasil menimpa data peserta', 'success')
                .then(res => {
                  history.push('/admin/peserta-terregistrasi');
                })
        }).catch(e => {
            swal.fire('Gagal', 'Gagal menimpa data peserta', 'error')
            console.log(e.errors.users);
        });
      }
    })
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Tambah Bulk Peserta'}>
          <li className="breadcrumb-item">
            <Link to="/admin/edit-company" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Peserta</li>
          <li className="breadcrumb-item">Tambah Peserta</li>
        </BreadcrumbComp>
      </div>

      <div className="row">
        <div className="col-sm-12">
          { cekBerhasil === false ? (
          <div className="card">
          <div className="card-header d-flex pb-3">
            <div className='mr-auto'>
              <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>TAMBAH BULK PESERTA</h4>
              <span className='text-muted'>Data Peserta</span>
            </div>
          </div>
          <div className="mx-4 mb-2">
              <p>Anda dapat menambahkan peserta secara banyak (bulk) dengan melakukan upload dokumen berekstensi .xls yang telah sesuai dengan format yang disediakan. Untuk mengunduh template klik Download</p>
          </div>
          {
            fileBulk && (
              <>
                <div className="mx-4">
                  <button className="btn btn-success" onClick={() => {window.open(fileBulk,'_blank')}}><i class="fa fa-cloud-upload" aria-hidden="true"></i>&nbsp;Download Template Bulk Registrasi</button>
                </div>
                <div className="mx-4 mt-2 mb-5">
                  {/* <button className="btn btn-primary"><i class="fa fa-cloud-upload" aria-hidden="true"></i>&nbsp;Upload List Peserta (.csv) </button> */}
                  <div className="btn btn-primary mr-2" style={{ position: 'relative', overflow: 'hidden' }}>
                    <span>
                      <i class="fa fa-cloud-upload" aria-hidden="true"></i>&nbsp;Upload List Peserta (.xlsx)
                    </span>
                    <input type="file" onChange={handleuploadBulk} style={{
                      position: 'absolute',
                      top: '0',
                      right: '0',
                      filter: 'alpha(opacity=0)',
                      opacity: '0',
                      outline: 'none',
                      cursor: 'inherit',
                      display: 'block',
                    }}/>
                  </div>
                  {
                    fileName !== null ? fileName : null
                  }
                </div>
              </>
            )
          }
          <div className="col-md-12 m-5">
              <div className="text-right mb-5">
                <button type="cancel" className="btn btn-outline-primary btn-pill mr-2" onClick={() => history.goBack()}>Batal</button>
                <button type="submit" className="btn btn-primary btn-pill" onClick={handleSubmited}>Simpan</button>
                <ToastContainer />
              </div>
          </div>
          </div>
           ) : (
            <div>
              <div className="card">
              <div className="card-body my-5">
                <div className="col-md-12 text-center">
                  <img src={woman} alt="" className="my-3"/>
                  <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>Tambah Bulk Peserta Berhasil</h4>
                  <div className="col-md-12 mb-5">
                    <p className="w-75 mx-auto">Berikut adalah data peserta yang sudah ada pada SDM Training. Anda dapat menimpa data peserta jika anda mau.</p>
                  </div>
                  <Link to={`/admin/peserta-terregistrasi`}>
                    <p><u>Kembali Ke List Peserta</u></p>
                  </Link>
                </div>
              </div>
            </div>
              <div className="card col-md-6">
                <div className="card-body">
                <h4 className='text-theme text-work mb-5' style={{ fontWeight: '600' }}>PESERTA YANG SUDAH ADA</h4>
                  <p style={{fontSize :'15px'}}>Nama Peserta</p>
                  <ul>
                    {
                      participant.map(v => {
                         return <li className="d-flex ">
                          <p style={{fontWeight :'600'}}>{v[0]} - {v[1]}</p>
                        </li>
                      })
                    }
                  </ul>
                  <button className="btn btn-danger mt-3" onClick={handleConfirmReplace}>Timpa data peserta</button>
                  <button className="btn btn-primary mt-3 ml-3" onClick={() => history.push('/admin')}>Kembali ke dashboard</button>
                </div>
              </div>
          </div>

           )}

        </div>
      </div>


    </>
  )
}

export default TambahBulkPeserta;
