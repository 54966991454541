import React from 'react';
import ReactPlayer from 'react-player'


// documentasi library https://cookpete.com/react-player/ 

const PelatihanVideoView = (props) => {
  return (
    <div className='card p-5'>
      <div className='w-100 border rounded mb-5'>
        <ReactPlayer url={props.url} width='100%' controls />
        <div className='d-flex mb-2 align-items-center ml-4 my-3'>
          <div>
            <img class="img-40 rounded-circle" src="https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg" alt="#"></img>
          </div>
          <label className='mb-0 ml-2'>
            <h6 className='mb-0' style={{ fontWeight: '600', fontSize: '13px' }}>{props.user.name}</h6>
            <p className='mb-0' style={{ fontSize: '10px' }}>{props.user.role} dari {props.user.perusahaan}</p>
          </label>
        </div>
      </div>
      <div>
          <h6 style={{ fontWeight: '600', fontSize: '18px' }}>Deskripsi</h6>
          <p className='text-justify'>Seperti yang sudah dipelajari dalam materi turunan, variabel dalam suatu fungsi mengalami penurunan pangkat. Berdasarkan contoh tersebut, diketahui bahwa ada banyak fungsi yang memiliki hasil turunan yang sama yaitu yI = 3x2. Fungsi dari variabel x3 ataupun fungsi dari variabel x3 yang ditambah atau dikurang suatu bilangan (misal contoh: +8, +17, atau -6) memiliki turunan yang sama. </p>
          <p className='text-justify'>Seperti yang sudah dipelajari dalam materi turunan, variabel dalam suatu fungsi mengalami penurunan pangkat. Berdasarkan contoh tersebut, diketahui bahwa ada banyak fungsi yang memiliki hasil turunan yang sama yaitu yI = 3x2. Fungsi dari variabel x3 ataupun fungsi dari variabel x3 yang ditambah atau dikurang suatu bilangan (misal contoh: +8, +17, atau -6) memiliki turunan yang sama. </p>
        </div>
    </div>
  )
}

export default PelatihanVideoView;