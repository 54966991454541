import React, { useState, useEffect } from 'react';
import { useLocation, Link, useHistory } from "react-router-dom";
import { BreadcrumbComp, TableComp } from '../../../component';
import { Layers } from 'react-feather';
import learning_dummy from '../../../assets/images/learning-dummy-image.png';
import {Progress, Collapse, ModalHeader, ModalBody, ModalFooter, Button, Modal} from 'reactstrap';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import API from "../../../api";
import {titleCase} from "../../../helper";

const PelatihanDetail = () => {
  const history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const progress = parseInt(query.get("progress"));

  const [course, setCourse] = useState(null);

  const [accordions, setAccordion] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    API.peserta.course.course_detail(id)
        .then(json => {
          setCourse(json.data);

          let accordion = [];

          json.data.course_contents.map(val => {
            accordion.push(false);
          });

          setAccordion(accordion);
        });
  }, []);

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Pelatihan Saya'}>
          <li className="breadcrumb-item">
            <Link to="/pelatihan-saya" className='text-warning'>
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Saya</li>
          <li className="breadcrumb-item active">Pelatihan Detail</li>
        </BreadcrumbComp>
      </div>
      {
        course &&
        <div className='row'>
          <div className='col-md-12'>
            <div className='card p-5'>
              <div className='row'>
                <div className='col-md-6'>
                  <img className="img-fluid w-100 mb-3" src={course.course_id.thumbnail} alt="omind"/>
                  <div className='d-flex'>
                    <p className='text-muted font-weight-bold mr-auto mb-1'>PROGRESS PELATIHAN</p>
                    <p className='text-muted font-weight-bold mb-1'>{course.progress}%</p>
                  </div>
                  <Progress color="primary" value={course.progress} className="sm-progress-bar"/>
                </div>
                <div className='col-md-6'>
                  <h3 className='font-weight-bold'>{course.course_id.name}</h3>
                  <div className='d-flex'>
                    <label>
                      <div
                          className={`badge text-wrap text-capitalize ${course.course_id.type === 'Online' ? 'badge-primary' : course.course_id.type === 'Face to Face' ? 'badge-danger' : 'badge-pink'}`}>
                        {course.course_id.type}
                      </div>
                    </label>
                    <p className='text-muted mr-4 ml-2'><span className='text-muted'><i
                        className='fa fa-question-circle'></i></span></p>
                    <p className='text-muted mr-4'><span className='text-theme'><i
                        className='fa fa-clock-o'></i></span>&nbsp; Sisa Durasi Pelatihan <span
                        className='text-dark font-weight-bold'>{course.days_left} hari ({course.due_date})</span></p>
                  </div>
                  <p className='text-justify'>{course.short_description}</p>
                  <p className='text-muted mb-0'>Kategori <span className='text-dark font-weight-bold'>{course.course_id.category_id.name}</span>
                  </p>
                  <p className='text-muted'>Dibuat oleh <span className='text-dark font-weight-bold'>{course.course_id.trainer_id.name}</span>
                  </p>
                  {
                    progress !== 0 ?
                        <button onClick={() => history.push(`/pelatihan?id=${course.id}`)} className={`btn btn-pill btn-outline-primary btn-sm w-25 font-weight-bold px-3`}
                                type="button">Ke Pelatihan</button>
                        :
                        <button onClick={() => history.push(`/pelatihan?id=${course.id}`)} className={`btn btn-pill btn-primary btn-sm w-25 font-weight-bold px-3`}
                                type="button">Mulai Pelatihan</button>
                  }

                    {
                        course.no_certificate ?
                        <button className="btn btn-pill btn-outline-primary btn-sm text-work ml-3" type="button" style={{ fontWeight: '600' }} onClick={toggle}>Lihat Sertifikat</button> :
                        <p className='text-center mt-3'>E-Sertifikat dapat diakses setelah anda menyelesaikan pelatihan 100%</p>
                    }


                </div>
              </div>
              <div className='row mt-4'>
                <div className='col-md-6'>
                  <div className='row theme-tab'>
                    <Tabs className="col-sm-12">
                      <TabList className="tabs tab-title">
                        <Tab className="current px-3">
                          <h6 className='font-weight-bold'>Deskripsi Pelatihan</h6>
                        </Tab>
                        <Tab className='px-3'>
                          <h6 className='font-weight-bold'>Konten Pelatihan</h6>
                        </Tab>
                        <Tab className='px-3'>
                          <h6 className='font-weight-bold'>Info Trainer</h6>
                        </Tab>
                      </TabList>
                      <div className="tab-content-cls">
                        <TabPanel>
                          <h5 className='font-weight-bold text-muted mt-4'>Pelatihan Terdiri dari</h5>
                          <div className='d-flex'>
                            <p className='text-muted mr-4'><span className='text-theme'><i
                                className='fa fa-play-circle-o'></i></span>&nbsp; {course.course_consists_of.filter(val => {return val.type === 'Video'})[0] ? course.course_consists_of.filter(val => {return val.type === 'Video'})[0].total : 0} Video</p>
                            <p className='text-muted mr-4'><span className='text-theme'><i
                                className='fa fa fa-file-pdf-o'></i></span>&nbsp; {course.course_consists_of.filter(val => {return val.type === 'E-Book'})[0] ? course.course_consists_of.filter(val => {return val.type === 'E-Book'})[0].total : 0} PDF</p>
                            <p className='text-muted mr-4'><span className='text-theme'><i
                                className='fa fa fa-file-powerpoint-o'></i></span> &nbsp; {course.course_consists_of.filter(val => {return val.type === 'Slide Powerpoint'})[0] ? course.course_consists_of.filter(val => {return val.type === 'Slide Powerpoint'})[0].total : 0} PPT</p>
                            <p className='text-muted mr-4'><span className='text-theme'><i
                                className='fa fa-pencil-square-o'></i></span>&nbsp; {course.course_consists_of.filter(val => {return val.type === 'Quiz'})[0] ? course.course_consists_of.filter(val => {return val.type === 'Quiz'})[0].total : 0} Kuis</p>
                            <p className='text-muted mr-4'><span className='text-theme'><i
                                className='fa fa-clipboard'></i></span>&nbsp; {course.course_consists_of.filter(val => {return val.type === 'Tugas Final'})[0] ? course.course_consists_of.filter(val => {return val.type === 'Tugas Final'})[0].total : 0} Ujian Final</p>
                          </div>
                          {course.course_id.type === 'Face to Face' &&
                          <div className=" card border mr-5">
                            <div className="card-body">
                              <p className='font-weight-bold text-theme mb-0'>Jadwal {course.course_schedule.name} </p>
                              <p className='font-weight-bold text-dark mb-0'>{course.course_schedule.location} </p>
                              <p className='text-dark mb-0'>{course.course_schedule.detail_location}</p>
                              <hr/>
                              {
                                course.course_schedule.meeting.map(val => {
                                  return <>
                                    <p className='font-weight-bold text-dark mb-0'>{val.name}</p>
                                    <span className='d-flex '>
                                      <p className='text-dark'>{val.date}</p>
                                      <p className='text-dark ml-auto'>{val.start_meeting.substring(0,5)} - {val.end_meeting.substring(0,5)} WIB</p>
                                    </span>
                                  </>
                                })
                              }
                            </div>
                          </div>
                          }
                          {course.course_id.type === 'Virtual Class' &&
                          <div className=" card border mr-5">
                            <div className="card-body">
                              <p className='font-weight-bold text-theme mb-0'>Jadwal {course.course_schedule.name} </p>
                              <p className='font-weight-bold text-dark mb-0'>{course.course_schedule.location} </p>
                              <p className='text-dark mb-0'>{course.course_schedule.detail_location}</p>
                              <hr/>
                              {
                                course.course_schedule.meeting.map(val => {
                                  return <>
                                    <p className='font-weight-bold text-dark mb-0'>{val.name}</p>
                                    <span className='d-flex '>
                                      <p className='text-dark'>{val.date}</p>
                                      <p className='text-dark ml-auto'>{val.start_meeting.substring(0,5)} - {val.end_meeting.substring(0,5)} WIB</p>
                                    </span>
                                  </>
                                })
                              }
                            </div>
                          </div>
                          }

                          <p className='text-justify'>{course.course_id.description}</p>
                        </TabPanel>
                        <TabPanel>
                          {
                            course.course_contents.map((val, key) => {
                              return <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                                <div className="row">
                                  <div className="col-xl-12">
                                    <div className="card">
                                      <div className="card-header">
                                        <h5 className="mb-0">
                                          <button className="btn btn-link pl-0 font-weight-bold" data-toggle="collapse"
                                                  onClick={() => {
                                                    let accord = [...accordions];
                                                    accord[key] = !accord[key];
                                                    setAccordion(accord);
                                                  }}
                                                  data-target="#collapseicon" aria-expanded={accordions[key]}
                                                  aria-controls="collapseicon">{val.name}
                                          </button>
                                        </h5>
                                      </div>
                                      <Collapse isOpen={accordions[key]}>
                                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon"
                                             data-parent="#accordion">
                                          {
                                            val.theory.map(t => {
                                              return <div className="card-body filter-cards-view animate-chk p-3">
                                                <p className='mb-0 text-dark'>{t.name}</p>
                                                {
                                                  t.type === 'Slide Powerpoint' ?
                                                      <p className='text-dark'><span className='text-theme'><i
                                                          className='fa fa fa-file-powerpoint-o'></i></span>&nbsp;PPT</p> :
                                                      t.type === 'Video' ?
                                                          <p className='text-dark'><span className='text-theme'><i
                                                              className='fa fa-play-circle-o'></i></span>&nbsp;Video</p> :
                                                          t.type === 'E-Book' ?
                                                              <p className='text-dark'><span className='text-theme'><i
                                                                  className='fa fa fa-file-pdf-o'></i></span>&nbsp;PDF</p> :
                                                              t.type === 'Quiz' ?
                                                                  <p className='text-dark'><span className='text-theme'><i
                                                                      className='fa fa-pencil-square-o'></i></span>&nbsp;Kuis</p> :
                                                                  <p className='text-dark'><span className='text-theme'><i className='fa fa-clipboard'></i></span>&nbsp;Ujian Final</p>
                                                }
                                              </div>
                                            })
                                          }
                                        </div>
                                      </Collapse>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            })
                          }

                        </TabPanel>
                        <TabPanel>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className='d-flex align-items-center mb-2'>
                                <div>
                                  <img className="img-40 rounded-circle"
                                       src={course.course_id.trainer_id.profile_picture} alt="#"
                                       style={{height: '40px', objectFit: 'cover'}}
                                  />
                                </div>
                                <label className='mb-0 ml-2'>
                                  <h6 className='font-weight-bold mb-0'>{course.course_id.trainer_id.name}</h6>
                                  <p className='font-weight-bold text-muted mb-0'>{titleCase(course.course_id.trainer_id.profession_id.name)}</p>
                                </label>
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='d-flex align-items-center mb-2 '>
                                <div>
                                  <img className="img-40 rounded-circle"
                                       src={course.course_id.company_id.logo} alt="#"
                                       style={{height: '40px', objectFit: 'cover'}}
                                  />
                                </div>
                                <label className='mb-0 ml-2'>
                                  <p className='text-muted mb-0'>Penyelenggara</p>
                                  <p className='font-weight-bold text-muted mb-0 mt-0'>{course.course_id.company_id.name}</p>
                                </label>
                              </div>
                            </div>
                            <div className='col-md-12 mt-4'>
                              <p className='text-justify'>{course.course_id.trainer_id.short_profile}</p>
                            </div>
                          </div>
                        </TabPanel>
                      </div>
                    </Tabs>

                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='card shadow m-4 p-4'>
                    <h6 className='text-muted text-work mb-4'>Komentar</h6>

                    {
                      course.course_comments.map((val, key) => {
                        return <>
                          <div className='d-flex align-items-center mb-2'>
                            <div>
                              <img className="img-40 rounded-circle"
                                   src={val.created_by_user.profile_picture}
                                   style={{height: 40, objectFit: 'cover'}}
                                   alt="#"/>
                            </div>
                            <label className='mb-0 ml-2'>
                              <h6 className='font-weight-bold mb-0'>{val.created_by_user.name}</h6>
                              <p className='font-weight-bold text-muted mb-0'>{titleCase(val.created_by_user.role_id.name)} - {val.created_by_user.company_id.name}</p>
                            </label>
                          </div>
                          <p className='text-justify mb-0'>{val.comment}</p>
                          {course.course_comments.length !== key+1 && <hr/>}
                        </>
                      })
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Sertifikat</ModalHeader>
                <ModalBody>
                    <img src={course.course_id.certificate_template} style={{display: 'block', margin: '0 auto', width: '100%'}} alt="Sertifikat"/>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
      }
    </>
  )
}

export default PelatihanDetail;
