import React, {useEffect, useState} from 'react';
import { Home } from 'react-feather';
import { Link, useHistory } from 'react-router-dom'
import CardItemPelathian from './CardItemPelathian';
import { BreadcrumbComp, OverviewPerusahaan } from '../../../component';
import SlickPelatihan from './SlickPelatihan';
import bg_promo from '../../../assets/images/bg_promo.png';
import laptop_promo from '../../../assets/images/laptop_promo.png';
import API from "../../../api";
import {numberWithDots} from "../../../helper";
import PelatihanAktif from "../pelatihanSaya/pelatihanAktif";
import moment from "moment";

const DashboardPeserta = () => {
  const history = useHistory();
  const NoPerusahaan = false;
  const [overview, setOverview] = useState(null);
  const [myCourse, setMyCourse] = useState([]);
  const [pelatihan, setPelatihan] = useState([]);
  const [newCourse, setNewCourse] = useState([]);
  const user = JSON.parse(localStorage.getItem('data'));

  const buy = async (data) => {
    await API.peserta.course.add_to_cart(data.id).catch(e => {history.push('/pelatihan-lainnya/cart');});
    history.push('/pelatihan-lainnya/cart');
  }

  useEffect(() => {
    API.peserta.company.company_overview(user.company_id)
        .then(json => {
          let data = json.data;
          setOverview(data);

          let courses = [];

          data.my_course.map(val => {
            let course = val.course_id;
            courses.push({
              id: val.id,
              item_judul: course.name,
              item_desc: course.description,
              item_bg: course.thumbnail,
              progress_item: parseFloat(val.progress),
              user_image: course.trainer_id.profile_picture,
              user_name: course.trainer_id.name,
              user_company: course.company_id.name,
              online: val.status === 'Berjalan' && moment().isBefore(val.due_date) ? 'online' : '',
              status: val.status,
              type: course.type
            });
          });

          courses = courses.filter(val => {
            return val.online === 'online';
          });

          setMyCourse(courses);

          courses = [];

          data.course.map(val => {
            courses.push({
              id: val.id,
              item_judul: val.name,
              item_desc: val.description,
              item_bg: val.thumbnail,
              item_harga: val.price,
              user_image: val.trainer_id.profile_picture,
              user_name: val.trainer_id.name,
              user_role: 'Trainer',
              status: val.type
            })
          });

          setPelatihan(courses);

          courses = [];

          data.course_new.map(val => {
            courses.push({
              id: val.id,
              item_judul: val.name,
              item_desc: val.description,
              item_bg: val.thumbnail,
              item_harga: val.price,
              user_image: val.trainer_id.profile_picture,
              user_name: val.trainer_id.name,
              user_role: val.trainer_id.company_id.name,
              status: val.type
            })
          });

          setNewCourse(courses);

        });
  }, []);

  return (
      overview &&
    <>
      <div className="">
        <div className="page-header">
          <BreadcrumbComp title={'Dashboard'} menuRight={
              <>
                  <Link to='/about-us'>
                      <button className="btn btn-outline-warning btn-sm bg-white mr-3" type="button">
                          <label className='d-flex align-items-center mb-0'>
                              <p className='mb-0 font-weight-bold ml-2'>
                                  About Us
                              </p>
                          </label>
                      </button>
                  </Link>
              </>
          }>
            <li className="breadcrumb-item">
              <Link to="/" className='text-warning'>
                <Home />
              </Link>
            </li>
            <li className="breadcrumb-item">Dashboard</li>
            <li className="breadcrumb-item active">{overview ? overview.name : '...'}</li>
          </BreadcrumbComp>
        </div>

        {NoPerusahaan &&
          <>
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header text-warning">
                    <h4 className='font-weight-bold'>PELATIHAN SAYA</h4><span>Pelatihan Aktif</span>
                  </div>
                  <div className="card-body">
                    {myCourse.map((data, key) => (
                      <CardItemPelathian
                        key={key}
                        {...data}
                      />
                    ))}
                    {
                      myCourse.length > 4 &&
                      <div className='text-center'>
                        <button className="btn btn-pill btn-outline-primary btn-sm" type="button">Muat lebih banyak</button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className='card shadow-0' style={{background: `url(${bg_promo})`, minHeight: '16rem', backgroundSize:'100%', boxShadow:'0 !important'}}>
              <div className="card-body d-flex p-0">
                <div className='my-auto ml-4'>
                  <h1 className='font-weight-bold text-nunito'>Belajar dari mana saja, kondisi apa saja!</h1>
                  <p>Mulai perkembangan Anda dengan bergabung dengan pelatihan yang sesuai dengan Anda</p>

                  <button className="btn btn-pill btn-primary btn-sm mt-3" type="button">Temukan Pelatihan Lainnya</button>
                </div>
                <img className='ml-auto mt-auto mr-4 w-25' src={laptop_promo} />
              </div>
            </div>
          </>
        }

        {!NoPerusahaan &&
          <div className="row">
            <div className="col-sm-12">
              <OverviewPerusahaan data={overview} />
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <span className='text-warning font-weight-bold'>PELATIHAN PERUSAHAAN</span>
                      <h3 className='mb-4 text-muted'>Pelatihan yang Anda ikuti</h3>
                      <div className='px-5 mt-3 row'>
                        {myCourse.map((data, key) => (
                            <div className='col-md-4' key={key}>
                              <PelatihanAktif key={key} {...data}/>
                            </div>
                        ))}
                      </div>
                      {
                        myCourse.length > 4 &&
                        <div className='text-center'>
                          <button className="btn btn-pill btn-outline-primary btn-sm" type="button">Muat lebih banyak</button>
                        </div>
                      }
                      <h3 className='mb-4 text-muted'> Pelatihan yang bisa Anda ikuti</h3>
                      <div className='px-5'>
                        <SlickPelatihan slidesToShow={pelatihan.length > 3 ? 3 : newCourse.length}>
                          {pelatihan.map((data, key) => (
                              <div className='p-3' key={key}>
                                <div className='card border'>
                                    <div className='rounded-left' style={{ backgroundImage: `url(${data.item_bg.replace(/ /g, "%20")})`, backgroundSize: 'cover', minHeight:'12rem' }}>
                                        <div className='h-100 d-flex flex-column p-3' >
                                            <div className="badge badge-primary text-wrap mt-3">
                                                {data.online && 'online'}
                                            </div>
                                        </div>
                                    </div>
                                  <div className='p-3'>
                                    <h5 className='font-weight-bold'>{data.item_judul}</h5>
                                    <div className='d-flex align-items-center mb-2 border-top border-bottom py-2'>
                                      {data.user_image &&
                                        <div>
                                          <img className="img-40 rounded-circle" style={{height: '40px', objectFit: 'cover'}} src={data.user_image} alt="#"/>
                                        </div>
                                      }
                                      <label className='mb-0 ml-2'>
                                        <h6 className='font-weight-bold mb-0'>{data.user_name}</h6>
                                        <p>{data.user_role}</p>
                                      </label>
                                    </div>
                                    <p className='text-right font-weight-bold text-success'>Rp {numberWithDots(data.item_harga)}</p>
                                    <div className='text-center'>
                                      <button className="btn btn-pill btn-primary btn-sm w-100" type="button" onClick={() => {
                                        if(['Virtual Class', 'Face to Face'].includes(data.status))
                                          history.push(`/pelatihan-detail-lainnya?id=${data.id}`);
                                        else
                                          buy(data)
                                      }}>Beli Pelatihan</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          ))}
                        </SlickPelatihan>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <span className='text-warning'>PELATIHAN TERBARU</span>
                      <h3 className='mb-4'>Pelatihan Lainnya</h3>
                      <div className='px-5'>
                        <SlickPelatihan slidesToShow={newCourse.length > 3 ? 3 : newCourse.length}>
                          {newCourse.map((data, key) => (
                            <div className='p-3' key={key}>
                              <div className='card border'>
                                  <div className='rounded-left' style={{ backgroundImage: `url(${data.item_bg.replace(/ /g, "%20")})`, backgroundSize: 'cover', minHeight:'12rem' }}>
                                      <div className='h-100 d-flex flex-column p-3' >
                                          <div className="badge badge-primary text-wrap mt-3">
                                              {data.online && 'online'}
                                          </div>
                                      </div>
                                  </div>
                                  <div className='p-3'>
                                    <h5 className='font-weight-bold'>{data.item_judul}</h5>
                                    <div className='d-flex align-items-center mb-2 border-top border-bottom py-2'>
                                        {data.user_image &&
                                          <div>
                                            <img className="img-40 rounded-circle" style={{height: '40px', objectFit: 'cover'}} src={data.user_image} alt="#"/>
                                          </div>
                                        }
                                        <label className='mb-0 ml-2'>
                                            <h6 className='font-weight-bold mb-0'>{data.user_name}</h6>
                                            <p>{data.user_role}</p>
                                        </label>
                                    </div>
                                    <p className='text-right font-weight-bold text-success'>Rp {numberWithDots(data.item_harga)}</p>
                                    <div className='text-center'>
                                      <button className="btn btn-pill btn-primary btn-sm w-100" type="button" onClick={() => {
                                        if(['Virtual Class', 'Face to Face'].includes(data.status))
                                          history.push(`/pelatihan-detail-lainnya?id=${data.id}`);
                                        else
                                          buy(data)
                                      }}>Beli Pelatihan</button>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          ))}
                        </SlickPelatihan>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default DashboardPeserta;
