import React, {useState, useEffect} from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Users, MessageSquare } from 'react-feather';
import { Link, useLocation } from "react-router-dom";
import ItemsPelatihanDetail from './itemsPelatihanDetail';
import API from "../../../api";

const DetailPeserta = () => {
  const ItemsData = [
    {
      judul:'Public Speaking King ',
      status:'online',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti.',
      progress: 0,
    },
    {
      judul:'Public Speaking King ',
      status:'Face to Face',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti.',
      progress: 100,
    },
    {
      judul:'Public Speaking King ',
      status:'Virtual Class',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, nisl a nulla lacus, augue porttitor ut turpis. Dapibus nunc tempus amet, nunc neque. Est in in condimentum potenti.',
      progress: 100,
    }
  ];

  let query = new URLSearchParams(useLocation().search);
  const [courses, setCourses] = useState([]);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    API.trainer.course.get_progress(query.get('id'), {user_id: query.get('user_id')})
        .then(json => {
          let pelatihan = [];

          json.data.data.map(val => {
            pelatihan.push({
              id: val.id,
              judul: val.course_id.name,
              status: val.course_id.type,
              desc: val.course_id.description,
              progress: val.progress,
            })
          });

          setCourses(pelatihan);
        })

    API.trainer.participant.get_one(query.get('user_id'))
        .then(json => {
          setProfile(json.data);
        })
  }, []);

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Peserta Saya'}>
          <li className="breadcrumb-item">
            <Link to="/trainer" className='text-warning'>
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Peserta Saya</li>
          <li className="breadcrumb-item">Detail Peserta</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className='col-md-7'>
          <div className='card p-4'>
            <div className="card-header p-0 border-0 mb-4">
              <div className='mr-auto'>
                <h3 className='text-warning font-weight-bold'>PELATIHAN YANG DIIKUTI</h3>
                <span className='text-muted font-weight-bold'>Pelatihan Aktif</span>
              </div>
            </div>
            {courses.map((data,key)=>(
              <ItemsPelatihanDetail {...data} key={key}/>
            ))}
          </div>
        </div>
        {
          profile &&
          <div className='col-md-5'>
            <div className='card p-4'>
              <div className="card-header p-0">
                <h5 className='text-muted font-weight-bold'>Profil Peserta</h5>
                <div className='d-flex align-items-center my-3'>
                  <div>
                    <img className="img-60 rounded-circle" src={profile.profile_picture} alt="#"></img>
                  </div>
                  <label className='mb-0 ml-2'>
                    <h6 className='mb-0'>{profile.name}</h6>
                    <p className='mb-0'><span className="font-weight-bold">Peserta</span> - {profile.company_id}</p>
                  </label>
                </div>
                <p className='mb-0'>No Telepon</p>
                <p className="font-weight-bold">{profile.phone_number}</p>
                <p className='mb-0'>Email</p>
                <p className="font-weight-bold">{profile.email}</p>
                <p className='mb-0'>Status Pelatihan</p>
                {
                  profile.is_active ?
                      <p className="font-weight-bold text-success">Aktif</p> :
                      <p className="font-weight-bold text-danger">Nonaktif</p>
                }
                <p className='mb-0'>Asal Kota</p>
                <p className="font-weight-bold">{profile.city}</p>
              </div>
              <div className="card-body d-flex align-items-center justify-content-center text-muted">
                <MessageSquare/>
                <h6 className='ml-2 font-weight-bold mb-0'>Chat</h6>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default DetailPeserta;
