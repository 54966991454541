import Get from "../Get";
import Post from "../Post";

const my_profile = () => Get(`super-admin/my-profile`);
const professions = () => Get(`super-admin/my-profile/professions`);
const update_account = (data) => Post(`super-admin/my-profile/account`, data);
const update_profile = (data) => Post(`super-admin/my-profile/profile`, data);

const SuperadminProfile = {
  my_profile,
  professions,
  update_account,
  update_profile,
};

export default SuperadminProfile;
