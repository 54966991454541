import Get from "../Get";
import Post from "../Post";

const get_detail_adminc = (id) => Get(`super-admin/admin-c/${id}`);
const insert_adminc = (data) => Post(`super-admin/admin-c`, data);
const update_adminc = (id, data) => Post(`super-admin/admin-c/${id}`, data);
const SuperadminAdminC = {
  get_detail_adminc,
  insert_adminc,
  update_adminc,
};

export default SuperadminAdminC;
