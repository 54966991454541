import React from 'react';
import { PrivateRoute } from '../../component';
import DashboardPeserta from './dashboard';
import {
   Switch,
   Route,
   useRouteMatch
} from "react-router-dom";
import GeneralResource from './generalResource';
import NotifDetail from './notifDetail';
import SertifikatSaya from './sertifikatSaya';
import PelatihanSaya from './pelatihanSaya';
import SertifikatCek from './sertifikatCek';
import PelatihanDetail from './pelatihanDetail';
import RedeemVoucher from './redeemVoucher';
import Chat from './chat';
import Error404 from './error404';
import PoinSaya from './poinSaya';
import PelatihanLainnya from './pelatihanLainnya';
import PelatihanLainnyaDetail from './PelatihanLainnyaDetail';
import RiwayatTransaksi from './riwayatTransaksi';
import PelatihanDetailLainnya from './pelatihanDetailLainnya';
import Cart from './cart';
import PelatihanDetailMulai from './pelatihanDetailMulai';
import ProfilSaya from './profilSaya';
import UnduhInvoice from './unduhInvoice';
import LihatTagihan from './lihatTagihan';
import AboutUs from './aboutUs';
import GeneralResourceDetail from './generalResourceDetail';

const Peserta = () => {
   const { path } = useRouteMatch();

   return (
      <Switch>
         <PrivateRoute
            routeAs='peserta'
            redirectPath='/1/login'
            role_id={5}
         >
            <div className="container-fluid">
               <Route exact path={path}>
                  <DashboardPeserta />
               </Route>
               <Route exact path={`/general-resource`}>
                  <GeneralResource />
               </Route>
               <Route exact path={`/general-resource/detail`}>
                  <GeneralResourceDetail />
               </Route>
               <Route exact path={`/about-us`}>
                  <AboutUs />
               </Route>
               <Route exact path={`/notif-detail`}>
                  <NotifDetail />
               </Route>
               <Route exact path={`/sertifikat-saya`}>
                  <SertifikatSaya />
               </Route>
               <Route exact path={`/cek-sertifikat`}>
                  <SertifikatCek />
               </Route>
               <Route exact path={`/pelatihan-saya`}>
                  <PelatihanSaya />
               </Route>
               <Route exact path={`/pelatihan-detail`}>
                  <PelatihanDetail />
               </Route>
               <Route exact path={`/pelatihan`}>
                  <PelatihanDetailMulai />
               </Route>
               <Route exact path={`/redeem-voucher`}>
                  <RedeemVoucher />
               </Route>
               <Route exact path={`/poin-saya`}>
                  <PoinSaya />
               </Route>
               <Route exact path={`/riwayat-transaksi`}>
                  <RiwayatTransaksi />
               </Route>
               <Route exact path={`/chat`}>
                  <Chat />
               </Route>
               <Route exact path={`/pelatihan-lainnya`}>
                  <PelatihanLainnya />
               </Route>
               <Route exact path={`/pelatihan-lainnya/detail`}>
                  <PelatihanLainnyaDetail />
               </Route>
               <Route exact path={`/pelatihan-lainnya/cart`}>
                  <Cart />
               </Route>
               <Route exact path={`/pelatihan-detail-lainnya`}>
                  <PelatihanDetailLainnya />
               </Route>
               <Route exact path={`/profil-saya`}>
                  <ProfilSaya />
               </Route>
            </div>
            <Route exact path={`/unduh-invoice`}>
               <UnduhInvoice />
            </Route>
            <Route exact path={`/lihat-tagihan`}>
               <LihatTagihan />
            </Route>
            {/* <Route path="*">
              <Error404 />
            </Route> */}
         </PrivateRoute>
      </Switch>
   )
}

export default Peserta;
