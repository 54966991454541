import React, { useState, useEffect } from "react";
import { Users } from "react-feather";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import API from "../../../api";

const TambahPeserta = () => {
  const history = useHistory();
  const [DoneSubmit, setDoneSubmit] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);

  const [userPhoto, setuserPhoto] = useState(null);
  const [userPhotoUrl, setuserPhotoUrl] = useState("");
  const [uploadKTPPreview, setuploadKTPPreview] = useState(null);
  const [uploadKTPPreviewUrl, setuploadKTPPreviewUrl] = useState("");
  const [isiBiodataSendiri, setisiBiodataSendiri] = useState(false);
  const [tampilkanForm, settampilkanForm] = useState(false);

  const [data_profession, setDataProfession] = useState([]);
  const [listProvinsi, setListProvinsi] = useState([]);
  const [listKota, setListKota] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);

  const [status, setStatus] = useState("0");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [profession, setProfession] = useState("");
  const [profession_id, setProfessionId] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [company, setCompany] = useState(null);
  const [company_id, setCompanyId] = useState(null);

  const [date_birth, setDateBirth] = useState("");
  const [photo, setPhoto] = useState("");
  const [nik, setNik] = useState("");
  const [ktp, setKtp] = useState("");
  const [phone, setPhone] = useState("");
  const [provinsi, setProvinsi] = useState("");
  const [kecamatan, setKecamatan] = useState("");
  const [kabupaten, setKabupaten] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [address, setAddress] = useState("");

  const handleImagePelatihanChange = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setPhoto(file);

    reader.onloadend = () => {
      setuserPhotoUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleuploadKTPChange = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setKtp(file);

    reader.onloadend = () => {
      setuploadKTPPreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (uploadKTPPreviewUrl) {
      setuploadKTPPreview(uploadKTPPreviewUrl);
    } else {
      setuploadKTPPreview(false);
    }
  }, [uploadKTPPreviewUrl]);

  useEffect(() => {
    if (userPhotoUrl) {
      setuserPhoto(userPhotoUrl);
    } else {
      setuserPhoto(false);
    }
  }, [userPhotoUrl]);

  useEffect(() => {
    API.superadmin.profession
      .get_profession()
      .then((json) => {
        setDataProfession(json.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    API.superadmin.company
      .list_perusahaan({per_page: 9999})
      .then((json) => {
        setCompany(json.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    API.rajaongkir
      .list_provinsi()
      .then((json) => {
        setListProvinsi(json);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirm_password) {
      toast.error("Confirm Password Tidak Sesuai", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const data = {
        email: email,
        password: password,
        name: name,
        profession_id: profession_id,
        office_address: office_address,
        company_id: company_id,
      };

      if (date_birth) {
        data.date_of_birth = date_birth;
      }
      if (photo) {
        data.profile_picture = photo;
      }
      if (phone) {
        data.phone_number = phone;
      }
      if (kecamatan) {
        data.region_id = kecamatan;
      }
      if (address) {
        data.address = address;
      }
      if (ktp) {
        data.ktp = ktp;
      }
      if (nik) {
        data.nik = nik;
      }
      if (postal_code) {
        data.postal_code = postal_code;
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      API.superadmin.participant
        .insert_participant(data, config)
        .then((json) => {
          console.log(json.message);
          setisLoading(true);
          toast.success(json.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setDoneSubmit(true);
          setTimeout(() => {
            history.push("/superadmin/peserta-terregistrasi");
          }, 5000);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Peserta Tidak Berhasil Ditambahkan", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"Peserta"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/tambah-peserta" className="text-warning">
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Pelatihan</li>
          <li className="breadcrumb-item">Peserta Terregistrasi</li>
          <li className="breadcrumb-item">Tambah Peserta</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header pb-3 d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  TAMBAH PESERTA BARU
                </h5>
                <span className="text-muted">Data Peserta</span>
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="border-bottom pb-3 mb-3">
                  <h6
                    className="text-muted"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Akun Peserta
                  </h6>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mt-4 text-left">
                        <h6 className="text-work">Email</h6>
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Masukkan email"
                          style={{ width: "343px" }}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group mt-4 text-left">
                        <h6 className="text-work">Password</h6>
                        <input
                          className="form-control"
                          type="password"
                          placeholder=""
                          style={{ width: "343px" }}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="form-group mt-4 text-left">
                        <h6 className="text-work">Konfirmasi Password</h6>
                        <input
                          className="form-control"
                          type="konfirmasi_password"
                          type="password"
                          placeholder=""
                          style={{ width: "343px" }}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                      <div className="form-group mt-4 text-left">
                        <h6 className="text-work">Nama</h6>
                        <input
                          className="form-control"
                          type="name"
                          placeholder="Masukkan nama"
                          style={{ width: "343px" }}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-4 text-left">
                        <h6 className="text-work">Nama Perusahaan</h6>
                        <select
                          className="form-control digits "
                          defaultValue="default"
                          style={{ width: "274px" }}
                          name="company_id"
                        >
                          <option value="default" disabled>
                            Pilih Perusahaan
                          </option>
                          {company &&
                            company.map((value) => {
                              return (
                                <option onClick={() => setCompanyId(value.id)}>
                                  {value.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="form-group mt-4 text-left">
                        <h6 className="text-work">Pekerjaan</h6>
                        <select
                          className="form-control digits"
                          id="exampleFormControlSelect7"
                        >
                          <option>--Pilih Pekerjaan--</option>
                          {data_profession &&
                            data_profession.map((v) => {
                              return (
                                <option onClick={() => setProfessionId(v.id)}>
                                  {v.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="form-group mt-4 text-left">
                        <h6 className="text-work">Alamat Kantor</h6>
                        <textarea
                          className="form-control"
                          name="alamat"
                          id="alamatKantor"
                          required
                          placeholder="Masukkan alamat kantor"
                          rows="5"
                          onChange={(e) => setOfficeAddress(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div className="form-group pl-2 pt-0 mt-0">
                    <input
                      type="checkbox"
                      id="inlineCheckbox1"
                      onChange={(e) => setisiBiodataSendiri(e.target.checked)}
                    />
                    <label className="text-muted" htmlFor="inlineCheckbox1">
                      &nbsp;&nbsp;Biarkan peserta mengisi biodata sendiri
                    </label>
                  </div>
                  <h6
                    className="text-muted"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Biodata Peserta
                  </h6>
                  <button
                    className="btn btn-primary mt-3"
                    onClick={() => settampilkanForm(!tampilkanForm)}
                  >
                    {tampilkanForm ? "Sembunyikan" : "Tampilkan"} Form
                  </button>

                  {tampilkanForm && (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mt-4 text-left">
                          <h6 className="text-work">Tanggal Lahir</h6>
                          <input
                            className="form-control"
                            type="date"
                            placeholder="Masukkan email"
                            style={{ width: "343px" }}
                            disabled={isiBiodataSendiri}
                            onChange={(e) => setDateBirth(e.target.value)}
                          />
                        </div>
                        <div className="form-group mt-4 text-left">
                          <div
                            className="button-custom m-0"
                            style={{
                              height: "92px",
                              width: "92px",
                            }}
                          >
                            <div
                              className="upload-image d-flex justify-content-center align-items-center"
                              style={{
                                position: "relative",
                                backgroundImage: `url(${userPhoto})`,
                                height: "92px",
                                width: "92px",
                                color: "#C4C4C4",
                                backgroundColor: "#F2F2F2",
                                fontSize: "35px",
                                borderRadius: "50% 50%",
                              }}
                            >
                              <span>
                                <i className="fa fa-camera"></i>
                              </span>
                              <input
                                disabled={isiBiodataSendiri}
                                id="file-select"
                                type="file"
                                onChange={handleImagePelatihanChange}
                                style={{
                                  height: "92px",
                                  width: "92px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group mt-4 text-left">
                          <h6 className="text-work">Nomor Telepon</h6>
                          <input
                            className="form-control"
                            type="numer"
                            placeholder="Masukkan email"
                            style={{ width: "343px" }}
                            disabled={isiBiodataSendiri}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mt-4 text-left">
                          <h6 className="text-work">NIK KTP</h6>
                          <input
                            className="form-control"
                            type="numer"
                            placeholder="Masukkan nomor telepon"
                            style={{ width: "343px" }}
                            disabled={isiBiodataSendiri}
                            onChange={(e) => setNik(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          {/* Preview */}
                          <img
                            className="img-fluid w-50 mb-3 h-50"
                            src={uploadKTPPreview}
                            alt="KTP"
                          />
                          {/* Input */}
                          <div className="button-custom w-50 py-3">
                            <div className="btn btn-pill btn-primary upload-image">
                              <span>
                                <i className="fa fa-cloud-upload"></i> &ensp;
                                Upload KTP
                              </span>
                              <input
                                id="file-select"
                                type="file"
                                onChange={handleuploadKTPChange}
                                disabled={isiBiodataSendiri}
                              />
                            </div>
                          </div>
                          <p style={{ color: "red" }}>
                            *Ukuran Foto KTP Maksimal 100KB
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-0 mt-1">
                          <label htmlFor="alamat">
                            <b>Alamat</b>
                          </label>
                        </div>
                        <div className="d-flex">
                          <div className="form-group mr-5">
                            <select
                              name="provinsi"
                              className="form-control"
                              id="provinsi"
                              style={{ width: "200px" }}
                              disabled={isiBiodataSendiri}
                              onChange={(e) => {
                                setProvinsi(e.target.value);
                                setListKota([]);
                                setListKecamatan([]);

                                API.rajaongkir
                                  .list_kota(e.target.value)
                                  .then((json) => {
                                    setListKota(json);
                                  });
                              }}
                            >
                              <option value="#">Provinsi</option>
                              {listProvinsi.map((v) => {
                                return (
                                  <option value={v.province_id}>
                                    {v.province}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="form-group">
                            <select
                              name="kabupaten"
                              className="form-control"
                              id="kabupaten"
                              style={{ width: "200px" }}
                              disabled={isiBiodataSendiri}
                              onChange={(e) => {
                                setKabupaten(e.target.value);
                                setListKecamatan([]);

                                API.rajaongkir
                                  .list_kecamatan(e.target.value)
                                  .then((json) => {
                                    console.log(json);
                                    setListKecamatan(json);
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              }}
                            >
                              <option value="#">Kabupaten</option>
                              {listKota.map((v) => {
                                return (
                                  <option value={v.city_id}>
                                    {v.city_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="form-group mr-5">
                            <select
                              name="kecamatan"
                              className="form-control"
                              id="kecamatan"
                              style={{ width: "200px" }}
                              disabled={isiBiodataSendiri}
                              onChange={(e) => setKecamatan(e.target.value)}
                            >
                              <option value="#">Kecamatan</option>
                              {listKecamatan.map((v) => (
                                <option value={v.subdistrict_id}>
                                  {v.subdistrict_name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              name="kodePos"
                              id="kodePos"
                              placeholder="Masukkan kode pos"
                              disabled={isiBiodataSendiri}
                              onChange={(e) => setPostalCode(e.target.value)}
                            />
                          </div>
                        </div>
                        <textarea
                          className="form-control"
                          name="alamat"
                          id="alamatKantor"
                          placeholder="Nama Jalan / Alamat Detail"
                          rows="5"
                          style={{ width: "530px" }}
                          disabled={isiBiodataSendiri}
                          onChange={(e) => setAddress(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-12 m-5 border-top mt-5 pt-5">
                  <div className="text-right mb-5">
                    <Link to="/superadmin/peserta-terregistrasi">
                      <button
                        type="cancel"
                        className="btn btn-outline-primary btn-pill mr-2"
                        type="button"
                      >
                        Batal
                      </button>
                    </Link>
                    <button type="submit" className="btn btn-primary btn-pill">
                      Simpan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TambahPeserta;
