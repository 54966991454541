import React, {useState, useEffect} from 'react';
import { Users } from 'react-feather';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useLocation } from 'react-router-dom'
import API from "../../../api";

const PesertaTerregistrasi = () => {
  let query = new URLSearchParams(useLocation().search);
  const columnsTabel = [
    {
      dataField: 'id',
      text: 'ID',
      headerStyle: () => ({ width: '5%', textAlign: 'left'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: 'peserta',
      text: 'Nama Peserta',
      headerStyle: () => ({ width: '20%', textAlign: 'left'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cell, row) => {
        return(
          <div className='d-flex align-items-center mb-2'>
            <div>
                <img className="img-40 rounded-circle" src={row.avatar} alt="#"></img>
            </div>
            <label className='mb-0 ml-2'>
              <p className='font-weight-bold'>{row.peserta}</p>
            </label>
          </div>
        )
      }
    },
    {
      dataField: 'tanggal',
      text: 'Tanggal Registrasi',
      headerStyle: () => ({ width: '20%', textAlign: 'center'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: 'bidang',
      text: 'Nama Perusahaan',
      headerStyle: () => ({ width: '20%', textAlign: 'center'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: 'status',
      text: 'Status',
      headerStyle: () => ({ width: '10%', textAlign: 'center'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: '',
      text: 'Aksi',
      headerStyle: () => ({ width: '10%', textAlign: 'center',}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cel,row) => {
        return  (
          <Link to={`/ketua/peserta-terregistrasi/detail-peserta?id=${row.id}`}>
            <button className=" btn btn-pill btn-outline-primary btn-sm font-weight-bold" type="button"> Detail </button>
          </Link>
        )
      },
    }
  ];

  const per_page = 5;
  let page = query.get('page') || 1;

  const [participants, setParticipant] = useState({
    data:[],
    meta:{
      from:1,
      to:0,
      total: 0,
      current_page: page,
      per_page,
      url: ''
    }
  });
  const [order_by, setOrderBy] = useState('desc');

  const handleActiveFilter = e => {
    setOrderBy(e.target.value)
  };

  useEffect(() => {
    API.ketua.participant.listParticipant({page, per_page, order_by})
        .then(json => {
          let participant = {...participants};
          participant.data = [];

          if(json.data){
            json.data.data.map(val => {
              participant.data.push({
                id: val.id,
                peserta: val.name,
                avatar: val.photo,
                tanggal: val.created_at+' WIB',
                bidang: val.company_name,
                status: val.status === 1 ? 'Aktif' : 'Nonaktif',
              })
            });
          }

          participant.meta.from = (page - 1) * per_page + 1;
          participant.meta.to = per_page > json.data.total ? json.data.total : page * per_page > json.data.total ? json.data.total : page * per_page;
          participant.meta.total = json.data.total;
          participant.meta.current_page = page;
          participant.meta.total_page = Math.ceil(participant.meta.total / participant.meta.per_page);

          setParticipant(participant);
        });
  }, [page, order_by]);

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Peserta Terregistrasi'}>
          <li className="breadcrumb-item">
            <Link to="/ketua/list-pelatihan" className='text-warning'>
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Pelatihan</li>
          <li className="breadcrumb-item">Peserta Terregistrasi</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
      <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-3 d-flex align-items-center">
              <div className='mr-auto'>
                <h5 className='text-work text-theme' style={{fontSize:'24px', fontWeight:'600'}}>LIST PESERTA TERREGISTRASI</h5>
              </div>
            </div>
            <div className="card-body">
            <div className='d-flex align-items-center justify-content-end mb-3'>
              <p className='my-auto mr-3 text-work' style={{fontSize:'16px'}}>Sort by</p>
              <div>
                <select className="form-control btn-pill digits" id="exampleFormControlSelect7" onChange={handleActiveFilter}>
                  <option value='desc' selected={true}>Terbaru</option>
                  <option value='asc'>Terlama</option>
                </select>
              </div>
            </div>
              <div className='overflow-auto w-100'>
                <TableComp
                    rowClasses='text-center'
                    title_paggination='peserta'
                    columns={columnsTabel}
                    data={participants}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default PesertaTerregistrasi;
