import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
// import { IMGEmailKonfirmasi } from '../../assets';
import { Form, Button } from 'semantic-ui-react';
import API from "../../api";
import IMGEmailVerifikasi from '../../assets/images/email-konfirmasi-verifikasi.png';

const Register = (props) => {
    const [DataRegister, setDataRegister] = useState({});
    const [matchPassword, setmatchPassword] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [DoneSubmit, setDoneSubmit] = useState(false);
    const [ErrMessage, setErrMessage] = useState([]);

    const [professions, setProfessions] = useState([]);

    const { id } = useParams();

    const handleChange = (event) => {
        setDataRegister({
            ...DataRegister,
            role_id: 5,
            company_id: id,
            [event.target.name]: event.target.value
        })
    }

    useEffect(() => {
        API.user.get_professions()
            .then(json => {
                setProfessions(json.data);
            })
            .catch(e => {
                alert(e.message);
            })
    },[]);

    useEffect(() => {
        if(DataRegister.password){
            setmatchPassword(DataRegister.password === DataRegister.confirm_password)
        }
    }, [DataRegister]);

    const handleSubmited = () => {
        setisLoading(true);
        let data = {...DataRegister};
        data.phone_number = data.phone_number.replace(/^[0]/, '62');

        // console.log(data);
        // setisLoading(false);

        API.user.register(data)
            .then(json => {
                setisLoading(false);
                setDoneSubmit(true);
            })
            .catch(e => {
                setisLoading(false);
                let errors = [];
                if(e.errors){
                    if(Array.isArray(e.errors)){
                        for (const [key, value] of Object.entries(e.errors)) {
                            errors.push(value);
                        }
                        setErrMessage(errors);
                    }
                    else
                        setErrMessage(e.errors);
                }
                else {
                    setErrMessage([e.message]);
                }
            })
    }

    if(DoneSubmit){
        return(
            <div className='text-center mx-auto'>
                <img src={IMGEmailVerifikasi} className='w-25' alt='Omind' />

                <h4 className='font-weight-bold mt-5'>Verifikasikan Email Anda </h4>
                <p>Selamat Anda berhasil melakukan pendaftaran. Selanjutnya silakan periksa email Anda untuk menyelesaikan proses pendaftaran</p>

                <Button type='button' className="btn btn-outline-primary btn-block mt-2" primary loading={false}  onClick={() => props.setviewLogin('login')}>Kembali ke Login</Button>
            </div>
        )
    }

    return(
        <Form
            className='w-100'
            onSubmit={handleSubmited}
        >
            <Form.Field className='mb-2'>
                <h2>Daftar</h2>
                <p>
                Masukkan data diri anda
                </p>
            </Form.Field>
            {ErrMessage.map((val, key) => {
                return <div className='form-error_message mt-3 d-flex'>
                    <label className='mb-0 mr-auto'>
                        {val}
                    </label>
                    <label className='m-0 p-0 btn' onClick={() => {
                        let errors = [...ErrMessage];
                        errors.splice(key, 1);
                        setErrMessage(errors);
                    }}>
                        X
                    </label>
                </div>
            })}
            <div className="form-group mt-2">
                <input className="form-control" type="text" name="name" required onChange={handleChange} placeholder="Nama"   />
            </div>
            <div className="form-group mt-2">
                <input className="form-control" type="email" name="email" required onChange={handleChange} placeholder="Alamat Email"   />
            </div>
            <div className="form-group mt-2">
                <input className="form-control" type="phone" name="phone_number" required onChange={handleChange} placeholder="Nomor Handphone"   />
            </div>
            <div className="form-group mt-2">
                <select name="profession_id" onChange={handleChange} className='form-control'>
                    <option disabled={true} selected={true}>Pekerjaan...</option>
                    {professions.map(val => {
                        return <option value={val.id}>{val.name}</option>
                    })}
                </select>
            </div>
            {/*<div className="form-group mt-2">
                <input className="form-control" type="text" name="nama_perusahaan" required onChange={handleChange}  placeholder="Nama Perusahaan"   />
            </div>*/}
            <div className="form-group mt-2">
                <input className="form-control" type="password" name="password" required onChange={handleChange}  placeholder="Password Baru"   />
            </div>
            <div className="form-group mt-2">
                <input className="form-control" type="password" name="confirm_password" required onChange={handleChange}  placeholder="Konfirmasi Password Baru"   />
            </div>

            <Button type='submit'  className="btn btn-primary btn-block"  primary disabled={isLoading}>Daftar</Button>
            <Form.Field className='d-block d-md-flex mt-2'>
                <p> Sudah punya akun ? <Link onClick={()=>props.setviewLogin('')}>&nbsp; Masuk</Link> </p>
            </Form.Field>
        </Form>
    )
}

export default Register;
