import React, {useEffect, useState} from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Home } from 'react-feather';
import API from "../../../api";

const ListPelatihan = () => {
  const history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  const per_page = 6;
  let pageActive = query.get('pageActive') || 1;
  let pageInactive = query.get('pageInactive') || 1;

  const [active_courses, setActiveCourse] = useState({
    data:[],
    meta:{
      from:1,
      to:0,
      total: 0,
      current_page: pageActive,
      per_page,
      url: ''
    }
  });
  const [inactive_courses, setInactiveCourse] = useState({
    data:[],
    meta:{
      from:1,
      to:0,
      total: 0,
      current_page: pageInactive,
      per_page,
      url: ''
    }
  });

  useEffect(() => {
    API.trainer.dashboard.my_courses({page: pageActive, per_page, status: 'Aktif'})
        .then(json => {
          let activeC = {...active_courses};
          activeC.data = [];

          activeC.data = json.data.data;

          activeC.meta.from = (pageActive - 1) * per_page + 1;
          activeC.meta.to = per_page > json.data.num_rows_filter ? json.data.num_rows_filter : pageActive * per_page > json.data.num_rows_filter ? json.data.num_rows_filter : pageActive * per_page;
          activeC.meta.total = json.data.num_rows_filter;
          activeC.meta.current_page = pageActive;
          activeC.meta.total_page = Math.ceil(activeC.meta.total / activeC.meta.per_page);

          setActiveCourse(activeC);

        });
  }, [pageActive]);

  useEffect(() => {
    API.trainer.dashboard.my_courses({page: pageInactive, per_page, status: 'Nonaktif'})
        .then(json => {
          let inactiveC = {...inactive_courses};
          inactiveC.data = [];

          inactiveC.data = json.data.data;

          inactiveC.meta.from = (pageInactive - 1) * per_page + 1;
          inactiveC.meta.to = per_page > json.data.num_rows_filter ? json.data.num_rows_filter : pageInactive * per_page > json.data.num_rows_filter ? json.data.num_rows_filter : pageInactive * per_page;
          inactiveC.meta.total = json.data.num_rows_filter;
          inactiveC.meta.current_page = pageInactive;
          inactiveC.meta.total_page = Math.ceil(inactiveC.meta.total / inactiveC.meta.per_page);

          setInactiveCourse(inactiveC);

        });
  }, [pageInactive]);

  const handleActiveFilter = e => {

    API.trainer.dashboard.my_courses({
      page: pageActive,
      per_page,
      status: 'Aktif',
      descending: e.target.value === 'new' ? 'true' : 'false'
    }).then(json => {
      let activeC = {...active_courses};
      activeC.data = [];

      activeC.data = json.data.data;

      activeC.meta.from = (pageActive - 1) * per_page + 1;
      activeC.meta.to = per_page > json.data.num_rows_filter ? json.data.num_rows_filter : pageActive * per_page > json.data.num_rows_filter ? json.data.num_rows_filter : pageActive * per_page;
      activeC.meta.total = json.data.num_rows_filter;
      activeC.meta.current_page = pageActive;
      activeC.meta.total_page = Math.ceil(activeC.meta.total / activeC.meta.per_page);

      setActiveCourse(activeC);

    });
  };

  const handleInactiveFilter = e => {
    API.trainer.dashboard.my_courses({
      page: pageInactive,
      per_page,
      status: 'Nonaktif',
      descending: e.target.value === 'new' ? 'true' : 'false'
    }).then(json => {
      let inactiveC = {...inactive_courses};
      inactiveC.data = [];

      inactiveC.data = json.data.data;

      inactiveC.meta.from = (pageInactive - 1) * per_page + 1;
      inactiveC.meta.to = per_page > json.data.num_rows_filter ? json.data.num_rows_filter : pageInactive * per_page > json.data.num_rows_filter ? json.data.num_rows_filter : pageInactive * per_page;
      inactiveC.meta.total = json.data.num_rows_filter;
      inactiveC.meta.current_page = pageInactive;
      inactiveC.meta.total_page = Math.ceil(inactiveC.meta.total / inactiveC.meta.per_page);

      setInactiveCourse(inactiveC);

    });
  };

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Pelatihan Saya '}>
          <li className="breadcrumb-item">
            <Link to="/trainer" className='text-warning'>
              <Home />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Saya </li>
        </BreadcrumbComp>
      </div>

      <div className='row'>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header d-flex">
              <div className='mr-auto'>
                <h5 className='text-warning '>PELATIHAN SAYA</h5>
                <span className='text-muted'>Pelatihan Aktif</span>
              </div>
              <div>
                <select className="form-control btn-pill digits" id="exampleFormControlSelect7" onChange={handleActiveFilter}>
                  <option disabled selected>Sorting berdasarkan</option>
                  <option value='new'>Terbaru</option>
                  <option value='old'>Terlama</option>
                </select>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                {
                  active_courses.data.map((v, key) => {
                    return <div className='col-md-4' key={key}>
                      <div className='border card shadow-0'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='h-100 rounded-left' style={{ backgroundImage: `url(${v.thumbnail.replace(/ /g, "%20")})`, backgroundSize: 'cover' }}>
                              <div className='h-100 d-flex flex-column p-3'>
                                <div className={`badge ${v.type === 'Online' ? 'badge-primary' : 'badge-danger'} text-wrap w-50`} style={{marginBottom: '150px'}}>
                                  {v.type}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-12 px-5 py-3'>
                            <h5 className={`text-muted font-weight-bold`}>{v.name}</h5>
                          </div>
                          <div className='col-md-12 px-5 py-3 mb-2 text-center'>
                            <Link to={`/trainer/detail-pelatihan?id=${v.id}`}>
                              <button  className={`btn btn-pill btn-primary btn-sm w-75`}  type="button">Detail pelatihan</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  })
                }
              </div>

              <div className="d-md-flex mt-3">
                <p className="my-auto" style={{color: '#C4C4C4'}}>Menampilkan <b>{active_courses.meta.from} - {active_courses.meta.to}</b> dari <b>{active_courses.meta.total} pelatihan </b></p>
                <div className="btn-group ml-md-auto" >
                  <div className="">
                    <div>
                      {active_courses.meta.current_page > 1 &&
                      <>
                        <Link to={`${active_courses.meta.url}?pageActive=${parseInt(1)}&pageInactive=${pageInactive}`} style={{color: 'black'}}>
                          <a className="btn rounded-circle btn-xs mx-1">
                            <i className="fa fa-angle-double-left"></i>
                          </a>
                        </Link>
                        <Link to={`${active_courses.meta.url}?pageActive=${parseInt(active_courses.meta.current_page) - 1}&pageInactive=${pageInactive}`} style={{color: 'black'}}>
                          <a  className="btn rounded-circle btn-xs mx-1">
                            <i className="fa fa-angle-left"></i>
                          </a>
                        </Link>
                      </>
                      }
                      {
                        parseInt(active_courses.meta.current_page) + 3 >= active_courses.meta.total_page ?
                            active_courses.meta.total_page - 3 <= 0 ?
                                <>
                                  {
                                    Array(active_courses.meta.total_page).fill(1).map((x, y) => x + y)
                                        .map(val => {
                                          return val === parseInt(active_courses.meta.current_page) ?
                                              <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{val}</a> :
                                              <Link to={`${active_courses.meta.url}?pageActive=${val}&pageInactive=${pageInactive}`} style={{color: 'black'}}>
                                                <a className="btn rounded-circle btn-xs mx-1">{val}</a>
                                              </Link>
                                        })
                                  }
                                </> :
                                <>
                                  {
                                    Array(4).fill(active_courses.meta.total_page - 3).map((x, y) => x + y)
                                        .map(val => {
                                          return val === parseInt(active_courses.meta.current_page) ?
                                              <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{val}</a> :
                                              <Link to={`${active_courses.meta.url}?pageActive=${val}&pageInactive=${pageInactive}`} style={{color: 'black'}}>
                                                <a className="btn rounded-circle btn-xs mx-1">{val}</a>
                                              </Link>
                                        })
                                  }
                                </>
                            :
                            <>
                              <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{active_courses.meta.current_page}</a>
                              <Link to={`${active_courses.meta.url}?pageActive=${parseInt(active_courses.meta.current_page) + 1}&pageInactive=${pageInactive}`} style={{color: 'black'}}>
                                <a className="btn rounded-circle btn-xs mx-1">{parseInt(active_courses.meta.current_page) + 1}</a>
                              </Link>
                              <Link to={`${active_courses.meta.url}?pageActive=${parseInt(active_courses.meta.current_page) + 2}&pageInactive=${pageInactive}`} style={{color: 'black'}}>
                                <a className="btn rounded-circle btn-xs mx-1">{parseInt(active_courses.meta.current_page) + 2}</a>
                              </Link>
                              <Link to={`${active_courses.meta.url}?pageActive=${parseInt(active_courses.meta.current_page) + 3}&pageInactive=${pageInactive}`} style={{color: 'black'}}>
                                <a className="btn rounded-circle btn-xs mx-1">{parseInt(active_courses.meta.current_page) + 3}</a>
                              </Link>
                            </>
                      }
                      {
                        parseInt(active_courses.meta.current_page) !== active_courses.meta.total_page &&
                        <>
                          <Link to={`${active_courses.meta.url}?pageActive=${parseInt(active_courses.meta.current_page) + 1}&pageInactive=${pageInactive}`} style={{color: 'black'}}>
                            <a  className="btn rounded-circle btn-xs mx-1">
                              <i className="fa fa-angle-right"></i>
                            </a>
                          </Link>
                          <Link to={`${active_courses.meta.url}?pageActive=${active_courses.meta.total_page}&pageInactive=${pageInactive}`} style={{color: 'black'}}>
                            <a className="btn rounded-circle btn-xs mx-1">
                              <i className="fa fa-angle-double-right"></i>
                            </a>
                          </Link>
                        </>
                      }
                    </div>
                    {/* <Pagination
                      ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                      firstItem={{ content: <i className='fa fa-angle-double-left' />, icon: true }}
                      lastItem={{ content: <i className='fa fa-angle-double-right' />, icon: true }}
                      prevItem={{ content: <i className='fa fa-angle-left' />, icon: true }}
                      nextItem={{ content: <i className='fa fa-angle-right' />, icon: true }}
                      defaultActivePage={active_courses.meta.current_page}
                      totalPages={active_courses.meta.last_page}
                      onPageChange={props.handlePaggination} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header d-flex">
              <div className='mr-auto'>
                <h5 className='text-warning '>PELATIHAN SAYA</h5>
                <span className='text-muted'>Pelatihan Tidak Aktif</span>
              </div>
              <div>
                <select className="form-control btn-pill digits" id="exampleFormControlSelect7" onChange={handleInactiveFilter}>
                  <option disabled selected>Sorting berdasarkan</option>
                  <option value='new'>Terbaru</option>
                  <option value='old'>Terlama</option>
                </select>
              </div>
            </div>
            <div className="card-body">
              {
                inactive_courses.data.map((v, key) => {
                  return <div className='col-md-4' key={key}>
                    <div className='border card shadow-0'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='h-100 rounded-left' style={{ backgroundImage: `url(${v.thumbnail.replace(/ /g, "%20")})`, backgroundSize: 'cover' }}>
                            <div className='h-100 d-flex flex-column p-3'>
                              <div className={`badge ${v.type === 'Online' ? 'badge-primary' : 'badge-danger'} text-wrap w-50`} style={{marginBottom: '150px'}}>
                                {v.type}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-12 px-5 py-3'>
                          <h5 className={`text-muted font-weight-bold text-center`}>{v.name}</h5>
                        </div>
                        <div className='col-md-12 px-5 py-3 mb-2 text-center'>
                          <Link to={`/trainer/detail-pelatihan?id=${v.id}`}>
                            <button  className={`btn btn-pill btn-primary btn-sm w-75`}  type="button">Detail pelatihan</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                })
              }

              <div className="d-md-flex mt-3">
                <p className="my-auto" style={{color: '#C4C4C4'}}>Menampilkan <b>{inactive_courses.meta.from} - {inactive_courses.meta.to}</b> dari <b>{active_courses.meta.total} pelatihan</b></p>
                <div className="btn-group ml-md-auto" >
                  <div className="">
                    <div>
                      {inactive_courses.meta.current_page > 1 &&
                      <>
                        <Link to={`${inactive_courses.meta.url}?pageActive=${pageActive}&pageInactive=${parseInt(1)}`} style={{color: 'black'}}>
                          <a className="btn rounded-circle btn-xs mx-1">
                            <i className="fa fa-angle-double-left"></i>
                          </a>
                        </Link>
                        <Link to={`${inactive_courses.meta.url}?pageActive=${pageActive}&pageInactive=${parseInt(inactive_courses.meta.current_page) - 1}`} style={{color: 'black'}}>
                          <a  className="btn rounded-circle btn-xs mx-1">
                            <i className="fa fa-angle-left"></i>
                          </a>
                        </Link>
                      </>
                      }
                      {
                        parseInt(inactive_courses.meta.current_page) + 3 >= inactive_courses.meta.total_page ?
                            inactive_courses.meta.total_page - 3 <= 0 ?
                                <>
                                  {
                                    Array(inactive_courses.meta.total_page).fill(1).map((x, y) => x + y)
                                        .map(val => {
                                          return val === parseInt(inactive_courses.meta.current_page) ?
                                              <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{val}</a> :
                                              <Link to={`${inactive_courses.meta.url}?pageActive=${pageActive}&pageInactive=${val}`} style={{color: 'black'}}>
                                                <a className="btn rounded-circle btn-xs mx-1">{val}</a>
                                              </Link>
                                        })
                                  }
                                </> :
                                <>
                                  {
                                    Array(4).fill(inactive_courses.meta.total_page - 3).map((x, y) => x + y)
                                        .map(val => {
                                          return val === parseInt(inactive_courses.meta.current_page) ?
                                              <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{val}</a> :
                                              <Link to={`${inactive_courses.meta.url}?pageActive=${pageActive}&pageInactive=${val}`} style={{color: 'black'}}>
                                                <a className="btn rounded-circle btn-xs mx-1">{val}</a>
                                              </Link>
                                        })
                                  }
                                </>
                            :
                            <>
                              <a className="btn rounded-circle btn-primary btn-xs text-white mx-1">{inactive_courses.meta.current_page}</a>
                              <Link to={`${inactive_courses.meta.url}?pageActive=${pageActive}&pageInactive=${parseInt(inactive_courses.meta.current_page) + 1}`} style={{color: 'black'}}>
                                <a className="btn rounded-circle btn-xs mx-1">{parseInt(inactive_courses.meta.current_page) + 1}</a>
                              </Link>
                              <Link to={`${inactive_courses.meta.url}?pageActive=${pageActive}&pageInactive=${parseInt(inactive_courses.meta.current_page) + 2}`} style={{color: 'black'}}>
                                <a className="btn rounded-circle btn-xs mx-1">{parseInt(inactive_courses.meta.current_page) + 2}</a>
                              </Link>
                              <Link to={`${inactive_courses.meta.url}?pageActive=${pageActive}&pageInactive=${parseInt(inactive_courses.meta.current_page) + 3}`} style={{color: 'black'}}>
                                <a className="btn rounded-circle btn-xs mx-1">{parseInt(inactive_courses.meta.current_page) + 3}</a>
                              </Link>
                            </>
                      }
                      {
                        parseInt(inactive_courses.meta.current_page) !== inactive_courses.meta.total_page &&
                        <>
                          <Link to={`${inactive_courses.meta.url}?pageActive=${pageActive}&pageInactive=${parseInt(inactive_courses.meta.current_page) + 1}`} style={{color: 'black'}}>
                            <a  className="btn rounded-circle btn-xs mx-1">
                              <i className="fa fa-angle-right"></i>
                            </a>
                          </Link>
                          <Link to={`${inactive_courses.meta.url}?pageActive=${pageActive}&pageInactive=${inactive_courses.meta.total_page}`} style={{color: 'black'}}>
                            <a className="btn rounded-circle btn-xs mx-1">
                              <i className="fa fa-angle-double-right"></i>
                            </a>
                          </Link>
                        </>
                      }
                    </div>
                    {/* <Pagination
                      ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                      firstItem={{ content: <i className='fa fa-angle-double-left' />, icon: true }}
                      lastItem={{ content: <i className='fa fa-angle-double-right' />, icon: true }}
                      prevItem={{ content: <i className='fa fa-angle-left' />, icon: true }}
                      nextItem={{ content: <i className='fa fa-angle-right' />, icon: true }}
                      defaultActivePage={active_courses.meta.current_page}
                      totalPages={active_courses.meta.last_page}
                      onPageChange={props.handlePaggination} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </>
  )
}

export default ListPelatihan;
