import React from 'react'
import PropTypes from 'prop-types'

export const CustomPrevButton = props => {
    const { page, handlePrevClick } = props
    if (page === 1) return <div />

    return (
        <h6
            style={{
                cursor: 'pointer',
                display: 'inline-block',
                marginRight: 24,
                marginTop: 0,
            }}
            onClick={handlePrevClick}>
            <i className="fa fa-chevron-circle-left"></i>
        </h6>
    )
}
CustomPrevButton.propTypes = {
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    handlePrevClick: PropTypes.func.isRequired,
}

export const CustomNextButton = props => {
    const { page, pages, handleNextClick } = props
    if (page === pages) return <div />

    return (
        <h6
            style={{
                cursor: 'pointer',
                display: 'inline-block',
                marginLeft: 24,
                marginTop: 0,
            }}
            onClick={handleNextClick}>
            <i className="fa fa-chevron-circle-right"></i>
        </h6>
    )
}
CustomNextButton.propTypes = {
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    handleNextClick: PropTypes.func.isRequired,
}

export const CustomPages = props => {
    const { page, pages } = props
    return (
        <h6 style={{ display: 'inline-block', marginTop: 0 }}>
            Page {page} from {pages}
        </h6>
    )
}
CustomPages.propTypes = {
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
}

const CustomNavigation = (props, user) => {
    const { page, pages } = props

    const { handlePrevClick, handleNextClick } = props

    return (
        <div className='row px-4 py-3'>
            <div className='col-md-6'>
                <div className='d-flex mb-2 align-items-center'>
                    <div>
                        <img class="img-40 rounded-circle" src="https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg" alt="#"></img>
                    </div>
                    <label className='mb-0 ml-2'>
                        <h6 className='mb-0' style={{fontWeight:'600', fontSize:'13px'}}>{user.name}</h6>
                        <p className='mb-0' style={{fontSize:'10px'}}>{user.role} dari {user.perusahaan}</p>
                    </label>
                </div>
            </div>
            <div className='col-md-6'>
                <div className='customWrapper'>
                    <CustomPrevButton
                        page={page}
                        pages={pages}
                        handlePrevClick={handlePrevClick}
                    />
                    <CustomPages page={page} pages={pages} />
                    <CustomNextButton
                        page={page}
                        pages={pages}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </div>
        </div>
    )
}
CustomNavigation.propTypes = {
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    handlePrevClick: PropTypes.func.isRequired,
    handleNextClick: PropTypes.func.isRequired,
}

export default CustomNavigation