import React from 'react';
import { PrivateRoute } from '../../component';
import {
    Switch,
    Route,
    useRouteMatch
  } from "react-router-dom";
import DashboardTrainer from './dashboard';
import ListPelatihan from './listPelatihan';
import SertifikatCek from './sertifikatCek';
import DetailPelatihan from './detailPelatihan';
import PesertaSaya from './pesertaSaya';
import DetailPeserta from './detailPeserta';
import ProgressPeserta from './progressPeserta';
import Quiz from './quiz';
import QuizPembahasan from './quizPembahasan';
import TugasFinal from './tugasFinal';
import Chat from './chat';
import ProfilSaya from './profilSaya';
import DetailPelatihanCourse from './detailPelatihanCourse';
import VirtualClassConfig from './virtualClassConfig';
import AboutUs from './aboutUs';

const Trainer = () => {
   const { path } = useRouteMatch();

    return(
        <Switch>
            <PrivateRoute
                routeAs='trainer'
                redirectPath='/1/trainer/login'
                role_id={4}
            >
                <div className="container-fluid">
                    <Route exact path={path}>
                       <DashboardTrainer />
                    </Route>
                    <Route exact path={`${path}/about-us`}>
                       <AboutUs />
                    </Route>
                    <Route exact path={`${path}/list-pelatihan`}>
                       <ListPelatihan />
                    </Route>
                    <Route exact path={`${path}/detail-pelatihan`}>
                       <DetailPelatihan />
                    </Route>
                    <Route exact path={`${path}/cek-sertifikat`}>
                       <SertifikatCek />
                    </Route>
                    <Route exact path={`${path}/peserta-saya`}>
                       <PesertaSaya />
                    </Route>
                    <Route exact path={`${path}/peserta-detail`}>
                       <DetailPeserta />
                    </Route>
                    <Route exact path={`${path}/progress-peserta`}>
                       <ProgressPeserta />
                    </Route>
                    <Route exact path={`${path}/quiz`}>
                       <Quiz />
                    </Route>
                    <Route exact path={`${path}/quiz/pembahasan`}>
                       <QuizPembahasan />
                    </Route>
                    <Route exact path={`${path}/tugas-final`}>
                       <TugasFinal />
                    </Route>
                    <Route exact path={`${path}/chat`}>
                       <Chat />
                    </Route>
                    <Route exact path={`${path}/profil-saya`}>
                       <ProfilSaya />
                    </Route>
                    <Route exact path={`${path}/pelatihan-detail-lainnya`}>
                       <DetailPelatihanCourse />
                    </Route>
                    <Route exact path={`${path}/virtual-class-config`}>
                        <VirtualClassConfig />
                    </Route>
                </div>
            </PrivateRoute>
        </Switch>
    )
}

export default Trainer;
