import React, { useState, useEffect } from "react";
import { BreadcrumbComp } from '../../../component';
import { Link, useHistory } from 'react-router-dom'
import API from "../../../api";

const AboutUs = () => {
    const history = useHistory();
    let user = JSON.parse(localStorage.getItem('data'));
    const [aboutUs, setAboutUs] = useState(null);

    useEffect(() => {
        API.about_us.get(user.company_id)
            .then(json => {
                setAboutUs(json.about_us);
            }).catch(e => {
                history.push('/admin/about-us/edit');
            })
    }, [])

    return (
        <>
            <div className="page-header">
                <BreadcrumbComp
                    title={'About us'}
                    menuRight={
                        <>
                            <Link to='/admin/about-us/edit'>
                                <button className="btn btn-outline-warning btn-sm bg-white mr-3" type="button">
                                    <label className='d-flex align-items-center mb-0'>
                                        <p className='mb-0 font-weight-bold ml-2'>
                                            Edit
                                        </p>
                                    </label>
                                </button>
                            </Link>
                        </>
                    }
                />
            </div>
            <Link to='/admin'>
                <p className='p-0 text-theme'>
                    <i className="fa fa-arrow-left"></i>&nbsp;Kembali
                </p>
            </Link>
            {aboutUs && <>
                <div className="w-50 card p-5 mt-4">
                    <img src={aboutUs.image} style={{borderRadius: 25}} alt="Omind Tech" className="w-100"/>
                    <div className="mt-5">
                        <h3 className="text-work" style={{fontSize: "24px"}}>About Us</h3>
                        <span className="text-justify" style={{fontSize: "14px"}} dangerouslySetInnerHTML={{__html: aboutUs.about_us}}>
              </span>
                    </div>
                </div>
                <div className="w-50 card p-5 mt-4">
                    <h3 className="text-work text-muted" style={{fontSize: "24px", fontWeight: "600"}}>Pesan Manajemen</h3>
                    <div className='d-flex align-items-center mb-2 py-2'>
                        <div>
                            <img className="img-50 rounded-circle" style={{height: 50, objectFit: 'cover'}} src={aboutUs.ceo_photo}
                                 alt="#"/>
                        </div>
                        <label className='mb-0 ml-3'>
                            <h6 className='font-weight-bold mb-0' style={{fontSize: "18px"}}>{aboutUs.ceo_name}</h6>
                            <p className="text-muted font-weight-bold" style={{fontSize: "14px"}}>CEO SDM</p>
                        </label>
                    </div>
                    <span className="text-justify" style={{fontSize: "14px"}} dangerouslySetInnerHTML={{__html: aboutUs.about_ceo}}>
            </span>
                </div>
            </>}

        </>
    )
}

export default AboutUs;
