import Post from "./Post";
import Get from "./Get";

const login = (data) => Post(`user/login`, data);
const register = (data) => Post(`user/register`, data);
const verify_email = (data) => Post(`user/verify_email`, data);
const forgot_password = (data) => Post(`user/password/forgot`, data);
const forgot_password_check = (data) => Post(`user/password/forgot/check_code`, data);
const forgot_password_new = (data) => Post(`user/password/forgot/new_password`, data);
const verify_phone = (phone, data) => Post(`user/verify_phone_number?phone_number=${phone}`, data);
const forgot_password_resend = (data) => Post(`user/password/forgot/resend`, data);
const get_professions = () => Get(`professions`);
const get_company = id => Get(`company/${id}`);
const snap_token = data => Post(`pay`, data);
const set_payment_method = data => Post(`set-payment-method`, data);

const User = {
    login,
    register,
    verify_email,
    forgot_password,
    forgot_password_check,
    forgot_password_new,
    verify_phone,
    forgot_password_resend,
    get_professions,
    get_company,
    snap_token,
    set_payment_method
};

export default User;
