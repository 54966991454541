import swal from "sweetalert2";

export function numberWithDots(x) {
    return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function minToday() {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();

    return year + '-' + month + '-' + day;
}

export function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function convertDate(date, reverse = false, toString = false, withDay = true){
    let dateraw = date;
    date = date.split('-');

    if(reverse)
        date = date.reverse();

    let newDate = '';

    if(toString){
        switch (date[1]){
            case '01': date[1] = 'Januari'; break;
            case '02': date[1] = 'Februari'; break;
            case '03': date[1] = 'Maret'; break;
            case '04': date[1] = 'April'; break;
            case '05': date[1] = 'Mei'; break;
            case '06': date[1] = 'Juni'; break;
            case '07': date[1] = 'Juli'; break;
            case '08': date[1] = 'Agustus'; break;
            case '09': date[1] = 'September'; break;
            case '10': date[1] = 'Oktober'; break;
            case '11': date[1] = 'November'; break;
            case '12': date[1] = 'Desember'; break;
        }

        newDate = date.join(' ');
    }
    else{
        newDate = date.join('-');
    }

    let day = new Date(dateraw);
    day = day.getDay();

    switch (day){
        case 0 : day = 'Minggu'; break;
        case 1 : day = 'Senin'; break;
        case 2 : day = 'Selasa'; break;
        case 3 : day = 'Rabu'; break;
        case 4 : day = 'Kamis'; break;
        case 5 : day = 'Jumat'; break;
        case 6 : day = 'Sabtu'; break;
    }

    if(withDay)
        return `${day}, ${newDate}`;
    else
        return newDate;
}

export function pusher_app_key() {
    return 'c7a0ca8b6e06e8644357';
}

export function checkEmpty(data, varnames){
    let errors = [];

    varnames.map(v => {
        if(!data.hasOwnProperty(v.name))
            errors.push(v.alias);
        else
            if(data[v.name] === null || data[v.name] === '')
                errors.push(v.alias);
    });

    if(errors.length > 0){
        swal.fire('Error', `${errors.join(', ')} harus diisi`, 'error');
        return false;
    }
    else
        return true;
};
