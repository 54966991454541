import React, {useState, useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom'
import { Home } from 'react-feather';
import { BreadcrumbComp } from '../../../component';
import API from "../../../api";
import swal from 'sweetalert2';

const VirtualClassConfig = () => {
  const query = new URLSearchParams(useLocation().search);
  const id = query.get('id');

  const [courseName, setCourseName] = useState('...');
  const [virtualClass, setVirtualClass] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    API.trainer.course.get_schedule_course(id)
        .then(json => {
          setVirtualClass(json.data.schedules);
          setCourseName(json.data.course);
        })
  }, []);

  const handleSaveSchedule = (data) => {
    if(!data.vc_link || !data.vc_meeting_id || !data.vc_password){
      swal.fire('Gagal', 'Data meeting harus diisi semua', 'error');
      return
    }

    setIsSubmitting(true);
    API.trainer.course.update_meeting(data.id, {
      vc_link: data.vc_link,
      vc_meeting_id: data.vc_meeting_id,
      vc_password: data.vc_password,
    }).then(json => {
      swal.fire('Sukses', 'Berhasil mengubah data meeting', 'success');
      setIsSubmitting(false);
    }).catch(e => {
      swal.fire('Gagal', 'Berhasil mengubah data meeting', 'error');
      setIsSubmitting(false);
    })
  };

  const handleChangeValue = (e, schedule_key, meeting_key) => {
    let newVirtualClass = [...virtualClass];

    newVirtualClass[schedule_key].meetings[meeting_key] = {
      ...newVirtualClass[schedule_key].meetings[meeting_key],
      [e.target.name] : e.target.value
    };

    setVirtualClass(newVirtualClass);
  };

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Pengaturan Virtual Class'}>
          <li className="breadcrumb-item">
            <Link to="/trainer" className='text-warning'>
              <Home />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Saya</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header d-flex">
              <div className='mr-auto'>
                <h5 className='text-warning'>VIRTUAL CLASS</h5>
                <span className='text-muted'>{courseName}</span>
              </div>
            </div>
            {
              virtualClass.map((v, s_key) => (
                  <div className="card-body">
                    <div className='ml-auto badge text-wrap text-capitalize badge-warning text-white mb-3'>Jadwal {v.name}</div>
                    {
                      v.meetings.map((val, m_key) => (
                          <>
                            <h6 style={{ fontWeight: '600', fontSize: '20px' }}>{val.name}</h6>
                            <p style={{ fontSize: '14px' }}>{val.start_meeting.substr(0, 5)} - {val.end_meeting.substr(0, 5)} WIB &nbsp; &nbsp; {val.date}</p>
                            <form className="mb-5">
                              <div className="form-group">
                                <h6 className='text-dark text-work' style={{fontSize:'16px'}}>Link Virtual Group</h6>
                                <input name='vc_link' type="text" className="form-control" style={{width:'343px'}} placeholder='Paste link meeting di sini... ' value={val.vc_link} onChange={e => handleChangeValue(e, s_key, m_key)} />
                              </div>
                              <div className="form-group">
                                <h6 className='text-dark text-work' style={{fontSize:'16px'}}>Meeting ID</h6>
                                <input name='vc_meeting_id' type="text" className="form-control" style={{width:'250px'}} placeholder='Masukkan Meeting ID di sini...' value={val.vc_meeting_id} onChange={e => handleChangeValue(e, s_key, m_key)} />
                              </div>
                              <div className="form-group">
                                <h6 className='text-dark text-work' style={{fontSize:'16px'}}>Password</h6>
                                <input name='vc_password' type="text" className="form-control" style={{width:'343px'}} placeholder='Masukkan Password meeting...' value={val.vc_password} onChange={e => handleChangeValue(e, s_key, m_key)} />
                              </div>
                              <button disabled={isSubmitting} className=" btn btn-pill btn-primary btn-sm mt-3" type="button" onClick={() => handleSaveSchedule(val)}>{isSubmitting ? 'Menyimpan jadwal...' : 'Simpan Jadwal'}</button>
                            </form>
                          </>
                      ))
                    }

                  </div>
              ))
            }

          </div>
        </div>
      </div>
    </>
  )
}

export default VirtualClassConfig;
