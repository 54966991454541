import React, { useState, useEffect, useRef } from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link, useHistory } from "react-router-dom";
import {Edit, User} from 'react-feather';
import DatePicker from "react-datepicker";
import {formatDate, titleCase} from "../../../helper";
import API from "../../../api";
import swal from 'sweetalert2';
import man from '../../../assets/images/dashboard/user.png'

const ProfilSaya = () => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('data'));

  const [url, setUrl] = useState(user.profile_picture ? user.profile_picture.replace(/ /g, '%20') : man);
  const [isSubmittingAccount, setIsSubmittingAccount] = useState(false);
  const [isSubmittingProfile, setIsSubmittingProfile] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);

  const [DataAkun, setDataAkun] = useState({
    email: user.email,
    no_hp: user.phone_number,
    password: '',
    confirm_password: ''
  });

  const [profile, setProfile] = useState({
    ktp: user.ktp && 0,
    nik: user.nik,
    address: user.address,
    date_of_birth : user.date_of_birth ? new Date(user.date_of_birth) : new Date(),
    region_id: user.region_id,
    postal_code: user.postal_code,
    profession_id: user.profession_id,
    office_address: user.office_address
  });
  const [listProvinsi, setListProvinsi] = useState([]);
  const [listKota, setListKota] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);
  const [listPekerjaan, setListPekerjaan] = useState([]);

  const [imagePreview, setimagePreview] = useState(null);
  const [imagePreviewUrl, setimagePreviewUrl] = useState(user.ktp);

  const photoRef = useRef(null);

  const handleAkun = e => {
    let data = {...DataAkun};
    data = {
      ...data,
      [e.target.name]: e.target.value
    };
    setDataAkun(data);
  };

  const handleProfil = e => {
    let data = {...profile};
    data = {
      ...data,
      [e.target.name]: e.target.value
    };
    setProfile(data);
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    setProfile({
      ...profile,
      [e.target.name]: file
    });

    reader.onloadend = () => {
      setimagePreviewUrl(reader.result);
    }

    reader.readAsDataURL(file)
  };

  useEffect(() => {
    API.peserta.participant.get_update_profile({region_id: user.region_id})
        .then(json => {
          let data = json.data;

          setListPekerjaan(data.professions);

          setListProvinsi(data.list_provinsi);
          setListKota(data.list_kota);
          setListKecamatan(data.list_kecamatan);

          let dataProfile = {...profile};
          dataProfile = {
            ...dataProfile,
            province_id: data.kecamatan.province_id,
            city_id: data.kecamatan.city_id,
            region_id: data.kecamatan.subdistrict_id,
          };
          setProfile(dataProfile);

        })
  }, []);

  useEffect(() => {
    if (imagePreviewUrl) {
      setimagePreview(<img className="w-100" src={imagePreviewUrl} alt='Global English' />)
    } else {
      setimagePreview(<div className=""></div>);
    }
  }, [imagePreviewUrl])

  const readUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    let file = event.target.files[0];

    setDataAkun({
      ...DataAkun,
      [event.target.name]: file
    });

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result)
    }
  }
  return (
      <>
        <div className="page-header">
          <BreadcrumbComp title={'Profil Saya'}>
            <li className="breadcrumb-item">
              <Link to="/trainer/profil-saya" className='text-warning'>
                <User />
              </Link>
            </li>
            <li className="breadcrumb-item">Profil Saya</li>
          </BreadcrumbComp>
        </div>
        <div className='row'>
          <div className='col-md-5'>
            <div className="card">
              <div className='card-header'>
                <h6 style={{ fontSize: '24px', fontWeight: 'normal' }}>Akun Saya</h6>
              </div>
              <div className='card-body'>
                <div className='d-flex align-items-center user-profile'>
                  <div className="user-image">
                    <div className="avatar ">
                      <img className="img-70 rounded-circle" style={{height: 70, objectFit: 'cover'}} alt="" src={url ? url : "https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg"} data-intro="This is Profile image" />
                      <div className="profile-edit">
                        <Link onClick={e => {
                          e.stopPropagation();
                          photoRef.current.click();
                        }} style={{
                          position: 'relative',
                          bottom: 28,
                          right: -45,
                          padding: 5,
                          background: '#fff',
                          borderRadius: 30,
                          boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.13)',
                          display: 'inline-block',
                          alignItems:'center'}}>
                          <Edit width='18' height='18' />
                        </Link>
                        <input
                            type="file"
                            name='profile_picture'
                            ref={photoRef}
                            onChange={readUrl}
                            style={{display: 'none'}}
                        />
                      </div>
                    </div>
                    {/*<div className="icon-wrapper">
                      <i className="icofont icofont-pencil-alt-5" data-intro="Change Profile image here" >
                          <input className="pencil" type="file" onChange={(e) => readUrl(e)} />
                      </i>
                    </div>*/}
                  </div>
                  <label className='mb-0 ml-4'>
                    <h6 className='mb-0' style={{ fontSize: '24px', fontWeight: 'normal' }}>{user.name}</h6>
                    <h6 className='mb-0' style={{ fontSize: '18px', fontWeight: '600' }}>{titleCase(user.role.name)}</h6>
                    <p className='mb-0'>{user.company_name}</p>
                  </label>
                </div>
                <form className="form theme-form mt-4" onSubmit={e => {
                  e.preventDefault();
                  setIsSubmittingAccount(true);

                  if(DataAkun.password !== DataAkun.confirm_password)
                    swal.fire('Gagal', 'Password tidak sama', 'error');
                  else{
                    let data = new FormData();
                    if(DataAkun.profile_picture)
                      data.append('profile_picture', DataAkun.profile_picture);

                    data.append('email', DataAkun.email);
                    data.append('phone_number', DataAkun.no_hp);

                    if(DataAkun.password)
                      data.append('password', DataAkun.password);

                    API.peserta.participant.update_account(data).then(json => {
                      json.data.profile_picture = json.data.photo;
                      let newUser = {
                        ...user,
                        ...json.data
                      };

                      localStorage.setItem('data', JSON.stringify(newUser));
                      swal.fire('Sukses', 'Sukses ubah akun', 'success').then((result) => {
                        if (result.isConfirmed) {
                          history.push('/');
                        }
                      });
                      setIsSubmittingAccount(false);
                    }).catch(e => {
                      swal.fire('Gagal', e.message, 'error');
                      setIsSubmittingAccount(false);
                    });
                  }

                }}>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>Email</label>
                        <input name='email' onChange={handleAkun} className="form-control text-dark" type="email" placeholder="name@example.com" defaultValue={DataAkun.email} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1" className='text-work d-flex' style={{fontSize:'16px'}}>Nomor Telepon <a className='ml-auto btn p-0 txt-primary'><u>Verifikasi No Telepon</u></a></label>
                        <input name='no_hp' onChange={handleAkun} className="form-control text-dark" type="number" defaultValue={DataAkun.no_hp} required />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-check mb-3">
                        <input className="form-check-input" type="checkbox" id="defaultCheck1" onClick={() => setIsChangePassword(!isChangePassword)} />
                        <label className="form-check-label" htmlFor="defaultCheck1">
                          Ganti Password
                        </label>
                      </div>
                    </div>
                  </div>
                  {
                    isChangePassword &&
                      <>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>Password</label>
                              <input name='password' onChange={handleAkun} className="form-control text-dark" type="password" defaultValue={DataAkun.password} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>Konfirmasi Password</label>
                              <input name='confirm_password' onChange={handleAkun} className="form-control text-dark" type="password" defaultValue={DataAkun.confirm_password} />
                            </div>
                          </div>
                        </div>
                      </>
                  }
                  <button className="mr-3 btn btn-pill btn-primary btn-sm mt-3 w-100 text-work" type="submit" style={{fontSize:'16px', fontWeight:'600'}} disabled={isSubmittingAccount}>{isSubmittingAccount ? 'Menyimpan...' : 'Simpan'}</button>
                </form>
              </div>
            </div>
          </div>
          <div className='col-md-7'>
            <div className="card">
              <div className='card-header'>
                <h6 style={{ fontSize: '24px', fontWeight: 'normal' }}>Ubah Data Profil</h6>
              </div>
              <div className='card-body'>
                <form className="form theme-form" onSubmit={e => {
                  e.preventDefault();
                  setIsSubmittingProfile(true);
                  API.peserta.participant.update_profile({
                    ...profile,
                    date_of_birth: formatDate(profile.date_of_birth)
                  }).then(json => {
                    let newUser = {
                      ...user,
                      ...json.data,
                    };

                    localStorage.setItem('data', JSON.stringify(newUser));
                    setIsSubmittingProfile(false);
                    swal.fire('Sukses', 'Sukses update profil', 'success').then((result) => {
                      if (result.isConfirmed) {
                        history.push('/');
                      }
                    });;
                  }).catch(e => {
                    setIsSubmittingProfile(false);
                    swal.fire('Gagal', e.message, 'error');
                  });
                }}>
                  <div className="row">

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>KTP</label>
                        <div className="w-100 mt-3" >
                          {imagePreview}
                        </div>
                        {
                          !user.ktp && <input className="form-control text-dark" name='ktp' type="file" onChange={handleImageChange} />
                        }
                        <p className='txt-danger mt-3'>*KTP hanya bisa diupload sekali, pastikan data tersebut adalah benar</p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>NIK KTP</label>
                        <input className="form-control text-dark" type="number" name='nik' defaultValue={profile.nik} onChange={handleProfil} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>Tanggal Lahir</label>
                        <DatePicker
                            name='date_of_birth'
                            dateFormat="dd/MM/yyyy"
                            selected={profile.date_of_birth}
                            className='w-100 form-control'
                            onChange={v => {
                              let data = {...profile};
                              data = {
                                ...data,
                                date_of_birth: v
                              };
                              setProfile(data);
                            }}
                        />
                      </div>
                    </div>
                  </div>
                  <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>Alamat</label>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <select disabled={listProvinsi.length === 0} defaultValue='default' className='form-control' name='province_id' onChange={e => {
                          handleProfil(e);

                          setListKota([]);
                          setListKecamatan([]);

                          setProfile({
                            ...profile,
                            city_id: null,
                            region_id: null
                          });

                          API.rajaongkir.list_kota(e.target.value)
                              .then(json => {
                                setListKota(json);
                              });
                        }} required>
                          <option selected={!profile.province_id} disabled>Provinsi</option>
                          {
                            listProvinsi.map(v => (
                                <option value={v.province_id} selected={v.province_id === profile.province_id}>{v.province}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <select disabled={listKota.length === 0} defaultValue='default' className='form-control' name='city_id'
                                onChange={e => {
                                  handleProfil(e);
                                  setListKecamatan([]);

                                  setProfile({
                                    ...profile,
                                    region_id: null
                                  });

                                  API.rajaongkir.list_kecamatan(e.target.value)
                                      .then(json => {
                                        setListKecamatan(json);
                                      });
                                }} required>
                          <option selected={!profile.city_id} disabled>Kota / Kabupaten</option>
                          {
                            listKota.map(v => (
                                <option value={v.city_id} selected={v.city_id === profile.city_id}>{`${v.type} ${v.city_name}`}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <select disabled={listKecamatan.length === 0} defaultValue='default' className='form-control' name='region_id' onChange={handleProfil} required>
                          <option selected={!profile.region_id} value='default'>Kecamatan</option>
                          {
                            listKecamatan.map(v => (
                                <option value={v.subdistrict_id} selected={v.subdistrict_id === profile.region_id}>{v.subdistrict_name}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <input required type="number" className='form-control' name='postal_code' placeholder='Kode Pos' value={profile.postal_code} onChange={handleProfil} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                      <textarea required placeholder='Nama Jalan / Alamat Detail' className='form-control' name='address' onChange={handleProfil}>
                        {profile.address}
                      </textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <select required defaultValue='default' className='form-control' name='profession_id' onChange={handleProfil}>
                          <option selected={!profile.profession_id} disabled>Pekerjaan</option>
                          {
                            listPekerjaan.map(v => (
                                <option value={v.id} selected={v.id === profile.profession_id}>{titleCase(v.name)}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1" className='text-work' style={{fontSize:'16px'}}>Alamat Kantor </label>
                        <textarea required placeholder='Nama Jalan / Alamat Detail' className='form-control' name='office_address' onChange={handleProfil}>
                        {profile.office_address}
                      </textarea>
                      </div>
                    </div>
                  </div>
                  <button className="mr-3 btn btn-pill btn-primary btn-sm mt-3 w-100 text-work" type="submit" style={{fontSize:'16px', fontWeight:'600'}} disabled={isSubmittingProfile}>{isSubmittingProfile ? 'Submitting... ' : 'Update Profil'}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}

export default ProfilSaya;
