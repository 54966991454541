import React, { useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import { BreadcrumbComp, TableComp } from '../../../component';
import { Layers } from 'react-feather';
import { Collapse } from 'reactstrap';
import PelatihanPPTView from './pptView';
import PelatihanVideoView from './videoView';
import PelatihanPDFView from './pdfView';
import PelatihanKuisView from './kuisView';
import PelatihanTugasFinalView from './tugasFinalView';
import PelatihanVirtualClassView from './virtualClassView';
import PelatihanFaceToFaceView from './faceToFaceView';
import API from "../../../api";

const PelatihanDetailMulai = () => {
  let query = new URLSearchParams(useLocation().search);
  let id = query.get('id');

  const [position, setposition] = useState(4);
  const [checkedValue, setcheckedValue] = useState(6);

  const [accordions, setAccordion] = useState([]);

  const [Pengenalan, setPengenalan] = useState(false);
  const [Rahasia, setRahasia] = useState(false);
  const [Teknik, setTeknik] = useState(false);
  const [course, setCourse] = useState(null);
  const [theory, setTheory] = useState(null);
  const [totTheory, setTotTheory] = useState([]);


  useEffect(() => {
    API.peserta.course.course_detail(id)
      .then(json => {
        setCourse(json.data);

        let accordion = [];
        let tottheory = [];
        let i = 1;

        if (json.data.schedule_id) {
          json.data.course_schedule.meeting.map(val => {
            tottheory.push(i - 1);
            i += json.data.course_schedule.meeting.length;
            accordion.push(false);
          });
        }


        json.data.course_contents.map(val => {
          tottheory.push(i - 1);
          i += val.theory.length;
          accordion.push(false);
        });

        setTotTheory(tottheory);
        setAccordion(accordion);
      })
  }, []);

  const handleChangeView = (value, theory) => {
    /* if (checkedValue >= value) {
       setposition(value);
       let teori = {...theory};
       teori.file = teori.file.replace(/ /g,"%20");
       setTheory(teori);
     }*/

    setposition(value);
    let teori = { ...theory };
    if (teori.file)
      teori.file = teori.file.replace(/ /g, "%20");
    setTheory(teori);
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Pelatihan Saya'}>
          <li className="breadcrumb-item">
            <Link to="/pelatihan-saya" className='text-warning'>
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Saya</li>
          <li className="breadcrumb-item active">Pelatihan Detail</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className='col-md-7'>
          <div className='card p-5'>
            <div className='w-100 border rounded mb-5'>
              {
                theory ? (
                  theory.type === 'Video' ?
                    <PelatihanVideoView
                      url={theory.file}
                      user={{
                        name: course.course_id.trainer_id.name,
                        img: course.course_id.trainer_id.profile_picture,
                        role: 'Trainer',
                        perusahaan: course.course_id.company_id.name
                      }}
                    /> :
                    theory.type === 'Slide Powerpoint' ?
                        <PelatihanPDFView link={theory.file.replace(/ /g, "%20")} name={theory.name} /> :
                      theory.type === 'E-Book' ?
                        <PelatihanPDFView link={theory.file.replace(/ /g, "%20")} name={theory.name} /> :
                        theory.type === 'Quiz' ?
                          <PelatihanKuisView id={theory.stat_theory_id} attempt={theory.attempt} name={theory.name} res={theory.result} isSuccess={theory.is_accessed} /> :
                          theory.type === 'Tugas Final' ?
                            <PelatihanTugasFinalView id={theory.stat_theory_id} /> :
                            course.course_id.type === 'Virtual Class' ? <PelatihanVirtualClassView data={theory} /> :
                              course.course_id.type === 'Face to Face' ? <PelatihanFaceToFaceView data={theory} schedule={course.course_schedule} /> :
                                course.course_id.type === 'Enquiries' ? <PelatihanFaceToFaceView data={theory} schedule={course.course_schedule} /> : ''
                ) : course &&
                <>
                    <img src={course.course_id.thumbnail.replace(/ /g,"%20")} className='w-100' alt=""/>
                    <h3 className='text-center p-3'>{course.course_id.name}</h3>
                </>
              }
            </div>
            {/*{theory.type === 'Video' || theory.type === 'Slide Powerpoint' || theory.type === 'E-Book' &&
              <div>
                <h6 style={{ fontWeight: '600', fontSize: '18px' }}>Deskripsi</h6>
                <p className='text-justify'>Seperti yang sudah dipelajari dalam materi turunan, variabel dalam suatu fungsi mengalami penurunan pangkat. Berdasarkan contoh tersebut, diketahui bahwa ada banyak fungsi yang memiliki hasil turunan yang sama yaitu yI = 3x2. Fungsi dari variabel x3 ataupun fungsi dari variabel x3 yang ditambah atau dikurang suatu bilangan (misal contoh: +8, +17, atau -6) memiliki turunan yang sama. </p>
                <p className='text-justify'>Seperti yang sudah dipelajari dalam materi turunan, variabel dalam suatu fungsi mengalami penurunan pangkat. Berdasarkan contoh tersebut, diketahui bahwa ada banyak fungsi yang memiliki hasil turunan yang sama yaitu yI = 3x2. Fungsi dari variabel x3 ataupun fungsi dari variabel x3 yang ditambah atau dikurang suatu bilangan (misal contoh: +8, +17, atau -6) memiliki turunan yang sama. </p>
              </div>
            }*/}
          </div>
        </div>

        <div className='col-md-5'>
          {
            (course && course.course_id.type !== 'Online') && course.schedule_id &&
            <div className='card p-5'>
              <h6 className='text-muted mb-3' style={{ fontWeight: '600', fontSize: '22px' }}>Jadwal Pertemuan</h6>
              <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        {
                          course.course_schedule.meeting.map((t, key) => {
                            return <div className="checkbox p-0">
                              <input id="checkbox1" type="checkbox" checked={t.is_accessed} />
                              <label htmlFor="checkbox1" onClick={() => {
                                /*if(!t.is_accessed && !['Quiz', 'Tugas Final'].includes(t.type)){
                                    let newCourse = {...course};
                                    newCourse.course_schedule.meeting[key].is_accessed = true;
                                    setCourse(newCourse);
                                }*/

                                handleChangeView(totTheory[key], t)
                              }}>
                                <p className={position === totTheory[key] ? `text-theme mb-0` : `text-dark mb-0`}>{t.name}</p>
                                <p className={position === totTheory[key] ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i className='fa fa-clipboard' /></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{course.course_id.type}</p>
                              </label>
                            </div>
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            course &&
            <div className='card p-5'>
              <h6 className='text-muted mb-3' style={{ fontWeight: '600', fontSize: '22px' }}>Topik yang dibahas</h6>
              {
                course.course_contents.map((val, key) => {
                  if (course.schedule_id)
                    key += course.course_schedule.meeting.length;
                  return <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-header">
                            <h5 className="mb-0">
                              <button className="btn btn-link pl-0 font-weight-bold" data-toggle="collapse"
                                onClick={() => {
                                  let accord = [...accordions];
                                  accord[key] = !accord[key];
                                  setAccordion(accord);
                                }}
                                data-target="#collapseicon" aria-expanded={accordions[key]} aria-controls="collapseicon">{val.name}</button>
                            </h5>
                          </div>
                          <Collapse isOpen={accordions[key]}>
                            <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                              <div className="card-body filter-cards-view animate-chk p-3">
                                {
                                  val.theory.map((t, k) => {
                                    return <div className="checkbox p-0">
                                      <input id="checkbox1" type="checkbox" checked={t.is_accessed} />
                                      <label htmlFor="checkbox1" onClick={() => {
                                        API.peserta.course.theory_accessed({ theory_id: t.id });
                                        if (!t.is_accessed && !['Quiz', 'Tugas Final'].includes(t.type)) {
                                          let newCourse = { ...course };
                                          newCourse.course_contents[key].theory[k].is_accessed = true;
                                          setCourse(newCourse);
                                        }

                                        handleChangeView(totTheory[key] + k, t)
                                      }}
                                        style={{ width: "85%" }}
                                      >
                                        <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}>{t.name}</p>
                                        {
                                          t.type === 'Slide Powerpoint' ?
                                            <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                              className='fa fa fa-file-powerpoint-o' /></span>&nbsp;PPT</p> :
                                            t.type === 'Video' ?
                                              <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                                className='fa fa-play-circle-o' /></span>&nbsp;Video</p> :
                                              t.type === 'E-Book' ?
                                                <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                                  className='fa fa fa-file-pdf-o' /></span>&nbsp;PDF</p> :
                                                t.type === 'Quiz' ?
                                                  <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                                    className='fa fa-pencil-square-o' /></span>&nbsp;Kuis</p> :
                                                  <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i className='fa fa-clipboard' /></span>&nbsp;Ujian Final</p>
                                        }
                                      </label>
                                    </div>
                                  })
                                }
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
          }
        </div>

      </div>
    </>
  )
}

export default PelatihanDetailMulai;
