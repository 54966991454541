import React from 'react';
import error from '../../../../../assets/images/error.png';

const CobaLagiGagalTugas = (props) => {
  const { Attempt, result, setView } = props;

  return (
    result &&
    <>
      <p className={`text-right mb-0 ml-auto mt-3 ${Attempt === 1 ? 'txt-primary' : Attempt === 2 ? 'text-warning' : 'txt-danger'}`} style={{ fontWeight: '600', fontSize: '14px' }}>Attempt {Attempt}/3</p>
      <div className='card p-5'>
        <div className='text-center p-4'>
          <div className='text-center mt-3'>
            <img className="img-fluid mb-4" src={error} alt="Omind Tech" style={{ width: '112.73px' }} />
            <h6 className='text-nunito' style={{ fontWeight: '800', fontSize: '18px' }}>Mohon Maaf! Anda Tidak Lulus Silahkan Coba Lagi</h6>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem a viverra tristique ornare velit orci orci. Mi purus augue at at pulvinar leo.</p>
            <button className="btn btn-pill btn-primary mt-2 font-weight-bold mb-3" type="button" onClick={() => setView('tugas')}> Coba Lagi </button>
            <hr />
            <h6 className='text-nunito' style={{ fontWeight: '800', fontSize: '18px' }}>Hasil Ujian Anda</h6>
            <div className='row mt-3'>
              <div className='col-md-3 text-center'>
                <h6 className='text-nunito' style={{ fontWeight: '600', fontSize: '18px' }}>Benar</h6>
                <h6 className='text-nunito' style={{ fontWeight: '800', fontSize: '18px' }}>{result.correct_answers}</h6>
              </div>
              <div className='col-md-3 text-center'>
                <h6 className='text-nunito' style={{ fontWeight: '600', fontSize: '18px' }}>Salah</h6>
                <h6 className='text-nunito' style={{ fontWeight: '800', fontSize: '18px' }}>{result.wrong_answers}</h6>
              </div>
              <div className='col-md-3 text-center'>
                <h6 className='text-nunito' style={{ fontWeight: '600', fontSize: '18px' }}>Nilai</h6>
                <h6 className='text-nunito txt-danger' style={{ fontWeight: '800', fontSize: '18px' }}>{result.score}</h6>
              </div>
              <div className='col-md-3 text-center'>
                <h6 className='text-nunito' style={{ fontWeight: '600', fontSize: '18px' }}>Keterangan</h6>
                <h6 className='text-nunito txt-danger' style={{ fontWeight: '800', fontSize: '18px' }}>Coba Lagi</h6>
              </div>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem a viverra tristique ornare velit orci orci. Mi purus augue at at pulvinar leo.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default CobaLagiGagalTugas
