import Post from "../Post";
import Get from "../Get";

const others = (data = null) => Get(`peserta/course/other_course?per_page=9999&${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const store_detail = (id) => Get(`peserta/course/store_course_detail/${id}`);
const add_to_cart = (id, data) => Post(`peserta/course/add_cart/${id}`, data);
const get_cart = () => Get(`peserta/course/get_cart?per_page=9999`);
const delete_cart = id => Post(`peserta/course/delete_cart/${id}`);
const check_certificate = data => Post(`peserta/course/check_certificate`, data);
const my_certificate = () => Get(`peserta/course/my_certificate?per_page=9999`);
const my_course = () => Get(`peserta/course/my_course?per_page=9999`);
const course_detail = id => Get(`peserta/course/course_detail/${id}`);
const history_transaction = () => Get(`peserta/course/history_transaction`);
const request_admin_authorization = data => Post(`peserta/course/request_admin_authorization`, data);
const show_quiz = id => Get(`peserta/course/show_quiz/${id}?per_page=999`);
const answer_quiz = (id, data) => Post(`peserta/course/answer_quiz/${id}`, data);
const use_promo = data => Post(`peserta/course/use_promo`, data);
const theory_accessed = data => Post(`peserta/course/theory_accessed`, data);
const meeting_accessed = data => Post(`peserta/course/meeting_accessed`, data);
const show_final_task = id => Get(`peserta/course/show_final_task/${id}`);
const comment_final_task = (id, data) => Post(`peserta/course/comment_final_task/${id}`, data);
const answer_final_task = (id, data) => Post(`peserta/course/answer_final_task/${id}`, data);
const check_final_task = (id) => Get(`peserta/course/check_final_task/${id}`);
const request_course = (id) => Post(`peserta/course/request_course/${id}`);
const discussion_quiz = (id) => Get(`peserta/course/discussion_quiz/${id}`);

const CoursePeserta = {
    others,
    store_detail,
    add_to_cart,
    get_cart,
    delete_cart,
    check_certificate,
    my_certificate,
    my_course,
    course_detail,
    history_transaction,
    request_admin_authorization,
    show_quiz,
    answer_quiz,
    use_promo,
    theory_accessed,
    meeting_accessed,
    show_final_task,
    comment_final_task,
    answer_final_task,
    check_final_task,
    request_course,
    discussion_quiz
};

export default CoursePeserta;
