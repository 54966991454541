import React from "react";
import { Link } from 'react-router-dom';
import bca_logo from '../../../assets/images/bca-logo.png';

const LihatTagihan = () => {
  return (
    <>
    <div style={{marginBottom:'7rem'}}></div>
      <div className="mt-5 card rounded-0 py-3 shadow">
        <div className="container-fluid">
          <Link to='/admin/transaksi-perusahaan'>
            <p className='btn p-0 text-theme mb-0'>
              <i className="fa fa-arrow-left"></i> Kembali ke transaksi
            </p>
          </Link>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <div className='badge badge-warning w-100 py-3' style={{ fontSize: '18px', backgroundColor: 'rgba(252, 255, 86, 0.62)', border: '1px solid #EFF400' }}>
              <span className='font-weight-normal'>Batas waktu pembayaran akan berakhir dalam </span>6 jam 5 menit 20 detik</div>
          </div>
          <div className='col-md-9'>
            <div className='card p-4'>
              <h3 className='font-weight-bold'>Detail Pesanan</h3>
              <label className='d-flex mt-3'>
                <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Sisa Waktu Pembayaran:</p>
                <p className='mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>Kamis, 20 Oktober 2020 17:00 WIB</p>
              </label>
              <label className='d-flex mt-3'>
                <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Pembayaran:</p>
                <p className='mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>BCA Virtual Account</p>
              </label>

              <div className='row w-75 mx-auto my-3'>
                <div className='col-md-6 text-center border-right'>
                  <img className="w-50 mb-3" src={bca_logo} alt="#"></img>
                  <p className='text-danger' style={{ fontWeight: '500', fontSize: '16px' }}>1651 1321 2315 2156</p>
                </div>
                <div className='col-md-6 text-center'>
                  <p className='mr-auto mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>Total Pembayaran</p>
                  <p className='mr-auto mb-0' style={{ fontWeight: '600', fontSize: '28px' }}>Rp400.000</p>
                </div>
              </div>

              <div>
                <p className='mr-auto mb-0' style={{ fontWeight: '600', fontSize: '18px' }}>Petunjuk</p>
                <ol className='d-block'>
                  <li>Login pada aplikasi M-BCA, masukkan PIN M-BCA.</li>
                  <li>Pilih "m-Transfer", pilih "BCA Virtual Account".</li>
                  <li>Klik "Input no. Virtual Account", lalu masukkan no BCA Virtual Account 1651 1321 2315 2156.</li>
                  <li>Klik "OK" & "Send".</li>
                  <li>Periksa data transaksi kemudian pilih "OK" kemudian masukkan PIN M-BCA Anda.</li>
                  <li>Periksa kembali data transaksi Anda, kemudian klik "OK".</li>
                </ol>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='card p-4'>
              <p className='text-muted' style={{ fontSize: '18px' }}>Detail Pembayaran</p>
              <label className='d-flex mt-3'>
                <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Total Price</p>
                <p className='mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>Rp.400.000</p>
              </label>
              <label className='d-flex'>
                <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Kupon</p>
                <p className='text-success mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>-Rp50.000</p>
              </label>
              <label className='d-flex'>
                <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Kupon</p>
                <p className='text-success mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>-Rp50.000</p>
              </label>
              <label className='d-flex border-top'>
                <p className='mr-auto mb-0' style={{ fontWeight: '600', fontSize: '18px' }}>Grand Total</p>
                <p className='mb-0' style={{ fontWeight: '600', fontSize: '18px' }}>Rp250.000</p>
              </label>
              <button className={`btn btn-pill btn-outline-primary btn-sm font-weight-bold px-3 w-100 mt-5 mb-3`} type="button">Upload Bukti Pembayaran</button>
              <button className={`btn btn-pill btn-primary btn-sm font-weight-bold px-3 w-100`} type="button">Selesai</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LihatTagihan;