import React from "react";
import { PrivateRoute } from "../../component";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CompanyDetail from "./companyDetail";
import DetailPelatihan from "./detailPelatihan";
import EditPelatihan from "./editPelatihan";
import ListPelatihan from "./listPelatihan";
import ListPelatihanGlobal from "./listPelatihanGlobal";
import ListPerusahaan from "./listPerusahaan";
import ListTrainer from "./listTrainer";
import ProgressPesertaPelatihan from "./progressPesertaPelatihan";
import TambahPelatihan from "./tambahPelatihan";
import TransaksiPeserta from "./transaksiPeserta";
import TransaksiPerPerusahaan from "./transaksiPerPerusahaan";
import ListVoucher from "./listVoucher";
import DetailTrainer from "./detailTrainer";
import Chat from "./chat";
import ProfileSaya from "./profileSaya";
import KodePromo from "./kodePromo";
import Crosspay from "./crosspay";
import LihatMateri from "./lihatMateri";
import TagihanVoucher from "./tagihanVoucher";
import SertifikatCek from "./sertifikatCek";
import PesertaTerregistrasi from "./pesertaTerregistrasi";
import DetailPesertaTerregistrasi from "./detailPesertaTerregistrasi";
import TambahPeserta from "./tambahPeserta";
import TambahBulk from "./tambahBulk";
import PelatihanPengenalan from "./pelatihanPengenalan";
import TambahPerusahaan from "./tambahPerusahaan"
import EditPerusahaan from "./editPerusahaan";
import Dashboard from "./dashboard";
import EditVoucher from "./editVouucher";
import TambahPelatihanPeserta from "./tambahPelatihanPeserta"
import UnduhInvoice from "../peserta/unduhInvoice";
import AboutUs from "./aboutUs";
import EditAboutUs from "./aboutUs/edit";
const SuperAdmin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute routeAs="superadmin" redirectPath="/superadmin/login" role_id={1}>
        <div className="container-fluid">
          <Route exact path={`${path}/about-us`}>
            <AboutUs />
          </Route>
          <Route exact path={`${path}/about-us/edit`}>
            <EditAboutUs />
          </Route>
          <Route exact path={`${path}/list-pelatihan`}>
            <ListPelatihan />
          </Route>
          <Route exact path={`${path}/list-pelatihan-global`}>
            <ListPelatihanGlobal />
          </Route>
          <Route exact path={`${path}/edit-pelatihan`}>
            <EditPelatihan />
          </Route>
          <Route exact path={`${path}/tambah-pelatihan`}>
            <TambahPelatihan />
          </Route>
          <Route exact path={`${path}/detail-pelatihan`}>
            <DetailPelatihan />
          </Route>
          <Route exact path={`${path}/detail-pelatihan/progress-peserta`}>
            <ProgressPesertaPelatihan />
          </Route>
          <Route exact path={`${path}/transaksi-peserta`}>
            <TransaksiPeserta />
          </Route>
          <Route exact path={`${path}/list-perusahaan`}>
            <ListPerusahaan />
          </Route>
          <Route exact path={`${path}/company-detail`}>
            <CompanyDetail />
          </Route>
          <Route exact path={`${path}/transaksi-per-perusahaan`}>
            <TransaksiPerPerusahaan />
          </Route>
          <Route exact path={`${path}/list-trainer`}>
            <ListTrainer />
          </Route>
          <Route exact path={`${path}/list-voucher`}>
            <ListVoucher />
          </Route>
          <Route exact path={`${path}/edit-voucher`}>
            <EditVoucher />
          </Route>
          <Route exact path={`${path}/tagihan-voucher`}>
            <TagihanVoucher />
          </Route>
          <Route exact path={`${path}/detail-trainer`}>
            <DetailTrainer />
          </Route>
          <Route exact path={`${path}/chat`}>
            <Chat />
          </Route>
          <Route exact path={`${path}/profil-saya`}>
            <ProfileSaya />
          </Route>
          <Route exact path={`${path}/kode-promo`}>
            <KodePromo />
          </Route>
          <Route exact path={`${path}/lihat-materi`}>
            <LihatMateri />
          </Route>
          <Route exact path={`${path}/cek-sertifikat`}>
            <SertifikatCek />
          </Route>
          <Route exact path={`${path}/crosspay`}>
            <Crosspay />
          </Route>
          <Route exact path={`${path}/peserta-terregistrasi`}>
            <PesertaTerregistrasi />
          </Route>
          <Route exact path={`${path}/detail-peserta-terregistrasi`}>
            <DetailPesertaTerregistrasi />
          </Route>
          <Route exact path={`${path}/tambah-peserta`}>
            <TambahPeserta />
          </Route>
          <Route exact path={`${path}/tambah-bulk`}>
            <TambahBulk />
          </Route>
          <Route exact path={`${path}/pelatihan-pengenalan`}>
            <PelatihanPengenalan />
          </Route>
          <Route exact path={`${path}/tambah-perusahaan`}>
            <TambahPerusahaan />
          </Route>
          <Route exact path={`${path}/edit-perusahaan`}>
            <EditPerusahaan />
          </Route>
          <Route exact path={`${path}/tambah-pelatihan-peserta`}>
            <TambahPelatihanPeserta />
          </Route>
          <Route exact path={`${path}`}>
            <Dashboard />
          </Route>
          <Route exact path={`${path}/unduh-invoice`}>
            <UnduhInvoice />
          </Route>
        </div>
      </PrivateRoute>
    </Switch>
  );
};

export default SuperAdmin;
