import React from 'react';
import { PrivateRoute } from '../../component';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import DashboardAdmin from './dashboard';
import CompanyData from './companyData';
import TrainerList from './trainer';
import KetuaKlien from './ketuaKlien';
import TambahKetua from './tambahKetua';
import Chat from './chat';
import ProfilSaya from './profilSaya';
import PesertaTerregistrasi from './pesertaTerregistrasi';
import DetailTrainer from './detailTrainer';
import TambahTrainer from './tambahTrainer';
import EditCompany from './editCompany';
import DetailPesertaTerregistrasi from './detailPesertaRegistrasi';
import UbahDataPeserta from './ubahPesertaTerregistrasi';
import TambahPeserta from './tambahPeserta';
import TambahBulkPeserta from './tambahBulkPeserta';
import TambahPelatihanPeserta from './tambahPelatihanPeserta';
import ListPelatihan from './listPelatihan';
import DetailPelatihan from './detailPelatihan';
import ProgressPesertaPelatihan from './progressPelatihan';
import TambahPelatihan from './tambahPelatihan';
import SertifikatCek from './sertifikatCek';
import TambahSoalQuis from './tambahSoalQuis';
import TambahSoalQuisForm from './tambahSoalQuisForm';
import TambahTugasFinal from './tambahTugasFinal';
import TambahTugasFinalForm from './tambahTugasFinalForm';
import TransaksiPerusahaan from './transaksiPerusahaan';
import LihatTagihan from './lihatTagihan';
import TransaksiPeserta from './transaksiPeserta';
import KodePromo from './kodePromo';
import TambahPromo from './tambahPromo';
import KodeVoucher from './kodeVoucher';
import TagihanVoucher from './tagihanVoucher';
import Crosspay from './crosspay';
import TambahVoucher from './tambahVoucher';
import DetailVoucher from './detailVoucher';
import LihatMateri from './lihatMateri';
import UnduhInvoice from './unduhInvoice';
import AboutUs from './aboutUs';
import TambahSoalQuisFormUpdate from "./tambahSoalQuisForm/update";
import UpdateTugasFinal from "./tambahTugasFinalForm/update";
import EditAboutUs from "./aboutUs/edit";
import EditPelatihan from "./editPelatihan";


const AdminC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute
        routeAs='admin'
        redirectPath='/1/admin/login'
        role_id={2}
      >
        <div className="container-fluid">
          <Route exact path={path}>
            <DashboardAdmin />
          </Route>
          <Route exact path={`${path}/about-us`}>
            <AboutUs />
          </Route>
          <Route exact path={`${path}/about-us/edit`}>
            <EditAboutUs />
          </Route>
          <Route exact path={`${path}/company-data`}>
            <CompanyData />
          </Route>
          <Route exact path={`${path}/trainer`}>
            <TrainerList />
          </Route>
          <Route exact path={`${path}/ketua-klien`}>
            <KetuaKlien />
          </Route>
          <Route exact path={`${path}/tambah-ketua-klien`}>
            <TambahKetua />
          </Route>
          <Route exact path={`${path}/edit-ketua-klien`}>
            <TambahKetua />
          </Route>
          <Route exact path={`${path}/chat`}>
            <Chat />
          </Route>
          <Route exact path={`${path}/profil-saya`}>
            <ProfilSaya />
          </Route>
          <Route exact path={`${path}/peserta-terregistrasi`}>
            <PesertaTerregistrasi />
          </Route>
          <Route exact path={`${path}/peserta-terregistrasi/detail-peserta`}>
            <DetailPesertaTerregistrasi />
          </Route>
          <Route exact path={`${path}/peserta-terregistrasi/ubah-peserta`}>
            <UbahDataPeserta />
          </Route>
          <Route exact path={`${path}/tambah-peserta`}>
            <TambahPeserta />
          </Route>
          <Route exact path={`${path}/tambah-bulk-peserta`}>
            <TambahBulkPeserta />
          </Route>
          <Route exact path={`${path}/tambah-pelatihan-peserta`}>
            <TambahPelatihanPeserta />
          </Route>
          <Route exact path={`${path}/detail-trainer`}>
            <DetailTrainer />
          </Route>
          <Route exact path={`${path}/tambah-trainer`}>
            <TambahTrainer />
          </Route>
          <Route exact path={`${path}/edit-company`}>
            <EditCompany />
          </Route>
          <Route exact path={`${path}/list-pelatihan`}>
            <ListPelatihan />
          </Route>
          <Route exact path={`${path}/detail-pelatihan`}>
            <DetailPelatihan />
          </Route>
          <Route exact path={`${path}/detail-pelatihan/progress-latihan`}>
            <ProgressPesertaPelatihan />
          </Route>
          <Route exact path={`${path}/detail-pelatihan/lihat-materi`}>
            <LihatMateri />
          </Route>
          <Route exact path={`${path}/tambah-pelatihan`}>
            <TambahPelatihan />
          </Route>
          <Route exact path={`${path}/edit-pelatihan`}>
            <EditPelatihan />
          </Route>
          <Route exact path={`${path}/atur-quiz`}>
            <TambahSoalQuis />
          </Route>
          <Route exact path={`${path}/atur-quiz/tambah`}>
            <TambahSoalQuisForm />
          </Route>
          <Route exact path={`${path}/atur-quiz/update`}>
            <TambahSoalQuisFormUpdate />
          </Route>
          <Route exact path={`${path}/atur-final`}>
            <TambahTugasFinal />
          </Route>
          <Route exact path={`${path}/atur-final/tambah`}>
            <TambahTugasFinalForm />
          </Route>
          <Route exact path={`${path}/atur-final/update`}>
            <UpdateTugasFinal />
          </Route>
          <Route exact path={`${path}/transaksi-perusahaan`}>
            <TransaksiPerusahaan />
          </Route>
          <Route exact path={`${path}/lihat-tagihan`}>
               <LihatTagihan />
            </Route>
          <Route exact path={`${path}/cek-sertifikat`}>
            <SertifikatCek />
          </Route>
          <Route exact path={`${path}/transaksi-peserta`}>
            <TransaksiPeserta />
          </Route>
          <Route exact path={`${path}/kode-promo`}>
            <KodePromo />
          </Route>
          <Route exact path={`${path}/tambah-promo`}>
            <TambahPromo />
          </Route>
          <Route exact path={`${path}/kode-voucher`}>
            <KodeVoucher />
          </Route>
          <Route exact path={`${path}/tagihan-voucher`}>
            <TagihanVoucher />
          </Route>
          <Route exact path={`${path}/crosspay`}>
            <Crosspay />
          </Route>
          <Route exact path={`${path}/tambah-voucher`}>
            <TambahVoucher />
          </Route>
          <Route exact path={`${path}/detail-voucher`}>
            <DetailVoucher />
          </Route>
          <Route exact path={`${path}/unduh-invoice`}>
            <UnduhInvoice />
          </Route>
        </div>
      </PrivateRoute>
    </Switch>
  )
}

export default AdminC;
