import React from 'react';
import { UncontrolledCollapse } from 'reactstrap';

const CardSoal = (props) => {
  return (
    <div className='card px-4 py-3 h-100'>
      <p className='text-nunito' style={{fontWeight:'600', fontSize:'14px'}}>{props.soalKey + 1}. {props.soal}</p>
      <div className='ml-3 w-50'>
        <div className='row'>
          <p className='col-md-6 text-nunito mb-0' style={{fontWeight:'600', fontSize:'14px'}}>Jawaban Peserta</p>
          <p className='col-md-1 text-nunito mb-0' style={{fontWeight:'600', fontSize:'14px'}}>:</p>
          <p className={`col-md-5 text-nunito mb-0 ${props.status ? 'txt-primary' : 'txt-danger'}`} style={{fontWeight:'600', fontSize:'14px'}}>{props.jawaban_peserta}</p>
        </div>
        <div className='row'>
          <p className='col-md-6 text-nunito mb-0' style={{fontWeight:'600', fontSize:'14px'}}>Jawaban yang Benar</p>
          <p className='col-md-1 text-nunito mb-0' style={{fontWeight:'600', fontSize:'14px'}}>:</p>
          <p className='col-md-5 text-nunito mb-0 text-success' style={{fontWeight:'600', fontSize:'14px'}}>{props.jawaban_benar}</p>
        </div>
      </div>
      <div className='ml-3'>
        <button className="btn btn-link txt-warning pl-0" id={`toggler${props.soalKey}`} style={{fontWeight:'600', fontSize:'14px'}}>Lihat penjelasan</button>
      </div>
      <UncontrolledCollapse toggler={`#toggler${props.soalKey}`}>
        <div className='ml-3 bg-light p-3 rounded text-dark' style={{fontWeight:'600', fontSize:'14px'}}>
          {props.penjelasan}
        </div>
      </UncontrolledCollapse>
    </div>
  )
}

export default CardSoal;