import React, {Fragment, useState, useEffect} from 'react';
import { ShoppingBag, Download, AlertCircle } from 'react-feather';
import { Link } from 'react-router-dom'
import API from "../../../api";

const Notification = ({role, notifications, unread, setNotifications, setUnread}) => {
    return (
        <Fragment>
            <div>
                <ul className="notification-dropdown onhover-show-div p-0">
                    <li className='font-weight-bold'>Notifikasi
                        {
                            unread > 0 &&
                            <span className="badge badge-pill bg-theme pull-right">{unread}</span>
                        }
                    </li>
                    {
                        notifications.map((v, k) => (
                            <li onMouseEnter={() => {
                                let notification = [...notifications];

                                API.notification.read(v.id);

                                if(notification[k].status_read === 0){
                                    notification[k].status_read = 1;
                                    setUnread(unread - 1);
                                }

                                setNotifications(notification);
                            }}>
                                <div className="media">
                                    <div className="media-body">
                                        <h6 className="mt-0 txt-success"><span><AlertCircle className="font-success"/></span>{v.title}</h6>
                                        {v.status_read ?
                                            <p className="mb-0">{v.description}</p> :
                                            <p className="mb-0"><b>{v.description}</b></p>
                                        }
                                    </div>
                                </div>
                            </li>
                        ))
                    }

                    <Link to={`${role}/notif-detail`}>
                        <li className="txt-dark">
                            Semua notifikasi
                        </li>
                    </Link>
                </ul>
            </div>
        </Fragment>
    );
};

export default Notification;
