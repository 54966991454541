import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation } from "react-router-dom";
import { CompanyIcon } from "../../../component/common/constant/menu";
import API from "../../../api";
import FormTrainer from "./FormTrainer";
import FormKlien from "./FormKetuaKlien";

const ListTrainer = () => {
  const history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get("page") || 1;
  const [pageView, setpageView] = useState(null);
  const [editDataAdmin, seteditDataAdmin] = useState({});
  const [search, setSearch] = useState('');

  const [trainers, setTrainer] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });

  const columnsTabel = [
    {
      dataField: "id",
      text: "ID",
      headerStyle: () => ({ width: "5%", textAlign: "center" }),
      headerFormatter: (column) => (
          <p
              className="text-muted my-2"
              style={{ fontWeight: "600", fontSize: "18px" }}
          >
            {column.text}
          </p>
      ),
    },
    {
      dataField: "peserta",
      text: "Nama Trainer",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center mb-2">
            <div>
              <img
                className="img-40 rounded-circle"
                src={row.avatar && row.avatar.replace(/ /g, '%20')}
                alt="#"
                style={{height: 40, objectFit: 'cover'}}
              />
            </div>
            <label className="mb-0 ml-2">
              <p className="font-weight-bold">{row.peserta}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "bidang",
      text: "Bidang Yang Dikuasai",
      headerStyle: () => ({ width: "20%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "tanggal",
      text: "Tanggal Registrasi",
      headerStyle: () => ({ width: "20%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "",
      text: "Action",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
            <Link to={`/superadmin/detail-trainer?id=${row.id}`}>
              <button
                  className=" btn btn-pill btn-outline-primary btn-sm font-weight-bold"
                  type="button"
              >
                {" "}
                Detail{" "}
              </button>
            </Link>
        );
      },
    },
  ];

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    API.superadmin.trainer.list_trainer({ page, per_page, search }).then((json) => {
      let trainer = { ...trainers };
      trainer.data = [];

      json.data.data.map((val) => {
        trainer.data.push({
          id: val.id,
          peserta: val.name,
          tanggal: val.created_at + " WIB",
          bidang: val.profession,
          status: val.status,
          avatar: val.photo,
        });
      });

      trainer.meta.from = (page - 1) * per_page + 1;
      trainer.meta.to =
        per_page > json.data.total
          ? json.data.total
          : page * per_page > json.data.total
            ? json.data.total
            : page * per_page;
      trainer.meta.total = json.data.total;
      trainer.meta.current_page = page;
      trainer.meta.total_page = Math.ceil(
        trainer.meta.total / trainer.meta.per_page
      );

      setTrainer(trainer);
    });
  }, [page, search]);

  useEffect(() => {
    history.push('/superadmin/list-trainer');
  }, [search]);

  if (pageView === 'admin') {
    return (<FormTrainer setpageView={setpageView} editDataAdmin={editDataAdmin} />)
  }

  if (pageView === 'klien') {
    return (<FormKlien setpageView={setpageView} editDataAdmin={editDataAdmin} />)
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"Trainer"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/list-trainer" className="text-warning">
              <CompanyIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Perusahaan</li>
          <li className="breadcrumb-item">Trainer</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 pb-3 d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  LIST TRAINER
                </h5>
                <div className="row mt-3">
                  <div className="col-xl-12">
                    <form className="search-form contact-search">
                      <div className="form-group mb-0">
                        <input
                            className="form-control badge-pill"
                            type="text"
                            placeholder="Cari trainer ..."
                            onChange={handleSearch}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="overflow-auto w-100">
                <TableComp
                  rowClasses="text-center"
                  title_paggination="trainer"
                  columns={columnsTabel}
                  data={trainers}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListTrainer;
