import React, { useState } from "react";
import { Link } from "react-router-dom";
import Exam from "../../../../assets/images/course_exam.png";

const PelatihanTugasFinalView = (props) => {
  const { questions } = props;
  const [View, setView] = useState("cobalagigagal");
  const [Attempt, setAttempt] = useState(1);

  console.log(questions)
  return (
    <>
      <div className="d-flex align-items-center">
        <img className="img-60 mr-3" src={Exam} alt="omind" />
        <label className="mb-0 ml-2 mr-auto">
          <h6 className="mb-0 font-weight-bold">Ujian Akhir</h6>
          <p className="mb-0">{questions.name}</p>
        </label>
      </div>
      <div className="card p-5 mt-4">
        <div>
          <ol className="d-block">
            {questions.question.map((data, index) => (
              <div className="mb-5">
                <li>{data.question}</li>
                {
                  data.question_file &&
                  <Link to={data.question_file}>
                    <button
                        class="btn btn-success text-work mt-3"
                        style={{ fontWeight: "600", fontSize: "12px" }}
                        type="button"
                    >
                      <i className="fa fa-cloud-download"></i> &nbsp;Download Soal
                    </button>
                  </Link>
                }

              </div>
            ))}
          </ol>
        </div>
      </div>
    </>
  );
};

export default PelatihanTugasFinalView;
