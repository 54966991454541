import Post from "../Post";
import Get from "../Get";

const getCompany = () => Get(`admin-c/company`);
const get_one_update = () => Get(`admin-c/company/update`);
const edit_company = (data) => Post(`admin-c/company`, data);

const CompanyAdminC = {
    getCompany,
    get_one_update,
    edit_company,
};

export default CompanyAdminC;