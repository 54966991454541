import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Form, Button } from 'semantic-ui-react';
import OTPForm from './OTPForm';
import API from "../../api";

const ResetPasswordByPhone = (props) => {
    const [DataPhone, setDataPhone] = useState('');
    const [viewForm, setDoneSubmit] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmited = () => {
        setIsSubmitting(true);

        let phone = DataPhone;

        if(phone.substr(0, 1) == 0)
            phone = phone.replace(0,62);

        // setDoneSubmit('otp');

        API.user.forgot_password({phone_number: phone})
            .then(json => {
                setDoneSubmit('otp');
                setIsSubmitting(false);
            })
            .catch(e => {
                if(e.message === 'bad request')
                    alert(e.errors.phone_number);
                else
                    alert(e.message);
                setIsSubmitting(false);
            });
    }

    if(viewForm === 'otp'){
        return <OTPForm noHp={DataPhone} as={props.as} setviewLogin={props.setviewLogin} />
    }

    return(
        <Form
            className='w-100'
            onSubmit={handleSubmited}
        >
            <Form.Field className='mb-3'>
                <h2>Lupa Password</h2>
                <p>
                    Jika Anda tidak ingat dengan password Anda, Anda dapat memilih salah satu cara di bawah untuk reset password
                </p>
            </Form.Field>
             <div className="form-group">
                <input className="form-control" type="phone" name="phone" required onChange={(e)=>setDataPhone(e.target.value)}  placeholder="No. Handphone Anda"   />
            </div>
            <Button type='submit' disabled={isSubmitting} className="btn btn-primary btn-block"  primary loading={false}>Kirim OTP</Button>
            <p className='text-center'>Atau</p>

            <Button type='button' className="btn btn-outline-primary btn-block " primary loading={false} basic onClick={()=>props.setviewLogin('resetPasswordByEmail')}>Verifikasi via Email</Button>
        </Form>
    )
}

export default ResetPasswordByPhone;
