import React, {useState, useEffect} from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link } from 'react-router-dom'
import { Layers } from 'react-feather';
import API from "../../../api";
import {RootPath} from "../../../api/Config";

const SertifikatSaya = () => {
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    API.peserta.course.my_certificate()
        .then(json => {
          let sertif = [];

          json.data.data.map(val => {
            sertif.push({
              item_judul: val.course_id.name,
              item_company: val.course_id.company_id.name,
              item_tanggal: val.obtained_on,
              item_bg: val.course_id.thumbnail.replace(/ /g, '%20'),
              no_certificate: val.no_certificate
            })
          });

          setCertificates(sertif);

        })
        .catch(e => {
          alert(e.message);
        });
  }, []);

  const downloadCertificate = (no_certificate) => {
    window.open(`${RootPath.substring(0, RootPath.length - 4)}/certificates/${no_certificate}.pdf`);
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Sertifikat Saya'}
          menuRight={
            <Link to='/cek-sertifikat'>
              <button className="btn btn-outline-warning btn-sm bg-white mr-3" type="button">
                <label className='d-flex align-items-center mb-0'>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.25 17.501H4.5V20.501H5.25C5.3895 20.501 5.5005 20.3915 5.5005 20.2505V17.75C5.5005 17.6105 5.3895 17.501 5.25 17.501Z" fill="#FFD200"/>
                    <path d="M10.2492 17.501H9.74972C9.61022 17.501 9.49922 17.6105 9.49922 17.7515V20.2505C9.49922 20.39 9.60872 20.501 9.74972 20.501H10.2492C10.3887 20.501 10.4997 20.3915 10.4997 20.2505V17.75C10.4997 17.6105 10.3902 17.501 10.2492 17.501Z" fill="#FFD200"/>
                    <path d="M19.29 12.03C18.4995 12.33 17.64 12.4995 16.7505 12.4995C12.75 12.4995 9.501 9.249 9.501 5.25C9.501 3.1905 10.3605 1.32 11.751 0H2.751C1.23 0 0 1.23 0 2.7495V21.249C0 22.77 1.23 24 2.7495 24H17.25C18.7695 24 19.9995 22.77 19.9995 21.2505V12.741L19.29 12.03ZM7.0005 20.25C7.0005 21.21 6.21 22.0005 5.25 22.0005H3.75C3.3405 22.0005 3 21.66 3 21.2505V16.7505C3 16.341 3.3405 16.0005 3.75 16.0005H5.25C6.21 16.0005 7.0005 16.791 7.0005 17.751V20.25ZM12 20.25C12 21.21 11.2095 22.0005 10.2495 22.0005H9.75C8.79 22.0005 7.9995 21.21 7.9995 20.25V17.7495C7.9995 16.7895 8.79 15.999 9.75 15.999H10.2495C11.2095 15.999 12 16.7895 12 17.7495V20.25ZM14.7495 20.5005H16.2495C16.6635 20.5005 16.9995 20.8365 16.9995 21.2505C16.9995 21.6645 16.6635 22.0005 16.2495 22.0005H14.7495C13.785 22.0005 12.999 21.216 12.999 20.25V17.7495C12.999 16.785 13.7835 15.999 14.7495 15.999H16.2495C16.6635 15.999 16.9995 16.335 16.9995 16.749C16.9995 17.163 16.6635 17.499 16.2495 17.499H14.7495C14.6115 17.499 14.499 17.6115 14.499 17.7495V20.25C14.5005 20.388 14.613 20.5005 14.7495 20.5005Z" fill="#FFD200"/>
                    <path d="M16.75 10.5C13.855 10.5 11.5 8.145 11.5 5.25C11.5 2.355 13.855 0 16.75 0C19.645 0 22 2.355 22 5.25C22 8.145 19.645 10.5 16.75 10.5ZM16.75 1.9995C14.9575 1.9995 13.4995 3.4575 13.4995 5.25C13.4995 7.0425 14.9575 8.5005 16.75 8.5005C18.5425 8.5005 20.0005 7.0425 20.0005 5.25C20.0005 3.4575 18.541 1.9995 16.75 1.9995Z" fill="#FFE777"/>
                    <path d="M22.9995 12.499C22.743 12.499 22.488 12.4015 22.293 12.2065L19.0425 8.95602C18.6525 8.56602 18.6525 7.93302 19.0425 7.54152C19.4325 7.15152 20.0655 7.15152 20.457 7.54152L23.7075 10.792C24.0975 11.182 24.0975 11.815 23.7075 12.2065C23.511 12.4015 23.256 12.499 22.9995 12.499Z" fill="#FFE777"/>
                  </svg>
                  <p className='mb-0 font-weight-bold ml-2'>
                    Cek keaslian sertifikat
                  </p>
                </label>
              </button>
            </Link>
          }
        >
          <li className="breadcrumb-item">
            <Link to="/" className='text-warning'>
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Saya</li>
          <li className="breadcrumb-item">Sertifikat Saya</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        {
          certificates.map((data, key)=>(
            <div className='col-md-3' key={key} style={{cursor: 'pointer'}} onClick={() => downloadCertificate(data.no_certificate)}>
              <div className='card p-3'>
                <img className='w-100' src={data.item_bg} itemProp="thumbnail" alt="description" />
                <h6 className='font-weight-bold mt-4'>{data.item_judul}</h6>
                <label className='mt-4 mb-0'> {data.item_company} </label>
                <p className='text-muted mb-0'>diperoleh pada</p>
                <label className='12 April 2020 font-weight-bold text-muted'> {data.item_tanggal} </label>
              </div>
            </div>
          ))
        }
      </div>
    </>
  )
}

export default SertifikatSaya;
