import React, { useState, useEffect } from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Users, MessageSquare } from 'react-feather';
import { TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledCollapse } from 'reactstrap';
import {
  useLocation,
  Link
} from "react-router-dom";
import quiz from '../../../assets/images/quiz.png';
import CardSoal from './CardSoal';
import API from "../../../api";

const QuizPembahasan = () => {
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const [attempt, setAttempt] = useState(0);
  const [attemptDone, setAttemptDone] = useState([]);
  const [quizName, setQuizName] = useState('');
  const [result, setResult] = useState(null);
  const [pembahasan, setPembahasan] = useState([]);
  const [user, setUser] = useState(null);

  const Soal = [
    {
      soal:'Candi Borobudur merupakan kenampakan buatan yang merupakan ikon dari provinsi ….',
      jawaban_peserta: 'Jawa Tengah',
      jawaban_benar: 'Jawa Tengah',
      status: true,
      penjelasan:'Candi Borrobudur adalah sebuah candi Buddha yang terletak di Borobudur, Magelang, Jawa Tengah, Indonesia. Candi ini terletak kurang lebih 100 km di sebelah barat daya Semarang, 86 km di sebelah barat Surakarta, dan 40 km di sebelah barat laut Yogyakarta.'
    },
    {
      soal:'Skala peta 1: 250.000, yaitu setiap 1 cm pada peta sesuai dengan … ',
      jawaban_peserta: '25 km',
      jawaban_benar: '2.5 km',
      status: false,
      penjelasan:'Candi Borrobudur adalah sebuah candi Buddha yang terletak di Borobudur, Magelang, Jawa Tengah, Indonesia. Candi ini terletak kurang lebih 100 km di sebelah barat daya Semarang, 86 km di sebelah barat Surakarta, dan 40 km di sebelah barat laut Yogyakarta.'
    },
    {
      soal:'Proyeksi peta diperoleh dengan memproyeksikan permukaan globe ke bidang silinder, disebut …',
      jawaban_peserta: 'Proyeksi Silinder',
      jawaban_benar: 'Proyeksi Silinder',
      status: true,
      penjelasan:'Candi Borrobudur adalah sebuah candi Buddha yang terletak di Borobudur, Magelang, Jawa Tengah, Indonesia. Candi ini terletak kurang lebih 100 km di sebelah barat daya Semarang, 86 km di sebelah barat Surakarta, dan 40 km di sebelah barat laut Yogyakarta.'
    },
    {
      soal:'Gambar konvensional dari berkurangnya permukaan bumi disebut …',
      jawaban_peserta: 'Kartu',
      jawaban_benar: 'Kartu',
      status: true,
      penjelasan:'Candi Borrobudur adalah sebuah candi Buddha yang terletak di Borobudur, Magelang, Jawa Tengah, Indonesia. Candi ini terletak kurang lebih 100 km di sebelah barat daya Semarang, 86 km di sebelah barat Surakarta, dan 40 km di sebelah barat laut Yogyakarta.'
    },
    {
      soal:'Elemen-elemen berikut dapat disadap di peta, kecuali …',
      jawaban_peserta: 'Situasi',
      jawaban_benar: 'Polanya',
      status: false,
      penjelasan:'Candi Borrobudur adalah sebuah candi Buddha yang terletak di Borobudur, Magelang, Jawa Tengah, Indonesia. Candi ini terletak kurang lebih 100 km di sebelah barat daya Semarang, 86 km di sebelah barat Surakarta, dan 40 km di sebelah barat laut Yogyakarta.'
    }
  ]

  useEffect(() => {
    API.trainer.course.quiz_discussion(id)
        .then(json => {
          API.trainer.participant.get_one(json.data.quiz_result.stat_theory_id.user_id)
              .then(json => {
                setUser(json.data);
              });

          setAttempt(json.data.quiz_result.attempt);
          setAttemptDone(json.data.other_attempts);
          setResult(json.data.quiz_result);
          setQuizName(json.data.quiz);

          let bahas = [];

          json.data.discussion.map(v => {
            bahas.push({
              soal: v.question,
              jawaban_peserta: `${v.your_answer.quiz_option_id.option}. ${v.your_answer.quiz_option_id.description}`,
              jawaban_benar: `${v.correct_answer.option}. ${v.correct_answer.description}`,
              status: v.your_answer.quiz_option_id.description === v.correct_answer.description,
              penjelasan: v.discussion
            })
          });

          setPembahasan(bahas);
        })
  }, [id]);

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Pembahasan'}>
          <li className="breadcrumb-item">
            <Link to="/trainer/peserta-saya" className='text-warning'>
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Peserta Saya</li>
          <li className="breadcrumb-item">Detail Peserta</li>
          <li className="breadcrumb-item">Progress Peserta</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className='col-md-8 mb-3'>
          <div className='row'>
            <div className='col-md-6'>
                <div className='d-flex align-items-center'>
                  <img className='img-60 mr-3' src={quiz} alt='omind' />
                  <label className='mb-0 ml-2 mr-auto'>
                    <h6 className='mb-0 font-weight-bold'>Quiz</h6>
                    <p className='mb-0'>Quiz {quizName}</p>
                  </label>

                </div>
            </div>
            <div className='col-md-6'>
              <div className='d-flex justify-content-end mt-auto align-items-end h-100'>
                {
                  attemptDone.map(v => (
                      <Link to={`/trainer/quiz/pembahasan?id=${v.id}`} className={v.attempt === 2 && 'mx-4'}>
                        <p className={`${attempt === v.attempt ? 'text-primary' : 'text-muted'} mb-0`}>Attempt {v.attempt}</p>
                      </Link>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
        </div>
        <div className='col-md-8'>
          <TabContent activeTab={attempt}>
            {
              attemptDone.map((v,k) => (
                  <TabPane tabId={k+1}>
                    {pembahasan.map((data,key)=>(
                        <CardSoal {...data} soalKey={key} key={key} />
                    ))}
                  </TabPane>
              ))
            }
          </TabContent>
        </div>
        <div className='col-md-4'>
          <TabContent activeTab={attempt}>
            {
              result &&
              attemptDone.map((v,k) => (
                  <TabPane tabId={k+1}>
                    <div className='card px-4 py-3 h-100'>
                      <div className='my-auto'>
                        <div className='d-flex align-items-center'>
                          <h5 className='mb-0 text-muted text-work'>Hasil Quiz</h5>
                        </div>
                        <div className='d-flex align-items-center my-3'>
                          <h6 className='mb-0 font-weight-bold'>Quiz {quizName}</h6>
                          <p className='mb-0 ml-auto text-muted'>{result.date_created}</p>
                        </div>
                        <div className='d-flex align-items-center w-75'>
                          <p className='mb-0 text-muted'>Benar <span className='text-dark'>{result.correct_answers}</span></p>
                          <p className='mb-0 mx-auto text-muted'>Salah <span className='text-danger'>{result.wrong_answers}</span></p>
                          <p className='mb-0 text-muted'>Nilai <span className='text-primary'>{result.score}</span></p>
                        </div>
                      </div>
                    </div>
                  </TabPane>
              ))
            }
          </TabContent>
          <div className='card p-4'>
            {
              user &&
              <div className="card-header p-0">
                <h5 className='text-muted font-weight-bold'>Profil Peserta</h5>
                <div className='d-flex align-items-center my-3'>
                  <div>
                    <img className="img-60 rounded-circle" src={user.profile_picture} alt="#" />
                  </div>
                  <label className='mb-0 ml-2'>
                    <h6 className='mb-0'>{user.name}</h6>
                    <p className='mb-0'><span className="font-weight-bold">Peserta</span> - {user.company_id}</p>
                  </label>
                </div>
                <p className='mb-0'>No Telepon</p>
                <p className="font-weight-bold">{user.phone_number}</p>
                <p className='mb-0'>Email</p>
                <p className="font-weight-bold">{user.email}</p>
                <p className='mb-0'>Status Pelatihan</p>
                <p className="font-weight-bold text-success">{user.is_active ? 'Aktif' : 'Nonaktif'}</p>
                <p className='mb-0'>Asal Kota</p>
                <p className="font-weight-bold">{user.city}</p>
              </div>
            }
            <div className="card-body d-flex align-items-center justify-content-center text-muted btn">
              <MessageSquare />
              <h6 className='ml-2 font-weight-bold mb-0'>Chat</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuizPembahasan
