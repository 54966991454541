import React from "react";
import { Link } from "react-router-dom";
import coursePDF from "../../../../assets/images/course_pdf.png";

const PelatihanPDFView = (props) => {
  const { name, link } = props;
  return (
    <div className="card p-5">
      <div className="w-100 border rounded mb-5">
        <div className="text-center p-4">
          <img
            className="img-fluid mb-4"
            src={coursePDF}
            alt="Omind Tech"
            style={{ width: "60px" }}
          />
          <h6 style={{ fontWeight: "600", fontSize: "16px" }}>{name}</h6>
          <Link to={link}>
            <button className="btn btn-primary mt-3" type="button">
              <label className="d-flex align-items-center mb-0">
                <i className="fa fa-cloud-download"></i>
                <p className="mb-0 font-weight-bold ml-2">Download</p>
              </label>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PelatihanPDFView;
