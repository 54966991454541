import React, {useState, useEffect, useRef} from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useHistory, useLocation } from "react-router-dom";
import { CompanyIcon } from '../../../component/common/constant/menu';
import { ToastContainer, toast } from 'react-toastify';
import camera from '../../../assets/images/camera.png';
import upload from '../../../assets/images/upload.png';
import API from "../../../api";
import swal from 'sweetalert2';

const TambahTrainer = () => {
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const history = useHistory();

  const photoRef = useRef(null);

  const [trainer,  setTrainer] = useState(null)
  const [data_login,  setDataLogin] = useState(null)

  const [isSubmitting,  setIsSubmitting] = useState(false);
  const [isLoading, setisLoading] = useState(false)
  const [isDisabled, setisDisabled] = useState(false)

  const [ktpPreview, setktpPreview] = useState(null);
  const [ktpPreviewUrl, setktpPreviewUrl] = useState("");

  const [tambahFotoPreview, settambahFotoPreview] = useState(null);
  const [tambahFotoPreviewUrl, settambahFotoPreviewUrl] = useState("");

  const [data_profession, setDataProfession] = useState([]);
  const [listProvinsi, setListProvinsi] = useState([]);
  const [listKota, setListKota] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);

  const [status, setStatus] = useState('0');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [profession, setProfession] = useState('');
  const [category, setCategory] = useState('');
  const [office_address, setOfficeAddress] = useState('');

  const [date_birth, setDateBirth] = useState('');
  const [photo, setPhoto] = useState('');
  const [nik, setNik] = useState('');
  const [ktp, setKtp] = useState('');
  const [phone, setPhone] = useState('');
  const [provinsi, setProvinsi] = useState('');
  const [kecamatan, setKecamatan] = useState('');
  const [kabupaten, setKabupaten] = useState('');
  const [kodepos, setKodepos] = useState('');
  const [address, setAddress] = useState('');
  const [short_profile, setShortProfile] = useState('');

  const handletambahFotoChange = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setPhoto(file)

    reader.onloadend = () => {
      settambahFotoPreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (tambahFotoPreviewUrl) {
      settambahFotoPreview(<img className="img-fluid my-3 rounded-circle ml-3" style={{width: 100, height: 100, objectFit: 'cover'}} src={tambahFotoPreviewUrl} alt="camera"/>);
    } else{
      settambahFotoPreview(<img className="img-fluid my-3 rounded-circle ml-3" style={{width: 100, height: 100, objectFit: 'cover'}} src={camera} alt="camera"/>);
    }
  }, [tambahFotoPreviewUrl]);

  const handleUploadKTP = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setKtp(file)

    reader.onloadend = () => {
      setktpPreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (ktpPreviewUrl) {
      setktpPreview(<img className="img-fluid w-50 mb-3 h-50" src={ktpPreviewUrl} alt="KTP"/>);
    } else {
      setktpPreview(<img className="img-fluid w-50 mb-3 h-50" src={upload} alt="KTP"/>);
    }
  }, [ktpPreviewUrl]);

  useEffect(() => {
    if(id){
      API.adminc.trainer.get_update(id)
        .then(json => {
          console.log(json.data);
          setTrainer(json.data)

          setStatus(json.data.status == 'Nonaktif' ? 0 : 1)
          setEmail(json.data.email)
          setName(json.data.name)
          setProfession(json.data.profession_id)
          setOfficeAddress(json.data.office_address)

          setDateBirth(json.data.date_of_birth)
          setPhoto(json.data.photo)
          if(json.data.photo){
            settambahFotoPreview(<img className="img-fluid my-3 rounded-circle ml-3" style={{width: 100, height: 100, objectFit: 'coverFhand'}} src={json.data.photo} alt="camera"/>)
          }

          setPhone(json.data.phone_number)
          setNik(json.data.nik)
          setKtp(json.data.ktp)
          if(json.data.ktp){
            setktpPreview(<img className="img-fluid w-50 mb-3 h-50" src={json.data.ktp} alt="camera"/>)
          }

          setKecamatan(json.data.region_id)
          setKodepos(json.data.postal_code)
          setAddress(json.data.address)
          setShortProfile(json.data.short_profile)

          API.rajaongkir.kecamatan(json.data.region_id)
            .then(json => {
              setProvinsi(json.province_id)
              setKabupaten(json.city_id)

              API.rajaongkir.list_kota(json.province_id)
                .then(json => {
                  setListKota(json)
                }).catch(err => {
                  console.log(err);
                })

              API.rajaongkir.list_kecamatan(json.city_id)
                .then(json => {
                  setListKecamatan(json)
                }).catch(err => {
                  console.log(err);
                })

            }).catch(err => {
              console.log(err);
            })
        }).catch(err => {
          console.log(err);
        })
    }

    API.adminc.profession.get_profession()
      .then(json => {
        setDataProfession(json.data)
      }).catch(err => {
        console.log(err);
      })

    API.rajaongkir.list_provinsi()
      .then(json => {
        setListProvinsi(json)
      }).catch(err => {
        console.log(err);
      })

      setDataLogin(JSON.parse(localStorage.getItem('data')));
  }, [])

  const handleSubmited = (event) => {
    setIsSubmitting(true);
    event.preventDefault();

    if(password !== confirm_password){
      toast.error("Confirm Password Tidak Sesuai", { position: toast.POSITION.TOP_CENTER });
      setIsSubmitting(false);
    }else{
      const data = {
        email: email,
        name: name,
        profession_id: profession,
        office_address: office_address,
        is_active: status
      }

      if(password){
        data.password = password
      }
      if(date_birth){
        data.date_of_birth = date_birth
      }
      if(photo){
        if(id){
          if(trainer.photo !== photo){
            data.profile_picture = photo
          }
        }else{
          data.profile_picture = photo
        }
      }
      if(phone){
        data.phone_number = phone
      }
      if(kecamatan){
        data.region_id = kecamatan
      }
      if(kodepos){
        data.postal_code = kodepos
      }
      if(address){
        data.address = address
      }
      if(nik){
        data.nik = nik
      }
      if(ktp){
        if(id){
          if(trainer.ktp !== ktp){
            data.ktp = ktp
          }
        }else{
          data.ktp = ktp
        }
      }
      if(short_profile){
        data.short_profile = short_profile
      }

      console.log(data)

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }

      if(id){
        API.adminc.trainer.update_trainer(id, data, config)
          .then(json => {
            console.log(json.data);
            setisLoading(true);
            toast.success("Data Trainer Berhasil Diubah", { position: toast.POSITION.TOP_CENTER });
            setIsSubmitting(false)
            setTimeout(() => {
              history.push('/admin/trainer')
            }, 5000)
          }).catch(err => {
            console.log(err);
            setIsSubmitting(false)
            for (const [key, value] of Object.entries(err.errors)) {
              toast.error(value, { position: toast.POSITION.TOP_CENTER });
            }

          })
      }else{
        API.adminc.trainer.add_trainer(data, config)
          .then(json => {
            console.log(json.data);
            setisLoading(true);
            toast.success("Trainer Berhasil Ditambahkan", { position: toast.POSITION.TOP_CENTER });
            setIsSubmitting(false)
            setTimeout(() => {
              history.push('/admin/trainer')
            }, 5000)
          }).catch(err => {
            console.log(err);
            toast.error("Trainer Tidak Berhasil Ditambahkan", { position: toast.POSITION.TOP_CENTER });
            setIsSubmitting(false)
          })
      }
    }
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={id ? 'Trainer' : 'Tambah Trainer'}>
          <li className="breadcrumb-item">
            <Link to="/admin/tambah-trainer" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Perusahaan</li>
          <li className="breadcrumb-item">Trainer</li>
          <li className="breadcrumb-item">{id ? name ? name : null : 'Tambah Trainer'}</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
          <div className="card-header d-flex pb-2">
              <div className='mr-auto'>
                <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>{id ? 'TRAINER' : 'TAMBAH TRAINER BARU'}</h4>
                <span className='text-muted'>Data Trainer</span>
              </div>
              <div className="mr-3">
                <span className='text-work' style={{ fontWeight: '500' }}>Status</span>
                <select className="form-control digits mt-2 pr-5 pl-1 p-0" id="exampleFormControlSelect7" onChange={(e)=>setStatus(e.target.value)}>
                  <option value='0' selected={ status == '0' }>Non Aktif</option>
                  <option value='1' selected={ status == '1' }>Aktif</option>
                </select>
              </div>
            </div>
            <div className="mx-4">
              <hr/>
            </div>
            <div className="card-body pt-4 pb-3">
              <h6 className='text-muted' style={{ fontWeight: '700' }}>Akun Trainer</h6>
            </div>
            <form className="row mx-4" onSubmit={handleSubmited}>

              <div className='col-md-5 pr-5 ml-1'>
                <div className="form-group ">
                  <label htmlFor="InputEmail"><b>Email</b></label>
                  <input type="email" className="form-control" name="email" id="InputEmail" required placeholder="Masukkan Email Trainer" value={email} onChange={(e)=>setEmail(e.target.value)} />
                </div>
                <div className="form-group ">
                  <label htmlFor="InputPassword"><b>Password</b></label>
                  <input type="password" className="form-control" name="password" id="InputPassword" required={!trainer} placeholder="Masukkan password" onChange={(e)=>setPassword(e.target.value)} />
                </div>
                <div className="form-group ">
                  <label htmlFor="konfirmasi_password"><b>Konfirmasi Password</b></label>
                  <input type="password" className="form-control" name="konfirmasi_password" id="konfirmasi_password" required={!trainer} placeholder="Masukkan ulang password" onChange={(e)=>setConfirmPassword(e.target.value)} />
                </div>
                <div className="form-group ">
                  <label htmlFor="nama"><b>Nama</b></label>
                  <input type="text" className="form-control" name="nama" id="nama" required placeholder="Masukkan nama trainer" value={name} onChange={(e)=>setName(e.target.value)} />
                </div>
              </div>

              <div className='ml-4 col-md-5 pr-5'>
                <div className="form-group ">
                  <label htmlFor="namaPerusahaan"><b>Nama Perusahaan</b></label>
                  <input type="text" className="form-control" name="nama_perusahaan" id="namaPerusahaan" required placeholder={data_login ? data_login.company_name : null} value={data_login ? data_login.company_name : null}disabled/>
                </div>
                <div className="form-group ">
                  <label htmlFor="exampleFormControlSelect7"><b>Pekerjaan</b></label>
                  <select className="form-control digits" id="exampleFormControlSelect7" onChange={(e)=>setProfession(e.target.value)}>
                    <option>--Pilih Pekerjaan--</option>
                    {
                      data_profession.map(v => {
                        return <option value={v.id} selected={profession == v.id}>{v.name}</option>
                      })
                    }
                  </select>
                </div>
                {/* <div className="form-group ">
                  <label htmlFor="bidangPelatihan"><b>Bidang pelatihan</b></label>
                  <input type="text" className="form-control" name="nama_perusahaan" id="bidangPelatihan" required placeholder="Masukkan bidang pelatihan"/>
                </div> */}
                <div className="form-group ">
                  <label htmlFor="alamatKantor"><b>Alamat kantor - Cabang</b></label>
                  <textarea className="form-control" name="alamat" id="alamatKantor" required placeholder="Masukkan alamat kantor" rows="5" value={office_address} onChange={(e)=>setOfficeAddress(e.target.value)}></textarea>
                </div>
              </div>
              <div className="col card-body px-2 pb-2">
                <hr/>
              </div>
              <div className="form-group pl-2 pt-0 mt-0">
                <input
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="option1"
                  onChange={() => {
                    if(isDisabled === true){
                      setisDisabled(false)
                    }else{
                      setisDisabled(true)
                    }
                  }} />
                <label className="text-muted" htmlFor="inlineCheckbox1">&nbsp;&nbsp;Biarkan trainer mengisi biodata sendiri</label>
              </div>
              <div className="col card-body pl-1 pb-3">
                <h6 className='text-muted' style={{ fontWeight: '700' }}>Biodata Trainer</h6>
              </div>
                <div className='col-md-5 pr-5 ml-2'>
                <div className="form-group ">
                  <label htmlFor="InputTTL"><b>Tanggal Lahir</b></label>
                  <input type="date" className="form-control" name="email" id="InputTTL" placeholder="Masukkan Email Trainer" disabled={isDisabled} value={date_birth} onChange={(e)=>setDateBirth(e.target.value)} />
                </div>

                <label htmlFor="InputTTL"><b>Foto</b></label>
                <div className="form-group my-3">
                  {/* Preview */}
                  <div className="text-center" style={{cursor: 'pointer'}} onClick={() => photoRef.current.click()}>
                    {tambahFotoPreview}
                  </div>
                  <input
                      ref={photoRef}
                      style={{display: 'none'}}
                      id="file-select"
                      type="file"
                      disabled={isDisabled}
                      onChange={handletambahFotoChange}
                  />
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="nomorTelepon"><b>Nomor Telepon</b></label>
                  <input type="number" className="form-control" name="nomorTelepon" id="nomorTelepon" placeholder="Masukkan nomor telepon" disabled={isDisabled} value={phone} onChange={(e)=>setPhone(e.target.value)} />
                </div>
                <div className="form-group mb-0 mt-1">
                  <label htmlFor="alamat"><b>Alamat</b></label>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <select disabled={listProvinsi.length === 0 || isDisabled} name="provinsi" className="form-control" id="provinsi" onChange={e => {
                      setProvinsi(e.target.value)
                      setListKota([]);
                      setListKecamatan([]);

                      API.rajaongkir.list_kota(e.target.value)
                          .then(json => {
                            setListKota(json);
                          });
                    }} required>
                      <option value="#">Provinsi</option>
                      {
                        listProvinsi.map(v => {
                          return <option value={v.province_id} selected={provinsi == v.province_id}>{v.province}</option>
                        })
                      }
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <select disabled={listKota.length === 0 || isDisabled} name="kabupaten" className="form-control" id="kabupaten" onChange={e => {
                      setKabupaten(e.target.value)
                      setListKecamatan([]);

                      API.rajaongkir.list_kecamatan(e.target.value)
                          .then(json => {
                            setListKecamatan(json);
                          });
                    }} required>
                      <option value="#">Kota / Kabupaten</option>
                      {
                        listKota.map(v => {
                          return <option value={v.city_id} selected={kabupaten == v.city_id}>{v.city_name}</option>
                        })
                      }
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <select disabled={listKecamatan.length === 0 || isDisabled} name="kecamatan" className="form-control" id="kecamatan" onChange={(e)=>setKecamatan(e.target.value)} >
                      <option value="#">Kecamatan</option>
                      {
                        listKecamatan.map(v => (
                            <option value={v.subdistrict_id} selected={kecamatan == v.subdistrict_id}>{v.subdistrict_name}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <input type="number" className="form-control" name="kodePos" id="kodePos" placeholder="Masukkan kode pos" disabled={isDisabled} value={kodepos} onChange={(e)=>setKodepos(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className='ml-4 col-md-5 pr-5'>
                <div className="form-group ">
                  <label htmlFor="nikKTP"><b>NIK KTP</b></label>
                  <input type="text" className="form-control" name="nik" id="nikKTP" placeholder="Masukkan NIK KTP Trainer" disabled={isDisabled} value={nik} onChange={(e)=>setNik(e.target.value)}/>
                </div>

                <div className="form-group mt-4">
                  {/* Preview */}
                  {ktpPreview}
                  {/* Input */}

                  <div className="button-custom w-50 py-3">
                    <div className="btn btn-pill btn-primary upload-image">
                        <span>
                          <i className="fa fa-cloud-upload"></i> &ensp; Upload KTP
                        </span>
                      <input
                          id="file-select"
                          type="file"
                          disabled={isDisabled}
                          onChange={handleUploadKTP}
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div className="ml-2 col-md-6">
                <div className="form-group mt-2">
                    <textarea className="form-control" name="alamatDetail" id="alamatDetail" rows="5" placeholder="Nama Jalan / Alamat Detail" disabled={isDisabled} value={address} onChange={(e)=>setAddress(e.target.value)}></textarea>
                </div>
              </div>
              <div className="ml-2 col-md-12">
                <div className="form-group mb-3">
                    <label htmlFor="profil"><b>Profil Singkat</b></label>
                    <textarea className="form-control p-4" name="profil" id="profil" rows="10" disabled={isDisabled} value={short_profile} onChange={(e)=>setShortProfile(e.target.value)} ></textarea>
                    <hr/>
                </div>
              </div>
              <div className="col-md-12 m-5">
                <div className="text-right mb-5">
                  <Link to={id ? `/admin/detail-trainer?id=${id}` : "/admin/trainer"} className='text-warning'>
                    <button type="cancel" className="btn btn-outline-primary btn-pill mr-2">Batal</button>
                  </Link>
                  <button type="submit" className="btn btn-primary btn-pill" disabled={isSubmitting}>Simpan</button>
                  <ToastContainer />
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>


    </>
  )
}

export default TambahTrainer;
