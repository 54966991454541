import Get from "../Get";
import Post from "../Post";

const list_perusahaan = (data = null) =>
  Get(
    `super-admin/company?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const detail_perusahaan = (id) => Get(`super-admin/company/${id}`);
const peserta = (id, data) => Get(`super-admin/company/${id}/participants?${data && Object.keys(data).map((key) => key + "=" + data[key]).join("&")}`);
const pelatihan = (id, data) => Get(`super-admin/company/${id}/courses?${data && Object.keys(data).map((key) => key + "=" + data[key]).join("&")}`);
const promo = (id, data) => Get(`super-admin/company/${id}/promos?${data && Object.keys(data).map((key) => key + "=" + data[key]).join("&")}`);
const voucher = (id, data) => Get(`super-admin/company/${id}/vouchers?${data && Object.keys(data).map((key) => key + "=" + data[key]).join("&")}`);
const transaksi = (id, data) => Get(`super-admin/company/${id}/transactions?${data && Object.keys(data).map((key) => key + "=" + data[key]).join("&")}`);
const trainer = (id, data) => Get(`super-admin/company/${id}/trainers?${data && Object.keys(data).map((key) => key + "=" + data[key]).join("&")}`);
const place = (id) => Get(`super-admin/company/${id}/place`);
const tambah_perusahaan = (data = null, onUploadProgress = null) => Post(`super-admin/company`, data, onUploadProgress);
const edit_perusahaan = (id, data) => Post(`super-admin/company/${id}/edit`, data);

const SuperadminCompany = {
  list_perusahaan,
  place,
  detail_perusahaan,
  peserta,
  pelatihan,
  promo,
  voucher,
  transaksi,
  trainer,
  tambah_perusahaan,
  edit_perusahaan,
};

export default SuperadminCompany;
