import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layers } from "react-feather";
import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import { BreadcrumbComp, TableComp } from "../../../component";
import learning_dummy from "../../../assets/images/learning-dummy-image.png";
import TambahPelatihanPengenalan from "./TambahPelatihanPengenalan";
import { ToastContainer, toast } from "react-toastify";
import API from "../../../api";
import swal from "sweetalert2";

const PelatihanPengenalan = () => {
  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get("page") || 1;
  const toastSuccessAdd = localStorage.getItem("tambahPelatihan");

  const [courses, setCourses] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });

  const [modalConfirm, setmodalConfirm] = useState(false);
  const [modalDuplikatDipilih, setmodalDuplikatDipilih] = useState(false);
  const [TambahPelatihanView, setTambahPelatihanView] = useState(false);
  const [dataDuplikat, setdataDuplikat] = useState({});

  const columnsTabel = [
    {
      dataField: "id",
      text: "ID",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
    },
    {
      dataField: "pelatihan",
      text: "Nama Pelatihan",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
    },
    {
      dataField: "jenis_pelatihan",
      text: "Jenis Pelatihan",
      headerStyle: () => ({ width: "15%", textAlign: "left" }),
    },
    {
      dataField: "tanggal",
      text: "Tanggal Dibuat",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
    },
    {
      dataField: "",
      text: "Action",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      formatter: (cel, row, idx) => {
        return (
          <div className="d-flex justify-content-center">
            <Link
              to={`/superadmin/detail-pelatihan?id=${row.id}`}
              className="mx-auto"
            >
              <button
                className=" btn rounded-circle btn-success btn-xs py-1"
                type="button"
              >
                {" "}
                <i className="fa fa-eye"></i>{" "}
              </button>
            </Link>
            <button
              className=" mx-auto btn rounded-circle btn-danger btn-xs"
              type="button"
              onClick={() => deleteCourse(row.id, idx)}
            >
              {" "}
              <i className="fa fa-trash"></i>{" "}
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    API.superadmin.intro_courses
      .list_pelatihan_pengenalan({ page, per_page })
      .then((json) => {
        let course = { ...courses };
        course.data = [];

        json.data.data.map((val) => {
          course.data.push({
            id: val.id,
            pelatihan: val.name,
            jenis_pelatihan: val.type,
            tanggal: val.created_at,
            status: val.status,
          });
        });

        course.meta.from = (page - 1) * per_page + 1;
        course.meta.to =
          per_page > json.data.total
            ? json.data.total
            : page * per_page > json.data.total
            ? json.data.total
            : page * per_page;
        course.meta.total = json.data.total;
        course.meta.current_page = page;
        course.meta.total_page = Math.ceil(
          course.meta.total / course.meta.per_page
        );

        setCourses(course);
      });
  }, [page, TambahPelatihanView]);

  const deleteCourse = (id, idx) => {
    swal.fire({
      title: 'Anda yakin?',
      text: "Pastikan pelatihan ini belum dibeli. Pelatihan yang dihapus tidak bisa dikembalikan",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Batal',
    }).then((result) => {
      if (result.isConfirmed) {
        swal.fire({
          onBeforeOpen () {
            swal.showLoading()
          },
          onAfterClose () {
            swal.hideLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });

        API.superadmin.course.hapus(id)
            .then(json => {
              swal.fire('Berhasil menghapus pelatihan!', '', 'success');

              let temp_courses = {...courses};
              temp_courses.data.splice(idx, 1);
              setCourses(temp_courses);
            }).catch(e => {
          swal.fire('Gagal menghapus pelatihan!', 'Mungkin pelatihan ini sudah ada yang membeli', 'error');
        })
      }
    })
  }

  const handleTambahPelatihan = (duplikat) => {
    if (duplikat) {
      setTambahPelatihanView(true);
    } else {
      setdataDuplikat(false);
      setTambahPelatihanView(true);
    }

    setmodalDuplikatDipilih(false);
    setmodalConfirm(false);
  };

  useEffect(() => {
    if (toastSuccessAdd) {
      toast.success("Berhasil menambahkan pelatihan", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("tambahPelatihan");
    }
  }, [toastSuccessAdd]);

  if (TambahPelatihanView) {
    return (
      <TambahPelatihanPengenalan
        dataDuplikat={dataDuplikat}
        setTambahPelatihanView={setTambahPelatihanView}
      />
    );
  }
  return (
    <>
      <ToastContainer />
      <div className="page-header">
        <BreadcrumbComp title={"List Pelatihan Intro"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/manajemen-pelatihan" className="text-warning">
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Pelatihan</li>
          <li className="breadcrumb-item">List Pelatihan Intro</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 d-flex">
              <div className="mr-auto">
                <h4
                  className="text-theme text-work"
                  style={{ fontWeight: "600" }}
                >
                  PELATIHAN INTRO{" "}
                </h4>
              </div>
              <div>
                <button
                  className="btn btn-pill btn-outline-primary btn-sm text-work"
                  type="button"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                    setmodalConfirm(true);
                  }}
                >
                  <i className="fa fa-pencil" />
                  &nbsp;Tambah Pelatihan Intro
                </button>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="overflow-auto w-100">
                <TableComp
                  title_pagination="pelatihan"
                  columns={columnsTabel}
                  data={courses}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalConfirm}
        toggle={() => setmodalConfirm(!modalConfirm)}
        size="md"
      >
        <ModalBody>
          <div className="text-center mt-3">
            <h6>Apakah ini pelatihan baru atau pelatihan duplikat?</h6>
            <button
              className="btn btn-pill btn-primary btn-sm my-3 mr-3"
              type="button"
              onClick={() => {
                handleTambahPelatihan(false);
              }}
            >
              Pelatihan Baru
            </button>
            <button
              className="btn btn-pill btn-outline-primary btn-sm my-3"
              type="button"
              onClick={() => {
                setmodalConfirm(!modalConfirm);
                setmodalDuplikatDipilih(true);
              }}
            >
              {" "}
              Pelatihan Duplikat
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalDuplikatDipilih}
        size="lg"
        style={{ maxWidth: "1050px" }}
      >
        <ModalHeader
          toggle={() => setmodalDuplikatDipilih(!modalDuplikatDipilih)}
        />
        <ModalBody>
          <div className="text-center mt-3">
            <h6>Pilih Pelatihan yang mau diduplikat</h6>
            <div className="w-50 mx-auto my-3">
              <div className="form-group mt-4 text-left">
                <label className="text-work">Nama Pelatihan</label>
                <select
                  className="form-control digits"
                  defaultValue="default"
                  required
                >
                  <option value="default" disabled>
                    Pilih Pelatihan
                  </option>
                  {courses &&
                    courses.data.map((value) => {
                      return (
                        <option onClick={() => setdataDuplikat(value)}>
                          {value.pelatihan}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            {dataDuplikat && (
              <div className="row-md-12 mr-auto d-flex justify-content-center mt-5">
                <div className="col-md-5">
                  <img
                    className="img-fluid w-100 mb-3"
                    src={learning_dummy}
                    alt="omind"
                  />
                </div>
                <div className="col-md-5 text-left">
                  <h3 className="font-weight-bold">{dataDuplikat.pelatihan}</h3>
                  <div className="d-flex">
                    <label>
                      <div
                        className={`badge text-wrap text-capitalize ${
                          dataDuplikat.jenis_pelatihan ===
                          "Pelatihan Pengenalan"
                            ? "badge-primary"
                            : "badge-success"
                        }
                      `}
                      >
                        {dataDuplikat.jenis_pelatihan}
                      </div>
                    </label>
                    <p className="text-muted mr-4 ml-2">
                      {" "}
                      <span className="text-muted">
                        <i className="fa fa-question-circle"></i>
                      </span>
                    </p>
                    <p className="text-muted mr-4">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa-clock-o"></i>
                      </span>
                      &nbsp; Durasi Pelatihan{" "}
                      <span className="text-dark font-weight-bold">
                        270 hari
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="text-muted mr-4">
                      {" "}
                      Kategori{" "}
                      <span className="text-dark font-weight-bold">
                        Komunikasi dan Interpersonal
                      </span>
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-muted mr-4">
                      {" "}
                      Dibuat Oleh{" "}
                      <span className="text-dark font-weight-bold">
                        Aang Sukarna
                      </span>
                    </p>
                  </div>
                  <div className="d-flex">
                    <p className="text-muted mr-1">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa-play-circle-o"></i>
                      </span>
                      &nbsp; 4 Video
                    </p>
                    <p className="text-muted mr-1">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa fa-file-pdf-o"></i>
                      </span>
                      &nbsp; 2 PDF
                    </p>
                    <p className="text-muted mr-1">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa fa-file-powerpoint-o"></i>
                      </span>{" "}
                      &nbsp;1 PPT
                    </p>
                    <p className="text-muted mr-1">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa-pencil-square-o"></i>
                      </span>
                      &nbsp; 2 Kuis
                    </p>
                    <p className="text-muted mr-1">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa-clipboard"></i>
                      </span>
                      &nbsp; 1 Ujian Final
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-pill btn-outline-primary btn-sm my-3 mr-3"
            type="button"
            onClick={() => {
              setmodalDuplikatDipilih(!modalDuplikatDipilih);
            }}
          >
            {" "}
            Batal
          </button>
          <button
            className="btn btn-pill btn-primary btn-sm my-3"
            type="button"
            onClick={() => {
              handleTambahPelatihan(true);
            }}
          >
            {" "}
            Duplikat Pelatihan
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PelatihanPengenalan;
