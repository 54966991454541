import Get from "../Get";
import Post from "../Post";
import Delete from "../Delete";

const getAll = (id) => Get(`admin-c/quiz/${id}`);
const insert = (id, data) => Post(`admin-c/quiz/${id}`, data);
const bulk = (id, data) => Post(`admin-c/quiz/bulk/${id}`, data);
const bulk_download = () => Get(`admin-c/quiz/download-bulk`);
const getOne = (id) => Get(`admin-c/quiz/detail/${id}`);
const update = (id, data) => Post(`admin-c/quiz/detail/${id}`, data);
const del = (id) => Delete(`admin-c/quiz/${id}`);

const QuizAdminC = {
    getAll,
    insert,
    bulk,
    bulk_download,
    getOne,
    update,
    del
};

export default QuizAdminC;
