import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button } from 'semantic-ui-react';
import OtpInput from 'react-otp-input';
import NewPassword from './NewPassword';
import API from "../../api";

const OTPForm = (props) => {
    const [otpValue, setotpValue] = useState(0);
    const [counter, setCounter] = React.useState(60);
    const [isResend, setIsResend] = React.useState(false);
    const [tokenForResetPassword, settokenForResetPassword] = useState('');

    useEffect(() => {
        const timer =
          counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
      }, [counter]);

    useEffect(() => {
        if(otpValue.length === 6)
            API.user.forgot_password_check({kode: parseInt(otpValue), phone_number: props.noHp})
                .then(json => {
                    settokenForResetPassword(json.data.code_verification_password);
                })
                .catch(e => {
                    alert(e.message);
                });
    }, [otpValue]);

    const resend = () => {
        setIsResend(true);
        API.user.forgot_password_resend({phone_number: props.noHp})
            .then(json => {
                alert('Sukses mengirim ulang kode OTP');
                setIsResend(false);
            })
            .catch(e => {
                alert(e.message);
                setIsResend(false);
            })
    };

    if(tokenForResetPassword){
        return <NewPassword token={tokenForResetPassword} as={props.as} setviewLogin={props.setviewLogin}/>
    }

    return(
        <div className='text-center mx-auto'>
            <h4>Masukkan OTP untuk Verifikasi Ubah Password Anda</h4>
            <p>Kode OTP telah dikirimkan ke nomor<br/><span className='font-weight-bold'>{props.noHp}</span></p>
            <div className='w-100'>
                <OtpInput
                    inputStyle={{
                        width: '100%',
                        height: '3rem',
                        margin: '20px 1rem',
                        fontSize: '2rem',
                        borderRadius: 4,
                        border: '2px solid rgba(0,0,0,0.3)',
                    }}
                    onChange={otp => setotpValue(otp)}
                    numInputs={6}
                    value={otpValue}
                    separator={<span>-</span>}
                />
            </div>
            {counter ?
                <>
                    <p>Anda dapat mengirim ulang kode verifikasi setelah {counter} detik</p>
                    <Button type='button' className="btn btn-primary btn-block" secondary disabled loading={false}>Kirim Ulang Kode</Button>
                </>
                :
                <>
                <p>Belum menerima kode verifikasi?</p>
                <Button type='button' className="btn btn-primary btn-block" primary loading={false} disabled={isResend} onClick={resend}>Kirim Ulang Kode</Button>
            </>
            }
            <Button type='button' className="btn btn-outline-primary btn-block mt-3" primary loading={false} basic onClick={()=>props.setviewLogin('login')}>Kembali ke Login</Button>
        </div>
    )
}

export default OTPForm;
