import React, { useState, useEffect } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link } from "react-router-dom";
import { CompanyIcon } from "../../../component/common/constant/menu";
import voucher from "../../../assets/images/voucher-redeem.png";
import ceklis from "../../../assets/images/ic_diterima.png";
import { Modal, ModalBody } from "reactstrap";
import API from "../../../api";

const TambahVoucher = () => {
  let today = new Date();
  let day = new Intl.DateTimeFormat('en-US', {day: '2-digit'}).format(today)
  let month = new Intl.DateTimeFormat('en-US', {month: '2-digit'}).format(today)
  let date = today.getFullYear()+'-'+month+'-'+day;
  const [modalDetail, setmodalDetail] = useState();
  const [cekAktif, setcekAktif] = useState(false);
  const [nominal, setNominal] = useState(null);
  const [quota, setQuota] = useState(1);
  const [quantity, setQuantity] = useState(null);
  const [start_voucher, setStartVoucher] = useState(null);
  const [end_voucher, setEndVoucher] = useState(null);
  const [description, setDescription] = useState(null);
  const [company_id, setCompanyId] = useState(null);
  const [status, setStatus] = useState(null);
  const [company, setCompany] = useState(null);
  const [result, setResult] = useState(null);
  const [check, setCheck] = useState(true);
  useEffect(() => {
    API.adminc.company
      .getCompany()
      .then((json) => {
        setCompanyId(json.data.id);
        setCompany(json.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleSubmited = (status) => {
    setStatus(status);

    const data = {
      nominal,
      quantity,
      quota,
      start_voucher,
      end_voucher,
      description,
      company_id,
      status,
    };
    API.adminc.vouchers
      .insert_voucher(data)
      .then((json) => {
        setResult(json.data);
        console.log(json.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"List Voucher"}>
          <li className="breadcrumb-item">
            <Link to="/admin/tambah-voucher" className="text-warning">
              <CompanyIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">Kode Voucher</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm">
          <div className="card">
            <div className="col-md-12 mx-4 mt-2">
              <div className="text-center my-5">
                <div>
                  <img src={voucher} className="img-70" alt="voucher" />
                </div>
                <label
                  className="text-muted text-work mt-3 text-center"
                  style={{ fontWeight: "700", fontSize: "18px" }}
                >
                  Generate Kode Voucher
                </label>
              </div>
              <form className="mb-5" onSubmit={handleSubmited}>
                {/* Krii */}
                <div className="col-md-12">
                  <div className="d-flex">
                    <div className="text-align-center">
                      <label
                        htmlFor="nominal"
                        className="text-work"
                        style={{ fontWeight: "600" }}
                      >
                        Nominal
                      </label>
                      <div className="d-flex form-inline justify-content-center">
                        <p
                          className="my-auto text-work"
                          style={{ fontSize: "16px", fontWeight: "600" }}
                        >
                          Rp. &ensp;
                        </p>
                        {cekAktif ? (
                          <label>{nominal}</label>
                        ) : (
                          <input
                            type="text"
                            placeholder="Isi nominal voucher"
                            className="form-control"
                            id="nominal"
                            required
                            onChange={(event) => setNominal(event.target.value)}
                          />
                        )}
                      </div>
                    </div>
                    <div className="my-auto" style={{ lineHeight: "90px" }}>
                      <span
                        className="mx-4 text-muted my-auto"
                        style={{ fontSize: "16px" }}
                      >
                        X
                      </span>
                    </div>
                    <div className="text-align-center">
                      <label
                        htmlFor="voucher"
                        className="text-work"
                        style={{ fontWeight: "600" }}
                      >
                        Jumlah Voucher
                      </label>
                      <div className="d-flex form-group">
                        {cekAktif ? (
                          <label className="mt-1">{quantity}</label>
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            id="voucher"
                            required
                            onChange={(event) =>
                              setQuantity(event.target.value)
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="my-auto" style={{ lineHeight: "90px" }}>
                      <span
                        className="mx-4 text-muted my-auto"
                        style={{ fontSize: "16px" }}
                      >
                        =
                      </span>
                    </div>
                    <div className="text-align-center">
                      <label
                        htmlFor="text-work"
                        style={{ fontWeight: "600" }}
                        className="text-work"
                      >
                        Nilai Total
                      </label>
                      <div className="d-flex form-inline justify-content-center">
                        <p
                          className="my-auto text-work"
                          style={{ fontSize: "16px" }}
                        >
                          Rp. &ensp;
                        </p>
                        {/* Nilai Rupiah */}
                        {cekAktif ? (
                          <label>{nominal * quantity}</label>
                        ) : (
                          nominal * quantity
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-1">
                  <div className="d-flex">
                    <div className="text-align-center">
                      <label
                        htmlFor="nominal"
                        className="text-work"
                        style={{ fontWeight: "600" }}
                      >
                        Tanggal Berlaku
                      </label>
                      <div className="d-flex form-inline">
                        {cekAktif ? (
                          <>
                            <label className="font-weight-bold">
                              {start_voucher}
                            </label>
                            <p className="text-align-center mx-4 my-auto">
                              hingga
                            </p>
                            <label className="font-weight-bold">
                              {end_voucher}
                            </label>
                          </>
                        ) : (
                          <>
                            <input
                              type="date"
                              className="form-control"
                              min={date}
                              max={end_voucher ? end_voucher : null}
                              onChange={(event) =>
                                setStartVoucher(event.target.value)
                              }
                            />
                            <p className="text-align-center mx-4 my-auto">
                              hingga
                            </p>
                            <input
                              type="date"
                              className="form-control"
                              required
                              min={start_voucher}
                              onChange={(event) =>
                                setEndVoucher(event.target.value)
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <div className="d-flex">
                    <div className="text-align-center">
                      <label
                          htmlFor="nominal"
                          className="text-work"
                          style={{ fontWeight: "600" }}
                      >
                        Jumlah Pemakaian per kode voucher
                      </label>
                      <div className="d-flex form-inline">
                        {cekAktif ? (
                            <label className="mt-1">{quota}</label>
                        ) : (
                            <input
                                type="text"
                                className="form-control"
                                id="quota"
                                required
                                onChange={(event) => setQuota(event.target.value)}
                            />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <div className="text-align-center">
                    <label
                      htmlFor="nominal"
                      className="text-work"
                      style={{ fontWeight: "600" }}
                    >
                      Keterangan Voucher
                    </label>
                    {cekAktif ? (
                      <div className="text-work">{description}</div>
                    ) : (
                      <textarea
                        cols="53"
                        rows="5"
                        required
                        placeholder="Masukkan keterangan penggunaan voucher"
                        className="form-control"
                        onChange={(event) => setDescription(event.target.value)}
                      ></textarea>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <div className="text-align-center">
                    <label
                      htmlFor="nominal"
                      className="text-work"
                      style={{ fontWeight: "600" }}
                    >
                      Perusahaan
                    </label>
                    {cekAktif ? (
                      <div className="text-work mt-0">{company.name}</div>
                    ) : (
                      company && (
                        <input
                          type="text"
                          className="form-control w-25"
                          value={company.name}
                          disabled
                        />
                      )
                    )}
                  </div>
                </div>
                {cekAktif ? (
                  <div className="mt-5">
                    <h5 className="text-work text-muted ml-3">Kode Voucher</h5>
                    <table className="table table-striped w-50 ml-3">
                      {result &&
                        result.codes.map((code, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{code}</td>
                          </tr>
                        ))}
                    </table>
                  </div>
                ) : (
                  <div className="form-check my-5 mx-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                      onChange={() => {
                        setCheck(!check)
                      }}
                      required
                    />
                    <label
                      className="form-check-label ml-3"
                      htmlFor="defaultCheck1"
                    >
                      Saya menyetujui bahwa setiap nilai voucher akan otomatis
                      ditagihkan kepada perusahaan ketika terpakai dan <br />
                      sepenuhnya menjadi tanggung jawab perusahaan
                    </label>
                  </div>
                )}

                {cekAktif ? (
                  <div className="col-md-12 text-center mx-auto mt-5">
                    <Link to={`/admin/kode-voucher`}>
                      <p style={{ fontSize: "14px" }}>
                        {" "}
                        <u> Kembali ke List Voucher </u>
                      </p>
                    </Link>
                  </div>
                ) : (
                  <div className="col-md-3 text-center mx-auto">
                    <div className="text-center my-3">
                      <button
                        type="button"
                        className="btn btn-primary btn-pill btn-block p-2"
                        disabled={check}
                        onClick={() => {
                          setmodalDetail(!modalDetail);
                          setcekAktif(!cekAktif);
                          handleSubmited("Aktif");
                        }}
                      >
                        Aktifkan
                      </button>
                    </div>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-pill btn-block p-2"
                        disabled={check}
                        onClick={() => {
                          setmodalDetail(!modalDetail);
                          setcekAktif(!cekAktif);
                          handleSubmited("Draft");
                        }}
                      >
                        Simpan sebagai draf
                      </button>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalDetail}
        toggle={() => setmodalDetail(!modalDetail)}
        size="md"
        style={{ maxWidth: "700px", maxHeight: "250px", marginTop: "80px" }}
      >
        <ModalBody className="pb-5">
          <div className="text-center mt-2 mb-4">
            <img src={ceklis} alt="" />
          </div>
          <div className="text-center ">
            <h5 className="font-weight-bold">Kode Voucher Berhasil Dibuat</h5>
          </div>
          <div className="text-center">
            <p>Anda dapat melihat status penggunaan voucher di list voucher</p>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TambahVoucher;
