import React from 'react';
import Sidebar from '../common/sidebar-component/sidebar';
import Header from '../common/header-component/header';

const Layout = (props) => {

    return(
        <div className="page-wrapper">
            <div className="page-body-wrapper">
                <Header as={props.as} />
                <Sidebar 
                    as={props.as}
                />
                <div className="page-body px-0">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Layout;