import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { BreadcrumbComp } from "../../../component";
import { Layers } from "react-feather";
import { Collapse } from "reactstrap";
import PelatihanPPTView from "./pptView";
import PelatihanVideoView from "./videoView";
import PelatihanPDFView from "./pdfView";
import PelatihanKuisView from "./kuisView";
import PelatihanTugasFinalView from "./tugasFinalView";
import PelatihanVirtualClassView from "./virtualClassView";
import PelatihanFaceToFaceView from "./faceToFaceView";
import API from "../../../api";

const DetailPelatihanCourse = () => {
  let query = new URLSearchParams(useLocation().search);
  let id = query.get("id");
  const status = query.get("status");

  const [company, setCompany] = useState(null);
  const [position, setposition] = useState(6);
  const [checkedValue, setcheckedValue] = useState(6);

  const [accordions, setAccordion] = useState([]);

  const [courseName, setCourseName] = useState('');
  const [trainer, setTrainer] = useState(null);
  const [course, setCourse] = useState(null);
  const [firstTheory, setfirstTheory] = useState(null);
  const [theory, setTheory] = useState([]);
  const [totTheory, setTotTheory] = useState([]);

  useEffect(() => {
    API.adminc.course
      .course_theories(id)
      .then((json) => {
        setCourse(json.data);
        setCourseName(json.data[0].course_name);
        setTrainer(json.data[0].trainer);
        setTheory(json.data[0].theories[0]);

        let accordion = [];
        let tottheory = [];
        let i = 1;

        json.data.map((val) => {
          tottheory.push(i - 1);
          i += val.theories.length;
          accordion.push(false);
        });
        accordion[0] = true;
        setposition(0);
        setAccordion(accordion);
        setTotTheory(tottheory);
      })
      .catch((err) => {
        console.log(err);
      });
    API.adminc.company
      .getCompany()
      .then((json) => {
        setCompany(json.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChangeView = (value, theory) => {
    setfirstTheory(null)
    setposition(value);
    let teori = { ...theory };
    if (teori.file) teori.file = teori.file.replace(/ /g, "%20");
    setTheory(teori);
  };

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"Pelatihan Saya"}>
          <li className="breadcrumb-item">
            <Link to="/admin/pelatihan-saya" className="text-warning">
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Saya</li>
          <li className="breadcrumb-item active">{courseName}</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-md-12 mb-4">
          <Link to={`/admin/detail-pelatihan?id=${id}&status=${status}`}>
            <p className="btn p-0 text-theme">
              <i className="fa fa-arrow-left"></i> Kembali
            </p>
          </Link>
        </div>
        <div className="col-md-7">
          {
            firstTheory !== null &&
            (firstTheory.type === "Video" ? (
              <PelatihanVideoView
                url={firstTheory.file}
                user={trainer}
              />
            ) : firstTheory.type === "Slide Powerpoint" ? (
              <PelatihanVideoView
                url={firstTheory.file}
                user={trainer}
              />
            ) : firstTheory.type === "E-Book" ? (
              <PelatihanPDFView
                link={firstTheory.file.replace(/ /g, "%20")}
                name={firstTheory.name}
              />
            ) : firstTheory.type === "Quiz" ? (
              <PelatihanKuisView
                questions={{
                  name: firstTheory.name,
                  question: firstTheory.questions,
                }}
              />
            ) : firstTheory.type === "Tugas Final" ? (
              <PelatihanTugasFinalView
                questions={{
                  name: firstTheory.name,
                  question: firstTheory.questions,
                }}
              />
            ) : firstTheory.type === "Virtual Class" ? (
              <PelatihanVirtualClassView
                firstTheory={{
                  name: firstTheory.name,
                  date: firstTheory.date,
                  start: firstTheory.start_meeting,
                  end: firstTheory.end_meeting,
                  vc_link: firstTheory.vc_link,
                  vc_meeting_id: firstTheory.vc_meeting_id,
                  vc_password: firstTheory.vc_password,
                }}
              />
            ) : firstTheory.type === "Face to Face" ? (
              <PelatihanFaceToFaceView
                firstTheory={{
                  name: firstTheory.name,
                  date: firstTheory.date,
                  start: firstTheory.start_meeting,
                  end: firstTheory.end_meeting,
                  location: firstTheory.location,
                  detail_location: firstTheory.detail_location,
                  company_name: company.name
                }}
              />
            ) : (
              ""
            ))
          }
          {theory &&
            (theory.type === "Video" ? (
              <PelatihanVideoView
                url={theory.file}
                user={trainer}
              />
            ) : theory.type === "Slide Powerpoint" ? (
                <PelatihanPDFView
                    link={theory.file.replace(/ /g, "%20")}
                    name={theory.name}
                />
            ) : theory.type === "E-Book" ? (
              <PelatihanPDFView
                link={theory.file.replace(/ /g, "%20")}
                name={theory.name}
              />
            ) : theory.type === "Quiz" ? (
              <PelatihanKuisView
                questions={{
                  name: theory.name,
                  question: theory.questions,
                }}
              />
            ) : theory.type === "Tugas Final" ? (
              <PelatihanTugasFinalView
                questions={{
                  name: theory.name,
                  question: theory.questions,
                }}
              />
            ) : theory.type === "Virtual Class" ? (
              <PelatihanVirtualClassView
                theory={{
                  name: theory.name,
                  date: theory.date,
                  start: theory.start_meeting,
                  end: theory.end_meeting,
                  vc_link: theory.vc_link,
                  vc_meeting_id: theory.vc_meeting_id,
                  vc_password: theory.vc_password,
                }}
              />
            ) : theory.type === "Face to Face" ? (
              <PelatihanFaceToFaceView
                theory={{
                  name: theory.name,
                  date: theory.date,
                  start: theory.start_meeting,
                  end: theory.end_meeting,
                  location: theory.location,
                  detail_location: theory.detail_location,
                  company_name: company.name
                }}
              />
            ) : (
              ""
            ))}
        </div>
        {course && (
          <div className="col-md-5">
            <div className="card p-5">
              <h6
                className="text-muted mb-3"
                style={{ fontWeight: "600", fontSize: "22px" }}
              >
                Topik yang dibahas
              </h6>
              {course.map((val, key) => {
                return (
                  <div
                    className="default-according style-1 faq-accordion job-accordion"
                    id="accordionoc"
                  >
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-header">
                            <h5 className="mb-0">
                              <button
                                className="btn btn-link pl-0 font-weight-bold"
                                data-toggle="collapse"
                                onClick={() => {
                                  let accord = [...accordions];
                                  accord[key] = !accord[key];
                                  setAccordion(accord);
                                }}
                                data-target="#collapseicon"
                                aria-expanded={accordions[key]}
                                aria-controls="collapseicon"
                              >
                                {val.name}
                              </button>
                            </h5>
                          </div>
                          <Collapse isOpen={accordions[key]}>
                            <div
                              className="collapse show"
                              id="collapseicon"
                              aria-labelledby="collapseicon"
                              data-parent="#accordion"
                            >
                              <div className="card-body filter-cards-view animate-chk p-3">
                                {val.theories.map((t, k) => {
                                    return <div className="p-0 mt-3">
                                        <label htmlFor="checkbox1" onClick={() => {
                                            handleChangeView(totTheory[key] + k, t)
                                        }}
                                           style={{ width: "85%" }}
                                        >
                                            <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}>{t.name}</p>
                                            {
                                                t.type === 'Slide Powerpoint' ?
                                                    <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                                        className='fa fa fa-file-powerpoint-o' /></span>&nbsp;PPT</p> :
                                                    t.type === 'Video' ?
                                                        <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                                            className='fa fa-play-circle-o' /></span>&nbsp;Video</p> :
                                                        t.type === 'E-Book' ?
                                                            <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                                                className='fa fa fa-file-pdf-o' /></span>&nbsp;PDF</p> :
                                                            t.type === 'Quiz' ?
                                                                <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i
                                                                    className='fa fa-pencil-square-o' /></span>&nbsp;Kuis</p> :
                                                                <p className={position === totTheory[key] + k ? `text-theme mb-0` : `text-dark mb-0`}><span className='text-theme'><i className='fa fa-clipboard' /></span>&nbsp;Ujian Final</p>
                                            }
                                        </label>
                                    </div>
                                })}
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DetailPelatihanCourse;
