import Get from "../Get";
import Post from "../Post";
import Delete from "../Delete";

const getAll = (id) => Get(`admin-c/final-task?theory_id=${id}`);
const getOne = (id) => Get(`admin-c/final-task/${id}`);
const insert = data => Post(`admin-c/final-task`, data);
const update = (id, data) => Post(`admin-c/final-task/${id}`, data);
const del = (id) => Delete(`admin-c/final-task/${id}`);

const FinalAdminC = {
    getAll,
    insert,
    getOne,
    update,
    del
};

export default FinalAdminC;
