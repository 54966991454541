import React, { useState } from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link } from 'react-router-dom'
import { Layers } from 'react-feather';
import sertif_IMG from '../../../assets/images/sertif-image.png';
import sertif_no_match from '../../../assets/images/sertif-no-match.png';
import sertif_dummy from '../../../assets/images/sertif-dummy.png';
import { Form } from 'semantic-ui-react';
import API from "../../../api";

const SertifikatCek = () => {
  const [NoSertif, setNoSertif] = useState('');
  const [isSubmitting, setIsSubmitting] = useState('');
  const [SertifStatus, setSertifStatus] = useState(false)
  const [certificate, setCertificate] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSubmitting(true);

    API.ketua.course.check_certificate({no_certificate: NoSertif})
    .then(json => {
      setCertificate(json.data);
      setSertifStatus('match');
      setIsSubmitting(false);
    })
    .catch(e => {
      setSertifStatus('no_match');
      setIsSubmitting(false);
    });
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Cek Sertifikat'}>
          <li className="breadcrumb-item">
            <Link to="/ketua/cek-sertifikat" className='text-warning'>
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Pelatihan Saya</li>
          <li className="breadcrumb-item">Cek Sertifikat</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='card p-5 text-center'>
            <img className='img-100 mx-auto mb-2' src={sertif_IMG} alt='omind' />
            <h4 className='font-weight-bold mt-4'>Cek keaslian sertifikat Anda</h4>
            <p className='text-muted'>Anda dapat memastikan keaslian sertifikat Anda dengan memasukkan nomor sertifikat Anda</p>
            <Form className='w-50 mx-auto mb-3' onSubmit={handleSubmit}>
              <div className="form-group">
                  <input className="form-control text-center" type="text" name="no_sertif" required placeholder="Ketikkan nomor sertifikat" onChange={(e)=>setNoSertif(e.target.value)}  />
              </div>
              <button  className={`btn btn-pill btn-primary btn-sm w-100`}  type="submit" disabled={isSubmitting}>Cek Sertifikat</button>
            </Form>
            <div>
              {SertifStatus === 'match' ?
              <div>
                <h4 className='font-weight-bold mt-4 text-theme'>Sertifikat ditemukan </h4>
                <div className='row w-50 mx-5 mt-3'>
                  <div className='col-md-6 d-flex'>
                    <label className='mr-auto'>Nomor Sertifikat</label>
                    <label>:</label>
                  </div>
                  <div className='col-md-6 text-left'>
                    <label className='font-weight-bold'>{certificate.no_certificate}</label>
                  </div>
                </div>
                <div className='row w-50 mx-5'>
                  <div className='col-md-6 d-flex'>
                    <label className='mr-auto'>Nama</label>
                    <label>:</label>
                  </div>
                  <div className='col-md-6 text-left'>
                    <label>{certificate.user_id.name}</label>
                  </div>
                </div>
                <div className='row w-50 mx-5'>
                  <div className='col-md-6 d-flex'>
                    <label className='mr-auto'>Nama Pelatihan</label>
                    <label>:</label>
                  </div>
                  <div className='col-md-6 text-left'>
                    <label>{certificate.course_id.name}</label>
                  </div>
                </div>
                <div className='row w-50 mx-5'>
                  <div className='col-md-6 d-flex'>
                    <label className='mr-auto'>Perusahaan</label>
                    <label>:</label>
                  </div>
                  <div className='col-md-6 text-left'>
                    <label>{certificate.course_id.company.name}</label>
                  </div>
                </div>
                <div className='row w-50 mx-5'>
                  <div className='col-md-6 d-flex'>
                    <label className='mr-auto'>Tanggal Didapatkan</label>
                    <label>:</label>
                  </div>
                  <div className='col-md-6 text-left'>
                    <label>{certificate.obtained_on}</label>
                  </div>
                </div>
              </div>
              : SertifStatus === 'no_match' ?
              <div>
                <img className='w-25 mx-auto mb-2' src={sertif_no_match} alt='omind' />
                <h4 className='font-weight-bold mt-4'>Sertifikat tidak ditemukan</h4>
                <p className='text-muted'>Mohon periksa kembali nomor sertifikat Anda</p>
              </div>  : ''
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SertifikatCek;
