import React from "react";
import { Route, Redirect, useParams } from "react-router-dom";
import Layout from "../Layout";

function PrivateRoute({ children, ...rest }) {
  const user = JSON.parse(localStorage.getItem("data"));

  if(user)
      if (user.role.id !== rest.role_id) {
        localStorage.clear();
      }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          user.role.id === rest.role_id ? (
            <Layout as={rest.routeAs}>{children}</Layout>
          ) : (
            <Redirect
              to={{
                pathname: rest.redirectPath,
                state: { from: location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: rest.redirectPath,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
