import React, { useState, useEffect } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation, useHistory } from "react-router-dom";
import { CompanyIcon } from "../../../component/common/constant/menu";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import logo_SDM from "../../../assets/images/logo-SDM.png";
import dupikat from "../../../assets/images/duplikat.png";
import remove from "../../../assets/images/remove.png";
import dummy_king from "../../../assets/images/dummy_king.png";
import API from "../../../api";
import TambahKodePromo from "../kodePromo/TambahKodePromo";
import TambahVoucher from "../kodePromo/TambahKodePromo";
import FormTrainer from "../listTrainer/FormTrainer";
import FormKlien from "../listTrainer/FormKetuaKlien";
import EditForm from "../detailPesertaTerregistrasi/EditForm";
import swal from "sweetalert2";
import cupon from "../../../assets/images/cupon.png";
import {numberWithDots} from "../../../helper";
import voucher from "../../../assets/images/voucher-redeem.png";

const DataCompany = () => {
  const history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const per_page = 10;
  const status = "online";

  let page = query.get("page") || 1;
  let pageParticipant = query.get("pageParticipants") || 1;
  let pageCourse = query.get("pageCourse") || 1;
  let pagePromo = query.get("pagePromo") || 1;
  let pageVoucher = query.get("pageVoucher") || 1;
  let pageTransaction = query.get("pageTransaction") || 1;
  let pageTrainer = query.get("pageTrainer") || 1;

  const [modalConfirm, setmodalConfirm] = useState(false);
  const [modalDetail, setmodalDetail] = useState(false);
  const [modalDuplikatDipilih, setmodalDuplikatDipilih] = useState(false);
  const [detailCourse, setDetailCourse] = useState(null);
  const [detailSelected, setdetailSelected] = useState(null);

  const [tambahPromo, settambahPromo] = useState(false);
  const [lihatPromoData, setlihatPromoData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);

  const [tambahVoucher, settambahVoucher] = useState(false);

  const [pageView, setpageView] = useState(null);
  const [editDataAdmin, seteditDataAdmin] = useState({});

  const [participant, setParticipant] = useState({
    peserta: "Hallo",
  });
  const [EditView, setEditView] = useState(false);
  const [modalDelete, setmodalDelete] = useState(false);
  const [order, setOrder] = useState('desc');
  const [orderTransaction, setOrderTransaction] = useState('desc');

  const [lihatPromo, setlihatPromo] = useState(false);

  const [company, setCompany] = useState(null);
  const [modalKtp, setModalKtp] = useState(false);
  const [pelatihan, setPelatihan] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
      custom_queries: {id},
      custom_page: 'Course',
    },
  });
  const [promos, setPromos] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
      custom_page: 'Promo',
      custom_queries: {id},
    },
  });
  const [vouchers, setVouchers] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
      custom_page: 'Voucher',
      custom_queries: {id},
    },
  });
  const [transactions, setTransactions] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
      custom_page: 'Transaction',
      custom_queries: {id},
    },
  });
  const [trainers, setTrainers] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
      custom_page: 'Trainer',
      custom_queries: {id},
    },
  });
  const [participants, setParticipants] = useState({
    data: [],
    meta: {
        from: 1,
        to: 0,
        total: 0,
        current_page: pageParticipant,
        per_page,
        url: "",
        custom_page: 'Participants',
        custom_queries: {id}
    },
  });

  const [adminC, setAdminC] = useState(null);
  const [chairman, setChairman] = useState(null);
  const [searchParticipant, setSearchParticipant] = useState('');
  const [searchCourse, setSearchCourse] = useState('');

    const [modalDetailVoucher, setmodalDetailVoucher] = useState(false);
    const [detailVoucherData, setdetailVoucherData] = useState({});

  useEffect(() => {
    API.superadmin.company
      .detail_perusahaan(id)
      .then((json) => {
        setCompany(json.data.company);

        setAdminC(json.data.adminc);

        setChairman(json.data.chairman);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    API.superadmin.company.peserta(id, {per_page, page: pageParticipant, search: searchParticipant, order})
        .then(json => {
            let temp_participants = { ...participants };
            temp_participants.data = [];

            json.data.data.map((v) => {
                temp_participants.data.push({
                    peserta: v.name,
                    tanggal: v.created_at,
                    bidang: v.company,
                    status: v.status,
                    avatar: v.photo,
                });
            });

            temp_participants.meta.from = (pageParticipant - 1) * per_page + 1;
            temp_participants.meta.to =
                per_page > json.data.total
                    ? json.data.total
                    : pageParticipant * per_page > json.data.total
                    ? json.data.total
                    : pageParticipant * per_page;
            temp_participants.meta.total = json.data.total;
            temp_participants.meta.current_page = pageParticipant;
            temp_participants.meta.total_page = Math.ceil(
                temp_participants.meta.total / temp_participants.meta.per_page
            );

            setParticipants(temp_participants);
        });
  }, [pageParticipant, searchParticipant, order]);

  useEffect(() => {
      API.superadmin.company.pelatihan(id, {per_page, page: pageCourse, search: searchCourse})
          .then(json => {
              let temp_course = { ...pelatihan };
              temp_course.data = [];

              json.data.data.map((val) => {
                  temp_course.data.push({
                      id: val.id,
                      pelatihan: val.name,
                      company: val.company.name,
                      jenis_pelatihan: val.category,
                      tanggal: val.created_at,
                      status: val.status,
                  });
              });

              temp_course.meta.from = (pageCourse - 1) * per_page + 1;
              temp_course.meta.to =
                  per_page > json.data.total
                      ? json.data.total
                      : pageCourse * per_page > json.data.total
                      ? json.data.total
                      : pageCourse * per_page;
              temp_course.meta.total = json.data.total;
              temp_course.meta.current_page = pageCourse;
              temp_course.meta.total_page = Math.ceil(
                  temp_course.meta.total / temp_course.meta.per_page
              );

              setPelatihan(temp_course);
          });
  }, [pageCourse, searchCourse]);

  useEffect(() => {
      API.superadmin.company.promo(id, {per_page, page: pagePromo})
          .then(json => {
              let temp_promo = { ...promos };
              temp_promo.data = [];

              json.data.data.map((val, index) => {
                  temp_promo.data.push({
                      id: val.id,
                      no: index + 1,
                      kode: val.code,
                      perusahaan: val.company,
                      periode: val.period,
                      syarat: val.condition,
                      status: val.status,
                  });
              });

              temp_promo.meta.from = (pagePromo - 1) * per_page + 1;
              temp_promo.meta.to =
                  per_page > json.data.total
                      ? json.data.total
                      : pagePromo * per_page > json.data.total
                      ? json.data.total
                      : pagePromo * per_page;
              temp_promo.meta.total = json.data.total;
              temp_promo.meta.current_page = pagePromo;
              temp_promo.meta.total_page = Math.ceil(
                  temp_promo.meta.total / temp_promo.meta.per_page
              );
              setPromos(temp_promo);
          });
  }, [pagePromo]);

  useEffect(() => {
        API.superadmin.company.voucher(id, {per_page, page: pageVoucher})
            .then(json => {
                let temp_voucher = { ...vouchers };
                temp_voucher.data = [];

                json.data.data.map((val) => {
                    temp_voucher.data.push({
                        id: val.id,
                        dibuat_pada: val.created_at,
                        status: val.status,
                        keterangan: val.description,
                        nominal: 'Rp '+numberWithDots(val.nominal),
                    });
                });

                temp_voucher.meta.from = (pageVoucher - 1) * per_page + 1;
                temp_voucher.meta.to =
                    per_page > json.data.total
                        ? json.data.total
                        : pageVoucher * per_page > json.data.total
                        ? json.data.total
                        : pageVoucher * per_page;
                temp_voucher.meta.total = json.data.total;
                temp_voucher.meta.current_page = pageVoucher;
                temp_voucher.meta.total_page = Math.ceil(
                    temp_voucher.meta.total / temp_voucher.meta.per_page
                );
                setVouchers(temp_voucher);
            });
    }, [pageVoucher]);

  useEffect(() => {
      API.superadmin.company.transaksi(id, {per_page, page: pageTransaction, order: orderTransaction})
          .then(json => {
              let temp_transaction = { ...transactions };
              temp_transaction.data = [];

              json.data.data.map((val) => {
                  temp_transaction.data.push({
                      id: val.id,
                      no: val.no_transaction,
                      tanggal: val.created_at,
                      avatar: val.participant.photo,
                      peserta: val.participant.name,
                      harga: new Intl.NumberFormat("id", {
                          style: "currency",
                          currency: "IDR",
                      }).format(val.price),
                      status: val.status,
                  });
              });

              temp_transaction.meta.from = (pageTransaction - 1) * per_page + 1;
              temp_transaction.meta.to =
                  per_page > json.data.total
                      ? json.data.total
                      : pageTransaction * per_page > json.data.total
                      ? json.data.total
                      : pageTransaction * per_page;
              temp_transaction.meta.total = json.data.total;
              temp_transaction.meta.current_page = pageTransaction;
              temp_transaction.meta.total_page = Math.ceil(
                  temp_transaction.meta.total / temp_transaction.meta.per_page
              );
              setTransactions(temp_transaction);
          });
  }, [pageTransaction, orderTransaction]);

  useEffect(() => {
      API.superadmin.company.trainer(id, {per_page, page: pageTrainer})
          .then(json => {
              let temp_trainer = { ...trainers };
              temp_trainer.data = [];

              json.data.data.map((val, index) => {
                  temp_trainer.data.push({
                      id: val.id,
                      bidang: val.profession,
                      tanggal: val.created_at,
                      avatar: val.photo,
                      peserta: val.name,
                      status: val.status,
                  });
              });

              temp_trainer.meta.from = (page - 1) * per_page + 1;
              temp_trainer.meta.to =
                  per_page > json.data.total
                      ? json.data.total
                      : pageTrainer * per_page > json.data.total
                      ? json.data.total
                      : pageTrainer * per_page;
              temp_trainer.meta.total = json.data.total;
              temp_trainer.meta.current_page = pageTrainer;
              temp_trainer.meta.total_page = Math.ceil(
                  temp_trainer.meta.total / temp_trainer.meta.per_page
              );
              setTrainers(temp_trainer);
          });
  }, [pageTrainer]);

    const deleteCourse = (id, idx) => {
        swal.fire({
            title: 'Anda yakin?',
            text: "Pastikan pelatihan ini belum dibeli. Pelatihan yang dihapus tidak bisa dikembalikan",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus!',
            cancelButtonText: 'Batal',
        }).then((result) => {
            if (result.isConfirmed) {
                swal.fire({
                    onBeforeOpen () {
                        swal.showLoading()
                    },
                    onAfterClose () {
                        swal.hideLoading()
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });

                API.superadmin.course.hapus(id)
                    .then(json => {
                        swal.fire('Berhasil menghapus pelatihan!', '', 'success');

                        let temp_courses = {...pelatihan};
                        temp_courses.data.splice(idx, 1);
                        setPelatihan(temp_courses);
                    }).catch(e => {
                    swal.fire('Gagal menghapus pelatihan!', 'Mungkin pelatihan ini sudah ada yang membeli', 'error');
                })
            }
        })
    }

    const deletePromo = (id, idx) => {
        swal.fire({
            title: 'Apakah anda yakin menghapus promo ini?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus!',
            cancelButtonText: 'Batal',
        }).then((result) => {
            if (result.isConfirmed) {
                swal.fire({
                    onBeforeOpen () {
                        swal.showLoading()
                    },
                    onAfterClose () {
                        swal.hideLoading()
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });

                API.superadmin.promo.delete_promo(id)
                    .then(json => {
                        swal.fire('Berhasil menghapus promo!', '', 'success');

                        let temp = {...promos};
                        temp.data.splice(idx, 1);
                        setPelatihan(temp);
                    }).catch(e => {
                    swal.fire('Gagal menghapus promo!', '', 'error');
                })
            }
        })
    }

    const deleteVoucher = (id, idx) => {
        swal.fire({
            title: 'Apakah anda yakin menghapus voucher ini?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus!',
            cancelButtonText: 'Batal',
        }).then((result) => {
            if (result.isConfirmed) {
                swal.fire({
                    onBeforeOpen () {
                        swal.showLoading()
                    },
                    onAfterClose () {
                        swal.hideLoading()
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });

                API.superadmin.vouchers.delete_voucher(id)
                    .then(json => {
                        swal.fire('Berhasil menghapus voucher!', '', 'success');

                        let temp = {...vouchers};
                        temp.data.splice(idx, 1);
                        setVouchers(temp);
                    }).catch(e => {
                    swal.fire('Gagal menghapus voucher!', '', 'error');
                })
            }
        })
    }

    const handleViewDetail = (row) => {
        setdetailVoucherData(row);
        setmodalDetailVoucher(!modalDetailVoucher);
    };

  const pelatihanPerusahaanTabel = [
    {
      dataField: "id",
      text: "ID",
      headerStyle: () => ({ width: "5%", textAlign: "left" }),
    },
    {
      dataField: "pelatihan",
      text: "Nama Pelatihan",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
    },
    {
      dataField: "jenis_pelatihan",
      text: "Jenis Pelatihan",
      headerStyle: () => ({ width: "15%", textAlign: "left" }),
    },
    {
      dataField: "tanggal",
      text: "Tanggal Dibuat",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
    },
    {
      dataField: "",
      text: "Action",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      formatter: (cel, row, idx) => {
        return (
          <div className="d-flex justify-content-center">
            <Link
                to={`/superadmin/detail-pelatihan?id=${row.id}&company=${row.company}`}
                className="mx-auto"
            >
              <button
                className=" btn rounded-circle btn-success btn-xs py-1"
                type="button"
              >
                {" "}
                <i className="fa fa-eye"></i>{" "}
              </button>
            </Link>
            <Link
              to={`/superadmin/detail-pelatihan?status=${row.status.toLowerCase()}&id=${
                row.id
              }`}
              className="mx-auto"
            >
              <button
                className=" mx-auto btn rounded-circle btn-warning btn-xs py-1"
                type="button"
              >
                {" "}
                <i className="fa fa-pencil"></i>{" "}
              </button>
            </Link>
            <button
              className=" mx-auto btn rounded-circle btn-danger btn-xs"
              type="button"
              onClick={() => deleteCourse(row.id, idx)}
            >
              {" "}
              <i className="fa fa-trash"></i>{" "}
            </button>
          </div>
        );
      },
    },
  ];
  const kodePromoTabel = [
    {
      dataField: "no",
      text: "No",
      headerStyle: () => ({ width: "5%", textAlign: "left" }),
    },
    {
      dataField: "kode",
      text: "Nama Kode Promo",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
      formatter: (cel) => {
        return <div style={{ fontWeight: "800" }}>{cel}</div>;
      },
    },
    {
      dataField: "syarat",
      text: "Syarat Promo",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
    },
    {
      dataField: "periode",
      text: "Periode",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      formatter: (cel, row) => {
        return (
          <div
            className={`my-auto mx-auto p-2 text-center card btn-status btn-status_${
              cel === "Aktif" ? "aktif" : cel === "Draft" ? "draft" : "expired"
            }`}
          >
            {cel}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      formatter: (cel, row, idx) => {
        return (
          <div className="d-flex">
              <button
                className=" btn rounded-circle btn-success btn-xs py-1"
                type="button"
                onClick={() => handleLihatPromo(row)}
              >
                {" "}
                <i className="fa fa-eye"></i>{" "}
              </button>
            {row.status !== "Aktif" ? (
                <button
                  className=" mx-auto btn rounded-circle btn-warning btn-xs py-1"
                  type="button"
                  onClick={() => handleEditPromo(row)}
                >
                  {" "}
                  <i className="fa fa-pencil"></i>{" "}
                </button>
            ) : (
              <></>
            )}
            <button
              className="mx-auto btn rounded-circle btn-danger btn-xs"
              type="button"
              onClick={() => deletePromo(row.id, idx)}
            >
              {" "}
              <i className="fa fa-trash"></i>{" "}
            </button>
          </div>
        );
      },
    },
  ];
  const voucherTabel = [
    {
      dataField: "id",
      text: "ID Voucher",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
    },
    {
      dataField: "dibuat_pada",
      text: "Dibuat Pada",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
    },
    {
      dataField: "keterangan",
      text: "Keterangan",
      headerStyle: () => ({ width: "40%", textAlign: "left" }),
    },
    {
      dataField: "nominal",
      text: "Nominal",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      formatter: (cel, row) => {
        return (
          <div
            className={`my-auto mx-auto p-2 text-center card btn-status btn-status_${
              cel === "Aktif"
                ? "aktif"
                : cel === "Draft"
                ? "draft"
                : cel === "Terpakai"
                ? "terpakai"
                : "expired"
            }`}
          >
            {cel}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      formatter: (cel, row, idx) => {
        return (
          <div className="d-flex">
              <button
                className=" btn rounded-circle btn-success btn-xs py-1"
                type="button"
                onClick={() => handleViewDetail(row)}
              >
                {" "}
                <i className="fa fa-eye"></i>{" "}
              </button>
            <button
              className="mx-auto btn rounded-circle btn-danger btn-xs"
              type="button"
              onClick={() => deleteVoucher(row.id, idx)}
            >
              {" "}
              <i className="fa fa-trash"></i>{" "}
            </button>
          </div>
        );
      },
    },
  ];
  const transaksiTabel = [
    {
      dataField: "no",
      text: "No Transaksi",
      headerStyle: () => ({ width: "15%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "tanggal",
      text: "Tanggal Transaksi",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "peserta",
      text: "Nama Peserta",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center mb-2">
            <div>
              <img
                className="img-40 rounded-circle"
                style={{ height: 40, objectFit: "cover" }}
                src={row.avatar}
                alt="#"
              />
            </div>
            <label className="mb-0 ml-2">
              <p className="font-weight-bold">{row.peserta}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "harga",
      text: "Harga",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2 text-center"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <div
            className={`my-auto mx-auto p-2 text-center card btn-status btn-status_${
              cel === "Lunas"
                ? "aktif"
                : cel === "Ditolak Admin"
                ? "ditolak"
                : cel === "Butuh Otorisasi"
                ? "terpakai"
                : "expired"
            }`}
          >
            {cel}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Aksi",
      headerStyle: () => ({ width: "20%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <div className="text-center">
            <button
              className="btn btn-pill btn-outline-primary btn-sm"
              type="button"
              onClick={() => {
                  API.superadmin.transaction
                      .transaction_detail(row.id)
                      .then((json) => {
                          setdetailSelected(json.data);
                      });
                setmodalDetail(!modalDetail);
              }}
            >
              {" "}
              Detail{" "}
            </button>
          </div>
        );
      },
    },
  ];
  const trainerTabel = [
    {
      dataField: "peserta",
      text: "Nama Trainer",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center mb-2">
            <div>
              <img
                className="img-40 rounded-circle"
                style={{ height: 40, objectFit: "cover" }}
                src={row.avatar}
                alt="#"
              />
            </div>
            <label className="mb-0 ml-2">
              <p className="font-weight-bold">{row.peserta}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "bidang",
      text: "Bidang Yang Dikuasai",
      headerStyle: () => ({ width: "20%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "tanggal",
      text: "Tanggal Registrasi",
      headerStyle: () => ({ width: "20%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "",
      text: "",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <Link to={`/superadmin/trainer-detail?id=${row.id}`}>
            <button
              className=" btn btn-pill btn-outline-primary btn-sm font-weight-bold"
              type="button"
            >
              {" "}
              Detail{" "}
            </button>
          </Link>
        );
      },
    },
  ];
  const persertaTerregistrasiTabel = [
    {
      dataField: "peserta",
      text: "Nama Peserta",
      headerStyle: () => ({ width: "30%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center mb-2">
              {
                  row.avatar &&
                  <div>
                      <img
                          className="img-40 rounded-circle"
                          style={{height: 40, objectFit: 'cover'}}
                          src={row.avatar}
                          alt="#"
                      />
                  </div>
              }

            <label className="mb-0 ml-2">
              <p className="font-weight-bold">{row.peserta}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "tanggal",
      text: "Tanggal Registrasi",
      headerStyle: () => ({ width: "30%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "bidang",
      text: "Nama Perusahaan",
      headerStyle: () => ({ width: "20%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "",
      text: "Aksi",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <div className="d-flex justify-content-center">
            <Link to={`/superadmin`} className="mx-auto">
              <button
                className=" btn rounded-circle btn-success btn-xs py-1"
                type="button"
              >
                {" "}
                <i className="fa fa-eye"></i>{" "}
              </button>
            </Link>
            <button
              className=" mx-auto btn rounded-circle btn-warning btn-xs py-1"
              type="button"
              onClick={() => {
                setEditView(true);
                API.superadmin.participant
                  .participant_detail(row.id)
                  .then((json) => {
                    setParticipant(json.data);
                  });
              }}
            >
              {" "}
              <i className="fa fa-pencil"></i>{" "}
            </button>
            <button
              className=" mx-auto btn rounded-circle btn-danger btn-xs"
              type="button"
              onClick={() => {
                handleModalDelete();
                setParticipant(row);
              }}
            >
              {" "}
              <i className="fa fa-trash"></i>{" "}
            </button>
          </div>
        );
      },
    },
  ];

  const chooseCourse = (id) => {
    API.superadmin.course
      .detail_pelatihan(id)
      .then((json) => {
        setDetailCourse(json.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditPromo = (row) => {
    API.superadmin.promo.get_update(row.id).then((json) => {
      history.push({
          pathname: '/superadmin/kode-promo',
          state: {
              promoData: json.data,
              isTambah: true,
              isUbah: true,
          }
      });
    });
  };

    const handleLihatPromo = (row) => {
        API.superadmin.promo.detail_promo(row.id).then((json) => {
            setlihatPromoData(json.data);
        });
        setlihatPromo(!lihatPromo);
    };

  const handleModalDelete = () => {
    setmodalDelete(!modalDelete);
  };

  const handleActiveFilter = e => {
    setOrder(e.target.value);
  };

  if (tambahPromo) {
    return (
      <TambahKodePromo
        settambahPromo={settambahPromo}
        isUpdate={isUpdate}
        promo={lihatPromoData}
      />
    );
  }
  if (tambahVoucher) {
    return (
      <TambahVoucher
        settambahVoucher={settambahVoucher}
        toastSucces={() =>
          toast.success("Berhasil menambahkan voucher", {
            position: toast.POSITION.TOP_CENTER,
          })
        }
      />
    );
  }
  if (pageView === "admin") {
    return (
      <FormTrainer setpageView={setpageView} editDataAdmin={editDataAdmin} />
    );
  }

  if (pageView === "klien") {
    return (
      <FormKlien setpageView={setpageView} editDataAdmin={editDataAdmin} />
    );
  }

  if (EditView) {
    return <EditForm participant={participant} setEditView={setEditView} />;
  }
  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"Company Data"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/data-company" className="text-warning">
              <CompanyIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Company Data</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header d-flex">
              <div className="mr-auto">
                <h4
                  className="text-theme text-work"
                  style={{ fontWeight: "600" }}
                >
                  Company Data
                </h4>
                <span className="text-muted">Data Perusahaan</span>
              </div>
              <div>
                <Link to={`/superadmin/edit-perusahaan/?id=${id}`}>
                  <button
                    className=" btn btn-pill btn-outline-primary btn-sm text-work"
                    type="button"
                    style={{ fontWeight: "600" }}
                  >
                    <i className="fa fa-pencil"></i> &nbsp;Ubah Data Company
                  </button>
                </Link>
              </div>
            </div>
            {company && (
              <div className="row">
                <div className="col-md-9">
                  <div className="card-body">
                    <div className="d-flex mb-4">
                      <div>
                        <h3 className="font-weight-bold">{company.name}</h3>
                        <p className="text-muted mb-0">
                          {" "}
                          <i className="fa fa-building-o"></i> Berdiri sejak{" "}
                          {company.since}
                        </p>
                        <p className="text-muted mb-0">
                          {" "}
                          <i className="fa fa-map-marker"></i> {company.address}
                        </p>
                        <p className="text-muted mb-0">
                          {" "}
                          <i className="fa fa-phone"></i> {company.phone_number}
                          &nbsp;&nbsp;<i className="fa fa-envelope-o"></i>{" "}
                          {company.email}
                        </p>
                        <a href={`/${company.id}/login`}>Link perusahaan</a>
                        <div className="d-flex align-items-center mt-3">
                          <p className="mb-0">Bidang Pelatihan </p>
                          {company.categories.length > 4 ?
                              <div className="row">
                                  {company.categories.map((value) => {
                                      return (
                                          <div className='col-3 mb-2'>
                                              <button
                                                  className="btn btn-pill btn-outline-warning btn-xs ml-3"
                                                  type="button"
                                              >
                                                  {value.name}
                                              </button>
                                          </div>
                                      );
                                  })}
                              </div> :
                              company.categories.map((value) => {
                                  return (
                                      <button
                                          className="btn btn-pill btn-outline-warning btn-xs ml-3"
                                          type="button"
                                      >
                                          {value.name}
                                      </button>
                                  );
                              })
                          }
                        </div>
                      </div>
                    </div>

                    <h5>Kontak Perusahaan</h5>
                    <div className="row">
                      <div className="col-md-6">
                        <p>PIC Admin</p>
                        <div className="d-flex mb-2">
                          <div>
                            <img
                              className="img-40 rounded-circle"
                              style={{height: 40, objectFit: 'cover'}}
                              src={company.pic_adminc.photo}
                              alt="#"
                            />
                          </div>
                          <label className="mb-0 ml-2">
                            <h6 className="font-weight-bold mb-0">
                              {company.pic_adminc.name}
                            </h6>
                            <p className="mb-0">
                              <i className="fa fa-envelope-o"></i>{" "}
                              {company.pic_adminc.email}
                            </p>
                            <p>
                              <i className="fa fa-phone"></i>
                              {company.pic_adminc.phone} (WA / Call)
                            </p>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <p>PIC Ketua</p>
                        <div className="d-flex mb-2">
                          <div>
                            <img
                              className="img-40 rounded-circle"
                              style={{height: 40, objectFit: 'cover'}}
                              src={company.pic_chairman.photo}
                              alt="#"
                            />
                          </div>
                          <label className="mb-0 ml-2">
                            <h6 className="font-weight-bold mb-0">
                              {company.pic_chairman.name}
                            </h6>
                            <p className="mb-0">
                              <i className="fa fa-envelope-o"></i>{" "}
                              {company.pic_chairman.email}
                            </p>
                            <p>
                              <i className="fa fa-phone"></i>
                              {company.pic_chairman.phone}
                              (WA / Call)
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <h5>Profil Singkat</h5>
                    <p className="text-muted text-justify">
                      {company.short_profile}
                    </p>
                  </div>
                </div>
                <div className="col-md-3 pt-5">
                  <div className="w-50 card border p-3">
                    <img
                      className="img-fluid w-100"
                      src={company.logo}
                      alt=""
                    />
                  </div>
                  <p className="text-muted mb-0">Direktur</p>
                  <h5>{company.director_name}</h5>
                  <button
                    className="btn btn-pill btn-outline-primary btn-sm mt-3 text-work"
                    type="button"
                    style={{ fontWeight: "600" }}
                    onClick={() => setModalKtp(!modalKtp)}
                  >
                    Lihat KTP Direktur
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={modalKtp} toggle={() => setModalKtp(!modalKtp)}>
        <ModalHeader toggle={() => setModalKtp(!modalKtp)}>
          KTP Direktur
        </ModalHeader>
        <ModalBody>
          {company && (
            <img
              src={company.director_ktp}
              style={{ display: "block", margin: "0 auto", width: "100%" }}
              alt="KTP Direktur"
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalKtp(!modalKtp)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 d-flex">
              <div className="mr-auto">
                <h4
                  className="text-theme text-work"
                  style={{ fontWeight: "600" }}
                >
                  {" "}
                  PELATIHAN PERUSAHAAN
                </h4>
                <div className="row mt-3">
                  <div className="col-xl-12">
                    <form className="search-form contact-search">
                      <div className="form-group mb-0">
                        <input
                          className="form-control badge-pill"
                          type="text"
                          placeholder="Cari pelatihan ..."
                          value={searchCourse}
                          onChange={e => setSearchCourse(e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-pill btn-outline-primary btn-sm text-work"
                  type="button"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                      history.push({
                          pathname: '/superadmin/tambah-pelatihan',
                          state: {
                              company_id: id
                          }
                      });
                  }}
                >
                  <i className="fa fa-pencil" />
                  &nbsp;Tambah Pelatihan
                </button>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="overflow-auto w-100">
                <TableComp
                  title_paggination="pelatihan"
                  columns={pelatihanPerusahaanTabel}
                  data={pelatihan}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 d-flex">
              <div className="mr-auto">
                <h4
                  className="text-theme text-work"
                  style={{ fontWeight: "600" }}
                >
                  {" "}
                  List Kode Promo Perusahaan
                </h4>
              </div>
              <div>
                <button
                  className="btn btn-pill btn-outline-primary btn-sm text-work"
                  type="button"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                      history.push({
                          pathname: '/superadmin/kode-promo',
                          state: {
                              isTambah: true,
                              company_id: id
                          }
                      });
                  }}
                >
                  <i className="fa fa-pencil" />
                  &nbsp;Tambah Kode Promo
                </button>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="overflow-auto w-100">
                <TableComp
                  title_paggination="promo"
                  columns={kodePromoTabel}
                  data={promos}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 d-flex">
              <div className="mr-auto">
                <h4
                  className="text-theme text-work"
                  style={{ fontWeight: "600" }}
                >
                  {" "}
                  List Voucher Perusahaan
                </h4>
              </div>
              <div>
                <button
                  className="btn btn-pill btn-outline-primary btn-sm text-work"
                  type="button"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                      history.push({
                          pathname: '/superadmin/list-voucher',
                          state: {
                              isTambah: true,
                              company_id: id
                          }
                      });
                  }}
                >
                  <i className="fa fa-pencil" />
                  &nbsp;Tambah Voucher
                </button>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="overflow-auto w-100">
                <TableComp
                  title_paggination="voucher"
                  columns={voucherTabel}
                  data={vouchers}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  LIST TRANSAKSI
                </h5>
              </div>
              <div className="d-flex ">
                <label className="mr-3 my-auto">Urutkan berdasarkan</label>
                <div>
                    <select
                        className="form-control digits"
                        id="exampleFormControlSelect7"
                        onChange={(e) => {
                            history.push(`/superadmin/company-detail?id=${id}`);
                            setOrderTransaction(e.target.value);
                        }}
                    >
                        <option disabled selected>Sorting berdasarkan</option>
                        <option value='desc'>Terbaru</option>
                        <option value='asc'>Terlama</option>
                    </select>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="overflow-auto w-100">
                <TableComp
                  title_paggination="pelatihan"
                  columns={transaksiTabel}
                  data={transactions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

        <Modal isOpen={modalDetail} size="lg" style={{ maxWidth: "1050px" }}>
            <ModalHeader toggle={() => setmodalDetail(!modalDetail)}>
                Detail Transaksi
            </ModalHeader>
            <ModalBody className="pb-5">
                {detailSelected && (
                    <>
                        <div className="d-flex">
                            <div className="mr-auto">
                                <label className="text-muted text-work">No Transaksi</label>
                                <h6 className="mb-4">{detailSelected.no_transaction}</h6>
                            </div>
                            <div className="text-center">
                                <button
                                    className="btn btn-pill btn-outline-primary btn-sm"
                                    type="button"
                                    onClick={() => {
                                        API.superadmin.transaction.invoice(detailSelected.id)
                                            .then(json => {
                                                let val = json.data;
                                                let courses = [];

                                                val.transaction_detail.map(v => {
                                                    let course = v.course_id;
                                                    let schedule = v.schedule_id;

                                                    courses.push({
                                                        id: course.id,
                                                        judul: `${course.name} ${schedule ? `- ${schedule.name}` : ''}`,
                                                        status: course.type,
                                                        thumbnail: course.thumbnail,
                                                        price: course.price,
                                                        tanggal: v.periode_course,
                                                        user_name: course.trainer_id.name,
                                                        user_company: course.company_id.name,
                                                        user_image: course.trainer_id.profile_picture,
                                                    });
                                                });

                                                history.push({
                                                    pathname: '/superadmin/unduh-invoice',
                                                    state: { detail: {
                                                            id: val.id,
                                                            user_name: val.user_name,
                                                            tanggal: val.transaction_date,
                                                            status: val.status,
                                                            no_transaksi: val.no_transaction,
                                                            metode: val.metode,
                                                            promo: val.promo_id ? (val.promo_id.discount_type === 'Persen' ? `-${val.promo_id.nominal}%` : `- Rp ${val.promo_id.nominal}`) : null,
                                                            harga_promo: val.promo_id ? (val.promo_id.discount_type === 'Persen' ? (val.subtotal * val.promo_id.nominal / 100) : `Rp ${numberWithDots(val.promo_id.nominal)}`) : null,
                                                            tanggal_pembayaran: val.expired_pay,
                                                            invoice: val.status === 'Lunas',
                                                            total_harga: `Rp ${numberWithDots(val.total)}`,
                                                            pelatihan: courses,
                                                            subtotal: val.subtotal,
                                                            point: val.point,
                                                            admin_fee: val.admin_fee,
                                                            total: val.total,
                                                            token: val.token,
                                                            va_account: val.va_account,
                                                        } }
                                                })
                                            });
                                    }}
                                >
                                    {" "}
                                    <i className="fa fa-download"></i> &nbsp;Unduh Invoice
                                </button>
                            </div>
                        </div>
                        <label className="text-muted text-work">No Transaksi</label>
                        <div className="d-flex align-items-center mb-4">
                            <div>
                                <img
                                    className="img-40 rounded-circle"

                                    src={detailSelected.buyer.photo}
                                    alt="#"
                                />
                            </div>
                            <label className="mb-0 ml-2">
                                <p className="font-weight-bold mb-0">
                                    {detailSelected.buyer.name}
                                </p>
                            </label>
                        </div>
                        <label className="text-muted text-work">Status</label>
                        <h6
                            className={`w-25 p-2 text-center card btn-status btn-status_${
                                detailSelected.status === "Lunas"
                                    ? "aktif"
                                    : detailSelected.status === "Kedaluarsa"
                                    ? "ditolak"
                                    : detailSelected.status === "Menunggu Pembayaran"
                                        ? "terpakai"
                                        : detailSelected.status === "Tagihan"
                                            ? "terpakai"
                                            : "expired"
                                }`}
                        >
                            {detailSelected.status}
                        </h6>
                        <label className="text-muted text-work">Tanggal Pembelian</label>
                        <h6 className="mb-4">{detailSelected.buy_date}</h6>
                        <hr />
                        <div>
                            <p
                                className="text-muted"
                                style={{ fontWeight: "600", fontSize: "18px" }}
                            >
                                Daftar Pelatihan
                            </p>
                            {detailSelected?.courses &&
                            detailSelected.courses.map((data, key) => (
                                <div className="row mb-4">
                                    <div className="col-md-6 d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <img
                                                    className="img-60"
                                                    style={{height: 60, objectFit: 'cover'}}
                                                    src={data.course.photo}
                                                    alt="#"
                                                />
                                            </div>
                                            <label className="mb-0 ml-2">
                                                <p
                                                    className="mb-0"
                                                    style={{ fontSize: "14px", fontWeight: "600" }}
                                                >
                                                    {data.course.name}
                                                </p>
                                                <div
                                                    className={`badge text-wrap text-capitalize ${
                                                        data.course.type === "online"
                                                            ? "badge-primary"
                                                            : data.course.type === "Face to Face"
                                                            ? "badge-danger"
                                                            : "badge-pink"
                                                        }`}
                                                >
                                                    {data.course.type}
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4 border-right border-left d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <img
                                                    className="img-40 rounded-circle"
                                                    style={{height: 40, objectFit: 'cover'}}
                                                    src={data.trainer.photo}
                                                    alt="#"
                                                />
                                            </div>
                                            <label className="mb-0 ml-2">
                                                <p className="font-weight-bold mb-0">
                                                    {data.trainer.name}
                                                </p>
                                                <p className="mb-0">{data.trainer.company}</p>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-2 d-flex align-items-center">
                                        <p className="font-weight-bold mb-0 txt-success">
                                            {new Intl.NumberFormat("id", {
                                                style: "currency",
                                                currency: "IDR",
                                            }).format(data.course.price)}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <hr />
                        <p
                            className="text-muted"
                            style={{ fontWeight: "600", fontSize: "18px" }}
                        >
                            Pembayaran
                        </p>
                        <div className="d-flex mx-5">
                            <p
                                className="text-muted text-work mb-0 mr-auto"
                                style={{ fontSize: "16px" }}
                            >
                                Subtotal Produk ({detailSelected.course_total} pelatihan)
                            </p>
                            <p
                                className="mb-0"
                                style={{ fontSize: "18px", fontWeight: "600" }}
                            >
                                {new Intl.NumberFormat("id", {
                                    style: "currency",
                                    currency: "IDR",
                                }).format(detailSelected.subtotal)}
                            </p>
                        </div>
                        {detailSelected.promo_discount.discount_type !== null && (
                            <>
                                <div className="d-flex mx-5">
                                    <p
                                        className="text-muted text-work mb-0 mr-auto"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Kupon Promo{" "}
                                    </p>
                                    <p
                                        className="mb-0"
                                        style={{ fontSize: "18px", fontWeight: "600" }}
                                    >
                                        {detailSelected.promo_discount.discount_type === "Persen"
                                            ? detailSelected.promo_discount.nominal + "%"
                                            : detailSelected.promo_discount.discount_type ===
                                            "Rupiah"
                                                ? new Intl.NumberFormat("id", {
                                                    style: "currency",
                                                    currency: "IDR",
                                                }).format(detailSelected.promo_discount.nominal)
                                                : null}
                                    </p>
                                </div>
                            </>
                        )}
                        {detailSelected.point !== null && (
                            <>
                                <div className="d-flex mx-5">
                                    <p
                                        className="text-muted text-work mb-0 mr-auto"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Points
                                    </p>
                                    <p
                                        className="mb-0"
                                        style={{ fontSize: "18px", fontWeight: "600" }}
                                    >
                                        {new Intl.NumberFormat("id", {
                                            style: "currency",
                                            currency: "IDR",
                                        }).format(detailSelected.point)}
                                    </p>
                                </div>
                            </>
                        )}
                        <div className="d-flex mx-5">
                            <p
                                className="text-muted text-work mb-0 mr-auto"
                                style={{ fontSize: "16px" }}
                            >
                                Biaya Admin
                            </p>
                            <p
                                className="mb-0"
                                style={{ fontSize: "18px", fontWeight: "600" }}
                            >
                                {new Intl.NumberFormat("id", {
                                    style: "currency",
                                    currency: "IDR",
                                }).format(detailSelected.admin_fee)}
                            </p>
                        </div>
                        <div className="d-flex mx-5">
                            <p
                                className="text-muted text-work mb-0 mr-auto"
                                style={{ fontSize: "16px" }}
                            >
                                Total Keseluruhan
                            </p>
                            <p
                                className="mb-0 txt-success"
                                style={{ fontSize: "18px", fontWeight: "600" }}
                            >
                                {new Intl.NumberFormat("id", {
                                    style: "currency",
                                    currency: "IDR",
                                }).format(detailSelected.total)}
                            </p>
                        </div>
                        <div className="d-flex mx-5">
                            <p
                                className="text-muted text-work mb-0 mr-auto"
                                style={{ fontSize: "16px" }}
                            >
                                Metode Pembayaran
                            </p>
                            <p
                                className="mb-0"
                                style={{ fontSize: "18px", fontWeight: "600" }}
                            >
                                {detailSelected.method}
                            </p>
                        </div>
                    </>
                )}
            </ModalBody>
        </Modal>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 pb-3 d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  LIST TRAINER
                </h5>
              </div>
            </div>
            <div className="card-body">
              <div className="overflow-auto w-100">
                <TableComp
                  rowClasses="text-center"
                  title_paggination="trainer"
                  columns={trainerTabel}
                  data={trainers}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 pb-3 d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  ADMIN C
                </h5>
                <span className="text-muted">Data Admin C</span>
              </div>
              <div>
                <button
                  className="btn btn-pill btn-outline-primary btn-sm text-work"
                  type="button"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                    setpageView("admin");
                    seteditDataAdmin(adminC);
                  }}
                >
                  <i className="fa fa-pencil" />
                  &nbsp;Ubah Data Admin C
                </button>
              </div>
            </div>
            <div className="card-body">
              {adminC && (
                <div className="d-flex overflow-auto w-100 text-align-left">
                  <div className="col">
                    <img
                      className="img-80 rounded-circle"
                      style={{height: 80, objectFit: 'cover'}}
                      src={adminC.photo}
                      alt="#"
                    />
                  </div>
                  <div className="col">
                    <div>
                      <h6>Email</h6>
                      <h6 style={{ fontSize: "16px", fontWeight: "600" }}>
                        {adminC.email}
                      </h6>
                    </div>
                    <div className="mt-3">
                      <h6>Password</h6>
                      <h6 style={{ fontSize: "16px", fontWeight: "600" }}>
                        *********
                      </h6>
                    </div>
                    <div className="mt-3">
                      <h6>Konfirmasi Password</h6>
                      <h6 style={{ fontSize: "16px", fontWeight: "600" }}>
                        *********
                      </h6>
                    </div>
                  </div>
                  <div className="col">
                    <div>
                      <h6>Nama</h6>
                      <h6 style={{ fontSize: "16px", fontWeight: "600" }}>
                        {adminC.name}
                      </h6>
                    </div>
                    <div className="mt-3">
                      <h6>Jabatan</h6>
                      <h6 style={{ fontSize: "16px", fontWeight: "600" }}>
                        {adminC.profession}
                      </h6>
                    </div>
                    <div className="mt-3">
                      <h6>Nomor Telepon</h6>
                      <h6 style={{ fontSize: "16px", fontWeight: "600" }}>
                        {adminC.phone}
                      </h6>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 pb-3 d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  KETUA KLIEN
                </h5>
                <span className="text-muted">Data Ketua Klien</span>
              </div>
              <div>
                <button
                  className="btn btn-pill btn-outline-primary btn-sm text-work"
                  type="button"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                    setpageView("klien");
                    seteditDataAdmin(chairman);
                  }}
                >
                  <i className="fa fa-pencil" />
                  &nbsp;Ubah Data Ketua Klien
                </button>
              </div>
            </div>
            <div className="card-body">
              {chairman && (
                <div className="d-flex overflow-auto w-100 text-align-left">
                  <div className="col">
                      <img
                          className="img-80 rounded-circle"
                          style={{height: 80, objectFit: 'cover'}}
                          src={chairman.photo}
                          alt="#"
                      />
                  </div>
                  <div className="col">
                    <div>
                      <h6>Email</h6>
                      <h6 style={{ fontSize: "16px", fontWeight: "600" }}>
                        {chairman.email}
                      </h6>
                    </div>
                    <div className="mt-3">
                      <h6>Password</h6>
                      <h6 style={{ fontSize: "16px", fontWeight: "600" }}>
                        *********
                      </h6>
                    </div>
                    <div className="mt-3">
                      <h6>Konfirmasi Password</h6>
                      <h6 style={{ fontSize: "16px", fontWeight: "600" }}>
                        *********
                      </h6>
                    </div>
                  </div>
                  <div className="col">
                    <div>
                      <h6>Nama</h6>
                      <h6 style={{ fontSize: "16px", fontWeight: "600" }}>
                        {chairman.name}
                      </h6>
                    </div>
                    <div className="mt-3">
                      <h6>Jabatan</h6>
                      <h6 style={{ fontSize: "16px", fontWeight: "600" }}>
                        {chairman.profession}
                      </h6>
                    </div>
                    <div className="mt-3">
                      <h6>Nomor Telepon</h6>
                      <h6 style={{ fontSize: "16px", fontWeight: "600" }}>
                        {chairman.phone}
                      </h6>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-3 d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  LIST PESERTA TERREGISTRASI
                </h5>
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="row mr-3">
                  <div className="col-xl-12">
                    <form className="search-form contact-search">
                      <div className="form-group mb-0">
                        <input
                          className="form-control badge-pill"
                          type="text"
                          placeholder="Cari peserta ..."
                          value={searchParticipant}
                          onChange={e => {

                              setSearchParticipant(e.target.value)
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="justify-content-flex-end">
                <Link to="/superadmin/tambah-pelatihan-peserta">
                  <button
                    className="btn btn-pill btn-outline-primary btn-sm text-work mr-1"
                    type="button"
                    style={{ fontWeight: "600" }}
                  >
                    <i className="fa fa-pencil" />
                    &nbsp;Tambah Pelatihan Ke Peserta
                  </button>
                  </Link>
                  <Link to="/superadmin/tambah-bulk">
                    <button
                      className=" btn btn-pill btn-outline-primary btn-sm mx-3"
                      type="button"
                    >
                      {" "}
                      <i className="fa fa-pencil"></i> Tambah Bulk Peserta
                    </button>
                  </Link>
                  <Link to="/superadmin/tambah-peserta">
                    <button
                      className=" btn btn-pill btn-outline-primary btn-sm"
                      type="button"
                    >
                      {" "}
                      <i className="fa fa-pencil"></i> Tambah Peserta
                    </button>
                  </Link>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end mb-3">
                <p
                  className="my-auto mr-3 text-work"
                  style={{ fontSize: "16px" }}
                >
                  Sort by
                </p>
                <div>
                  <select
                    className="form-control digits"
                    id="exampleFormControlSelect7"
                    onChange={handleActiveFilter}
                  >
                    <option disabled selected>Sorting berdasarkan</option>
                    <option value='desc'>Terbaru</option>
                    <option value='asc'>Terlama</option>
                  </select>
                </div>
              </div>
              <div className="overflow-auto w-100">
                <TableComp
                  rowClasses="text-center"
                  title_paggination="peserta"
                  columns={persertaTerregistrasiTabel}
                  data={participants}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalConfirm}
        toggle={() => setmodalConfirm(!modalConfirm)}
        size="md"
      >
        <ModalBody>
          <div className="text-center mt-3">
            <h6>Apakah ini pelatihan baru atau pelatihan duplikat?</h6>
            <Link to="/superadmin/tambah-pelatihan">
              <button
                className="btn btn-pill btn-primary btn-sm my-3 mr-3"
                type="button"
                onClick={() => {
                  setmodalConfirm(!modalConfirm);
                }}
              >
                Pelatihan Baru
              </button>
            </Link>
            <button
              className="btn btn-pill btn-outline-primary btn-sm my-3"
              type="button"
              onClick={() => {
                setmodalConfirm(!modalConfirm);
                setmodalDuplikatDipilih(true);
              }}
            >
              {" "}
              Pelatihan Duplikat
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalDuplikatDipilih}
        size="lg"
        style={{ maxWidth: "1050px" }}
      >
        <ModalHeader
          toggle={() => setmodalDuplikatDipilih(!modalDuplikatDipilih)}
        />
        <ModalBody>
          <div className="text-center mt-5 mb-4">
            <img src={dupikat} alt="" />
          </div>
          <div className="text-center mb-4 border-bottom pb-3">
            <h5 className="text-weight-bold">
              Pilih pelatihan yang mau diduplikat
            </h5>
            <div className="w-50 mx-auto text-left">
              <label className="text-work" style={{ fontSize: "16px" }}>
                Nama Pelatihan
              </label>
              <select
                className="form-control digits"
                defaultValue="default"
                onChange={(event) => chooseCourse(event.target.value)}
                required
              >
                <option value="default" disabled>
                  Pilih Pelatihan
                </option>
                {pelatihan &&
                  pelatihan.data.map((value) => {
                    return (
                      <option value={value.id}>
                        {value.pelatihan} - {value.company}
                      </option>
                    );
                  })}
              </select>
              {/* <input
                type="text"
                className="form-control"
                name="namaPelatihan"
                id="namaPelatihan"
                required
              /> */}
            </div>
            {detailCourse && (
              <div className="row w-75 mx-auto mt-4">
                <div className="col-md-4">
                  <img
                    class="img-fluid w-100 mb-3"
                    src={detailCourse.photo}
                    alt="omind"
                  />
                </div>
                <div className="col-md-8 text-left">
                  <h3 className="font-weight-bold">{detailCourse.nama}</h3>
                  <div className="d-flex">
                    <label>
                      <div
                        className={`badge text-wrap text-capitalize ${
                          detailCourse.type === "Online"
                            ? "badge-primary"
                            : detailCourse.type === "Face to Face"
                            ? "badge-danger"
                            : "badge-pink"
                        }`}
                      >
                        {status}
                      </div>
                    </label>
                    <p className="text-muted mr-4 ml-2">
                      {" "}
                      <span className="text-muted">
                        <i className="fa fa-question-circle"></i>
                      </span>
                    </p>
                    <p className="text-muted mr-4">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa-clock-o"></i>
                      </span>
                      &nbsp; Durasi Pelatihan{" "}
                      <span className="text-dark font-weight-bold">
                        {detailCourse.day_duration} hari
                      </span>
                    </p>
                  </div>
                  <p className="text-muted mb-0">
                    Kategori{" "}
                    <span className="text-dark font-weight-bold">
                      {detailCourse.category}
                    </span>
                  </p>
                  <p className="text-muted mb-0">
                    Dibuat oleh{" "}
                    <span className="text-dark font-weight-bold">
                      {detailCourse.created_by}
                    </span>
                  </p>
                  <div className="d-flex justify-content-between mt-3">
                    <p className="text-muted">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa-play-circle-o"></i>
                      </span>
                      &nbsp; {detailCourse.count_videos} {detailCourse.type}
                    </p>
                    <p className="text-muted">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa fa-file-pdf-o"></i>
                      </span>
                      &nbsp; {detailCourse.count_ebooks} PDF
                    </p>
                    <p className="text-muted">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa fa-file-powerpoint-o"></i>
                      </span>{" "}
                      &nbsp; {detailCourse.count_ppt} PPT
                    </p>
                    <p className="text-muted">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa-pencil-square-o"></i>
                      </span>
                      &nbsp; {detailCourse.count_quiz} Kuis
                    </p>
                    <p className="text-muted">
                      {" "}
                      <span className="text-theme">
                        <i className="fa fa-clipboard"></i>
                      </span>
                      &nbsp; {detailCourse.count_final} Ujian Final
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-pill btn-outline-primary btn-sm my-3 mr-3"
            type="button"
            onClick={() => {
              setmodalDuplikatDipilih(!modalDuplikatDipilih);
            }}
          >
            {" "}
            Batal
          </button>
          {detailCourse && (
            <Link
              to={`/superadmin/tambah-pelatihan?duplicate=${detailCourse.id}`}
            >
              <button
                className="btn btn-pill btn-primary btn-sm my-3"
                type="button"
                onClick={() => {
                  setmodalDuplikatDipilih(!modalDuplikatDipilih);
                }}
              >
                {" "}
                Duplikat Pelatihan
              </button>
            </Link>
          )}
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalDelete} toggle={handleModalDelete}>
        <ModalBody>
          <div className="text-center w-50 mx-auto py-5">
            <img
              className="img-fluid mb-4"
              src={remove}
              style={{ width: "73px" }}
              alt="omind"
            />
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              Apakah anda yakin menghapus peserta ini?
            </h3>
            <div className="d-flex justify-content-between mx-3 mt-4">
              <button
                className="btn btn-pill btn-primary btn-sm text-work"
                type="button"
                style={{ fontWeight: "600", width: "88px" }}
                onClick={() => {
                  handleModalDelete();

                  API.superadmin.participant
                    .delete_participant(participant.id)
                    .then((json) => {
                      console.log(json);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
              >
                {" "}
                Ya{" "}
              </button>
              <button
                className="btn btn-pill btn-outline-primary btn-sm text-work"
                type="button"
                style={{ fontWeight: "600", width: "88px" }}
                onClick={handleModalDelete}
              >
                {" "}
                Tidak{" "}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

        <Modal isOpen={modalDelete} toggle={handleModalDelete}>
            <ModalBody>
                <div className="text-center w-50 mx-auto py-5">
                    <img
                        className="img-fluid mb-4"
                        src={remove}
                        style={{ width: "73px" }}
                        alt="omind"
                    />
                    <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
                        Apakah anda yakin menghapus promo ini?
                    </h3>
                    <div className="d-flex justify-content-between mx-3 mt-4">
                        <button
                            className="btn btn-pill btn-primary btn-sm text-work"
                            type="button"
                            style={{ fontWeight: "600", width: "88px" }}
                            onClick={() => {
                                let temp_promo = {...promos};
                                temp_promo.data.splice(lihatPromoData[1], 1);
                                setPromos(temp_promo);
                                handleModalDelete();

                                API.superadmin.promo
                                    .delete_promo(lihatPromoData[0])
                                    .then((json) => {
                                        console.log(json);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }}
                        >
                            {" "}
                            Ya{" "}
                        </button>
                        <button
                            className="btn btn-pill btn-outline-primary btn-sm text-work"
                            type="button"
                            style={{ fontWeight: "600", width: "88px" }}
                            onClick={handleModalDelete}
                        >
                            {" "}
                            Tidak{" "}
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>

        <Modal isOpen={lihatPromo} toggle={handleLihatPromo} size="lg">
            <ModalBody>
                <div className="text-center w-75 mx-auto py-5">
                    <img
                        className="img-fluid mb-4"
                        src={cupon}
                        style={{ width: "73px" }}
                        alt="omind"
                    />
                    <div className="row mt-3">
                        <div className="col-md-6 text-left">
                            <label
                                className="text-work text-muted mb-0"
                                style={{ fontSize: "16px", fontWeight: "normal" }}
                            >
                                Nama Promo
                            </label>
                            <p
                                className="text-work"
                                style={{ fontSize: "18px", fontWeight: "600" }}
                            >
                                {lihatPromoData.code}
                            </p>
                        </div>
                        <div className="col-md-6 text-left">
                            <label
                                className="text-work text-mutted mb-0"
                                style={{ fontSize: "16px", fontWeight: "normal" }}
                            >
                                Status
                            </label>
                            <div
                                className={` my-auto w-75 p-2 text-center card btn-status btn-status_${
                                    lihatPromoData.status === "Aktif"
                                        ? "aktif"
                                        : lihatPromoData.status === "Expired"
                                        ? "expired"
                                        : lihatPromoData.status === "Terpakai"
                                            ? "terpakai"
                                            : "draft"
                                    }`}
                            >
                                {lihatPromoData.status}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6 text-left">
                            <label
                                className="text-work text-muted mb-0"
                                style={{ fontSize: "16px", fontWeight: "normal" }}
                            >
                                Durasi
                            </label>
                            <p
                                className="text-work"
                                style={{ fontSize: "18px", fontWeight: "normal" }}
                            >
                                {lihatPromoData.period}
                            </p>
                        </div>
                        <div className="col-md-6 text-left">
                            <label
                                className="text-work text-muted mb-0"
                                style={{ fontSize: "16px", fontWeight: "normal" }}
                            >
                                Diskon
                            </label>
                            <p
                                className="text-work"
                                style={{ fontSize: "18px", fontWeight: "normal" }}
                            >
                                {lihatPromoData.discount}
                            </p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6 text-left">
                            <label
                                className="text-work text-muted mb-0"
                                style={{ fontSize: "16px", fontWeight: "normal" }}
                            >
                                Perusahaan
                            </label>
                            <p
                                className="text-work"
                                style={{ fontSize: "18px", fontWeight: "normal" }}
                            >
                                {lihatPromoData.company}
                            </p>
                        </div>
                        <div className="col-md-6 text-left">
                            <label
                                className="text-work text-muted mb-0"
                                style={{ fontSize: "16px", fontWeight: "normal" }}
                            >
                                Syarat
                            </label>
                            <p
                                className="text-work"
                                style={{ fontSize: "18px", fontWeight: "normal" }}
                            >
                                {lihatPromoData.condition}
                            </p>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>

        <Modal isOpen={modalDetailVoucher} toggle={handleViewDetail} size="lg">
            <ModalBody>
                <div className="text-center w-75 mx-auto py-5">
                    <img
                        className="img-fluid mb-4"
                        src={voucher}
                        style={{ width: "73px" }}
                        alt="omind"
                    />
                    <h3
                        className="text-work text-muted"
                        style={{ fontSize: "20px", fontWeight: "600" }}
                    >
                        Generate Kode Voucher
                    </h3>
                    <div className="row mt-3">
                        <div className="col-md-6 text-left">
                            <label
                                className="text-work text-muted mb-0"
                                style={{ fontSize: "16px", fontWeight: "normal" }}
                            >
                                Kode Voucher
                            </label>
                            <p
                                className="text-work"
                                style={{ fontSize: "18px", fontWeight: "600" }}
                            >
                                {detailVoucherData.code}
                            </p>
                        </div>
                        <div className="col-md-6 text-left">
                            <label
                                className="text-work text-mutted mb-0"
                                style={{ fontSize: "16px", fontWeight: "normal" }}
                            >
                                Status
                            </label>
                            <div
                                className={` my-auto w-75 p-2 text-center card btn-status btn-status_${
                                    detailVoucherData.status === "Aktif"
                                        ? "aktif"
                                        : detailVoucherData.status === "Expired"
                                        ? "expired"
                                        : detailVoucherData.status === "Terpakai"
                                            ? "terpakai"
                                            : "draft"
                                    }`}
                            >
                                {detailVoucherData.status}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6 text-left">
                            <label
                                className="text-work text-muted mb-0"
                                style={{ fontSize: "16px", fontWeight: "normal" }}
                            >
                                Perusahaan
                            </label>
                            <p
                                className="text-work"
                                style={{ fontSize: "18px", fontWeight: "normal" }}
                            >
                                {detailVoucherData.company}
                            </p>
                        </div>
                        <div className="col-md-6 text-left">
                            <label
                                className="text-work text-muted mb-0"
                                style={{ fontSize: "16px", fontWeight: "normal" }}
                            >
                                Nominal Voucher
                            </label>
                            <p
                                className="text-work"
                                style={{ fontSize: "18px", fontWeight: "normal" }}
                            >
                                {detailVoucherData.nominal}
                            </p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6 text-left">
                            <label
                                className="text-work text-muted mb-0"
                                style={{ fontSize: "16px", fontWeight: "normal" }}
                            >
                                Tanggal Berlaku
                            </label>
                            <p
                                className="text-work"
                                style={{ fontSize: "18px", fontWeight: "normal" }}
                            >
                                {detailVoucherData.start} - {detailVoucherData.end}
                            </p>
                        </div>
                        <div className="col-md-6 text-left">
                            <label
                                className="text-work text-muted mb-0"
                                style={{ fontSize: "16px", fontWeight: "normal" }}
                            >
                                Keterangan Voucher
                            </label>
                            <p
                                className="text-work"
                                style={{ fontSize: "18px", fontWeight: "normal" }}
                            >
                                {detailVoucherData.description}
                            </p>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    </>
  );
};

export default DataCompany;
