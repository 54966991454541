import React, { useState, useEffect, useRef } from "react";
import { BreadcrumbComp } from "../../../component";
import { Layers } from "react-feather";
import { useLocation, Link, useHistory } from "react-router-dom";
import learning_dummy from "../../../assets/images/learning-dummy-image.png";
import API from "../../../api";
import { ToastContainer, toast } from "react-toastify";

const EditPelatihan = () => {
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const history = useHistory();

  const [imagePreview, setimagePreview] = useState(null);
  const [imagePreviewUrl, setimagePreviewUrl] = useState("");

  const [imagePelatihanPreview, setimagePelatihanPreview] = useState(null);
  const [imagePelatihanPreviewUrl, setimagePelatihanPreviewUrl] = useState("");
  const [imageCertificatePreviewUrl, setimageCertificatePreviewUrl] = useState("");

  const [dataPelatihan, setDataPelatihan] = useState(null);
  const [list_category, setListCategory] = useState([]);
  const uploadImgRef = useRef(null);
  const uploadCertificateRef = useRef(null);

  const handleImageChange = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setDataPelatihan({
      ...dataPelatihan,
      certificate_template: file,
    });

    reader.onloadend = () => {
      setimagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (imagePreviewUrl) {
      setimagePreview(
        <img className="w-100" src={imagePreviewUrl} alt="Global English" />
      );
    } else {
      setimagePreview(<div className=""></div>);
    }
  }, [imagePreviewUrl]);

  const handleImagePelatihanChange = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    setDataPelatihan({
      ...dataPelatihan,
      thumbnail: file,
    });

    reader.onloadend = () => {
      setimagePelatihanPreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (imagePelatihanPreviewUrl) {
      setimagePelatihanPreview(imagePelatihanPreviewUrl);
    } else {
      setimagePelatihanPreview(false);
    }
  }, [imagePelatihanPreviewUrl]);

  useEffect(() => {
    API.superadmin.course
      .detail_pelatihan(id)
      .then((detail) => {
        API.category
          .get_all()
          .then(async (category) => {
            setListCategory(category.data);
            await category.data.map((value) => {
              value.name === detail.data.category &&
                setDataPelatihan({
                  name: detail.data.nama,
                  status: detail.data.status,
                  photo: detail.data.photo,
                  certificate: detail.data.certificate,
                  type: detail.data.type,
                  category_id: value.id,
                });
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleChange = (event) => {
    setDataPelatihan({
      ...dataPelatihan,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    API.superadmin.course
      .update_pelatihan(id, dataPelatihan)
      .then((json) => {
        toast.success(json.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          history.push("/superadmin/list-pelatihan");
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Gagal Update Pelatihan", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  return (
    <>
      <ToastContainer />
      <div className="page-header">
        <BreadcrumbComp title={"Edit Pelatihan"}>
          <li className="breadcrumb-item">
            <Link
              to={`/superadmin/edit-pelatihan/?id=${id}`}
              className="text-warning"
            >
              <Layers />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Pelatihan</li>
          <li className="breadcrumb-item">Edit Pelatihan</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header pb-3 d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  EDIT PELATIHAN
                </h5>
                <p
                  className="text-work text-muted"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  Data Pelatihan
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-end mb-3">
                <p
                  className="my-auto mr-3 text-work"
                  style={{ fontSize: "16px" }}
                >
                  Status
                </p>
                <div>
                  <select
                    className="form-control digits"
                    id="exampleFormControlSelect7"
                    name='status'
                    onChange={handleChange}
                    value={dataPelatihan && dataPelatihan.status}
                  >
                    <option value="Nonaktif">Nonaktif</option>
                    <option value="Aktif, Public">Aktif, Public</option>
                    <option value="Active, Private">Active, Private</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="card-body">
              {dataPelatihan && (
                <div className="row">
                  <div className="col-md-8">
                    <p
                      className="text-work text-muted"
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      Informasi Pelatihan
                    </p>
                    <h6>Nama Pelatihan</h6>
                    <input
                      className="form-control"
                      style={{ width: "343px" }}
                      onChange={handleChange}
                      name="name"
                      value={dataPelatihan.name}
                    />
                    <h6 className="mt-4">Jenis Pelatihan</h6>
                    <select
                      className="form-control digits "
                      id="exampleFormControlSelect7"
                      onChange={handleChange}
                      value={dataPelatihan.type}
                      name="type"
                      style={{ width: "343px" }}
                    >
                      <option value="Online">Online</option>
                      <option value="Face to Face">Face to Face</option>
                      <option value="Enquiries">Enqueries</option>
                      <option value="Virtual Class">Virtual Class</option>
                      <option value="General Resource">General Resource</option>
                      <option value="Pelatihan Pengenalan">
                        Pelatihan Pengenalan
                      </option>
                    </select>
                    <h6 className="mt-4">Bidang Pelatihan</h6>
                    <select
                      type="select"
                      className="form-control"
                      onChange={handleChange}
                      value={dataPelatihan.category_id}
                      name="category_id"
                      style={{ width: "343px" }}
                    >
                      <option value="default" disabled>
                        Pilih Bidang Pelatihan
                      </option>
                      {list_category.map((v) => {
                        return <option value={v.id}>{v.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <img
                      className="img-fluid w-100 mb-3"
                      src={imagePelatihanPreviewUrl ? imagePelatihanPreviewUrl : dataPelatihan.photo}
                      alt="omind"
                    />
                    <div className='w-50 mx-auto'>
                      <button className="btn btn-pill btn-primary upload-image" onClick={() => {
                        uploadImgRef.current.click();
                      }}>
                        <i className="fa fa-cloud-upload"></i> &nbsp; Unggah Foto
                      </button>
                    </div>
                    <input
                        id="file-select"
                        ref={uploadImgRef}
                        style={{display: "none"}}
                        type="file"
                        onChange={handleImagePelatihanChange}
                    />
                  </div>
                  <div className="col-md-12 mt-5">
                    <p
                      className="text-work text-muted"
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      Sertifikat
                    </p>
                    <img
                        className="img-fluid w-25 mb-3"
                        src={imagePreviewUrl ? imagePreviewUrl : dataPelatihan.certificate}
                        alt="omind"
                    />
                    <div className="form-group row">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="text-work col-sm-2 col-form-label text-right"
                        style={{ fontSize: "16px" }}
                      >
                        Upload Sertifikat
                      </label>
                      <div className="col-sm-10">
                        <input
                          className="form-control text-dark w-50"
                          type="file"
                          onChange={handleImageChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border-top w-100 d-flex justify-content-end pt-4">
                    <Link to="/superadmin/list-pelatihan">
                      <button className="btn btn-pill btn-outline-primary mr-3">
                        Batal
                      </button>
                    </Link>
                    <button
                      className="btn btn-pill btn-primary"
                      onClick={handleSubmit}
                    >
                      Simpan
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditPelatihan;
