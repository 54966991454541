import React, { useState, useEffect } from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { CompanyIcon } from '../../../component/common/constant/menu';
import { Link, useHistory } from "react-router-dom";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ToastContainer, toast } from 'react-toastify';
import API from "../../../api";

const EditCompany = () => {
  const history = useHistory();
  const [company, setCompany] = useState(null);

  const [imagePreview, setimagePreview] = useState(null);
  const [imagePreviewUrl, setimagePreviewUrl] = useState('');

  const [listProvinsi, setListProvinsi] = useState([]);
  const [listKota, setListKota] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);
  const [listKategori, setListKategori] = useState([]);
  
  const [provinsi, setProvinsi] = useState(null);
  const [kabupaten, setKabupaten] = useState(null);
  const [kecamatan, setKecamatan] = useState(null);
  
  const [name, setName] = useState(null);
  const [kode_pos, setKodePos] = useState(null);
  const [address, setAddress] = useState(null);
  const [phone, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [since, setSince] = useState(null);
  const [category, setCategory] = useState([]);
  const [short_profile, setShortProfile] = useState(null);
  const [director_name, setDirectorName] = useState(null);
  const [director_ktp, setDirectorKtp] = useState(null);

  useEffect(() => {
    API.ketua.company.company_update()
      .then(json => {
        setCompany(json.data)

        setName(json.data.name)
        setSince(json.data.since)
        setKodePos(json.data.kode_pos)
        setAddress(json.data.address)
        setPhoneNumber(json.data.phone_number)
        setEmail(json.data.email)
        setShortProfile(json.data.short_profile)
        setDirectorName(json.data.director_name)
        setKecamatan(json.data.region_id)

        const kategori = []

        json.data.categories.map(v => {
          kategori.push({
            id: v.id,
            name: v.name,
          })
        })

        setCategory(kategori)

        API.rajaongkir.kecamatan(json.data.region_id)
          .then(json => {
            setProvinsi(json.province_id)
            setKabupaten(json.city_id)

            API.rajaongkir.list_kota(json.province_id)
              .then(json => {
                setListKota(json)
              }).catch(err => {
                console.log(err);
              })

            API.rajaongkir.list_kecamatan(json.city_id)
              .then(json => {
                setListKecamatan(json)
              }).catch(err => {
                console.log(err);
              })

          }).catch(err => {
            console.log(err);
          })
        
        API.rajaongkir.list_provinsi()
          .then(json => {
            setListProvinsi(json)
          }).catch(err => {
            console.log(err);
          })

        API.category.get_all()
          .then(json => {
            setListKategori(json.data)
          }).catch(err => {
            console.log(err);
          })

      }).catch(err => {
        console.log(err);
      })
  }, [])

  const handleAddCategory = (val) => {
    const kategori = Object.assign([], category)
  
    const found = kategori.some(el => el.id === val.id);

    if(!found){
      kategori.push({
        id: val.id,
        name: val.name,
      })
    }

    console.log(kategori)
    setCategory(kategori)
  }

  const handleRemoveCategory = (index) => {
    const kategori = Object.assign([], category)
    
    delete kategori[index]

    console.log(kategori);
    setCategory(kategori)
  }

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    setDirectorKtp(file)

    reader.onloadend = () => {
      setimagePreviewUrl(reader.result);
    }

    reader.readAsDataURL(file)
  }

  useEffect(() => {
    if (imagePreviewUrl) {
      setimagePreview(<img style={{ height: '90px' }} src={imagePreviewUrl} alt='Global English' />)
    } else {
      setimagePreview(<div className=""></div>);
    }
  }, [imagePreviewUrl])

  const handleSubmited = (e) => {
    e.preventDefault()

    let cat = []

    category.map(v => {
      cat.push(v.id)
    })

    let category_data = JSON.stringify(cat)

    let data = new FormData()

    data.append('name', name)
    data.append('since', since)
    data.append('address', address)
    data.append('region_id', kecamatan)
    data.append('phone_number', phone)
    data.append('email', email)
    data.append('categories', category_data)
    data.append('short_profile', short_profile)
    data.append('director_name', director_name)

    if(director_ktp){
      data.append('director_ktp', director_ktp)
    }

    API.ketua.company.edit_company(data)
      .then(json => {
        console.log(json.data);
        toast.success("Data Perusahaan Berhasil Diubah", { position: toast.POSITION.TOP_CENTER });
        setTimeout(() => {
          history.push('/ketua/data-company')
        }, 5000)
      }).catch(err => {
        console.log(err);
        toast.error("Data Perusahaan Tidak Berhasil Diubah", { position: toast.POSITION.TOP_CENTER });
      })
  }

  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <BreadcrumbComp title={'Company Data'}>
          <li className="breadcrumb-item">
            <Link to="/ketua/data-company" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Company Data</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header d-flex">
              <div className='mr-auto'>
                <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>Edit Company Data</h4>
                <span className='text-muted'>Ubah Data Perusahaan</span>
              </div>
            </div>
            <div className='card-body'>
              {
                company &&
                <form onSubmit={handleSubmited}>
                  <div className='w-75' >

                    <div className="row mb-3">
                      <div className="col">
                        <div className="form-group">
                          <h3 className='text-work' style={{ fontSize: '16px' }}>Nama Perusahaan</h3>
                          <input className="form-control text-dark" type="text" defaultValue={name} placeholder="PT. name" onChange={(e)=>setName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <h3 className='text-work' style={{ fontSize: '16px' }}>Tahun Berdiri</h3>
                          <input className="form-control text-dark" type="date" defaultValue={since} placeholder="name@example.com" onChange={(e)=>setSince(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <h3 className='text-work' style={{ fontSize: '16px' }}>Alamat</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <select disabled={listProvinsi.length === 0} name="provinsi" className="form-control text-dark" id="provinsi" onChange={e => {
                            setProvinsi(e.target.value)
                            setListKota([]);
                            setListKecamatan([]);

                            API.rajaongkir.list_kota(e.target.value)
                                .then(json => {
                                  setListKota(json);
                                });
                          }} required>
                            <option value="#">Provinsi</option>
                            {
                              listProvinsi.map(v => {
                                return <option value={v.province_id} selected={provinsi == v.province_id}>{v.province}</option>
                              })
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <select disabled={listKota.length === 0} name="kabupaten" className="form-control text-dark" id="kabupaten" onChange={e => {
                            setKabupaten(e.target.value)
                            setListKecamatan([]);

                            API.rajaongkir.list_kecamatan(e.target.value)
                                .then(json => {
                                  setListKecamatan(json);
                                });
                          }} required>
                            <option value="#">Kota / Kabupaten</option>
                            {
                              listKota.map(v => {
                                return <option value={v.city_id} selected={kabupaten == v.city_id}>{v.city_name}</option>
                              })
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <select disabled={listKecamatan.length === 0} name="kecamatan" className="form-control text-dark" id="kecamatan" onChange={(e)=>setKecamatan(e.target.value)} >
                            <option value="#">Kecamatan</option>
                            {
                              listKecamatan.map(v => (
                                  <option value={v.subdistrict_id} selected={kecamatan == v.subdistrict_id}>{v.subdistrict_name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input className="form-control text-dark" type="text" defaultValue={kode_pos} placeholder="08902" onChange={(e)=>setKodePos(e.target.value)} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <textarea className="form-control text-dark" defaultValue={address} onChange={(e)=>setAddress(e.target.value)}></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <h3 className='text-work' style={{ fontSize: '16px' }}>Nomor Telepon</h3>
                          <input className="form-control text-dark" type="phone" defaultValue={phone} placeholder="08xxxxxx" onChange={(e)=>setPhoneNumber(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <h3 className='text-work' style={{ fontSize: '16px' }}>Email</h3>
                          <input className="form-control text-dark" type="email" defaultValue={email} placeholder="name@example.com" onChange={(e)=>setEmail(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <h3 className='text-work' style={{ fontSize: '16px' }}>Bidang Pelatihan</h3>
                        </div>
                        <div className="d-flex">
                          {
                            category.map((v, index) => {
                              return <div className="mr-2">
                                <p className="btn btn-warning" >{v.name} &nbsp;<a className="text-light"><i class="fa fa-times " onClick={() => handleRemoveCategory(index)}></i></a></p>
                              </div>
                            })
                          }
                        </div>
                      </div>
                      <div className="col-md-5 mt-2">
                        <ul>
                          {
                            listKategori.map(v => {
                              return <li><a className="text-muted" style={{ cursor: 'pointer' }} onClick={() => handleAddCategory(v)}><big><i class="fa fa-plus-circle"></i>&nbsp;{v.name}</big></a></li>
                            })
                          }
                        </ul>
                      </div>
                    </div>
                    <h3 className='text-work' style={{ fontSize: '16px' }}>Profil Singkat</h3>
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName border"
                      editorClassName="editorClassName"
                      onChange={(event) => {
                        setShortProfile(event.blocks[0].text)
                      }}
                    />
                    <div className="row mt-3">
                      <div className="col">
                        <div className="form-group">
                          <h3 className='text-work' style={{ fontSize: '16px' }}>Nama Direktur</h3>
                          <input className="form-control text-dark" type="text" defaultValue={director_name} placeholder="name" onChange={(e)=>setDirectorName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <h3 htmlFor="exampleFormControlInput1" className='text-work' style={{ fontSize: '16px' }}>KTP</h3>
                          <div className="mt-3">
                            {imagePreview}
                          </div>
                          <input className="form-control text-dark" type="file" onChange={handleImageChange} />
                          <p className='txt-danger mt-3'>*Ukuran Foto KTP Maksimal 100KB</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h3 className='text-work text-muted' style={{ fontSize: '18px', fontWeight: 'bold' }}>Kontak Perusahaan</h3>
                  <div className='row'>
                    <div className='col-md-6'>
                      <h3 className='text-work text-muted mb-3' style={{ fontSize: '18px' }}>PIC Admin</h3>
                      <div className='row'>
                        <div className='col-md-3'>
                          <img className="img-80 rounded-circle" src={company.pic_adminc.photo} alt="#"></img>
                        </div>
                        <div className='col-md-9'>
                          <h3 className='text-work' style={{ fontSize: '16px' }}>Nama</h3>
                          <input className="form-control text-dark" type="text" value={company.pic_adminc.name} disabled />
                          <h3 className='text-work mt-3' style={{ fontSize: '16px' }}>Email</h3>
                          <input className="form-control text-dark" type="email" value={company.pic_adminc.email} disabled />
                          <h3 className='text-work mt-3' style={{ fontSize: '16px' }}>Phone</h3>
                          <input className="form-control text-dark" type="phone" value={company.pic_adminc.phone} disabled />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <h3 className='text-work text-muted mb-3' style={{ fontSize: '18px' }}>PIC Ketua</h3>
                      <div className='row'>
                        <div className='col-md-3'>
                          <img className="img-80 rounded-circle" src={company.pic_chairman.photo} alt="#"></img>
                        </div>
                        <div className='col-md-9'>
                          <h3 className='text-work' style={{ fontSize: '16px' }}>Nama</h3>
                          <input className="form-control text-dark" type="text" value={company.pic_chairman.name} disabled />
                          <h3 className='text-work mt-3' style={{ fontSize: '16px' }}>Email</h3>
                          <input className="form-control text-dark" type="email" value={company.pic_chairman.email} disabled />
                          <h3 className='text-work mt-3' style={{ fontSize: '16px' }}>Phone</h3>
                          <input className="form-control text-dark" type="phone" value={company.pic_chairman.phone} disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end mt-5'>
                    <button className="mr-3 btn btn-pill btn-outline-primary btn-sm text-work" type="button" style={{ fontSize: '16px', fontWeight: '600' }}>Cancel</button>
                    <button className="btn btn-pill btn-primary btn-sm text-work" type="submit" style={{ fontSize: '16px', fontWeight: '600' }}>Simpan</button>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditCompany;