import React, {useState, Fragment} from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Users } from 'react-feather';
import { Input } from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import API from "../../../api";
import swal from 'sweetalert2';

const TambahSoalQuisForm = () => {
  let query = new URLSearchParams(useLocation().search);
  let id = query.get('id');
  const history = useHistory();

  const [question, setQuestion] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [correct_answer, setCorrectAnswer] = useState('');
  const [discussion, setDiscussion] = useState('');
  const [option, setOption] = useState([{option: 'A', description: ''}, {option: 'B', description: ''}, {option: 'C', description: ''}, {option: 'D', description: ''}]);

  const handleChangeDescription = (event) => {
    const { name, value, index } = event.target;
    const newInput = Object.assign([], option);
    newInput[index] = { ...newInput[index], [name]: value };

    setOption(newInput);
  };

  const handleSubmited = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    let data = new FormData();

    data.append('question', question);
    data.append('correct_answer', correct_answer);
    data.append('discussion', discussion);

    option.map((v, k) => {
      data.append(`quiz_option[${k}][option]`, v.option);
      data.append(`quiz_option[${k}][description]`, v.description);
    });

    API.adminc.quiz.insert(id, data)
        .then(json => {
          setIsSubmitting(false);
          swal.fire('Sukses', 'Soal Quiz Berhasil Ditambahkan', 'success').then(() => {
            history.push(`/admin/atur-quiz?id=${id}`)
          })
        }).catch(e => {
          setIsSubmitting(false);
          toast.error(e.message, { position: toast.POSITION.TOP_CENTER });
        })
  }
  return (
    <Fragment>
      <div className="page-header">
        <ToastContainer />
        <BreadcrumbComp title={'List pelatihan'}>
          <li className="breadcrumb-item">
            <Link to="/admin/list-pelatihan" className='text-warning'>
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Soal Quiz</li>
          <li className="breadcrumb-item">Tambah Soal Quiz</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5 className='text-work text-theme' style={{ fontSize: '24px', fontWeight: '600' }}>PELATIHAN PERUSAHAAN</h5>
              <p className='text-work text-muted' style={{ fontSize: '18px', fontWeight: '600' }}>Atur Soal Quiz</p>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmited}>

                <h6 style={{ fontSize: '16px' }}>Soal</h6>
                <Editor
                  toolbarClassName="border-bottom"
                  wrapperClassName="border"
                  editorClassName="p-3"
                  onChange={(event) => {
                    setQuestion(event.blocks[0].text)
                  }}
                />
                <div className='mt-5'>

                  {
                    option.length !== 0 &&
                    option.map((field, index) => {
                      return <>
                        <h6 style={{ fontSize: '16px' }}>Pilihan Soal {field.option}</h6>
                        <Editor
                          toolbarClassName="border-bottom"
                          wrapperClassName="border"
                          editorClassName="p-3"
                          onChange={event => handleChangeDescription({
                            target: { name: 'description', value: event.blocks[0].text, index: index }
                          })}
                        />
                      </>
                    })
                  }

                  {/* <h6 style={{ fontSize: '16px' }} className='mt-3'>Pilihan Soal B</h6>
                  <Editor
                    toolbarClassName="border-bottom"
                    wrapperClassName="border"
                    editorClassName="p-3"
                  />
                  <h6 style={{ fontSize: '16px' }} className='mt-3'>Pilihan Soal C</h6>
                  <Editor
                    toolbarClassName="border-bottom"
                    wrapperClassName="border"
                    editorClassName="p-3"
                  />
                  <h6 style={{ fontSize: '16px' }} className='mt-3'>Pilihan Soal D</h6>
                  <Editor
                    toolbarClassName="border-bottom"
                    wrapperClassName="border"
                    editorClassName="p-3"
                  /> */}
                  <h6 style={{ fontSize: '16px' }} className='mt-3'>Pilihan Jawaban</h6>
                  <div className='d-flex justify-content-between w-50 ml-4'>
                    <label>
                      <Input type="radio" name="radio1" onChange={()=>setCorrectAnswer('A')} />{' '}
                      Jawaban A
                  </label> <br />
                    <label>
                      <Input type="radio" name="radio1" onChange={()=>setCorrectAnswer('B')} />{' '}
                      Jawaban B
                  </label><br />
                    <label>
                      <Input type="radio" name="radio1" onChange={()=>setCorrectAnswer('C')} />{' '}
                      Jawaban C
                  </label><br />
                    <label>
                      <Input type="radio" name="radio1" onChange={()=>setCorrectAnswer('D')} />{' '}
                      Jawaban D
                  </label>
                  </div>
                  <textarea required placeholder="Masukan Pembahasan . . ." className='form-control' onChange={(e)=>setDiscussion(e.target.value)} ></textarea>
                </div>
                <div className='my-5 d-flex align-items-center justify-content-end border-top pt-4'>
                  <Link to={`/admin/atur-quiz?id=${id}`}>
                    <button type='button' className='btn btn-outline-primary btn-pill mr-3'>Batal</button>
                  </Link>
                    <button className='btn btn-primary btn-pill' disabled={isSubmitting}>Simpan</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default TambahSoalQuisForm;
