import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { BreadcrumbComp, TableComp } from "../../../component";
import { TransaksiIcon } from "../../../component/common/constant/menu";
import question from "../../../assets/images/question.png";
import success from "../../../assets/images/success.png";
import swal from 'sweetalert2';

import API from "../../../api";
import {numberWithDots} from "../../../helper";

const TagihanVoucher = () => {
  const [modalDetailVoucher, setmodalDetailVoucher] = useState(false);
  const [detailVoucherData, setdetailVoucherData] = useState({});
  const [modalKonfirmasiLunas, setmodalKonfirmasiLunas] = useState(false);
  const [modalAuthentication, setmodalAuthentication] = useState(false);
  const [modalSuccess, setmodalSuccess] = useState(false);
  const [password, setPassword] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [filter, setFilter] = useState({
    code: 0,
    date: 0,
    company: 0,
    total: 0,
    payment_status: 0,
    company_where: 0,
  });

  const selectRef = useRef(null);

  const handleFilter = (col, val = null) => {
    let data_filter = {...filter};

    Object.keys(data_filter).map(function(key, index) {
      data_filter[key] = 0;
    });

    if(val){
      setFilter({
        ...data_filter,
        [col]: val
      })
    }
    else{
      selectRef.current.value = 0;
      let new_val = filter[col] + 1;

      setFilter({
        ...data_filter,
        [col]: new_val > 2 ? 0 : new_val
      })
    }
  };

  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get("page") || 1;

  const [vouchers, setVouchers] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });

  const handleSuccess = () => {
    setmodalAuthentication(false);
    setmodalSuccess(true);
    list_voucher_bills();
  };

  const handleButuhAuthentication = () => {
    setmodalKonfirmasiLunas(false);
    setmodalAuthentication(true);
  };

  const handleKonfirmasiLunas = () => {
    setmodalDetailVoucher(false);
    setmodalKonfirmasiLunas(true);
  };

  const handleViewDetail = (row) => {
    setdetailVoucherData(row);
    setmodalDetailVoucher(!modalDetailVoucher);
  };

  const voucherTabel = [
    {
      dataField: "code",
      text: "Kode Voucher",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      headerFormatter: (column) => (
          <span
              style={{ cursor: 'pointer', display: "block" }}
              onClick={() => handleFilter('code')}
              dangerouslySetInnerHTML={{ __html: `${column.text} ${filter.code === 0 ? '' : filter.code === 1 ? '&#8593;' : '&#8595;'}` }}
          >
          </span>
      ),
    },
    {
      dataField: "date",
      text: "Tanggal",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
      headerFormatter: (column) => (
          <span
              style={{ cursor: 'pointer', display: "block" }}
              onClick={() => handleFilter('date')}
              dangerouslySetInnerHTML={{ __html: `${column.text} ${filter.date === 0 ? '' : filter.date === 1 ? '&#8593;' : '&#8595;'}` }}
          >
          </span>
      ),
      formatter: (cel, row) => {
        return <p className="d-flex align-items-center mb-2">{row.date}</p>;
      },
    },
    {
      dataField: "company",
      text: "Perusahaan",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
    },
    {
      dataField: "nominal",
      text: "Nominal",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      headerFormatter: (column) => (
          <span
              style={{ cursor: 'pointer', display: "block" }}
              onClick={() => handleFilter('total')}
              dangerouslySetInnerHTML={{ __html: `${column.text} ${filter.total === 0 ? '' : filter.total === 1 ? '&#8593;' : '&#8595;'}` }}
          >
          </span>
      ),
      formatter: (cel, row) => {
        return (
          <p className="d-flex align-items-center mb-2">Rp. {numberWithDots(row.nominal)}</p>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "10%", textAlign: "left" }),
      formatter: (cel, row) => {
        return (
          <div
            className={`my-auto mx-auto p-2 text-center card btn-status btn-status_${
              cel === "Tagihan Aktif"
                ? "terpakai"
                : cel === "Expired"
                ? "expired"
                : cel === "Terpakai"
                ? "terpakai"
                : cel === "Draft"
                ? "draft"
                : "aktif"
            }`}
          >
            {cel}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      formatter: (cel, row) => {
        return (
          <div className="d-flex justify-content-center">
            <button
              className=" btn rounded-circle btn-success btn-xs py-1 mr-3"
              type="button"
              onClick={() => handleViewDetail(row)}
            >
              {" "}
              <i className="fa fa-eye"></i>{" "}
            </button>
          </div>
        );
      },
    },
  ];

  const list_voucher_bills = () => {
    setVouchers({
      data: [],
      meta: {
        from: 1,
        to: 0,
        total: 0,
        current_page: page,
        per_page,
        url: "",
      },
    });

    let data_filter = {...filter};

    Object.keys(data_filter).map(function(key, index) {
      if(data_filter[key] === 0)
        delete data_filter[key];
      else
        data_filter[key] = data_filter[key] === 1 ? 'asc' : data_filter[key] === 2 ? 'desc' : data_filter[key];
    });

    API.superadmin.vouchers
        .list_vouchers_bills({ page, per_page, ...data_filter })
        .then((json) => {
          let voucher = { ...vouchers };
          voucher.data = [];

          json.data.data.map((val) => {
            voucher.data.push({
              id: val.id,
              code: val.code,
              company: val.company,
              nominal: val.nominal,
              date: val.date,
              status: val.status,
            });
          });

          voucher.meta.from = (page - 1) * per_page + 1;
          voucher.meta.to =
              per_page > json.data.total
                  ? json.data.total
                  : page * per_page > json.data.total
                  ? json.data.total
                  : page * per_page;
          voucher.meta.total = json.data.total;
          voucher.meta.current_page = page;
          voucher.meta.total_page = Math.ceil(
              voucher.meta.total / voucher.meta.per_page
          );

          setVouchers(voucher);
        });
  }

  useEffect(() => {
    list_voucher_bills();
  }, [page, filter]);

  useEffect(() => {
    API.superadmin.company.list_perusahaan({per_page: 9999})
        .then(json => {
          setCompanies(json.data.data);
        })
  }, []);

  const handleSubmit = () => {
    API.superadmin.vouchers
      .confirm_paid_vocuher(detailVoucherData.id, {password})
      .then((json) => {
        console.log(json);
        handleSuccess();
      })
      .catch((e) => {
        swal.fire('Error', e.message, 'error');
      });
  };
  return (
    <>
      <ToastContainer />
      <div className="page-header d-flex">
        <BreadcrumbComp title={"Tagihan Voucher"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/list-voucher" className="text-warning">
              <TransaksiIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">Tagihan Voucher</li>
        </BreadcrumbComp>
        <div className="ml-auto">
          <button
            className="btn btn-pill btn-outline-primary btn-sm text-work bg-white"
            type="button"
            style={{ fontWeight: "600" }}
          >
            <i className="fa fa-print" />
            &nbsp;Cetak List Tagihan
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 d-flex">
              <div className="mr-auto">
                <h4
                  className="text-theme text-work"
                  style={{ fontWeight: "600" }}
                >
                  {" "}
                  List Voucher
                </h4>
              </div>
              <select
                className="form-control digits mr-3 badge-pill"
                style={{ width: "273px" }}
                id="exampleFormControlSelect7"
                onChange={e => handleFilter('company_where', e.target.value)}
                ref={selectRef}
              >
                <option value={0} selected disabled>Pilih Perusahaan...</option>
                {
                  companies.map(v => (
                    <option value={v.id}>{v.name}</option>
                  ))
                }
              </select>
            </div>
            <div className="card-body pt-0">
              <div className="overflow-auto w-100">
                <TableComp
                  title_pagination="peserta"
                  columns={voucherTabel}
                  data={vouchers}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalDetailVoucher}
        toggle={handleViewDetail}
        size="lg"
        style={{ maxWidth: "1000px" }}
      >
        <ModalHeader
          className="border-0"
          toggle={() => setmodalDetailVoucher(!modalDetailVoucher)}
        >
          <h3
            className="text-work text-muted text-center"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            Detail Voucher
          </h3>
        </ModalHeader>
        <ModalBody>
          <div className="text-center w-75 mx-auto pb-5">
            <div className="row mt-3">
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-muted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Kode Voucher
                </label>
                <p
                  className="text-work text-muted"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  {detailVoucherData.code}
                  {detailVoucherData.terpakai && (
                    <span className="text-theme ml-3">Terpakai</span>
                  )}
                </p>
              </div>
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-muted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Nominal Voucher
                </label>
                <p
                  className="text-work text-success"
                  style={{ fontSize: "18px", fontWeight: "normal" }}
                >
                  {detailVoucherData.nominal}
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-muted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Perusahaan
                </label>
                <p
                  className="text-work"
                  style={{ fontSize: "18px", fontWeight: "normal" }}
                >
                  {detailVoucherData.company}
                </p>
              </div>
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-mutted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Status
                </label>
                <div
                  className={` my-auto w-75 p-2 text-center card btn-status btn-status_${
                    detailVoucherData.status === "Tagihan Aktif"
                      ? "terpakai"
                      : detailVoucherData.status === "Expired"
                      ? "expired"
                      : detailVoucherData.status === "Terpakai"
                      ? "terpakai"
                      : detailVoucherData.status === "Draft"
                      ? "draft"
                      : "aktif"
                  }`}
                >
                  {detailVoucherData.status}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 text-left">
                <label
                  className="text-work text-muted mb-0"
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Tanggal Berlaku
                </label>
                <p
                  className="text-work"
                  style={{ fontSize: "18px", fontWeight: "normal" }}
                >
                  {detailVoucherData.date}
                </p>
              </div>
              {detailVoucherData.status === "Tagihan Aktif" ||
              detailVoucherData.status === "Draft" ? (
                <div className="col-md-6 text-left">
                  <label
                    className="text-work text-muted mb-0"
                    style={{ fontSize: "16px", fontWeight: "normal" }}
                  >
                    Status Transaksi (centang jika sudah lunas)
                  </label>
                  <div className="d-flex align-items-center">
                    <div className="checkbox p-0">
                      <input
                        id="checkbox1"
                        type="checkbox"
                        onChange={handleKonfirmasiLunas}
                      />
                      <label htmlFor="checkbox1">Sudah Lunas</label>
                    </div>
                    <label
                      className="card p-2 text-center ml-3 mb-0"
                      style={{
                        color: "#797979",
                        fontWeight: "bold",
                        fontSize: "16px",
                        backgroundColor: "#E0E2E5",
                      }}
                    >
                      Konfirmasi Lunas
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalKonfirmasiLunas}
        size="md"
        toggle={() => setmodalKonfirmasiLunas(!modalKonfirmasiLunas)}
      >
        <ModalBody>
          <div className="text-center w-75 mx-auto py-5">
            <img
              className="img-fluid mb-4"
              src={question}
              style={{ width: "73px" }}
              alt="omind"
            />
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              Apakah Anda yakin <br />
              <span className="font-weight-normal">
                voucher ini sudah dibayarkan?
              </span>
            </h3>
            <div className="d-flex mx-3 mt-4 justify-content-center">
              <button
                className="btn btn-pill btn-primary btn-sm text-work mr-3"
                type="button"
                style={{ fontWeight: "600", width: "88px" }}
                onClick={handleButuhAuthentication}
              >
                {" "}
                Ya{" "}
              </button>
              <button
                className="btn btn-pill btn-outline-primary btn-sm text-work"
                type="button"
                style={{ fontWeight: "600", width: "88px" }}
                onClick={() => setmodalKonfirmasiLunas(!modalKonfirmasiLunas)}
              >
                {" "}
                Tidak{" "}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalAuthentication} toggle={handleViewDetail}>
        <ModalHeader
          className="border-0"
          toggle={() => setmodalAuthentication(!modalAuthentication)}
        ></ModalHeader>
        <ModalBody>
          <div className="text-center w-75 mx-auto pb-5">
            <h3
              className="text-work"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              Dibutuhkan Autentikasi
            </h3>
            <p
              className="text-work"
              style={{ fontSize: "12px", fontWeight: "normal" }}
            >
              Masukkan password anda untuk konfirmasi status pembayaran
            </p>
            <input
              className="form-control"
              type="password"
              placeholder="Kata Sandi"
              onChange={(event) => setPassword(event.target.value)}
            />
            <button
              className="btn btn-pill btn-primary btn-sm text-work mt-3"
              type="button"
              style={{ fontWeight: "600", width: "88px" }}
              onClick={handleSubmit}
            >
              {" "}
              Masuk{" "}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalSuccess}
        toggle={() => setmodalSuccess(!modalSuccess)}
      >
        <ModalHeader
          className="border-0"
          toggle={() => setmodalSuccess(!modalSuccess)}
        ></ModalHeader>
        <ModalBody>
          <div className="text-center w-75 mx-auto pb-5">
            <img
              className="img-fluid mb-4"
              src={success}
              style={{ width: "73px" }}
              alt="omind"
            />
            <h3
              className="text-work"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              Sukses mengkonfirmasi pelunasan
            </h3>
            <p
              className="text-work"
              style={{ fontSize: "12px", fontWeight: "normal" }}
            >
              Status tagihan akan diubah menjadi sudah lunas
            </p>
          </div>
        </ModalBody>
      </Modal>

      {/* <Modal isOpen={modalDelete} size="md" toggle={() => setModalDelete(!modalDelete)}>
        <ModalBody>
          <div className="text-center w-50 mx-auto py-5">
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>Apakah anda yakin menghapus voucher ini?</h3>
            <div className="d-flex justify-content-between mx-3 mt-4">
              <button
                className="btn btn-pill btn-primary btn-sm text-work"
                type="button"
                style={{ fontWeight: "600", width: '88px' }}
                onClick={() => setModalDelete(!modalDelete)}
              > Ya </button>
              <button
                className="btn btn-pill btn-outline-primary btn-sm text-work"
                type="button"
                style={{ fontWeight: "600", width: '88px' }}
                onClick={() => setModalDelete(!modalDelete)}
              > Tidak </button>
            </div>
          </div>
        </ModalBody>
      </Modal> */}
    </>
  );
};
export default TagihanVoucher;
