import React, { useState, useEffect } from "react";
import { BreadcrumbComp, TableComp } from "../../../component";
import { Link, useLocation } from "react-router-dom";
import { CompanyIcon } from "../../../component/common/constant/menu";
import remove from "../../../assets/images/remove.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import dummy_king from "../../../assets/images/dummy_king.png";
import API from "../../../api";
import {numberWithDots} from "../../../helper";

const Crosspay = () => {
  const [detailSelected, setdetailSelected] = useState(null);
  const [modalDetail, setmodalDetail] = useState();
  const [order, setOrder] = useState('desc');

  let query = new URLSearchParams(useLocation().search);
  const per_page = 10;
  let page = query.get("page") || 1;

  const [crosspays, setCrosspays] = useState({
    data: [],
    meta: {
      from: 1,
      to: 0,
      total: 0,
      current_page: page,
      per_page,
      url: "",
    },
  });
  const columnsTabel = [
    {
      dataField: "id",
      text: "ID Transaksi",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "16px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="text-center">
            <label>
              <p className="font-weight-bold">{row.id}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "tanggal",
      text: "Tanggal",
      headerStyle: () => ({ width: "12%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "16px" }}
        >
          {column.text}
        </p>
      ),
    },
    {
      dataField: "nama",
      text: "Nama Perusahaan",
      headerStyle: () => ({ width: "20%", textAlign: "left" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "16px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="text-left">
            <img src={row.avatar} alt="" className="img-20 rounded-circle" />
            <label className="mb-0 ml-2">
              <p style={{ fontWeight: "400", fontSize: "14px" }}>{row.nama}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "peserta",
      text: "Peserta",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "16px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="text-center">
            <label className="mb-0 ml-2">
              <p className="font-weight-bold">{row.peserta}</p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "harga",
      text: "Harga Total",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "16px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cell, row) => {
        return (
          <div className="text-center">
            <label className="mb-0 ml-2">
              <p style={{ fontWeight: "500", fontSize: "14px" }}>
                Rp {numberWithDots(row.harga)}
              </p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "16px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <div className="text-center">
            <label
              className={`mb-0 ml-2 badge badge-${
                row.status === "Paid" ? "success" : "warning"
              }`}
            >
              <p style={{ fontWeight: "500", fontSize: "14px" }}>
                {row.status}
              </p>
            </label>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Aksi",
      headerStyle: () => ({ width: "10%", textAlign: "center" }),
      headerFormatter: (column) => (
        <p
          className="text-muted my-2"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {column.text}
        </p>
      ),
      formatter: (cel, row) => {
        return (
          <div className="text-center">
            <button
              className="btn btn-pill btn-outline-primary btn-sm"
              type="button"
              onClick={() => {
                API.superadmin.crosspay
                  .crosspay_detail(row.id_crosspay)
                  .then((json) => {
                    setdetailSelected(json.data);
                  });
                setmodalDetail(!modalDetail);
              }}
            >
              {" "}
              Detail{" "}
            </button>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    API.superadmin.crosspay.list_crosspay({ page, per_page, order }).then((json) => {
      let crosspay = { ...crosspays };
      crosspay.data = [];
      let companies = {};
      json.data.data.map((val, index) => {
        val.company.map((company, index) => {
          companies = company;
          crosspay.data.push({
            id_crosspay: val.id,
            id: val.no_transaction,
            no: index + 1,
            crosspay: val.code,
            tanggal: val.created_at,
            avatar: companies.logo,
            nama: companies.name,
            peserta: 3,
            harga: val.price,
            status: val.status_paid,
          });
        });
      });

      crosspay.meta.from = (page - 1) * per_page + 1;
      crosspay.meta.to =
        per_page > json.data.total
          ? json.data.total
          : page * per_page > json.data.total
          ? json.data.total
          : page * per_page;
      crosspay.meta.total = json.data.total;
      crosspay.meta.current_page = page;
      crosspay.meta.total_page = Math.ceil(
        crosspay.meta.total / crosspay.meta.per_page
      );

      setCrosspays(crosspay);
    });
  }, [page, order]);
  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"List Pemakaian Pelatihan"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/crosspay" className="text-warning">
              <CompanyIcon color="#FCC932" />
            </Link>
          </li>
          <li className="breadcrumb-item">Transaksi</li>
          <li className="breadcrumb-item">Crosspay</li>
        </BreadcrumbComp>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 pb-3 d-flex align-items-center">
              <div className="mr-auto">
                <h5
                  className="text-work text-theme"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  LIST PEMAKAIAN PELATIHAN
                </h5>
              </div>
              <div className="d-flex ml-4">
                <label className="mr-3 my-auto">Urutkan berdasarkan</label>
                <div>
                  <select
                      className="form-control digits"
                      id="exampleFormControlSelect7"
                      onChange={e => {
                        setOrder(e.target.value);
                      }}
                  >
                    <option value="desc" >Terbaru</option>
                    <option value="asc" >Terlama</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="overflow-auto w-100">
                <TableComp
                  rowClasses="text-center"
                  title_paggination="transaksi"
                  columns={columnsTabel}
                  data={crosspays}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalDetail}
        toggle={() => setmodalDetail(!modalDetail)}
        size="lg"
        style={{ maxWidth: "1050px" }}
      >
        <ModalHeader toggle={() => setmodalDetail(!modalDetail)}>
          Detail Transaksi
        </ModalHeader>
        <ModalBody className="pb-5">
          {detailSelected && (
            <>
              <div className="d-flex">
                <div className="mr-auto">
                  <label className="text-muted text-work mb-0">
                    No Transaksi
                  </label>
                  <h6 className="mb-3">{detailSelected.no_transaction}</h6>
                </div>
              </div>
              <label className="text-muted text-work mb-0">Pembeli</label>
              <div className="d-flex align-items-center mb-3">
                <div>
                  <img
                    className="img-20 rounded-circle"
                    src={detailSelected.buyer.photo}
                    alt="#"
                  ></img>
                </div>
                <label className="mb-0 ml-2">
                  <p className="font-weight-bold mb-0">
                    {detailSelected.buyer.name}
                  </p>
                </label>
              </div>
              <label className="text-muted text-work mb-0">Status</label>
              <div className="mb-3">
                <h6
                  className={`p-2 text-center badge badge-${
                    detailSelected.status_paid === "Paid"
                      ? "success"
                      : "warning"
                  } text-work text-muted`}
                >
                  {detailSelected.status_paid}
                </h6>
              </div>
              <label className="text-muted text-work mb-0">
                Tanggal Pembelian
              </label>
              <h6 className="mb-4">{detailSelected.buy_date}</h6>
              <hr />
              <div>
                <div className="row">
                  <div className="col-md-6">
                    {" "}
                    <p
                      className="text-muted"
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      Nama Pelatihan
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p
                      className="text-muted"
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      Trainer
                    </p>
                  </div>
                  <div className="col-md-2">
                    <p
                      className="text-muted"
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      Harga
                    </p>
                  </div>
                </div>
                {detailSelected?.courses &&
                  detailSelected.courses.map((data, key) => (
                    <div className="row mb-4">
                      <div className="col-md-6 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <div>
                            <img
                              className="img-60"
                              src={data.course.photo}
                              alt="#"
                            ></img>
                          </div>
                          <label className="mb-0 ml-2">
                            <p
                              className="mb-0"
                              style={{ fontSize: "14px", fontWeight: "600" }}
                            >
                              {data.course.name}
                            </p>
                            <div
                              className={`badge text-wrap text-capitalize ${
                                data.course.type === "online"
                                  ? "badge-primary"
                                  : data.course.type === "Face to Face"
                                  ? "badge-danger"
                                  : "badge-pink"
                              }`}
                            >
                              {data.course.type}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 border-right border-left d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <div>
                            <img
                              className="img-40 rounded-circle"
                              src={data.trainer.photo}
                              alt="#"
                            ></img>
                          </div>
                          <label className="mb-0 ml-2">
                            <p className="font-weight-bold mb-0">
                              {data.trainer.name}
                            </p>
                            <p className="mb-0">{data.trainer.company}</p>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 d-flex align-items-center">
                        <p className="font-weight-bold mb-0 txt-success">
                          {data.course.price}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="mt-5">
                <p
                  className="text-muted"
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  Daftar Peserta
                </p>
                <table className="table table-hover table-borderless w-50">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Nama Peserta</th>
                    </tr>
                  </thead>
                  {/* {detailSelected?.namapeserta &&
                    detailSelected.namapeserta.map((data, key) => (
                      <tbody>
                        <tr>
                          <td>{data.no}</td>
                          <td>{data.nama}</td>
                        </tr>
                      </tbody>
                    ))} */}
                </table>
              </div>
              <hr />
              <p
                className="text-muted"
                style={{ fontWeight: "600", fontSize: "16px" }}
              >
                Pembayaran
              </p>
              <div className="d-flex mx-5">
                <p
                  className="text-muted text-work mb-0 mr-auto"
                  style={{ fontSize: "16px" }}
                >
                  Subtotal Produk
                </p>
                <p
                  className="mb-0"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  {new Intl.NumberFormat("id", {
                    style: "currency",
                    currency: "IDR",
                  }).format(detailSelected.subtotal)}
                </p>
              </div>
              <div className="d-flex mx-5">
                <p
                  className="text-muted text-work mb-0 mr-auto"
                  style={{ fontSize: "16px" }}
                >
                  Total Keseluruhan
                </p>
                <p
                  className="mb-0 txt-success"
                  style={{ fontSize: "16px", fontWeight: "600" }}
                >
                  {new Intl.NumberFormat("id", {
                    style: "currency",
                    currency: "IDR",
                  }).format(detailSelected.total)}
                </p>
              </div>
              <div className="d-flex mx-5">
                <p
                  className="text-muted text-work mb-0 mr-auto"
                  style={{ fontSize: "16px" }}
                >
                  Metode Pembayaran
                </p>
                <p
                  className="mb-0"
                  style={{ fontSize: "16px", fontWeight: "600" }}
                >
                  {detailSelected.method}
                </p>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Crosspay;
