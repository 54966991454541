import React from "react";
import { Link, useLocation, useHistory } from 'react-router-dom';
import bca_logo from '../../../assets/images/bca-logo.png';
import {numberWithDots} from "../../../helper";
import bni_logo from "../../../assets/images/bni.png";

const LihatTagihan = () => {
  const {detail} = useLocation().state;
  const history = useHistory();

  return (
    <>
      <div className="card rounded-0 py-3 shadow">
        <div className="container-fluid">
          <Link to='/riwayat-transaksi'>
            <p className='btn p-0 text-theme mb-0'>
              <i className="fa fa-arrow-left"></i> Kembali ke transaksi
            </p>
          </Link>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          {/*<div className='col-md-12 mb-3'>
            <div className='badge badge-warning w-100 py-3' style={{ fontSize: '18px', backgroundColor: 'rgba(252, 255, 86, 0.62)', border: '1px solid #EFF400' }}>
              <span className='font-weight-normal'>Batas waktu pembayaran akan berakhir dalam </span>6 jam 5 menit 20 detik</div>
          </div>*/}
          <div className='col-md-9'>
            <div className='card p-4'>
              <h3 className='font-weight-bold'>Detail Pesanan</h3>
              <label className='d-flex mt-3'>
                <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Sisa Waktu Pembayaran:</p>
                <p className='mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>{detail.tanggal_pembayaran}</p>
              </label>
              <label className='d-flex mt-3'>
                <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Pembayaran:</p>
                <p className='mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>{detail.metode}</p>
              </label>

              {
                detail.va_account &&
                <div className='row w-75 mx-auto my-3'>
                  <div className='col-md-6 text-center border-right'>
                    <img className="w-50 mb-3" src={detail.metode === 'BCA Virtual Account' ? bca_logo : detail.metode === 'BNI Virtual Account' ? bni_logo : ''} alt="#"/>
                    <p className='text-danger' style={{fontWeight:'500', fontSize:'16px'}}>{detail.va_account}</p>
                  </div>
                  <div className='col-md-6 text-center'>
                    <p className='mr-auto mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>Total Pembayaran</p>
                    <p className='mr-auto mb-0' style={{ fontWeight: '600', fontSize: '28px' }}>Rp {numberWithDots(detail.total)}</p>
                  </div>
                </div>
              }

              <button className={`btn btn-pill btn-primary btn-sm font-weight-bold px-3 w-100`} onClick={(e) => {
                window.snap.pay(detail.token, {
                  onPending: function(result){
                    e.preventDefault();
                  },
                });
              }}>Lihat Tagihan</button>

              {/*<div>
                <p className='mr-auto mb-0' style={{ fontWeight: '600', fontSize: '18px' }}>Petunjuk</p>
                <ol className='d-block'>
                  <li>Login pada aplikasi M-BCA, masukkan PIN M-BCA.</li>
                  <li>Pilih "m-Transfer", pilih "BCA Virtual Account".</li>
                  <li>Klik "Input no. Virtual Account", lalu masukkan no BCA Virtual Account 1651 1321 2315 2156.</li>
                  <li>Klik "OK" & "Send".</li>
                  <li>Periksa data transaksi kemudian pilih "OK" kemudian masukkan PIN M-BCA Anda.</li>
                  <li>Periksa kembali data transaksi Anda, kemudian klik "OK".</li>
                </ol>
              </div>*/}
            </div>
          </div>
          <div className='col-md-3'>
            <div className='card p-4'>
              <p className='text-muted' style={{ fontSize: '18px' }}>Detail Pembayaran</p>
              <label className='d-flex mt-3'>
                <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Total Price</p>
                <p className='mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>Rp {numberWithDots(detail.subtotal)}</p>
              </label>
              {detail.promo &&
                <label className='d-flex'>
                  <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Kupon</p>
                  <p className='text-success mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>- Rp {numberWithDots(''+detail.harga_promo)}</p>
                </label>
              }

              {detail.point !== '0' &&
                <label className='d-flex'>
                  <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Kupon</p>
                  <p className='text-success mb-0' style={{ fontWeight: '500', fontSize: '16px' }}> - Rp {numberWithDots(''+detail.point)}</p>
                </label>
              }

              <label className='d-flex border-top'>
                <p className='mr-auto mb-0' style={{ fontWeight: 'normal', fontSize: '16px' }}>Biaya Admin</p>
                <p className='mb-0' style={{ fontWeight: '500', fontSize: '16px' }}>Rp {numberWithDots(''+detail.admin_fee)}</p>
              </label>

              <label className='d-flex border-top'>
                <p className='mr-auto mb-0' style={{ fontWeight: '600', fontSize: '18px' }}>Grand Total</p>
                <p className='mb-0' style={{ fontWeight: '600', fontSize: '18px' }}>Rp {numberWithDots(''+detail.total)}</p>
              </label>
              {/*<button className={`btn btn-pill btn-outline-primary btn-sm font-weight-bold px-3 w-100 mt-5 mb-3`} type="button">Upload Bukti Pembayaran</button>*/}
              <button className={`btn btn-pill btn-primary btn-sm font-weight-bold px-3 w-100`} type="button" onClick={() => {
                history.push('/riwayat-transaksi');
              }}>Selesai</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LihatTagihan;
