import React, {useState, useEffect} from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useHistory, useLocation } from "react-router-dom";
import { CompanyIcon } from '../../../component/common/constant/menu';
import { ToastContainer, toast } from 'react-toastify';
import camera from '../../../assets/images/camera.png';
import API from "../../../api";

const TambahKetua = () => {
  let query = new URLSearchParams(useLocation().search);
  let id = query.get('id');
  const history = useHistory();
  const [tambahFotoPreview, settambahFotoPreview] = useState(null);
  const [tambahFotoPreviewUrl, settambahFotoPreviewUrl] = useState("");
  const [data_profession, setDataProfession] = useState('');
  const [photo, setPhoto] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [profession, setProfession] = useState('');
  const [phone, setPhone] = useState('');
  const [chairman, setChairman] = useState(null);

    const [DataKetua, setDataKetua] = useState({});
    const [matchPassword, setmatchPassword] = useState(false)
    const [DoneSubmit,  setDoneSubmit] = useState(false)
    const [isLoading, setisLoading] = useState(false)

    const handleChange = (event) => {
        // setDataKetua({
        //     ...DataKetua,
        //     [event.target.name]: event.target.value
        // })
      }

    useEffect(() => {
      if(DataKetua.password){
          setmatchPassword(DataKetua.password === DataKetua.konfirmasi_password)
      }
    }, [DataKetua]);

    // const handleSubmited = (event) => {
    //   setisLoading(true);
    //   toast.success("Ketua Klien Berhasil Ditambahkan", { position: toast.POSITION.TOP_CENTER });
    //   setDoneSubmit(true)
    // }

    const handletambahFotoChange = (event) => {
      event.preventDefault();

      let reader = new FileReader();
      let file = event.target.files[0];

      setPhoto(file)

      reader.onloadend = () => {
        settambahFotoPreviewUrl(reader.result);
      };

      reader.readAsDataURL(file);
    };

    useEffect(() => {
      if (tambahFotoPreviewUrl) {
        settambahFotoPreview(tambahFotoPreviewUrl);
      } else {
        settambahFotoPreview(false);
      }
    }, [tambahFotoPreviewUrl]);

    useEffect(() => {
      API.adminc.profession.get_profession()
        .then(json => {
          let profesi = {...data_profession}
          profesi.data = []

          json.data.map(v => {
            profesi.data.push({
              id: v.id,
              name: v.name,
            })
          })
          setDataProfession(profesi)
        }).catch(err => {
          console.log(err);
        });

      if(id){
        API.adminc.chairman.get_chairman()
            .then(json => {
              setChairman(json.data);
              let user = json.data;

              setName(user.name);
              setEmail(user.email);
              setPhone(user.phone);
              setProfession(user.profession.id);
            }).catch(err => {
          console.log(err);
        })
      }
    }, [])

    const handleSubmited = (event) => {
      event.preventDefault();

      if(photo === null){
        toast.error("Foto Profil Tidak Boleh Kosong", { position: toast.POSITION.TOP_CENTER });
      }else if(password !== confirm_password){
        toast.error("Confirm Password Tidak Sesuai", { position: toast.POSITION.TOP_CENTER });
      }else{
        const data = {
          email: email,
          name: name,
          profession_id: profession,
          phone_number: phone,
        }

        if(id){
          if(photo)
            data.profile_picture = photo;

          if(password)
            data.password = password;
        }
        else{
          data.profile_picture = photo;
          data.password = password;
        }

        const config = {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }

        if(id){
          API.adminc.chairman.update_chairman(data, config)
              .then(json => {
                console.log(json.data);
                setisLoading(true);
                toast.success("Ketua Klien Berhasil Diupdate", { position: toast.POSITION.TOP_CENTER });
                setDoneSubmit(true)
                setTimeout(() => {
                  history.push('/admin/ketua-klien')
                }, 5000)
              }).catch(err => {
                Object.values(err.errors).map(v => {
                  toast.error(v, { position: toast.POSITION.TOP_CENTER });
                });
                console.log(err);
              })
        }
        else
          API.adminc.chairman.add_chairman(data, config)
            .then(json => {
              console.log(json.data);
              setisLoading(true);
              toast.success("Ketua Klien Berhasil Ditambahkan", { position: toast.POSITION.TOP_CENTER });
              setDoneSubmit(true)
              setTimeout(() => {
                history.push('/admin/ketua-klien')
              }, 5000)
            }).catch(err => {
              console.log(err);
              toast.error("Ketua Klien Tidak Berhasil Ditambahkan", { position: toast.POSITION.TOP_CENTER });
            })
      }
    }

    return (
      <>
        <div className="page-header">
          <BreadcrumbComp title={'Ketua Klien'}>
            <li className="breadcrumb-item">
              <Link to="/admin/ketua-klien" className='text-warning'>
                <CompanyIcon color='#FCC932' />
              </Link>
            </li>
            <li className="breadcrumb-item">Manajemen Perusahaan</li>
            <li className="breadcrumb-item">Ketua Klien</li>
            <li className="breadcrumb-item">{id ? 'Ubah' : 'Tambah'} Data Ketua Klien</li>
          </BreadcrumbComp>
        </div>
        <div className="row">
          <div className="col-sm">
            <div className="card">

              <div className="card-header">
                  <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>KETUA KLIEN</h4>
                  <span className='text-muted'>Data Ketua Klien</span>
              </div>

              <div className="col-md-12">
                <form className="row mx-4" onSubmit={handleSubmited}>
                  {/* Krii */}
                  <div className="col-md-2">
                    <div className="form-group">
                    <img className="rounded-circle img-100 ml-3" style={{height: 100, objectFit: 'cover'}} src={tambahFotoPreview ? tambahFotoPreview : (chairman ? chairman.photo : camera)} alt="KTP"/>

                    <div className="button-custom py-3 mt-3">
                      <div className="upload-image w-100 mr-3">
                        <a style={{fontSize : '14px'}}>
                          {id ? 'Ubah' : 'Tambah'} Foto
                        </a>
                        <input
                          type="file"
                          onChange={handletambahFotoChange}
                        />
                      </div>
                    </div>
                    </div>

                  </div>
                  {/* Tengah */}
                  <div className='col-md-4 mr-3'>
                    <div className="form-group ">
                      <label htmlFor="InputEmail"><b>Email</b></label>
                      <input type="email" className="form-control" name="email" id="InputEmail" required placeholder="jims.otw@sdm.training.co.id" onChange={(e)=>setEmail(e.target.value)} value={email} />
                    </div>
                    <div className="form-group ">
                      <label htmlFor="InputPassword"><b>Password</b></label>
                      <input type="password" className="form-control" name="password" id="InputPassword" required={!id} onChange={(e)=>setPassword(e.target.value)} />
                    </div>
                    <div className="form-group ">
                      <label htmlFor="konfirmasi_password"><b>Konfirmasi Password</b></label>
                      <input type="password" className="form-control" name="konfirmasi_password" id="konfirmasi_password" required={!id} onChange={(e)=>setConfirmPassword(e.target.value)} />
                    </div>
                  </div>

                  {/* kanan */}
                  <div className='col-md-4 ml-5'>
                    <div className="form-group ">
                      <label htmlFor="nama"><b>Nama</b></label>
                      <input type="text" className="form-control" name="nama" id="nama" required placeholder="Jimmy Maulani" onChange={(e)=>setName(e.target.value)} value={name}/>
                    </div>

                    <div className="form-group ">
                      <label htmlFor="alamatKantor"><b>Jabatan</b></label>
                        <select required defaultValue='default' className="form-control" name="profession_id" onChange={(e)=>setProfession(e.target.value)}>
                          <option disabled selected>Pekerjaan</option>
                          {
                            data_profession &&
                            data_profession.data.map(v => {
                              return <option value={v.id} selected={profession === v.id}>{v.name}</option>
                            })
                          }
                      </select>
                    </div>

                    <div className="form-group ">
                      <label htmlFor="noTel"><b>Nomor Telepon</b></label>
                      <input className="form-control" name="noTel" id="noTel" required placeholder="0812349828588" onChange={(e)=>setPhone(e.target.value)} value={phone} />
                    </div>
                  </div>

            <div className="form-group col-sm-12 my-5">
              <div className="col text-right mr-5">
                <Link to="/admin/ketua-klien">
                  <button type="batal" className="btn btn-outline-primary btn-pill text-work">Batal</button>
                </Link>

                <button type="submit" onClick={() => {
                  toast({ type: "error", message: "Error message", autoDismissTimeout:5000 });
                }} className="btn btn-primary ml-3 btn-pill text-work mr-5" primary loading={isLoading}>Simpan</button>
                <ToastContainer />
              </div>
            </div>

            </form>
            </div>
            </div>
          </div>
        </div>
      </>
    )
}
export default TambahKetua;
