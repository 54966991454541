import React, { useState, useEffect } from "react";
import { BreadcrumbComp } from "../../../component";
import { Link } from "react-router-dom";
import { MessageCircle } from "react-feather";
import API from "../../../api";
import axios from 'axios';
import Pusher from 'pusher-js';

const Chat = () => {
  const [listchats, setListChat] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [contactChat, setContactChat] = useState(null);
  const [contactStatus, setContactStatus] = useState(false);
  const [chats, setChat] = useState(null);
  const [chatStatus, setChatStatus] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    API.superadmin.chat.list_contact().then((json) => {
      let data_contact = json.data;
      setContacts(data_contact.contact);
    });

    getListChat()

    const user = JSON.parse(localStorage.getItem('data'))

    var pusher = new Pusher('adcb5b5e9c81cd42daff', {
      cluster: 'ap1'
    });

    var channel = pusher.subscribe('user' + user.id);
    channel.bind('chat', function (data) {
      getListChat()

      if (data.user == localStorage.getItem('userChatId')) {
        API.superadmin.chat
          .get_chat(data.user)
          .then((json) => {
            const chat = json.data;
            setChat(chat);
            handleChangeStatus(data.user)
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }, []);

  useEffect(() => {
    ScrollChatBottom();
  }, [chats]);

  const ScrollChatBottom = () => {
    document.querySelector(".chat-history-container").scrollTop = document.querySelector(
      ".chat-history-container"
    ).scrollHeight;
  }


  const handleChat = (data, id) => {
    setChatStatus("no_match");
    setContactChat(data);
    setContactStatus("match");
    handleGetChat(id)
    handleChangeStatus(id)
  };

  const handleContact = (data, id) => {
    setChatStatus("no_match");
    setContactChat({
      contact: {
        id: id,
        name: data.name,
        profile_picture: data.profile_picture,
        role_id: {
          name: data.role_id
        }
      }
    });
    setContactStatus("match");
    handleGetChat(id)
  }

  const handleGetChat = (id) => {
    API.superadmin.chat
      .get_chat(id)
      .then((json) => {
        const chat = json.data;
        setChat(chat);
        setChatStatus("match");
        localStorage.setItem('userChatId', id)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSendMessage = (id) => {
    API.superadmin.chat
      .send_chat(id, { message: message })
      .then((json) => {
        const newChat = { ...chats }
        newChat.data.push(json.data)
        setChat(newChat);
        getListChat();
        setMessage("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListChat = () => {
    API.superadmin.chat.list_chat().then((json) => {
      let list_chat = json.data;
      setListChat(list_chat);
    });
  }

  const handleChangeStatus = (id) => {
    API.superadmin.chat.change_status(id).then((json) => {
      getListChat()
    })
  }

  const handleSearch = (search) => {
    if (search.length === 0) {
      getListChat()
    }
    const result = listchats.filter(val => {
      return val.contact.name.toLowerCase().includes(search.toLowerCase())
    })
    setListChat(result)
  }

  const handleSearchContact = (search) => {
    if (search.length === 0) {
      API.superadmin.chat.list_contact().then((json) => {
        let data_contact = json.data;
        setContacts(data_contact.contact);
      });
    }
    const result = contacts.filter(val => {
      return val.name.toLowerCase().includes(search.toLowerCase())
    })
    setContacts(result)
  }

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={"Chat"}>
          <li className="breadcrumb-item">
            <Link to="/superadmin/chat" className="text-warning">
              <MessageCircle />
            </Link>
          </li>
          <li className="breadcrumb-item">Chat</li>
        </BreadcrumbComp>
      </div>
      <div className="row" style={{ height: "33rem" }}>
        <div className="col-md-3 h-100">
          <div className="card h-100">
            <div className="card-header px-3">
              <h6
                className="mb-2 text-nunito"
                style={{ fontSize: "18px", fontWeight: "800" }}
              >
                All Chats
              </h6>
              <div className="row">
                <div className="col-xl-12">
                  <form className="search-form contact-search ">
                    <div className="form-group mb-0">
                      <input
                        className="form-control rounded"
                        type="text"
                        placeholder="search"
                        onChange={(e) => {
                          handleSearch(e.target.value)
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="card-body" style={{ height: "100%", overflowY: "auto" }}>
              {listchats && (
                <>
                  {listchats.map((val) => {
                    return (
                      <>
                        <div
                          className="row border-bottom mb-3 pb-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleChat(val, val.contact.id)}
                        >
                          <div className="col-md-3 px-2">
                            <img
                              className="rounded-circle"
                              src={val.contact.profile_picture ? val.contact.profile_picture : require('../../../assets/images/user.png')}
                              style={{width: 40, height: 40}}
                              alt="#"
                            />
                          </div>
                          <div className="col-md-9">
                            <div className="row">
                              <div className="col-md-9 pl-0">
                                <h6
                                  className="mb-0 text-truncate"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {val.contact.name}
                                  <span
                                    className="font-weight-normal text-muted ml-2 mr-auto"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {val.contact.role_id.name}
                                  </span>
                                </h6>
                                <p className="mb-0 text-truncate mt-2">
                                  {" "}
                                  {val.message}
                                </p>
                              </div>
                              <div className="col-md-3 px-0 text-right">
                                <p
                                  className="font-weight-normal text-muted mb-0"
                                  style={{ fontSize: "12px" }}
                                >
                                  {val.created_at}
                                </p>
                                {
                                  val.count_message > 0 &&
                                  <span
                                    className="bg-primary py-1 px-2  rounded-circle"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {val.count_message}
                                  </span>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
        <div className='col-md-6' style={{ maxHeight: "33rem" }}>
          <div className="card mb-0" style={{ height: "100%" }}>
            <div className="card-header py-3 d-flex align-items-center">
              <div className="d-flex align-items-center">
                {contactStatus === "match" ? (
                  <>
                    <div>
                      <img
                        className="rounded-circle"
                        src={contactChat.contact.profile_picture ? contactChat.contact.profile_picture : require('../../../assets/images/user.png')}
                        style={{width: 40, height: 40}}
                        alt="#"
                      />
                    </div>
                    <label className="mb-0 ml-4">
                      <h6
                        className="mb-0"
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        {contactChat.contact.name} -{" "}
                        {contactChat.contact.role_id.name}
                      </h6>
                      <p className="mb-0">online</p>
                    </label>
                  </>
                ) : null}
              </div>
            </div>
            <div className="card-body chat-history-container" style={{ height: '100%', overflowX: 'auto' }}>
              <div className="chat-history chat-msg-box custom-scrollbar">
                {chatStatus === "match" ? (
                  <>
                    <ul>
                      {chats.data.map((item, index) => (
                        <li key={index} className="clearfix">
                          <div
                            className={`message my-message p-3  mb-3 ${
                              item.created_by === contactChat.contact.id ? "" : "float-right text-right"
                              }`}
                            style={
                              item.created_by === contactChat.contact.id
                                ? {
                                  maxWidth: "70%",
                                  width: "fit-content",
                                  background: "#EBEEF0",
                                  borderRadius: "0px 40px 40px 40px",
                                }
                                : {
                                  maxWidth: "70%",
                                  width: "fit-content",
                                  color: "#FFFFFF",
                                  background: "#2DABFF",
                                  borderRadius: "40px 40px 0px 40px",
                                }
                            }
                          >
                            {item.message}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : null}
              </div>
            </div>
            <div className="card-footer">
              <div className="d-flex align-items-center">
                {contactStatus === "match" ? (
                  <>
                    <input
                      type="text"
                      value={message}
                      className="form-control input-txt-bx bg-light text-dark"
                      placeholder="Type a Message"
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={e => {
                        if (e.key === 'Enter')
                          handleSendMessage(contactChat.contact.id)
                      }}
                    />
                    <button
                      className="btn bg-white txt-primary border-0 text-work"
                      type="button"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                      onClick={() => handleSendMessage(contactChat.contact.id)}
                    >
                      Send
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-3 h-100'>
          <div className="card h-100">
            <div className='card-body h-100 px-0' style={{ overflow: "hidden" }}>
              <h6
                className="mb-2 text-nunito text-center mb-3"
                style={{ fontSize: "18px", fontWeight: "800" }}
              >
                Contacts
              </h6>
              <div className="row mb-4">
                <div className="col pl-0 pr-0 chat-menu show">
                  <form className="search-form contact-search ">
                    <div className="form-group mb-0">
                      <input
                        className="form-control rounded"
                        type="text"
                        placeholder="search contact"
                        onChange={(e) => {
                          handleSearchContact(e.target.value)
                        }}

                      />
                    </div>
                  </form>
                </div>
              </div>
              <div style={{ overflowY: "auto", overflowX: "hidden", height: "100%" }}>
                {contacts &&
                  contacts.map((v) => {
                    return (
                      <div
                        className="row border-bottom mb-3 pb-2 px-3"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleContact(v, v.id)}
                      >
                        <div className="col-md-3 px-2 ">
                          <img
                            className="img-40 rounded-circle"
                            src={v.profile_picture ? v.profile_picture : require('../../../assets/images/user.png')}
                            style={{height: 40, width: 40}}
                            alt="#"
                          />
                        </div>
                        <div className="col-md-9">
                          <div className="row">
                            <div className="col-md-12 pl-2">
                              <h6
                                className="mb-0 text-truncate"
                                style={{ fontSize: "14px", fontWeight: "600" }}
                              >
                                {v.name}
                              </h6>
                              <p className="mb-0 text-truncate">{v.role_id}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
