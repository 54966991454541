import Post from "../Post";
import Get from "../Get";

const list_trainer = (data = null) =>
  Get(
    `super-admin/trainers?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const detail_trainer = id => Get(`super-admin/trainers/${id}`);
const course_trainer = (id, data = null) =>
  Get(
    `super-admin/trainers/courses/${id}?${
      data &&
      Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&")
    }`
  );
const SuperadminListTrainer = {
  list_trainer,
  detail_trainer,
  course_trainer,
};

export default SuperadminListTrainer;
