import React, { useState, useEffect } from 'react';
import MulaiKuis from './mulaiKuis';
import Kuis from './kuis';
import LulusKuis from './lulusKuis';
import CobaLagi from './cobaLagi';
import HubungiTrainer from './hubungiTrainer';
import Pembahasan from './pembahasan';


const PelatihanKuisView = ({id, name, attempt, isSuccess, res}) => {
  const [View, setView] = useState('mulai');
  const [Attempt, setAttempt] = useState(attempt);
  const [result, setResult] = useState(res);

  useEffect(() => {
    setResult(res);
    setAttempt(attempt);
  }, [res, attempt]);

  return View === 'pembahasan' ? (
    <Pembahasan id={id} name={name} result={result} />
  ) : View === 'kuis' ? (
    <Kuis Attempt={Attempt} setView={setView} id={id} setAttempt={setAttempt} setResultProps={setResult} />
  ) : View === 'lulus' ? (
    <LulusKuis result={result} Attempt={attempt} setView={setView} />
  ) :  View === 'cobalagi' ? (
    <CobaLagi Attempt={Attempt} setView={setView} result={result} />
  ) :  View === 'hubungi' ? (
    <HubungiTrainer Attempt={Attempt} setView={setView}  />
  ) : isSuccess ? (
    <LulusKuis result={result} Attempt={attempt} setView={setView} />
  ) : View === 'mulai' && attempt < 4 ? (
    <MulaiKuis Attempt={Attempt} setView={setView} name={name} />
  ) : attempt === 4 ? (
    <HubungiTrainer result={result} Attempt={Attempt} setView={setView}  />
  ) : ''
}

export default PelatihanKuisView;
