import Get from "../Get";
import Post from "../Post";
import Delete from "../Delete";

const list_participant = (data) => Get(`admin-c/participants?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const participant_detail = id => Get(`admin-c/participants/${id}`);
const delete_participant = id => Delete(`admin-c/participants/${id}`);
const reset_password = id => Post(`admin-c/participants/reset-password/${id}`);
const upload_bulk = data => Post(`admin-c/participants/import-participant`, data);
const upload_bulk_replace = data => Post(`admin-c/participants/import-participant/replace`, data);
const get_bulk = () => Get(`admin-c/participants/download-bulk`);
const add_participant = data => Post(`admin-c/participants`, data);
const search_participant = (data) => Get(`admin-c/participants/search?${data && Object.keys(data).map(key => key + '=' + data[key]).join('&')}`);
const get_update = id => Get(`admin-c/participants/${id}/update`);
const update_participant = (id, data) => Post(`admin-c/participants/${id}/update`, data);

const ParticipantAdminC = {
    list_participant,
    delete_participant,
    reset_password,
    participant_detail,
    upload_bulk,
    upload_bulk_replace,
    get_bulk,
    add_participant,
    search_participant,
    get_update,
    update_participant,
};

export default ParticipantAdminC;
