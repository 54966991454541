import React, {useEffect, useState} from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link, useLocation } from "react-router-dom";
import { Users } from 'react-feather';
import API from "../../../api";

const PesertaSaya = () => {
  let query = new URLSearchParams(useLocation().search);
  const columnsTabelTransaksi = [
      {
          dataField: 'peserta_id',
          text: 'ID',
          headerStyle: () => ({ width: '10%', textAlign: 'left'}),
          headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      },
    {
      dataField: 'peserta',
      text: 'Nama Peserta',
      headerStyle: () => ({ width: '30%', textAlign: 'left'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cell, row) => {
        return(
          <div className='d-flex align-items-center mb-2'>
            <div>
                <img className="rounded-circle" src={row.avatar} style={{height: 30, width: 30}} />
            </div>
            <label className='mb-0 ml-2'>
              <p className='font-weight-bold'>{row.peserta}</p>
            </label>
          </div>
        )
      }
    },
    {
      dataField: 'pelatihan',
      text: 'Pelatihan yang diikuti',
      headerStyle: () => ({ width: '20%', textAlign: 'left'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: 'periode',
      text: 'Periode',
      headerStyle: () => ({ width: '20%', textAlign: 'left'}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
    },
    {
      dataField: '',
      text: '',
      headerStyle: () => ({ width: '10%', textAlign: 'center',}),
      headerFormatter: (column)=><p className='text-muted my-2' style={{fontWeight:'600', fontSize:'18px'}}>{column.text}</p>,
      formatter: (cel,row) => {
        return  (
          <Link to={`/trainer/peserta-detail?id=${row.id}&user_id=${row.peserta_id}`}>
            <button className=" btn btn-pill btn-outline-primary btn-sm font-weight-bold" type="button"> Detail </button>
          </Link>
        )
      },
    }
  ];
  const per_page = 7;

  let page = query.get('page') || 1;
  const [participants, setParticipants] = useState({
      data:[],
      meta:{
          from:1,
          to:0,
          total: 0,
          current_page: page,
          per_page,
          url: 'peserta-saya'
      }
  });

  useEffect(() => {
      API.trainer.participant.get_all({status: 'aktif', per_page, page})
          .then(json => {
              let peserta = {...participants};
              peserta.data = [];

              json.data.data.map(val => {
                 peserta.data.push({
                     id: val.id,
                     peserta: val.user_id.name,
                     peserta_id: val.user_id.id,
                     periode: val.periode,
                     pelatihan: val.course_id.name,
                     avatar: val.user_id.profile_picture ? val.user_id.profile_picture.replace(/ /g, '%20') : require('../../../assets/images/user.png'),
                     time_added: val.time_added
                 })
              });

              peserta.meta.from = (page - 1) * per_page + 1;
              peserta.meta.to = per_page > json.data.num_rows_filter ? json.data.num_rows_filter : page * per_page > json.data.num_rows_filter ? json.data.num_rows_filter : page * per_page;
              peserta.meta.total = json.data.num_rows_filter;
              peserta.meta.current_page = page;

              setParticipants(peserta);
          })
  },[page]);

    const handleFilter = e => {
        let peserta = {...participants};
        if(e.target.value === 'new')
            peserta.data = peserta.data.sort((a,b) => {
                return b.time_added - a.time_added
            });
        else if(e.target.value === 'old')
            peserta.data = peserta.data.sort((a,b) => {
                return a.time_added - b.time_added
            });
        setParticipants(peserta);
    };

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Peserta Saya'}>
          <li className="breadcrumb-item">
            <Link to="/trainer" className='text-warning'>
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Peserta Saya</li>
        </BreadcrumbComp>
      </div>
      <div className='row'>
      <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 pb-3 d-flex align-items-center">
              <div className='mr-auto'>
                <h5 className='text-work' style={{fontSize:'24px', fontWeight:'600'}}>LIST PESERTA AKTIF</h5>
              </div>
              <div>
                <select className="form-control btn-pill digits" id="exampleFormControlSelect7" onChange={handleFilter}>
                    <option disabled selected>Sorting berdasarkan</option>
                    <option value='new'>Terbaru</option>
                    <option value='old'>Terlama</option>
                </select>
              </div>
            </div>
            <div className="card-body">
              <div className='overflow-auto w-100'>
                <TableComp
                    title_paggination='pelatihan'
                    columns={columnsTabelTransaksi}
                    data={participants}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PesertaSaya;
