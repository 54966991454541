import React, {useEffect, useState} from 'react';
import { BreadcrumbComp } from '../../../component';
import { Link, useLocation, useHistory } from "react-router-dom";
import { Users, MessageSquare, ArrowLeft } from 'react-feather';
import quizpng from '../../../assets/images/quiz.png';
import API from "../../../api";

const Quiz = () => {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const [quiz, setQuiz] = useState([]);
  const [namaQuiz, setNamaQuiz] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    API.trainer.course.progress_quiz(query.get('id'))
        .then(json => {
          setNamaQuiz(json.data[0].stat_theory_id.name);
          API.trainer.participant.get_one(json.data[0].stat_theory_id.user_id)
              .then(json => {
                setUser(json.data);
              });

          setQuiz(json.data);
        })
  }, []);

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Pembahasan'}>
          <li className="breadcrumb-item">
            <Link to="/trainer/peserta-saya" className='text-warning'>
              <Users />
            </Link>
          </li>
          <li className="breadcrumb-item">Peserta Saya</li>
          <li className="breadcrumb-item">Detail Peserta</li>
          <li className="breadcrumb-item">Progress Peserta</li>
        </BreadcrumbComp>
        <div className="text-theme d-flex align-items-center font-weight-bold mt-4 text-work" style={{cursor: 'pointer'}} onClick={() => {
            history.goBack();
        }}>
          <ArrowLeft /> &nbsp;Kembali
        </div>
      </div>
      <div className='row'>
        <div className='col-md-8'>
          <div className='row'>
            <div className='col-md-6 mb-3'>
              <div className='card px-4 py-2 h-100'>
                <div className='d-flex align-items-center my-auto'>
                  <img className='img-60 mr-3' src={quizpng} alt='omind' />
                  <label className='mb-0 ml-2 mr-auto'>
                    <h6 className='mb-0 font-weight-bold mb-1' style={{fontSize:'20px', fontWeight:'600'}}>Quiz</h6>
                    <p className='mb-0'  style={{fontSize:'16px'}}>Quiz {namaQuiz}</p>
                  </label>
                </div>
              </div>
            </div>
            {
              quiz.map((v,k) => (
                  <div className='col-md-6 mb-3'>
                    <div className='card px-4 py-2 h-100'>
                      <div className='my-auto'>
                        <h5 className='mb-0 text-muted text-work' style={{fontSize:'22px', fontWeight:'600', display: 'inline'}}>Hasil Quiz</h5>
                        <h5 className='mb-0 text-muted text-work' style={{fontSize:'14px', fontWeight:'600', float: 'right'}} ref={element => {
                          if (element) element.style.setProperty('color', k+1 === 1 ? '#2DABFF' : k+1 === 2 ? '#FCC932' : '#E74C3C', 'important');
                        }}>Attempt {k+1}/3</h5>
                        <div className='d-flex align-items-center my-3'>
                          <p className='mb-0'  style={{fontSize:'18px',fontWeight:'600'}}>Quiz {namaQuiz}</p>
                          <p className='mb-0 ml-auto text-muted'>{v.date_created}</p>
                        </div>
                        <div className='d-flex align-items-center w-75'>
                          <p className='mb-0 text-muted'>Benar <span className='text-dark'>{v.correct_answers}</span></p>
                          <p className='mb-0 mx-auto text-muted'>Salah <span className='text-danger'>{v.wrong_answers}</span></p>
                          <p className='mb-0 text-muted'>Nilai <span className='text-primary'>{v.score}</span></p>
                        </div>
                        <div className='text-right mt-4'>
                          <Link to={`/trainer/quiz/pembahasan?id=${v.id}`}>
                            <button className={`btn btn-square btn-outline-light txt-primary border-0 p-0`} type="button">Lihat Detail <i className='fa fa-angle-right'></i></button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
              ))
            }
          </div>
        </div>
        <div className='col-md-4'>
        <div className='card p-4'>
          {
            user &&
            <div className="card-header p-0">
              <h5 className='text-muted font-weight-bold'>Profil Peserta</h5>
              <div className='d-flex align-items-center my-3'>
                <div>
                  <img className="img-60 rounded-circle" src={user.profile_picture} alt="#" />
                </div>
                <label className='mb-0 ml-2'>
                  <h6 className='mb-0'>{user.name}</h6>
                  <p className='mb-0'><span className="font-weight-bold">Peserta</span> - {user.company_id}</p>
                </label>
              </div>
              <p className='mb-0'>No Telepon</p>
              <p className="font-weight-bold">{user.phone_number}</p>
              <p className='mb-0'>Email</p>
              <p className="font-weight-bold">{user.email}</p>
              <p className='mb-0'>Status Pelatihan</p>
              <p className="font-weight-bold text-success">{user.is_active ? 'Aktif' : 'Nonaktif'}</p>
              <p className='mb-0'>Asal Kota</p>
              <p className="font-weight-bold">{user.city}</p>
            </div>
          }
            <div className="card-body d-flex align-items-center justify-content-center text-muted btn">
              <MessageSquare />
              <h6 className='ml-2 font-weight-bold mb-0'>Chat</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Quiz;
