import React, {useState, useEffect} from 'react';
import { BreadcrumbComp, TableComp } from '../../../component';
import { Link } from "react-router-dom";
import { CompanyIcon } from '../../../component/common/constant/menu';
import logo_SDM from '../../../assets/images/logo-SDM.png';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import API from "../../../api";

const CompanyData = () => {
  const [company, setCompany] = useState(null);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    API.adminc.company.getCompany()
      .then(json => {
        setCompany(json.data)
      }).catch(err => {
        console.log(err);
      })
  }, [])

  return (
    <>
      <div className="page-header">
        <BreadcrumbComp title={'Company Data'}>
          <li className="breadcrumb-item">
            <Link to="/admin/data-company" className='text-warning'>
              <CompanyIcon color='#FCC932' />
            </Link>
          </li>
          <li className="breadcrumb-item">Manajemen Perusahaan</li>
          <li className="breadcrumb-item">Company Data</li>
        </BreadcrumbComp>
      </div>
      {
        company &&
        <>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex">
                  <div className='mr-auto'>
                    <h4 className='text-theme text-work' style={{ fontWeight: '600' }}>COMPANY DATA</h4>
                    <span className='text-muted'>Data Perusahaan</span>
                  </div>
                  <div>
                    <Link to='/admin/edit-company'>
                    <button className=" btn btn-pill btn-outline-primary btn-sm text-work" type="button" style={{ fontWeight: '600' }}><i className="fa fa-pencil"></i> &nbsp;Ubah Data Company</button>
                    </Link>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-9'>
                    <div className="card-body">
                      <div className='d-flex mb-4'>
                        <div>
                          <h3 className='font-weight-bold'>{company.name}</h3>
                          <p className='text-muted mb-0'> <i className='fa fa-building-o'></i> Berdiri sejak {company.since}</p>
                          <p className='text-muted mb-0'> <i className='fa fa-map-marker'></i> {company.address}</p>
                          <p className='text-muted mb-0'> <i className='fa fa-phone'></i>  {company.phone_number}  &nbsp;&nbsp;<i className='fa fa-envelope-o'></i> {company.email}</p>
                          <a href={`/${company.id}/login`}>Link perusahaan</a>
                          <div className='d-flex align-items-center mt-3'>
                            <p className='mb-0'>Bidang Pelatihan </p>
                            {company.categories.length > 4 ?
                                <div className="row">
                                  {company.categories.map((value) => {
                                    return (
                                        <div className='col-3 mb-2'>
                                          <button
                                              className="btn btn-pill btn-outline-warning btn-xs ml-3"
                                              type="button"
                                          >
                                            {value.name}
                                          </button>
                                        </div>
                                    );
                                  })}
                                </div> :
                                company.categories.map((value) => {
                                  return (
                                      <button
                                          className="btn btn-pill btn-outline-warning btn-xs ml-3"
                                          type="button"
                                      >
                                        {value.name}
                                      </button>
                                  );
                                })
                            }
                          </div>
                        </div>
                      </div>

                      <h5>Kontak Perusahaan</h5>
                      <div className='row'>
                        <div className='col-md-6'>
                          <p>PIC Admin</p>
                          <div className='d-flex mb-2'>
                            <div>
                              <img className="img-40 rounded-circle" style={{height:40, objectFit: 'cover'}} src={company.pic_adminc.photo} alt="#"/>
                            </div>
                            <label className='mb-0 ml-2'>
                              <h6 className='font-weight-bold mb-0'>{company.pic_adminc.name}</h6>
                              <p className='mb-0'><i className='fa fa-envelope-o'></i> {company.pic_adminc.email}</p>
                              <p><i className='fa fa-phone'></i>{company.pic_adminc.phone} (WA / Call)</p>
                            </label>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <p>PIC Ketua</p>
                          <div className='d-flex mb-2'>
                              <div>
                                <img className="img-40 rounded-circle" style={{height:40, objectFit: 'cover'}} src={company.pic_chairman.photo} alt="#"/>
                              </div>
                            <label className='mb-0 ml-2'>
                              <h6 className='font-weight-bold mb-0'>{company.pic_chairman.name}</h6>
                              <p className='mb-0'><i className='fa fa-envelope-o'></i> {company.pic_chairman.email}</p>
                              <p><i className='fa fa-phone'></i>{company.pic_chairman.phone} (WA / Call)</p>
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <h5>Profil Singkat</h5>
                      <span className='text-muted text-justify' dangerouslySetInnerHTML={{__html: company.short_profile}}></span>
                    </div>
                  </div>
                  <div className='col-md-3 pt-5'>
                    <div className='w-50 card border p-3'>
                      <img className="img-fluid w-100" src={company.logo} alt="" />
                    </div>
                    <p className='text-muted mb-0'>Direktur</p>
                    <h5>{company.director_name}</h5>
                    <button className="btn btn-pill btn-outline-primary btn-sm mt-3 text-work" type="button" style={{ fontWeight: '600' }} onClick={toggle}>Lihat KTP Direktur</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>KTP Direktur</ModalHeader>
              <ModalBody>
                  <img src={company.director_ktp} style={{display: 'block', margin: '0 auto', width: '100%'}} alt="KTP Direktur"/>
              </ModalBody>
              <ModalFooter>
                  <Button color="secondary" onClick={toggle}>Cancel</Button>
              </ModalFooter>
          </Modal>
        </>
      }
    </>
  )
}

export default CompanyData;
