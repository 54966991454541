import React from 'react';
import ReactPlayer from 'react-player'


// documentasi library https://cookpete.com/react-player/

const PelatihanVideoView = (props) => {
  return (
    <div className='card p-5'>
      <div className='w-100 border rounded mb-5'>
        <ReactPlayer url={props.url.replace(/ /g,"%20")} width='100%' controls />
        <div className='d-flex mb-2 align-items-center ml-4 my-3'>
          <div>
            <img className="img-40 rounded-circle" style={{height: '40px', objectFit: 'cover'}} src={props.user.img} alt="#" />
          </div>
          <label className='mb-0 ml-2'>
            <h6 className='mb-0' style={{ fontWeight: '600', fontSize: '13px' }}>{props.user.name}</h6>
            <p className='mb-0' style={{ fontSize: '10px' }}>{props.user.role} dari {props.user.perusahaan}</p>
          </label>
        </div>
      </div>
    </div>
  )
}

export default PelatihanVideoView;
