import React, {useState} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import logo_SDM from '../../../assets/images/logo-SDM.png';
const OverviewPerusahaan = (props) => {
    const {data} = props;
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return(
        data &&
        <>
        <div className="card">
            <div className='row'>
                <div className='col-md-9'>
                    <div className="card-header">
                        <h5 className='text-warning mb-3'>OVERVIEW PERUSAHAAN</h5>
                        <h3 className='font-weight-bold'>{data.name}</h3>
                        <p className='text-muted mb-0'> <i className='fa fa-building-o'></i> Berdiri sejak {data.since}</p>
                        <p className='text-muted mb-0'> <i className='fa fa-map-marker'></i> {data.address}</p>
                        <p className='text-muted mb-0'> <i className='fa fa-phone'></i> {data.phone_number} &nbsp;&nbsp;<i className='fa fa-envelope-o'></i> {data.email}</p>
                        <div className='d-flex align-items-center mt-3'>
                            <p className='mb-0'>Bidang Pelatihan </p>
                            {
                                data.category_company.map(val => {
                                    return <button className="btn btn-pill btn-outline-warning btn-xs ml-3" type="button">{val.category_id.name}</button>

                                })
                            }
                        </div>
                    </div>
                    <div className="card-body">
                        <h5>Kontak Perusahaan</h5>
                        <div className='row'>
                            <div className='col-md-6'>
                                <p>PIC Admin</p>
                                <div className='d-flex mb-2'>
                                    <div>
                                        <img class="img-40 rounded-circle" src={data.adminc.profile_picture} style={{backgroundRepeat: 'no-repeat', height: '40px', objectFit: 'cover'}} alt="#" />
                                    </div>
                                    <label className='mb-0 ml-2'>
                                        <h6 className='font-weight-bold mb-0'>{data.adminc.name}</h6>
                                        <p className='mb-0'><i className='fa fa-envelope-o'></i> {data.adminc.email}</p>
                                        <p><i className='fa fa-phone'></i> {data.adminc.phone_number} (WA / Call)</p>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <p>PIC Ketua</p>
                                <div className='d-flex mb-2'>
                                    <div>
                                        <img class="img-40 rounded-circle" style={{height: '40px', objectFit: 'cover'}} src={data.chairman.profile_picture} alt="#"/>
                                    </div>
                                    <label className='mb-0 ml-2'>
                                        <h6 className='font-weight-bold mb-0'>{data.chairman.name}</h6>
                                        <p className='mb-0'><i className='fa fa-envelope-o'></i> {data.chairman.email}</p>
                                        <p><i className='fa fa-phone'></i> {data.chairman.phone_number} (WA / Call)</p>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <h5>Profil Singkat</h5>
                        <p className='text-muted text-justify' dangerouslySetInnerHTML={{__html: data.short_profile}}/>
                    </div>
                </div>
                <div className='col-md-3 pt-5'>
                    <div className='w-50 card border p-3'>
                        <img className="img-fluid w-100" src={data.logo} alt="" />
                    </div>
                    <p className='text-muted mb-0'>Direktur</p>
                    <h5>{data.director_name}</h5>
                    {/*<button className="btn btn-pill btn-outline-primary btn-sm mt-3" type="button" onClick={toggle}>Lihat KTP Direktur</button>*/}
                </div>
            </div>
        </div>
            {/*<Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>KTP Direktur</ModalHeader>
                <ModalBody>
                    <img src={data.director_ktp} style={{display: 'block', margin: '0 auto', width: '100%'}} alt="KTP Direktur"/>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>*/}
        </>
    )
}

export default OverviewPerusahaan;
