import React, { useState, useEffect } from 'react';
import dummy_king from '../../../assets/images/dummy_king.png';
import { CustomInput } from 'reactstrap';
import {numberWithDots} from "../../../helper";
import API from "../../../api";
import {Link} from "react-router-dom";

const Cart1 = (props) => {
    const [product, setProduct] = useState([]);
    const [total, setTotal] = useState(0);
    const [checkedAll, setCheckedAll] = useState(false);

    const handleDelete = (id, key) => {
      let products = [...product];
      let price = products[key].harga;
      setTotal(total => total - price < 0 ? 0 : total - price);
      products.splice(key, 1);
      setProduct(products);

      API.peserta.course.delete_cart(id)
          .catch(e => {
              alert(e.message);
          })
    };

    const handleCheck = key => {
        let products = [...product];
        products[key].checked = !products[key].checked;
        setProduct(products);

        let checked = product.filter(val => {
            return val.checked;
        });

        let total = 0;
        checked.map(val => {
            total += parseInt(val.harga);
        });

        setTotal(total);

        let checkedCount = checked.length;

        setCheckedAll(checkedCount === product.length)
    };

    const handleCheckAll = () => {
        let products = [...product];
        products.map(val => {
            val.checked = !checkedAll;
        });

        let checked = product.filter(val => {
            return val.checked;
        });

        let total = 0;
        checked.map(val => {
            total += parseInt(val.harga);
        });

        setTotal(total);

        setCheckedAll(check => !check);
        setProduct(products);
    }

    useEffect(() => {
        setProduct(props.product);
    }, [props.product]);

    return (
        <div className='col-md-12'>
          <div className="card">
            <div className='card-body'>
              {
                  product.map((data, key) => (
                      <div className='row py-3 border-bottom'>
                        <div className='col-md-5'>
                          <div className='d-flex align-items-center'>
                          <CustomInput type="checkbox" id={key} checked={data.checked} onClick={() => handleCheck(key)} />
                            <img className="img-60 ml-2 mr-3" src={data.img} alt="#" />
                            <label>
                              <p className='mb-0'>Pelatihan</p>
                              <h6> {data.name} {data.schedule_id ? `- ${data.schedule_id.name}` : ''} </h6>
                            </label>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <p className='mb-0'>Trainer</p>
                          <h6> {data.trainer} </h6>
                        </div>
                        <div className='col-md-1 d-flex align-items-center justify-content-center' >
                          <h6> {data.status} </h6>
                        </div>
                        <div className='col-md-2 d-flex align-items-center justify-content-end' >
                          <h6 className='text-success'>Rp. {numberWithDots(data.harga)} </h6>
                        </div>
                        <div className='col-md-1 d-flex align-items-center justify-content-center' style={{cursor:"pointer"}}
                             onClick={() => handleDelete(data.id, key)}
                        >
                          <h6><i className="fa fa-trash-o"></i></h6>
                        </div>
                      </div>
                  ))
              }
              <div className='row py-4'>
                <div className='col-md-6'>
                  <CustomInput type="checkbox" id="checkAll" label='Pilih semua pelatihan' checked={checkedAll} onClick={handleCheckAll} />
                </div>
                <div className='col-md-5 text-right' >
                  <h6 className='text-nunito' style={{fontWeight:'800', fontSize:'20px'}}> Total Harga &nbsp; &nbsp; &nbsp; <span className='text-success'>Rp {numberWithDots(total)}</span> </h6>
                </div>
                <div className='col-md-1' ></div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end'>
              <Link to={`/pelatihan-lainnya`}>
                  <button className={`btn btn-pill btn-outline-primary btn-sm font-weight-bold px-3 mr-3`} type="button">Temukan Pelatihan Lainnya</button>
              </Link>
            <button className={`btn btn-pill btn-primary btn-sm font-weight-bold px-3`} type="button" onClick={()=>{
                let products = [...product].filter(val => {
                    return val.checked;
                });

                if (products.length){
                    props.setProducts(products);
                    props.setTotal(total);
                    props.setStep(2)
                }
                else
                    alert('Pilih course yang ingin dibeli dulu');
            }}>Check Out </button>
          </div>
        </div>
    )
}

export default Cart1;
