import React from 'react';
import PDFViewer from 'pdf-viewer-reactjs'
import CustomNavigation from './navigation';

const PelatihanPPTView = (props) => {
  const {url, user} = props
  return (
    <>
      <div className='card p-5'>
        <div className='w-100 border rounded mb-5'>
          <PDFViewer
            hideZoom
            hideRotation
            document={{
              url: url,
            }}
            css='customViewer'
            navigation={(e) => CustomNavigation(e, props.user)}
            canvasCss='customCanvas'
          />
        </div>
        <div>
          <h6 style={{ fontWeight: '600', fontSize: '18px' }}>Deskripsi</h6>
          <p className='text-justify'>Seperti yang sudah dipelajari dalam materi turunan, variabel dalam suatu fungsi mengalami penurunan pangkat. Berdasarkan contoh tersebut, diketahui bahwa ada banyak fungsi yang memiliki hasil turunan yang sama yaitu yI = 3x2. Fungsi dari variabel x3 ataupun fungsi dari variabel x3 yang ditambah atau dikurang suatu bilangan (misal contoh: +8, +17, atau -6) memiliki turunan yang sama. </p>
          <p className='text-justify'>Seperti yang sudah dipelajari dalam materi turunan, variabel dalam suatu fungsi mengalami penurunan pangkat. Berdasarkan contoh tersebut, diketahui bahwa ada banyak fungsi yang memiliki hasil turunan yang sama yaitu yI = 3x2. Fungsi dari variabel x3 ataupun fungsi dari variabel x3 yang ditambah atau dikurang suatu bilangan (misal contoh: +8, +17, atau -6) memiliki turunan yang sama. </p>
        </div>
      </div>
    </>
  )
}

export default PelatihanPPTView;